<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 col-md-12 p-0 formborder">
    <div class="col-md-2">
      <span>From Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          required
          placeholder="DD/MM/YYYY"
          name="FromDate"
          id="FromDate"
          #refFromdate="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="FromDate"
        />
        <!-- [ngClass]="{
            'is-invalid': at.submitted && refToDate.invalid,
            'alert-warning':
              refToDate.invalid &&
              (refToDate.dirty || refToDate.touched || refToDate.untouched)
          }" -->
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>
    <div class="col-md-2">
      <span>To Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          [min]="FromDate"
          placeholder="DD/MM/YYYY"
          name="ToDate"
          id="ToDate"
          #refTodate="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="ToDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
    </div>
    <div class="col-md-2">
      <span>Status</span>
      <select
        name="st"
        [(ngModel)]="Status"
        class="form-control form-select"
        required
      >
        <option value="Pending" selected>Pending</option>
        <option value="Approved">Approved</option>
        <option value="Rejected">Rejected</option>
      </select>
    </div>
    <div class="col-md-1">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="Get_Receipt_Data_From_Date()"
      >
        Search
      </button>
    </div>

    <div class="col-md-3 mt-2 ml-5" [hidden]="!dataSourceCustomer">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="mt-3">
    <div
      class="table-responsive mt-3"
      *ngIf="dataSourceCustomer.data.length > 0"
    >
      <mat-table
        [dataSource]="dataSourceCustomer"
        #sortCustomerList="matSort"
        matSort
        id="exportReport"
        matTableExporter
        #exporter="matTableExporter"
      >
        <ng-container matColumnDef="Id">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 50px"
            *matHeaderCellDef
          >
            #
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 50px; max-height: 5px"
          >
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="VoucherId">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 150px"
            *matHeaderCellDef
          >
            Voucher ID
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 150px"
          >
            {{ row.vid }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="LoanId">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 150px"
            *matHeaderCellDef
          >
            Loan Id
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 150px"
          >
            {{ row.LoanId }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="ApplicationNo">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 150px"
            *matHeaderCellDef
          >
            Aplication No
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 150px"
          >
            {{ row.Application_No }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Voucher_Type">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Voucher Type
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_Type }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Voucher_Sub_Type">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Voucher SubType
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_Sub_Type }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Voucher_Tag">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Voucher Tag
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_Tag }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Voucher_Date">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Voucher Date
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_Date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="CreatedBy">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 150px"
            *matHeaderCellDef
          >
            Created By
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 150px"
          >
            {{ row.CreatedBy }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Account_Name">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 150px"
            *matHeaderCellDef
          >
            Account Name
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 150px"
          >
            {{ row.Account_Name }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Amount">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 150px"
            *matHeaderCellDef
          >
            Amount
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 150px"
          >
            {{ row.Amount }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Reason">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Reason</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Reason }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="EditAction">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 100px"
            *matHeaderCellDef
          >
            View Detail
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell j-c-center"
            style="max-width: 100px"
          >
            <i
              class="fa fa-arrow-circle-right arrow-circle action-btn"
              (click)="OnShowVoucherModel(row.LoanId, row.vid, row.Status)"
              aria-label="true"
            ></i>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedCustomerColumns"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedCustomerColumns"
        ></mat-row>
      </mat-table>
      <div class="row m-0 align-items-center">
        <div class="col-md-4">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportexcelNew()"
          >
            <i class="" style="font-size: medium; cursor: pointer"></i>
            Export
          </button>
        </div>

        <div class="col-md-8">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<!--  Modal Sheet -->
<div
  class="modal fade in"
  id="VoucherModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    style="max-width: 1140px"
  >
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Voucher Detail
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseVoucherModel()"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <!-- Loan Info -->
        <div class="row m-0 col-md-12 mt-0 formborder" *ngIf="loanSummary">
          <div
            class="col-md-12 mb-1"
            [ngClass]="
              loanSummary.CloseDate != null
                ? 'alert alert-danger'
                : loanSummary.Loan_Acc_Type != 'Own Fund'
                ? 'alert alert-info'
                : 'alert alert-success'
            "
          >
            <div class="row">
              <div class="col-md-2">
                <span class="fs-12"
                  ><B>Loan No :</B> {{ loanSummary.LoanNo }}</span
                >
              </div>
              <div class="col-md-2">
                <span class="fs-12"
                  ><B>Loan Date :</B> {{ loanSummary.LoanDate }}</span
                >
              </div>
              <div class="col-md-4">
                <span class="fs-12"
                  ><B>Application No :</B> {{ loanSummary.ApplicationNo }}</span
                >
              </div>
              <div class="col-md-4">
                <span class="fs-12">
                  <B>Application Date :</B> {{ loanSummary.Application_Date }}
                </span>
              </div>

              <div class="col-md-2">
                <span class="fs-12"
                  ><B>Branch :</B> {{ loanSummary.Branch }}</span
                >
              </div>
              <div class="col-md-2">
                <span class="fs-12"
                  ><B>Loan Amount :</B> {{ loanSummary.LoanAmount }}</span
                >
              </div>
              <div class="col-md-4">
                <span class="fs-12"
                  ><B>Product :</B> {{ loanSummary.Product }}</span
                >
              </div>
              <div class="col-md-4">
                <span class="fs-12"
                  ><B>Borrower :</B> {{ loanSummary.Customer }}</span
                >
              </div>

              <div class="col-md-2">
                <span class="fs-12">
                  <B>Tenure :</B>
                  {{ loanSummary.Tenure > 0 ? loanSummary.Tenure : "" }}
                </span>
              </div>
              <div class="col-md-2">
                <span class="fs-12"
                  ><B>Expiry Date :</B> {{ loanSummary.ExpiryDate }}</span
                >
              </div>
              <div class="col-md-4">
                <span class="fs-12"
                  ><B>Close Date :</B> {{ loanSummary.CloseDate }}</span
                >
              </div>
              <div class="col-md-4">
                <span class="fs-12">
                  <B>Loan A/C Category :</B> {{ loanSummary.Loan_Acc_Category }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- Table  -->
        <div class="row mt-3">
          <div class="col-md-12">
            <div class="table-responsive" style="overflow: auto">
              <table
                class="table-bordered table-sm table"
                mat-table
                [dataSource]="dataSourceModel"
              >
                <ng-container matColumnDef="TranType">
                  <th mat-header-cell *matHeaderCellDef>Type</th>
                  <td mat-cell *matCellDef="let row">{{ row.TranType }}</td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="Case_No">
                  <th mat-header-cell *matHeaderCellDef>Case No</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.TranType == "DR" ? "" : loanSummary.ApplicationNo }}
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="Customer">
                  <th mat-header-cell *matHeaderCellDef>Customer</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.TranType == "DR" ? "" : loanSummary.Customer }}
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="Branch">
                  <th mat-header-cell *matHeaderCellDef>Branch</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.TranType == "DR" ? "" : loanSummary.Branch }}
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="Category">
                  <th mat-header-cell *matHeaderCellDef>Category</th>
                  <td mat-cell *matCellDef="let row">{{ row.ChagesHead }}</td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <ng-container matColumnDef="Account_Name">
                  <th mat-header-cell *matHeaderCellDef>Account Head</th>
                  <td mat-cell *matCellDef="let row">{{ row.Account_Name }}</td>
                  <td mat-footer-cell *matFooterCellDef>Amount</td>
                </ng-container>
                <ng-container matColumnDef="AmountDR">
                  <th mat-header-cell *matHeaderCellDef>Amount(DR)</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.TranType == "DR" ? row.Amount : "0.0" }}
                  </td>
                  <td mat-footer-cell *matFooterCellDef>{{ Dramt }}</td>
                </ng-container>
                <ng-container matColumnDef="AmountCR">
                  <th mat-header-cell *matHeaderCellDef>Amount(CR)</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.TranType == "CR" ? row.Amount : "0.0" }}
                  </td>
                  <td mat-footer-cell *matFooterCellDef>
                    {{ Cramt.toFixed(2) }}
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumnsModel; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsModel"
                ></tr>
                <tr
                  mat-footer-row
                  *matFooterRowDef="displayedColumnsModel"
                ></tr>
              </table>
            </div>
          </div>
        </div>
        <!-- Payment Details -->
        <div class="row mt-3 formborder" *ngIf="DrData">
          <div class="col-md-3">
            <span class="fs-12"><B>Tag :</B>{{ DrData.Voucher_Tag }} </span>
          </div>

          <div class="col-md-3" *ngIf="DrData?.Voucher_Mode_of_Payment">
            <span class="fs-12"
              ><B>Mode of Payment :</B>{{ DrData.Voucher_Mode_of_Payment }}
            </span>
          </div>
          <div
            class="col-md-3"
            *ngIf="DrData?.Voucher_Mode_of_Payment == 'Cheque'"
          >
            <span class="fs-12"
              ><B>Cheque No :</B>{{ DrData.Voucher_Cheque_No }}</span
            >
          </div>
          <div
            class="col-md-3"
            *ngIf="DrData?.Voucher_Mode_of_Payment == 'Cheque'"
          >
            <span class="fs-12"
              ><B>Cheque Date :</B>{{ DrData.Voucher_Cheque_Date }}</span
            >
          </div>
          <div
            class="col-md-3"
            *ngIf="DrData?.Voucher_Mode_of_Payment == 'Cheque'"
          >
            <span class="fs-12"
              ><B>Bank Name :</B>{{ DrData.Voucher_Bank_Name }}</span
            >
          </div>
          <div
            class="col-md-3"
            *ngIf="
              !!DrData?.Voucher_Mode_of_Payment &&
              DrData?.Voucher_Mode_of_Payment !== 'Cheque'
            "
          >
            <span class="fs-12"
              ><B>Transaction Date :</B>
              {{ DrData.Voucher_E_Transaction_Date }}</span
            >
          </div>
          <div
            class="col-md-3"
            *ngIf="
              !!DrData?.Voucher_Mode_of_Payment &&
              DrData?.Voucher_Mode_of_Payment !== 'Cheque'
            "
          >
            <span class="fs-12"
              ><B>Reference No :</B>{{ DrData.Voucher_E_Ref_No }}
            </span>
          </div>
          <div class="col-md-4" *ngIf="Status == 'Rejected'">
            <span class="fs-12"
              ><B>Reject Reason :</B>{{ DrData.Reason }}
            </span>
          </div>
          <div class="col-md-3">
            <span class="fs-12"
              ><B>{{ Status == "Pending" ? "Created" : Status }} By :</B
              >{{ DrData.Voucher_CreateBy }}
            </span>
          </div>
          <div class="col-md-3">
            <span class="fs-12"
              ><B>{{ Status == "Pending" ? "Created" : Status }} On :</B
              >{{ DrData.Voucher_CreateOn }}
            </span>
          </div>

          <div class="col-md-12">
            <span class="fs-12"><B>Remark :</B>{{ DrData.Narration }} </span>
          </div>
        </div>
      </div>
      <div
        class="modal-footer"
        *ngIf="
          dataSourceCustomer?.data.length > 0 &&
          dataSourceCustomer?.data[0]['Status'] !== 'Rejected' &&
          dataSourceCustomer?.data[0]['Status'] !== 'Approved'
        "
      >
        <button
          (click)="onApproval(DrData.vid)"
          type="button"
          class="btn btn-success"
        >
          <i class="fa fa-check" aria-hidden="true"></i> Approve
        </button>
        <button
          (click)="onRejectedDone(DrData.vid)"
          type="button"
          class="btn btn-danger"
        >
          <i class="fa fa-times" aria-hidden="true"></i> Reject
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="RejectModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered model" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Reject Detail
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseRejectModel()"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form #f="ngForm" novalidate>
          <div style="max-height: 330px; max-width: 100%; overflow: auto">
            <div class="row m-0 mt-2">
              <div class="col-md-12 p-1">
                <span class="required-lable">Reject Reason </span>
                <textarea
                  [(ngModel)]="Reason"
                  rows="1"
                  maxlength="150"
                  #refReason="ngModel"
                  required
                  [ngClass]="{
                    'is-invalid': f.submitted && refReason.invalid,
                    'alert-warning':
                      refReason.invalid &&
                      (refReason.dirty ||
                        refReason.touched ||
                        refReason.untouched)
                  }"
                  placeholder="Reason"
                  name="Reason"
                  id="Reason"
                  class="form-control input-text-css"
                ></textarea>
              </div>
              <div class="col-md-12 p-1 text-right">
                <button
                  (click)="onRejected()"
                  type="button"
                  class="btn btn-danger"
                  [disabled]="!f.form.valid"
                >
                  <i class="fa fa-times" aria-hidden="true"></i> Reject
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<table
  id="imd_auth"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher Sub Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Vocuher Tag
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Created By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Account Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Account
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td style="text-align: left">{{ x.Application_No }}</td>
    <td>{{ x.Voucher_Type }}</td>
    <td>{{ x.Voucher_Sub_Type }}</td>
    <td>{{ x.Voucher_Tag }}</td>
    <td>{{ x.Voucher_Date }}</td>
    <td>{{ x.CreatedBy }}</td>
    <td>{{ x.Account_Name }}</td>
    <td>{{ x.Amount }}</td>
  </tr>
</table>
