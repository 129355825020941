import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
declare var $: any;
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { constantUrl } from "../../Shared/constantUrl";
import { MasterService } from "../../Shared/app.Masters.Service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { LmsService } from '../services/lms.service';

@Component({
  selector: "app-lms-edit-financial-detail-model",
  templateUrl: "./lms-edit-financial-detail-model.component.html",
  styleUrls: ["./lms-edit-financial-detail-model.component.scss"],
})
export class LmsEditFinancialDetailModelComponent implements OnInit {
  currentUser: any;
  CurrentDate: any = new Date();
  showSpinner: boolean = false;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  @Input("applicationId") applicationId: any;
  @Input("isEdit") isEdit: boolean = false;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  productDropdown: any[] = [];
  dataSchemeSource: any;
  displayedSchemeColumns: string[] = [
    "SchemeId",
    "Scheme",
    "Amount",
    "ROI",
    "Period",
    "AdvanceEMI",
    "Select",
  ];
  selectedScheme: any = {};
  cEIModel: any = {};
  stepEMI: any[] = [];
  emiIrr: any;
  isFrequency: boolean = false;
  stepEmiData: any;
  loading: boolean = false;
  schemeArr: any[] = [];
  constructor(
    private dataSharingService: DataSharingService,
    private _MasterService: MasterService,
    private route: ActivatedRoute,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private losService: LosService,
    private LmsService: LmsService
  ) { }

  ngOnInit() {
    this.isFrequency = this.isEdit;
    //this.dataSharingService.LOSHeaderTitle.next('Customer Detail');
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  }
  onEditFinanceDetail() {
    this.showSpinner = true;
    this.stepEMI = [];
    this.productDropdown = [];
    this.emiIrr = undefined;
    this.stepEmiData = undefined;
    this.LmsService
      .FinancialDetailsForUpdate({ LoanAcNo: 'LOJPR00010' })
      .subscribe((res: any) => {
        console.log('res', res);
        this.showSpinner = false;
        let ApplicationData = res.Item1[0];
        this.cEIModel = {
          ProductId: ApplicationData.ProductId,
          Asset_Cost: ApplicationData.AssetCost,
          NetFinance_Amt: ApplicationData.NetFinance,
          Flat_Rate: ApplicationData.Flat_Rate,
          No_Of_Inst: ApplicationData.No_Of_Instl,
          Tenure: ApplicationData.Tenure,
          Adv_Inst: ApplicationData.Adv_Instl,
          MgmtFee: ApplicationData.ManagementFee,
          EMI_Type: ApplicationData.EMI_Type,
          First_EMI_Date: ApplicationData.FirstEMIDate
            ? new Date(ApplicationData.FirstEMIDate)
            : "",
          IRR_Type: ApplicationData.IRR_Type,
          Product: ApplicationData.Product,
          SchemeId: ApplicationData.SchemeId,
          IRR_CalculateBy: ApplicationData.IRR_CalculateBy,
          EMI_Amount: ApplicationData.EMIAmount,
        };
        let i = 1;
        res.Item2.forEach((obj: any) => {
          this.stepEMI.push({
            FromEMI: obj.FromEMI,
            ToEMI: obj.ToEMI,
            EMI_Amount: obj.EMIAmount,
            NoOfInstl: obj.NoOfEMI,
            TotalAmount: obj.TotalAmount,
            disable: i != res.Item2.length,
          });
          i++;
        });

        this.emiIrr = {
          Disbursement_Amt: ApplicationData.DisbursementAmt,
          Interest_Amt: ApplicationData.InterestAmt,
          Agreement_Value: ApplicationData.AgreementValue,
          LTV: ApplicationData.LTV,
          EMI_Amt: ApplicationData.EMIAmount,
          Case_IRR: ApplicationData.Case_IRR,
          Disbursement_IRR: ApplicationData.Disbursement_IRR,
          Margin: ApplicationData.Margin,
          ROI: ApplicationData.Flat_Rate,
        };

        // this.geSchemeList();

        this.getTotlaEmiAndAmount();
        // this.productDropdown.push({
        //   ProductId: this.cEIModel.ProductId,
        //   Product_Name: this.cEIModel.Product,
        // });
        $("#updateFinancialDetail").modal("show");
        $("#updateFinancialDetail").css("z-index", "1050");
      });
  }
  OnFinancialDetailClose() {
    $("#updateFinancialDetail").modal("hide");
    this.close.emit(false);
  }
  geSchemeList() {
    this.showSpinner = false;
    this.schemeArr = [];
    this._MasterService
      .LOS_GetSchemeToValidateLoan({ ProductId: this.cEIModel.ProductId })
      .subscribe((result: any) => {
        this.schemeArr = JSON.parse(JSON.stringify(result));
        this.dataSchemeSource = new MatTableDataSource(
          JSON.parse(JSON.stringify(result))
        );
        this.selectedScheme =
          result.find((obj: any) => {
            return obj.SchemeId == this.cEIModel.SchemeId;
          }) || {};
        // this.onCalculateEmiAndIRR();
        this.showSpinner = false;
      });
  }
  onCheckboxChange(e: any, data: any) {
    if (this.selectedScheme.SchemeId == data.SchemeId) {
      this.selectedScheme = {};
    } else {
      this.selectedScheme = data;
      this.cEIModel.Adv_Inst = data.AdvanceEMI;
    }
  }
  onCalculateEmiAndIRR() {
    let netAmount: any[] = [];
    let roi: any[] = [];
    if (+this.cEIModel.ProductId <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please select a product.",
        ...this.configSuccess,
      });
      return;
    }
    // if (+this.cEIModel.Asset_Cost <= 0) {
    //     this.snackBar.openFromComponent(SnackbarComponent, { data: "Asset Cost should be greater than 0.", ...this.configSuccess });
    //     return;
    // }
    if (+this.cEIModel.NetFinance_Amt <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Net Finance Amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.Flat_Rate < 0 &&
      this.cEIModel.IRR_CalculateBy == "ROI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Flate rate should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.EMI_Amount <= 0 &&
      this.cEIModel.IRR_CalculateBy == "FLAT_EMI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMI amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.No_Of_Inst <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No of Installment should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.Tenure <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Tenure should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      this.cEIModel.IRR_CalculateBy == "FLAT_EMI" &&
      +this.cEIModel.EMI_Amount * +this.cEIModel.No_Of_Inst <
      +this.cEIModel.NetFinance_Amt
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data:
          "No of Installment multiply by EMI amount should be greater than or equal to Net Finance Amount.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.selectedScheme.Amount) {
      netAmount = this.selectedScheme.Amount.split("-");
      roi = this.selectedScheme.ROI.split("-");
      if (
        +this.cEIModel.NetFinance_Amt > +netAmount[1] ||
        +this.cEIModel.NetFinance_Amt < +netAmount[0]
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Net Finance Amount should be in between Scheme Amount.",
          ...this.configSuccess,
        });
        return;
      }
      if (
        this.cEIModel.IRR_CalculateBy == "ROI" &&
        (+this.cEIModel.Flat_Rate > +roi[1] ||
          +this.cEIModel.Flat_Rate < +roi[0])
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Flate Rate should be in between Scheme ROI.",
          ...this.configSuccess,
        });
        return;
      }
      if (+this.cEIModel.Adv_Inst < +this.selectedScheme.AdvanceEMI) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Advance Installment can not be less than Scheme Advance EMI.",
          ...this.configSuccess,
        });
        return;
      }
      // if (+this.cEIModel.NetFinance_Amt < +this.selectedScheme.Asset_Cost) {
      //     this.snackBar.openFromComponent(SnackbarComponent, { data: "Asset Cost should be less than Net Finance Amount.", ...this.configSuccess });
      //     return;
      // }
      this.calculateEmiIrr();
    } else {
      // if (+this.cEIModel.NetFinance_Amt < +this.selectedScheme.Asset_Cost) {
      //     this.snackBar.openFromComponent(SnackbarComponent, { data: "Asset Cost should be less than Net Finance Amount.", ...this.configSuccess });
      //     return;
      // }
      this.calculateEmiIrr();
    }
  }
  calculateEmiIrr() {
    let stepArr: any[] = [];
    if (this.cEIModel.IRR_CalculateBy == "STEP_EMI") {
      this.cEIModel.IsStep = true;
      if (this.stepEMI.length < 1) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please add step EMI first",
          ...this.configSuccess,
        });
        return;
      }
      let lastEMI = this.stepEMI[this.stepEMI.length - 1].ToEMI;
      if (+lastEMI != +this.cEIModel.No_Of_Inst) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Total EMI Should Be Equal To No of Installment",
          ...this.configSuccess,
        });
        return;
      }
      if (this.stepEmiData != undefined && +this.stepEmiData.totalAmount < +this.cEIModel.NetFinance_Amt) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data:
            "Total EMI Amount should be  equal or Greater then  Net Finance Amount.",
          ...this.configSuccess,
        });
        return;
      }
      for (let i = 0; i < this.stepEMI.length; i++) {
        for (
          let j = +this.stepEMI[i].FromEMI;
          j <= +this.stepEMI[i].ToEMI;
          j++
        ) {
          if (+this.stepEMI[i].EMI_Amount >= 0) {
            //if (this.cEIModel.EMI_Type == "ByMonthly") {
            //  stepArr.push(0);
            //}
            //if (this.cEIModel.EMI_Type == "Quarterly") {
            //  stepArr.push(0);
            //  stepArr.push(0);
            //}
            //if (this.cEIModel.EMI_Type == "HalfYearly") {
            //  stepArr.push(0);
            //  stepArr.push(0);
            //  stepArr.push(0);
            //  stepArr.push(0);
            //  stepArr.push(0);
            //}
            //if (this.cEIModel.EMI_Type == "Yearly") {
            //  stepArr.push(0);
            //  stepArr.push(0);
            //  stepArr.push(0);
            //  stepArr.push(0);
            //  stepArr.push(0);
            //  stepArr.push(0);
            //  stepArr.push(0);
            //  stepArr.push(0);
            //  stepArr.push(0);
            //  stepArr.push(0);
            //  stepArr.push(0);
            //}
            stepArr.push(this.stepEMI[i].EMI_Amount);
          }
        }
      }
    } else {
      this.cEIModel.IsStep = false;
      this.cEIModel.STEP_IRR = 0;
    }
    let _data = { ...this.cEIModel };
    _data.cashflow = stepArr;
    if (this.cEIModel.IsStep) {
      _data.EMI_Amount = this.stepEmiData.totalAmount;
      //this.showSpinner = true;
      //this.losService
      //  .IRRcalculation({
      //    cashflow: stepArr,
      //    amount: this.cEIModel.NetFinance_Amt,
      //  })
      //  .subscribe((res: any) => {
      //    this.showSpinner = false;
      //    this.cEIModel.STEP_IRR = res;
      //    let _data = { ...this.cEIModel };
      //    _data.EMI_Amount = this.stepEmiData.totalAmount;
      //    this.getCalculateEMIIRR(_data);
      //  });
    }
    //else {
    //  this.getCalculateEMIIRR(this.cEIModel);
    //}
    this.getCalculateEMIIRR(_data);
  }
  getCalculateEMIIRR(data: any) {
    this.showSpinner = true;
    this.LmsService.GetEMICalculate(data).subscribe((res: any) => {
      this.showSpinner = false;
      this.emiIrr = res[0];
      setTimeout(() => {
        var elmntToView = document.getElementById("scrollto");
        elmntToView.scrollIntoView();
      }, 500);
    });
  }
  handleByChange(event: any) {
    this.emiIrr = undefined;
    if (this.cEIModel.IRR_CalculateBy == "ROI") {
      delete this.cEIModel.EMI_Amount;
    } else {
      delete this.cEIModel.Flat_Rate;
    }

    if (this.cEIModel.IRR_CalculateBy !== "STEP_EMI") {
      this.stepEMI = [];
    } else {
      this.stepEMI.push({
        FromEMI: 1,
        ToEMI: "",
        EMI_Amount: "",
        disable: false,
      });
      this.stepEmiData = undefined;
    }
  }
  setTenure() {
    if (this.cEIModel.No_Of_Inst && this.cEIModel.EMI_Type != "SingleEMI") {
      if (this.cEIModel.EMI_Type == "ByMonthly") {
        this.cEIModel.Tenure = 2 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Quarterly") {
        this.cEIModel.Tenure = 3 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "HalfYearly") {
        this.cEIModel.Tenure = 6 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Yearly") {
        this.cEIModel.Tenure = 12 * +this.cEIModel.No_Of_Inst;
      } else {
        this.cEIModel.Tenure = this.cEIModel.No_Of_Inst;
      }
    }
    this.setAdvanceInstl();
  }
  setAdvanceInstl() {
    if (Number(this.cEIModel.Adv_Inst) >= Number(this.cEIModel.No_Of_Inst)) {
      this.cEIModel.Adv_Inst = 0;
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Advance Installment not greater than No. Of Installment !!!", ...this.configSuccess });

    }
  }
  handleChange(event: any) {
    if (!this.isEdit) {
      event.preventDefault();
      return;
    }
    this.emiIrr = undefined;
    if (this.cEIModel.IRR_Type == "Flat") {
      this.stepEMI = [];
    } else {
      this.stepEMI.push({
        FromEMI: 1,
        ToEMI: "",
        EMI_Amount: "",
        disable: false,
      });
      this.stepEmiData = undefined;
    }
  }
  addNextStep(data: any) {
    if (+data.ToEMI <= 0 || +data.EMI_Amount < 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Fill All Data.",
        ...this.configSuccess,
      });
      return;
    } else if (+data.ToEMI > +this.cEIModel.No_Of_Inst) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "To Emi can not be greater than number of installment.",
        ...this.configSuccess,
      });
      return;
    }
    // if (+data.ToEMI == +this.cEIModel.No_Of_Inst) {
    //     this.snackBar.openFromComponent(SnackbarComponent, { data: "Another Step can not be added. You can add till number of installment.", ...this.configSuccess });
    //     return;
    // }
    else {
      if (+data.FromEMI > +data.ToEMI) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "To EMI should be greater than From EMI.",
          ...this.configSuccess,
        });
        return;
      }
      data.NoOfInstl = +data.ToEMI - +data.FromEMI + 1;
      data.TotalAmount = data.NoOfInstl * +data.EMI_Amount;
      if (+data.ToEMI < +this.cEIModel.No_Of_Inst) {
        data.disable = true;
        this.stepEMI.push({
          FromEMI: +data.ToEMI + 1,
          ToEMI: "",
          EMI_Amount: "",
          disable: false,
        });
      }
      this.getTotlaEmiAndAmount();
    }
  }
  getTotlaEmiAndAmount() {
    //if (this.stepEMI.length > 1) {
    let TotalEmi: any = 0,
      TotalAmount: any = 0;
    for (let i = 0; i < this.stepEMI.length; i++) {
      if (this.stepEMI[i].EMI_Amount) {
        TotalEmi =
          TotalEmi + (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1);
        TotalAmount =
          TotalAmount +
          (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1) *
          +this.stepEMI[i].EMI_Amount;
      }
    }
    this.stepEmiData = { totalEmi: TotalEmi, totalAmount: TotalAmount };
    // }
  }
  removeLastStep(index: any) {
    this.stepEMI.splice(index, 1);
    this.stepEMI[index - 1].disable = false;
    this.getTotlaEmiAndAmount();
  }
  onSaveFinancialDetail() {
    // if (+this.cEIModel.Asset_Cost <= 0) {
    //     this.snackBar.openFromComponent(SnackbarComponent, { data: "Asset Cost should be greater than 0.", ...this.configSuccess });
    //     return;
    // }
    if (+this.cEIModel.NetFinance_Amt <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Net Finance Amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }

    if (
      +this.cEIModel.Flat_Rate < 0 &&
      this.cEIModel.IRR_CalculateBy == "ROI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Flate rate should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.EMI_Amount <= 0 &&
      this.cEIModel.IRR_CalculateBy == "EMI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMI amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.No_Of_Inst <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No of Installment should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.Tenure <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Tenure should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.cEIModel.IRR_CalculateBy !== "STEP_EMI" &&
      +this.cEIModel.No_Of_Inst * +this.emiIrr.EMI_Amt <
      +this.cEIModel.NetFinance_Amt
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data:
          "EMI multiply with installment should be equal or grater than net finance.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.cEIModel.IRR_Type == "Step") {
      let length = this.stepEMI.length;
      let ToEmi = this.stepEMI[length - 1].ToEMI;
      if (+ToEmi != +this.cEIModel.No_Of_Inst) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Add EMI step.",
          ...this.configSuccess,
        });
        return;
      }
      if (
        this.emiIrr.Disbursement_Amt + this.emiIrr.Interest_Amt !=
        +this.stepEmiData.totalAmount
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data:
            "Total EMI Amount must be equal to  (Disbursement Amount  + Interest Amount)",
          ...this.configSuccess,
        });
        return;
      }
    }
    let netAmount: any[] = [];
    let roi: any[] = [];
    if (this.selectedScheme.Amount) {
      netAmount = this.selectedScheme.Amount.split("-");
      roi = this.selectedScheme.ROI.split("-");
      if (
        +this.cEIModel.NetFinance_Amt > +netAmount[1] ||
        +this.cEIModel.NetFinance_Amt < +netAmount[0]
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Net Finance Amount should be in between Scheme Amount.",
          ...this.configSuccess,
        });
        return;
      }
      if (
        this.cEIModel.IRR_CalculateBy == "EMI" &&
        (+this.cEIModel.Flat_Rate > +roi[1] ||
          +this.cEIModel.Flat_Rate < +roi[0])
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Flate Rate should be in between Scheme ROI.",
          ...this.configSuccess,
        });
        return;
      }
      if (+this.cEIModel.Adv_Inst < +this.selectedScheme.AdvanceEMI) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Advance Installment can not be less than Scheme Advance EMI.",
          ...this.configSuccess,
        });
        return;
      }
      // if (+this.cEIModel.NetFinance_Amt < +this.selectedScheme.Asset_Cost) {
      //     this.snackBar.openFromComponent(SnackbarComponent, { data: "Asset Cost should be less than Net Finance Amount.", ...this.configSuccess });
      //     return;
      // }
    }

    if (this.emiIrr.Interest_Amt == null || this.emiIrr.Interest_Amt == 0 || this.emiIrr.Interest_Amt == '') {
      this.emiIrr.Interest_Amt = 0
      this.emiIrr.Case_IRR = 0;
      this.emiIrr.Disbursement_IRR = 0;

    }
    let data = {
      Application: {
        ApplicationId: this.applicationId,
        Asset_Cost: this.cEIModel.Asset_Cost,
        NetFinance_Amt: this.cEIModel.NetFinance_Amt,
        Flat_Rate: this.cEIModel.Flat_Rate,
        No_Of_Inst: this.cEIModel.No_Of_Inst,
        Tenure: this.cEIModel.Tenure,
        Adv_Inst: this.cEIModel.Adv_Inst,
        MgmtFee: 0,  // this.cEIModel.MgmtFee,
        EMI_Type: this.cEIModel.EMI_Type,
        Disbursement_Amt: this.emiIrr.Disbursement_Amt,
        Interest_Amt: this.emiIrr.Interest_Amt,
        Agreement_Value: this.emiIrr.Agreement_Value,
        LTV: this.emiIrr.LTV,
        EMI_Amt: this.emiIrr.EMI_Amt,
        Case_IRR: this.emiIrr.Case_IRR,
        Disbursement_IRR: this.emiIrr.Disbursement_IRR,
        Loan_SchemeId: this.selectedScheme.SchemeId,
        FirstEMIDate: new Date(), // this.cEIModel.First_EMI_Date,
        IRR_Type: this.cEIModel.IRR_Type,
        LoginUserId: this.currentUser.userId,
        ProductId: this.cEIModel.ProductId,
        ROI: this.emiIrr.ROI,
        EMI_Amount: this.cEIModel.EMI_Amount,
        IRR_CalculateBy: this.cEIModel.IRR_CalculateBy,
      },
      StepIRR: this.stepEMI,
    };
    this.losService
      .LOS_UpdateApplicationFinancialDetails({ JSON: JSON.stringify(data) })
      .subscribe((res: any) => {
        this.loading = false;
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.close.emit(true);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  setType(event: any) {
    if (this.cEIModel.IRR_CalculateBy == "STEP_EMI") {
    
      this.cEIModel.EMI_Type = 'Monthly';
      this.cEIModel.Tenure = this.cEIModel.No_Of_Inst;
    }
    if (this.cEIModel.IRR_CalculateBy == 'Redusing_ROI')
      this.isFrequency = false;
    else
      this.isFrequency = this.isEdit;
    //if (this.cEIModel.Type == 'EMIAmt')
    //  this.cEIModel.IRR_CalculateBy = 'FLAT_EMI'
    //if (this.cEIModel.Type == 'StepEMI')
    //  this.cEIModel.IRR_CalculateBy = 'STEP_EMI'

    this.emiIrr = undefined;
    if (this.cEIModel.IRR_CalculateBy == "ROI") {
      delete this.cEIModel.EMI_Amount;
    } else {
      delete this.cEIModel.Flat_Rate;
    }

    if (this.cEIModel.IRR_CalculateBy !== "STEP_EMI") {
      this.stepEMI = [];
    } else {
      this.stepEMI.push({
        FromEMI: 1,
        ToEMI: "",
        EMI_Amount: "",
        disable: false,
      });
      this.stepEmiData = undefined;
    }
  }
}
