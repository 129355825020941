import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import * as moment from "moment";
import { ActivatedRoute, Params } from "@angular/router";

// Excel export code start here
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

// Excel export code end here

@Component({
  selector: "app-day-book",
  templateUrl: "./day-book.component.html",
  styleUrls: ["./day-book.component.scss"],
})
export class DayBookComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  displayedColumns: string[] = [
    "Row_Num",
    "Voucher_Date",
    "Voucher_Type",
    "Voucher_No",
    "Voucher_Narration",
    "CaseNo",
    "Account_Name",
    "TransDR",
    "TransCR",
  ];
  VoucherDateFrom: any = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  VoucherDateTo: any = new Date();
  maxDate: any;
  minDate: any;
  maxDateTo: any;
  FyearId: any;
  dataSource: any;

  vocuherTypeDropdown: any = "";
  @ViewChild(MatPaginator) paginator: MatPaginator;
  TotRow: any = 50;
  JsonData: any[] = [];

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild("paginatorlist", { read: MatPaginator })
  // paginatorlist: MatPaginator;/
  @ViewChild("SortTable1", { read: MatSort }) sort1: MatSort;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (this.sort != undefined) {
      this.dataSource.sort = this.sort;
    }
  }

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private lmsService: LmsService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Day Book");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      console.log("paran", this.FyearId);
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.minDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          this.maxDate = new Date(moment(res[0].fyent).format("MM/DD/YYYY"));

          if (this.maxDate > new Date() && this.minDate < new Date())
            this.VoucherDateTo = new Date();
          else this.VoucherDateTo = this.maxDate;
          this.dataSource = null;
        });
    });
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }
  }

  updateTableData(data: any[]) {
    this.dataSource = new MatTableDataSource(data);
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
      this.paginator.firstPage(); // Reset to the first page
    }
  }

  ngAfterViewChecked() {
    // console.log('DataSource:', this.dataSource);
    // console.log('Paginator:', this.paginator);

    if (!this.dataSource?.paginator && this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
  }

  DateChange() {
    if (this.VoucherDateFrom > this.VoucherDateTo) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "VoucherDate To greater than VoucherDate From.",
        ...this.configSuccess,
      });
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.VoucherDateFrom = new Date(
            moment(res[0].fystart).format("MM/DD/YYYY")
          );
          this.VoucherDateTo = new Date();
        });
    }

    if (this.VoucherDateFrom) {
      const dateFrom = new Date(this.VoucherDateFrom);
      const maxDate = new Date(dateFrom);
      maxDate.setDate(maxDate.getDate() + 31);
      this.maxDateTo = maxDate;

      if (this.VoucherDateTo > maxDate) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Report can be viewed for 31 days maximum. Please type the date accordingly.",
          ...this.configSuccess,
        });

        // this.VoucherDateTo = maxDate;
      }
    }

    // console.log("Max Date To:", this.maxDateTo);
  }
  // getDayBookData(Addmore: boolean) {
  //   let NextCnt = 0;

  //   if (Addmore == false) {
  //     this.JsonData = [];
  //   }

  //   if (this.dataSource != null && this.dataSource.filteredData.length > 0) {
  //     NextCnt = this.dataSource.filteredData.length + 1;
  //   }
  //   this.showSpinner = true;
  //   this.lmsService
  //     .Acc_Day_Book({
  //       From: this.VoucherDateFrom,
  //       To: this.VoucherDateTo,
  //       TotalRow: this.TotRow,
  //       RowFrom: NextCnt,
  //       Voucher_Type: this.vocuherTypeDropdown,
  //     })
  //     .subscribe((res: any) => {
  //       console.log(res);
  //       if (res.length > 0) {
  //         this.JsonData = res;
  //         //this.ResData = JSON.parse(JSON.stringify(res));
  //         var ResData = JSON.parse(JSON.stringify(res));
  //         for (let i = 0; i < ResData.length; i++) {
  //           this.JsonData.push(ResData[i]);
  //         }

  //         this.dataSource = new MatTableDataSource(this.JsonData);
  //         this.dataSource.sort = this.sort;
  //         this.dataSource.paginator = this.paginator;
  //         this.paginator.firstPage();
  //         this.showSpinner = false;
  //       } else {
  //         this.snackBar.openFromComponent(SnackbarComponent, {
  //           data: "No data found",
  //           ...this.configSuccess,
  //         });
  //         this.showSpinner = false;
  //       }
  //     });
  // }

  async getDayBookData(Addmore: boolean) {
    let NextCnt = 0;

    if (Addmore === false) {
      this.JsonData = [];
    }

    if (this.dataSource != null && this.dataSource.filteredData.length > 0) {
      NextCnt = this.dataSource.filteredData.length + 1;
    }

    this.showSpinner = true;

    try {
      const res: any = await this.lmsService
        .Acc_Day_Book({
          From: this.VoucherDateFrom,
          To: this.VoucherDateTo,
          TotalRow: this.TotRow,
          RowFrom: NextCnt,
          Voucher_Type: this.vocuherTypeDropdown,
        })
        .toPromise(); // Use firstValueFrom if on RxJS v7+

      console.log(res);

      if (res.length > 0) {
        this.JsonData = res;
        // Deep copy the response data
        const ResData = JSON.parse(JSON.stringify(res));

        for (let i = 0; i < ResData.length; i++) {
          this.JsonData.push(ResData[i]);
        }

        this.dataSource = new MatTableDataSource(this.JsonData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.paginator.firstPage();
        this.showSpinner = false;
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "No data found",
          ...this.configSuccess,
        });
        this.showSpinner = false;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // this.snackBar.openFromComponent(SnackbarComponent, {
      //   data: "An error occurred while fetching data",
      //   ...this.configError,
      // });
      this.showSpinner = false;
    }
  }

  // LoadMore() {
  //   this.getDayBookData(true);
  // }

  // Excel export in backend start here
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      excelFileName + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  applyFilter(filterValue: any) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportExcel(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("day_book") as HTMLTableElement;
    const worksheetName = "DAY BOOK "; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
