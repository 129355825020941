<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div
    class=" row formborder ml-1"
    style="display: flex; align-items: center; gap: 20px"
  >
    <div  *ngIf="isStepAvailable('RC')">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="verifyRCVerificationModal('RC')"
      >
        RC Verification
      </button>
    </div>

    <div  *ngIf="isStepAvailable('Aadhaar_generate_otp')">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="verifyAadharVerificationModal('Aadhaar_generate_otp')"
      >
        Aadhar Card
      </button>
    </div>

    <div  *ngIf="isStepAvailable('PAN_Verification')">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="verifyPanVerificationModal('PAN_Verification')"
      >
        PAN Verification
      </button>
    </div>

    <div  *ngIf="isStepAvailable('Electricity')">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="verifyElectricityVerificationModal('Electricity')"
      >
        Electricity Verification
      </button>
    </div>

    <div *ngIf="isStepAvailable('Bank_Verification')">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="verifyBankVerificationModal('Bank_Verification')"
      >
        Bank Verification
      </button>
    </div>

    <div  *ngIf="isStepAvailable('License')">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="verifyDLVerificationModal('License')"
      >
        DL Verification
      </button>
    </div>

    <div  *ngIf="isStepAvailable('Voter_ID')">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="verifyVoterIdVerificationModal('Voter_ID')"
      >
        Voter Id Verification
      </button>
    </div>
  </div>
</div>

<!-- RC VERIFICATIONNNN -->

<div
  class="modal fade in"
  id="rcVerificationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          RC Verification
        </h6>
        <button
          type="button"
          (click)="onCloseRcVerificationModal()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12 row formborder p-1 ml-1">
          <div class="col-md-3 p-1">
            <span class="">Vehicle Registation No</span>
            <input
              type="text"
              #refVehicleRegistationNo="ngModel"
              (keypress)="onKeyPress($event)"
              [(ngModel)]="VehicleRegistationNo"
              name="VehicleRegistationNo"
              id="VehicleRegistationNo"
              class="form-control input-text-css"
              placeholder="Vehicle Registation No"
            />
          </div>
          <div class="col-md-3 p-1">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="verifyCustomerRC()"
            >
              RC Verification
            </button>
          </div>

          <div class="col-md-3 p-1">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="onDocsPrint('rcPrint', 'RC')"
              *ngIf="
                VehicleRegistationNo != '' &&
                RCVerified_Button &&
                (RCKYC_IsVerified == 1 || RCLastVerfiedDate)
              "
            >
              Print RC
            </button>
          </div>
        </div>

        <div class="mainDiv">
          <div
            class="row mt-2 formborder ml-2 mr-2 mt-2"
            style="background-color: #d2ebec"
            *ngIf="
              VehicleRegistationNo != '' &&
              RCVerified_Button &&
              (RCKYC_IsVerified == 1 || RCLastVerfiedDate)
            "
            id="rcPrint"
          >
            <div class="col-md-12 bg-success1 printStyle">Verified RC Data</div>

            <div class="col-md-2 border-div">
              <h6>Vehicle Registation No</h6>
              <p style="font-weight: 800">
                {{ APIResponse.DoucumentNumber }}
              </p>
            </div>

            <div class="col-md-2 border-div">
              <h6>Fit Up To</h6>
              <p>
                {{ APIResponse.fit_up_to }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Registration Date</h6>
              <p>
                {{ APIResponse.registration_date }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Owner Name</h6>
              <p>
                {{ APIResponse.owner_name }}
              </p>
            </div>

            <div class="col-md-2 border-div">
              <h6>Father Name</h6>
              <p>
                {{ APIResponse.father_name }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Mobile Number</h6>
              <p>
                {{ APIResponse.mobile_number }}
              </p>
            </div>

            <div class="col-md-2 border-div">
              <h6>Vehicle Category</h6>
              <p>
                {{ APIResponse.vehicle_category }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Vehicle Chasi Number</h6>
              <p>
                {{ APIResponse.vehicle_chasi_number }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Vehicle Engine Number</h6>
              <p>
                {{ APIResponse.vehicle_engine_number }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Maker Description</h6>
              <p>
                {{ APIResponse.maker_description }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Maker Model</h6>
              <p>
                {{ APIResponse.maker_model }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Body Type</h6>
              <p>
                {{ APIResponse.body_type }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Fuel Type</h6>
              <p>
                {{ APIResponse.fuel_type }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Color</h6>
              <p>
                {{ APIResponse.color }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Norms Type</h6>
              <p>
                {{ APIResponse.norms_type }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Financer</h6>
              <p>
                {{ APIResponse.financer }}
              </p>
            </div>

            <div class="col-md-2 border-div">
              <h6>financed</h6>
              <p>
                {{ APIResponse.financed }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Insurance Company</h6>
              <p>
                {{ APIResponse.insurance_company }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Insurance Policy Number</h6>
              <p>
                {{ APIResponse.insurance_policy_number }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Insurance Upto</h6>
              <p>
                {{ APIResponse.insurance_upto }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Manufacturing Date</h6>
              <p>
                {{ APIResponse.manufacturing_date }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Registered At</h6>
              <p>
                {{ APIResponse.registered_at }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Tax Upto</h6>
              <p>
                {{ APIResponse.tax_upto }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Tax Paid Upto</h6>
              <p>
                {{ APIResponse.tax_paid_upto }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Seat Capacity</h6>
              <p>
                {{ APIResponse.seat_capacity }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Vehicle Category Description</h6>
              <p>
                {{ APIResponse.vehicle_category_description }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Pucc Number</h6>
              <p>
                {{ APIResponse.pucc_number }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Pucc Upto</h6>
              <p>
                {{ APIResponse.pucc_upto }}
              </p>
            </div>
            <div class="col-md-2 border-div">
              <h6>Owner Number</h6>
              <p>
                {{ APIResponse.owner_number }}
              </p>
            </div>
            <div class="col-md-12 border-div">
              <h6>Permanent Address</h6>
              <p>
                {{ APIResponse.permanent_address }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- PAN VERIFICATIONNN -->

<div
  class="modal fade in"
  id="panVerificationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          PAN Verification
        </h6>
        <button
          type="button"
          (click)="onCloseRcVerificationModal()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #dlf="ngForm"
          (ngSubmit)="onVerifiedPanNo()"
          novalidate
          class="col-md-12 row m-0 p-0 formborder"
        >
          <div class="row m-0">
            <div class="col-md-12">
              <span class="required-lable">Enter PAN Number</span>
              <input
                type="text"
                #refVehicleRegistationNo="ngModel"
                [(ngModel)]="panNumber"
                name="panNumber"
                id="panNumber"
                class="form-control input-text-css"
                placeholder="Enter PAN Number"
              />
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button
              type="button"
              (click)="onVerifiedPanNo()"
              class="ml-4 btn font-size-12 button-btn"
              [disabled]="!dlf.form.valid"
            >
              Verify
            </button>
          </div>
        </form>

        <div
          class="card-body formborder mt-2"
          *ngIf="APIResponse.KYC_DocId == 1"
        >
          <div class="text-center" style="font-weight: bolder">
            {{ APIResponse.DoucumentNumber }}
          </div>
          <h6 class="card-title">Name:</h6>
          <p class="card-text">{{ APIResponse.name }}</p>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ELECTRICITY VERFICATIONN -->

<div
  class="modal fade in"
  id="electricityModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Electricity Verification
        </h6>
        <button
          type="button"
          (click)="onCloseRcVerificationModal()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #elf="ngForm"
          (ngSubmit)="onVerifiedElectricity()"
          novalidate
          class="col-md-12 row m-0 p-0 formborder"
        >
          <div class="row m-0">
            <div class="col-md-12">
              <span class="required-lable">Enter Electricity Number</span>
              <input
                type="text"
                #refelectricityNumber="ngModel"
                [(ngModel)]="electricityNumber"
                name="electricityNumber"
                id="electricityNumber"
                class="form-control input-text-css"
                placeholder="Enter Electricity Number"
              />
            </div>

            <div class="col-md-12">
              <span class="required-lable">Select State</span>
              <select
                name="ELState"
                id="ELState"
                [(ngModel)]="ELState"
                #refELState="ngModel"
                class="form-control input-text-css"
                required
                (change)="getElectricityOpCodelist()"
                [ngClass]="{
                  'is-invalid': elf.submitted && refELState.invalid,
                  'alert-warning':
                    refELState.invalid &&
                    (refELState.dirty ||
                      refELState.touched ||
                      refELState.untouched)
                }"
              >
                <option value="">Select State</option>
                <option
                  [value]="cus.state"
                  *ngFor="let cus of ElectricityStatelist"
                >
                  {{ cus.state }}
                </option>
              </select>
            </div>
            <div class="col-md-12">
              <span class="required-lable">Select Operator Code</span>
              <select
                name="OperatorCode"
                id="OperatorCode"
                [(ngModel)]="OperatorCode"
                #refOperatorCode="ngModel"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': elf.submitted && refOperatorCode.invalid,
                  'alert-warning':
                    refOperatorCode.invalid &&
                    (refOperatorCode.dirty ||
                      refOperatorCode.touched ||
                      refOperatorCode.untouched)
                }"
              >
                <option value="">Select Operator Code</option>
                <option
                  [value]="cus.Operator_code"
                  *ngFor="let cus of ElectricityOpCodelist"
                >
                  {{ cus.Operator_code }}
                </option>
              </select>
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button
              type="button"
              (click)="onVerifiedElectricity()"
              class="ml-4 font-size-12 button-btn"
              [disabled]="!elf.form.valid"
            >
              Verify
            </button>
          </div>
        </form>

        <div
          class="card-body formborder mt-2"
          *ngIf="APIResponse.KYC_DocId == 2"
        >
          <div class="text-center" style="font-weight: bolder">
            {{ APIResponse.DoucumentNumber }}
          </div>
          <h6 class="card-title">Name:</h6>
          <p class="card-text">{{ APIResponse.Full_name }}</p>
          <br />
          <h6 class="card-title">Address:</h6>
          <p class="card-text">{{ APIResponse.address }}</p>
          <br />

          <h6 class="card-title">Mobile:</h6>
          <p class="card-text">{{ APIResponse.mobile }}</p>
          <br />
          <h6 class="card-title">State:</h6>
          <p class="card-text">{{ APIResponse.state }}</p>
          <br />
          <h6 class="card-title">Operator Code:</h6>
          <p class="card-text">{{ APIResponse.operator_code }}</p>
          <br />
          <h6 class="card-title">Status:</h6>
          <p class="card-text">
            {{
              APIResponse.message_code == "success"
                ? "Verified"
                : "NOT-Verified"
            }}
          </p>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BANK VERIFICATION -->

<div
  class="modal fade in"
  id="bankVerificationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Bank Verification Modal
        </h6>
        <button
          type="button"
          (click)="onCloseRcVerificationModal()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form #blf="ngForm" novalidate class="col-md-12 row m-0 p-0 formborder">
          <div class="row m-0">
            <div class="col-md-12">
              <span class="required-lable">Account Number</span>
              <input
                type="text"
                #refVerifyAccountNo="ngModel"
                [(ngModel)]="VerifyAccountNo"
                placeholder="Enter Account Number "
                required
                [ngClass]="{
                  'is-invalid': blf.submitted && refVerifyAccountNo.invalid,
                  'alert-warning':
                    refVerifyAccountNo.invalid &&
                    (refVerifyAccountNo.dirty ||
                      refVerifyAccountNo.touched ||
                      refVerifyAccountNo.untouched)
                }"
                name="VerifyAccountNo"
                id="VerifyAccountNo"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-12">
              <span class="required-lable">IFSC Code</span>
              <input
                type="text"
                #refVerifyIFSC="ngModel"
                [(ngModel)]="VerifyIFSC"
                placeholder="Enter IFSC "
                required
                [ngClass]="{
                  'is-invalid': blf.submitted && refVerifyIFSC.invalid,
                  'alert-warning':
                    refVerifyIFSC.invalid &&
                    (refVerifyIFSC.dirty ||
                      refVerifyIFSC.touched ||
                      refVerifyIFSC.untouched)
                }"
                name="VerifyIFSC"
                id="VerifyIFSC"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button
              type="button"
              (click)="onVerifiedBankDetail()"
              class="ml-4 font-size-12 button-btn"
              [disabled]="!blf.form.valid"
            >
              Verify
            </button>
          </div>
        </form>

        <div class="card-body formborder">
          <!-- [ngClass]="{ 'pending-danger': APIResponse.IsVerified == false }" -->
          <div class="text-center" style="font-weight: bolder">
            {{ APIResponse.DoucumentNumber }}
          </div>
          <h6 class="card-title">Remark:</h6>
          <p class="card-text">{{ APIResponse.Remark }}</p>
          <br />

          <h6 class="card-title">contact:</h6>
          <p class="card-text">{{ APIResponse.contact }}</p>
          <br />
          <h6 class="card-title">account_exists:</h6>
          <p class="card-text">{{ APIResponse.account_exists }}</p>
          <br />
          <h6 class="card-title">address:</h6>
          <p class="card-text">{{ APIResponse.address }}</p>
          <br />
          <h6 class="card-title">Name:</h6>

          <p class="card-text">
            {{ APIResponse.Full_name }}
          </p>

          <br />

          <h6 class="card-title">Bank Name:</h6>
          <p class="card-text">
            {{ APIResponse.bank_name }}
          </p>

          <br />
          <h6 class="card-title">branch:</h6>
          <p class="card-text">
            {{ APIResponse.branch }}
          </p>

          <br />

          <h6 class="card-title">micr:</h6>
          <p class="card-text">
            {{ APIResponse.micr }}
          </p>

          <br />
          <h6 class="card-title">ifsc:</h6>
          <p class="card-text">
            {{ APIResponse.ifsc }}
          </p>

          <br />
          <h6 class="card-title">rtgs:</h6>
          <p class="card-text">{{ APIResponse.rtgs }}</p>

          <br />
          <h6 class="card-title">neft:</h6>
          <p class="card-text">{{ APIResponse.neft }}</p>

          <br />
          <h6 class="card-title">imps:</h6>
          <p class="card-text">{{ APIResponse.imps }}</p>

          <br />
          <h6 class="card-title">message:</h6>
          <p class="card-text">{{ APIResponse.message }}</p>

          <br />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- DL VERIFICATION -->

<div
  class="modal fade in"
  id="drivingLicenseVerificationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Driving License Verification
        </h6>
        <button
          type="button"
          (click)="onCloseRcVerificationModal()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #dlf="ngForm"
          (ngSubmit)="onVerifiedDrivingLicence()"
          novalidate
          class="col-md-12 row m-0 p-0 formborder"
        >
          <div class="row m-0">
            <div class="col-md-12">
              <span class="required-lable">Enter License Number</span>
              <input
                type="text"
                #refVehicleRegistationNo="ngModel"
                [(ngModel)]="LicenseNumber"
                name="LicenseNumber"
                id="LicenseNumber"
                class="form-control input-text-css"
                placeholder="Enter License Number"
              />
            </div>

            <div class="col-md-12">
              <span class="required-lable"> Enter DOB</span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker5"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="DL_DOB"
                  id="DL_DOB"
                  #refDL_DOB="ngModel"
                  required
                  [ngClass]="{
                    'is-invalid': dlf.submitted && refDL_DOB.invalid,
                    'alert-warning':
                      refDL_DOB.invalid &&
                      (refDL_DOB.dirty ||
                        refDL_DOB.touched ||
                        refDL_DOB.untouched)
                  }"
                  [(ngModel)]="DL_DOB"
                  class="form-control input-text-css"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker5"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker5></mat-datepicker>
              </div>
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button
              type="button"
              (click)="onVerifiedDrivingLicence()"
              class="ml-4 btn font-size-12 button-btn"
              [disabled]="!dlf.form.valid"
            >
              Verify
            </button>
          </div>
        </form>

        <div
          class="card-body formborder mt-2"
          *ngIf="APIResponse.KYC_DocId == 5"
        >
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <img
              src="{{ 'data:image/jpg;base64,' + APIResponse.profile_image }}"
              *ngIf="APIResponse.KYC_DocId == 6 || APIResponse.KYC_DocId == 5"
              alt="Customer Image"
              class="mt-2"
              style="
                border-radius: 50%;
                width: 100px;
                height: 100px;
                margin: auto;
              "
            />
          </div>

          <div class="text-center" style="font-weight: bolder">
            {{ APIResponse.DoucumentNumber }}
          </div>
          <h6 class="card-title">Name:</h6>
          <p class="card-text">{{ APIResponse.Full_name }}</p>
          <br />
          <h6 class="card-title">Permanent address:</h6>

          <p class="card-text">{{ APIResponse.address }}</p>
          <br />
          <h6 class="card-title">DOB:</h6>
          <p class="card-text">{{ APIResponse.dob }}</p>
          <br />
          <h6 class="card-title">Issue Date:</h6>
          <p class="card-text">{{ APIResponse.doi }}</p>
          <br />
          <h6 class="card-title">Expiry Date:</h6>
          <p class="card-text">{{ APIResponse.doe }}</p>
          <br />
          <h6 class="card-title">Issued By:</h6>
          <p class="card-text">{{ APIResponse.ola_name }}</p>
          <br />

          <h6 class="card-title">vehicle classes:</h6>
          <p class="card-text">{{ APIResponse.vehicle_classes }}</p>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- VOTER ID VERIFICATION -->

<div
  class="modal fade in"
  id="voterIdModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Voter Id Verification Modal
        </h6>
        <button
          type="button"
          (click)="onCloseRcVerificationModal()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #dlf="ngForm"
          (ngSubmit)="onVerifiedVoterIDCard()"
          novalidate
          class="col-md-12 row m-0 p-0 formborder"
        >
          <div class="row m-0">
            <div class="col-md-12">
              <span class="required-lable">Enter Voter ID Number</span>
              <input
                type="text"
                #refvoterIdNumber="ngModel"
                [(ngModel)]="voterIdNumber"
                name="voterIdNumber"
                id="voterIdNumber"
                class="form-control input-text-css"
                placeholder="Enter Voter Id Number"
              />
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button
              type="button"
              (click)="onVerifiedVoterIDCard()"
              class="ml-4 btn font-size-12 button-btn"
              [disabled]="!dlf.form.valid"
            >
              Verify
            </button>
          </div>
        </form>

        <div
          class="card-body formborder mt-2"
          *ngIf="APIResponse.KYC_DocId == 4"
        >
          <div class="text-center" style="font-weight: 700">
            {{ APIResponse.DoucumentNumber }}
          </div>
          <h6 class="card-title">Name:</h6>
          <p class="card-text">{{ APIResponse.name }}</p>
          <br />
          <h6 class="card-title">Relation:</h6>
          <p class="card-text">
            {{ APIResponse.relation_type + " " }}
            {{ APIResponse.relation_name }}
          </p>
          <br />

          <h6 class="card-title">age:</h6>
          <p class="card-text">{{ APIResponse.age }}</p>
          <br />
          <h6 class="card-title">Gender:</h6>
          <p class="card-text">
            {{ APIResponse.gender == "F" ? "Female" : "Male" }}
          </p>
          <br />
          <h6 class="card-title">Area:</h6>
          <p class="card-text">
            {{
              APIResponse.area +
                " " +
                APIResponse.dist +
                " " +
                APIResponse.state
            }}
          </p>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- AADHAR CARD VERIFICATION MODAL -->

<div
  class="modal fade in"
  id="aadharCardVerification"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Aadhar Card Verification Modal
        </h6>
        <button
          type="button"
          (click)="onCloseRcVerificationModal()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #elf="ngForm"
          (ngSubmit)="onVerifiedAadharCard()"
          novalidate
          class="col-md-12 row m-0 p-0 formborder"
        >
          <div class="row m-0">
            <div class="col-md-12">
              <span class="required-lable">Enter Aadhar Number</span>
              <input
                type="text"
                #refaadharCardNumber="ngModel"
                [(ngModel)]="aadharCardNumber"
                name="aadharCardNumber"
                id="aadharCardNumber"
                class="form-control input-text-css"
                placeholder="Enter Aadhar Number"
              />
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button
              type="button"
              (click)="onVerifiedAadharCard()"
              class="ml-4 font-size-12 button-btn"
            >
              Verify
            </button>
          </div>
        </form>

        <div class="card-body formborder" *ngIf="APIResponse.KYC_DocId == 6">
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <img
              src="{{ 'data:image/jpg;base64,' + APIResponse.profile_image }}"
              *ngIf="APIResponse.KYC_DocId == 6 || APIResponse.KYC_DocId == 5"
              alt="Customer Image"
              class="mt-2"
              style="
                border-radius: 50%;
                width: 100px;
                height: 100px;
                margin: auto;
              "
            />
          </div>

          <div class="text-center" style="font-weight: bolder">
            {{ APIResponse.DoucumentNumber }}
          </div>
          <h6 class="card-title">Name:</h6>
          <p class="card-text">{{ APIResponse.Full_name }}</p>
          <br />
          <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
            Relation
          </h6>
          <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
            {{ APIResponse.care_of | slice : 0 : 4 }}
            {{ APIResponse.care_of | slice : 4 }}
          </p>
          <br />
          <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">DOB:</h6>
          <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
            {{ APIResponse.dob }}
          </p>
          <br />
          <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">Gender:</h6>
          <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
            {{ APIResponse.gender }}
          </p>
          <br />
          <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
            Address:
          </h6>
          <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
            {{ APIResponse.house }},{{ APIResponse.street }},
            {{ APIResponse.po }},{{ APIResponse.loc }},{{ APIResponse.vtc }},{{
              APIResponse.subdist
            }},
            {{ APIResponse.landmark }}
          </p>
          <br />
          <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
            Pin Code:
          </h6>
          <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
            {{ APIResponse.zip }}
          </p>
          <br />
          <h6
            class="card-title"
            *ngIf="APIResponse.KYC_DocId == 6 && Verify_PAN_No"
          >
            Verify PAN No
          </h6>
          <p
            class="card-text"
            *ngIf="APIResponse.KYC_DocId == 6 && Verify_PAN_No"
          >
            {{ Verify_PAN_No }}
          </p>

          <br />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- OTP MODELLL -->

<div
  class="modal fade in"
  id="KYCOTPModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          OTP Verification
        </h6>
        <button
          type="button"
          (click)="onCloseKYCOTP()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #cotp="ngForm"
          (ngSubmit)="onSAVEKYCOTP()"
          novalidate
          class="col-md-12 row m-0 p-0"
        >
          <div class="row m-0">
            <div class="col-md-12">
              <span class="required-lable"> Enter OTP</span>
              <input
                type="text"
                #refOTP="ngModel"
                numbersOnly
                maxlength="6"
                minlength="6"
                [(ngModel)]="OTP"
                placeholder="Enter OTP"
                required
                [ngClass]="{
                  'is-invalid': cotp.submitted && refOTP.invalid,
                  'alert-warning':
                    refOTP.invalid &&
                    (refOTP.dirty || refOTP.touched || refOTP.untouched)
                }"
                name="OTP"
                id="OTP"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button
              type="button"
              (click)="onSAVEKYCOTP()"
              class="ml-4 mt-3 btn font-size-12 button-btn"
              [disabled]="!cotp.form.valid"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
