import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "../../Shared/constantUrl";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { LmsService } from "../../_Lms/services/lms.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import { DialogService } from "../../Shared/dialog.service";
import * as moment from "moment";
import { QuickLoanService } from "../services/quickLoan.service";
import { AggregateErrors } from "@microsoft/signalr/dist/esm/Errors";

@Component({
  selector: "app-quickloan-generate-loanno",
  templateUrl: "./quickloan-generate-loanno.component.html",
  styleUrls: ["./quickloan-generate-loanno.component.scss"],
})
export class QuickloanGenerateLoannoComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  CRC_Type: any;
  loading: boolean = false;
  datasourceFeeCheck: any;
  displayedFeeColumns: any = ["Charges_Name", "Due", "Received", "balance"]
  @Input() loanSummary: any;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  LoanSeriesList: any[] = [];
  AccountingCatDropdown: any[] = [];
  loanNoModel: any = { Loan_Series: "" };
  minDate: any;
  JsonData: any[] = [];
  SelectAssignEmp: any = "";
  LoanSeries: any = "";
  isDisabled: boolean = false;
  AsignEmpData: any = [];
  Remarks: any;

  @Input() Processid: any;
  @Input() isNew: boolean;
  @Input() Status: any;
  @Input() ProcessName: any;
  emiIrr: any;
  AmortizationSource: any;
  cEIModel: any = {
    Asset_Cost: 0,
    IRR_Type: "Flat",
    EMI_Type: "Monthly",
    ProductId: "",
    IRR_CalculateBy: "FLAT_EMI",
    Adv_Inst: 0,
    DueDate: new Date(),
  };
  AmortizationColumns: string[] = [];

  constructor(
    private dataSharingService: DataSharingService,
    private _EncrDecrService: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private losService: LosService,
    private lmsService: LmsService,
    private route: ActivatedRoute,
    private MasterService: MasterService,
    private dialog: DialogService,
    private _QuickLoanService: QuickLoanService
  ) { }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next(this.ProcessName);
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    console.log("this.loanSummary", this.loanSummary);

    this.loanNoModel = {
      Loan_Id: this.loanSummary.LoanId,
      Loan_Series: this.loanSummary.LoanSeries,
      Loan_No: this.loanSummary.LoanNo,
      CRC_LoanType:this.loanSummary.CRCTypeId,
      Loan_Date: this.loanSummary.LoanDate
        ? new Date(this.loanSummary.LoanDate)
        : "",
      Loan_First_EMIDate: this.loanSummary.FirstEMIDate
        ? new Date(this.loanSummary.FirstEMIDate)
        : "",
      Loan_AccountingCategoryId: this.loanSummary.LoanAccountingCategoryId
        ? this.loanSummary.LoanAccountingCategoryId
        : "",
      LoginUserId: this.currentUser.userId,
    };
    this.getCRCType();
    this.LMS_Get_Loan_Series_For_Dropdown();
    this.Get_Loan_Accounting_Category_ForDropdown();
    this.isDisabled = !!this.loanSummary.LoanNo;
    // this.minDate = new Date(this.currentUser.Data_Freeze.split('T')[0]);
    this.minDate = new Date(
      this.loanSummary.Application_Date.split("-").reverse().join("-") +
      " 00:00:00"
    );
    this.MasterService.GetConfigurationDetails().subscribe((result) => {
      this.LoanSeries = JSON.parse(JSON.stringify(result))[0].LoanSeries;
    });

    this.getAsignEmpList();
  }

  LMS_Get_Loan_Series_For_Dropdown() {
    this.lmsService
      .LMS_Get_Loan_Series_For_Dropdown({ Loan_Id: this.loanSummary.LoanId })
      .subscribe((res: any) => {
        this.LoanSeriesList = res;
      });
  }

  LMS_Get_Loan_No() {
    this.lmsService
      .LMS_Get_Loan_No({ Series: this.loanNoModel.Loan_Series })
      .subscribe((res: any) => {
        this.loanNoModel.Loan_No = res;
      });
  }

  Get_Loan_Accounting_Category_ForDropdown() {
    this.lmsService
      .Get_Loan_Accounting_Category_ForDropdown({
        ProductId: this.loanSummary.ProductId,
      })
      .subscribe((res: any) => {
        this.AccountingCatDropdown = res;
      });
  }

  onNextStep() {
    if (this.loanSummary.LoanNo) {
      this.action.emit("next");
    } else {
      if (this.loanNoModel.Loan_Series == "") {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Loan Series is required",
          ...this.configSuccess,
        });
        return;
      }
      if (
        new Date(this.loanNoModel.Loan_First_EMIDate).getTime() <
        new Date(this.loanNoModel.Loan_Date).getTime()
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Loan Date should be less than First EMI Date",
          ...this.configSuccess,
        });
        return;
      }
      if (
        new Date(this.loanNoModel.Loan_First_EMIDate).getTime() <
        new Date(this.loanNoModel.Loan_Date).getTime()
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Loan Date should be less than first emi date",
          ...this.configSuccess,
        });
        return;
      }
      if(this.loanSummary.EMI_Type=="SingleEMI"){
      this.loading = true;

       const startTime = new Date(this.loanNoModel.Loan_Date).getTime();
        const endTime = new Date(this.loanNoModel.Loan_First_EMIDate).getTime();
        
        // Calculate the difference in milliseconds
        const difference = endTime - startTime;
    
        // Convert milliseconds to days
        var DaysDiff= Math.ceil(difference / (1000 * 3600 * 24));
        

        var ROI=parseFloat(this.loanSummary.IRR);
        if(this.loanSummary.ROI_Input_mathod=='Daily'){
          ROI=ROI*this.currentUser.DaysInYear;
        }
        var Interest_Amt=Math.round((((parseFloat(this.cEIModel.NetFinance_Amt)*ROI)/100)/this.currentUser.DaysInYear)*DaysDiff)
        var Agreement_Value=Math.round(parseFloat(this.cEIModel.NetFinance_Amt)+parseFloat(Interest_Amt.toString()));
       






      this.loanNoModel.Tenure = DaysDiff;
      this.loanNoModel.DisbIRR =this.loanSummary.IRR;
      this.loanNoModel.Case_IRR =this.loanSummary.IRR;
      this.loanNoModel.InterestAmount = Interest_Amt;
      this.loanNoModel.RateOfInterest = this.loanSummary.IRR.toFixed(2);
      this.loanNoModel.Loan_No = this.loanNoModel.Loan_No.toUpperCase();
      this.loanNoModel.DaysInYear = this.currentUser.DaysInYear
      this.loanNoModel.EMI_Amount = Agreement_Value
      this.loanNoModel.EMI_Type = this.loanSummary.EMI_Type
      this.loanNoModel.IRR_CalculateBy = this.loanSummary.IRR_CalculateBy
      this.loanNoModel.NetFinance_Amt = Number(this.cEIModel.NetFinance_Amt)
      this.loanNoModel.No_Of_Inst = 1
      this.loanNoModel.Agreement_Value=Agreement_Value
      this.loanNoModel.Adv_Inst = 0



      }else{
      if (this.loanSummary.IRR_CalculateBy == "STEP_EMI") {
        this.loanNoModel.Tenure = this.loanSummary.Tenure;
        this.loanNoModel.DisbIRR = 0;
        this.loanNoModel.Case_IRR = this.loanSummary.IRR;
        this.loanNoModel.InterestAmount = 0;
        this.loanNoModel.RateOfInterest = 0;
      } 
     
      else {
        this.loanNoModel.Tenure = this.cEIModel.Tenure;
        this.loanNoModel.DisbIRR =
          this.emiIrr.Interest_Amt == 0 ? 0 : this.emiIrr.Disbursement_IRR;
        this.loanNoModel.Case_IRR =
          this.emiIrr.Interest_Amt == 0 ? 0 : this.emiIrr.Case_IRR;
        this.loanNoModel.InterestAmount = this.emiIrr.Interest_Amt;
        this.loanNoModel.RateOfInterest = this.emiIrr.ROI.toFixed(2);
      }
      this.loading = true;

      this.loanNoModel.Loan_No = this.loanNoModel.Loan_No.toUpperCase();



      this.loanNoModel.DaysInYear = this.currentUser.DaysInYear
      this.loanNoModel.EMI_Amount = this.loanSummary.EMIAmount
      this.loanNoModel.EMI_Type = this.loanSummary.EMI_Type
      this.loanNoModel.IRR_CalculateBy = this.loanSummary.IRR_CalculateBy
      this.loanNoModel.NetFinance_Amt = Number(this.loanSummary.DisbursementAmt)
      this.loanNoModel.No_Of_Inst = this.loanSummary.No_Of_Instl
      this.loanNoModel.Adv_Inst = this.loanSummary.AdvInstl
      }


      this._QuickLoanService
        .QuickLoan_Get_FeeCheck_By_Process({
          LoanId: this.loanSummary.LoanId,
          ProcessId: this.Processid,
          ProductId: this.loanSummary.ProductId
        })
        .subscribe((res: any) => {
          if (res.length > 0) {

            //this.FinalSubmit = true;
            this.datasourceFeeCheck = new MatTableDataSource(res);
            $("#FeeCheckModel").modal("show");
            $("#FeeCheckModel").css("z-index", "1050");
          }
          else {
            this.datasourceFeeCheck = null;
            this.lmsService
              .LMS_Generate_LoanNo(this.loanNoModel)
              .subscribe((res: any) => {
                this.loading = false;
                if (res[0].CODE >= 0) {
                  this.QuickLoan_Save_FinalSubmitProcess();
                  if (this.loanSummary.WebsiteId != '' && this.loanSummary.WebsiteId != null && this.loanSummary.WebsiteId != undefined) {
                    this.updateWebsiteStatus();
                  }
                  this.snackBar.openFromComponent(SnackbarComponent, {
                    data: res[0].MSG,
                    ...this.configSuccess,
                  });

                  this.lmsService
                    .SaveNext_Loan({
                      Loan_Id: this.loanSummary.LoanId,
                      CommandName: "LoanNo",
                      isStatus: 1,
                    })
                    .subscribe((response) => {
                      //this.action.emit("next");
                      this.router.navigate([
                        `quickloan-dashboard/quick-loan/list/${this._EncrDecrService.encrypt(
                          encodeURIComponent(this.Processid)
                        )}/${this._EncrDecrService.encrypt(
                          encodeURIComponent(this.ProcessName)
                        )}`,
                      ]);
                    });
                } else {
                  this.snackBar.openFromComponent(SnackbarComponent, {
                    data: res[0].MSG,
                    ...this.configSuccess,
                  });
                }
              });
          }
        })


    }
  }
  OnCloseFeeCheckModel() {
    $("#FeeCheckModel").modal("hide");

  }

  updateWebsiteStatus(){
    this._QuickLoanService
      .LoanInNeedRejected({ id: this.loanSummary.WebsiteId, status: "Completed", loanNo: this.loanNoModel.Loan_No, application_no: this.loanSummary.ApplicationNo })
    .subscribe((res: any) => {
      console.log("Result of InProcess loan api call", res);
    
    });

  }
  QuickLoan_Save_FinalSubmitProcess() {
    this._QuickLoanService
      .QuickLoan_Save_FinalSubmitProcess({
        LoanId: this.loanSummary.LoanId,
        ProcessId: this.Processid,
        ProductId: this.loanSummary.ProductId,
        LoginUserId: this.currentUser.userId,
        Remark: this.Remarks,
        Application_No: this.loanSummary.ApplicationNo,
        AssignEmpId: this.SelectAssignEmp == "" ? "0" : this.SelectAssignEmp,
      })
      .subscribe((res: any) => {
        //console.log("res", res);
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  getAsignEmpList() {
    this._QuickLoanService
      .QuickLoan_Get_Process_Menu({
        ProductId: this.loanSummary.ProductId,
        ProcessId: this.Processid,
        LoanId: this.loanSummary.LoanId,
      })
      .subscribe((result: any) => {
        if (result.length > 0) {
          this.MasterService.QuickLoan_Employee_Assignment_Dropdown({
            ProcessId: result[0].NextProcessId,
            BranchId: this.loanSummary.BranchId,
            LoanId: this.loanSummary.LoanId,
          }).subscribe((res: any) => {
            this.AsignEmpData = res;
            this.SelectAssignEmp = res[0].EmpId ? res[0].EmpId : "";
          });
        }
      });
  }
  onCalculateEmiAndIRR() {
    if (
      new Date(this.loanNoModel.Loan_First_EMIDate).getTime() <
      new Date(this.loanNoModel.Loan_Date).getTime()
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Loan Date should be less than first emi date",
        ...this.configSuccess,
      });
      return;
    }
    if (
      this.loanSummary.EMI_Type == "ByMonthly" ||
      this.loanSummary.EMI_Type == "Quarterly" ||
      this.loanSummary.EMI_Type == "HalfYearly" ||
      this.loanSummary.EMI_Type == "Yearly"
    ) {
      if (
        new Date(this.loanNoModel.Loan_First_EMIDate).getMonth() ==
        new Date(this.loanNoModel.Loan_Date).getMonth()
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "first EMI Date should be 1 Month greater than Loan Date",
          ...this.configSuccess,
        });
        return;
      }
    }
    if (this.loanNoModel.Loan_No.trim() == "") {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Loan No. Can Not be Blank!!!",
        ...this.configSuccess,
      });
      return;
    }
    if (this.LoanSeries != "Automatic") {
      this.lmsService
        .LMS_Get_Loan_No_Mannual({ LoanNo: this.loanNoModel.Loan_No })
        .subscribe((res: any) => {
          if (res.length > 0) {
            if (res[0].CODE == 1) {
              this.cEIModel = {
                Asset_Cost: this.loanSummary.Asset_Cost,
                Adv_Inst: 0,
                DueDate: this.loanNoModel.Loan_First_EMIDate,
                EMI_Amount: this.loanSummary.EMIAmount,
                EMI_Type: this.loanSummary.EMI_Type,
                IRR_CalculateBy: "FLAT_EMI",
                Loan_Date: this.loanNoModel.Loan_Date,
                IsStep: false,
                NetFinance_Amt: this.loanSummary.LoanAmount,
                No_Of_Inst: this.loanSummary.No_Of_Instl,
                Tenure: this.loanSummary.Tenure,
                RateOfInterest:this.loanSummary.RateOfInterest
              };
              this.setTenure();
              console.log("cEIModel", this.cEIModel);
              if (this.loanSummary.IRR_CalculateBy == "STEP_EMI" || this.loanSummary.EMI_Type=="SingleEMI") {
                this.onNextStep();
              } else {
                this.cEIModel.IsStep = false;
                this.cEIModel.STEP_IRR = 0;
                let _data = { ...this.cEIModel };


                this.getCalculateEMIIRR(_data);
              }
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "Loan No. Can Not be Start Of This Series!!!",
                ...this.configSuccess,
              });
              this.loanSummary.LoanNo = "";
              return;
            }
          }
        });
    } else {
      this.cEIModel = {
        Asset_Cost: this.loanSummary.Asset_Cost,
        Adv_Inst: 0,
        DueDate: this.loanNoModel.Loan_First_EMIDate,
        EMI_Amount: this.loanSummary.EMIAmount,
        EMI_Type: this.loanSummary.EMI_Type,
        IRR_CalculateBy: "FLAT_EMI",
        Loan_Date: this.loanNoModel.Loan_Date,
        IsStep: false,
        NetFinance_Amt: this.loanSummary.LoanAmount,
        No_Of_Inst: this.loanSummary.No_Of_Instl,
        Tenure: this.loanSummary.Tenure,
      };
      this.setTenure();
      console.log("cEIModel", this.cEIModel);
      if (this.loanSummary.IRR_CalculateBy == "STEP_EMI" || this.loanSummary.EMI_Type=="SingleEMI") {
        this.onNextStep();
      } else {
        this.cEIModel.IsStep = false;
        this.cEIModel.STEP_IRR = 0;
        let _data = { ...this.cEIModel };
        this.getCalculateEMIIRR(_data);
      }
    }
  }

  getCalculateEMIIRR(data: any) {
    this.showSpinner = true;
    // data.ProductId = 2;
    data.DaysInYear = this.currentUser.DaysInYear;
    this.lmsService.GetEMICalculate(data).subscribe((res: any) => {
      this.showSpinner = false;
      this.emiIrr = res[0];
      setTimeout(() => {
        var elmntToView = document.getElementById("scrollto");
        elmntToView.scrollIntoView();
      }, 500);

      if (this.cEIModel.IRR_CalculateBy == "FLAT_EMI") {
        var stepEMI = [];

        stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfInstl: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
        var amt = this.emiIrr.ROI.toFixed(2);
        $(function () {
          $("#Flat_Rate").val(amt);
        });
      }

      var ReqModel = {};

      ReqModel = {
        LoanAmount: this.cEIModel.NetFinance_Amt,
        LoanPeriod: this.cEIModel.No_Of_Inst,
        Application_EMIType: this.cEIModel.EMI_Type,
        EMIAmount: this.emiIrr.EMI_Amt,
        RateOfInterest: this.emiIrr.ROI,
        NoOfEMI: this.cEIModel.Tenure,
        AdvanceInst:
          this.cEIModel.EMI_Type == "Monthly" ? this.cEIModel.Adv_Inst : 0,
        DisbIRR: this.emiIrr.Disbursement_IRR,
        InterestAmount: this.emiIrr.Interest_Amt,
        DueDate: this.cEIModel.DueDate,
        LoanDate: new Date(this.cEIModel.Loan_Date),
      };
      this.MasterService.LMS_EMI_Calculator_Without_StepEmi(ReqModel).subscribe(
        (resAmor: any) => {
          console.log("resAmor", resAmor);
          this.JsonData = resAmor;
          this.AmortizationColumns = [
            "PERIOD",
            "DueDate",
            "EMI_Amount",
            "PRINCIPAL",
            "INTEREST",
            "Principle_OS",
            "export",
          ];
          this.AmortizationSource = new MatTableDataSource(resAmor);
          $("#EMICalculatorAmort").modal("show");
        }
      );
    });
  }

  onCloseEMICalculator() {
    $("#EMICalculatorAmort").modal("hide");
  }
  totaltypewise(type) {
    if (
      this.AmortizationSource &&
      this.AmortizationSource != null &&
      this.AmortizationSource != ""
    ) {
      let sum = 0;
      for (let i = 0; i < this.AmortizationSource.filteredData.length; i++) {
        sum += Number(this.AmortizationSource.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : "";
    }
    return 0;
  }
  setTenure() {
    if (this.cEIModel.No_Of_Inst && this.cEIModel.EMI_Type != "SingleEMI") {
      if (this.cEIModel.EMI_Type == "ByMonthly") {
        this.cEIModel.Tenure = 2 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Quarterly") {
        this.cEIModel.Tenure = 3 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "HalfYearly") {
        this.cEIModel.Tenure = 6 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Yearly") {
        this.cEIModel.Tenure = 12 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Weekly") {
        this.cEIModel.Tenure = 7 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Fortnightly") {
        this.cEIModel.Tenure = 15 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "ByWeekly") {
        this.cEIModel.Tenure = 14 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "FourthWeekly") {
        this.cEIModel.Tenure = 28 * +this.cEIModel.No_Of_Inst;
      } else {
        this.cEIModel.Tenure = this.cEIModel.No_Of_Inst;
      }
    }
    var diffMonth =
      (new Date(this.cEIModel.DueDate).getFullYear() -
        new Date(this.cEIModel.Loan_Date).getFullYear()) *
      12 +
      (new Date(this.cEIModel.DueDate).getMonth() -
        new Date(this.cEIModel.Loan_Date).getMonth());

    if (this.cEIModel.EMI_Type == "ByMonthly") {
      this.cEIModel.Tenure = this.cEIModel.Tenure - (2 - diffMonth);
    } else if (this.cEIModel.EMI_Type == "Quarterly") {
      this.cEIModel.Tenure = this.cEIModel.Tenure - (3 - diffMonth);
    } else if (this.cEIModel.EMI_Type == "HalfYearly") {
      this.cEIModel.Tenure = this.cEIModel.Tenure - (6 - diffMonth);
    } else if (this.cEIModel.EMI_Type == "Yearly") {
      this.cEIModel.Tenure = this.cEIModel.Tenure - (12 - diffMonth);
    }
  }
  getCRCType() {
    this.lmsService
      .GET_CRC_MASTER_BY_CRCAGENCY({ Is_Firm: this.loanSummary.IsFirm })
      .subscribe((res: any) => {
        this.CRC_Type = res;
      });
  }
  keyPressAlphanumeric(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "quickloan_generate_loan"
    ) as HTMLTableElement;
    const worksheetName = "QUICKLOAN GENERATE LOAN"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
