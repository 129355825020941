import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import * as moment from "moment";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "../../Shared/constantUrl";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { LmsService } from "../../_Lms/services/lms.service";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";

@Component({
  selector: "app-mstemployeenewregister",
  templateUrl: "./mstemployeenewregister.component.html",
  styleUrls: ["./mstemployeenewregister.component.scss"],
})
export class MstemployeenewregisterComponent implements OnInit {
  private data: any;
  showSpinner: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  ReportingManagerRequestModel: RequestModel = new RequestModel();
  CopyEmployeeRequestModel: RequestModel = new RequestModel();
  EmployeeProcessForCopyRequestModel: RequestModel = new RequestModel();
  SaveEmployeeDataRequestModel: RequestModel = new RequestModel();
  SaveEmployeeProfileRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssBranchRequestModel: RequestModel = new RequestModel();
  EmpDeleteAssProcessRequestModel: RequestModel = new RequestModel();
  Emp_AccountId: any = "";
  loading: boolean = false;
  CurrentDate: any = new Date();
  // register new role
  EmployeeId: any = "0";
  Days_Allowed: any = 0;
  GroupName: any;
  cashPaymentLimit: any;
  bankPaymentLimit: any;
  CreditLimit: any = 0;
  SelectDeptId: any = "";
  SelectRoleId: any = "";
  FirstName: any = "";
  LastName: any = "";
  SelectGender: any = "";
  DateOfBirth: any = "";
  PresentAddress: any = "";
  PermanentAddress: any = "";
  PhoneNo: any = "";
  AlternetPhoneNo: any = "";
  EnterEmail: any = "";
  AadharNo: any = "";
  EmployeeLoginId: any = "";
  Emp_PAN_No: any;
  empDateOfJoining: any = "";
  Emp_Code: any;
  EmployeePassword: any = "";
  EmpManagerId: any = "";
  UploadImageBase64: any = "";
  UploadImageName: string = "";
  profilePicture: string = "";
  // save data array
  SelectedLoanProcessData: any = [];
  SelectedBranchesData: any = [];
  // bind data
  BranchesList: any = [];
  LoanProcessList: any = [];
  EmployeeDetailData: any = {};
  RolesDropdownData: any = [];
  ReportingManagerDropdown: any;
  // copy employee
  CopyEmployeeDropdown: any = [];
  CopyEmployeeId: any = "";
  isShowCopyEmployee: boolean = false;
  isEdit: boolean = false;
  DepartmentList: any[] = [];
  isDisable: boolean = true;
  dropdownSettings: any = {};
  ShowFilter = true;
  RoleId: any;
  limitSelection = false;
  myForm: UntypedFormGroup;
  RoleDropdown: any[] = [];

  BaseBranchesList: any[] = [];
  BaseBranchId: any = "";

  GroupId: any = "";
  Ischecked: boolean = false;
  groupDropdown: any[] = [];
  IsCreateEmployee: boolean = true;
  AccountHeadDropdown: any[] = [];
  Department: any;
  DeptForm: UntypedFormGroup;
  DeptDropdownSettings: any = {};

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private _Route: Router,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private fb: UntypedFormBuilder,
    private lmsService: LmsService,
    private _EncrDecrService: EncrDecrService
  ) {
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Employee Register");
    this._MasterService.GetRoleForDropdown().subscribe((result) => {
      this.RolesDropdownData = JSON.parse(JSON.stringify(result));
    });
    this.Get_DepartmentMaster();
    this.EmployeeId = sessionStorage.getItem("getEmployeeID");
    if (this.EmployeeId != "0") {
      this.isEdit = true;
      this.IsCreateEmployee = false;
      this.getEmployeeData();
    } else {
      this.myForm = this.fb.group({
        Role: [],
      });
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "Role_Name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: this.ShowFilter,
    };

    this.Get_Acc_GroupDropdown();

    // Department Settings
    this.DeptForm = this.fb.group({
      Department: [],
    });
    this.DeptDropdownSettings = {
      singleSelection: false,
      idField: "DeptId",
      textField: "Dept_Name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: this.ShowFilter,
    };
  }

  OnDepartmentSelect(item: any) {
    this.SelectDeptId = this.Department.map((element) => element.DeptId).join(
      ","
    );
  }

  notDecimal(event) {
    if (event.key === ".") {
      event.preventDefault();
    }
  }

  onItemSelect(item: any) {
    //console.log('onItemSelect', item);
    //console.log('RoleId', this.RoleId);
  }
  getRole() {
    this.RoleId = [];
    this._MasterService
      .Get_Role_By_Designation({ Desig_Id: this.SelectRoleId })
      .subscribe((res: any) => {
        this.RoleDropdown = res;

        // if selected any role on selection box
        this.RoleId = res;
        //for (var i = 0; i < res.length; i++) {
        //  this.chargesHeadDropdown.push({ id: res[i]["Id"], name: res[i]["ChagesHead"]});
        //}
      });
  }
  getEmployeeData() {
    this.BranchesList = [];
    this.LoanProcessList = [];
    this.RequestModel.EmpId = this.EmployeeId;
    this._MasterService
      .GetEmployeeDetails(this.RequestModel)
      .subscribe((result) => {
        //console.log("RESULT :",result);

        this.EmployeeDetailData = result;
        if (this.EmployeeDetailData.Item1[0]) {
          this.SelectRoleId = this.EmployeeDetailData.Item1[0].Emp_RoleId;
          this.SelectDeptId = this.EmployeeDetailData.Item1[0].Emp_DepartmentId;
          this.onChangeRoles(false);

          this.FirstName = this.EmployeeDetailData.Item1[0].Emp_FirstName;
          this.LastName = this.EmployeeDetailData.Item1[0].Emp_LastName;
          this.SelectGender = this.EmployeeDetailData.Item1[0].Emp_Gender;
          this.DateOfBirth = new Date(
            moment(this.EmployeeDetailData.Item1[0].Emp_DOB).format(
              "MM/DD/YYYY"
            )
          );

          this.empDateOfJoining = new Date(
            moment(this.EmployeeDetailData.Item1[0].Emp_DOJ).format(
              "MM/DD/YYYY"
            )
          );

          this.PresentAddress =
            this.EmployeeDetailData.Item1[0].Emp_PresentAddress;
          this.PermanentAddress =
            this.EmployeeDetailData.Item1[0].Emp_PermanentAddress;
          this.PhoneNo = this.EmployeeDetailData.Item1[0].Emp_PhoneNo;
          this.AlternetPhoneNo = this.EmployeeDetailData.Item1[0].Emp_PhoneNo1;
          this.EnterEmail = this.EmployeeDetailData.Item1[0].Emp_Email;
          this.AadharNo = this.EmployeeDetailData.Item1[0].Emp_AadharNo;
          this.EmployeeLoginId = this.EmployeeDetailData.Item1[0].Emp_LoginId;
          this.EmployeePassword = this.EmployeeDetailData.Item1[0].Emp_Password;
          this.EmpManagerId = this.EmployeeDetailData.Item1[0].Emp_ManagerId;
          this.UploadImageName = this.EmployeeDetailData.Item1[0].Emp_Image;
          this.UploadImageBase64 =
            this.EmployeeDetailData.Item1[0].Emp_Image != ""
              ? constantUrl.apiProfilePicUrl +
                this.EmployeeDetailData.Item1[0].Emp_Image
              : "";
          this.BaseBranchId = this.EmployeeDetailData.Item1[0].Base_BranchId;
          this.Emp_PAN_No = this.EmployeeDetailData.Item1[0].Emp_PAN_No;
          this.Emp_Code = this.EmployeeDetailData.Item1[0].Emp_Code;
          this.BaseBranchesList = this.EmployeeDetailData.Item2;
          this.Days_Allowed = this.EmployeeDetailData.Item1[0].Days_Allowed;
          this.CreditLimit = this.EmployeeDetailData.Item1[0].CreditLimit;
          this.Emp_AccountId = this.EmployeeDetailData.Item1[0].Emp_AccountId;
          this.GroupName = this.EmployeeDetailData.Item1[0].Group_Name;
          this.cashPaymentLimit =
            this.EmployeeDetailData.Item1[0].Cash_Payment_Limit;
          this.bankPaymentLimit =
            this.EmployeeDetailData.Item1[0].Bank_Payment_Limit;
          this.get_Acc_AccountList();
        }

        this.myForm = this.fb.group({
          Role: [JSON.parse(JSON.stringify(this.EmployeeDetailData.Item4))],
        });
        this.BranchesList = this.EmployeeDetailData.Item2;
        this.BranchesList.map((item) => {
          item.isActiveSelect = item.BranchAlradyAssigned;
          item.isDisable = item.BranchAlradyAssigned;
        });
        this.LoanProcessList = this.EmployeeDetailData.Item3;
        this.LoanProcessList.map((item) => {
          item.isActiveSelect = item.ProcessAlradyAssigned;
          item.isDisable = item.ProcessAlradyAssigned;
        });

        // if selected multiple Department on selection box Start
        var ArrDept = this.SelectDeptId.split(",");
        var arr = [];
        ArrDept.forEach((obj: any) => {
          arr.push(this.DepartmentList.filter((item) => item.DeptId == obj)[0]);
        });
        this.Department = arr;
        // if selected multiple Department on selection box End
      });
  }

  onChangeRoles(isCheck: boolean = true) {
    if (isCheck && this.EmployeeId != "0") {
      this._MasterService
        .Get_Employee_Subordinate_IsExists({ EmployeeId: this.EmployeeId })
        .subscribe((result) => {
          if (result[0].CODE == 1) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: result[0].MSG,
              ...this.configSuccess,
            });
            this.SelectRoleId = this.EmployeeDetailData.Item1[0].Emp_RoleId;
            return;
          } else {
            this.ReportingManagerDropdown = [];
            this.EmpManagerId = "";
            this.CopyEmployeeDropdown = [];
            this.CopyEmployeeId = "";
            this.isDisable = false;
            if (this.SelectRoleId != "") {
              this.ReportingManagerRequestModel.EmployeeRole = parseInt(
                this.SelectRoleId
              );
              this._MasterService
                .ReportingManagerForDropdown(this.ReportingManagerRequestModel)
                .subscribe((result) => {
                  this.ReportingManagerDropdown = JSON.parse(
                    JSON.stringify(result)
                  );
                });
            }
          }
        });
    } else {
      this.ReportingManagerDropdown = [];
      this.EmpManagerId = "";
      this.CopyEmployeeDropdown = [];
      this.CopyEmployeeId = "";
      if (this.SelectRoleId != "") {
        this.ReportingManagerRequestModel.EmployeeRole = parseInt(
          this.SelectRoleId
        );
        this._MasterService
          .ReportingManagerForDropdown(this.ReportingManagerRequestModel)
          .subscribe((result) => {
            this.ReportingManagerDropdown = JSON.parse(JSON.stringify(result));
          });
      }
    }
    this.getRole();
  }
  onChangeReportingManager() {
    this._MasterService
      .GetEmployeeDetails({ EmpId: 0, ReportingManagerId: this.EmpManagerId })
      .subscribe((result: any) => {
        this.BranchesList = result.Item2;
        this.BaseBranchesList = result.Item2;
        // this.BranchesList.map((item) => {
        //   item.isActiveSelect = item.BranchAlradyAssigned;
        //   item.isDisable = item.BranchAlradyAssigned;
        // });
        this.LoanProcessList = result.Item3;
        // this.LoanProcessList.map((item) => {
        //   item.isActiveSelect = item.ProcessAlradyAssigned;
        //   item.isDisable = item.ProcessAlradyAssigned;
        // });
        this.BranchesList.map((item) => {
          item.isActiveSelect = false;
          item.isDisable = false;
          item.BranchAlradyAssigned = false;
        });
        this.LoanProcessList.map((item) => {
          item.isActiveSelect = false;
          item.isDisable = false;
          item.ProcessAlradyAssigned = false;
        });
      });
  }

  onChangeCopyEmployee() {
    if (this.CopyEmployeeId != "") {
      this.isShowCopyEmployee = true;
    } else {
      this.isShowCopyEmployee = false;
      this.BranchesList.map((item) => {
        item.isActiveSelect = false;
        item.isDisable = false;
        item.BranchAlradyAssigned = false;
      });
      this.LoanProcessList.map((item) => {
        item.isActiveSelect = false;
        item.isDisable = false;
        item.ProcessAlradyAssigned = false;
      });
    }
  }

  onClickCopyEmployee() {
    this.EmployeeProcessForCopyRequestModel.EmpId = parseInt(
      this.CopyEmployeeId
    );
    this._MasterService
      .Get_EmployeeProcessForCopy(this.EmployeeProcessForCopyRequestModel)
      .subscribe((result: any) => {
        this.BranchesList = result.Item1;
        this.BranchesList.map((item) => {
          item.isActiveSelect = item.BranchAlradyAssigned;
          item.isDisable = item.BranchAlradyAssigned;
        });
        this.LoanProcessList = result.Item2;
        this.LoanProcessList.map((item) => {
          item.isActiveSelect = item.ProcessAlradyAssigned;
          item.isDisable = item.ProcessAlradyAssigned;
        });
      });
  }

  onChangeAssignBranch(event: MatCheckboxChange, selectedBranchId) {
    this.BranchesList.map((item) => {
      if (item.BranchId == selectedBranchId) {
        item.isActiveSelect = event.checked;
      }
    });
  }

  onChangeAssignProcess(event: MatCheckboxChange, selectedProcessId) {
    this.LoanProcessList.map((item) => {
      if (item.ProcessId == selectedProcessId) {
        item.isActiveSelect = event.checked;
      }
    });
  }

  handleUpload = (event) => {
    const file = event.target.files[0];
    if (file == undefined) {
      this.UploadImageName = "";
      this.UploadImageBase64 = "";
      this.profilePicture = "";
      return;
    }
    let extension = file.name.split(".").pop().toLowerCase();
    if (extension == "jpg" || extension == "jpeg" || extension == "png") {
      let imgsize = Math.round(file.size / 1024 / 1024);
      if (imgsize <= 5) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.UploadImageBase64 = reader.result;
          this.UploadImageName = file.name;
        };
      } else {
        this.profilePicture = "";
        this.UploadImageName = "";
        this.UploadImageBase64 = "";
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Profile pic uploaded less than and equal 5MB.",
          ...this.configSuccess,
        });
      }
    } else {
      this.profilePicture = "";
      this.UploadImageName = "";
      this.UploadImageBase64 = "";
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Profile pic not uploaded, because only accept .png, .jpg and .jpeg.",
        ...this.configSuccess,
      });
    }
  };

  OnClose(CODE) {
    this._Route.navigate([
      `/employee/employee/${this._EncrDecrService.encrypt(
        encodeURIComponent(CODE)
      )}/${true}`,
    ]);
  }

  onCopyPresentAdd(event: MatCheckboxChange) {
    if (event.checked == true) {
      this.PermanentAddress = this.PresentAddress;
    } else {
      this.PermanentAddress = "";
    }
  }

  onSaveEmployeeDetailData() {
    if (this.AadharNo && this.AadharNo.length != 12) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Enter valid Adhar nomber.",
        ...this.configSuccess,
      });
      return;
    }
    this.BranchesList.map((item) => {
      // if (item.BranchAlradyAssigned == true) {
      this.SelectedBranchesData.push({
        Id: item.EmpBranchId,
        EmpBranch_EmpId: this.EmployeeId,
        EmpBranch_BranchId: item.BranchId,
        EmpBranch_IsActive: item.isActiveSelect == true ? 1 : 0,
        EmpBranch_CreateBy: this.data.userId,
        EmpBranch_ModifyBy: this.data.userId,
      });
      // }
    });

    this.LoanProcessList.map((item) => {
      // if (item.ProcessAlradyAssigned == true) {
      this.SelectedLoanProcessData.push({
        Id: item.EmpProcessId,
        EmpProcess_EmpId: this.EmployeeId,
        EmpProcess_ProcessId: item.ProcessId,
        EmpProcess_IsActive: item.isActiveSelect == true ? 1 : 0,
        EmpProcess_CreateBy: this.data.userId,
        EmpProcess_ModifyBy: this.data.userId,
      });
      // }
    });
    let RoleIds = this.RoleId.map((element) => element.id).join(",");
    let DeptId = this.Department.map((element) => element.DeptId).join(",");
    //console.log('DeptId', DeptId);
    let saveEmployeeDetail = {
      Employee: {
        EmpId: this.EmployeeId,
        Emp_FirstName: this.FirstName,
        Emp_LastName: this.LastName,
        Emp_Gender: this.SelectGender,
        Emp_DOB: moment(this.DateOfBirth).format("YYYY-MM-DD"),
        Emp_DOJ: moment(this.empDateOfJoining).format("YYYY-MM-DD"),
        Emp_PhoneNo: this.PhoneNo,
        Emp_PhoneNo1: this.AlternetPhoneNo,
        Emp_RoleId: parseInt(this.SelectRoleId),
        Emp_DepartmentId: DeptId, //parseInt(this.SelectDeptId),
        Emp_Email: this.EnterEmail,
        Emp_LoginId: this.EmployeeLoginId,
        Emp_Password: this.EmployeePassword,
        Emp_PresentAddress: this.PresentAddress,
        Emp_PermanentAddress: this.PermanentAddress,
        Emp_AadharNo: this.AadharNo,
        Emp_Image:
          this.profilePicture != ""
            ? this.CurrentDate.getTime() + "_" + this.UploadImageName
            : this.UploadImageName,
        Emp_ManagerId: parseInt(this.EmpManagerId),
        Emp_Createby: this.data.userId,
        Emp_ModifyBy: this.data.userId,
        Base_BranchId: this.BaseBranchId,
        GroupId: this.GroupId,
        Emp_PAN_No: this.Emp_PAN_No,
        Emp_Code: this.Emp_Code,
        Days_Allowed: this.Days_Allowed,
        CreditLimit: this.CreditLimit,
        Emp_AccountId: this.Emp_AccountId,
        Cash_Payment_Limit: this.cashPaymentLimit,
        Bank_Payment_Limit: this.bankPaymentLimit,
      },
      EmployeeBranch: this.SelectedBranchesData,
      EmployeeProcess: this.SelectedLoanProcessData,
    };

    if (this.profilePicture != "") {
      this.SaveEmployeeProfileRequestModel.DocData =
        this.UploadImageBase64.split(";base64,").pop();
      this.SaveEmployeeProfileRequestModel.DocName =
        this.CurrentDate.getTime() + "_" + this.UploadImageName;
      this._MasterService
        .UploadProfilePic(this.SaveEmployeeProfileRequestModel)
        .subscribe((response) => {
          //console.log(response);
        });
    }

    this.SaveEmployeeDataRequestModel.JSON = JSON.stringify(saveEmployeeDetail);
    this.SaveEmployeeDataRequestModel.RoleIds = RoleIds;
    this._MasterService
      .SaveEmployeDetails(this.SaveEmployeeDataRequestModel)
      .subscribe((response) => {
        if (
          response[0].MSG != "" &&
          response[0].MSG != null &&
          response[0].CODE != -1
        ) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          sessionStorage.setItem("getEmployeeID", response[0].CODE);
          this.OnClose(response[0].CODE);
          // this.EmployeeId = response[0].CODE;
          // this.getEmployeeData();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  onDeleteAssignBranch(selectedBranchId) {
    this.EmpDeleteAssBranchRequestModel.EmpBranchId = selectedBranchId;
    this._MasterService
      .DeleteEmployeeBranch(this.EmpDeleteAssBranchRequestModel)
      .subscribe((response) => {
        if (response[0].CODE == 1) {
          this.BranchesList.map((item) => {
            if (item.EmpBranchId == selectedBranchId) {
              item.isDisable = false;
              item.BranchAlradyAssigned = false;
              item.isActiveSelect = false;
            }
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  onDeleteAssignProcess(selectedProcessId) {
    this.EmpDeleteAssProcessRequestModel.EmpProcessId = selectedProcessId;
    this._MasterService
      .DeleteEmployeeProcess(this.EmpDeleteAssProcessRequestModel)
      .subscribe((response) => {
        if (response[0].CODE == 1) {
          this.LoanProcessList.map((item) => {
            if (item.EmpProcessId == selectedProcessId) {
              item.isDisable = false;
              item.ProcessAlradyAssigned = false;
              item.isActiveSelect = false;
            }
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  Get_DepartmentMaster() {
    this.showSpinner = true;
    this._MasterService.Get_DepartmentMaster({}).subscribe((result) => {
      this.DepartmentList = JSON.parse(JSON.stringify(result));
      this.showSpinner = false;
    });
  }

  onChangeCreateEmpAcc(event: MatCheckboxChange) {
    if (event.checked == true) {
      this.Ischecked = true;
    } else {
      this.Ischecked = false;
    }
  }

  Get_Acc_GroupDropdown() {
    this.lmsService.Get_Acc_GroupDropdown({}).subscribe((res: any) => {
      this.groupDropdown = res;
    });
  }
  get_Acc_AccountList() {
    this.lmsService.Get_Account_Master({}).subscribe((res: any) => {
      this.AccountHeadDropdown = res;
    });
  }
  Get_Group() {
    this.GroupName = this.AccountHeadDropdown.find((obj: any) => {
      return obj.AccountId == this.Emp_AccountId;
    }).Group_Name;
  }
}
