<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>


<div class="mt-3" *ngIf="dataSource">
  <div class="">
    <div class="table-responsive">
      <mat-table [dataSource]="dataSource"
                 matSort
                 matTableExporter
                 #exporter="matTableExporter">
        <ng-container matColumnDef="Loan_Id" sticky>
          <mat-header-cell mat-sort-header
                           class="grid-header"
                           *matHeaderCellDef>#</mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo" sticky>
          <mat-header-cell mat-sort-header
                           class="grid-header"
                           *matHeaderCellDef>Loan No.</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{
      row.LoanAcNo
            }}
          </mat-cell>
        </ng-container>



        <ng-container matColumnDef="Loan_Date">
          <mat-header-cell mat-sort-header
                           class="grid-header"
                           *matHeaderCellDef>Loan Date</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Loan_Date }}
          </mat-cell>
        </ng-container>



        <ng-container matColumnDef="Application_FirstEMIDate">
          <mat-header-cell mat-sort-header
                           class="grid-header"
                           *matHeaderCellDef>First EMI Date</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Application_FirstEMIDate | date : "dd/MM/yyyy" }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Tenure">
          <mat-header-cell mat-sort-header
                           class="grid-header"
                           *matHeaderCellDef>Tenure</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Tenure }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="No_Of_Inst">
          <mat-header-cell mat-sort-header
                           class="grid-header"
                           *matHeaderCellDef>No_Of_Inst</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.No_Of_Inst }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="EMI_Amount">
          <mat-header-cell mat-sort-header
                           class="grid-header"
                           *matHeaderCellDef>EMI_Amount</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.EMI_Amount }}
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

      </mat-table>
    </div>
  </div>

  <div class="row m-0 mt-3">
    <div class="col-md-4">
      <button type="button"
              (click)="OnUpdateEffectiveIrr()"
              class="btn font-size-12 button-btn mr-3">
        Update Effective Irr
      </button>
    </div>
    <div class="col-md-8 text-right">
      <mat-paginator #paginatorRef
                     [pageSizeOptions]="[20, 50]"
                     class="mat-paginator-sticky"
                     showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>
