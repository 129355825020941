<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<form
  #df="ngForm"
  novalidate
  (keyup.enter)="onSearchENachAU()"
  class="formborder"
>
  <div class="row m-0">
    <div class="col-md-2">
      <span>Status</span>
      <select
        name="Status"
        id="Status"
        [(ngModel)]="Status"
        class="form-control input-text-css"
      >
        <!-- <option value="">Select Status</option> -->
        <option value="Pending">Pending</option>
        <option value="Aweted">Awaited</option>
        <option value="All">All</option>
      </select>
    </div>

    <div class="col-md-2">
      <span>Date From</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="LoanDateFrom"
          id="LoanDateFrom"
          class="form-control input-text-css"
          [(ngModel)]="LoanDateFrom"
          #refLoanDateFrom="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refLoanDateTo.invalid,
            'alert-warning':
              refLoanDateFrom.invalid &&
              (refLoanDateFrom.dirty ||
                refLoanDateFrom.touched ||
                refLoanDateFrom.untouched)
          }"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>
    <div class="col-md-2">
      <span>Date To</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="CaseDateTo"
          id="CaseDateTo"
          class="form-control input-text-css"
          [(ngModel)]="LoanDateTo"
          #refLoanDateTo="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refLoanDateTo.invalid,
            'alert-warning':
              refLoanDateTo.invalid &&
              (refLoanDateTo.dirty ||
                refLoanDateTo.touched ||
                refLoanDateTo.untouched)
          }"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
    </div>
    <div class="col-md-2">
      <span>Loan No</span>
      <input
        type="text"
        [(ngModel)]="LoanAcNo"
        placeholder="Loan No"
        name="LoanAcNo"
        id="LoanAcNo"
        class="form-control input-text-css"
      />
    </div>

    <div class="col-md-2 text-right">
      <button
        type="button"
        (click)="onSearchENachAU()"
        class="mt-3 font-size-12 button-btn"
      >
        Search
      </button>
    </div>
    <div class="col-md-2 text-right">
      <button
        type="button"
        (click)="updateToken()"
        class="mt-3 font-size-12 button-btn"
      >
       Update ENACH Token
      </button>
    </div>
  </div>
</form>

<div class="mt-3" [hidden]="!dataSourceEnachAU">
  <div class="">
    <div class="table-responsive">
      <mat-table
        [dataSource]="dataSourceEnachAU"
        matSort
        matTableExporter
        #exporter="matTableExporter"
        style="height: 300px; max-width: 100%; overflow: auto"
      >
        <ng-container matColumnDef="Id">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-height: 5px"
          >
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan No.</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.LoanAcNo }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Loan_Date">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Loan Date
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Loan_Date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="CustomerName">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Customer Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.CustomerName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PresentBankInCompanyName">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Present Bank in Company Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-center">
            {{ row.PresentBankInCompanyName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PresentBankInCompany">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Present On</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.PresentBankInCompany | date : "dd/MM/yyyy" }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Status">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Status</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Status }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Identifier">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Identifier</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Identifier }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="checkButton">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Check Status</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            <button
              *ngIf="row.Status === 'Pending'"
              type="button"
              (click)="onSearchPendingStatus(row)"
              class="font-size-12 button-btn"
              mat-raised-button
            >
              Check Status
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Action</mat-header-cell
          >

          <mat-cell
            *matCellDef="let row"
            class="grid-cell j-c-center"
            style="max-width: 40px"
          >
            <i
              *ngIf="row.Status === 'Aweted'"
              class="fa fa-arrow-circle-right arrow-circle action-btn"
              (click)="goToDetail(row)"
              aria-label="true"
            ></i>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumnsEnachAU; sticky: true"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsEnachAU"
        ></mat-row>
      </mat-table>

      <div class="row m-0 align-items-center" style="background: #fff">
        <div class="col-md-3">
          <button
            type="button"
            (click)="onExport(exporter)"
            class="font-size-12 button-btn"
          >
            <!-- [class.spinner]="loading" -->
            Export
          </button>
        </div>
        <div class="col-md-6">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            class="mat-paginator-sticky"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="addAUEnachInfo"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            AU E-Nach Info
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row m-0 col-md-12 p-0" *ngIf="applicationDetail">
            <div class="col-md-3">
              <h6 class="fs-12">Loan Ac No</h6>
              <p class="lead fs-12 fw-7">{{ applicationDetail.LoanAcNo }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Case Date</h6>
              <p class="lead fs-12">{{ applicationDetail.Loan_Date }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Customer Name</h6>
              <p class="lead fs-12">{{ applicationDetail.CustomerName }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Bank Name</h6>
              <p class="lead fs-12">{{ applicationDetail.Bankname }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Branch</h6>
              <p class="lead fs-12">{{ applicationDetail.Branch }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Customer A/C Type</h6>
              <p class="lead fs-12">{{ applicationDetail.AccType }}</p>
            </div>

            <div class="col-md-3">
              <h6 class="fs-12">Customer A/c No.</h6>
              <p class="lead fs-12">{{ applicationDetail.Ac }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Status</h6>
              <p class="lead fs-12">
                <!--{{ applicationDetail.status }}-->
                {{
                  applicationDetail.status == "Aweted"
                    ? "Awaited"
                    : applicationDetail.status
                }}
              </p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Loan Amount</h6>
              <p class="lead fs-12">{{ applicationDetail.LoanAmount }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">EMI Amount</h6>
              <p class="lead fs-12">{{ applicationDetail.EMIAmount }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">No. Of EMI</h6>
              <p class="lead fs-12">{{ applicationDetail.NoOfEMI }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Expiry Date Of EMI</h6>
              <p class="lead fs-12">{{ applicationDetail.ExpiryEMIDate }}</p>
            </div>

            <div class="col-md-3">
              <h6 class="fs-12">Nach Amount</h6>
              <p class="lead fs-12">{{ applicationDetail.NachAmount }}</p>
            </div>

            <div class="col-md-3">
              <h6 class="fs-12">Present On Company Bank</h6>
              <p class="lead fs-12">
                {{ applicationDetail.PresentOnCompanyBank }}
              </p>
            </div>

            <div class="col-md-3">
              <h6 class="fs-12">Present Bank In Company</h6>
              <p class="lead fs-12">
                {{ applicationDetail.PresentBankInCompanyName }}
              </p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Frequency</h6>
              <p class="lead fs-12">{{ applicationDetail.Frequency }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Debit Type</h6>
              <p class="lead fs-12">{{ applicationDetail.DebitType }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">UMRN Number</h6>
              <p class="lead fs-12">{{ applicationDetail.NachCode }}</p>
            </div>
          </div>
          <hr />

          <div class="col-md-12 row m-0">
            <div class="col-md-4">
              <span>Status Date</span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker6"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="StatusDate"
                  [(ngModel)]="StatusDate"
                  (dateChange)="changeDate()"
                  id="StatusDate"
                  class="form-control input-text-css"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker6"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker6></mat-datepicker>
              </div>
            </div>

            <div class="col-md-4">
              <span>EMI From</span>
              <input
                name="EMIFrom"
                [(ngModel)]="EMIFrom"
                (change)="EMIFromChange()"
                id="EMIFrom"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-4">
              <span>EMI To</span>
              <input
                name="EMITo"
                [(ngModel)]="EMITo"
                disabled
                id="EMITo"
                class="form-control input-text-css"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border: none">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveAUEnachInfo()"
            [disabled]="!f.form.valid"
            class="mt-3 btn font-size-12 button-btn"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
