import {
  AfterViewInit,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { LmsService } from "../../_Lms/services/lms.service";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { QuickLoanService } from "../services/quickLoan.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import { LosService } from "../../_LOS/services/los.service";
import { NgForm } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
declare var $: any;

class ChargesHead {
  Id;
  Balance = 0.0;
  AccData;
  TaxData = [];
  constructor(id?) {
    this.Id = id;
  }
}

class RequestData {
  LoanId;
  Voucher_Type;
  Voucher_Sub_Type;
  Voucher_Tag;
  Voucher_Date = moment(new Date().toISOString()).format("YYYY-MM-DD");
  Narration;
  ChargeHeadId = 0;
  AccountId;
  TranType;
  Amount;
  IsTax;
  Tax_Per = 0;
  GST_Type;
  GST_No;
  Party_AccountName;
  HSN_Code;
  Slab_Name;
  LoginUserId;
  Voucher_Cheque_No;
  Voucher_Mode_of_Payment;
  Voucher_Cheque_Date;
  Voucher_Bank_Name;
  Voucher_E_Transaction_Date;
  Voucher_E_Ref_No;
}

@Component({
  selector: "app-quickloan-tab-layout",
  templateUrl: "./quickloan-tab-layout.component.html",
  styleUrls: ["./quickloan-tab-layout.component.scss"],
})
export class QuickloanTabLayoutComponent implements OnInit, OnChanges {
  currentUser: any;
  showSpinner: boolean = false;
  isNew: boolean = false;
  currentTab: number = 0;
  lastFillTab: number = 0;
  LoanId: any;
  Processid: any;
  NextProcessId: any;
  loanSummary: any;
  TabDisabled: boolean = false;
  TotalTab: any;
  nextProcessCount: any = 0;
  FinalSubmit: boolean = true;
  LoanInfo: any;
  CustomerInfo: any;
  CustomerRef: any;
  IncExpInfo: any;
  AccountInfo: any;
  CRCInfo: any;
  PDCInfo: any;
  PendingDocs: any;
  BusinessAnalysis: any;
  AssetsInfo: any;
  GenerateLoan: any;
  Deviation: any;
  FamilyMemberInfo: any;
  Insurance: any;
  SanctionConditions: any;
  PersonalDiscussion: any;
  FOIR: any;
  FileChecking: any;
  FiQuestionsInfo: any;
  TVRQestionsInfo: any;
  LegalProcess: any;
  ValuationProcess: any;
  Remarks: any;
  ProductId: any;
  LastTab: any = 0;
  ShowFinalTab: boolean = true;
  SelectedPage: any;
  SelectStatus: any = "";
  AsignEmpData: any = [];
  AsignEmpDataLegal: any = [];
  AsignEmpDataVal: any = [];
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  Score: any = 1;
  dataSource: any;
  displayedColumns: string[] = [];
  DeviationData: any[] = [];
  DeviationApprove: any[] = [];
  LoanCustomerRelated: any = [];
  DocumentRelated: any = [];
  LoanCustomerRelated1: any = [];
  DocumentRelated1: any = [];
  ManualRelated: any = [];
  Status: any;
  Tenure_Name: any;
  NextProcessName: any;
  ProcessName: any;
  CompletedPage: any;
  PageModel: any = [];

  // Area Label Variable
  LabelLoan: any;
  LabelCustomer: any;
  LabelAccount: any;
  LabelCustomerRef: any;
  LabelIncExp: any;
  LabelCRC: any;
  LabelPDC: any;
  LabelDocs: any;
  LabelAssets: any;
  LabelBusinessAnalysis: any;
  LabelBankAnalysis: any;
  PendingProcess: any;
  SelectAssignEmp: any = "";
  LabelFamilyMem: any;
  LabelFIQues: any;
  LabelTVRQues: any;
  LabelLegalProcess: any;
  LabelValuationProcess: any;
  AllProcess: any = [];
  SendBackModel: any = [];
  inquiryId: any;
  Legal_Process: any;
  inquiryDetail: any;
  Process: any = {
    IsSelectedLegal: false,
    LegalAssignBy: "",
    IsSelectedValuation: false,
    ValuationAssignBy: "",
  };
  Valuation_Process: any;
  LabelInsurance: string;
  LabelSanctionConditions: string;
  LabelPersonalDiscussion: string;
  LabelFOIR: string;
  LabelFileChecking: string;
  RevertedProcessId: any;
  RevertedProcessName: any;
  RevertedEmployeeName: any;

  Completed: any;
  Deviation_Check: any;
  Hold: any;
  Rejected: any;
  SendBack: any;
  Mannual_Assignment: any;
  progress = 0;
  styleElement: HTMLStyleElement;
  colors: Array<string> = ["#ff00ff", "#00ff00"];

  //Add Fee Variable
  chargesHeadDropDownData: any;
  chargesHeadData: ChargesHead[] = [new ChargesHead(1)];
  bankAccountData: any;
  currentIndex = 0;
  grandTotal = 0.0;
  paymentDetails = {};
  AccountDropDown: any[] = [];
  ModeOfPayment: any[] = [];
  hiddenId = [];
  TotalScore: any = 0;
  getScore: any = 0;
  bufferValue: any = 0;
  @ViewChild("LF") form: NgForm;
  Revert_IsEdit: any = 0;
  displayedTableColumns = [];
  dataSourceChat = new MatTableDataSource([]);
  JsonData: any[] = [];
  datasourceFeeCheck: any;
  displayedFeeColumns: any = ["Charges_Name",	"Due",	"Received",	"balance"]
  constructor(
    private dataSharingService: DataSharingService,
    private _EncrDecrService: EncrDecrService,
    private route: ActivatedRoute,
    private router: Router,
    private LmsService: LmsService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private _QuickLoanService: QuickLoanService,
    private losService: LosService,
    private lmsService: LmsService
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.Processid = Number(
        this._EncrDecrService.decrypt(decodeURIComponent(param.get("Pid")))
      );
      this.ProductId = this._EncrDecrService.decrypt(
        decodeURIComponent(param.get("Prd_id"))
      );
      this.LoanId = this._EncrDecrService.decrypt(
        decodeURIComponent(param.get("id"))
      );
      this.inquiryId = this._EncrDecrService.decrypt(
        decodeURIComponent(param.get("inquiry"))
      );
      this.Status = param.get("Status");
      this.RevertedProcessId = Number(
        this._EncrDecrService.decrypt(
          decodeURIComponent(param.get("RevertedProcessId"))
        )
      );
      this.RevertedEmployeeName = this._EncrDecrService
        .decrypt(decodeURIComponent(param.get("RevertedEmployeeName")))
        .replace("%20", " ")
        .replace("%20", " ");
      this.RevertedProcessName = this._EncrDecrService
        .decrypt(decodeURIComponent(param.get("RevertedProcessName")))
        .replace("%20", " ")
        .replace("%20", " ");
      this.Revert_IsEdit = Number(
        this._EncrDecrService.decrypt(decodeURIComponent(param.get("IsEdit")))
      );
      this.finalprocessdropdown();
      console.log("this.inquiryId", this.inquiryId);
      if (this.Status == "D" || this.Processid == 6) this.ShowFinalTab = false;
      console.log("this.LoanId", this.LoanId);
      if (this.LoanId == "" || this.LoanId == 0) {
        this.LoanId = 0;
        this.isNew = true;
        this.ShowFinalTab = false;
        this.currentTab = 1;
        console.log("currentTab22", this.currentTab);
      } else {
        this.isNew = false;
        this.getLoan();
        this.QuickLoan_Get_DeviationApply1();
      }
      console.log("  this.isNew", this.isNew);
    });

    //console.log("this.LoanId", this.LoanId);
    console.log("this.Processid", this.Processid);
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log("On Changes triggered");
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    if (this.LoanId != undefined && Number(this.LoanId) > 0) {
      this.TabDisabled = false;
      this.currentTab = this.currentTab + 1;
      console.log("currentTab33", this.currentTab);

      setTimeout(() => {
        this.QuickLoan_Get_Process_Menu();
      }, 500);
      this.getActiveTab();
      this.finalprocessdropdown();
    } else {
      this.QuickLoan_Get_Process_MenuTabs();
      this.TabDisabled = true;
      this.currentTab = 0;
      console.log("currentTab44", this.currentTab);
    }

    /*if(this.ProductId == 0){
      this.QuickLoan_Get_Process_MenuTabs();
    }
    else{
      this.QuickLoan_Get_Process_Menu();
    }*/
    this.GetLosDashboardData();
    this.QuickLoan_CompletedPage();
    this.getQuickLoan_Get_NextAllProcess();

    this.QuickLoan_Get_PriviouseProcessList();

    this.fetchChat();
  }
  changeColors() {
    const head = document.getElementsByTagName("head")[0];
    var css = "";
    if (this.progress <= 30 || this.bufferValue <= 30) {
      css = `
  .style1 .mat-progress-bar-fill::after {
    background-color: #808087 !important;
 content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
  } `;
    }
    if (
      (this.progress > 30 && this.progress <= 60) ||
      (this.bufferValue <= 30 && this.bufferValue <= 60)
    ) {
      css = `
  .style1 .mat-progress-bar-fill::after {
    background-color: blue !important;
 content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
  } `;
    }
    if (
      (this.progress > 60 && this.progress < 100) ||
      (this.bufferValue > 60 && this.bufferValue < 100)
    ) {
      css = `
  .style1 .mat-progress-bar-fill::after {
    background-color: #2f9d0b !important;
 content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
  } `;
    }
    if (this.progress >= 100) {
      css = `
  .style1 .mat-progress-bar-fill::after {
    background-color: #2f9d0b !important;
 
  } `;
    }
    this.styleElement.innerHTML = "";
    this.styleElement.type = "text/css";
    this.styleElement.appendChild(document.createTextNode(css));
    head.appendChild(this.styleElement);
  }
  finalprocessdropdown() {
    this._QuickLoanService
      .QuickLoan_Get_ProcessLevel({
        ProductId: this.ProductId,
        ProcessId: this.Processid,
      })
      .subscribe((res: any) => {
        console.log("res", res);
        this.Completed = res[0].Completed;
        this.Hold = res[0].Hold;
        this.Rejected = res[0].Rejected;
        this.SendBack = res[0].SendBack;
        this.Mannual_Assignment = res[0].Mannual_Assignment;
        this.Deviation_Check = res[0].Deviation_Check;
        this.loanSummary.Mannual_Assignment = this.Mannual_Assignment;
      });
  }

  getQuickLoan_Get_NextAllProcess() {
    this._QuickLoanService
      .QuickLoan_Get_NextAllProcess({
        ProductId: this.ProductId,
        ProcessId: this.Processid == 20 ? this.Processid : 0,
        LoanId: this.LoanId,
      })
      .subscribe((res: any) => {
        this.Legal_Process = res.Item1.filter((x) => x.ProcessId == 16);
        this.Valuation_Process = res.Item1.filter((x) => x.ProcessId == 17);
        console.log("this.Legal_Process", this.Legal_Process);
        console.log("this.Valuation_Process", this.Valuation_Process);
        this.PendingProcess = res.Item2;
        this.nextProcessCount = res.Item1.length;
      });
  }

  onTabChange(event: any) {
    console.log("this.ShowFinalTab", this.ShowFinalTab);
    this.currentTab = event.index;
    console.log("currentTab11", this.currentTab);
    if (event.tab.textLabel == "Deviation" && this.Status != "D") {
      this.ShowFinalTab = true;
    } else {
      this.ShowFinalTab = false;
    }
    if (this.LoanId != undefined && Number(this.LoanId) > 0) {
      this.QuickLoan_Get_DeviationApply1();
      this.QuickLoan_CompletedPage();
    }
  }

  getLoan() {
    this.LmsService.LMS_Get_Loan_Summary({ EncLoanId: this.LoanId }).subscribe(
      (res: any) => {
        console.log("loanSummary Tab:", res);
        this.loanSummary = res[0];
        this.loanSummary.RevertedProcessId = this.RevertedProcessId;
        this.loanSummary.Mannual_Assignment = this.Mannual_Assignment;
        this.inquiryId = this.loanSummary.inquiryId;
        if (
          this.loanSummary.EMI_Type == "Daily" ||
          this.loanSummary.EMI_Type == "Weekly" ||
          this.loanSummary.EMI_Type == "Fortnightly" ||
          this.loanSummary.EMI_Type == "ByWeekly" ||
          this.loanSummary.EMI_Type == "FourthWeekly" ||
          this.loanSummary.EMI_Type == "SingleEMI"
        )
          this.Tenure_Name = "Days";
        else this.Tenure_Name = "Months";
      }
    );
  }

  getActiveTab() {
    if (this.Processid == 2) {
      this.currentTab = this.LastTab;
      console.log("currentTab55", this.currentTab);
    }
  }

  onChildAction(event: any) {
    if (this.LoanId != undefined && Number(this.LoanId) > 0) {
      this.QuickLoan_Get_DeviationApply1();
    }
    //console.log("event:", event);
    if (event == "next") {
      if (this.currentTab - 1 == this.lastFillTab) {
        this.lastFillTab = this.lastFillTab + 1;
        this.getLoan();
      }
      if (this.currentTab == 1 && !this.loanSummary.Customer) {
        this.getLoan();
      }
      if (this.currentTab == 2 && !this.loanSummary.LoanNo) {
        this.getLoan();
      }
      this.currentTab = this.currentTab + 1;
    }
    console.log("currentTab66", this.currentTab);
    this.QuickLoan_CompletedPage();
  }

  QuickLoan_Get_Process_MenuTabs() {
    //let ProductId = this.LoanId ? this.LoanId : 0;
    this._QuickLoanService
      .QuickLoan_Get_Process_MenuTabs({
        ProductId: this.ProductId,
        ProcessId: this.Processid,
      })
      .subscribe((res: any) => {
        //console.log("QuickLoan_Get_Process_MenuTabs", res);
        this.TotalTab = res.filter((x) => x.IsApplicable == true);
        //console.log("this.TotalTab", this.TotalTab);

        this.LoanInfo = this.GetFilteredValue("Loan Info");
        this.CustomerInfo = this.GetFilteredValue("Customer Info");
        this.AccountInfo = this.GetFilteredValue("Account Info");
        this.CustomerRef = this.GetFilteredValue("Customer Reference");
        this.IncExpInfo = this.GetFilteredValue(
          "Customer Income and expenditure"
        );
        this.CRCInfo = this.GetFilteredValue("CRC");
        this.PDCInfo = this.GetFilteredValue("PDC");
        this.PendingDocs = this.GetFilteredValue("Pending Document");
        this.BusinessAnalysis = this.GetFilteredValue(
          "Bank Statement Analysis"
        );

        this.AssetsInfo = this.GetFilteredValue("Assets Detail");
        this.FamilyMemberInfo = this.GetFilteredValue("Family Member");
        this.Insurance = this.GetFilteredValue("Insurance");
        this.SanctionConditions = this.GetFilteredValue("Sanction Conditions");
        this.PersonalDiscussion = this.GetFilteredValue("Personal Discussion");
        this.FOIR = this.GetFilteredValue("FOIR");
        this.FileChecking = this.GetFilteredValue("FileChecking");
        this.FiQuestionsInfo = this.GetFilteredValue("FI Questions");
        this.TVRQestionsInfo = this.GetFilteredValue("TVR Questions");
        this.LegalProcess = this.GetFilteredValue("Legal Process");
        this.ValuationProcess = this.GetFilteredValue("Valuation Process");
        this.GenerateLoan = 1;
        this.Deviation = 1;
        if (this.LoanId) this.LastTab = this.TotalTab.length - 1;
        else this.LastTab = 0;
        //this.currentTab = this.LastTab;
        console.log("this.TotalTabsssss", this.TotalTab);
      });
  }

  QuickLoan_Get_Process_Menu() {
    this._QuickLoanService
      .QuickLoan_Get_Process_Menu({
        ProductId: this.ProductId,
        ProcessId: this.Processid,
        LoanId: this.LoanId,
      })
      .subscribe((res: any) => {
        //console.log("QuickLoan_Get_Process_Menu", res);
        this.TotalTab = res.filter((x) => x.IsApplicable == true);
        //console.log("this.TotalTab", this.TotalTab);
        if (res.length > 0)
          if (this.Status == "R") {
            this.NextProcessId = this.RevertedProcessId;
            this.NextProcessName = this.RevertedProcessName;
          } else {
            this.NextProcessId = res[0].NextProcessId;
            this.NextProcessName = res[0].NextProcessName;
          }
        this.LoanInfo = this.GetFilteredValue("Loan Info");
        this.CustomerInfo = this.GetFilteredValue("Customer Info");
        this.AccountInfo = this.GetFilteredValue("Account Info");
        this.CustomerRef = this.GetFilteredValue("Customer Reference");
        this.IncExpInfo = this.GetFilteredValue(
          "Customer Income and expenditure"
        );
        this.CRCInfo = this.GetFilteredValue("CRC");
        this.PDCInfo = this.GetFilteredValue("PDC");
        this.PendingDocs = this.GetFilteredValue("Pending Document");
        this.BusinessAnalysis = this.GetFilteredValue(
          "Bank Statement Analysis"
        );

        this.AssetsInfo = this.GetFilteredValue("Assets Detail");
        this.FamilyMemberInfo = this.GetFilteredValue("Family Member");
        this.Insurance = this.GetFilteredValue("Insurance");
        this.SanctionConditions = this.GetFilteredValue("Sanction Conditions");

        this.PersonalDiscussion = this.GetFilteredValue("Personal Discussion");
        this.FOIR = this.GetFilteredValue("FOIR");
        this.FileChecking = this.GetFilteredValue("FileChecking");
        this.FiQuestionsInfo = this.GetFilteredValue("FI Questions");
        this.TVRQestionsInfo = this.GetFilteredValue("TVR Questions");
        this.LegalProcess = this.GetFilteredValue("Legal Process");
        this.ValuationProcess = this.GetFilteredValue("Valuation Process");

        this.GenerateLoan = 1;
        this.Deviation = 1;
        this.getAsignEmpList();
        //this.LastTab = (this.TotalTab.length) - 1;
        if (this.LoanId) this.LastTab = this.TotalTab.length - 1;
        else this.LastTab = 0;
        //this.LastTab = this.Processid == 1 ? (res.length)-1 : ((res.length)-1)-1;
        //console.log("this.LastTab 2", this.LastTab);

        if (this.Processid > 1) {
          this.currentTab = this.LastTab;
        }
        if (this.Processid == 6) {
          this.currentTab = this.LastTab + 1;
        }
        console.log("currentTab88", this.currentTab);

        console.log("this.LoanInfo =this.LoanInfo =", this.TotalTab);
      });
  }

  GetFilteredValue(type: any) {
    let page = this.TotalTab.filter((x) => x.MM_Name == type);
    //console.log("page", page);
    return page != "" ? page : "";
  }

  /*GetPageId(type){
    let PageId = this.TotalTab.filter(x => x.MM_Name == type);
    return PageId != '' ? PageId[0].MM_Id : '';
  }*/

  QuickLoan_Save_FinalSubmitProcess() {
    /*console.log("this.LoanCustomerRelated1.length:", this.LoanCustomerRelated1.length, 
    "this.DocumentRelated1.length:", this.DocumentRelated1.length, "this.DeviationData.length:", this.DeviationData.length, 
    "this.DeviationApprove.length:", this.DeviationApprove.length, "this.LoanCustomerRelated.length:", this.LoanCustomerRelated.length, 
    "this.DocumentRelated.length:", this.DocumentRelated.length
    );*/

    //if(this.LoanCustomerRelated1.length > 0 || this.DocumentRelated1.length > 0 ||
    //  (this.DeviationData.length > 0 && this.DeviationApprove.length > 0) || this.LoanCustomerRelated.length > 0 || this.DocumentRelated.length > 0
    //)
    // if (this.LoanCustomerRelated1.length > 0 || this.DocumentRelated1.length > 0 || this.LoanCustomerRelated.length > 0 || this.DocumentRelated.length > 0) {

    if (this.SelectStatus == "Reject") {
      this.updateWebsiteStatus();
    }
    if (
      this.SelectStatus != "Reject" &&
      this.Deviation_Check == true &&
      ((this.DeviationData.length > 0 && this.DeviationApprove.length > 0) ||
        this.LoanCustomerRelated1.length > 0 ||
        this.DocumentRelated1.length > 0 ||
        this.LoanCustomerRelated.length > 0 ||
        this.DocumentRelated.length > 0 ||
        this.ManualRelated.length > 0)
    ) {
      this.FinalSubmit = true;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please resolve all Deviation conditions before Final Submit.",
        ...this.configSuccess,
      });
      return;
    }
    // }
    else {
      if (this.SelectStatus == "Completed") {
        if (this.PendingProcess.length > 0 && this.Processid == 20) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data:
              "Please Complete these Pending Process-- " +
              this.PendingProcess.map((x) => x.Process_Name).join(","),
            ...this.configSuccess,
          });
        }
        if (
          this.Status != "R" &&
          this.Status != "C" &&
          this.Processid != 16 &&
          this.Processid != 17 &&
          this.SelectAssignEmp == "" &&
          this.nextProcessCount > 0
        ) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Please Assign Employee for next process!!!",
            ...this.configSuccess,
          });
        } else {
          this._QuickLoanService
            .QuickLoan_Save_FinalSubmitProcess({
              LoanId: this.LoanId,
              ProcessId: this.Processid,
              ProductId: this.ProductId,
              LoginUserId: this.currentUser.userId,
              Remark: this.Remarks,
              AssignEmpId:
                this.SelectAssignEmp == "" ? "0" : this.SelectAssignEmp,
              Application_No: this.loanSummary.ApplicationNo,
              Score: this.Score,
            })
            .subscribe((res: any) => {
              //console.log("res", res);
              this.FinalSubmit = false;
              this.showSpinner = false;
              if (res[0].CODE == 0) {
                this.FinalSubmit = true;
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.router.navigate([
                  `quickloan-dashboard/quick-loan/list/${this._EncrDecrService.encrypt(
                    encodeURIComponent(this.Processid)
                  )}/${this._EncrDecrService.encrypt(
                    encodeURIComponent(this.ProcessName)
                  )}`,
                ]);
              } else {
                this.FinalSubmit = true;
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      } else {
        this._QuickLoanService
          .QuickLoan_UpdateStatus_Process({
            LoanId: this.LoanId,
            ProcessId: this.Processid,
            LoginUserId: this.currentUser.userId,
            Remark: this.Remarks,
            Status: this.SelectStatus,
          })
          .subscribe((res: any) => {
            this.showSpinner = false;
            if (res[0].CODE >= 0) {
              this.FinalSubmit = true;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: res[0].MSG,
                ...this.configSuccess,
              });
              this.router.navigate([
                `quickloan-dashboard/quick-loan/list/${this._EncrDecrService.encrypt(
                  encodeURIComponent(this.Processid)
                )}/${this._EncrDecrService.encrypt(
                  encodeURIComponent(this.ProcessName)
                )}`,
              ]);
            } else {
              this.FinalSubmit = true;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: res[0].MSG,
                ...this.configSuccess,
              });
            }
          });
      }
    }
    this.showSpinner = false;
  }

  QuickLoan_Get_DeviationApply1() {
    this._QuickLoanService
      .QuickLoan_Get_DeviationApply({ Loan_Id: this.LoanId })
      .subscribe((res: any) => {
        //console.log("QuickLoan_Get_DeviationApply : ",res);
        this.DeviationData = res;
        this.DeviationApprove = res.filter((x) => x.Status != "Approve");
        this.OnClickDeviationCheck();
      });
  }
  Get_MannualDeviation() {
    this._QuickLoanService
      .QuickLoan_GetManual_Deviation({
        LoanId: this.LoanId,
      })
      .subscribe((res: any) => {
        this.ManualRelated = res;
      });
  }
  OnClickDeviationCheck() {
    this._QuickLoanService
      .QuickLoan_Get_DeviationForApproval({
        Loan_Id: this.LoanId,
        productId: this.ProductId,
        ProcessId: this.Processid,
      })
      .subscribe((res: any) => {
        //console.log("QuickLoan_Get_DeviationForApproval", res);
        this.LoanCustomerRelated = res.Item1.filter(
          (x) => x.Deviation == "True" && x.IsDeviation == true
        );
        this.DocumentRelated = res.Item2.filter(
          (x) => x.Deviation == "True" && x.IsDeviation == true
        );

        // If deviation Mismatch code start here
        this.LoanCustomerRelated1 = res.Item1.filter(
          (x) => x.Deviation == "True" && x.IsDeviation == false
        );
        this.DocumentRelated1 = res.Item2.filter(
          (x) => x.Deviation == "True" && x.IsDeviation == false
        );
        // If deviation Mismatch code end here
        console.log("LoanCustomerRelated1", this.LoanCustomerRelated1);
        console.log("DocumentRelated1", this.DocumentRelated1);
        console.log("LoanCustomerRelated", this.LoanCustomerRelated);
        console.log("DocumentRelated", this.DocumentRelated);
      });
    this.Get_MannualDeviation();
  }

  GetLosDashboardData() {
    this._QuickLoanService
      .GetQuickLoanDashboardCount({ LoginUserId: this.currentUser.userId })
      .subscribe((res: any) => {
        this.ProcessName = res.filter(
          (x) => x.EmpProcessId == this.Processid
        )[0].EmpProcessName;
        //console.log("this.ProcessName", this.ProcessName);
      });
  }

  QuickLoan_CompletedPage() {
    this._QuickLoanService
      .QuickLoan_CompletedPage({ LoanId: this.LoanId })
      .subscribe((res: any) => {
        this.CompletedPage = res;
        this._QuickLoanService
          .QuickLoan_Get_Process_Menu({
            ProductId: this.ProductId,
            ProcessId: 0,
          })
          .subscribe((res_TotalPage: any) => {
            var TotalPage = res_TotalPage.length;
            var TotalCompletedPage = res.length;

            this.progress = Math.round((TotalCompletedPage / TotalPage) * 100);
            if (
              this.loanSummary.LoanNo == "" ||
              this.loanSummary.LoanNo == undefined
            ) {
              if (this.progress >= 100) {
                this.progress = 100;
                this.progress = this.progress - 2;
              }
            }
            this.styleElement = document.createElement("style");
            this.changeColors();
          });
        this.PageModel.LoanInfo = res.filter((x) => x.MM_Name == "Loan Info");
        this.PageModel.CustomerInfo = res.filter(
          (x) => x.MM_Name == "Customer Info"
        );
        this.PageModel.AccountInfo = res.filter(
          (x) => x.MM_Name == "Account Info"
        );
        this.PageModel.CustomerRef = res.filter(
          (x) => x.MM_Name == "Customer Reference"
        );
        this.PageModel.IncExpInfo = res.filter(
          (x) => x.MM_Name == "Customer Income and expenditure"
        );
        this.PageModel.CRCInfo = res.filter((x) => x.MM_Name == "CRC");
        this.PageModel.PDCInfo = res.filter((x) => x.MM_Name == "PDC");
        this.PageModel.PendingDocs = res.filter(
          (x) => x.MM_Name == "Pending Document"
        );
        this.PageModel.BusinessAnalysis = res.filter(
          (x) => x.MM_Name == "Bank Statement Analysis"
        );

        this.PageModel.AssetsInfo = res.filter(
          (x) => x.MM_Name == "Assets Detail"
        );
        this.PageModel.FamilyMemberInfo = res.filter(
          (x) => x.MM_Name == "Family Member"
        );
        this.PageModel.Insurance = res.filter((x) => x.MM_Name == "Insurance");
        this.PageModel.SanctionConditions = res.filter(
          (x) => x.MM_Name == "Sanction Conditions"
        );

        this.PageModel.PersonalDiscussion = res.filter(
          (x) => x.MM_Name == "Personal Discussion"
        );
        this.PageModel.FOIR = res.filter((x) => x.MM_Name == "FOIR");
        this.PageModel.FileChecking = res.filter(
          (x) => x.MM_Name == "FileChecking"
        );

        this.PageModel.FIQuestionsInfo = res.filter(
          (x) => x.MM_Name == "FI Questions"
        );
        this.PageModel.TVRQuestionsInfo = res.filter(
          (x) => x.MM_Name == "TVR Questions"
        );
        this.PageModel.LegalProcess = res.filter(
          (x) => x.MM_Name == "Legal Process"
        );
        this.PageModel.ValuationProcess = res.filter(
          (x) => x.MM_Name == "Valuation Process"
        );
        this.PageModel.BankAnalysis = res.filter(
          (x) => x.MM_Name == "Bank Analysis"
        );

        console.log("PageModel", this.PageModel);

        this.LabelLoan = this.PageModel.LoanInfo.length > 0 ? "completed" : "";
        this.LabelCustomer =
          this.PageModel.CustomerInfo.length > 0 ? "completed" : "";
        this.LabelAccount =
          this.PageModel.AccountInfo.length > 0 ? "completed" : "";
        this.LabelCustomerRef =
          this.PageModel.CustomerRef.length > 0 ? "completed" : "";
        this.LabelIncExp =
          this.PageModel.IncExpInfo.length > 0 ? "completed" : "";
        this.LabelBankAnalysis =
          this.PageModel.BankAnalysis.length > 0 ? "completed" : "";
        
        this.LabelCRC = this.PageModel.CRCInfo.length > 0 ? "completed" : "";
        this.LabelPDC = this.PageModel.PDCInfo.length > 0 ? "completed" : "";
        this.LabelDocs =
          this.PageModel.PendingDocs.length > 0 ? "completed" : "";
        this.LabelBusinessAnalysis =
          this.PageModel.BusinessAnalysis.length > 0 ? "completed" : "";

        this.LabelAssets =
          this.PageModel.AssetsInfo.length > 0 ? "completed" : "";
        this.LabelFamilyMem =
          this.PageModel.FamilyMemberInfo.length > 0 ? "completed" : "";
        this.LabelInsurance =
          this.PageModel.Insurance.length > 0 ? "completed" : "";
        this.LabelSanctionConditions =
          this.PageModel.SanctionConditions.length > 0 ? "completed" : "";

        this.LabelPersonalDiscussion =
          this.PageModel.PersonalDiscussion.length > 0 ? "completed" : "";
        this.LabelFOIR = this.PageModel.FOIR.length > 0 ? "completed" : "";
        this.LabelFileChecking =
          this.PageModel.FileChecking.length > 0 ? "completed" : "";

        this.LabelFIQues =
          this.PageModel.FIQuestionsInfo.length > 0 ? "completed" : "";
        this.LabelTVRQues =
          this.PageModel.TVRQuestionsInfo.length > 0 ? "completed" : "";
        this.LabelLegalProcess =
          this.PageModel.LegalProcess.length > 0 ? "completed" : "";
        this.LabelValuationProcess =
          this.PageModel.ValuationProcess.length > 0 ? "completed" : "";
      });
  }

  QuickLoan_Get_PriviouseProcessList() {
    this._QuickLoanService
      .QuickLoan_Get_PriviouseProcessList({
        ProductId: this.ProductId,
        ProcessID: this.Processid,
        LoanId: this.LoanId,
      })
      .subscribe((res: any) => {
        this.getScore = 0;
        this.AllProcess = res;
        this.TotalScore = 10 * this.AllProcess.length();
        this.AllProcess.forEach((x) => {
          x.IsSelected = false;
          x.IsEdit = true;
          this.getScore = this.getScore + x.Score;
        });
        this.bufferValue = Math.round((this.getScore / this.TotalScore) * 100);
        console.log("this.bufferValue", this.bufferValue);
      });
  }

  OnClickSendBack() {
    this.AllProcess.forEach((x) => {
      x.IsEdit = true;
    });
    $("#SendBackModel").modal("show");
    $("#SendBackModel").css("z-index", "1050");
  }
  OnCloseSendBack() {
    $("#SendBackModel").modal("hide");
  }

  OnSaveSendBack() {
    var _data = this.AllProcess.filter((x) => x.IsSelected == true);
    if (_data.length > 0) {
      _data.forEach((obj: any) => {
        delete obj.IsSelected;
        delete obj.Process_Name;
        obj.IsEdit = obj.IsEdit == true ? 1 : 0;
      });

      let Data = {
        RevertedData: _data,
      };

      console.log("Data", Data);

      this._QuickLoanService
        .QuickLoan_Send_Back({
          LoanId: this.LoanId,
          ProcessId: this.Processid,
          JSON: JSON.stringify(Data),
          LoginUserId: this.currentUser.userId,
        })
        .subscribe((res: any) => {
          if (res[0].CODE == 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            // this.router.navigate([`quickloan-dashboard/quick-loan/list/${this._EncrDecrService.encrypt(encodeURIComponent(this.Processid))}`]);
            this.router.navigate([
              `quickloan-dashboard/quick-loan/list/${this._EncrDecrService.encrypt(
                encodeURIComponent(this.Processid)
              )}/${this._EncrDecrService.encrypt(
                encodeURIComponent(this.ProcessName)
              )}`,
            ]);
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Select Atleast one process",
        ...this.configSuccess,
      });
    }
  }

  QuickLoanFinalSubmit() {
    this.FinalSubmit = false;



    this._QuickLoanService
      .QuickLoan_Get_FeeCheck_By_Process({
        LoanId: this.LoanId,
        ProcessId: this.Processid,
        ProductId: this.ProductId
      })
      .subscribe((res: any) => {
        if (res.length > 0) {

          this.FinalSubmit = true;
          this.datasourceFeeCheck = new MatTableDataSource(res);
          $("#FeeCheckModel").modal("show");
          $("#FeeCheckModel").css("z-index", "1050");
        }
        else {
          this.datasourceFeeCheck = null;
          if (this.Status == "R") {
            /*   if (this.SelectStatus == 'Completed') {*/
            this.QuickLoan_Update_Reverted_Process();
            //}
            //else {
            //    this._QuickLoanService.QuickLoan_UpdateStatus_Process({
            //      LoanId: this.LoanId, ProcessId: this.Processid, LoginUserId: this.currentUser.userId,
            //      Remark: this.Remarks, Status: this.SelectStatus
            //    }).subscribe((res: any) => {
            //      this.showSpinner = false;
            //      if (res[0].CODE >= 0) {
            //        this.FinalSubmit = true;
            //        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            //        this.router.navigate([`quickloan-dashboard/quick-loan/list/${this._EncrDecrService.encrypt(encodeURIComponent(this.Processid))}/${this._EncrDecrService.encrypt(encodeURIComponent(this.ProcessName))}`]);
            //      }
            //      else {
            //        this.FinalSubmit = true;
            //        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            //      }
            //    });
            //  }
          } else {
            this.QuickLoan_Save_FinalSubmitProcess();
          }
        }


      })

  }
  OnCloseFeeCheckModel() {
    $("#FeeCheckModel").modal("hide");

  }
  QuickLoan_Update_Reverted_Process() {
    // if (this.LoanCustomerRelated1.length > 0 || this.DocumentRelated1.length > 0 || this.LoanCustomerRelated.length > 0 || this.DocumentRelated.length > 0) {
    if (
      this.Deviation_Check == true &&
      ((this.DeviationData.length > 0 && this.DeviationApprove.length > 0) ||
        this.LoanCustomerRelated1.length > 0 ||
        this.DocumentRelated1.length > 0 ||
        this.LoanCustomerRelated.length > 0 ||
        this.DocumentRelated.length > 0 ||
        this.ManualRelated.length > 0)
    ) {
      this.FinalSubmit = true;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please resolve all Deviation conditions before Final Submit.",
        ...this.configSuccess,
      });
      return;
    }
    //  }
    else {
      this._QuickLoanService
        .QuickLoan_Update_Reverted_Process({
          LoanId: this.LoanId,
          ProcessId: this.Processid,
          ProductId: this.ProductId,
          LoginUserId: this.currentUser.userId,
          Remark: this.Remarks,
        })
        .subscribe((res: any) => {
          if (res[0].CODE == 0) {
            this.FinalSubmit = true;
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            //this.router.navigate([`quickloan-dashboard/quick-loan/list/${this._EncrDecrService.encrypt(encodeURIComponent(this.Processid))}`]);
            this.router.navigate([
              `quickloan-dashboard/quick-loan/list/${this._EncrDecrService.encrypt(
                encodeURIComponent(this.Processid)
              )}/${this._EncrDecrService.encrypt(
                encodeURIComponent(this.ProcessName)
              )}`,
            ]);
          } else {
            this.FinalSubmit = true;
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    }
  }
  getAsignEmpList() {
    this._MasterService
      .QuickLoan_Employee_Assignment_Dropdown({
        ProcessId: this.NextProcessId,
        BranchId: this.loanSummary.BranchId,
        LoanId: this.LoanId,
      })
      .subscribe((res: any) => {
        this.AsignEmpData = res;
        //this.AsignEmpData.forEach(x => {
        //  x.IsSelected = false;
        //});
        if (this.Status == "R") {
          this.SelectAssignEmp = this.AsignEmpData.filter(
            (x) => x.EmpName == this.RevertedEmployeeName
          )[0].EmpId;
        } else {
          this.SelectAssignEmp = this.AsignEmpData[0].EmpId
            ? this.AsignEmpData[0].EmpId
            : "";
        }
      });
  }
  getAsignLegalEmpList() {
    this._MasterService
      .QuickLoan_Employee_Assignment_Dropdown({
        ProcessId: 16,
        BranchId: this.loanSummary.BranchId,
        LoanId: this.loanSummary.LoanId,
      })
      .subscribe((res: any) => {
        this.AsignEmpDataLegal = res;
      });
  }
  getAsignValuationEmpList() {
    this._MasterService
      .QuickLoan_Employee_Assignment_Dropdown({
        ProcessId: 17,
        BranchId: this.loanSummary.BranchId,
        LoanId: this.loanSummary.LoanId,
      })
      .subscribe((res: any) => {
        this.AsignEmpDataVal = res;
      });
  }
  OnProcessAssignment() {
    this.getAsignLegalEmpList();
    this.getAsignValuationEmpList();
    $("#ProcessAssignmentModel").modal("show");
    $("#ProcessAssignmentModel").css("z-index", "1050");
  }
  OnCloseProcessAssignmentModel() {
    $("#ProcessAssignmentModel").modal("hide");
  }
  OnSaveProcessAssignment() {
    let saveData = {
      JSON: "",
      LoanId: this.LoanId,
      LoginUserId: this.currentUser.userId,
    };
    let FinalData = { Data: [] };
    let Data = [];
    if (this.Process.IsSelectedLegal) {
      Data.push({
        ProcessId: 16,
        AssignEmpId: this.Process.LegalAssignBy,
      });
    }
    if (this.Process.IsSelectedValuation) {
      Data.push({
        ProcessId: 17,
        AssignEmpId: this.Process.ValuationAssignBy,
      });
    }
    if (Data.length > 0) {
      FinalData.Data = Data;
      console.log("Data", FinalData);
      let asset = JSON.stringify(FinalData);
      saveData.JSON = asset;
      console.log("asset", asset);
      console.log("saveData", saveData);
      this._QuickLoanService
        .QuickLoan_Assign_Mannual(saveData)
        .subscribe((response) => {
          if (response[0].CODE == 0) {
            this.OnCloseProcessAssignmentModel();
            this.getQuickLoan_Get_NextAllProcess();
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Assign the process!!!",
        ...this.configSuccess,
      });
    }
  }
  ViewInquiry() {
    this.LOS_GetInquiryDetails();
    $("#InquiryModel").modal("show");
    $("#InquiryModel").css("z-index", "1050");
  }
  LOS_GetInquiryDetails() {
    this.showSpinner = true;
    this.losService
      .LOS_GetInquiryDetails({ InquiryId: this.inquiryId })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.showSpinner = false;
          this.inquiryDetail = res[0];
        }
        this.showSpinner = false;
      });
  }
  OnCloseInquiryModel() {
    $("#InquiryModel").modal("hide");
  }
  Showprogressbar() {
    this.Showprogress();
    this.timer();
  }

  Showprogress() {
    this.progress = 0;
    setInterval(() => this.ProgressBar(), 2000);
  }

  ProgressBar() {
    this.bufferValue = this.bufferValue + 1;
    if (this.progress == 0) {
      this.progress = this.progress + 1;
    } else {
      this.progress = this.progress + 1;
      if ((this.progress = this.progress + 30)) {
        this.progress == this.progress + 1;
        if (this.progress >= 100) {
          this.progress = 100;
        }
      }
    }
  }
  timer() {
    var timeleft = 6;
    var downloadTimer = setInterval(function () {
      if (timeleft <= 0) {
        clearInterval(downloadTimer);
        this.progress = 0;
      } else {
        document.getElementById("countdown").innerHTML = timeleft + "";
      }
      timeleft -= 1;
    }, 1000);
    setInterval(() => clearInterval(downloadTimer), 6000);
  }

  //Login Fee Methods
  //Edited From Here
  getChargesHeadDropdownData(prid) {
    this._MasterService
      .Get_ChargesHeadsByProduct_ForDropdow({ ProductId: prid })
      .subscribe((resp) => {
        this.chargesHeadDropDownData = resp;
        this.chargesHeadData = [new ChargesHead(1)];
        this.onChangeChargeHead();
      });
  }

  getBankDropDownData(Tag) {
    this.LmsService.Get_Acc_Accounts_By_Tag({
      LoginUserId: this.currentUser.userId,
      Account_Tag: Tag,
    }).subscribe((res: any[]) => {
      this.AccountDropDown = res;
    });
  }

  addMoreData(chargeData) {
    // Check Value is there in Charge Head
    if (
      this.chargesHeadData.length > 1 &&
      this.chargesHeadData.findIndex((x) => x.Id == chargeData.Id) !=
      this.chargesHeadData.length - 1
    ) {
      this.snackBar
        .openFromComponent(SnackbarComponent, {
          data: "Entry Already Exist!!",
          ...this.configSuccess,
        })
        ._dismissAfter(700);
      chargeData.Id = undefined;
      return;
    }
    this.chargesHeadData.push(new ChargesHead());
    // let chargeHeadId=this.chargesHeadData[i].Id;
    // this.hiddenId.push(Number(id))
    // console.log("Deleted Data Id", id)
    // console.log("Deleted Data Array", this.hiddenId)
    this.currentIndex++;
  }

  removeData(index) {
    if (this.chargesHeadData.length <= 1) {
      return;
    }
    let data = (this.chargesHeadData as []).splice(index, 1);
    // console.log("data", data)
    // this.hiddenId.splice(this.hiddenId.findIndex(x => x === Number(data["Id"])))
    this.currentIndex--;
    this.calculateGrandTotal();
  }

  OnFeeAdd(pid) {
    this.resetForm();
    this.getChargesHeadDropdownData(pid);
    this.onChangeChargeHead();
    this.getPendingFee();
    $("#LoanFeeModel").modal("show");
  }

  OnCloseFeeAdd() {
    this.resetForm();
    $("#LoanFeeModel").modal("hide");
  }

  onChangeChargeHead(
    HeadId = 1,
    VoucherSubType = "Loan",
    ProductId = this.loanSummary.ProductId,
    TaxType = "CR",
    LoanNo = this.loanSummary.ApplicationNo,
    chargeData = this.chargesHeadData[0]
  ) {
    this.LmsService.Get_AccountHead_For_VoucherEntry({
      HeadId,
      VoucherSubType,
      ProductId,
      TaxType,
      LoanNo,
    }).subscribe((res) => {
      (chargeData as ChargesHead).AccData = res["Item1"][0];
      chargeData.TaxData = res["Item2"];
      chargeData.TaxData.forEach((x) => (x["ttAmt"] = 0.0));
      chargeData.Balance = 0.0;
      this.calculateGrandTotal();
    });
  }

  calculateGrandTotal() {
    let cal = 0.0;
    this.chargesHeadData.forEach((cd) => {
      cal += Number(cd.Balance);
      if (cd.TaxData && cd.TaxData.length > 0)
        cd?.TaxData.forEach((x) => {
          cal += x["ttAmt"];
        });
    });
    this.grandTotal = cal;
  }

  onTagChange(Tag) {
    if (Tag == "Other") {
      this.getOtherDropDownData();
    }
    this.getBankDropDownData(Tag);
    this.paymentDetails["MOP"] = undefined;
  }

  getOtherDropDownData() {
    this.LmsService.Get_Acc_Accounts_OtherThan_BankCash({}).subscribe(
      (res: any[]) => {
        this.AccountDropDown = res;
      }
    );
  }

  convertToRequestData() {
    let arr = [];
    this.chargesHeadData.forEach((x) => {
      let requestData = new RequestData();
      requestData.LoanId = Number(this.LoanId);
      requestData.Voucher_Type =
        this.paymentDetails["V_Tag"] === "CASH" ||
          this.paymentDetails["V_Tag"] === "Bank"
          ? "Receipt"
          : "Journal";
      requestData.Voucher_Sub_Type = "Loan";
      requestData.Voucher_Tag =
        this.paymentDetails["V_Tag"] == "Other"
          ? ""
          : this.paymentDetails["V_Tag"];
      requestData.Narration = this.paymentDetails["remark"];
      requestData.ChargeHeadId = Number(x.Id);
      requestData.AccountId = Number(x.AccData["AccountId"]);
      requestData.TranType = "CR";
      requestData.Amount = x.Balance;
      requestData.IsTax = 0;
      requestData.LoginUserId = this.currentUser.userId;
      arr.push(requestData);
      x.TaxData.forEach((y) => {
        let reqData = { ...requestData };
        reqData.IsTax = 1;
        reqData.Tax_Per = y["TaxRate"];
        reqData.Slab_Name = y["SlabName"];
        reqData.Amount = y["ttAmt"];
        reqData.AccountId = y["TaxAccountId"];
        reqData.GST_Type = "Existing_Party";

        arr.push(reqData);
      });
    });
    //DR Array
    let dr = new RequestData();
    dr.LoanId = Number(this.LoanId);
    dr.Voucher_Type =
      this.paymentDetails["V_Tag"] === "CASH" ||
        this.paymentDetails["V_Tag"] === "Bank"
        ? "Receipt"
        : "Journal";
    dr.Voucher_Sub_Type = "Loan";
    dr.Voucher_Tag =
      this.paymentDetails["V_Tag"] == "Other"
        ? ""
        : this.paymentDetails["V_Tag"];
    dr.Narration = this.paymentDetails["remark"];
    dr.TranType = "CR";
    dr.IsTax = 0;
    dr.LoginUserId = this.currentUser.userId;
    dr.Amount = this.grandTotal;
    dr.AccountId = Number(this.paymentDetails["AccountId"]);
    dr.TranType = "DR";
    // data
    if (this.paymentDetails["V_Tag"] === "Bank")
      dr.Voucher_Mode_of_Payment = this.paymentDetails["MOP"];
    else {
      dr.Voucher_Mode_of_Payment = undefined;
    }
    //data as per Payment
    if (this.paymentDetails["MOP"] == "Cheque") {
      dr.Voucher_Cheque_Date = this.paymentDetails["ChequeDate"];
      dr.Voucher_Cheque_No = this.paymentDetails["ChequeNo"];
      dr.Voucher_Bank_Name = this.paymentDetails["BankName"];
      dr.Voucher_E_Ref_No = undefined;
      dr.Voucher_E_Transaction_Date = undefined;
    } else if (this.paymentDetails["MOP"] !== "Cheque") {
      dr.Voucher_Cheque_Date = undefined;
      dr.Voucher_Cheque_No = undefined;
      dr.Voucher_Bank_Name = undefined;
      dr.Voucher_E_Ref_No = this.paymentDetails["RefNo"];
      dr.Voucher_E_Transaction_Date = this.paymentDetails["TranDate"];
    }
    dr.Voucher_Mode_of_Payment = this.paymentDetails["MOP"];
    arr.push(dr);
    let req = { Voucher: arr };
    return JSON.stringify(req);
  }

  onAmtChange(Chd: ChargesHead) {
    Chd?.TaxData.forEach(
      (x) =>
      (x["ttAmt"] = Math.round(
        0.01 * Number(Chd.Balance) * Number(x["TaxRate"])
      ))
    );
  }

  onSaveLoginFee() {
    let requestData = this.convertToRequestData();
    console.log("Request Data:", requestData);
    this.losService
      .QuickLoan_Save_Fees({ JSON: requestData })
      .subscribe((resp: any) => {
        if (resp.length > 0) {
          console.log("response Data", resp);

          this.snackBar.openFromComponent(SnackbarComponent, {
            data: resp[0]["MSG"],
            ...this.configSuccess,
          });
          if (resp[0]["CODE"] === 0) {
            this.OnCloseFeeAdd();
          }
        }
      });
  }

  resetForm() {
    this.chargesHeadData = [new ChargesHead(1)];
    console.log(this.chargesHeadData);
    this.paymentDetails = {};
    this.currentIndex = 0;
    this.hiddenId = [];
    this.grandTotal = 0.0;
  }

  ValidateChargeHead(chargeData) {
    if (
      this.chargesHeadData.length > 1 &&
      this.chargesHeadData.findIndex((x) => x.Id == chargeData.Id) !=
      this.chargesHeadData.length - 1
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Entry Already Exist!!",
        ...this.configSuccess,
      });
      chargeData.Id = undefined;
      return;
    }
  }
  getPendingFee() {
    this._QuickLoanService
      .QuickLoan_Pending_FeeList({ LoanId: Number(this.LoanId) })
      .subscribe((res: any[]) => {
        this.displayedColumns = [
          "Vid",
          "Voucher_Type",
          "Voucher_Sub_Type",
          "Voucher_Tag",
          "Voucher_Date",
          "ChagesHead",
          "Account_Name",
          "IsTax",
          "Tax_Per",
          "Amount",
          "Status",
          "Reason",
          "CreatedBy",
        ];
        // this.dataSource = res
        this.dataSource = new MatTableDataSource(
          JSON.parse(JSON.stringify(res))
        );
        console.log("Data source", this.dataSource);
      });
  }

  openChatModal() {
    this.fetchChat();
    $("#chatModel").modal("show");
    $("#chatModel").css("z-index", "1050");
  }

  onCloseChatModel() {
    $("#chatModel").modal("hide");
  }

  fetchChat() {
    this.lmsService
      .Get_Loan_Chat({ Loan_id: this.loanSummary.LoanId })
      .subscribe((x: any) => {
        // console.log("Chat Data", x);
        this.JsonData = JSON.parse(JSON.stringify(x));
        this.displayedTableColumns = [
          "Id",
          "Sender",
          "SendDate",
          "Receiver",
          "DateRead",
          "MSG",
          "Document_Attachment",
        ];
        this.dataSourceChat = new MatTableDataSource(x);
      });
    // console.log("Data Source Chat", this.dataSourceChat);
  }

  exportChatExcel(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("export-chat") as HTMLTableElement;
    const worksheetName = "CHAT TABLE"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
  updateWebsiteStatus() {
    this._QuickLoanService
      .LoanInNeedRejected({ id: this.loanSummary.WebsiteId, status: "Reject", application_no: this.loanSummary.ApplicationNo })
      .subscribe((res: any) => {
        console.log("Result of reject loan api call", res);
      });
  }
}
