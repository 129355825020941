<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div style="max-width: 100%; overflow: auto">
  <div>
    <div class="formborder row m-0 mt-1 p-1 align-items-center justify-content-between">
      <div class="col-md-5">
        <h6 class="m-0">Sanction Conditions</h6>
      </div>
      <!-- <button type="button" class="primary-btn" (click)="addCustomer()"> Add Customer </button> -->
      <div class="">
        <button type="button"
                class="btn font-size-12 button-btn"
                (click)="ShowCAMReport()"
                [class.spinner]="loading">
          CAM Report
        </button>
      </div>
      <div class="">
        <button type="button"
                class="btn font-size-12 button-btn"
                (click)="addSanctionConditions()"
                *ngIf="Status != 'C' && (Processid == 20 || Processid == 2)">
          Add Sanction Conditions
        </button>
      </div>
      <div class="">
        <button type="button"
                class="btn font-size-12 button-btn"
                (click)="addSanctionFinancial()"
                *ngIf="
        (LoanDetailApproved.Type!='Approved' && Status == 'P' && (Processid == 20 || Processid == 2)) ||
        (LoanDetailApproved.Type!='Approved' && Status == 'R' &&
          (loanSummary.RevertedProcessId == 6 ||
            loanSummary.RevertedProcessId == 2 ||
            loanSummary.RevertedProcessId == 8) &&
          (Processid == 20 || Processid == 2))
      ">
          Add Sanction Financial
        </button>
      </div>
    </div>
    <div *ngIf="Processid == 8 || Processid == 2"
         style="background-color: #b5eca478; padding: 9px">
      <h6 style="text-decoration: underline">Proposed Financial</h6>
      <ng-container *ngIf="LoanDetail">
        <div class="row m-0 mt-3">
          <div class="col-md-2" *ngIf="LoanDetail.EMI_Type != 'SingleEMI'">
            <h6 class="fs-12">Asset Cost</h6>
            <p class="lead fs-12 fw-7">₹ {{ LoanDetail.AssetCost }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Net Finance</h6>
            <p class="lead fs-12">₹ {{ LoanDetail.NetFinance }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">ROI</h6>
            <p class="lead fs-12">{{ LoanDetail.Flat_Rate }}%</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Tenure ({{ LoanDetail.Name }})</h6>
            <p class="lead fs-12">
              {{ LoanDetail.Tenure }}({{ LoanDetail.Name }})
            </p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">No of Installment</h6>
            <p class="lead fs-12">{{ LoanDetail.No_Of_Instl }}</p>
          </div>
          <div class="col-md-2" *ngIf="LoanDetail.EMI_Type != 'SingleEMI'">
            <h6 class="fs-12">Adv. Installment</h6>
            <p class="lead fs-12">{{ LoanDetail.Adv_Instl }}</p>
          </div>
          <!-- <div class="col-md-2">
          <h6 class="fs-12">Management Fee</h6>
          <p class="lead fs-12">₹ {{ LoanDetail.ManagementFee }}</p>
        </div> -->
          <div class="col-md-2" hidden>
            <h6 class="fs-12">Disbursement Amount</h6>
            <p class="lead fs-12">₹ {{ LoanDetail.DisbursementAmt }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Agreemnent Value</h6>
            <p class="lead fs-12">₹ {{ LoanDetail.AgreementValue }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Interest Amount</h6>
            <p class="lead fs-12">₹ {{ LoanDetail.InterestAmt }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">EMI Amount</h6>
            <p class="lead fs-12">₹ {{ LoanDetail.EMIAmount }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">EMI Type</h6>
            <p class="lead fs-12">{{ LoanDetail.EMI_Type }}</p>
          </div>
          <div class="col-md-2"
               [hidden]="
              LoanDetail.EMI_Type == 'Daily' ||
              LoanDetail.EMI_Type == 'Weekly' ||
              LoanDetail.EMI_Type == 'Fortnightly' ||
              LoanDetail.EMI_Type == 'ByWeekly' ||
              LoanDetail.EMI_Type == 'FourthWeekly' ||
              LoanDetail.EMI_Type == 'SingleEMI'
            ">
            <h6 class="fs-12">Case IRR</h6>
            <p class="lead fs-12">{{ LoanDetail.Case_IRR }}%</p>
          </div>
          <div class="col-md-2"
               [hidden]="
              LoanDetail.EMI_Type == 'Daily' ||
              LoanDetail.EMI_Type == 'Weekly' ||
              LoanDetail.EMI_Type == 'Fortnightly' ||
              LoanDetail.EMI_Type == 'ByWeekly' ||
              LoanDetail.EMI_Type == 'FourthWeekly' ||
              LoanDetail.EMI_Type == 'SingleEMI'
            ">
            <h6 class="fs-12" *ngIf="LoanDetail.EMI_Type != 'SingleEMI'">
              Disbursement IRR
            </h6>
            <p class="lead fs-12">{{ LoanDetail.Disbursement_IRR }}%</p>
          </div>
          <div class="col-md-2" *ngIf="LoanDetail.EMI_Type != 'SingleEMI'">
            <h6 class="fs-12">LTV</h6>
            <p class="lead fs-12">{{ LoanDetail.LTV }}%</p>
          </div>
          <div class="col-md-2"
               [hidden]="
              loanSummary.Product_Category == 'Un-Secured Loan' ||
              LoanDetail.EMI_Type == 'SingleEMI'
            ">
            <h6 class="fs-12">Margin</h6>
            <p class="lead fs-12">
              ₹ {{ LoanDetail.Margin < 0 ? 0 : LoanDetail.Margin }}
            </p>
          </div>
        </div>
        <div class="col-md-12 mb-3" *ngIf="stepEMIProposed">
          <div class="row m-0 mt-3 justify-content-center">
            <div class="table-border">
              <div class="item-row header">
                <div class="lead w50 fs-14">#</div>
                <div class="lead w120 fs-14">From EMI</div>
                <div class="lead w120 fs-14">To EMI</div>
                <div class="lead fs-14">EMI Amount</div>
                <div class="lead w120 fs-14">No of EMI</div>
                <div class="lead w150 fs-14">Total Amount</div>
              </div>
              <div class="item-row"
                   *ngFor="let semi of stepEMIProposed; let i = index">
                <div class="w50">{{ i + 1 }}</div>
                <div class="w120">
                  <input type="text"
                         numbersOnly
                         readonly
                         [(ngModel)]="semi.FromEMI"
                         placeholder="From EMI"
                         name="FromEMI{{ i }}"
                         id="FromEMI{{ i }}"
                         class="form-control input-text-css" />
                </div>
                <div class="w120">
                  <input type="text"
                         numbersOnly
                         [disabled]="semi.disable"
                         [(ngModel)]="semi.ToEMI"
                         readonly
                         placeholder="To EMI"
                         name="ToEMI{{ i }}"
                         id="ToEMI{{ i }}"
                         class="form-control input-text-css" />
                </div>
                <div class="">
                  <input type="text"
                         numbersOnly
                         [disabled]="semi.disable"
                         [(ngModel)]="semi.EMI_Amount"
                         readonly
                         placeholder="EMI Amount"
                         name="EMI_Amount{{ i }}"
                         id="EMI_Amount{{ i }}"
                         class="form-control input-text-css mr-3 emi-amount" />
                </div>
                <div class="w120">{{ semi.NoOfInstl }}&nbsp;</div>
                <div class="w150">{{ semi.TotalAmount }}&nbsp;</div>
              </div>
              <div class="item-row header" *ngIf="stepEmiDataProposed">
                <div class="lead w50 fs-14">&nbsp;</div>
                <div class="lead w120 fs-14"><b>Total</b></div>
                <div class="lead w120 fs-14">&nbsp;</div>
                <div class="lead fs-14">&nbsp;</div>
                <div class="lead w120 fs-14">
                  <b>{{ stepEmiDataProposed.totalEmi }}</b>
                </div>
                <div class="lead w150 fs-14">
                  <b>{{ stepEmiDataProposed.totalAmount }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <hr style="border: 0; border-top: 5px solid rgb(35 29 29 / 58%)" />
      <h6 class="m-0" style="text-decoration: underline">
        {{ LoanDetailApproved.Heading }} Financial
      </h6>
      <ng-container *ngIf="Processid == 8 || Processid == 2">
        <div class="row m-0 mt-3">
          <div class="col-md-2"
               *ngIf="LoanDetailApproved.EMI_Type != 'SingleEMI'">
            <h6 class="fs-12">Asset Cost</h6>
            <p class="lead fs-12 fw-7">₹ {{ LoanDetailApproved.AssetCost }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Net Finance</h6>
            <p class="lead fs-12">₹ {{ LoanDetailApproved.NetFinance }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">ROI</h6>
            <p class="lead fs-12">{{ LoanDetailApproved.Flat_Rate }}%</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Tenure ({{ LoanDetailApproved.Name }})</h6>
            <p class="lead fs-12">
              {{ LoanDetailApproved.Tenure }}({{ LoanDetailApproved.Name }})
            </p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">No of Installment</h6>
            <p class="lead fs-12">{{ LoanDetailApproved.No_Of_Instl }}</p>
          </div>
          <div class="col-md-2"
               *ngIf="LoanDetailApproved.EMI_Type != 'SingleEMI'">
            <h6 class="fs-12">Adv. Installment</h6>
            <p class="lead fs-12">{{ LoanDetailApproved.Adv_Instl }}</p>
          </div>
          <!-- <div class="col-md-2">
          <h6 class="fs-12">Management Fee</h6>
          <p class="lead fs-12">₹ {{ LoanDetailApproved.ManagementFee }}</p>
        </div> -->
          <div class="col-md-2" hidden>
            <h6 class="fs-12">Disbursement Amount</h6>
            <p class="lead fs-12">₹ {{ LoanDetailApproved.DisbursementAmt }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Agreemnent Value</h6>
            <p class="lead fs-12">₹ {{ LoanDetailApproved.AgreementValue }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Interest Amount</h6>
            <p class="lead fs-12">₹ {{ LoanDetailApproved.InterestAmt }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">EMI Amount</h6>
            <p class="lead fs-12">₹ {{ LoanDetailApproved.EMIAmount }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">EMI Type</h6>
            <p class="lead fs-12">{{ LoanDetailApproved.EMI_Type }}</p>
          </div>
          <div class="col-md-2"
               [hidden]="
              LoanDetailApproved.EMI_Type == 'Daily' ||
              LoanDetailApproved.EMI_Type == 'Weekly' ||
              LoanDetailApproved.EMI_Type == 'Fortnightly' ||
              LoanDetailApproved.EMI_Type == 'ByWeekly' ||
              LoanDetailApproved.EMI_Type == 'FourthWeekly' ||
              LoanDetailApproved.EMI_Type == 'SingleEMI'
            ">
            <h6 class="fs-12">Case IRR</h6>
            <p class="lead fs-12">{{ LoanDetailApproved.Case_IRR }}%</p>
          </div>
          <div class="col-md-2"
               [hidden]="
              LoanDetailApproved.EMI_Type == 'Daily' ||
              LoanDetailApproved.EMI_Type == 'Weekly' ||
              LoanDetailApproved.EMI_Type == 'Fortnightly' ||
              LoanDetailApproved.EMI_Type == 'ByWeekly' ||
              LoanDetailApproved.EMI_Type == 'FourthWeekly' ||
              LoanDetailApproved.EMI_Type == 'SingleEMI'
            ">
            <h6 class="fs-12"
                *ngIf="LoanDetailApproved.EMI_Type != 'SingleEMI'">
              Disbursement IRR
            </h6>
            <p class="lead fs-12">{{ LoanDetailApproved.Disbursement_IRR }}%</p>
          </div>
          <div class="col-md-2"
               *ngIf="LoanDetailApproved.EMI_Type != 'SingleEMI'">
            <h6 class="fs-12">LTV</h6>
            <p class="lead fs-12">{{ LoanDetailApproved.LTV }}%</p>
          </div>
          <div class="col-md-2"
               [hidden]="
              loanSummary.Product_Category == 'Un-Secured Loan' ||
              LoanDetailApproved.EMI_Type == 'SingleEMI'
            ">
            <h6 class="fs-12">Margin</h6>
            <p class="lead fs-12">
              ₹
              {{
                LoanDetailApproved.Margin < 0 ? 0 : LoanDetailApproved.Margin
              }}
            </p>
          </div>
        </div>
        <div class="col-md-12 mb-3" *ngIf="stepEMIApproved">
          <div class="row m-0 mt-3 justify-content-center">
            <div class="table-border">
              <div class="item-row header">
                <div class="lead w50 fs-14">#</div>
                <div class="lead w120 fs-14">From EMI</div>
                <div class="lead w120 fs-14">To EMI</div>
                <div class="lead fs-14">EMI Amount</div>
                <div class="lead w120 fs-14">No of EMI</div>
                <div class="lead w150 fs-14">Total Amount</div>
              </div>
              <div class="item-row"
                   *ngFor="let semi of stepEMIApproved; let i = index">
                <div class="w50">{{ i + 1 }}</div>
                <div class="w120">
                  <input type="text"
                         numbersOnly
                         readonly
                         [(ngModel)]="semi.FromEMI"
                         placeholder="From EMI"
                         name="FromEMI{{ i }}"
                         id="FromEMI{{ i }}"
                         class="form-control input-text-css" />
                </div>
                <div class="w120">
                  <input type="text"
                         numbersOnly
                         [disabled]="semi.disable"
                         [(ngModel)]="semi.ToEMI"
                         readonly
                         placeholder="To EMI"
                         name="ToEMI{{ i }}"
                         id="ToEMI{{ i }}"
                         class="form-control input-text-css" />
                </div>
                <div class="">
                  <input type="text"
                         numbersOnly
                         [disabled]="semi.disable"
                         [(ngModel)]="semi.EMI_Amount"
                         readonly
                         placeholder="EMI Amount"
                         name="EMI_Amount{{ i }}"
                         id="EMI_Amount{{ i }}"
                         class="form-control input-text-css mr-3 emi-amount" />
                </div>
                <div class="w120">{{ semi.NoOfInstl }}&nbsp;</div>
                <div class="w150">{{ semi.TotalAmount }}&nbsp;</div>
              </div>
              <div class="item-row header" *ngIf="stepEmiDataApproved">
                <div class="lead w50 fs-14">&nbsp;</div>
                <div class="lead w120 fs-14"><b>Total</b></div>
                <div class="lead w120 fs-14">&nbsp;</div>
                <div class="lead fs-14">&nbsp;</div>
                <div class="lead w120 fs-14">
                  <b>{{ stepEmiDataApproved.totalEmi }}</b>
                </div>
                <div class="lead w150 fs-14">
                  <b>{{ stepEmiDataApproved.totalAmount }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row col-md-12 m-0 mt-3 justify-content-end">
          <!--<button type="button" (click)="onCloseDialog()" class="btn btn-light font-size-12" data-dismiss="modal">
          Cancel
        </button>-->
          <button type="button"
                  (click)="onSanctionFinancialApproved()"
                  class="ml-4 btn font-size-12 button-btn"
                  *ngIf="(Processid == 8 ||(Processid == 2 && loanSummary.Product_Category=='Single Installment' )) && LoanDetailApproved.Type == 'Sanction'">
            Accept
          </button>
        </div>
      </ng-container>
    </div>



    <table class="table table-bordered mt-2" id="Sanction">
      <thead>
        <tr>
          <th>Header</th>
          <th class="WD-66">Details</th>
          <th class="WD-16">Completed</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let vdData of Headers; let i = index">
          <td>{{ vdData.Header }}</td>
          <td>
            <table>
              <tr *ngFor="let data of vdData.Condition; let i = index">
                <td>{{ data.Detail }}</td>
              </tr>
            </table>
          </td>
          <td>
            <mat-checkbox name="Account_IsLoanChargeable"
                          [(ngModel)]="vdData.IsCompleted"
                          class="mt-3"
                          *ngIf="Status != 'C' && Processid == 8">
            </mat-checkbox>
            <button type="button"
                    id="Edit"
                    (click)="OnEditSanctionConditions(vdData)"
                    *ngIf="
                PageData.currentTab == 1 &&
                Status != 'C' &&
                (Status == 'R' ? (Revert_IsEdit == 1 ? true : false) : true)
              "
                    class="ml-4 btn font-size-12 button-btn"
                    mat-raised-button
                    [class.spinner]="loading">
              Edit
            </button>
            <button type="button"
                    id="Delete"
                    (click)="OnDeleteSanctionConditions(vdData.Id)"
                    *ngIf="
                PageData.currentTab == 1 &&
                Status != 'C' &&
                (Status == 'R' ? (Revert_IsEdit == 1 ? true : false) : true)
              "
                    class="ml-4 btn font-size-12 button-btn"
                    mat-raised-button
                    [class.spinner]="loading">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="display: flex; align-items: center; gap: 15px">

      <div *ngIf="(Processid == 8 || Processid == 20 || Processid == 2 )">
        <app-printsectionletter [applicationId]="loanSummary.LoanId"></app-printsectionletter>
      </div>

      <div *ngIf="
      (Processid == 8 || Processid == 20 || Processid == 2 ) &&
      loanSummary.Product_Category == 'Vehicle Loan' &&
      Vehicle_Type == 'New'
    ">
        <app-print-template TemplateName="DOLetter"
                            [loanId]="loanSummary.LoanId"
                            textName="DO Letter"></app-print-template>
      </div>

      <div *ngIf=" (Processid == 8 || Processid == 20 || Processid == 2 )">
        <app-print-template TemplateName="AgreementLetter"
                            [loanId]="loanSummary.LoanId"
                            textName="Agreement Letter"></app-print-template>
      </div>

      <div *ngIf="
       (Processid == 8 || Processid == 20 || Processid == 2 ) && loanSummary.Product_Category == 'Property Loan'
    ">
        <app-print-template TemplateName="ListOfDocument"
                            [loanId]="loanSummary.LoanId"
                            textName="List of Document"></app-print-template>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 15px">
      <div>
        <button type="button"
                id="EVidioKyc"
                style=" background-color: #cb870b; color: black;"
                (click)="InitiateEVideoKYC()"
                class="btn font-size-12 button-btn mt-2"
                *ngIf="!loanSummary?.Digio_Video_Status && loanSummary?.IsDigioApi==1 && Processid == 20 && loanSummary?.Digio_Esign_Sanction_Status == 'completed' ">
          Initiate Video KYC
        </button>
        <button *ngIf="
    ( loanSummary?.Digio_Video_Status == 'requested' ||loanSummary?.Digio_Video_Status == 'approval_pending' ) && loanSummary?.IsDigioApi==1 && (Processid == 20 || Processid == 2 || Processid == 14)
   "
                type="button"
                id="KYCStatus"
                style="background-color: #a7a72b; color: black;"
                (click)="checkStatusForEVideoKYC()"
                class="btn font-size-12 button-btn mt-2">
          Check Video KYC Status
        </button>
        <button *ngIf="
      loanSummary?.Digio_Video_Status == 'approved'  && loanSummary?.IsDigioApi==1 && (Processid == 20 || Processid == 2 || Processid == 14)
    "
                type="button"
                id="VideoKYC"
                style="background-color:green"
                (click)="downloadEVideoKYC()"
                class="btn font-size-12 button-btn mt-2">
          Download  Video KYC
        </button>
        <button type="button"
                id="ESignInitiateAgreement"
                style=" background-color: #cb870b; color: black;"
                (click)="openESignModel()"
                class="ml-3 btn font-size-12 button-btn mt-2"
                *ngIf="!loanSummary?.Digio_Esign_Status  && loanSummary?.IsDigioApi==1 && Processid == 20 && loanSummary?.Digio_Esign_Sanction_Status == 'completed' ">
          Initiate E-Sign Agreement
        </button>

        <button *ngIf="
            loanSummary?.Digio_Esign_Status == 'requested'  && loanSummary?.IsDigioApi==1 && (Processid == 20 || Processid == 2 || Processid == 14)
          "
                type="button"
                id="ESignStatusAgreement"
                style="background-color: #a7a72b; color: black;"
                (click)="checkStatusForEsign()"
                class="ml-3 btn font-size-12 button-btn mt-2">
          Check E-Sign Status Agreement
        </button>

        <button *ngIf="
            loanSummary?.Digio_Esign_Status == 'completed'  && loanSummary?.IsDigioApi==1 && (Processid == 20 || Processid == 2 || Processid == 14)
          "
                type="button"
                id="ESignDownloadAgreement"
                style="background-color:green"
                (click)="downloadESign()"
                class="ml-3 btn font-size-12 button-btn mt-2">
          Download E-Sign Agreement
        </button>
        <button type="button"
                id="ESignInitiateSanction"
                style=" background-color: #cb870b; color: black;"
                (click)="openESignSanctionModel()"
                class="ml-3 btn font-size-12 button-btn mt-2"
                *ngIf="!loanSummary?.Digio_Esign_Sanction_Status  && loanSummary?.IsDigioApi==1 && Processid == 20">
          Initiate E-Sign Sanction
        </button>

        <button *ngIf="
            loanSummary?.Digio_Esign_Sanction_Status == 'requested'  && loanSummary?.IsDigioApi==1 && (Processid == 20 || Processid == 2 || Processid == 14)
          "
                type="button"
                id="ESignStatusSanction"
                style="background-color: #a7a72b; color: black;"
                (click)="checkStatusForEsignSanction()"
                class="ml-3 btn font-size-12 button-btn mt-2">
          Check E-Sign Status Sanction
        </button>
        <button *ngIf="
     loanSummary?.Digio_Esign_Sanction_Status == 'requested'  && loanSummary?.IsDigioApi==1 && (Processid == 20 || Processid == 2 || Processid == 14)"
                type="button"
                id="ESignStatusSanctionCancel"
                style="background-color: rgb(207 133 116); color: black;"
                (click)="CancelForEsignSanction()"
                class="ml-3 btn font-size-12 button-btn mt-2">
          Reject E-Sign  Sanction
        </button>
        <button *ngIf="
            loanSummary?.Digio_Esign_Sanction_Status == 'completed'  && loanSummary?.IsDigioApi==1 && (Processid == 20 || Processid == 2 || Processid == 14)
          "
                type="button"
                id="ESignSanction"
                style="background-color:green"
                (click)="downloadESignSanction()"
                class="ml-3 btn font-size-12 button-btn mt-2">
          Download E-Sign Sanction
        </button>
      </div>

    </div>

  </div>
  </div>

<div
  class="modal fade in"
  id="SanctionConditions"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          {{ Value }} Sanction Conditions
        </h6>
        <button
          type="button"
          (click)="onCloseSanctionConditions()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-body">
          <form #f="ngForm" novalidate class="formborder">
            <div class="row m-0 mt-1">
              <div class="col-md-12">
                <span class="required-lable"> Header </span>
                <input
                  type="text"
                  #refHeader="ngModel"
                  required
                  [(ngModel)]="Header"
                  placeholder="Header"
                  name="Header"
                  id="Header"
                  class="form-control input-text-css"
                  [ngClass]="{
                    'is-invalid': f.submitted && refHeader.invalid,
                    'alert-warning':
                      refHeader.invalid &&
                      (refHeader.dirty ||
                        refHeader.touched ||
                        refHeader.untouched)
                  }"
                />
              </div>
            </div>
            <form #df="ngForm" novalidate>
              <div
                class="row m-0 add-remove"
                *ngFor="let vdData of SanctionConditionsDetails; let i = index"
                style="margin-top: 8px !important"
              >
                <div class="col-md-12 row m-0 type-column">
                  <div
                    [ngClass]="{ 'bg-success1': i == 0 }"
                    *ngIf="i == 0"
                    style="width: 100%"
                  >
                    Conditions
                  </div>
                  <input
                    type="text"
                    name="Conditions{{ i }}"
                    id="Conditions{{ i }}"
                    class="form-control input-text-css"
                    #refConditions="ngModel"
                    required
                    [ngClass]="{
                      'is-invalid': df.submitted && refConditions.invalid,
                      'alert-warning':
                        refConditions.invalid &&
                        (refConditions.dirty ||
                          refConditions.touched ||
                          refConditions.untouched),
                      'mt-1': i == 0
                    }"
                    [(ngModel)]="vdData.Detail"
                  />
                </div>
                <button
                  type="button"
                  class="remove"
                  (click)="removeData(i)"
                  [ngClass]="{ 'no-head': i != 0 }"
                  *ngIf="SanctionConditionsDetails.length > 1"
                >
                  -
                </button>
                <button
                  type="button"
                  class="addmore"
                  (click)="addMoreData(i)"
                  [ngClass]="{
                    both: SanctionConditionsDetails.length > 1,
                    'no-head': i != 0
                  }"
                  [disabled]="!df.form.valid"
                  *ngIf="i == SanctionConditionsDetails.length - 1"
                >
                  +
                </button>
              </div>
              <div class="row col-md-12 m-0 mt-3 justify-content-end">
                <button
                  type="button"
                  (click)="onCloseSanctionConditions()"
                  class="btn font-size-12 button-btn"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  id="btnSanctionConditions"
                  #btnRegisterNewCustomer
                  (click)="OnSaveSanctionConditions()"
                  class="ml-4 btn font-size-12 button-btn"
                  [class.spinner]="loading"
                  [disabled]="!f.form.valid && !df.form.valid"
                >
                  {{ BtnValue }}
                </button>
              </div>
            </form>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="loanFinancialDetailsModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Loan Financial Details
        </h6>
        <button
          type="button"
          (click)="onCloseDialog()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <h6>Proposed Financial</h6>

        <div class="formborder mt-2">
          <ng-container *ngIf="LoanDetail">
            <div class="row m-0 mt-3">
              <div class="col-md-2" *ngIf="LoanDetail.EMI_Type != 'SingleEMI'">
                <h6 class="fs-12">Asset Cost</h6>
                <p class="lead fs-12 fw-7">₹ {{ LoanDetail.AssetCost }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Net Finance</h6>
                <p class="lead fs-12">₹ {{ LoanDetail.NetFinance }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">ROI</h6>
                <p class="lead fs-12">{{ LoanDetail.Flat_Rate }}%</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Tenure ({{ LoanDetail.Name }})</h6>
                <p class="lead fs-12">
                  {{ LoanDetail.Tenure }}({{ LoanDetail.Name }})
                </p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">No of Installment</h6>
                <p class="lead fs-12">{{ LoanDetail.No_Of_Instl }}</p>
              </div>
              <div class="col-md-2" *ngIf="LoanDetail.EMI_Type != 'SingleEMI'">
                <h6 class="fs-12">Adv. Installment</h6>
                <p class="lead fs-12">{{ LoanDetail.Adv_Instl }}</p>
              </div>
              <!-- <div class="col-md-2">
                <h6 class="fs-12">Management Fee</h6>
                <p class="lead fs-12">₹ {{ LoanDetail.ManagementFee }}</p>
              </div> -->
              <div class="col-md-2" hidden>
                <h6 class="fs-12">Disbursement Amount</h6>
                <p class="lead fs-12">₹ {{ LoanDetail.DisbursementAmt }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Agreemnent Value</h6>
                <p class="lead fs-12">₹ {{ LoanDetail.AgreementValue }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Interest Amount</h6>
                <p class="lead fs-12">₹ {{ LoanDetail.InterestAmt }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">EMI Amount</h6>
                <p class="lead fs-12">₹ {{ LoanDetail.EMIAmount }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">EMI Type</h6>
                <p class="lead fs-12">{{ LoanDetail.EMI_Type }}</p>
              </div>
              <div
                class="col-md-2"
                [hidden]="
                  LoanDetail.EMI_Type == 'Daily' ||
                  LoanDetail.EMI_Type == 'Weekly' ||
                  LoanDetail.EMI_Type == 'Fortnightly' ||
                  LoanDetail.EMI_Type == 'ByWeekly' ||
                  LoanDetail.EMI_Type == 'FourthWeekly' ||
                  LoanDetail.EMI_Type == 'SingleEMI'
                "
              >
                <h6 class="fs-12">Case IRR</h6>
                <p class="lead fs-12">{{ LoanDetail.Case_IRR }}%</p>
              </div>
              <div
                class="col-md-2"
                [hidden]="
                  LoanDetail.EMI_Type == 'Daily' ||
                  LoanDetail.EMI_Type == 'Weekly' ||
                  LoanDetail.EMI_Type == 'Fortnightly' ||
                  LoanDetail.EMI_Type == 'ByWeekly' ||
                  LoanDetail.EMI_Type == 'FourthWeekly' ||
                  LoanDetail.EMI_Type == 'SingleEMI'
                "
              >
                <h6 class="fs-12">Disbursement IRR</h6>
                <p class="lead fs-12">{{ LoanDetail.Disbursement_IRR }}%</p>
              </div>
              <div class="col-md-2" *ngIf="LoanDetail.EMI_Type != 'SingleEMI'">
                <h6 class="fs-12">LTV</h6>
                <p class="lead fs-12">{{ LoanDetail.LTV }}%</p>
              </div>
              <div
                class="col-md-2"
                [hidden]="
                  loanSummary.Product_Category == 'Un-Secured Loan' ||
                  LoanDetail.EMI_Type == 'SingleEMI'
                "
              >
                <h6 class="fs-12">Margin</h6>
                <p class="lead fs-12">
                  ₹ {{ LoanDetail.Margin < 0 ? 0 : LoanDetail.Margin }}
                </p>
              </div>
            </div>
            <div class="col-md-12 mb-3" *ngIf="stepEMIProposed">
              <div class="row m-0 mt-3 justify-content-center">
                <div class="table-border">
                  <div class="item-row header">
                    <div class="lead w50 fs-14">#</div>
                    <div class="lead w120 fs-14">From EMI</div>
                    <div class="lead w120 fs-14">To EMI</div>
                    <div class="lead fs-14">EMI Amount</div>
                    <div class="lead w120 fs-14">No of EMI</div>
                    <div class="lead w150 fs-14">Total Amount</div>
                  </div>
                  <div
                    class="item-row"
                    *ngFor="let semi of stepEMIProposed; let i = index"
                  >
                    <div class="w50">{{ i + 1 }}</div>
                    <div class="w120">
                      <input
                        type="text"
                        numbersOnly
                        readonly
                        [(ngModel)]="semi.FromEMI"
                        placeholder="From EMI"
                        name="FromEMI{{ i }}"
                        id="FromEMI{{ i }}"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="w120">
                      <input
                        type="text"
                        numbersOnly
                        [disabled]="semi.disable"
                        [(ngModel)]="semi.ToEMI"
                        readonly
                        placeholder="To EMI"
                        name="ToEMI{{ i }}"
                        id="ToEMI{{ i }}"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="">
                      <input
                        type="text"
                        numbersOnly
                        [disabled]="semi.disable"
                        [(ngModel)]="semi.EMI_Amount"
                        readonly
                        placeholder="EMI Amount"
                        name="EMI_Amount{{ i }}"
                        id="EMI_Amount{{ i }}"
                        class="form-control input-text-css mr-3 emi-amount"
                      />
                    </div>
                    <div class="w120">{{ semi.NoOfInstl }}&nbsp;</div>
                    <div class="w150">{{ semi.TotalAmount }}&nbsp;</div>
                  </div>
                  <div class="item-row header" *ngIf="stepEmiDataProposed">
                    <div class="lead w50 fs-14">&nbsp;</div>
                    <div class="lead w120 fs-14"><b>Total</b></div>
                    <div class="lead w120 fs-14">&nbsp;</div>
                    <div class="lead fs-14">&nbsp;</div>
                    <div class="lead w120 fs-14">
                      <b>{{ stepEmiDataProposed.totalEmi }}</b>
                    </div>
                    <div class="lead w150 fs-14">
                      <b>{{ stepEmiDataProposed.totalAmount }}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="formborder mt-2">
          <ng-container *ngIf="isCalEMITableView && Processid != 8">
            <form
              #eif="ngForm"
              class="row m-0"
              (ngSubmit)="onCalculateEmiAndIRR()"
              novalidate
            >
              <div class="row m-0 p-0 col-md-12 mt-3">
                <div class="col-md-2 mb-3"
                     *ngIf="cEIModel.EMI_Type != 'SingleEMI'">
                  <span>Asset Cost (Rs.) </span>
                  <input type="text"
                         numbersOnly
                         #refAsset_Cost="ngModel"
                         [(ngModel)]="cEIModel.Asset_Cost"
                         disabled
                         placeholder="Asset Cost"
                         [ngClass]="{
                      'is-invalid': eif.submitted && refAsset_Cost.invalid,
                      'alert-warning':
                        refAsset_Cost.invalid &&
                        (refAsset_Cost.dirty ||
                          refAsset_Cost.touched ||
                          refAsset_Cost.untouched)
                    }"
                         name="Asset_Cost"
                         id="Asset_Cost"
                         class="form-control input-text-css" />
                </div>
                <div class="col-md-2 mb-3">
                  <span> Net Finance (Rs.) </span>
                  <input type="text"
                         numbersOnly
                         required
                         (change)="ChangeText()"
                         #refNetFinance_Amt="ngModel"
                         [(ngModel)]="cEIModel.NetFinance_Amt"
                         placeholder="Net Finance"
                         [ngClass]="{
                      'is-invalid': eif.submitted && refNetFinance_Amt.invalid,
                      'alert-warning':
                        refNetFinance_Amt.invalid &&
                        (refNetFinance_Amt.dirty ||
                          refNetFinance_Amt.touched ||
                          refNetFinance_Amt.untouched)
                    }"
                         name="NetFinance_Amt"
                         id="NetFinance_Amt"
                         class="form-control input-text-css" />
                </div>
                <div class="col-md-3 mb-3">
                  <span class="required-lable"> Type</span>
                  <select name="Type"
                          id="Type"
                          [disabled]="cEIModel.EMI_Type == 'SingleEMI'"
                          #refType="ngModel"
                          [ngClass]="{
                      'is-invalid': eif.submitted && refType.invalid,
                      'alert-warning':
                        refType.invalid &&
                        (refType.dirty || refType.touched || refType.untouched)
                    }"
                          [(ngModel)]="cEIModel.IRR_CalculateBy"
                          class="form-control input-text-css"
                          (change)="setType($event)"
                          required>
                    <option value="ROI">Flat ROI</option>
                    <option value="FLAT_EMI">EMI Amt (₹)</option>
                    <option [hidden]="
                        cEIModel.EMI_Type == 'Daily' ||
                        cEIModel.EMI_Type == 'Weekly' ||
                        cEIModel.EMI_Type == 'Fortnightly' ||
                        cEIModel.EMI_Type == 'ByWeekly' ||
                        cEIModel.EMI_Type == 'FourthWeekly'
                      "
                            value="STEP_EMI">
                      Step EMI
                    </option>
                    <option value="Reducing_ROI">Reducing ROI</option>
                  </select>
                </div>

                <div class="col-md-3 mb-3">
                  <span class="required-lable"
                        *ngIf="cEIModel.IRR_CalculateBy != 'STEP_EMI'">
                    {{ cEIModel.IRR_CalculateBy }}
                  </span>
                  <input type="text"
                         appTwoDigitDecimaNumber
                         required
                         #refReducing_ROI="ngModel"
                         [(ngModel)]="cEIModel.Reducing_ROI"
                         placeholder="Reducing_ROI"
                         *ngIf="cEIModel.IRR_CalculateBy == 'Reducing_ROI'"
                         [ngClass]="{
                      'is-invalid': eif.submitted && refReducing_ROI.invalid,
                      'alert-warning':
                        refReducing_ROI.invalid &&
                        (refReducing_ROI.dirty ||
                          refReducing_ROI.touched ||
                          refReducing_ROI.untouched)
                    }"
                         name="Reducing_ROI"
                         id="Reducing_ROI"
                         class="form-control input-text-css" />
                  <input type="text"
                         placeholder="Flat Rate"
                         name="Flat_Rate"
                         id="Flat_Rate"
                         class="form-control input-text-css"
                         [hidden]="true"
                         *ngIf="cEIModel.IRR_CalculateBy !== 'ROI'" />
                  <input type="text"
                         appTwoDigitDecimaNumber
                         required
                         (change)="ChangeText()"
                         #refFlat_Rate="ngModel"
                         [(ngModel)]="cEIModel.Flat_Rate"
                         placeholder="Flat Rate"
                         *ngIf="cEIModel.IRR_CalculateBy == 'ROI'"
                         [ngClass]="{
                      'is-invalid': eif.submitted && refFlat_Rate.invalid,
                      'alert-warning':
                        refFlat_Rate.invalid &&
                        (refFlat_Rate.dirty ||
                          refFlat_Rate.touched ||
                          refFlat_Rate.untouched)
                    }"
                         name="Flat_Rate"
                         id="Flat_Rate"
                         class="form-control input-text-css" />

                  <input type="text"
                         placeholder="EMI Amount"
                         name="EMI_Amount"
                         id="EMI_Amount"
                         class="form-control input-text-css"
                         [hidden]="true"
                         *ngIf="cEIModel.IRR_CalculateBy !== 'FLAT_EMI'" />
                  <input type="text"
                         numbersOnly="true"
                         required
                         #refEMI_Amount="ngModel"
                         [(ngModel)]="cEIModel.EMI_Amount"
                         placeholder="EMI Amount"
                         *ngIf="cEIModel.IRR_CalculateBy == 'FLAT_EMI'"
                         [ngClass]="{
                      'is-invalid': eif.submitted && refEMI_Amount.invalid,
                      'alert-warning':
                        refEMI_Amount.invalid &&
                        (refEMI_Amount.dirty ||
                          refEMI_Amount.touched ||
                          refEMI_Amount.untouched)
                    }"
                         name="EMI_Amount"
                         id="EMI_Amount"
                         class="form-control input-text-css" />
                </div>

                <div class="col-md-2 mb-3">
                  <span> EMI Frequency</span>
                  <select name="EMI_Type"
                          id="EMI_Type"
                          [disabled]="cEIModel.EMI_Type == 'SingleEMI'"
                          #refEMI_Type="ngModel"
                          [ngClass]="{
                      'is-invalid': eif.submitted && refEMI_Type.invalid,
                      'alert-warning':
                        refEMI_Type.invalid &&
                        (refEMI_Type.dirty ||
                          refEMI_Type.touched ||
                          refEMI_Type.untouched)
                    }"
                          [(ngModel)]="cEIModel.EMI_Type"
                          class="form-control input-text-css"
                          (change)="setTenure()"
                          required>
                    <option value="Daily"
                            [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'">
                      Daily
                    </option>
                    <option value="Weekly"
                            [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'">
                      Weekly
                    </option>
                    <option value="ByWeekly"
                            [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'">
                      Two-Weekly
                    </option>
                    <option value="FourthWeekly"
                            [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'">
                      Four-Weekly
                    </option>
                    <option value="Fortnightly"
                            [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'">
                      Fortnightly
                    </option>
                    <option value="Monthly">Monthly</option>
                    <option value="ByMonthly"
                            [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'">
                      Bi-Monthly
                    </option>
                    <option value="Quarterly"
                            [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'">
                      Quarterly
                    </option>
                    <option value="HalfYearly"
                            [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'">
                      Half Yearly
                    </option>
                    <option value="Yearly"
                            [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'">
                      Yearly
                    </option>
                    <option value="SingleEMI"
                            [hidden]="
                        loanSummary.Product_Category != 'Single Installment'
                      ">
                      Single Installment
                    </option>
                  </select>
                </div>
                <div class="col-md-2 mb-3">
                  <span> No Of Installments </span>
                  <input type="text"
                         numbersOnly
                         required
                         #refNo_Of_Inst="ngModel"
                         [(ngModel)]="cEIModel.No_Of_Inst"
                         placeholder="No Of Installments"
                         (blur)="setTenure()"
                         [ngClass]="{
                      'is-invalid': eif.submitted && refNo_Of_Inst.invalid,
                      'alert-warning':
                        refNo_Of_Inst.invalid &&
                        (refNo_Of_Inst.dirty ||
                          refNo_Of_Inst.touched ||
                          refNo_Of_Inst.untouched)
                    }"
                         name="No_Of_Inst"
                         id="No_Of_Inst"
                         class="form-control input-text-css" />
                </div>
                <div class="col-md-3 mb-3">
                  <span> Tenure ({{ Name }}) </span>
                  <input type="text"
                         numbersOnly
                         required
                         [disabled]="cEIModel.EMI_Type == 'SingleEMI'"
                         #refTenure="ngModel"
                         [(ngModel)]="cEIModel.Tenure"
                         placeholder="Tenure"
                         [ngClass]="{
                      'is-invalid': eif.submitted && refTenure.invalid,
                      'alert-warning':
                        refTenure.invalid &&
                        (refTenure.dirty ||
                          refTenure.touched ||
                          refTenure.untouched)
                    }"
                         name="Tenure"
                         id="Tenure"
                         class="form-control input-text-css" />
                </div>
                <div class="col-md-3 mb-3"
                     [hidden]="
                    cEIModel.EMI_Type == 'Daily' ||
                    cEIModel.EMI_Type == 'Weekly' ||
                    cEIModel.EMI_Type == 'Fortnightly' ||
                    cEIModel.EMI_Type == 'ByWeekly' ||
                    cEIModel.EMI_Type == 'FourthWeekly' ||
                    cEIModel.EMI_Type == 'SingleEMI'
                  ">
                  <span> Instl In Adv. </span>
                  <input type="text"
                         numbersOnly
                         required
                         #refAdv_Inst="ngModel"
                         [(ngModel)]="cEIModel.Adv_Inst"
                         (blur)="setAdvanceInstl()"
                         placeholder="Instl In Adv."
                         [ngClass]="{
                      'is-invalid': eif.submitted && refAdv_Inst.invalid,
                      'alert-warning':
                        refAdv_Inst.invalid &&
                        (refAdv_Inst.dirty ||
                          refAdv_Inst.touched ||
                          refAdv_Inst.untouched)
                    }"
                         name="Adv_Inst"
                         id="Adv_Inst"
                         class="form-control input-text-css" />
                </div>

                <div class="col-md-3 p-1"
                     *ngIf="cEIModel.EMI_Type == 'SingleEMI'">
                  <span class="required-lable">Loan Date</span>
                  <div class="datepicker_feild">
                    <input [matDatepicker]="picker"
                           dateConvert
                           placeholder="DD/MM/YYYY"
                           name="Loan_Date"
                           id="Loan_Date"
                           *ngIf="cEIModel.EMI_Type == 'SingleEMI'"
                           (dateChange)="changeLoan_Date()"
                           required
                           [min]="minDate"
                           #refLoan_Date="ngModel"
                           class="form-control input-text-css"
                           [(ngModel)]="cEIModel.Loan_Date"
                           [ngClass]="{
                        'is-invalid': eif.submitted && refLoan_Date.invalid,
                        'alert-warning':
                          refLoan_Date.invalid &&
                          (refLoan_Date.dirty ||
                            refLoan_Date.touched ||
                            refLoan_Date.untouched)
                      }" />
                    <mat-datepicker-toggle matSuffix
                                           [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </div>
                </div>
                <div class="col-md-3 p-1"
                     *ngIf="cEIModel.EMI_Type == 'SingleEMI'">
                  <span class="required-lable">Loan Maturity Date</span>
                  <div class="datepicker_feild">
                    <input [matDatepicker]="picker1"
                           [min]="minLoanMaturityDate"
                           *ngIf="cEIModel.EMI_Type == 'SingleEMI'"
                           dateConvert
                           (dateChange)="DaysDifference()"
                           name="LoanMaturityDate"
                           id="LoanMaturityDate"
                           required
                           placeholder="DD/MM/YYYY"
                           #refLoanMaturityDate="ngModel"
                           [ngClass]="{
                        'is-invalid':
                          eif.submitted && refLoanMaturityDate.invalid,
                        'alert-warning':
                          refLoanMaturityDate.invalid &&
                          (refLoanMaturityDate.dirty ||
                            refLoanMaturityDate.touched ||
                            refLoanMaturityDate.untouched)
                      }"
                           class="form-control input-text-css"
                           [(ngModel)]="cEIModel.LoanMaturityDate" />
                    <mat-datepicker-toggle matSuffix
                                           [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </div>
                </div>


                <div class="col-md-2 mb-3"
                     *ngIf="cEIModel.EMI_Type == 'SingleEMI'">
                  <span>Processing Fee % </span>
                  <input type="text"
                         #refProcessingFee="ngModel"
                         [(ngModel)]="cEIModel.ProcessingFee"
                        
                         placeholder="Processing Fee"
                         [ngClass]="{
                      'is-invalid': eif.submitted && refProcessingFee.invalid,
                      'alert-warning':
                        refProcessingFee.invalid &&
                        (refProcessingFee.dirty ||
                          refProcessingFee.touched ||
                          refProcessingFee.untouched)
                    }"
                         name="ProcessingFee"
                         id="ProcessingFee"
                         class="form-control input-text-css" />
                </div>

                <div class="col-md-12 mb-3" *ngIf="stepEmiForm">
                  <div class="row m-0 mt-3 justify-content-center">
                    <div class="table-border">
                      <div class="item-row header">
                        <div class="lead w50 fs-14">#</div>
                        <div class="lead w120 fs-14">From EMI</div>
                        <div class="lead w120 fs-14">To EMI</div>
                        <div class="lead fs-14">EMI Amount</div>
                        <div class="lead w120 fs-14">No of EMI</div>
                        <div class="lead w150 fs-14">Total Amount</div>
                      </div>
                      <div class="item-row"
                           *ngFor="let semi of stepEMI; let i = index">
                        <div class="w50">{{ i + 1 }}</div>
                        <div class="w120">
                          <input type="text"
                                 numbersOnly
                                 readonly
                                 [(ngModel)]="semi.FromEMI"
                                 placeholder="From EMI"
                                 name="FromEMI{{ i }}"
                                 id="FromEMI{{ i }}"
                                 class="form-control input-text-css" />
                        </div>
                        <div class="w120">
                          <input type="text"
                                 numbersOnly
                                 [disabled]="semi.disable"
                                 [(ngModel)]="semi.ToEMI"
                                 placeholder="To EMI"
                                 name="ToEMI{{ i }}"
                                 id="ToEMI{{ i }}"
                                 class="form-control input-text-css" />
                        </div>
                        <div class="">
                          <input type="text"
                                 numbersOnly
                                 [disabled]="semi.disable"
                                 [(ngModel)]="semi.EMI_Amount"
                                 placeholder="EMI Amount"
                                 name="EMI_Amount{{ i }}"
                                 id="EMI_Amount{{ i }}"
                                 class="form-control input-text-css mr-3 emi-amount" />
                          <button type="button"
                                  *ngIf="!semi.disable"
                                  class="primary-btn"
                                  (click)="addNextStep(semi)">
                            <i class="fa fa-check-circle"
                               style="font-size: 18px"></i>
                          </button>
                          <button type="button"
                                  class="primary-btn"
                                  style="margin-left: 10px"
                                  *ngIf="!semi.disable && i > 0"
                                  (click)="removeLastStep(i)">
                            <i class="fa fa-minus-circle"
                               style="font-size: 18px"></i>
                          </button>
                        </div>
                        <div class="w120">{{ semi.NoOfInstl }}&nbsp;</div>
                        <div class="w150">{{ semi.TotalAmount }}&nbsp;</div>
                      </div>
                      <div class="item-row header" *ngIf="stepEmiData">
                        <div class="lead w50 fs-14">&nbsp;</div>
                        <div class="lead w120 fs-14"><b>Total</b></div>
                        <div class="lead w120 fs-14">&nbsp;</div>
                        <div class="lead fs-14">&nbsp;</div>
                        <div class="lead w120 fs-14">
                          <b>{{ stepEmiData.totalEmi }}</b>
                        </div>
                        <div class="lead w150 fs-14">
                          <b>{{ stepEmiData.totalAmount }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-0 col-md-12 justify-content-center">
                <button
                  type="button"
                  (click)="onCalculateEmiAndIRR()"
                  class="pl-5 pr-5 btn font-size-12 button-btn"
                  [class.spinner]="loading"
                >
                  Calculate EMI and IRR
                </button>
              </div>
              <div
                class="row col-md-12 m-0 p-0 mt-3"
                id="scrollto"
                *ngIf="emiIrr"
              >
               
                <div class="col-md-3">
                  <h6 class="fs-12">Interest Amount</h6>
                  <p class="lead fs-12">
                    ₹ {{ emiIrr?.Interest_Amt.toFixed(2) }}
                  </p>
                </div>
                <div class="col-md-3">
                  <h6 class="fs-12">Agreement Value</h6>
                  <p class="lead fs-12">
                    ₹ {{ emiIrr.Agreement_Value.toFixed(2) }}
                  </p>
                </div>
                <div class="row m-0 pl-0 pr-0 col-md-3">
                  <div
                    class="col-md-6 pr-1"
                    *ngIf="cEIModel.EMI_Type != 'SingleEMI'"
                  >
                    <h6 class="fs-12">LTV</h6>
                    <p class="lead fs-12">{{ emiIrr.LTV.toFixed(2) }} %</p>
                  </div>
                  <div class="col-md-6 pl-1">
                    <h6 class="fs-12">ROI</h6>
                    <p class="lead fs-12">{{ emiIrr.ROI.toFixed(2) }} %</p>
                  </div>
                </div>
                <div class="col-md-3">
                  <h6 class="fs-12">EMI Amount</h6>
                  <p class="lead fs-12">₹ {{ emiIrr.EMI_Amt.toFixed(2) }}</p>
                </div>
                <div class="col-md-3" *ngIf="cEIModel.EMI_Type != 'SingleEMI'">
                  <h6 class="fs-12">Case IRR</h6>
                  <p class="lead fs-12">{{ emiIrr.Case_IRR.toFixed(2) }} %</p>
                </div>
                <div
                  class="col-md-3"
                  [hidden]="
                    cEIModel.EMI_Type == 'Daily' ||
                    cEIModel.EMI_Type == 'Weekly' ||
                    cEIModel.EMI_Type == 'Fortnightly' ||
                    cEIModel.EMI_Type == 'ByWeekly' ||
                    cEIModel.EMI_Type == 'FourthWeekly' ||
                    cEIModel.EMI_Type == 'SingleEMI'
                  "
                >
                  <h6 class="fs-12">Disbursement IRR</h6>
                  <p class="lead fs-12">
                    {{ emiIrr.Disbursement_IRR.toFixed(2) }} %
                  </p>
                </div>
                <div class="col-md-3" *ngIf="cEIModel.EMI_Type != 'SingleEMI'">
                  <h6 class="fs-12">Margin</h6>
                  <p class="lead fs-12">₹ {{ emiIrr.Margin.toFixed(2) }}</p>
                </div>
                <div class="col-md-12">
                  <h6 class="fs-12">Remarks</h6>
                  <textarea
                    [(ngModel)]="RestructureRemark"
                    required
                    placeholder="Remarks"
                    name="RestructureRemark"
                    id="RestructureRemark"
                    class="form-control input-text-css"
                  ></textarea>
                </div>
              </div>
              <div class="row col-md-12 m-0 mt-3 justify-content-end">
                <button
                  type="button"
                  (click)="onCloseDialog()"
                  class="btn font-size-12 button-btn"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  *ngIf="emiIrr"
                  (click)="onSaveApplication()"
                  class="ml-4 btn font-size-12 button-btn"
                >
                  Save
                </button>
              </div>
            </form>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="LoanId && LoanId != 0">
  <div
    class="modal fade in"
    id="CAMReport"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    data-backdrop="false"
    style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="max-width: 1140px"
    >
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            CAM Report
          </h6>
          <button
            type="button"
            class="close"
            (click)="HideCAMReport()"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <app-main-card-info
            [loanId]="this.loanSummary.LoanId"
          ></app-main-card-info>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="agreementUploadEsign"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Initiate for E-Sign Agreement
        </h6>
        <button
          type="button"
          (click)="OnCloseAgreementModal()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>

      <div class="modal-body formborder m-3">
        <!-- File Upload Section -->
        <div class="col-md-12">
          <div class="p-2 border rounded bg-light">
            <label class="font-weight-bold mb-2">Upload Document:</label>
            <div class="d-flex align-items-center">
              <button
                class="btn btn-outline-primary btn-sm mr-2"
                (click)="docFile.click()"
                *ngIf="!selectedEsignFile"
              >
                <i class="fa fa-upload"></i> Choose File
              </button>

              <div *ngIf="selectedEsignFile" class="d-flex align-items-center">
                <i class="fa fa-file-pdf text-danger mr-2"></i>
                <span class="text-truncate" style="max-width: 200px">
                  {{ selectedEsignFile }}
                </span>
                <i
                  class="fa fa-times text-danger ml-2"
                  style="cursor: pointer"
                  (click)="removeFile(docFile)"
                ></i>
              </div>
            </div>

            <input
              #docFile
              type="file"
              [multiple]="false"
              accept="application/pdf,application/vnd.ms-excel,image/jpeg,image/png"
              (change)="fileChangeListener(docFile.files)"
              style="display: none"
            />
          </div>

          <div>
            <p>
              This E-Sign request will be delivered on Customer mail id :
              {{ this.loanSummary.Customer_Email }}
            </p>
          </div>
        </div>

        <!-- E-Sign Button -->
        <div class="col-md-12 text-right mt-3">
          <button
            type="button"
            id="ESign"
            (click)="OnESign()"
            class="btn font-size-12 button-btn"
          >
            Upload for E-Sign
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade in"
     id="SanctionUploadEsign"
     tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     data-backdrop="false"
     style="background-color: rgba(0, 0, 0, 0.5); overflow: auto">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Initiate for E-Sign Sanction
        </h6>
        <button type="button"
                (click)="OnCloseSanctionModal()"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>

      <div class="modal-body formborder m-3">
        <!-- File Upload Section -->
        <div class="col-md-12">
          <div class="p-2 border rounded bg-light">
            <label class="font-weight-bold mb-2">Upload Document:</label>
            <div class="d-flex align-items-center">
              <button class="btn btn-outline-primary btn-sm mr-2"
                      (click)="docFile12.click()"
                      *ngIf="!selectedEsignSanctionFile">
                <i class="fa fa-upload"></i> Choose File
              </button>

              <div *ngIf="selectedEsignSanctionFile" class="d-flex align-items-center">
                <i class="fa fa-file-pdf text-danger mr-2"></i>
                <span class="text-truncate" style="max-width: 200px">
                  {{ selectedEsignSanctionFile }}
                </span>
                <i class="fa fa-times text-danger ml-2"
                   style="cursor: pointer"
                   (click)="removeFile1(docFile12)"></i>
              </div>
            </div>

            <input #docFile12
                   type="file"
                   [multiple]="false"
                   accept="application/pdf,application/vnd.ms-excel,image/jpeg,image/png"
                   (change)="fileChangeListener1(docFile12.files)"
                   style="display: none" />
          </div>

          <div>
            <p>
              This E-Sign request will be delivered on Customer mail id :
              {{ this.loanSummary.Customer_Email }}
            </p>
          </div>
        </div>

        <!-- E-Sign Button -->
        <div class="col-md-12 text-right mt-3">
          <button type="button"
                  id="ESign"
                  (click)="OnESignSanction()"
                  class="btn font-size-12 button-btn">
            Upload for E-Sign Sanction
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
