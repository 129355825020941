import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
declare var $: any;
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { DatePipe } from "@angular/common";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "app-printnocletter",
  templateUrl: "./printnocletter.component.html",
  styleUrls: ["./printnocletter.component.scss"],
})
export class PrintnocletterComponent implements OnInit {
  showSpinner: boolean = false;
  applicationDetail: any;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  @Input() index: any;
  @Input("LoanId") LoanId: any;
  currentUser: any;
  templateData: any;
  content: SafeHtml;
  loading: boolean = false;
  dropdownLanguages: any[];
  coborrower1Details: any;
  guaranter1Details: any;
  guaranter2Details: any;
  coborrower2Details: any;
  ChargesBalance: any;
  constructor(
    public datepipe: DatePipe,
    private dataSharingService: DataSharingService,
    private _MasterService: MasterService,
    private route: ActivatedRoute,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private LmsService: LmsService,
    private sanitizer: DomSanitizer
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {});
  }

  ngOnInit() {
    this.templateData;
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.LMS_GetNocLatterDetailsViewModel();
  }
  LMS_GetNocLatterDetailsViewModel() {
    this._MasterService
      .Get_Template_Details({
        Loan_Id: this.LoanId,
      })
      .subscribe((res: any) => {
        this.loading = false;
        this.applicationDetail = res.Item1[0];
        console.log("Response Details Application ", res);
        this.coborrower1Details = res.Item2[0];
        this.coborrower1Details = res.Item2[1];
        this.guaranter1Details = res.Item3[0];
        this.guaranter1Details = res.Item3[1];
        this.ChargesBalance = res.Item4;
        this.Get_Template_Type();
      });
  }

  Get_Template_Type() {
    this._MasterService
      .Get_Template_Type({
        Type: "nocletter",
        ProductId: parseInt(this.applicationDetail.ProductId),
      })
      .subscribe((x: any[]) => {
        this.dropdownLanguages = x;
      });
  }

  Get_Template(Id) {
    this.loading = true;
    //let templatename = this.applicationDetail.ProductId + "$" + template
    this._MasterService
      .Get_Template_Data({
        Id: Id,
        ProductId: this.applicationDetail.ProductId,
      })
      .subscribe((res) => {
        console.log("Response x", res[0].TemplateData);
        this.loading = false;
        this.templateData = res[0].TemplateData;

        //replace Main Borrower Details
        this.templateData = this.templateData.replace(
          /\[Application_No\]/g,
          this.applicationDetail.Application_No
        );
        this.templateData = this.templateData.replace(
          /\[Loan_No\]/g,
          this.applicationDetail.Loan_No == null
            ? ""
            : this.applicationDetail.Loan_No
        );
        this.templateData = this.templateData.replace(
          /\[Customer_Name\]/g,
          this.applicationDetail.Customer_Name == null
            ? ""
            : this.applicationDetail.Customer_Name.toUpperCase()
        );
        this.templateData = this.templateData.replace(
          /\[Father_Name\]/g,
          this.applicationDetail.Father_Name == null
            ? ""
            : this.applicationDetail.Father_Name.toUpperCase()
        );
        this.templateData = this.templateData.replace(
          /\[Customer_Address\]/g,
          this.applicationDetail.Customer_Address == null
            ? ""
            : this.applicationDetail.Customer_Address.toUpperCase()
        );
        this.templateData = this.templateData.replace(
          /\[Customer_PhoneNo\]/g,
          this.applicationDetail.Customer_PhoneNo == null
            ? ""
            : this.applicationDetail.Customer_PhoneNo
        );
        this.templateData = this.templateData.replace(
          /\[Loan_Product\]/g,
          this.applicationDetail.Loan_Product == null
            ? ""
            : this.applicationDetail.Loan_Product
        );
        this.templateData = this.templateData.replace(
          /\[Loan_Amount\]/g,
          this.applicationDetail.Loan_Amount == null
            ? ""
            : this.applicationDetail.Loan_Amount
        );
        this.templateData = this.templateData.replace(
          /\[Overdue_Installment_No\]/g,
          this.applicationDetail.OverDUE_EMI == null
            ? ""
            : this.applicationDetail.OverDUE_EMI
        );
        this.templateData = this.templateData.replace(
          /\[Margin_Amount\]/g,
          this.applicationDetail.Margin_Amount == null
            ? ""
            : this.applicationDetail.Margin_Amount
        );
        this.templateData = this.templateData.replace(
          /\[Tenure_Month\]/g,
          this.applicationDetail.Tenure_Month == null
            ? ""
            : this.applicationDetail.Tenure_Month
        );
        this.templateData = this.templateData.replace(
          /\[No_Of_instalment\]/g,
          this.applicationDetail.No_Of_instalment == null
            ? ""
            : this.applicationDetail.No_Of_instalment
        );
        this.templateData = this.templateData.replace(
          /\[EMI_Amount\]/g,
          this.applicationDetail.EMI_Amount == null
            ? ""
            : this.applicationDetail.EMI_Amount
        );
        this.templateData = this.templateData.replace(
          /\[EMI_Advance\]/g,
          this.applicationDetail.EMI_Advance == null
            ? ""
            : this.applicationDetail.EMI_Advance
        );
        this.templateData = this.templateData.replace(
          /\[ROI\]/g,
          this.applicationDetail.ROI == null ? "" : this.applicationDetail.ROI
        );
        this.templateData = this.templateData.replace(
          /\[IRR\]/g,
          this.applicationDetail.IRR == null ? "" : this.applicationDetail.IRR
        );
        this.templateData = this.templateData.replace(
          /\[Date\]/g,
          this.applicationDetail.Date == null ? "" : this.applicationDetail.Date
        );
        this.templateData = this.templateData.replace(
          /\[Date_Of_EMI\]/g,
          this.applicationDetail.Date_Of_EMI == null
            ? ""
            : this.applicationDetail.Date_Of_EMI
        );
        this.templateData = this.templateData.replace(
          /\[Day_Of_EMI\]/g,
          this.applicationDetail.Day_Of_EMI == null
            ? ""
            : this.applicationDetail.Day_Of_EMI
        );
        this.templateData = this.templateData.replace(
          /\[EMI_Frequency\]/g,
          this.applicationDetail.EMI_Frequency == null
            ? ""
            : this.applicationDetail.EMI_Frequency
        );
        this.templateData = this.templateData.replace(
          /\[Customer_Relation\]/g,
          this.applicationDetail.Customer_Relation == null
            ? ""
            : this.applicationDetail.Customer_Relation
        );
        this.templateData = this.templateData.replace(
          /\[Customer_Tehsil\]/g,
          this.applicationDetail.Customer_Tehsil == null
            ? ""
            : this.applicationDetail.Customer_Tehsil
        );
        this.templateData = this.templateData.replace(
          /\[Customer_District\]/g,
          this.applicationDetail.Customer_District == null
            ? ""
            : this.applicationDetail.Customer_District
        );
        this.templateData = this.templateData.replace(
          /\[Customer_State\]/g,
          this.applicationDetail.Customer_State == null
            ? ""
            : this.applicationDetail.Customer_State
        );
        this.templateData = this.templateData.replace(
          /\[Customer_Pin_Code\]/g,
          this.applicationDetail.Customer_Pin_Code == null
            ? ""
            : this.applicationDetail.Customer_Pin_Code
        );
        this.templateData = this.templateData.replace(
          /\[Exposure_Amount\]/g,
          this.applicationDetail.Exposure_Amount == null
            ? ""
            : this.applicationDetail.Exposure_Amount
        );
        this.templateData = this.templateData.replace(
          /\[Overdue_Amount\]/g,
          this.applicationDetail.Overdue_Amount == null
            ? ""
            : this.applicationDetail.Overdue_Amount
        );

        this.templateData = this.templateData.replace(
          /\[DisbursementAmount\]/g,
          this.applicationDetail.DisbursementAmount == null
            ? ""
            : this.applicationDetail.DisbursementAmount
        );
        this.templateData = this.templateData.replace(
          /\[DealerName\]/g,
          this.applicationDetail.DealerName == null
            ? ""
            : this.applicationDetail.DealerName
        );
        this.templateData = this.templateData.replace(
          /\[VehicleModel\]/g,
          this.applicationDetail.Vehicle_Model == null
            ? ""
            : this.applicationDetail.Vehicle_Model
        );
        this.templateData = this.templateData.replace(
          /\[SalesExecutive\]/g,
          this.applicationDetail.SalesExecutive == null
            ? ""
            : this.applicationDetail.SalesExecutive
        );
        this.templateData = this.templateData.replace(
          /\[AssetsValue\]/g,
          this.applicationDetail.Application_AssetCost == null
            ? ""
            : this.applicationDetail.Application_AssetCost
        );
        this.templateData = this.templateData.replace(
          /\[LTV\]/g,
          this.applicationDetail.LTV == null ? "" : this.applicationDetail.LTV
        );
        this.templateData = this.templateData.replace(
          /\[LoanDate\]/g,
          this.applicationDetail.Loan_Date == null
            ? ""
            : this.applicationDetail.Loan_Date
        );
        this.templateData = this.templateData.replace(
          /\[CloseDate\]/g,
          this.applicationDetail.LoanCloseDate == null
            ? ""
            : this.applicationDetail.LoanCloseDate
        );
        this.templateData = this.templateData.replace(
          /\[Total_Deduction\]/g,
          this.applicationDetail.Total_Deduction == null
            ? ""
            : this.applicationDetail.Total_Deduction
        );
        //new

        this.templateData = this.templateData.replace(
          /\[Expiry_Date\]/g,
          this.applicationDetail.Expiry_Date == null
            ? ""
            : this.applicationDetail.Expiry_Date
        );
        this.templateData = this.templateData.replace(
          /\[Customer_Nach_Bank\]/g,
          this.applicationDetail.Customer_Nach_Bank == null
            ? ""
            : this.applicationDetail.Customer_Nach_Bank
        );
        this.templateData = this.templateData.replace(
          /\[Loan_Purpose\]/g,
          this.applicationDetail.Loan_Purpose == null
            ? ""
            : this.applicationDetail.Loan_Purpose
        );
        this.templateData = this.templateData.replace(
          /\[Loan_Amount_InWord\]/g,
          this.applicationDetail.Loan_Amount_InWord == null
            ? ""
            : this.applicationDetail.Loan_Amount_InWord
        );
        this.templateData = this.templateData.replace(
          /\[Property_Address\]/g,
          this.applicationDetail.Property_Address == null
            ? ""
            : this.applicationDetail.Property_Address
        );
        this.templateData = this.templateData.replace(
          /\[Type_of_Property\]/g,
          this.applicationDetail.Type_of_Property == null
            ? ""
            : this.applicationDetail.Type_of_Property
        );
        this.templateData = this.templateData.replace(
          /\[Nature_of_Property\]/g,
          this.applicationDetail.Nature_of_Property == null
            ? ""
            : this.applicationDetail.Nature_of_Property
        );
        this.templateData = this.templateData.replace(
          /\[Ownership_Type\]/g,
          this.applicationDetail.Ownership_Type == null
            ? ""
            : this.applicationDetail.Ownership_Type
        );

        this.templateData = this.templateData.replace(
          /\[Ownership_Document\]/g,
          this.applicationDetail.Ownership_Document == null
            ? ""
            : this.applicationDetail.Ownership_Document
        );
        this.templateData = this.templateData.replace(
          /\[Unit_of_Measurement\]/g,
          this.applicationDetail.Unit_of_Measurement == null
            ? ""
            : this.applicationDetail.Unit_of_Measurement
        );
        this.templateData = this.templateData.replace(
          /\[Total_Area\]/g,
          this.applicationDetail.Total_Area == null
            ? ""
            : this.applicationDetail.Total_Area
        );
        this.templateData = this.templateData.replace(
          /\[Constructed_Area\]/g,
          this.applicationDetail.Constructed_Area == null
            ? ""
            : this.applicationDetail.Constructed_Area
        );

        this.templateData = this.templateData.replace(
          /\[Engine_No\]/g,
          this.applicationDetail.Engine_No == null
            ? ""
            : this.applicationDetail.Engine_No
        );
        this.templateData = this.templateData.replace(
          /\[Chassis_No\]/g,
          this.applicationDetail.Chassis_No == null
            ? ""
            : this.applicationDetail.Chassis_No
        );
        this.templateData = this.templateData.replace(
          /\[Vehicle_Registation_No\]/g,
          this.applicationDetail.Vehicle_Registation_No == null
            ? ""
            : this.applicationDetail.Vehicle_Registation_No
        );
        this.templateData = this.templateData.replace(
          /\[Identification_1\]/g,
          this.applicationDetail.Identification_1 == null
            ? ""
            : this.applicationDetail.Identification_1
        );
        this.templateData = this.templateData.replace(
          /\[Identification_2\]/g,
          this.applicationDetail.Identification_2 == null
            ? ""
            : this.applicationDetail.Identification_2
        );
        this.templateData = this.templateData.replace(
          /\[Customer_Email\]/g,
          this.applicationDetail.Customer_Email == null
            ? ""
            : this.applicationDetail.Customer_Email
        );
        this.templateData = this.templateData.replace(
          /\[Customer_PanNo\]/g,
          this.applicationDetail.Customer_PanNo == null
            ? ""
            : this.applicationDetail.Customer_PanNo
        );
        //Replace Coborrower Details
        this.templateData = this.templateData.replace(
          /\[COBorrower_Name1\]/g,
          this.coborrower1Details
            ? this.coborrower1Details["Customer_Name"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[COBorrower_Relation1\]/g,
          this.coborrower1Details
            ? this.coborrower1Details["Customer_Relation"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[COBorrower_Address1\]/g,
          this.coborrower1Details
            ? this.coborrower1Details["Customer_Address"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[COBorrower_PhoneNo1\]/g,
          this.coborrower1Details
            ? this.coborrower1Details["Customer_PhoneNo"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[COBorrower_Tehsil1\]/g,
          this.coborrower1Details
            ? this.coborrower1Details["Customer_Tehsil"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[COBorrower_District1\]/g,
          this.coborrower1Details
            ? this.coborrower1Details["Customer_District"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[COBorrower_State1\]/g,
          this.coborrower1Details
            ? this.coborrower1Details["Customer_State"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[COBorrower_Pin_Code1\]/g,
          this.coborrower1Details
            ? this.coborrower1Details["Customer_Pin_Code"]
            : ""
        );

        //Replace Coborrower Details

        this.templateData = this.templateData.replace(
          /\[COBorrower_Name2\]/g,
          this.coborrower2Details
            ? this.coborrower2Details["Customer_Name"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[COBorrower_Relation2\]/g,
          this.coborrower2Details
            ? this.coborrower2Details["Customer_Relation"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[COBorrower_Address2\]/g,
          this.coborrower2Details
            ? this.coborrower2Details["Customer_Address"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[COBorrower_PhoneNo2\]/g,
          this.coborrower2Details
            ? this.coborrower2Details["Customer_PhoneNo"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[COBorrower_Tehsil2\]/g,
          this.coborrower2Details
            ? this.coborrower2Details["Customer_Tehsil"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[COBorrower_District2\]/g,
          this.coborrower2Details
            ? this.coborrower2Details["Customer_District"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[COBorrower_State2\]/g,
          this.coborrower2Details
            ? this.coborrower2Details["Customer_State"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[COBorrower_Pin_Code2\]/g,
          this.coborrower2Details
            ? this.coborrower2Details["Customer_Pin_Code"]
            : ""
        );

        //Replace Guaranter1
        this.templateData = this.templateData.replace(
          /\[Guarantor_Name1\]/g,
          this.guaranter1Details ? this.guaranter1Details["Customer_Name"] : ""
        );
        this.templateData = this.templateData.replace(
          /\[Guarantor_Relation1\]/g,
          this.guaranter1Details
            ? this.guaranter1Details["Customer_Relation"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[Guarantor_Address1\]/g,
          this.guaranter1Details
            ? this.guaranter1Details["Customer_Address"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[Guarantor_PhoneNo1\]/g,
          this.guaranter1Details
            ? this.guaranter1Details["Customer_PhoneNo"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[Guarantor_Tehsil1\]/g,
          this.guaranter1Details
            ? this.guaranter1Details["Customer_Tehsil"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[Guarantor_District1\]/g,
          this.guaranter1Details
            ? this.guaranter1Details["Customer_District"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[Guarantor_State1\]/g,
          this.guaranter1Details ? this.guaranter1Details["Customer_State"] : ""
        );
        this.templateData = this.templateData.replace(
          /\[Guarantor_Pin_Code1\]/g,
          this.guaranter1Details
            ? this.guaranter1Details["Customer_Pin_Code"]
            : ""
        );

        //Replace Guaranter2

        this.templateData = this.templateData.replace(
          /\[Guarantor_Name2\]/g,
          this.guaranter2Details ? this.guaranter2Details["Customer_Name"] : ""
        );
        this.templateData = this.templateData.replace(
          /\[Guarantor_Relation2\]/g,
          this.guaranter2Details
            ? this.guaranter2Details["Customer_Relation"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[Guarantor_Address2\]/g,
          this.guaranter2Details
            ? this.guaranter2Details["Customer_Address"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[Guarantor_PhoneNo2\]/g,
          this.guaranter2Details
            ? this.guaranter2Details["Customer_PhoneNo"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[Guarantor_Tehsil2\]/g,
          this.guaranter2Details
            ? this.guaranter2Details["Customer_Tehsil"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[Guarantor_District2\]/g,
          this.guaranter2Details
            ? this.guaranter2Details["Customer_District"]
            : ""
        );
        this.templateData = this.templateData.replace(
          /\[Guarantor_State2\]/g,
          this.guaranter2Details ? this.guaranter2Details["Customer_State"] : ""
        );
        this.templateData = this.templateData.replace(
          /\[Guarantor_Pin_Code2\]/g,
          this.guaranter2Details
            ? this.guaranter2Details["Customer_Pin_Code"]
            : ""
        );
        if (this.ChargesBalance && this.ChargesBalance.length > 0) {
          this.ChargesBalance.forEach((value, index) => {
            this.templateData = this.templateData.replace(value.Charges_Head, value.Balance ? value.Balance : "");

          })
        }
        this.content = this.sanitizer.bypassSecurityTrustHtml(
          this.templateData
        );
        $("#NOCmodel").modal("show");
        $("#NOCmodel").css("z-index", "1050");
      });
  }

  OnClose() {
    $(`#NOCmodel`).modal("hide");
  }
  printLetter() {
    this.LmsService.LMS_Save_NOC_Detail({
      LoanId: this.LoanId,
      LoginUserId: this.currentUser.userId,
    }).subscribe((res: any) => {
      if (res.length > 0) {
        setTimeout(() => {
          this.downloadPdf();
        }, 1000);
      }
    });
  }
  downloadPdf() {
    var prtContent = document.getElementById("pdfNOCDownload");
    console.log("prtContent.innerHTML", prtContent.innerHTML);
    var WinPrint = window.open("", "", "left=100,top=100");
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
  }
}
