import {
  Component,
  OnInit,
  ViewChild,
  Input,
  EventEmitter,
  Output,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { LmsService } from "../../_Lms/services/lms.service";
import { MasterService } from "../../Shared/app.Masters.Service";
declare var $: any;
import { ActivatedRoute, Params } from "@angular/router";
import * as moment from "moment";
import { constantUrl } from "src/app/Shared/constantUrl";
import { CommonFunctions } from "src/app/CommonComponent/function/commonfunctions";
import { ToWords } from "to-words";
import { ExcelExportService } from "src/app/excel-export.service";

@Component({
  selector: "app-gst-detail",
  templateUrl: "./gst-detail.component.html",
  styleUrls: ["./gst-detail.component.scss"],
})
export class GstDetailComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  TabDisabled: boolean = false;
  ProductSummary: any = { ProductId: 0 };
  ProductId: any;
  currentGSTTab: number = 0;
  lastFillTab: number = 0;
  Add: any;

  FromDateInput: any;
  ToDateInput: any;

  FromDateOutput: any;
  ToDateOutput: any;

  docModal: any = [];
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  displayedLoanColumns: string[] = [
    "Type",
    "CaseNo",
    "Customer",
    "Branch",
    "Category",
    "AccountHead",
    "AmountDR",
    "AmountCR",
  ];
  displayedAccountingColumns: string[] = [
    "AccountHead",
    "Branch",
    "AmountDR",
    "AmountCR",
  ];

  @Input() voucherId: any;
  // showSpinner: boolean = false;
  // currentUser: any;
  DataSource: any;
  BranchesList: any[] = [];
  ProductList: any = [];
  BranchId: any = 0;
  // ProductId: any = 0;
  selectedId: any;
  JsonData: any[] = [];
  JsonDataInput: any[] = [];
  JsonDataOutput: any[] = [];
  gstDetail: any = {};
  AccountNameDropdown: any = "0";
  AccountNameDropdownInput: any = "0";
  AccountNameDropdownOutput: any = "0";

  FyearId: any;
  maxDate: any;
  minDate: any;

  CompanyProfileBaseUrl: any = constantUrl.apiProfilePicUrl;

  FromDate: any = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  ToDate: any = new Date();

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  @ViewChild(MatSort) sort!: MatSort;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = [
    "Id",
    "InvoiceDate",
    "InvoiceNo",
    "LoanacNo",
    "PartyName",

    "Acc_GSTNo",
    "Account_Name",
    "HSN_Code",
    "Taxable_Amount_Input" /*'SGST_Rate_Input', */,
    "SGST_Amount_Input",
    /*'CGST_Rate_Input',*/ "CGST_Amount_Input",
    /*'IGST_Rate_Input',*/ "IGST_Amount_Input",
    "Invoice_Amount_Input",
    "Taxable_Amount_Output",
    /*'SGST_Rate_Output',*/ "SGST_Amount_Output",
    /*'CGST_Rate_Output',*/ "CGST_Amount_Output" /*'IGST_Rate_Output',*/,
    "IGST_Amount_Output",
    "Invoice_Amount_Output",
    "Action",
  ];

  displayedColumnsInput: string[] = [
    "Id",
    "InvoiceDate",
    "InvoiceNo",
    "LoanacNo",
    "PartyName",

    "Acc_GSTNo",
    "Account_Name",
    "HSN_Code",
    "Taxable_Amount_Input",
    "SGST_Amount_Input",
    "CGST_Amount_Input",
    "IGST_Amount_Input",
    "Invoice_Amount_Input",
    "Action",
  ];

  displayedColumnsOutput: string[] = [
    "Id",
    "InvoiceDate",
    "InvoiceNo",
    "LoanacNo",
    "PartyName",
    "Acc_GSTNo",
    "Account_Name",
    "HSN_Code",
    "Taxable_Amount_Output",
    "SGST_Amount_Output",
    "CGST_Amount_Output",
    "IGST_Amount_Output",
    "Invoice_Amount_Output",
    "Action",
  ];
  row: any;
  AmountInWord: string = "";
  JsonData2: any[] = [];
  originalData: any[] = [];
  accountList: any[] = [];
  DataSourceInput: any;
  DataSourceOutput: any;
  // AmountInWord: string;

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private lmsService: LmsService,
    private _MasterService: MasterService,
    private _commonfunctions: CommonFunctions,
    private excelExportService: ExcelExportService
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("GST Detail");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.GetAccAccountingList();

    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      console.log("paran", this.FyearId);
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.minDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          this.maxDate = new Date(moment(res[0].fyent).format("MM/DD/YYYY"));
          this.FromDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          if (this.maxDate > new Date() && this.minDate < new Date())
            this.ToDate = new Date();
          else this.ToDate = this.maxDate;

          this.FromDateInput = new Date(
            moment(res[0].fystart).format("MM/DD/YYYY")
          );
          if (this.maxDate > new Date() && this.minDate < new Date())
            this.ToDateInput = new Date();
          else this.ToDateInput = this.maxDate;

          this.FromDateOutput = new Date(
            moment(res[0].fystart).format("MM/DD/YYYY")
          );
          if (this.maxDate > new Date() && this.minDate < new Date())
            this.ToDateOutput = new Date();
          else this.ToDateOutput = this.maxDate;

          this.DataSource = null;
          this.DataSourceInput = null;
          this.DataSourceOutput = null;
        });
    });
  }

  DateChange() {
    if (this.FromDate != "" && this.ToDate != "") {
      if (this.FromDate > this.ToDate) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "To Date will be greater than to From Date.",
          ...this.configSuccess,
        });
        this.FromDate = "";
        this.ToDate = "";
      }
    }
  }
  goToDetailModel(row: any) {
    // this.showSpinner = true;

    this.lmsService
      .GSTReceiptDetails({ LoanId: 0, VoucherId: row.InvoiceNo })
      .subscribe((result: any) => {
        // console.log("Receipt Details : ", result);
        this.originalData = result.Item2;
        this.JsonData2 = this.originalData.filter(
          (originalData) => originalData.TranType == "CR"
        );
        //  this.JsonData2 = JSON.parse(JSON.stringify(result.Item2));
        console.log("Filter Output", this.JsonData2);
        this.gstDetail = row;
        console.log(this.totaltypewisemodal("Total"), "gfdgfdgd");
        this.AmountInWord = new ToWords().convert(
          parseInt(this.totaltypewisemodal("Total"))
        );

        $("#invoiceBill").modal("show");
        $("#invoiceBill").css("z-index", "1050");

        // console.log("Output",this.originalData)

        // if (result.length > 0) {
        //   this.JsonData2 = JSON.parse(JSON.stringify(result));

        // } else {
        //   this.snackBar.openFromComponent(SnackbarComponent, {
        //     data: "No data found",
        //     ...this.configSuccess,
        //   });
        // }
      });
  }

  GetAccAccountingList() {
    this.lmsService.GetAccAccountingList({}).subscribe((res: any) => {
      console.log("res", res);
      this.accountList = res.filter((data) => data.GSTEnable === "Yes");
      console.log("AccountList", this.accountList);
    });
  }

  OnSearchGSTDetail() {
    this.showSpinner = true;
    this.lmsService
      .GST_Detail({
        From: this.FromDate,
        To: this.ToDate,
        accountId: this.AccountNameDropdown,
      })
      .subscribe((res: any) => {
        console.log("Result123 : ", res);

        if (res.length > 0) {
          this.JsonData = JSON.parse(JSON.stringify(res));
          this.DataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.DataSource.sort = this.sort;
          this.DataSource.paginator = this.paginator;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
        }
        this.showSpinner = false;
      });
  }

  OnSearchGSTDetailOutput() {
    this.showSpinner = true;
    this.lmsService
      .GST_Detail({
        From: this.FromDateOutput,
        To: this.ToDateOutput,
        accountId: this.AccountNameDropdownOutput,
      })
      .subscribe((res: any) => {
        console.log("Result123 : ", res);

        if (res.length > 0) {
          this.JsonDataOutput = JSON.parse(JSON.stringify(res));
          this.JsonDataOutput = this.JsonDataOutput.filter(
            (data) => data.Taxable_Amount_Output > 0
          );
          this.DataSourceOutput = new MatTableDataSource(this.JsonDataOutput);
          this.DataSourceOutput.sort = this.sort;
          this.DataSourceOutput.paginator = this.paginator;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
        }
        this.showSpinner = false;
      });
  }

  OnSearchGSTDetailInput() {
    this.showSpinner = true;
    this.lmsService
      .GST_Detail({
        From: this.FromDateInput,
        To: this.ToDateInput,
        accountId: this.AccountNameDropdownInput,
      })
      .subscribe((res: any) => {
        console.log("Result123 : ", res);

        if (res.length > 0) {
          this.JsonDataInput = JSON.parse(JSON.stringify(res));
          this.JsonDataInput = this.JsonDataInput.filter(
            (data) => data.Taxable_Amount_Input > 0
          );
          this.DataSourceInput = new MatTableDataSource(this.JsonDataInput);
          this.DataSourceInput.sort = this.sort;
          this.DataSourceInput.paginator = this.paginator;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
        }
        this.showSpinner = false;
      });
  }

  applyFilter(filterValue: string) {
    this.DataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterInput(filterValue: string) {
    this.DataSourceInput.filter = filterValue.trim().toLowerCase();
  }

  applyFilterOutput(filterValue: string) {
    this.DataSourceOutput.filter = filterValue.trim().toLowerCase();
  }

  totaltypewise(type) {
    if (this.DataSource != null) {
      let sum = 0;
      for (let i = 0; i < this.DataSource.filteredData.length; i++) {
        sum += Number(this.DataSource.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : "";
    }
    return 0;
  }

  totaltypewiseOutput(type) {
    if (this.DataSourceOutput != null) {
      let sum = 0;
      for (let i = 0; i < this.DataSourceOutput.filteredData.length; i++) {
        sum += Number(this.DataSourceOutput.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : "";
    }
    return 0;
  }

  totaltypewiseInput(type) {
    if (this.DataSourceInput != null) {
      let sum = 0;
      for (let i = 0; i < this.DataSourceInput.filteredData.length; i++) {
        sum += Number(this.DataSourceInput.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : "";
    }
    return 0;
  }

  totaltypewisemodal(row): string {
    if (this.JsonData2 != null) {
      let sum = 0;
      for (let i = 0; i < this.JsonData2.length; i++) {
        sum += Number(this.JsonData2[i][row]);
      }
      return sum > 0 ? sum.toFixed(2) : "";
    }
    // return 0;
    return "0";
  }

  public highlightRow(row) {
    this.selectedId = row.Id;
  }
  goToDetailModelEye(row) {
    this.onOpenViewDetailModal(row);
  }

  OnCloseVoucherModel() {
    $("#invoiceBill").modal("hide");
  }
  OnCloseVoucherViewModel() {
    $("#VoucherDetailView").modal("hide");
  }
  onOpenViewDetailModal(row) {
    this.lmsService
      .LMS_Get_Voucher_Details({ VoucherId: row.InvoiceNo })
      .subscribe((res: any) => {
        if (res.Item1.length > 0) {
          console.log("res", res);

          //  this.minDate = new Date(JSON.parse(sessionStorage.getItem("currentUser")).Data_Freeze.split('T')[0] + ' 00:00:00');
          this.voucherModel.Voucher = res.Item1[0];
          var VoucherDate = new Date(
            this.voucherModel.Voucher.VoucherDate.split("T")[0] + " 00:00:00"
          );
          this.voucherModel.Voucher_Detail = res.Item2;
          this.docModal = res.Item3;
          console.log("this.voucherModel.Voucher", this.voucherModel.Voucher);
          console.log(
            "this.voucherModel.Voucher_Detail",
            this.voucherModel.Voucher_Detail
          );

          this.voucherModel.Voucher_Detail.forEach((x) => {
            x.AmountDR = 0.0;
            x.AmountCR = 0.0;
            if (x.TranType == "CR") x.AmountCR = x.Amount;
            if (x.TranType == "DR") x.AmountDR = x.Amount;
          });
          // this.onVoucherTagChange();
          //this.VoucherPrintModel = { Voucher: this.voucherModel.Voucher, Voucher_Detail: this.voucherModel.Voucher_Detail };
          $("#VoucherDetailView").modal("show");
          $("#VoucherDetailView").css("z-index", "1050");
        }
      });
  }
  PrintDoc(divName, Header) {
    let printContents, popupWin;
    printContents = document.getElementById(divName).innerHTML;
    popupWin = window.open("", "_blank");
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title></title>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css">
          <style>
        
     

          @media print {
            @page {
              
                size: A4;
                margin: 10px 20px 20px 20px; 
                content: counter(page) "/" counter(pages);

         
             
              
            }
            #foot {
              content: counter(page) "/" counter(pages);
              counter-increment: page;
            }
            .mt-2 {
              margin-top: 0.5rem !important;
            }
            
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
}

.invoice {
  margin-top:35px;
  max-height: 100%;
  max-width: 100%;
  // margin: 0 auto;
  /* padding: 10px; */
  border: 2px solid black;
  color:black;
}

  .header {
  background-color: lightblue;
  height: 40px;
  border-bottom: 1px solid black;
  
} 

.header h2 {
  text-align: center;
  margin:0px;
  font-weight: 500;
}
   




.company-info {
  /* display: flex; */
  padding: 5px;
  margin: 0;
  border-bottom: 1px solid #000000;
  height: 200px
}
  
  
  
.info {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  margin-left: 130px;
}

.company-name {
  text-align: center;
  word-wrap: normal;
  white-space: nowrap;
  word-break: keep-all;
  font-weight: bold;
  font-size: 24px;
  margin-right: 110px;
  margin-top: 10px;
  margin-bottom: 0px;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  
}
.company-details{
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  // margin-right: 20px;
  top: 2px;
  right: 65px;
  
 
}
.logo {
  margin-top: 40px;
  // margin-left: 10px;
  position: absolute;
  top: 59px;
}

.logo img {
  max-height: 50px;
}

.party-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  border-bottom: 1px solid black;
  justify-content: start;
}
.party-details{
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-right: 1px solid black;
  padding-right: 150px;
}

.invoice-details{
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-bottom:0px;

}
.invoice-no{
  display: flex;
  justify-content: space-between;

}
.invoice-details p {
  text-align: right;
 
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  /* margin-bottom: 20px; */
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #ddd;
  padding: 3px;
  text-align: right;
  width: 56.72px;
}

.invoice-table th {
  background-color: #f0f0f0;
}
.Total-Amount{
  /* width:800px;
  height:70px;
  border:1px solid black; 
  margin: 40px; 
  position: absolute;
  left: 240px; */
  margin-bottom: 50px;
}
.amount{
  text-decoration: underline;
  margin:5px;
}

.footer{
  position:relative;
  top:5px;
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  padding:10px;
  align-items: end;
}
#sign{
  margin-top:30px;
  margin-left:0px;
}
         
          </style>
        </head>
        <body onload="window.print(); window.close()">${printContents}
        <footer></footer>
        </body>
      </html>`);
    popupWin.document.close();
  }

  exportToExcel(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("gst_details") as HTMLTableElement;
    const worksheetName = "GST DETAILS"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  exportToExcelInput(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "gst_details_input"
    ) as HTMLTableElement;
    const worksheetName = "GST DETAILS INPUT"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  exportToExcelOutput(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "gst_details_Output"
    ) as HTMLTableElement;
    const worksheetName = "GST DETAILS OUTPUT"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  // exportToExcelOutput() {
  //   this.excelExportService.exportMatTableToExcel(
  //     this.DataSourceOutput,
  //     this.getDisplayedColumnsOutput(),
  //     "GST DETAILS OUTPUT"
  //   );
  // }

  // getDisplayedColumns() {
  //   return this.displayedColumns.map((column) => {
  //     return {
  //       header: column,
  //       field: column,
  //     };
  //   });
  // }

  // getDisplayedColumnsInput() {
  //   return this.displayedColumnsInput.map((column) => {
  //     return {
  //       header: column,
  //       field: column,
  //     };
  //   });
  // }

  // getDisplayedColumnsOutput() {
  //   return this.displayedColumnsOutput.map((column) => {
  //     return {
  //       header: column,
  //       field: column,
  //     };
  //   });
  // }

  onTabChange(event: any) {
    this.currentGSTTab = event.index;
  }

  calculateTranTypeCR() {
    if (this.voucherModel.Voucher_Detail != null) {
      let sum = 0.0;
      for (let i = 0; i < this.voucherModel.Voucher_Detail.length; i++) {
        sum += Number(this.voucherModel.Voucher_Detail[i]["AmountCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTranTypeDR() {
    if (this.voucherModel.Voucher_Detail != null) {
      let sum = 0.0;
      for (let i = 0; i < this.voucherModel.Voucher_Detail.length; i++) {
        sum += Number(this.voucherModel.Voucher_Detail[i]["AmountDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
}
