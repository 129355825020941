import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { LosService } from "../../_LOS/services/los.service";
import { constantUrl } from "../../Shared/constantUrl";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { LmsService } from "../services/lms.service";
import * as moment from "moment";
declare var $: any;

@Component({
  selector: "app-lms-loan-info",
  templateUrl: "./loan-info.component.html",
  styleUrls: ["./loan-info.component.scss"],
})
export class LmsLoanInfoComponent implements OnInit {
  // disabled: boolean = false;
  private data: any;
  showSpinner: boolean = false;

  loading: boolean = false;
  SUITFILEDSTATUSList: any[] = [];

  BranchesList: any[] = [];

  areaData: any = [];
  branchHeadData: any = [];

  collectionArea: any = [];
  subAreaData: any = [];
  addressList: any = [];
  leadSourceDropdown: any = [];
  loanSegmentList: any[] = [];

  partnerDropdown: any = [];

  SalesExecutiveData: any = [];
  CollectionExecutiveData: any = [];
  SourceAreaData: any = [];
  LoanAccounting: any[] = [];
  ProductList: any[] = [];
  CategoryList: any[] = [];
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  CustomerPicDetail: any;
  @Input() Type: any = "";
  @Input() ApplicationId: any;
  @Input() loanSummary: any;
  @Input() customerId: any = "0";
  CRC_Type: any;
  @Input() isNew: boolean;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  loanInfoModel: any = {};
  currentDate: Date = new Date();
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  customerData: any = {};
  PageAcess: any;
  isNOCBlocked: any;
  remarksNOC: any;
  values = "";
  CustomerViewDetailRequestModel: RequestModel = new RequestModel();
  EMIDetails: any;
  ApplicationDetail: any;
  CustomerAddress: any;
  loanSegementView: any;
  currentUser: any;

  constructor(
    private route: ActivatedRoute,
    private _Route: Router,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private losService: LosService,
    private LmsService: LmsService
  ) {
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
    let AllPage = JSON.parse(sessionStorage.getItem("AllPageAcess"));
    if (AllPage != null) {
      this.PageAcess = AllPage.filter((x) => x.Page_Name == "Loan Info");
      console.log("layout page", this.PageAcess);
      if (
        this.PageAcess == "" ||
        this.PageAcess == null ||
        this.PageAcess == undefined
      ) {
        this.RedirectTo();
      }
    }
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Loan Info");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    console.log("Loan Summary", this.loanSummary);
    this.loanInfoModel = {
      AppInfo: {
        Int_Id: 0,
        LoanCreatedBy: this.data.userId,
        Branch_Id: "",
        ApplicationNo: "",
        ApplicationDate: new Date(),
        LoanProduct_Id: "",
        IsActive: 1,
        isDisable: false,
        Type: "LMS",
      },
      LoanInfo: {
        Int_Id: 0,
        CollectionExecutive: "0",
        SelectSourceArea: "",
        SelectSubarea: "0",
        SalesExecutive: "",
        SelectArea: "0",
        LeadReference: "",
        LeadSourceName: "",
        SelectSourceType: "",
        SelectBranchHead: "",
        LoanPurpose: "",
        Loan_Id: "",
        IsActive: 1,
        isEdit: true,
        SUITFILEDSTATUS: 0,
        CRC_LoanType: 0,
        Loan_Segment: 0,
      },
    };

    console.log("Loan Info Model", this.loanInfoModel);

    this.Get_LMS_Customer_Loan_EmiDetails();

    if (this.Type.toLowerCase() == "a") {
      this.GET_LOANINFOBYLOS();
    }
    this.getCRCType();

    this.getLeadSourceDropdown();
    if (!this.isNew) {
      this.get_Save_LoanInfo();
    }
    if (this.isNew && this.Type.toLowerCase() !== "a") {
      this.getBranchesList();
      this.getProductDropdown();
    }

    this.LmsService.LMS_Commaon_Master({
      Commands: "Select",
      Type: "SUIT FILED STATUS",
    }).subscribe((res: any) => {
      this.SUITFILEDSTATUSList = res;
    });

    this.getLoanSegment();
  }

  getLoanSegment() {
    this.LmsService.LMS_Commaon_Master({
      Commands: "Select",
      Type: "LoanSegment",
    }).subscribe((res: any) => {
      this.loanSegmentList = res;
    });
  }

  GET_LOANINFOBYLOS() {
    this.LmsService.GET_LOANINFOBYLOS({
      ApplicationIdendity: this.ApplicationId,
      SearchBy: "LoanInfo",
    }).subscribe((res: any) => {
      this.loanInfoModel.AppInfo.Branch_Id = res[0].BranchId;
      this.loanInfoModel.AppInfo.ApplicationNo = res[0].ApplicationNo;
      this.loanInfoModel.AppInfo.ApplicationDate = new Date(
        res[0].Application_CreateOn
      );
      this.loanInfoModel.AppInfo.LoanProduct_Id = res[0].LoanProduct;
      this.loanInfoModel.AppInfo.isDisable = true;
      this.loanInfoModel.LoanInfo.LoanPurpose = res[0].LoanPurpose;
      this.loanInfoModel.LoanInfo.SelectSourceType = res[0].SourceType;
      this.loanInfoModel.LoanInfo.SalesExecutive = res[0].salesEx;
      this.loanInfoModel.LoanInfo.LeadReference = res[0].SourceName;
      // this.loanInfoModel.LoanInfo.Loan_Segment = res[0].LoanSegment;
      this.BranchesList.push({
        BranchId: res[0].BranchId,
        Branch_Name: res[0].BranchName,
      });
      this.ProductList.push({
        ProductId: res[0].LoanProduct,
        Product: res[0].ProductName,
        Product_IsActive: true,
      });

      this.getBranchHead(this.loanInfoModel.AppInfo.Branch_Id);
      this.getAreaByBranch(this.loanInfoModel.AppInfo.Branch_Id);
      this.GetCollectionExecutive(
        this.loanInfoModel.AppInfo.Branch_Id,
        this.loanInfoModel.LoanProduct_Id
      );
      this.GetSalesExecutive(
        this.loanInfoModel.AppInfo.Branch_Id,
        this.loanInfoModel.LoanProduct_Id
      );
    });
  }
  getBranchesList() {
    this.LmsService.GetBranches({ Emp_Id: this.data.userId }).subscribe(
      (result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
      }
    );
  }
  getProductDropdown() {
    this._MasterService
      .GetProductList({ Emp_Id: this.data.userId })
      .subscribe((result: any) => {
        this.ProductList = JSON.parse(JSON.stringify(result));
      });
  }
  getAppNo_AND_LoanNo() {
    this.LmsService.getAppNo_AND_LoanNo({
      Branch_Id: this.loanInfoModel.AppInfo.Branch_Id,
    }).subscribe((response: any) => {
      if (response.length > 0) {
        this.loanInfoModel.AppInfo.ApplicationNo = response[0].ApplicationNo;
      }
    });
    this.loanInfoModel.LoanInfo.SelectBranchHead = "";
    this.loanInfoModel.LoanInfo.SelectSourceArea = "";
    this.loanInfoModel.LoanInfo.SalesExecutive = "";
    this.loanInfoModel.LoanInfo.CollectionExecutive = "0";
    this.loanInfoModel.LoanInfo.SelectArea = "0";
    this.loanInfoModel.LoanInfo.SelectSourceType = "";
    this.loanInfoModel.LoanInfo.LeadSourceName = "";
    this.loanInfoModel.LoanInfo.SUITFILEDSTATUS = 0;
    this.loanInfoModel.LoanInfo.Loan_Segment = 0;
    this.loanInfoModel.LoanInfo.CRC_LoanType = 0;
    this.getBranchHead(this.loanInfoModel.AppInfo.Branch_Id);
    this.getAreaByBranch(this.loanInfoModel.AppInfo.Branch_Id);
    this.GetCollectionExecutive(
      this.loanInfoModel.AppInfo.Branch_Id,
      this.loanInfoModel.LoanProduct_Id
    );
    this.GetSalesExecutive(
      this.loanInfoModel.AppInfo.Branch_Id,
      this.loanInfoModel.LoanProduct_Id
    );
  }

  getAreaByBranch(value) {
    this.LmsService.Get_Lms_Area_ByBranch({ Branch_Id: value }).subscribe(
      (res: any) => {
        this.areaData = res;
      }
    );
  }
  changeArea(isChange: boolean = true) {
    if (isChange) {
      this.loanInfoModel.LoanInfo.SelectSubarea = "0";
    }
    this.LmsService.Get_Lms_SubArea_ByArea({
      Area_Id: Number(this.loanInfoModel.LoanInfo.SelectArea),
    }).subscribe((res: any) => {
      this.subAreaData = res;
    });
  }
  get_Save_LoanInfo() {
    this.LmsService.get_Saved_LoanInfo({
      Loan_Id: this.loanSummary.LoanId,
    }).subscribe((res: any) => {
      console.log("get_Saved_LoanInfo", res);

      this.loanInfoModel.LoanInfo = res[0];
      this.loanInfoModel.LoanInfo.SelectBranchHead = res[0].BranchHead;
      this.loanInfoModel.LoanInfo.SelectSourceType = res[0].SourceType;
      this.loanInfoModel.LoanInfo.SelectSourceArea = res[0].SourceArea;
      this.loanInfoModel.LoanInfo.SelectArea = res[0].Area;
      this.loanInfoModel.LoanInfo.SelectSubarea = res[0].Subarea;
      this.loanInfoModel.LoanInfo.Is_Noc_Block = res[0].Is_Noc_Block;
      this.loanInfoModel.LoanInfo.NOC_Reason = res[0].NOC_Reason;
      this.loanInfoModel.LoanInfo.Loan_Segment = res[0].Loan_Segment;
      console.log("loan segment", this.loanInfoModel.LoanInfo.Loan_Segment);

      this.loanInfoModel.AppInfo.ApplicationDate = new Date(
        res[0].Application_CreateOn
      );

      this.getAreaByBranch(this.loanSummary.BranchId);
      this.getBranchHead(this.loanSummary.BranchId);
      this.GetCollectionExecutive(
        this.loanSummary.BranchId,
        this.loanInfoModel.LoanProduct_Id
      );
      this.GetSalesExecutive(
        this.loanSummary.BranchId,
        this.loanSummary.ProductId
      );
      this.Get_PartnerForDropdown(false);
      this.changeArea(false);
    });
  }

  getBranchHead(value) {
    this.LmsService.Get_Lms_BranchHead_ByBranch({ Branch_Id: value }).subscribe(
      (res: any) => {
        if (res.length > 0) {
          this.branchHeadData = res;
          this.loanInfoModel.LoanInfo.SelectBranchHead =
            this.branchHeadData[0].EmpId;
        }
      }
    );
  }

  getLeadSourceDropdown() {
    this.showSpinner = true;
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
      this.showSpinner = false;
    });
  }

  Get_PartnerForDropdown(isChange: boolean = true) {
    if (
      this.loanInfoModel.LoanInfo.SelectSourceType == "Agent" ||
      this.loanInfoModel.LoanInfo.SelectSourceType == "DSA" ||
      this.loanInfoModel.LoanInfo.SelectSourceType == "Dealer"
    ) {
      this.losService
        .Get_PartnerForDropdown({
          PartnerType: this.loanInfoModel.LoanInfo.SelectSourceType,
          branchId:
            this.loanInfoModel.LoanInfo.BranchId ||
            this.loanInfoModel.AppInfo.Branch_Id,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = res;
        });
    } else if (
      this.loanInfoModel.LoanInfo.SelectSourceType == "Sales Executive" ||
      this.loanInfoModel.LoanInfo.SelectSourceType == "Telecaller Executive" ||
      this.loanInfoModel.LoanInfo.SelectSourceType == "Marketing Executive"
    ) {
      this.losService
        .getEmployeeDropdown({
          BranchId: this.loanSummary.BranchId,
          ProcessId: 1,
          ProductId: this.loanSummary.ProductId,
        })

        .subscribe((res: any) => {
          this.SalesExecutiveData = res;

          this.partnerDropdown = [];
          this.SalesExecutiveData.forEach((obj: any) => {
            this.partnerDropdown.push({
              PartnerId: obj.EmpId,
              Partner_Name: obj.EmpName,
            });
          });
        });
    } else {
      this.loanInfoModel.LoanInfo.LeadSourceName = "";
    }
  }

  GetCollectionExecutive(value, productID) {
    this.LmsService.Get_Lms_CollectionExecutive({
      Branch_Id: value,
      ProductId: productID,
    }).subscribe((response) => {
      this.CollectionExecutiveData = response;
    });
  }
  GetSalesExecutive(value, productID) {
    this.losService
      .getEmployeeDropdown({
        BranchId: value,
        ProcessId: 1,
        ProductId: productID,
      })
      .subscribe((res: any) => {
        // this.employeeDropdown = res;
        this.SalesExecutiveData = res;
        this.Get_PartnerForDropdown();
        //console.log('res', res);
      });
  }
  SaveLoanInfo() {
    if (this.isNew) {
      this.loading = true;
      if (this.Type.toLowerCase() == "a") {
        this.loanInfoModel.AppInfo.Type = "LOS";
      }
      this.LmsService.SaveLoanAccounting(this.loanInfoModel.AppInfo).subscribe(
        (res: any) => {
          this.loading = false;
          if (res[0].CODE > 0) {
            this.loanInfoModel.LoanInfo.Loan_Id = res[0].CODE;
            let _CODEENC = res[0].CODEENC;
            let data = JSON.parse(sessionStorage.getItem("currentUser"));
            this.loanInfoModel.LoanInfo.LeadSourceName =
              +this.loanInfoModel.LoanInfo.LeadSourceName;
            this.loanInfoModel.LoanInfo.LoginUserId = data.userId;
            this.LmsService.SaveLoanInfo(this.loanInfoModel.LoanInfo).subscribe(
              (response: any) => {
                if (response[0].CODE >= 0) {
                  this.LmsService.SaveNext_Loan({
                    Loan_Id: this.loanInfoModel.LoanInfo.Loan_Id,
                    CommandName: "Loan",
                    isStatus: 1,
                  }).subscribe((response) => {});
                  this.snackBar.openFromComponent(SnackbarComponent, {
                    data: response[0].MSG,
                    ...this.configSuccess,
                  });
                  // this.action.emit("next")
                  this._Route.navigate([
                    `/lms/loan-info/${encodeURIComponent(_CODEENC)}/${"L"}`,
                  ]);
                }
              }
            );
          }
        }
      );
    } else {
      if (this.loanInfoModel.LoanInfo.isEdit) {
        let data = JSON.parse(sessionStorage.getItem("currentUser"));
        this.loanInfoModel.LoanInfo.LoginUserId = data.userId;
        this.loanInfoModel.LoanInfo.Loan_Id = this.loanSummary.LoanId;
        this.loanInfoModel.LoanInfo.LeadSourceName =
          +this.loanInfoModel.LoanInfo.LeadSourceName;
        this.loanInfoModel.LoanInfo.ApplicationDate =
          this.loanInfoModel.AppInfo.ApplicationDate;
        this.loanInfoModel.LoanInfo.LoanSegmentId =
          this.loanInfoModel.LoanInfo.Loan_Segment;
        this.LmsService.SaveLoanInfo(this.loanInfoModel.LoanInfo).subscribe(
          (response) => {
            if (response[0].CODE >= 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
              this.action.emit("next");
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
            }
          }
        );
      } else {
        this.action.emit("next");
      }
    }
  }

  viewCustomerDetails() {
    this.action.emit("next");
  }

  RedirectTo() {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: "You have not authorize to access this page ?",
      ...this.configSuccess,
    });
    this._Route.navigate([`lms-dashboard/lms-modifyBooking-list`]);
    return;
  }
  getCRCType() {
    this.LmsService.GET_CRC_MASTER_BY_CRCAGENCY({}).subscribe((res: any) => {
      this.CRC_Type = res;
    });
  }

  nocModel() {
    $("#nocBlocked").modal("show");
    $("#nocBlocked").css("z-index", "1050");

    this.values = "";
  }

  OnSave() {
    this.LmsService.LMS_Saved_Is_Noc_Block({
      Loan_Id: this.loanSummary.LoanId,
      Reason: this.remarksNOC,
      Is_Noc_Block: this.loanInfoModel.LoanInfo.Is_Noc_Block == 0 ? 1 : 0,
    }).subscribe((res: any) => {
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
        this.get_Save_LoanInfo();
        this.OnClose();
        this.remarksNOC = "";
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
      }
    });
  }

  onKey(event: any) {
    // without type info
    this.values += event.preventDefault.target.value;
  }

  OnClose() {
    $("#nocBlocked").modal("hide");
  }

  Get_LMS_Customer_Loan_EmiDetails() {
    this.LmsService.LMS_GetLoanDetails({
      Loan_Id: this.loanSummary.LoanId,
    }).subscribe((res: any) => {
      console.log("Result of Get loan details :", res);
      this.showSpinner = false;
      this.CustomerAddress = res.Item3[0];

      console.log("Customer Address", this.CustomerAddress);

      this.customerData = res.Item2[0];
      this.loanSegementView = res.Item1[0].LoanSegment;
      console.log(
        "Phone no verfication console lg",
        this.customerData.PhoneNoIsVerified
      );
      if (this.customerData.ProfilePic) {
        this.CustomerPicDetail =
          this.customerProfileBaseUrl +
          this.customerData.CustomerId +
          "/" +
          this.customerData.ProfilePic;
      } else {
        this.CustomerPicDetail = "../assets/images/profile.png";
      }
      // console.log("Customer Name :", this.customerData.Customer);

      this.EMIDetails = res.Item7[0];
      console.log("Mature EMI_Amount", this.EMIDetails);
    });

    // this.CustomerViewDetailRequestModel.CustomerId =
    //   this.loanSummary.CustomerId.toString();
    // this.LmsService.Get_LMS_CustomerDetails(
    //   this.CustomerViewDetailRequestModel
    // ).subscribe((result: any) => {
    //   if (result != null) {
    //     console.log("all Result", result);
    //     this.customerData = result.Item1[0];
    //     // console.log("Result of customer data", this.customerData);
    //     this.addressList = result.Item2;
    //     // console.log("Result of customer data add", this.addressList);
    //     this.CategoryList = result.Item3;
    //     // console.log("Result of customer data add", this.CategoryList);
    //   }
    // });
  }
}
