import { Component, OnInit, ViewChild } from "@angular/core";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { ParamMap, Router, ActivatedRoute } from "@angular/router";
declare var $: any;
import { LmsService } from "../../_Lms/services/lms.service";
import { QuickLoanService } from "src/app/_QuickLoan/services/quickLoan.service";

@Component({
  selector: "app-product-account-details",
  templateUrl: "./product-account-details.component.html",
  styleUrls: ["./product-account-details.component.scss"],
})
export class ProductAccountDetailsComponent implements OnInit {
  data: any;
  showSpinner: boolean = false;
  loading: boolean = false;
  private _MasterService;
  ProductId: any = 0;
  DefaultData: any = 0;
  chargesHead: any[] = [];
  ChargesHead1: any[] = [];
  mixChargesHead: any[] = [];
  chargableAccount: any[] = [];
  ChargesModel: any = { Charges_Detail: [] };
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  SelectProductCategory: any = "";
  ProductName: string = "";
  ProductShortName: string = "";
  ProductCategoryDropdown: any = [];
  ChargesHeadId: any = 0;
  OldAccountId: any = 0;
  NewAccountId: any = "";
  SwapChanges: any = {};
  IsUpdate: boolean = false;
  IsEdit: boolean = false;
  IsDisabled: boolean = false;
  IsDisabledAccount: boolean = false;
  FilterProductId: any = "";
  ProcessDropdown: any = [];
  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private dialog: DialogService,
    private _EncrDecrService: EncrDecrService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService,
    private lmsService: LmsService,
    private _QuickLoanService: QuickLoanService
  ) {
    this._MasterService = MasterService;
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Account Product");
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.ProductId = this._EncrDecrService.decrypt(
        decodeURIComponent(param.get("Id"))
      );
      if (this.ProductId) {
        this.QuickLoan_Get_ProcessLevel();
      }
    });
    this.getPageLoad();
  }

  QuickLoan_Get_ProcessLevel() {
    this._QuickLoanService
      .QuickLoan_Get_ProcessLevel({ ProductId: this.ProductId })
      .subscribe((res: any) => {
        console.log("res", res);
        this.ProcessDropdown = res;
      });
  }

  getPageLoad() {
    this.Get_DefaultChargeHeadsForDropdown();
    this.Get_ChargeableAccountForDropdown();

    this.getProductCategory();
    this.SwapChanges = { Id: 0, ChagesHead: "", Account_HeadId: "" };
    if (Number(this.ProductId) > 0) {
      this.IsUpdate = true;
      this.getProductDetail();
      this.Get_ProductDefaultChargeableDetails();
      this.Get_WithoutDeafult_ChargeHeads();
    }
  }

  Get_DefaultChargeHeadsForDropdown() {
    this.lmsService
      .Get_DefaultChargeHeadsForDropdown({})
      .subscribe((res: any) => {
        this.DefaultData = res.length;
        this.chargesHead = res;
        this.mixChargesHead.push(...this.chargesHead);
        this.Get_ProductDefaultChargeableDetails();
      });
  }

  Get_ChargeableAccountForDropdown() {
    this.lmsService
      .Get_ChargeableAccountForDropdown({})
      .subscribe((res: any) => {
        this.chargableAccount = res;
      });
  }

  Get_ProductDefaultChargeableDetails() {
    this.lmsService
      .Get_ProductDefaultChargeableDetails({
        ProductId: this.ProductId,
        isDefault: false,
      })
      .subscribe((res: any) => {
        this.chargesHead.forEach((obj: any) => {
          let _find = res.find((ele: any) => {
            return ele.Charge_HeadId == obj.Id;
          });
          if (_find) {
            obj.select = true;
            obj.ChargeHeadId = obj.Id;
            obj.ProductId = this.ProductId;
            obj.LoginUserId = this.data.userId;
            obj.Account_HeadId = _find.Charge_Account_HeadId;
            obj.Apply_ProcessId = _find.Apply_ProcessId;
            obj.Charge_Type = _find.Charge_Type;
            obj.Charge_Values = _find.Charge_Values;
            obj.Charge_ApplyOn = _find.Charge_ApplyOn;
            obj.Type = "Old";
          } else {
            obj.ChargeHeadId = obj.Id;
            obj.ProductId = this.ProductId;
            obj.LoginUserId = this.data.userId;
            obj.select = res.count > 0 ? false : true;
            obj.Account_HeadId = "";
            obj.Apply_ProcessId = "";
            obj.Charge_Type = "";
            obj.Charge_Values = "";
            obj.Charge_ApplyOn = "";
            obj.Type = res.count > 0 ? "Old" : "New";
          }
        });
        if (res.length > 0) {
          this.IsEdit = true;
          this.IsDisabled = true;
          this.IsDisabledAccount = true;
          this.IsUpdate = false;
        }
        let data;
        data = res.filter((x) => x.Is_Deafult != 1);
        if (data.length > 0) {
          this.IsEdit = true;
          this.IsDisabled = true;
          this.IsDisabledAccount = true;
          this.IsUpdate = false;
          this.ChargesModel.Charges_Detail = data.map((obj) => {
            //Rename json key like Charge_HeadId to ChagesHead and more like this
            return {
              Select: true,
              Id: obj.Charge_HeadId,
              ChagesHead: obj.Charge_HeadId,
              Account_HeadId: obj.Charge_Account_HeadId,
              Apply_ProcessId: obj.Apply_ProcessId,
              Charge_Type: obj.Charge_Type,
              Charge_Values: obj.Charge_Values,
              Charge_ApplyOn: obj.Charge_ApplyOn,
              Type: obj.Type,
            };
          });
        }
      });
  }
  Get_WithoutDeafult_ChargeHeads() {
    this.lmsService.Get_WithoutDeafult_ChargeHeads({}).subscribe((res: any) => {
      this.ChargesHead1 = res;
      this.mixChargesHead.push(...this.ChargesHead1);
    });
  }

  onSaveNewProduct() {
    let _data = {
      ProductCharges: [],
    };
    _data.ProductCharges = JSON.parse(
      JSON.stringify(
        this.chargesHead.filter((obj: any) => {
          return obj.select;
        })
      )
    );
    _data.ProductCharges.forEach((obj: any) => {
      delete obj.select;
      delete obj.Id;
      delete obj.ChagesHead;
    });
    this.ChargesModel.Charges_Detail.forEach((obj: any) => {
      _data.ProductCharges.push({
        Id: Number(obj.ChagesHead),
        ChargeHeadId: Number(obj.ChagesHead),
        Account_HeadId: Number(obj.Account_HeadId),
        Charge_Type: obj.Charge_Type,
        Charge_Values: obj.Charge_Values.toString(),
        Charge_ApplyOn: obj.Charge_ApplyOn,
        Apply_ProcessId: obj.Apply_ProcessId,
      });
    });
    this._MasterService
      .SaveLoanProduct({ JSON: JSON.stringify(_data) })
      .subscribe((response) => {
        if (response[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.getPageLoad();
          this.IsEdit = true;
          this.IsUpdate = false;
          this.IsDisabled = true;
          this.IsDisabledAccount = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  addMoreFirstData() {
    this.ChargesModel = { Charges_Detail: [] };
    this.initializeChargesModel();
  }
  removeData(index: any) {
    this.ChargesModel.Charges_Detail.splice(index, 1);
  }
  addMoreData() {
    this.initializeChargesModel();
  }

  initializeChargesModel() {
    this.ChargesModel.Charges_Detail.push({
      Select: true,
      ChagesHead: "",
      Account_HeadId: "",
      Charge_Type: "",
      Charge_Values: "",
      Charge_ApplyOn: "",
      Type: "New",
      Id: "",
    });
  }

  onChangeChargesHead(data: any, dindex: any) {
    if (dindex > 0) {
      let _find = this.ChargesModel.Charges_Detail.find(
        (obj: any, index: any) => {
          return index != dindex && obj.ChagesHead == data.ChagesHead;
        }
      );
      if (_find != undefined && _find.ChagesHead == data.ChagesHead) {
        this.ChargesModel.Charges_Detail[dindex].ChagesHead = "";
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "This Account head already selected.",
          ...this.configSuccess,
        });
      }
    }
  }

  getProductCategory() {
    this._MasterService.GetProductCategory().subscribe((result) => {
      this.ProductCategoryDropdown = JSON.parse(JSON.stringify(result));
    });
  }
  getProductDetail() {
    this._MasterService
      .GetProductListById({ ProductId: this.ProductId })
      .subscribe((result) => {
        if (result.length > 0) {
          this.SelectProductCategory = result[0].ProductCatId;
          this.ProductName = result[0].Product;
          this.ProductShortName = result[0].ShortName;
        }
      });
  }

  onEditAccount() {
    this.IsEdit = false;
    this.IsUpdate = true;
    this.IsDisabled = false;
    this.IsDisabledAccount = true;
  }
  onSwap(charges) {
    this.ChargesHeadId = charges.Id;
    this.OldAccountId = charges.Account_HeadId;
    this.SwapChanges = charges;
    $("#SwapAccountModel").modal("show");
    $("#SwapAccountModel").css("z-index", "1050");
  }
  onCloseSwapAccountModel() {
    this.ChargesHeadId = 0;
    this.OldAccountId = 0;
    this.SwapChanges = {};
    this.SwapChanges = { Id: 0, ChagesHead: "", Account_HeadId: "" };
    $("#SwapAccountModel").modal("hide");
  }
  Swap() {
    this.dialog
      .openConfirmDialog(`Are You Sure To Swap Account?`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this._MasterService
            .Loan_ACC_Charges_Swap({
              ProductId: this.ProductId,
              ChargesheadId: this.ChargesHeadId,
              OldAccountId: this.OldAccountId,
              NewAccountId: this.NewAccountId,
            })
            .subscribe((response) => {
              if ((response[0].CODE = 0)) {
                this.onCloseSwapAccountModel();
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: response[0].MSG,
                  ...this.configSuccess,
                });
                this.getPageLoad();
              } else {
                this.getPageLoad();
                this.onCloseSwapAccountModel();
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: response[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      });
  }
}
