import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import * as moment from "moment";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "../../Shared/constantUrl";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { LmsService } from "../../_Lms/services/lms.service";
declare var $: any;
@Component({
  selector: "app-employee-lending-assignment",
  templateUrl: "./employee-lending-assignment.component.html",
  styleUrls: ["./employee-lending-assignment.component.scss"],
})
export class EmployeeLendingAssignmentComponent implements OnInit {
  RequestModel: RequestModel = new RequestModel();
  ReportingManagerRequestModel: RequestModel = new RequestModel();
  EmployeeId: any = "0";
  showSpinner: boolean = false;
  RolesDropdownData: any = [];
  ReportingManagerDropdown: any;
  loading: boolean = false;
  SelectRoleId: any = "";
  EmpManagerId: any;
  EmployeeName: any;
  LendingAssignmentList: any = [];
  BaseProductsList: any[] = [];
  BaseProductId: any = "";
  SelectProduct: any = "";
  AssignLenderList: any = [];
  EmployeeDetailData: any = {};
  IsCreateEmployee: boolean = true;
  isEdit: boolean = false;
  SelectAll: boolean = false;
  popupValue: any;
  SelectLender: any = "";
  SelectedEmpIds: any = "";
  dataSourceLenderAssign: any;
  displayedColumns: any = [];
  data: any;

  SaveEmployeeDataRequestModel: RequestModel = new RequestModel();

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  SelectLenderedId: any;
  dataSource: any;
  SelectedLenderAssignedData: any = [];

  constructor(
    private _Route: Router,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private fb: UntypedFormBuilder,
    private lmsService: LmsService
  ) {
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Employee Register");

    this.EmployeeId = sessionStorage.getItem("getEmployeeID");

    if (this.EmployeeId != "0") {
      this.isEdit = true;
      this.IsCreateEmployee = false;
      this.Get_Master();
      this.getEmployeeData();
    }

    this.getnewLenderAssignmentList();
  }

  getnewLenderAssignmentList() {
    this._MasterService
      .Get_Employee_Lender_NotAssigned_List({ EmpId: this.EmployeeId })
      .subscribe((res: any) => {
        this.AssignLenderList = res;
      });
  }

  Get_Master() {
    this.showSpinner = true;
    this._MasterService.GetRoleForDropdown().subscribe((result) => {
      this.RolesDropdownData = JSON.parse(JSON.stringify(result));
    });
    this._MasterService
      .ReportingManagerForDropdown(this.ReportingManagerRequestModel)
      .subscribe((result) => {
        this.ReportingManagerDropdown = JSON.parse(JSON.stringify(result));
      });
  }

  getEmployeeData() {
    this.LendingAssignmentList = [];
    this.RequestModel.EmpId = this.EmployeeId;
    this._MasterService
      .GetEmployeeDetails(this.RequestModel)
      .subscribe((result) => {
        this.EmployeeDetailData = result;
        if (this.EmployeeDetailData.Item1[0]) {
          this.BaseProductsList = this.EmployeeDetailData.Item6;
          this.EmployeeName =
            this.EmployeeDetailData.Item1[0].Emp_FirstName +
            " " +
            this.EmployeeDetailData.Item1[0].Emp_LastName;
          this.SelectRoleId = this.EmployeeDetailData.Item1[0].Emp_RoleId;
          this.EmpManagerId = this.EmployeeDetailData.Item1[0].Emp_ManagerId;
        }

        this.LendingAssignmentList = this.EmployeeDetailData.Item7;
        this.LendingAssignmentList.map((item) => {
          item.isActiveSelect = item.LenderAlradyAssigned;
          item.isDisable = item.LenderAlradyAssigned;
        });
      });
  }

  onNewLenderAssign() {
    this.getnewLenderAssignmentList();
    this._MasterService
      .Get_Hierarchy_Upper_Lower({
        LoginUserId: this.EmployeeId,
        Type: "Upper",
        ProductId: 0,
      })
      .subscribe((response: any) => {
        if (response.length > 0) {
          var empIds = JSON.parse(JSON.stringify(response));
          empIds.map((item) => {
            this.SelectedEmpIds = this.SelectedEmpIds + "," + item.EmpId;
          });
          this.displayedColumns = ["Emp_Name", "Designation"];
          this.dataSourceLenderAssign = JSON.parse(JSON.stringify(response));
        }
      });
    this.SelectLender = "";
    $("#LenderAssignModel").modal("show");
    $("#LenderAssignModel").css("z-index", "1050");
  }

  LenderAssignment() {
    $("#LenderAssignModel").modal("hide");
    this._MasterService
      .Save_Upper_Employee_LenderAssign({
        EmpIds: this.SelectedEmpIds,
        currentEmpId: this.EmployeeId,
        LenderId: this.SelectLender,
        LoginUserId: this.data.userId,
      })
      .subscribe((response) => {
        if (response[0].CODE == 0) {
          this.SelectedEmpIds = "";
          this.SelectLender = "";
          this.getEmployeeData();
          this.getnewLenderAssignmentList();
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        } else {
          this.SelectedEmpIds = "";
          this.SelectLender = "";
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  onChangeAssignLender(event: MatCheckboxChange, selectedLenderId) {
    this.LendingAssignmentList.map((item) => {
      if (item.Loan_Acc_CategoryId == selectedLenderId) {
        item.isActiveSelect = event.checked;
      }
    });
  }

  onDeleteAssignLender(selectedLenderId) {
    this.popupValue = "Delete";
    this.SelectLenderedId = selectedLenderId;

    this._MasterService
      .Get_Hierarchy_Upper_Lower({
        LoginUserId: this.EmployeeId,
        Type: "Lower",
        ProductId: selectedLenderId,
      })
      .subscribe((response: any) => {
        if (response.length > 0) {
          var empIds = JSON.parse(JSON.stringify(response));
          empIds.map((item) => {
            this.SelectedEmpIds = this.SelectedEmpIds + "," + item.EmpId;
          });
          this.displayedColumns = ["Emp_Name", "Designation"];
          this.dataSource = JSON.parse(JSON.stringify(response));
          $("#DeleteAssignModel").modal("show");
          $("#DeleteAssignModel").css("z-index", "1050");
        } else {
          this.DeleteAllLendingAssignment();
        }
      });
  }

  onCloseDeleteProductModel() {}

  DeleteAllLendingAssignment() {
    this._MasterService
      .Delete_Employee_Assign_Lender({
        EmpIds: this.SelectedEmpIds,
        currentEmpId: this.EmployeeId,
        LenderId: this.SelectLenderedId,
      })
      .subscribe((response) => {
        if (response[0].CODE == 1) {
          this.LendingAssignmentList.map((item) => {
            if (item.ProductId == this.SelectLenderedId) {
              item.isDisable = false;
              item.ProductAlradyAssigned = false;
              item.isActiveSelect = false;
              this.onCloseDeleteProductModel();
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
            }
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }

  onSaveEmployeeLendingAssignment() {}

  onChangeSelectAll() {
    for (let i = 0; i < this.LendingAssignmentList.length; i++) {
      if (!this.LendingAssignmentList[i].isDisable) {
        this.LendingAssignmentList[i].isActiveSelect = this.SelectAll;
      }
    }
  }

  onCloseLenderAssignModel() {}

  onSaveEmployeeDetailData() {
    this.LendingAssignmentList.map((item) => {
      this.SelectedLenderAssignedData.push({
        Id: item.EmpLenderId,
        EmpLender_EmpId: this.EmployeeId,
        EmpLender_LenderId: item.Loan_Acc_CategoryId,
        EmpLender_IsActive: item.isActiveSelect == true ? 1 : 0,
        EmpLender_CreateBy: this.data.userId,
        EmpLender_ModifyBy: this.data.userId,
      });
    });

    let saveEmployeeDetail = {
      Employee: {
        EmpId: this.EmployeeId,
      },

      EmployeeLender: this.SelectedLenderAssignedData,
    };

    this.SaveEmployeeDataRequestModel.JSON = JSON.stringify(saveEmployeeDetail);

    this._MasterService
      .SaveEmployeDetails(this.SaveEmployeeDataRequestModel)
      .subscribe((response) => {
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          //  this.OnClose();
          this.getEmployeeData();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
}
