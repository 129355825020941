import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
declare var $: any;
import { LmsService } from "../../_Lms/services/lms.service";
import { QuickLoanService } from "../services/quickLoan.service";
// import { LosService } from "../../_los/services/los.service";

// import { format } from 'core-js/core/date';
import { formatDate } from "@angular/common";
import { LosService } from "src/app/_LOS/services/los.service";
@Component({
  selector: "app-quickloan-list",
  templateUrl: "./quickloan-list.component.html",
  styleUrls: ["./quickloan-list.component.scss"],
})
export class QuickloanListComponent implements OnInit {
  ProcessComplition: any;
  currentUser: any;
  showSpinner: boolean = false;
  JsonData: any[] = [];
  ApplicationData: any[] = [];
  dataSource: any;
  AgencyDropDown: any[] = [];
  sendToModal: any = {};
  displayedColumns: string[] = [
    "Loan_Id",
    "Application_No",
    "Application_CreateOn",
    "Customer_Name",
    "Branch_Name",
    "Product_Name",
    "Application_LoanAmount",
    "Application_CreateBy",
    "Application_LoanPurpose",
    "SourceType",
    "SourceName",
    "TAT",
    "Action",
  ];
  displayedSelectedColumns: string[] = [
    "ApplicationId",
    "Application_No",
    "Customer",
    "Product",
    "LoanAmount",
    "Branch",
    "Action",
  ];
  ApplicationListData: any[] = [];
  dataSelectedSource: any = new MatTableDataSource([]);
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  CurrentDate: any = new Date();
  Processid: any;
  SelectStatus: any = "Pending";
  ProcessName: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  FileReceivHOList: any[] = [];

  LoanId: any;
  Remarks: any;
  Status: any = "";
  displayedDetailColumns: string[] = [];
  dataSourceDetail: MatTableDataSource<any> = new MatTableDataSource([]);
  TatReportsList: any;
  Application_No: any;
  CustomerInfo: any;
  Product: any;
  Branch: any;
  AddLoan: boolean = true;
  ApplicationCreatedOn: any;
  LoanAmount: any;
  LoanDuration_Month: any;
  SelectSourceType: any = "";
  partnerDropdown: any;
  SalesExecutiveData: any = [];
  LeadSourceName: string;
  leadSourceDropdown: any;

  // @ViewChild("TATTable") sort: MatSort;
  // @ViewChild("TATTable") set matSort(sort: MatSort) {
  //   if(this.sort != undefined){
  //     this.dataSourceDetail.sort = this.sort;
  //   }
  // }

  @ViewChild(MatSort) sort: MatSort;
  PendingSince: any;

  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private _EncrDecrService: EncrDecrService,
    private dialog: DialogService,
    public snackBar: MatSnackBar,
    private lmsService: LmsService,
    private route: ActivatedRoute,
    private losService: LosService,
    private _QuickLoanService: QuickLoanService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.Processid = this._EncrDecrService.decrypt(
        decodeURIComponent(param.get("Pid"))
      );
      this.ProcessName = this._EncrDecrService
        .decrypt(decodeURIComponent(param.get("PName")))
        .replace("%20", " ");
      this.dataSharingService.HeaderTitle.next(
        this.ProcessName.replace("%20", " ")
      );
      this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

      console.log("this.Processid", this.Processid);
    });
    if (new Date(this.currentUser.SubscriptionDate) <= new Date()) {
      this.AddLoan = false;
    } else {
      this.AddLoan = true;
    }
    this.QuickLoanList();

    this.Get_PartnerForDropdown();
    this.getLeadSourceDropdown();
    this.GetSalesExecutive();
  }
  LOS_GetCourierListReceiveHO() {
    this.showSpinner = true;
    if (this.SelectStatus == "Pending") {
      this.displayedColumns = [
        "CourierId",
        "CourierBy",
        "Courier",
        "NoOfApplications",
        "SendBy",
        "SendDate",
        "TrackingNo",
        "Action",
      ];
    } else {
      this.displayedColumns = [
        "CourierId",
        "CourierBy",
        "Courier",
        "NoOfApplications",
        "SendBy",
        "SendDate",
        "TrackingNo",
        "Action",
      ];
    }
    this.losService.setFilterDropdownValue({
      "file-receive-to-ho-list": this.SelectStatus,
    });
    this._QuickLoanService
      .Quick_GetCourierListReceiveHO({
        CourierStatus: this.SelectStatus,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        this.FileReceivHOList = JSON.parse(JSON.stringify(res));
        this.dataSource = new MatTableDataSource(this.FileReceivHOList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
      });
  }
  QuickLoanList() {
    if (this.Processid == 7) {
      this.LOS_GetCourierListReceiveHO();
    } else {
      if (this.SelectStatus == "Reverted") {
        this.displayedColumns = [
          "Loan_Id",
          "Application_No",
          "Application_CreateOn",
          "Customer_Name",
          "Branch_Name",
          "Product_Name",
          "Application_LoanAmount",
          "Application_CreateBy",
          "Application_LoanPurpose",
          "RevertedRemark",
          "SourceType",
          "SourceName",
          "Action",
        ];
      }
      this.sendToModal = { LoginUserId: this.currentUser.userId };
      this.showSpinner = true;
      this._QuickLoanService
        .QuickLoan_Get_Application_Process_List({
          LoginUserId: this.currentUser.userId,
          ProcessID: this.Processid,
          Status: this.SelectStatus,
          SourceType: this.SelectSourceType || "",
          SourceId: this.LeadSourceName || 0,
        })
        .subscribe((res: any) => {
          console.log("RESULT : ", res);
          //if (res.length > 0) {
          this.JsonData = JSON.parse(JSON.stringify(res));
          console.log("JSON DATA", this.JsonData);

          this.dataSource = new MatTableDataSource(this.JsonData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;

          this.showSpinner = false;
        });
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  goToDetail(row) {
    console.log("row", row);
    let Processid = this._EncrDecrService.encrypt(
      encodeURIComponent(this.Processid)
    );
    let ProductId = this._EncrDecrService.encrypt(
      encodeURIComponent(row.productId)
    );
    let Loan_Id = this._EncrDecrService.encrypt(
      encodeURIComponent(row.Loan_Id)
    );
    var Status =
      this.SelectStatus == "Completed"
        ? "C"
        : this.SelectStatus == "Reverted"
        ? "R"
        : "P";
    var RevertedProcessId = row.RevertedProcessId
      ? this._EncrDecrService.encrypt(encodeURIComponent(row.RevertedProcessId))
      : this._EncrDecrService.encrypt(encodeURIComponent(0));
    var RevertedProcessName = row.RevertedProcessName
      ? this._EncrDecrService.encrypt(
          encodeURIComponent(row.RevertedProcessName)
        )
      : this._EncrDecrService.encrypt(encodeURIComponent(""));
    var RevertedEmployeeName = row.RevertedEmployeeName
      ? this._EncrDecrService.encrypt(
          encodeURIComponent(row.RevertedEmployeeName)
        )
      : this._EncrDecrService.encrypt(encodeURIComponent(""));
    var IsEdit = this._EncrDecrService.encrypt(
      encodeURIComponent(row.Revert_IsEdit)
    );

    this.router.navigate([
      `quickloan-dashboard/quick-loan/details/${Processid}/${ProductId}/${Loan_Id}/${Status}/${RevertedProcessId}/${RevertedProcessName}/${RevertedEmployeeName}/${IsEdit}`,
    ]);
  }

  OnClickNewLoan() {
    let Processid = this._EncrDecrService.encrypt(
      encodeURIComponent(this.Processid)
    );
    let ProductId = this._EncrDecrService.encrypt(encodeURIComponent(0));
    this.router.navigate([
      `quickloan-dashboard/quick-loan/details/${Processid}/${ProductId}`,
    ]);
  }

  onCheckboxChange(e: any, data: any) {
    console.log("ApplicationData", this.ApplicationData);
    let _idx = this.ApplicationData.indexOf(data.Loan_Id);
    if (_idx > -1) {
      this.ApplicationData.splice(_idx, 1);
      this.ApplicationListData.splice(_idx, 1);
      this.sendToModal.ApplicationId1 = this.ApplicationData.join(",");
      this.dataSelectedSource = new MatTableDataSource(
        this.ApplicationListData
      );
    } else {
      this.ApplicationData.push(data.Loan_Id);
      this.ApplicationListData.push(data);
      this.sendToModal.ApplicationId1 = this.ApplicationData.join(",");
      this.dataSelectedSource = new MatTableDataSource(
        this.ApplicationListData
      );
    }
  }
  onOpenSendHO() {
    this.LOS_GetCourierForDropdown();
    this.sendToModal.SendingBy = "";
    this.sendToModal.AgencyId = "";
    this.displayedSelectedColumns = [
      "ApplicationId",
      "Application_No",
      "Customer",
      "Product",
      "Branch",
      "Action",
    ];

    $("#sen-to-ho").modal("show");
    $("#sen-to-ho").css("z-index", "1050");
  }
  LOS_GetCourierForDropdown() {
    this.losService.LOS_GetCourierForDropdown({}).subscribe((res: any) => {
      this.AgencyDropDown = res;
    });
  }
  onRemoveSelected(e: any, data: any) {
    let _idx = this.ApplicationData.indexOf(data.ApplicationId);
    this.ApplicationData.splice(_idx, 1);
    this.ApplicationListData.splice(_idx, 1);
    this.sendToModal.ApplicationId1 = this.ApplicationData.join(",");
    this.dataSelectedSource = new MatTableDataSource(this.ApplicationListData);
    if (this.ApplicationData.length == 0) {
      this.onCloseSendHO();
    }
  }
  onCloseSendHO() {
    this.ApplicationData = [];
    $("#sen-to-ho").modal("hide");
  }
  onSendToHo() {
    this.showSpinner = true;
    if (this.sendToModal.SendingBy != "Courier") {
      this.sendToModal.AgencyId = 0;
    }
    this._QuickLoanService
      .Quick_SaveSendFileHOProcess(this.sendToModal)
      .subscribe((res: any) => {
        this.showSpinner = false;
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.QuickLoanList();
          this.onCloseSendHO();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  goToReceiveDetail(data: any) {
    // var cid = this.encdec.encrypt(data.CourierId);
    this.router.navigate([
      `/quickloan-dashboard/file-receive-to-ho/${this._EncrDecrService.encrypt(
        encodeURIComponent(this.Processid)
      )} /${this._EncrDecrService.encrypt(encodeURIComponent(data.CourierId))}`,
    ]);
  }

  goToHoldRejectLoan(row) {
    this.LoanId = row.Loan_Id;
    this.Status = "";
    this.Remarks = "";
    $("#HoldReject").modal("show");
    $("#HoldReject").css("z-index", "1050");
  }

  OnCloseHoldReject() {
    $("#HoldReject").modal("hide");
  }

  QuickLoanUpdateStatusProcess() {
    let Sts = this.Status == "InProcess" ? "Process" : this.Status;
    this.dialog
      .openConfirmDialog(`Are you sure you want to ${Sts} this loan.`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.showSpinner = true;
          this._QuickLoanService
            .QuickLoan_UpdateStatus_Process({
              LoanId: this.LoanId,
              ProcessId: this.Processid,
              LoginUserId: this.currentUser.userId,
              Remark: this.Remarks,
              Status: this.Status,
            })
            .subscribe((res: any) => {
              this.showSpinner = false;
              if (res[0].CODE >= 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.QuickLoanList();
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
              this.OnCloseHoldReject();
            });
        }
      });
  }

  /*QuickLoanUpdateStatusProcess(){
    this._QuickLoanService.QuickLoan_UpdateStatus_Process({
      LoanId: this.LoanId, ProcessId: this.Processid, LoginUserId: this.currentUser.userId, 
      Remark: this.Remarks, Status: this.Status
    }).subscribe((res: any) => {
      this.showSpinner = false;
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this.router.navigate([`quickloan-dashboard/quick-loan/list/${this._EncrDecrService.encrypt(encodeURIComponent(this.Processid))}/${this._EncrDecrService.encrypt(encodeURIComponent(this.ProcessName))}`]); 
      } 
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    });
  }*/

  onCloseTATReportModel() {
    $("#ViewTATReportModel").modal("hide");
  }

  GoToTATReportDetail(row) {
    console.log("row" + row);

    //this.showSpinner = true;
    this.displayedDetailColumns = [
      "ProcessId",
      "Process",
      "ProcessExecutive",
      "ProcessStatus",
      "AssignedOn",
      "CompletedOn",
      "TAT_Hr",
      "Remark",
      "LatLong",
    ];

    this.dataSourceDetail = new MatTableDataSource([]);
    this.dataSourceDetail.sort = this.sort;
    this._QuickLoanService
      .Quick_rpt_TAT_Report({ LoanId: row.Loan_Id })
      .subscribe((res) => {
        console.log("result: ", res);

        $("#ViewTATReportModel").modal("show");
        $("#ViewTATReportModel").css("z-index", "1050");

        this.TatReportsList = JSON.parse(JSON.stringify(res));
        this.Application_No =
          Object.keys(this.TatReportsList).length > 0
            ? this.TatReportsList.Item1
              ? this.TatReportsList.Item1[0]
                ? this.TatReportsList.Item1[0].Application_No
                : ""
              : ""
            : "";
        this.CustomerInfo =
          Object.keys(this.TatReportsList).length > 0
            ? this.TatReportsList.Item1
              ? this.TatReportsList.Item1[0]
                ? this.TatReportsList.Item1[0].Customer
                : ""
              : ""
            : "";
        this.Product =
          Object.keys(this.TatReportsList).length > 0
            ? this.TatReportsList.Item1
              ? this.TatReportsList.Item1[0]
                ? this.TatReportsList.Item1[0].Product
                : ""
              : ""
            : "";
        this.Branch =
          Object.keys(this.TatReportsList).length > 0
            ? this.TatReportsList.Item1
              ? this.TatReportsList.Item1[0]
                ? this.TatReportsList.Item1[0].Branch
                : ""
              : ""
            : "";
        this.ApplicationCreatedOn =
          Object.keys(this.TatReportsList).length > 0
            ? this.TatReportsList.Item1
              ? this.TatReportsList.Item1[0]
                ? this.TatReportsList.Item1[0].ApplicationCreatedOn
                : ""
              : ""
            : "";
        this.LoanAmount =
          Object.keys(this.TatReportsList).length > 0
            ? this.TatReportsList.Item1
              ? this.TatReportsList.Item1[0]
                ? this.TatReportsList.Item1[0].LoanAmount
                : ""
              : ""
            : "";
        this.LoanDuration_Month =
          Object.keys(this.TatReportsList).length > 0
            ? this.TatReportsList.Item1
              ? this.TatReportsList.Item1[0]
                ? this.TatReportsList.Item1[0].LoanDuration_Month
                : ""
              : ""
            : "";

        if (!!!this.LoanAmount) this.LoanAmount = 0;
        this.JsonData = this.TatReportsList.Item2;
        this.dataSourceDetail = new MatTableDataSource(
          this.TatReportsList.Item2
        );
        this.dataSourceDetail.sort = this.sort;
        //this.dataSourceDetail.paginator = this.paginator;
        this.showSpinner = false;

        let sum = 0;
        let pending = this.TatReportsList.Item2.find((obj: any) => {
          return !obj.CompletedOn;
        }).TAT_Hr;
        for (let i = 0; i < this.TatReportsList.Item2.length; i++) {
          if (this.TatReportsList.Item2[i].CompletedOn)
            sum += Number(this.TatReportsList.Item2[i].TAT_Hr);
          if (
            !this.TatReportsList.Item2[i].CompletedOn &&
            Number(pending) < Number(this.TatReportsList.Item2[i].TAT_Hr)
          )
            pending = this.TatReportsList.Item2[i].TAT_Hr;
        }
        this.ProcessComplition = sum.toFixed(2);
        this.PendingSince = pending;

        console.log(" sum.toFixed(2)", sum.toFixed(2));
        var days: number = this.ProcessComplition / 24;
        console.log("days.toFixed(0)", Math.floor(days));
        var hour = (days - Math.floor(days)) * 24;
        if (hour) {
          this.ProcessComplition =
            Math.floor(days) + "d " + hour.toFixed(2) + "h";
        } else {
          this.ProcessComplition = Math.floor(days) + "d";
        }

        var days1: number = this.PendingSince / 24;
        var hour1 = (days1 - Math.floor(days1)) * 24;
        if (hour1) {
          this.PendingSince = Math.floor(days1) + "d " + hour1.toFixed(2) + "h";
        } else {
          this.PendingSince = Math.floor(days1) + "d";
        }

        this.dataSourceDetail = new MatTableDataSource(
          this.TatReportsList.Item2
        );
        this.dataSourceDetail.sort = this.sort;
        console.log(this.dataSourceDetail.data as Array<Object>);
        var min = this.dataSource.data[0].AssignedOn;
        //if Application Generation completed is null then TAT is Current
        var max = !!this.dataSourceDetail.data[0].CompletedOn
          ? this.dataSourceDetail.data[0].CompletedOn
          : Date();
        for (let x of this.dataSourceDetail.data) {
          if (!!x.AssignedOn) {
            min = min < x.AssignedOn ? min : x.AssignedOn;
            if (!!x.CompletedOn)
              max = max > x.CompletedOn ? max : x.CompletedOn;
          }
        }
        console.log("max: " + max);
        console.log("min: " + min);
        console.log(this.convertStringToDate(max));

        this.showSpinner = false;
      });
  }

  convertStringToDate(str: string) {
    var array = str.substring(0, 10);
    console.log("Array" + array);
    return new Date(array);
  }

  exportexcel(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "quickloan_loan_list"
    ) as HTMLTableElement;
    const worksheetName = "QUICK LOAN LIST"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
  // Excel export in backend code end here

  getLeadSourceDropdown() {
    this.showSpinner = true;
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
      console.log("Lead Source Dropdown", this.leadSourceDropdown);
      this.showSpinner = false;
    });
  }

  Get_PartnerForDropdown() {
    console.log("Partner Dropdown API Function");

    if (
      this.SelectSourceType == "Agent" ||
      this.SelectSourceType == "DSA" ||
      this.SelectSourceType == "Dealer"
    ) {
      this.losService
        .Get_PartnerForDropdown({
          PartnerType: this.SelectSourceType,
          branchId: 0,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = res;
        });
    } else if (
      this.SelectSourceType == "Sales Executive" ||
      this.SelectSourceType == "Telecaller Executive" ||
      this.SelectSourceType == "Marketing Executive"
    ) {
      this.partnerDropdown = [];
      this.SalesExecutiveData.forEach((obj: any) => {
        this.partnerDropdown.push({
          PartnerId: obj.EmpId,
          Partner_Name: obj.EmpName,
        });
      });
    } else {
      this.LeadSourceName = "";
    }
    this.LeadSourceName = "";
    this.QuickLoanList();
  }

  GetSalesExecutive() {
    this.losService
      .getEmployeeDropdown({
        BranchId: 0,
        ProcessId: 1,
        ProductId: 0,
      })
      .subscribe((res: any) => {
        // this.employeeDropdown = res;
        this.SalesExecutiveData = res;
        this.Get_PartnerForDropdown();
        //console.log('res', res);
      });
  }
}
