// #region Import Used Components and Services
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { RequestModel } from "../Shared/Models/app.MasterRequestModel";
import {
  DestrictResponseModel,
  GetProcessListModal,
  StateResponseModel,
  TahsilResponseModel,
  ZoneListResponseModel,
  GetBranchResponseModel,
  GetrSaveresponseModel,
  GetBranchDropdownResponseModel,
  GetEmployeeListResponseModel,
  EmployeeDropdownResponseModel,
  DestrictListResponseModel,
  TahsilListResponseModel,
  ProductForDropdownResponseModel,
  ZoneResponseModel,
  GetBranchDetailResponseModel,
  ReportingManagerForDropdownResponseModel,
  GetRoleForDropdownResponseModel,
  GetProductCategoryResponseModel,
  GetProductRequiredDOcResponseModel,
  GetDocumentMasterResponseModel,
  GetProductListResponseModel,
  GetProcesswisemployeeListresponseModel,
  GetProductChargesResponseModel,
  ProductChargesHeadDropDownResponseModel,
} from "../Shared/Models/app.MasterResponseModel";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { SnackbarComponent } from "../snackbar/snackbar.component";
import { constantUrl } from "../../../src/app/Shared/constantUrl";
import { DataSharingService } from "../../app/AuthGuard/DataSharingService";
// #endregion

@Injectable({
  providedIn: "root",
})
export class MasterService {
  // #region Variable Declarations and Models
  //private data: any;
  //token: any;
  // #endregion
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  // #region constructor ListuserService
  constructor(
    private _Route: Router,
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private dataSharingService: DataSharingService
  ) {}

  // #region GetDistricts
  public GetDistricts(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetDistricts";
    return this.http.post<DestrictResponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region GetDistrictsList
  public GetDistrictsList(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetDistrictsList";
    return this.http.post<DestrictListResponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region GetState
  public GetState() {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetState";
    return this.http.post<StateResponseModel>(apiUrl, null);
  }
  // #endregion

  // #region GetTahsil
  public GetTahsil(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetTahsil";
    return this.http.post<TahsilResponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region GetTahsilList
  public GetTahsilList(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetTahsilList";
    return this.http.post<TahsilListResponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region GetBranches
  public GetBranches(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetBranches";
    return this.http.post<GetBranchResponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region GetBrancheDetails
  public GetBrancheDetails(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetBrancheDetails";
    return this.http.post<GetBranchDetailResponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region GetBranchesDropdown
  public GetBranchesDropdown(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetBranchesDropdown";
    return this.http.post<GetBranchDropdownResponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region GetEmployeeList
  public GetEmployeeList(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetEmployeeList";
    return this.http.post<GetEmployeeListResponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region GetProcessList
  public GetProcessList() {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetProcessList";
    return this.http.post<GetProcessListModal>(apiUrl, null);
  }
  // #endregion

  // #region UpdateProcessDetails
  public UpdateProcessDetails(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/UpdateProcessDetails";
    return this.http.post<number>(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveBranchDetails
  public SaveBranchDetails(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/SaveBranchDetails";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region DeleteBranchDetails
  public DeleteBranchDetails(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/DeleteBranchDetails";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region GetZoneListDropdown
  public GetZoneListDropdown() {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetZoneListDropdown";
    return this.http.post<ZoneResponseModel>(apiUrl, null);
  }
  // #endregion

  // #region GetZoneList
  public GetZoneList() {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetZoneList";
    return this.http.post<ZoneListResponseModel>(apiUrl, null);
  }
  // #endregion

  // #region GetEmployeeDropdown
  public GetEmployeeDropdown(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetEmployeeDropdown";
    return this.http.post<EmployeeDropdownResponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region GetEmployeeDetails
  public GetEmployeeDetails(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetEmployeeDetails";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveEmployeDetails
  public SaveEmployeDetails(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/SaveEmployeDetails";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region DeleteEmployeeBranch
  public DeleteEmployeeBranch(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/DeleteEmployeeBranch";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region DeleteEmployeeProcess
  public DeleteEmployeeProcess(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/DeleteEmployeeProcess";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region DeleteEmployee
  public DeleteEmployee(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/DeleteEmployee";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region GetProductForDropdown
  public GetProductForDropdown() {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetProductForDropdown";
    return this.http.post<ProductForDropdownResponseModel>(apiUrl, null);
  }
  // #endregion

  // #region Get_ALLProductDropdown
  public Get_ALLProductDropdown() {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/Get_ALLProductDropdown";
    return this.http.post<ProductForDropdownResponseModel>(apiUrl, null);
  }
  // #endregion

  // #region SaveLoanProduct
  public SaveLoanProduct(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/SaveLoanProduct";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region ReportingManagerForDropdown
  public ReportingManagerForDropdown(requestModel: RequestModel) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/ReportingManagerForDropdown";
    return this.http.post<ReportingManagerForDropdownResponseModel>(
      apiUrl,
      requestModel
    );
  }
  // #endregion

  // #region GetRoleForDropdown
  public GetRoleForDropdown() {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetRoleForDropdown";
    return this.http.post<GetRoleForDropdownResponseModel>(apiUrl, null);
  }
  // #endregion

  // #region SaveZoneMaster
  public SaveZoneMaster(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/SaveZoneMaster";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveDistrictMaster
  public SaveDistrictMaster(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/SaveDistrictMaster";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveTehsilMaster
  public SaveTehsilMaster(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/SaveTehsilMaster";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region GetProductWorkflow
  public GetProductWorkflow(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetProductWorkflow";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region GetProductCategory
  public GetProductCategory() {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetProductCategory";
    return this.http.post<GetProductCategoryResponseModel>(apiUrl, null);
  }
  // #endregion

  // #region GetProductRequiredDoc
  public GetProductRequiredDoc(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetProductRequiredDoc";
    return this.http.post<GetProductRequiredDOcResponseModel>(
      apiUrl,
      requestModel
    );
  }
  // #endregion

  // #region GetDocumentMaster
  public GetDocumentMaster(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetDocumentMaster";
    return this.http.post<GetDocumentMasterResponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region GetProductList
  public GetProductList(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetProductList";
    return this.http.post<GetProductListResponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveProductWorkflow
  public SaveProductWorkflow(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/SaveProductWorkflow";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveProductReqDoc
  public SaveProductReqDoc(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/SaveProductReqDoc";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveDocumentCategory
  public SaveDocumentCategory(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/SaveDocumentCategory";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveDocumentMaster
  public SaveDocumentMaster(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/SaveDocumentMaster";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region DeleteDocumentMaster
  public DeleteDocumentMaster(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/DeleteDocumentMaster";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region DeleteProduct
  public DeleteProduct(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/DeleteProduct";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region UploadProfilePic
  public UploadProfilePic(requestModel: RequestModel) {
    var apiUrl =
      constantUrl.apiEndpointUploadDoc + "/api/UploadDoc/UploadProfilePic";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region GetProcesswisemployeeList
  public GetProcesswisemployeeList(requestModel: RequestModel) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/GetProcesswisemployeeList";
    return this.http.post<GetProcesswisemployeeListresponseModel>(
      apiUrl,
      requestModel
    );
  }
  // #endregion

  // #region GetProductCharges
  public GetProductCharges(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetProductCharges";
    return this.http.post<GetProductChargesResponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveLoanProductCharges
  public SaveLoanProductCharges(requestModel: RequestModel) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/SaveLoanProductCharges";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region DeleteLoanProductCharges
  public DeleteLoanProductCharges(requestModel: RequestModel) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/DeleteLoanProductCharges";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region GetProductChargesHeadDropDown
  public GetProductChargesHeadDropDown() {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/GetProductChargesHeadDropDown";
    return this.http.post<ProductChargesHeadDropDownResponseModel>(
      apiUrl,
      null
    );
  }
  // #endregion

  // #region SaveFIQuestions
  public SaveFIQuestions(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/SaveFIQuestions";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region LOSGetFIQuestionsList
  public LOSGetFIQuestionsList(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/LOSGetFIQuestionsList";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region LOSGetFIQuestionDetails
  public LOSGetFIQuestionDetails(requestModel: RequestModel) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/LOSGetFIQuestionDetails";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region LOSDeleteFIQuestion
  public LOSDeleteFIQuestion(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/LOSDeleteFIQuestion";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveTVRQuestions
  public SaveTVRQuestions(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/SaveTVRQuestions";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region LOSGetTVRQuestionsList
  public LOSGetTVRQuestionsList(requestModel: RequestModel) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/LOSGetTVRQuestionsList";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region LOSGetTVRQuestionDetails
  public LOSGetTVRQuestionDetails(requestModel: RequestModel) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/LOSGetTVRQuestionDetails";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region LOSDeleteTVRQuestion
  public LOSDeleteTVRQuestion(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/LOSDeleteTVRQuestion";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region LOSGetEmployeeForCopyDropdown
  public LOSGetEmployeeForCopyDropdown(requestModel: RequestModel) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/LOSGetEmployeeForCopyDropdown";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveProductCategory
  public SaveProductCategory(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/SaveProductCategory";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Get_EmployeeProcessForCopy
  public Get_EmployeeProcessForCopy(requestModel: RequestModel) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/Get_EmployeeProcessForCopy";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region LOSGetPendingApplicationForSWAP
  public LOSGetPendingApplicationForSWAP(requestModel: RequestModel) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/LOSGetPendingApplicationForSWAP";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region LOS_GetEmployeeForSWAP
  public LOS_GetEmployeeForSWAP(requestModel: RequestModel) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/LOS_GetEmployeeForSWAP";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region LOS_SaveUserApplicationSwap
  public LOS_SaveUserApplicationSwap(requestModel: RequestModel) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/LOS_SaveUserApplicationSwap";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region GetSearchCustomer
  public GetSearchCustomer(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetSearchCustomer";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Get_CustomerDetails
  public Get_CustomerDetails(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Get_CustomerDetails";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region ChargesHeadsList
  public ChargesHeadsList(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/ChargesHeadsList";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region SaveChargesHeadMaster
  public SaveChargesHeadMaster(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/SaveChargesHeadMaster";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region LOS_GetCreditAssignmentPolicy
  public LOS_GetCreditAssignmentPolicy(requestModel: RequestModel) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/LOS_GetCreditAssignmentPolicy";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region LOS_SaveCreditAssignmentPolicy
  public LOS_SaveCreditAssignmentPolicy(requestModel: RequestModel) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/LOS_SaveCreditAssignmentPolicy";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region LOS_SaveConfigurationDetails
  public GetConfigurationDetails() {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/GetConfigurationDetails";
    return this.http.post(apiUrl, null);
  }
  // #endregion

  // #region LOS_SaveConfigurationDetails
  public LOS_SaveConfigurationDetails(requestModel: RequestModel) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/LOS_SaveConfigurationDetails";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region GetCourierMasterList
  public GetCourierMasterList(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetCourierMasterList";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region LOS_SaveCourierMaster
  public LOS_SaveCourierMaster(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/LOS_SaveCourierMaster";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region LOS_rptChargeReports
  public LOS_rptChargeReports(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/LOS_rptChargeReports";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Get_LoanProductAssetDetail
  public Get_LoanProductAssetDetail(requestModel: RequestModel) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/Get_LoanProductAssetDetail";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Save_ProductAssets
  public Save_ProductAssets(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Save_ProductAssets";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion
  // #region Get_PartnerList
  public Get_PartnerList(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Get_PartnerList";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Get_PartnerList
  public Get_PartnerDetail(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Get_PartnerDetail";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Save_Partner
  public Save_Partner(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Save_Partner";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Delete_Partner
  public Delete_Partner(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Delete_Partner";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Get_SchemeList
  public Get_SchemeList(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Get_SchemeList";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region LOS_GetSchemeToValidateLoan
  public LOS_GetSchemeToValidateLoan(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/LOS_GetSchemeToValidateLoan";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Get_SchemeDetail
  public Get_SchemeDetail(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Get_SchemeDetail";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Save_Scheme
  public Save_Scheme(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Save_Scheme";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Delete_Scheme
  public Delete_Scheme(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Delete_Scheme";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Get_EmployeeBranchForDropdown
  public Get_EmployeeBranchForDropdown(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/Get_EmployeeBranchForDropdown";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region SendSMS
  public SendSMS(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/SendSMS";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion
  // #region SendSMStoResetPWD
  public SendSMStoResetPWD(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/SendSMStoResetPWD";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Generate_OTPPhoneNoVerification
  public Generate_OTPPhoneNoVerification(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/Generate_OTPPhoneNoVerification";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion
  // #region SAVE_OTP_Verification
  public SAVE_OTP_Verification(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/SAVE_OTP_Verification";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region LOS_rptHoldInquiry
  public LOS_rptHoldInquiry(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/LOS_rptHoldInquiry";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Get_SenctionLetter
  public Get_SenctionLetter(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Get_SenctionLetter";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Save_SenctionLetter
  public Save_SenctionLetter(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Save_SenctionLetter";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region MakeShortURL
  public MakeShortURL(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/MakeShortURL";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region LOS_rptLogReport
  public LOS_rptLogReport(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/LOS_rptLogReport";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region GetResetPassword
  public GetResetPassword(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetResetPassword";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region UpdateUserPass
  public UpdateUserPass(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/UpdateUserPass";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region GetRoleForList
  public GetRoleForList(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetRoleForList";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Save_Role
  public Save_Role(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Save_Role";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region GetRoleForList
  public Delete_Role(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Delete_Role";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion
  // #region GetProductListById
  public GetProductListById(requestModel: RequestModel) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/GetProductListById";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region GetProductListById
  public GetManufactureForDropdown(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/GetManufactureForDropdown";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region GetVehicleCategoryForDropdown
  public GetVehicleCategoryForDropdown(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/GetVehicleCategoryForDropdown";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region GetVehicleModelForDropdown
  public GetVehicleModelForDropdown(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/GetVehicleModelForDropdown";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region GetFuleTypeForDropdown
  public GetFuleTypeForDropdown(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/GetFuleTypeForDropdown";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region GetCommonMaster_FOR_DROPDOWN
  public GetCommonMaster_FOR_DROPDOWN(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/GetCommonMaster_FOR_DROPDOWN";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion
  // #region GetCustomer_Profile_Master_For_Dropdown
  public GetCustomer_Profile_Master_For_Dropdown(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Masters/GetCustomer_Profile_Master_For_Dropdown";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region GetInsuranceCompanyMaster_FOR_DROPDOWN
  public GetInsuranceCompanyMaster_FOR_DROPDOWN(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Masters/GetInsuranceCompanyMaster_FOR_DROPDOWN";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region GetInsuranceBranchMaster_FOR_DROPDOWN
  public GetInsuranceBranchMaster_FOR_DROPDOWN(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Masters/GetInsuranceBranchMaster_FOR_DROPDOWN";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Commaon_Master
  public Commaon_Master(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/LMS_Commaon_Master`,
      requestData
    );
  }
  // #endregion

  // #region Get_EmployeeForTreeMap
  public Get_EmployeeForTreeMap(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_EmployeeForTreeMap`,
      requestData
    );
  }
  // #endregion
  // #region LOS_Get_rptCAMReport
  public GetStateDropdown(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/GetState`,
      requestData
    );
  }
  // #endregion

  // #region LOS_Get_rptCAMReport
  public GetDistrickDropdown(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/GetDistricts`,
      requestData
    );
  }
  // #endregion

  // #region LOS_Get_rptCAMReport
  public GetTehasilDropdown(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/GetTahsil`,
      requestData
    );
  }
  // #endregion

  // #region Get_DepartmentMaster
  public Get_DepartmentMaster(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_DepartmentMaster`,
      requestData
    );
  }
  // #endregion

  // #region Save_DepartmentMaster
  public Save_DepartmentMaster(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_DepartmentMaster`,
      requestData
    );
  }
  // #endregion

  // #region Delete_DepartmentMaster
  public Delete_DepartmentMaster(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Delete_DepartmentMaster`,
      requestData
    );
  }
  // #endregion

  // #region Get_Employee_Subordinate_SWAP
  public Get_Employee_Subordinate_SWAP(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Employee_Subordinate_SWAP`,
      requestData
    );
  }
  // #endregion

  // #region Get_ReportingManager_SWAP
  public Get_ReportingManager_SWAP(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_ReportingManager_SWAP`,
      requestData
    );
  }
  // #endregion

  // #region Update_ReportingManager
  public Update_ReportingManager(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Update_ReportingManager`,
      requestData
    );
  }
  // #endregion

  // #region Get_Employee_Subordinate_IsExists
  public Get_Employee_Subordinate_IsExists(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Employee_Subordinate_IsExists`,
      requestData
    );
  }
  // #endregion

  // #region Delete_Zone_Master
  public Delete_Zone_Master(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Delete_Zone_Master`,
      requestData
    );
  }
  // #endregion

  // #region Get_Menu_By_Role
  public Get_Menu_By_Role(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Menu_By_Role`,
      requestData
    );
  }
  // #endregion

  // #region Get_SearchCustomerByLMSLOS
  public Get_SearchCustomerByLMSLOS(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_SearchCustomerByLMSLOS`,
      requestData
    );
  }
  // #endregion

  // #region Get_SearchCustomerByPartner
  public Get_SearchCustomerByPartner(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_SearchCustomerByPartner`,
      requestData
    );
  }
  // #endregion

  // #region Get_ChargesHeadsByProduct_ForDropdow
  public Get_ChargesHeadsByProduct_ForDropdow(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_ChargesHeadsByProduct_ForDropdow`,
      requestData
    );
  }
  // #endregion
  // #region GetReceiptNo
  public GetReceiptNo(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/GetReceiptNo`,
      requestData
    );
  }
  // #endregion
  // #region Save_Product_PreCloserCharges
  public Save_Product_PreCloserCharges(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Product_PreCloserCharges`,
      requestData
    );
  }
  // #endregion
  // #region Get_Product_PreCloserCharges
  public Get_Product_PreCloserCharges(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Product_PreCloserCharges`,
      requestData
    );
  }
  // #endregion

  // #Save_Report_Slab_Master
  public Save_Report_Slab_Master(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Report_Slab_Master`,
      requestData
    );
  }
  // #end Save_Report_Slab_Master
  // #Get_Report_Slab_Master
  public Get_Report_Slab_Master(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Report_Slab_Master`,
      requestData
    );
  }
  // #end Get_Report_Slab_Master

  // #Get_Report_Slab_Config_List
  public Get_Report_Slab_Config_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Report_Slab_Config_List`,
      requestData
    );
  }
  // #end Get_Report_Slab_Config_List

  // #Save_Report_Slab_Config
  public Save_Report_Slab_Config(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Report_Slab_Config`,
      requestData
    );
  }
  // #end Save_Report_Slab_Config

  // #Get_Report_Slab_Config
  public Get_Report_Slab_Config(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Report_Slab_Config`,
      requestData
    );
  }
  // #end Get_Report_Slab_Config

  // #Delete_Report_Slab_Config
  public Delete_Report_Slab_Config(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Delete_Report_Slab_Config`,
      requestData
    );
  }
  // #end Get_Report_Slab_Config

  // #Get__Slab_dropdown
  public Get__Slab_dropdown(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get__Slab_dropdown`,
      requestData
    );
  }
  // #end Get__Slab_dropdown

  // #Get_MenuPages_By_Role
  public Get_MenuPages_By_Role(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_MenuPages_By_Role`,
      requestData
    );
  }
  // #end Get_MenuPages_By_Role

  // #Save_Role_Designation
  public Save_Role_Designation(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Role_Designation`,
      requestData
    );
  }
  // #end Save_Role_Designation

  // #Get_Page_Role_Rights
  public Get_Page_Role_Rights(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Page_Role_Rights`,
      requestData
    );
  }
  // #end Get_Page_Role_Rights

  // #Get_Roal_Designation_For_List
  public Get_Roal_Designation_For_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Roal_Designation_For_List`,
      requestData
    );
  }
  // #end Get_Roal_Designation_For_List

  // #Delete_Role_Designation
  public Delete_Role_Designation(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Delete_Role_Designation`,
      requestData
    );
  }
  // #end Delete_Role_Designation

  // #Get_Role_By_Designation
  public Get_Role_By_Designation(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Role_By_Designation`,
      requestData
    );
  }
  // #end Get_Role_By_Designation

  // #Get_Page_Rights_Of_EMP
  public Get_Page_Rights_Of_EMP(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Page_Rights_Of_EMP`,
      requestData
    );
  }
  // #end Get_Page_Rights_Of_EMP

  // #LOS_TATReportList
  public LOS_TATReportList(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/LOS_TATReportList`,
      requestData
    );
  }
  // #end LOS_TATReportList

  // #EncriptToDcript
  public EncriptToDcript(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/EncriptToDcript`,
      requestData
    );
  }
  // #end EncriptToDcript

  // #DcriptToEncript
  public DcriptToEncript(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/DcriptToEncript`,
      requestData
    );
  }
  // #end DcriptToEncript

  // #Get_ChargesHeadsByAccountingId
  public Get_ChargesHeadsByAccountingId(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_ChargesHeadsByAccountingId`,
      requestData
    );
  }
  // #end Get_ChargesHeadsByAccountingId

  // #region UploadProfilePic
  public SaveOtherDocPath(requestModel: RequestModel) {
    var apiUrl =
      constantUrl.apiEndpointUploadDoc + "/api/UploadDoc/UploadOthersDoc";
    return this.http.post<GetrSaveresponseModel>(apiUrl, requestModel);
  }
  // #endregion

  // #region UploadProfilePic
  public Get_Sms_Configration(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Sms_Configration`,
      requestData
    );
  }
  // #endregion
  // #region MessageSend
  public MessageSend(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/MessageSend`,
      requestData
    );
  }
  // #endregion
  // #region Save_Customer_Kyc_Verification
  public Save_Customer_Kyc_Verification(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Customer_Kyc_Verification`,
      requestData
    );
  }
  // #endregion

  // #region Get_KYC_Verification
  public Get_KYC_Verification(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_KYC_Verification`,
      requestData
    );
  }
  // #endregion

  // #region Get_Alredy_Verified_KYC
  public Get_Alredy_Verified_KYC(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Alredy_Verified_KYC`,
      requestData
    );
  }
  // #endregion

  // #region Get_ValuesOf_SendSMS
  public Get_ValuesOf_SendSMS(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_ValuesOf_SendSMS`,
      requestData
    );
  }
  // #endregion
  // #region Get_WelcomeLetter
  public Get_WelcomeLetter(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Get_WelcomeLetter";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Save_WelcomeLetter
  public Save_WelcomeLetter(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Save_WelcomeLetter";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Get_NOCLetter
  public Get_NOCLetter(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Get_NOCLetter";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Save_NOCLetter
  public Save_NOCLetter(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Save_NOCLetter";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region Get_AccountForGST_Dropdown
  public Get_AccountForGST_Dropdown(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_AccountForGST_Dropdown`,
      requestData
    );
  }
  // #endregion

  // #region De_DupeCustomer
  public De_DupeCustomer(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/De_DupeCustomer";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  // #region UploadCopyCustomerDoc
  public UploadCopyCustomerDoc(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpointUploadDoc}/api/UploadDoc/UploadCopyCustomerDoc`,
      requestData
    );
  }
  // #end Upload Others doc
  // #region Get_Hierarchy_By_LoginUser
  public Get_Hierarchy_By_LoginUser(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Hierarchy_By_LoginUser`,
      requestData
    );
  }
  // #end
  // #region Get_Employee_ByBranch_Department
  public Get_Employee_ByBranch_Department(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Employee_ByBranch_Department`,
      requestData
    );
  }
  // #end
  // #region Save_Product_Config
  public Save_Product_Config(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Product_Config`,
      requestData
    );
  }
  // #end
  // #region Get_Product_Config
  public Get_Product_Config(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Product_Config`,
      requestData
    );
  }
  // #end

  // #region Get_Product_FeesCharges
  public Get_Product_FeesCharges(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Product_FeesCharges`,
      requestData
    );
  }
  // #end

  // #region Get_BankAccount_Dropdown_For_Customer
  public Get_BankAccount_Dropdown_For_Customer(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_BankAccount_Dropdown_For_Customer`,
      requestData
    );
  }
  // #end Get_BankAccount_Dropdown_For_Customer

  // #region Save_CustomerBankDetail
  public Save_CustomerBankDetail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_CustomerBankDetail`,
      requestData
    );
  }
  // #end Save_CustomerBankDetail

  // #region Update_Password
  public Update_Password(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Update_Password`,
      requestData
    );
  }
  // #end Update_Password

  // #region ENach_Get_Registration_Deatil
  public ENach_Get_Registration_Deatil(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/ENach_Get_Registration_Deatil`,
      requestData
    );
  }
  // #end ENach_Get_Registration_Deatil

  // #region ENach_Save_NachInfo
  public ENach_Save_NachInfo(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/ENach_Save_NachInfo`,
      requestData
    );
  }
  // #end ENach_Save_NachInfo

  // #region ENach_Save_NachGenerate
  public ENach_Save_NachGenerate(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/ENach_Save_NachGenerate`,
      requestData
    );
  }
  // #end ENach_Save_NachGenerate

  // #region Loan_ACC_Charges_Swap
  public Loan_ACC_Charges_Swap(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Loan_ACC_Charges_Swap`,
      requestData
    );
  }
  // #end Loan_ACC_Charges_Swap

  // #region Partner_ACC_Charges_Swap
  public Partner_ACC_Charges_Swap(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Partner_ACC_Charges_Swap`,
      requestData
    );
  }
  // #end Partner_ACC_Charges_Swap

  // #region Update_Legal_Config
  public Update_Legal_Config(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Update_Legal_Config`,
      requestData
    );
  }
  // #end Update_Legal_Config

  // #region Save_Court_Establishment
  public Save_Court_Establishment(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Court_Establishment`,
      requestData
    );
  }
  // #end Save_Court_Establishment

  // #region Get_Court_Establishment
  public Get_Court_Establishment(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Court_Establishment`,
      requestData
    );
  }
  // #end Get_Court_Establishment

  // #region Save_Legal_Case_Type
  public Save_Legal_Case_Type(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Legal_Case_Type`,
      requestData
    );
  }
  // #end Save_Legal_Case_Type

  // #region Get_Legal_Case_Type
  public Get_Legal_Case_Type(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Legal_Case_Type`,
      requestData
    );
  }
  // #end Get_Legal_Case_Type

  // #region Get_Legal_Master_Form_Dropdown
  public Get_Legal_Master_Form_Dropdown(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Legal_Master_Form_Dropdown`,
      requestData
    );
  }
  // #end Get_Legal_Master_Form_Dropdown

  // #region Save_Update_Template
  public Save_Update_Template(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Update_Template`,
      requestData
    );
  }
  // #end Save_Update_Template

  // #region Get_Template_Type
  public Get_Template_Type(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Template_Type`,
      requestData
    );
  }
  // #end Get_Template_Type

  // #region Save_Template
  public Save_Template(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Save_Template";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion Save_Template

  // #region Get_Template
  public Get_Template(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Get_Template";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion Get_Template
  // #region Get_AccountHead_For_ChargesHead
  public Get_AccountHead_For_ChargesHead(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/Get_AccountHead_For_ChargesHead";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion Get_AccountHead_For_ChargesHead
  // #region Get_Disbursment_Accounts_By_Tag
  public Get_Disbursment_Accounts_By_Tag(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/Get_Disbursment_Accounts_By_Tag";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion Get_Disbursment_Accounts_By_Tag

  // #region Get_ChargesHeadsByCaseNo_ForDropdown
  public Get_ChargesHeadsByCaseNo_ForDropdown(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Masters/Get_ChargesHeadsByCaseNo_ForDropdown";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion Get_ChargesHeadsByCaseNo_ForDropdown

  // #region QuickLoan_Employee_Assignment_Dropdown
  public QuickLoan_Employee_Assignment_Dropdown(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Masters/QuickLoan_Employee_Assignment_Dropdown";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion QuickLoan_Employee_Assignment_Dropdown
  // #region Save_Customer_Profile_Master
  public Save_Customer_Profile_Master(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/Save_Customer_Profile_Master";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion Save_Customer_Profile_Master

  // #region Save_Customer_Profile_Questions
  public Save_Customer_Profile_Questions(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/Save_Customer_Profile_Questions";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion Save_Customer_Profile_Questions
  // #region Get_Customer_Profile_Questions_List
  public Get_Customer_Profile_Questions_List(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Masters/Get_Customer_Profile_Questions_List";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion Get_Customer_Profile_Questions_List

  // #region Delete_Customer_Profile_Master
  public Delete_Customer_Profile_Master(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/Delete_Customer_Profile_Master";
    return this.http.post(apiUrl, requestModel);
  }
  // # endregion Delete_Customer_Profile_Master

  // #region Save_Customer_Profile_Questions_Level
  public Save_Customer_Profile_Questions_Level(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Masters/Save_Customer_Profile_Questions_Level";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion Save_Customer_Profile_Questions_Level

  // #region Get_Customer_Profile_Questions_Option
  public Get_Customer_Profile_Questions_Option(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Masters/Get_Customer_Profile_Questions_Option";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion Get_Customer_Profile_Questions_Option

  // #region Delete_Nature_Of_Work_Question
  public Delete_Nature_Of_Work_Question(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/Delete_Nature_Of_Work_Question";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion Delete_Nature_Of_Work_Question
  // #region Get_Customer_For_PD
  public Get_Customer_For_PD(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Masters/Get_Customer_For_PD";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion Get_Customer_For_PD
  // #region Quick_Loan_Get_Pending_Application_ForSWAP
  public Quick_Loan_Get_Pending_Application_ForSWAP(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Masters/Quick_Loan_Get_Pending_Application_ForSWAP";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion Quick_Loan_Get_Pending_Application_ForSWAP

  // #region QuickLoan_GetEmployeeForSWAP
  public QuickLoan_GetEmployeeForSWAP(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint + "/api/Masters/QuickLoan_GetEmployeeForSWAP";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion QuickLoan_GetEmployeeForSWAP

  // #region QuickLoan_SaveUserApplicationSwap
  public QuickLoan_SaveUserApplicationSwap(requestModel: any) {
    var apiUrl =
      constantUrl.apiEndpoint +
      "/api/Masters/QuickLoan_SaveUserApplicationSwap";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion QuickLoan_SaveUserApplicationSwap

  // #region Get_Employee_By_DeviationApproval
  public Get_Employee_By_DeviationApproval(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Employee_By_DeviationApproval`,
      requestData
    );
  }
  // #end
  // #region Get_Hierarchy_Upper_Lower
  public Get_Hierarchy_Upper_Lower(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Hierarchy_Upper_Lower`,
      requestData
    );
  }
  // #end
  // #region Delete_Employee_Assign_Branch
  public Delete_Employee_Assign_Branch(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Delete_Employee_Assign_Branch`,
      requestData
    );
  }
  // #end
  // #region Get_Employee_Branch_NotAssigned_List
  public Get_Employee_Branch_NotAssigned_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Employee_Branch_NotAssigned_List`,
      requestData
    );
  }
  // #end

  // #region Save_Upper_Employee_BranchAssign
  public Save_Upper_Employee_BranchAssign(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Upper_Employee_BranchAssign`,
      requestData
    );
  }
  // #end

  // #region Save_Update_Delete_ParkingYard
  public Save_Update_Delete_ParkingYard(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Update_Delete_ParkingYard`,
      requestData
    );
  }
  // #end

  // #region Get_ParkingYard_List
  public Get_ParkingYard_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_ParkingYard_List`,
      requestData
    );
  }
  // #end
  // #region LMS_Assets_List_Not_Repossessed
  public LMS_Assets_List_Not_Repossessed(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/LMS_Assets_List_Not_Repossessed`,
      requestData
    );
  }
  // #end

  // #region SaveSubscibtion
  public SaveSubscibtion(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/User/SaveSubscibtion`,
      requestData
    );
  }
  // #end
  // #region GetConfiguration
  public GetConfiguration(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/User/GetConfiguration`,
      requestData
    );
  }
  // #end
  // #region LMS_EMI_Calculator_Without_StepEmi
  public LMS_EMI_Calculator_Without_StepEmi(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/LMS_EMI_Calculator_Without_StepEmi`,
      requestData
    );
  }
  // #end
  // #region LMS_EMI_Calculator_With_StepEmi
  public LMS_EMI_Calculator_With_StepEmi(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/LMS_EMI_Calculator_With_StepEmi`,
      requestData
    );
  }
  // #end
  // #region LMS_NACH_Mandate_RejectList
  public LMS_NACH_Mandate_RejectList() {
    return this.http.get(
      `${constantUrl.apiEndpoint}/api/Masters/LMS_NACH_Mandate_RejectList`
    );
  }
  // #end
  // #region Get_Employee_Branch_Product_Process
  public Get_Employee_Branch_Product_Process(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Employee_Branch_Product_Process`,
      requestData
    );
  }
  // #end

  public utf8Encode(unicodeString) {
    if (typeof unicodeString != "string")
      throw new TypeError("parameter ‘unicodeString’ is not a string");
    const utf8String = unicodeString
      .replace(
        /[\u0080-\u07ff]/g, // U+0080 - U+07FF => 2 bytes 110yyyyy, 10zzzzzz
        function (c) {
          var cc = c.charCodeAt(0);
          return String.fromCharCode(0xc0 | (cc >> 6), 0x80 | (cc & 0x3f));
        }
      )
      .replace(
        /[\u0800-\uffff]/g, // U+0800 - U+FFFF => 3 bytes 1110xxxx, 10yyyyyy, 10zzzzzz
        function (c) {
          var cc = c.charCodeAt(0);
          return String.fromCharCode(
            0xe0 | (cc >> 12),
            0x80 | ((cc >> 6) & 0x3f),
            0x80 | (cc & 0x3f)
          );
        }
      );
    return utf8String;
  }
  // #sanction letter template starts
  public GetTemplate_SideMenu(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/GetTemplate_SideMenu/GetTemplate_SideMenu`,
      requestData
    );
  }

  // #end

  // Mac Adress
  public GetMACAddress(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/User/MAC_AddressList`,
      requestData
    );
  }

  //end
  // Mac Adress
  public SaveMACAddress(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_MAC_Address`,
      requestData
    );
  }

  //end

  // #Get_Template_Details starts
  public Get_Template_Details(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Template_Details`,
      requestData
    );
  }
  // #end
  // #Get_Menu_Rights starts
  public Get_Menu_Rights(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Menu_Rights`,
      requestData
    );
  }
  // #end

  // Mac Adress
  public ReportODInterestDetail(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Report_OD_Interest_Detail`,
      requestData
    );
  }

  //end
  // Get_Electricity_State
  public Get_Electricity_State() {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Electricity_State`,
      null
    );
  }

  //end

  // Get_Electricity_OperatorCode
  public Get_Electricity_OperatorCode(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Electricity_OperatorCode`,
      requestData
    );
  }

  //end

  // Get_Template_Data
  public Get_Template_Data(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Template_Data`,
      requestData
    );
  }

  //end
  // Update_CustomerBankDetail
  public Update_CustomerBankDetail(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Update_CustomerBankDetail`,
      requestData
    );
  }

  //end

  public Check_URL(RoleId, RouteUrl) {
    this.Get_Menu_Rights({
      RoleID: RoleId,
      MM_Url: RouteUrl.split("?")[0],
    }).subscribe((res: any) => {
      if (res.length <= 0 || res[0].CODE == -1) {
        sessionStorage.removeItem("currentUser");
        sessionStorage.clear();
        this._Route.navigate(["Login"]);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
        this.dataSharingService.RefreshLoginPage.next("false");
      }
    });
  }
  // #Get_Menu_Rights starts
  public CheckMACAllowed(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/User/Allow_MacAddress`,
      requestData
    );
  }

  getMacAddress() {
    try {
      var xml = new XMLHttpRequest();
      xml.open("GET", "http://127.0.0.1:12345", false);
      xml.send();
      console.log("Status", xml.status);
      console.log("MAC", xml.responseText);
      if (xml.status == 200) return xml.responseText;
      return "";
    } catch (error) {
      return "";
    }
  }
  // #end

  // Get_Master_Consumer_Durable_Category
  public Get_Master_Consumer_Durable_Category() {
    return this.http.get(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Master_Consumer_Durable_Category`
    );
  }

  //end

  // Save_Master_Consumer_Durable_Category
  public Save_Master_Consumer_Durable_Category(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Master_Consumer_Durable_Category`,
      requestData
    );
  }

  //end

  // Get_Master_Consumer_Durable_Manufacture
  public Get_Master_Consumer_Durable_Manufacture(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Master_Consumer_Durable_Manufacture`,
      requestData
    );
  }

  //end

  // Save_Master_Consumer_Durable_Category
  public Save_Master_Consumer_Durable_Manufacture(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Master_Consumer_Durable_Manufacture`,
      requestData
    );
  }

  //end

  // Get_Master_Consumer_Durable_Manufacture
  public Get_Master_Consumer_Durable_Product(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Master_Consumer_Durable_Product`,
      requestData
    );
  }

  //end

  // Save_Master_Consumer_Durable_Category
  public Save_Master_Consumer_Durable_Product(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Master_Consumer_Durable_Product`,
      requestData
    );
  }

  //end

  // Get_Master_Consumer_Durable_Model
  public Get_Master_Consumer_Durable_Model(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Master_Consumer_Durable_Model`,
      requestData
    );
  }

  //end

  // Save_Master_Consumer_Durable_Category
  public Save_Master_Consumer_Durable_Model(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Master_Consumer_Durable_Model`,
      requestData
    );
  }

  //end
  // Get_Employee_Product_NotAssigned_List
  public Get_Employee_Product_NotAssigned_List(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Employee_Product_NotAssigned_List`,
      requestData
    );
  }

  //end
  // Save_Upper_Employee_ProductAssign
  public Save_Upper_Employee_ProductAssign(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Upper_Employee_ProductAssign`,
      requestData
    );
  }

  //end
  // Delete_Employee_Assign_Product
  public Delete_Employee_Assign_Product(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Delete_Employee_Assign_Product`,
      requestData
    );
  }

  //end
  // Employee_Dropdown_For_Product_OR_Branch
  public Employee_Dropdown_For_Product_OR_Branch(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Employee_Dropdown_For_Product_OR_Branch`,
      requestData
    );
  }

  //end

  // Save_Employee_Branch_Product_Process
  public Save_Employee_Branch_Product_Process(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Employee_Branch_Product_Process`,
      requestData
    );
  }

  //end

  // #region Get_Employee_ExportData
  public Get_Employee_ExportData() {
    return this.http.get(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Employee_ExportData`
    );
  }
  // #end

  // HDFCNach
  public HDFCNach(requestData): Observable<any> {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/HDFCNach`,
      requestData
    );
  }

  //end

  // #region Get_Location_List
  public Get_Location_List(requestModel: RequestModel) {
    return this.http.get(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Location_List`
    );
  }
  // #endregion

  // #region Get_Location_List
  public getLiveBankDtls() {
    return this.http.get(
      `${constantUrl.apiEndpoint}/api/Masters/getLiveBankDtls`
    );
  }
  // #endregion

  // #region getNupayLiveBankDtls
  public getNupayLiveBankDtls() {
    return this.http.get(
      `${constantUrl.apiEndpoint}/api/ThirdPartyAPi/getNupayLiveBankDtls`
    );
  }
  // #endregion

  // #region Add_Current_FinancialYear
  public Add_Current_FinancialYear() {
    return this.http.get(
      `${constantUrl.apiEndpoint}/api/Masters/Add_Current_FinancialYear`
    );
  }
  // #endregion

  // #region Get_CibilAPI_Request_Response
  public Get_CibilAPI_Request_Response(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/QuickLoan/Get_CibilAPI_Request_Response`,
      requestData
    );
  }
  // #endregion

  // Get_Master_Consumer_Durable_Model
  public LMS_Get_Loan_Detail_For_LoanAcNo(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/LMS_Get_Loan_Detail_For_LoanAcNo`,
      requestData
    );
  }

  //end

  // start Masters/Update_Loanac_No
  public Update_Loanac_No(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Update_Loanac_No`,
      requestData
    );
  }

  //end

  // start GetFileCheckingMaster_List
  public GetFileCheckingMaster_List(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/GetFileCheckingMaster_List`,
      requestData
    );
  }
  //end

  // Save_Upper_Employee_ProductAssign
  public Save_FileCheckingMaster(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_FileCheckingMaster`,
      requestData
    );
  }

  //end

  // Save_Upper_Employee_ProductAssign
  public GetFileCheckingMaster_Details(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/GetFileCheckingMaster_Details`,
      requestData
    );
  }

  //end

  // Delete_File_Checking
  public Delete_File_Checking(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Delete_File_Checking`,
      requestData
    );
  }

  //end

  // start Masters/AU_Mandate_RegistrationByUser
  public AU_Mandate_RegistrationByUser(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/AU_Mandate_RegistrationByUser`,
      requestData
    );
  }

  //end

  // start Masters/AU_Mandate_RegistrationWithoutUser
  public AU_Mandate_RegistrationWithoutUser(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/LMS/AU_Mandate_RegistrationWithoutUser`,
      requestData
    );
  }

  public Logout() {
    return this.http.get(`${constantUrl.apiEndpoint}/api/Authenticate/Logout`);
  }

  //end

  // CancelAccessToken
  public CancelAccessToken(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/CancelAccessToken`,
      requestData
    );
  }

  //end
  public Get_SMS_Detail_Exists(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_SMS_Detail_Exists`,
      requestData
    );
  }
  public MessageSendOTP(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/MessageSendOTP`,
      requestData
    );
  }
  public OTPValid(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/OTPValid`,
      requestData
    );
  }

  public MessageSendForgotOTP(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/MessageSendForgotOTP`,
      requestData
    );
  }
  public ForgotOTPValid(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/ForgotOTPValid`,
      requestData
    );
  }
  public Nupay_Mandate_Registration(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/ThirdPartyAPi/Nupay_Mandate_Registration`,
      requestData
    );
  }

  public AU_Payment_Initiate(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/ThirdPartyAPi/AU_Payment_Initiate`,
      requestData
    );
  }
  public AU_Payment_Enquiry(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/ThirdPartyAPi/AU_Payment_Enquiry`,
      requestData
    );
  }

  public Get_Product_LoginFeeCharges(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Product_LoginFeeCharges`,
      requestData
    );
  }
  public Save_Product_LoginFeeCharges(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Product_LoginFeeCharges`,
      requestData
    );
  }

  public Get_Employee_Lender_NotAssigned_List(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Get_Employee_Lender_NotAssigned_List`,
      requestData
    );
  }

  // Save_Upper_Employee_LenderAssign
  public Save_Upper_Employee_LenderAssign(requestData) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Save_Upper_Employee_LenderAssign`,
      requestData
    );
  }

  //end



    // Delete_Employee_Assign_Lender
    public Delete_Employee_Assign_Lender(requestData) {
      return this.http.post(
        `${constantUrl.apiEndpoint}/api/Masters/Delete_Employee_Assign_Lender`,
        requestData
      );
    }
  
    //end


}
