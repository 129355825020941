<section class="main-content-wrapper">

  <mat-tab-group class="Product_Layout" id="ProductTab" [selectedIndex]="currentProductTab" (selectedTabChange)="onTabChange($event)">
    <mat-tab>
      <ng-template mat-tab-label><span>Product</span></ng-template>
      <ng-template matTabContent>
        <app-product-details [ProductSummary]="ProductSummary"></app-product-details>
      </ng-template>
    </mat-tab>



    <!--<mat-tab [disabled]="TabDisabled">
    <ng-template mat-tab-label><span>Work Flow</span></ng-template>
    <ng-template matTabContent>
      <app-product-work-flow [ProductSummary]="ProductSummary"></app-product-work-flow>
    </ng-template>
  </mat-tab>-->

    <mat-tab [disabled]="TabDisabled">
      <ng-template mat-tab-label><span>Documents</span></ng-template>
      <ng-template matTabContent>
        <app-product-documents [ProductSummary]="ProductSummary"></app-product-documents>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="TabDisabled">
      <ng-template mat-tab-label><span>Configuration</span></ng-template>
      <ng-template matTabContent>
        <app-product-configuration [ProductSummary]="ProductSummary"></app-product-configuration>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="TabDisabled">
      <ng-template mat-tab-label><span>QLS Process Level</span></ng-template>
      <ng-template matTabContent>
        <app-quickloan-process-level [ProductSummary]="ProductSummary"></app-quickloan-process-level>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="TabDisabled">
      <ng-template mat-tab-label><span>QLS Process Assignment</span></ng-template>
      <ng-template matTabContent>
        <app-quickloan-process-assignment [ProductSummary]="ProductSummary"></app-quickloan-process-assignment>
      </ng-template>
    </mat-tab>
    <mat-tab [disabled]="TabDisabled">
      <ng-template mat-tab-label><span>Account Add</span></ng-template>
      <ng-template matTabContent>
        <app-product-account-details [ProductSummary]="ProductSummary"></app-product-account-details>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

</section>
