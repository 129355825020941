<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keyup.enter)="onSearchBusinessData()"
    class="formborder"
  >
    <div class="col-md-12 p-0 row">
      <div class="col-md-3">
        <span class="required-lable"> From Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="FromDate"
            id="FromDate"
            #refFromDate="ngModel"
            class="form-control input-text-css"
            (dateChange)="DateChange()"
            required
            [(ngModel)]="FromDate"
            [ngClass]="{
              'is-invalid': df.submitted && refFromDate.invalid,
              'alert-warning':
                refFromDate.invalid &&
                (refFromDate.dirty ||
                  refFromDate.touched ||
                  refFromDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
      <div class="col-md-3">
        <span class="required-lable"> To Date </span>

        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ToDate"
            id="ToDate"
            (dateChange)="DateChange()"
            required
            #refToDate="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="ToDate"
            [ngClass]="{
              'is-invalid': df.submitted && refToDate.invalid,
              'alert-warning':
                refToDate.invalid &&
                (refToDate.dirty || refToDate.touched || refToDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
      </div>
      <div class="col-md-3">
        <span> Search By </span>
        <select
          name="SearchBy"
          id="SearchBy"
          [(ngModel)]="SearchBy"
          class="form-control input-text-css"
        >
          <option value="Branch">Branch Wise</option>
          <option value="Product">Product Wise</option>
          <option value="CollectionEX">Collection executive Wise</option>
          <option value="SalesEX">Sales executive Wise</option>
        </select>
      </div>
      <div class="col-md-3">
        <span class="required-lable"> Accounting Category </span>
        <select
          name="Loan_AccountingCategoryId"
          id="Loan_AccountingCategoryId"
          [(ngModel)]="LoanAccountCategory"
          class="form-control input-text-css"
        >
          <option value="0">All</option>
          <option
            *ngFor="let AccountingCat of AccountingCatDropdown"
            [value]="AccountingCat.Loan_CategoryId"
          >
            {{ AccountingCat.Loan_category }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-md-12 row p-0">
      <div class="col-md-3">
        <span> Source Type </span>
        <select
          name="SelectSourceType"
          id="SelectSourceType"
          #refSelectSourceType="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refSelectSourceType.invalid,
            'alert-warning':
              refSelectSourceType.invalid &&
              (refSelectSourceType.dirty ||
                refSelectSourceType.touched ||
                refSelectSourceType.untouched)
          }"
          (change)="Get_PartnerForDropdown()"
          [(ngModel)]="SelectSourceType"
          class="form-control input-text-css"
        >
          <option value="">Select Source Type</option>
          <option
            *ngFor="let lead of leadSourceDropdown"
            [value]="lead.Lead_Source"
          >
            {{ lead.Lead_Source }}
          </option>
        </select>
      </div>
      <div class="col-md-3">
        <div
          *ngIf="
            SelectSourceType == 'Agent' ||
            SelectSourceType == 'DSA' ||
            SelectSourceType == 'Dealer' ||
            SelectSourceType == 'Sales Executive' ||
            SelectSourceType == 'Telecaller Executive' ||
            SelectSourceType == 'Marketing Executive'
          "
        >
          <span> Source Name</span>
          <select
            name="LeadSourceName"
            id="LeadSourceName"
            #refLeadSourceName="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refLeadSourceName.invalid,
              'alert-warning':
                refLeadSourceName.invalid &&
                (refLeadSourceName.dirty ||
                  refLeadSourceName.touched ||
                  refLeadSourceName.untouched)
            }"
            [(ngModel)]="LeadSourceName"
            class="form-control input-text-css"
          >
            <option value="">Select Lead Reference</option>
            <option
              *ngFor="let partner of partnerDropdown"
              [value]="partner.PartnerId"
            >
              {{ partner.Partner_Name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-6">
        <button
          type="button"
          (click)="onSearchBusinessData()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
          style="float: right"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="mt-3">
    <div class="">
      <div
        class="table-responsive"
        [hidden]="!DataSource"
        style="overflow: auto; max-height: 60vh"
      >
        <table
          mat-table
          class="table-bordered table-striped"
          matSort
          [dataSource]="DataSource"
          matTableExporter
          #exporter="matTableExporter"
        >
          <ng-container matColumnDef="Id">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let row; let i = index">
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let row">
              {{ row.Name }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">Total:</th>
          </ng-container>

          <ng-container matColumnDef="Due_Case">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Due Case</th>
            <td mat-cell *matCellDef="let row">{{ row.Due_Case }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-center">
              {{ totaltypewiseCases("Due_Case") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="EMI_Due">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>EMI Due</th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.EMI_Due }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("EMI_Due") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Total_Principle_Due">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Total Principle Due
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Total_Principle_Due }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Total_Principle_Due") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Total_Interest_Due">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Total Interest Due
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Total_Interest_Due }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Total_Interest_Due") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Received_Case">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Received Case
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Received_Case }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-center">
              {{ totaltypewiseCases("Received_Case") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="EMI_Collection">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              EMI Collection
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.EMI_Collection }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("EMI_Collection") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Total_Principle_Received">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Total Principle Received
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Total_Principle_Received }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Total_Principle_Received") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Total_Interest_Received">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Total Interest Received
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Total_Interest_Received }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Total_Interest_Received") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Action">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row">
              <i
                class="fa fa-arrow-circle-right arrow-circle action-btn"
                (click)="goToDetails(row)"
                aria-label="true"
              ></i>
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-center"></th>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="sticky-footer fontcolor"
            style="background: #4dc3a3"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr
            mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"
          ></tr>
        </table>
      </div>

      <div class="row m-0 align-items-center" [hidden]="!DataSource">
        <div class="col-md-3">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportexcel()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export Summary
          </button>
        </div>
        <div class="col-md-3">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportDetail()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export Detailed
          </button>
        </div>
        <div class="col-md-6">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="ViewBusinessCollectionDetails"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          View Details
        </h6>
        <button
          type="button"
          (click)="onCloseBusinessCollectionDetails()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="float-right mb-2">
          <form action="" class="search-text">
            <div class="bg-light rounded rounded-pill shadow-sm">
              <div class="input-group">
                <input
                  type="text"
                  id="FilterInputSearch"
                  (keyup)="applyFilter($event.target.value)"
                  name="FilterInputSearch"
                  placeholder="What're you searching for?"
                  class="form-control border-0 bg-light"
                />
                <div class="input-group-append">
                  <button
                    id="button-addon1"
                    type="submit"
                    class="btn btn-link text-primary"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="table-responsive">
          <mat-table
            [dataSource]="DataSourceModel"
            matSort
            matTableExporter
            #exporter1="matTableExporter"
            style="height: 400px; max-width: 100%; overflow: auto"
          >
            <ng-container
              [matColumnDef]="col"
              *ngFor="let col of displayedColumnsModel"
            >
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                {{
                  col == "Id"
                    ? "#"
                    : col == "LoanAcNo"
                    ? "Loan No."
                    : col == "EMIType"
                    ? "EMI Type"
                    : col == "DueDate"
                    ? "Due Date"
                    : col == "Total_Principle_Received"
                    ? "Principle Received"
                    : col == "Total_Interest_Received"
                    ? "Interest Received"
                    : col == "CustomerName"
                    ? "Customer Name"
                    : col == "SourceType"
                    ? "Source Type"
                    : col == "SourceName"
                    ? "Source Name"
                    : col.split("_").join(" ")
                }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
                {{
                  col == "Id"
                    ? paginatorlist.pageIndex * paginatorlist.pageSize + (i + 1)
                    : row[col]
                }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef class="grid-header text-left">
                {{
                  col == "LoanAcNo"
                    ? ""
                    : col == "Id"
                    ? ""
                    : col == "CustomerName"
                    ? ""
                    : col == "DueDate"
                    ? ""
                    : col == "EMIType"
                    ? "Total"
                    : DataSourceModel
                    ? totaltypewise1(col)
                    : ""
                }}
              </mat-footer-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumnsModel; sticky: true"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumnsModel"
            ></mat-row>
            <mat-footer-row
              class="sticky-footer fontcolor"
              *matFooterRowDef="displayedColumnsModel; sticky: true"
              style="background: #4dc3a3"
            ></mat-footer-row>
          </mat-table>
          <div class="row m-0 align-items-center" style="background: #fff">
            <div class="col-md-4">
              <button
                class="mt-3 btn font-size-12 button-btn"
                (click)="exportexcelDetail()"
              >
                <i
                  class="fa fa-file-export mr-1"
                  style="font-size: medium; cursor: pointer"
                ></i>
                Export
              </button>
            </div>

            <div class="col-md-8">
              <mat-paginator
                #paginatorlist
                [pageSize]="20"
                [pageSizeOptions]="[20, 50]"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="branch_wise_duevscoll_summary"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Name
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Due Case
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Due
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Principle Due
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Interest Due
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Received Case
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Collection
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principle Received
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest Received
    </th>
  </tr>
  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i }}</td>
    <td>{{ x.Name }}</td>
    <td>{{ x.Due_Case }}</td>
    <td>{{ x.EMI_Due }}</td>
    <td>{{ x.Total_Principle_Due }}</td>
    <td>{{ x.Total_Interest_Due }}</td>
    <td>{{ x.Received_Case }}</td>
    <td>{{ x.EMI_Collection }}</td>
    <td>{{ x.Total_Principle_Received }}</td>
    <td>{{ x.Total_Interest_Received }}</td>
  </tr>
  <tfoot>
    <tr>
      <td
        colspan="2"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Due_Case") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("EMI_Due") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Total_Principle_Due") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Total_Interest_Due") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Received_Case") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("EMI_Collection") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Total_Principle_Received") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Total_Interest_Received") }}</strong>
      </td>
    </tr>
  </tfoot>
</table>

<table
  id="branch_wise_duevscoll_detail"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Due Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Due
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Principle Due
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Interest Due
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Collection
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principle Received
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest Received
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Name
    </th>
  </tr>

  <tr *ngFor="let x of JsonDataDetail; let i = index">
    <td>{{ i }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.DueDate }}</td>
    <td>{{ x.EMIType }}</td>
    <td>{{ x.EMI_Due }}</td>
    <td>{{ x.Total_Principle_Due }}</td>
    <td>{{ x.Total_Interest_Due }}</td>
    <td>{{ x.EMI_Collection }}</td>
    <td>{{ x.Total_Principle_Received }}</td>
    <td>{{ x.Total_Interest_Received }}</td>
    <td>{{ x.SourceType }}</td>
    <td>{{ x.SourceName }}</td>
  </tr>
  <tfoot>
    <tr>
      <td
        colspan="5"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("EMI_Due") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("Total_Principle_Due") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("Total_Interest_Due") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("EMI_Collection") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("Total_Principle_Received") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("Total_Interest_Received") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
    </tr>
  </tfoot>
</table>

<table
  id="detailExport"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Ac No
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Due
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Principle Due
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Interest Due
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Collection
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Principle Received
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Interest Received
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Due Date
    </th>
  </tr>
  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>

    <td>{{ x.EMI_Due }}</td>
    <td>{{ x.Total_Principle_Due }}</td>
    <td>{{ x.Total_Interest_Due }}</td>

    <td>{{ x.EMI_Collection }}</td>
    <td>{{ x.Total_Principle_Received }}</td>
    <td>{{ x.Total_Interest_Received }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.EMIType }}</td>
    <td>{{ x.DueDate }}</td>
  </tr>
</table>
