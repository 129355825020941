import { Component, OnInit } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import * as moment from "moment";
import { ReportsService } from "src/app/_Reports/services/reports.service";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { CommonFunctions } from "src/app/CommonComponent/function/commonfunctions";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { DialogService } from "src/app/Shared/dialog.service";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
declare var $: any;

@Component({
  selector: "app-customer-third-partyapi",
  templateUrl: "./customer-third-partyapi.component.html",
  styleUrls: ["./customer-third-partyapi.component.scss"],
})
export class CustomerThirdPartyapiComponent implements OnInit {
  showSpinner: boolean = false;
  VehicleRegistationNo: any = "";
  RCKey: any;
  RCSMS_URL: any;
  RCVerified_Button: boolean = false;
  RCLastVerfiedDate: any;
  RCKYC_IsVerified: any;

  DLKey: any;
  DLSMS_URL: any;
  DLVerified_Button: boolean = false;
  DLLastVerfiedDate: any;
  DLKYC_IsVerified: any;

  ElectricityStatelist: any[] = [];
  ElectricityOpCodelist: any[] = [];
  OperatorCode: any = "";
  ELState: any = "";
  electricityNumber: any;

  voterIdNumber: any;

  VerifyIFSC: any;
  VerifyAccountNo: any;

  Step_Name: any = "";
  data: any;

  APIResponse: any = {
    DoucumentNumber: "",
    Full_name: "",
    IsVerified: "",
    category: "",
  };

  stepNameDropdown: any;
  selectedStep: string = "";
  DL_DOB: any;
  LicenseNumber: any;
  panNumber: any;
  currentUser: any;
  verify_DocNumber: any;
  verify_DocId: any;
  APIResponseData: {
    KYC_DocId: number;
    DoucumentNumber: any;

    Full_name: any;
    IsVerified: any;
    address: any;
    mobile: any;
    bank_name: any;
    account_exists: any;
    micr: any;
    branch: any;
    ifsc: any;
    state: any;
    district: any;
    city: any;
    rtgs: any;
    neft: any;
    imps: any;
    Remark: any;
    message: any;
    message_code: any;
  };
  BankForm: boolean = true;
  excludedStep: any;
  OTP: any;
  aadharCardNumber: any;
  clientId: any;
  record: any;
  ApplicationDetail: any;
  constructor(
    private dataSharingService: DataSharingService,
    private dialog: DialogService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private reportsService: ReportsService,
    private _commonfunctions: CommonFunctions
  ) {}

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Customer KYC Verification");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.DropdownFor_KycVerificationApi();
    this.getElectricityStatelist();
  }

  getElectricityStatelist() {
    this._MasterService.Get_Electricity_State().subscribe((res: any) => {
      if (res.length > 0) {
        this.ElectricityStatelist = res;
      }
    });
  }
  getElectricityOpCodelist() {
    this._MasterService
      .Get_Electricity_OperatorCode({ state: this.ELState })
      .subscribe((res: any) => {
        this.ElectricityOpCodelist = res;
      });
  }

  onKeyPress(event: KeyboardEvent) {
    const char = String.fromCharCode(event.which);
    const charUpper = char.toUpperCase();

    this.VehicleRegistationNo += charUpper;

    event.preventDefault();
  }

  stepNameVerification(stepName: string) {
    console.log("this.selectedStep", stepName);
    this._MasterService
      .Get_Sms_Configration({ Step_Name: stepName })
      .subscribe((res: any) => {
        if (res.length === 0) {
          this.RCVerified_Button = false;
        } else {
          this.RCVerified_Button = true;
          this.RCKey = res[0].Key;
          this.RCSMS_URL = res[0].SMS_URL;
        }
      });
  }

  DropdownFor_KycVerificationApi() {
    this.reportsService
      .DropdownFor_KycVerificationApi()
      .subscribe((res: any) => {
        this.stepNameDropdown = res;
        // console.log("Step Name", this.stepNameDropdown);

        this.excludedStep = res.find(
          (step: any) => step.Step_Name === "Aadhaar_submit_otp"
        );
        console.log("aadhar submit otp", this.excludedStep);
        this.stepNameDropdown = res.filter(
          (step: any) => step.Step_Name !== "Aadhaar_submit_otp"
        );
        console.log("this.stepNameDropdown", this.stepNameDropdown);
      });
  }

  isStepAvailable(stepName: string): boolean {
    return this.stepNameDropdown.some(
      (step: any) => step.Step_Name === stepName
    );
  }

  verifyRCVerificationModal(stepName: string) {
    this.stepNameVerification(stepName);
    this.openModal("#rcVerificationModal");
  }

  verifyAadharVerificationModal(stepName: string) {
    this.stepNameVerification(stepName);
    this.openModal("#aadharCardVerification");
  }

  verifyPanVerificationModal(stepName: string) {
    this.stepNameVerification(stepName);
    this.openModal("#panVerificationModal");
  }

  verifyElectricityVerificationModal(stepName: string) {
    this.stepNameVerification(stepName);
    this.openModal("#electricityModal");
  }

  verifyBankVerificationModal(stepName: string) {
    this.stepNameVerification(stepName);
    this.openModal("#bankVerificationModal");
  }

  verifyDLVerificationModal(stepName: string) {
    this.stepNameVerification(stepName);
    this.openModal("#drivingLicenseVerificationModal");
  }

  verifyVoterIdVerificationModal(stepName: string) {
    this.stepNameVerification(stepName);
    this.openModal("#voterIdModal");
  }

  onDocsPrint(divName, Header) {
    this._commonfunctions.PrintDocumentsRC(divName, Header);
  }

  openModal(modalId: string) {
    $(modalId).modal("show");
    $(modalId).css("z-index", "1050");
  }

  onCloseModal(modalId: string) {
    $(modalId).modal("hide");
  }

  onCloseRcVerificationModal() {
    this.VehicleRegistationNo = "";
    $("#rcVerificationModal").modal("hide");
  }

  verifyCustomerRC() {
    this.KYCVerifyByAPI();
  }

  KYCVerifyByAPI() {
    this.showSpinner = true;
    var model = {
      KYC_DocNumber: this.VehicleRegistationNo,
      KYC_DocId: 17,
      Key: this.RCKey,
      SMS_URL: this.RCSMS_URL,
    };
    this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
      var APIResult = res;
      var data = {
        KYC_Verification: {
          DoucumentNumber: this.VehicleRegistationNo,

          DocumentId: 17,
          IsVerified: APIResult.success,
          APIURL: APIResult.APIURL,
          APIRequest: APIResult.request,
          APIResponse: APIResult.response,
          APIStatus: APIResult.status_code,
          LoginUserId: this.currentUser.userId,
        },
      };

      if (APIResult.success == true) {
        this.RCKYC_IsVerified = 1;
        this.APIResponse = {
          KYC_DocId: 17,

          DoucumentNumber: JSON.parse(data.KYC_Verification.APIResponse).data
            .rc_number,
          IsVerified: JSON.parse(data.KYC_Verification.APIResponse).success,
          rc_number: JSON.parse(data.KYC_Verification.APIResponse).data
            .rc_number,
          fit_up_to: JSON.parse(data.KYC_Verification.APIResponse).data
            .fit_up_to,
          registration_date: JSON.parse(data.KYC_Verification.APIResponse).data
            .registration_date,
          owner_name: JSON.parse(data.KYC_Verification.APIResponse).data
            .owner_name,
          father_name: JSON.parse(data.KYC_Verification.APIResponse).data
            .father_name,
          present_address: JSON.parse(data.KYC_Verification.APIResponse).data
            .present_address,
          permanent_address: JSON.parse(data.KYC_Verification.APIResponse).data
            .permanent_address,
          mobile_number: JSON.parse(data.KYC_Verification.APIResponse).data
            .mobile_number,
          vehicle_category: JSON.parse(data.KYC_Verification.APIResponse).data
            .vehicle_category,
          vehicle_chasi_number: JSON.parse(data.KYC_Verification.APIResponse)
            .data.vehicle_chasi_number,
          vehicle_engine_number: JSON.parse(data.KYC_Verification.APIResponse)
            .data.vehicle_engine_number,
          maker_description: JSON.parse(data.KYC_Verification.APIResponse).data
            .maker_description,
          maker_model: JSON.parse(data.KYC_Verification.APIResponse).data
            .maker_model,
          body_type: JSON.parse(data.KYC_Verification.APIResponse).data
            .body_type,
          fuel_type: JSON.parse(data.KYC_Verification.APIResponse).data
            .fuel_type,
          color: JSON.parse(data.KYC_Verification.APIResponse).data.color,
          norms_type: JSON.parse(data.KYC_Verification.APIResponse).data
            .norms_type,
          financer: JSON.parse(data.KYC_Verification.APIResponse).data.financer,
          financed: JSON.parse(data.KYC_Verification.APIResponse).data.financed,
          insurance_company: JSON.parse(data.KYC_Verification.APIResponse).data
            .insurance_company,
          insurance_policy_number: JSON.parse(data.KYC_Verification.APIResponse)
            .data.insurance_policy_number,
          insurance_upto: JSON.parse(data.KYC_Verification.APIResponse).data
            .insurance_upto,
          manufacturing_date: JSON.parse(data.KYC_Verification.APIResponse).data
            .manufacturing_date,
          manufacturing_date_formatted: JSON.parse(
            data.KYC_Verification.APIResponse
          ).data.manufacturing_date_formatted,
          registered_at: JSON.parse(data.KYC_Verification.APIResponse).data
            .registered_at,
          latest_by: JSON.parse(data.KYC_Verification.APIResponse).data
            .latest_by,
          less_info: JSON.parse(data.KYC_Verification.APIResponse).data
            .less_info,
          tax_upto: JSON.parse(data.KYC_Verification.APIResponse).data.tax_upto,
          tax_paid_upto: JSON.parse(data.KYC_Verification.APIResponse).data
            .tax_paid_upto,
          cubic_capacity: JSON.parse(data.KYC_Verification.APIResponse).data
            .cubic_capacity,
          vehicle_gross_weight: JSON.parse(data.KYC_Verification.APIResponse)
            .data.vehicle_gross_weight,
          no_cylinders: JSON.parse(data.KYC_Verification.APIResponse).data
            .no_cylinders,
          seat_capacity: JSON.parse(data.KYC_Verification.APIResponse).data
            .seat_capacity,
          sleeper_capacity: JSON.parse(data.KYC_Verification.APIResponse).data
            .sleeper_capacity,
          standing_capacity: JSON.parse(data.KYC_Verification.APIResponse).data
            .standing_capacity,
          wheelbase: JSON.parse(data.KYC_Verification.APIResponse).data
            .wheelbase,
          unladen_weight: JSON.parse(data.KYC_Verification.APIResponse).data
            .unladen_weight,
          vehicle_category_description: JSON.parse(
            data.KYC_Verification.APIResponse
          ).data.vehicle_category_description,
          pucc_number: JSON.parse(data.KYC_Verification.APIResponse).data
            .pucc_number,
          pucc_upto: JSON.parse(data.KYC_Verification.APIResponse).data
            .pucc_upto,
          permit_number: JSON.parse(data.KYC_Verification.APIResponse).data
            .permit_number,
          permit_issue_date: JSON.parse(data.KYC_Verification.APIResponse).data
            .permit_issue_date,
          permit_valid_from: JSON.parse(data.KYC_Verification.APIResponse).data
            .permit_valid_from,
          permit_valid_upto: JSON.parse(data.KYC_Verification.APIResponse).data
            .permit_valid_upto,
          permit_type: JSON.parse(data.KYC_Verification.APIResponse).data
            .permit_type,
          national_permit_number: JSON.parse(data.KYC_Verification.APIResponse)
            .data.national_permit_number,
          national_permit_upto: JSON.parse(data.KYC_Verification.APIResponse)
            .data.national_permit_upto,
          national_permit_issued_by: JSON.parse(
            data.KYC_Verification.APIResponse
          ).data.national_permit_issued_by,
          non_use_status: JSON.parse(data.KYC_Verification.APIResponse).data
            .non_use_status,
          non_use_from: JSON.parse(data.KYC_Verification.APIResponse).data
            .non_use_from,
          non_use_to: JSON.parse(data.KYC_Verification.APIResponse).data
            .non_use_to,
          blacklist_status: JSON.parse(data.KYC_Verification.APIResponse).data
            .blacklist_status,
          noc_details: JSON.parse(data.KYC_Verification.APIResponse).data
            .noc_details,
          owner_number: JSON.parse(data.KYC_Verification.APIResponse).data
            .owner_number,
          rc_status: JSON.parse(data.KYC_Verification.APIResponse).data
            .rc_status,
          masked_name: JSON.parse(data.KYC_Verification.APIResponse).data
            .masked_name,
          challan_details: JSON.parse(data.KYC_Verification.APIResponse).data
            .challan_details,
          variant: JSON.parse(data.KYC_Verification.APIResponse).data.variant,
          rto_code: JSON.parse(data.KYC_Verification.APIResponse).data.rto_code,

          message_code: JSON.parse(data.KYC_Verification.APIResponse)
            .message_code,
        };
      } else {
        this.RCKYC_IsVerified = 0;
      }

      this._MasterService
        .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
        .subscribe((Save_res: any) => {
          if (Save_res[0].CODE == 0) {
            this.showSpinner = false;
            if (
              JSON.parse(data.KYC_Verification.APIResponse).status_code != 200
            ) {
              this.RCKYC_IsVerified = 0;
              this.showSpinner = false;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: JSON.parse(data.KYC_Verification.APIResponse).message,
                ...this.configSuccess,
              });
            }
          }
        });
    });
  }

  onVerifiedDrivingLicence() {
    this.showSpinner = true;
    var model = {
      KYC_DocNumber: this.LicenseNumber,
      KYC_DocId: 5,
      Key: this.RCKey,
      SMS_URL: this.RCSMS_URL,
      DOB: moment(this.DL_DOB).format("YYYY-MM-DD"),
    };

    console.log("modellllll dl", model);

    this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
      var APIResult = res;
      var data = {
        KYC_Verification: {
          DoucumentNumber: this.verify_DocNumber,
          DocumentId: 5,
          IsVerified: APIResult.success,
          APIURL: APIResult.APIURL,
          APIRequest: APIResult.request,
          APIResponse: APIResult.response,
          APIStatus: APIResult.status_code,
          LoginUserId: this.currentUser.userId,
        },
      };

      if (APIResult.success == true) {
        this.APIResponse = {
          KYC_DocId: 5,
          profile_image: JSON.parse(data.KYC_Verification.APIResponse).data
            .profile_image,
          DoucumentNumber: JSON.parse(data.KYC_Verification.APIResponse).data
            .license_number,
          Full_name: JSON.parse(data.KYC_Verification.APIResponse).data.name,
          address: JSON.parse(data.KYC_Verification.APIResponse).data
            .permanent_address,
          dob: JSON.parse(data.KYC_Verification.APIResponse).data.dob,
          doi: JSON.parse(data.KYC_Verification.APIResponse).data.doi,
          doe: JSON.parse(data.KYC_Verification.APIResponse).data.doe,
          ola_name: JSON.parse(data.KYC_Verification.APIResponse).data.ola_name,
          vehicle_classes: JSON.parse(data.KYC_Verification.APIResponse).data
            .vehicle_classes,
        };
      }
      this._MasterService
        .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
        .subscribe((Save_res: any) => {
          this.showSpinner = true;
          if (Save_res[0].CODE == 0) {
            this.showSpinner = false;
            if (APIResult.success == true) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "YOUR KYC IS VERIFIED !!!",
                ...this.configSuccess,
              });
            } else {
              //this.CustomerKYCDoc[this.record].KYC_IsVerified = 0;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "YOUR KYC NOT VALID !!!",
                ...this.configSuccess,
              });
              return;
            }
          }
        });

      this.showSpinner = false;
    });
  }

  onVerifiedPanNo() {
    var model = {
      KYC_DocNumber: this.panNumber,
      KYC_DocId: 4,
      Key: this.RCKey,
      SMS_URL: this.RCSMS_URL,
    };

    this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
      //console.log("API Result", res);
      var APIResult = res;
      var data = {
        KYC_Verification: {
          DoucumentNumber: model.KYC_DocNumber,
          DocumentId: model.KYC_DocId,
          IsVerified: APIResult.success,
          APIURL: APIResult.APIURL,
          APIRequest: APIResult.request,
          APIResponse: APIResult.response,
          APIStatus: APIResult.status_code,
          LoginUserId: this.currentUser.userId,
        },
      };

      if (APIResult.success == true) {
        // KYCDocs[this.record].KYC_IsVerified = 1;
        this.APIResponse = {
          KYC_DocId: 1,

          DoucumentNumber: JSON.parse(data.KYC_Verification.APIResponse).data
            .pan_number,
          name: JSON.parse(data.KYC_Verification.APIResponse).data.full_name,
        };
      }
      this._MasterService
        .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
        .subscribe((Save_res: any) => {
          this.showSpinner = true;
          if (Save_res[0].CODE == 0) {
            this.showSpinner = false;
            if (APIResult.success == true) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "YOUR KYC IS VERIFIED !!!",
                ...this.configSuccess,
              });
            } else {
              //this.CustomerKYCDoc[this.record].KYC_IsVerified = 0;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "YOUR KYC NOT VALID !!!",
                ...this.configSuccess,
              });
              return;
            }
          }
        });
    });
  }

  onVerifiedElectricity() {
    var model = {
      KYC_DocNumber: this.electricityNumber,
      KYC_DocId: 2,
      Key: this.RCKey,
      SMS_URL: this.RCSMS_URL,
      Op_code: this.OperatorCode,
    };

    this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
      //console.log("API Result", res);
      var APIResult = res;
      var data = {
        KYC_Verification: {
          DoucumentNumber: model.KYC_DocNumber,
          DocumentId: model.KYC_DocId,
          IsVerified: APIResult.success,
          APIURL: APIResult.APIURL,
          APIRequest: APIResult.request,
          APIResponse: APIResult.response,
          APIStatus: APIResult.status_code,
          LoginUserId: this.currentUser.userId,
        },
      };

      if (APIResult.success == true) {
        // KYCDocs[this.record].KYC_IsVerified = 1;
        this.APIResponse = {
          KYC_DocId: 2,
          // DoucumentNumber: this.verify_DocNumber,
          // DoucumentNumber: this.verify_DocNumber.toUpperCase().substr(0, 3) + MaskingDoc + this.verify_DocNumber.toUpperCase().substr(this.verify_DocNumber.length - 3),
          DoucumentNumber: JSON.parse(data.KYC_Verification.APIResponse).data
            .customer_id,
          Full_name: JSON.parse(data.KYC_Verification.APIResponse).data
            .full_name,
          IsVerified: JSON.parse(data.KYC_Verification.APIResponse).data
            .success,
          address: JSON.parse(data.KYC_Verification.APIResponse).data.address,
          mobile: JSON.parse(data.KYC_Verification.APIResponse).data.mobile,
          state: JSON.parse(data.KYC_Verification.APIResponse).data.state,
          operator_code: JSON.parse(data.KYC_Verification.APIResponse).data
            .operator_code,
          message_code: JSON.parse(data.KYC_Verification.APIResponse)
            .message_code,
        };
      }
      this._MasterService
        .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
        .subscribe((Save_res: any) => {
          this.showSpinner = true;
          if (Save_res[0].CODE == 0) {
            this.showSpinner = false;
            if (APIResult.success == true) {
              $(`#ElectricityModelVerified`).modal("hide");
              $(`#modalOverlay`).modal("show");
              $(`#modalOverlay`).css("z-index", "1050");
            } else {
              //this.CustomerKYCDoc[this.record].KYC_IsVerified = 0;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "YOUR KYC NOT VALID !!!",
                ...this.configSuccess,
              });
              return;
            }
          }
        });
    });
  }

  onVerifiedVoterIDCard() {
    var model = {
      KYC_DocNumber: this.voterIdNumber,
      KYC_DocId: 4,
      Key: this.RCKey,
      SMS_URL: this.RCSMS_URL,
    };

    this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
      //console.log("API Result", res);
      var APIResult = res;
      var data = {
        KYC_Verification: {
          DoucumentNumber: model.KYC_DocNumber,
          DocumentId: model.KYC_DocId,
          IsVerified: APIResult.success,
          APIURL: APIResult.APIURL,
          APIRequest: APIResult.request,
          APIResponse: APIResult.response,
          APIStatus: APIResult.status_code,
          LoginUserId: this.currentUser.userId,
        },
      };

      if (APIResult.success == true) {
        // KYCDocs[this.record].KYC_IsVerified = 1;
        this.APIResponse = {
          KYC_DocId: 4,

          DoucumentNumber: JSON.parse(data.KYC_Verification.APIResponse).data
            .epic_no,
          name: JSON.parse(data.KYC_Verification.APIResponse).data.name,
          age: JSON.parse(data.KYC_Verification.APIResponse).data.age,
          gender: JSON.parse(data.KYC_Verification.APIResponse).data.gender,
          area: JSON.parse(data.KYC_Verification.APIResponse).data.area,
          dist: JSON.parse(data.KYC_Verification.APIResponse).data.district,
          state: JSON.parse(data.KYC_Verification.APIResponse).data.state,
        };
      }
      this._MasterService
        .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
        .subscribe((Save_res: any) => {
          this.showSpinner = true;
          if (Save_res[0].CODE == 0) {
            this.showSpinner = false;
            if (APIResult.success == true) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "YOUR KYC IS VERIFIED !!!",
                ...this.configSuccess,
              });
            } else {
              //this.CustomerKYCDoc[this.record].KYC_IsVerified = 0;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "YOUR KYC NOT VALID !!!",
                ...this.configSuccess,
              });
              return;
            }
          }
        });
    });
  }

  onVerifiedBankDetail() {
    this.showSpinner = true;

    var model = {
      KYC_DocNumber: this.VerifyAccountNo,

      Key: this.RCKey,
      SMS_URL: this.RCSMS_URL,
      IFSC: this.VerifyIFSC,
    };
    //  console.log("AllBankDetails sdfdf", this.AllBankDetails);

    // console.log("BankDetails sdfdf", this.BankDetails);
    this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
      var APIResult = res;
      var data = {
        KYC_Verification: {
          DoucumentNumber: model.KYC_DocNumber,
          DocumentId: 0,
          IsVerified: APIResult.success,
          APIURL: APIResult.APIURL,
          APIRequest: APIResult.request,
          APIResponse: APIResult.response,
          APIStatus: APIResult.status_code,
          LoginUserId: this.currentUser.userId,
        },
      };

      this._MasterService
        .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
        .subscribe((Save_res: any) => {
          if (Save_res[0].CODE == 0) {
            this.showSpinner = false;
            //if (APIResult.success == true) {
            this.BankForm = false;

            this.APIResponse = {
              KYC_DocId: 0,
              DoucumentNumber: this.VerifyAccountNo,
              // DoucumentNumber: this.verify_DocNumber.toUpperCase().substr(0, 3) + MaskingDoc + this.verify_DocNumber.toUpperCase().substr(this.verify_DocNumber.length - 3),

              Full_name: JSON.parse(data.KYC_Verification.APIResponse).data
                .full_name,
              IsVerified: JSON.parse(data.KYC_Verification.APIResponse).success,
              address: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.address,
              mobile: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.contact,
              bank_name: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.bank_name,
              account_exists: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.account_exists,
              message: JSON.parse(data.KYC_Verification.APIResponse).message,
              micr: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.micr,
              branch: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.branch,
              ifsc: this.VerifyIFSC,
              state: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.state,
              district: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.district,
              city: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.city,
              rtgs: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.rtgs,
              neft: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.neft,
              imps: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.imps,
              Remark: JSON.parse(data.KYC_Verification.APIResponse).data
                .remarks,

              message_code: JSON.parse(data.KYC_Verification.APIResponse)
                .message_code,
            };
            this.APIResponseData = {
              KYC_DocId: 0,
              DoucumentNumber: this.VerifyAccountNo,
              // DoucumentNumber: this.verify_DocNumber.toUpperCase().substr(0, 3) + MaskingDoc + this.verify_DocNumber.toUpperCase().substr(this.verify_DocNumber.length - 3),

              Full_name: JSON.parse(data.KYC_Verification.APIResponse).data
                .full_name,
              IsVerified: JSON.parse(data.KYC_Verification.APIResponse).success,
              address: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.address,
              mobile: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.contact,
              bank_name: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.bank_name,
              account_exists: JSON.parse(data.KYC_Verification.APIResponse).data
                .account_exists,

              micr: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.micr,
              branch: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.branch,
              ifsc: this.VerifyIFSC,
              state: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.state,
              district: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.district,
              city: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.city,
              rtgs: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.rtgs,
              neft: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.neft,
              imps: JSON.parse(data.KYC_Verification.APIResponse).data
                .ifsc_details.imps,
              Remark: JSON.parse(data.KYC_Verification.APIResponse).data
                .remarks,
              message: JSON.parse(data.KYC_Verification.APIResponse).message,

              message_code: JSON.parse(data.KYC_Verification.APIResponse)
                .message_code,
            };
            //} else {
            //  //this.CustomerKYCDoc[this.record].KYC_IsVerified = 0;
            //  this.onCloseBankModel();
            //  this.snackBar.openFromComponent(SnackbarComponent, {
            //    data: "YOUR Bank Account NOT VALID !!!",
            //    ...this.configSuccess,
            //  });
            //  return;
            //}
          }
        });
    });
  }

  onVerifiedAadharCard() {
    var model = {
      KYC_DocNumber: this.aadharCardNumber,
      SMS_URL: this.RCSMS_URL,
      KYC_DocId: 6,
      Key: this.RCKey,
    };
    this._MasterService.Get_KYC_Verification(model).subscribe((res: any) => {
      //console.log("API Result", res);
      var APIResult = res;
      var data = {
        KYC_Verification: {
          DoucumentNumber: this.aadharCardNumber,
          DocumentId: 6,
          IsVerified: APIResult.success,
          APIURL: APIResult.APIURL,
          APIRequest: APIResult.request,
          APIResponse: APIResult.response,
          APIStatus: APIResult.status_code,
          LoginUserId: this.currentUser.userId,
        },
      };

      this._MasterService
        .Save_Customer_Kyc_Verification({ JSON: JSON.stringify(data) })
        .subscribe((Save_res: any) => {
          this.showSpinner = false;
          if (Save_res[0].CODE == 0) {
            if (
              JSON.parse(data.KYC_Verification.APIResponse).status_code != 200
            ) {
              this.showSpinner = false;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: JSON.parse(data.KYC_Verification.APIResponse).message,
                ...this.configSuccess,
              });
            } else {
              this.clientId = JSON.parse(
                data.KYC_Verification.APIResponse
              ).data.client_id;
              this.verify_DocNumber = this.aadharCardNumber;
              this.verify_DocId = 6;
              $(`#KYCOTPModel`).modal("show");
              $(`#KYCOTPModel`).css("z-index", "1050");
            }
          }
        });
    });
  }

  onSAVEKYCOTP() {
    this._MasterService
      .Get_Sms_Configration({ Step_Name: "Aadhaar_submit_otp" })
      .subscribe((res: any) => {
        var data = {
          Key: res[0].Key,
          SMS_URL: res[0].SMS_URL,
        };
        var model = {
          KYC_DocNumber: this.verify_DocNumber,
          SMS_URL: data.SMS_URL,
          KYC_DocId: this.verify_DocId,
          Key: data.Key,
          OTP: this.OTP,
          clientId: this.clientId,
        };

        this._MasterService
          .Get_KYC_Verification(model)
          .subscribe((res: any) => {
            //console.log("API Result", res);
            var APIResult = res;
            var data = {
              KYC_Verification: {
                DoucumentNumber: model.KYC_DocNumber,
                DocumentId: model.KYC_DocId,
                IsVerified: APIResult.success,
                APIURL: APIResult.APIURL,
                APIRequest: APIResult.request,
                APIResponse: APIResult.response,
                APIStatus: APIResult.status_code,
                LoginUserId: this.currentUser.userId,
              },
            };

            if (APIResult.success == true) {
              var clientId = JSON.parse(data.KYC_Verification.APIResponse).data
                .client_id;

              this.APIResponse = {
                KYC_DocId: 6,
                DoucumentNumber: this.verify_DocNumber,
                Full_name: JSON.parse(data.KYC_Verification.APIResponse).data
                  .full_name,
                IsVerified: JSON.parse(data.KYC_Verification.APIResponse).data
                  .success,
                dob: JSON.parse(data.KYC_Verification.APIResponse).data.dob,
                gender: JSON.parse(data.KYC_Verification.APIResponse).data
                  .gender,
                country: JSON.parse(data.KYC_Verification.APIResponse).data
                  .address.country,
                dist: JSON.parse(data.KYC_Verification.APIResponse).data.address
                  .dist,
                state: JSON.parse(data.KYC_Verification.APIResponse).data
                  .address.state,
                po: JSON.parse(data.KYC_Verification.APIResponse).data.address
                  .po,
                loc: JSON.parse(data.KYC_Verification.APIResponse).data.address
                  .loc,
                vtc: JSON.parse(data.KYC_Verification.APIResponse).data.address
                  .vtc,
                subdist: JSON.parse(data.KYC_Verification.APIResponse).data
                  .address.subdist,
                street: JSON.parse(data.KYC_Verification.APIResponse).data
                  .address.street,
                house: JSON.parse(data.KYC_Verification.APIResponse).data
                  .address.house,
                landmark: JSON.parse(data.KYC_Verification.APIResponse).data
                  .address.landmark,
                Pin: JSON.parse(data.KYC_Verification.APIResponse).data.zip,
                profile_image: JSON.parse(data.KYC_Verification.APIResponse)
                  .data.profile_image,
                care_of: JSON.parse(data.KYC_Verification.APIResponse).data
                  .care_of,
                message_code: JSON.parse(data.KYC_Verification.APIResponse)
                  .message_code,
                zip: JSON.parse(data.KYC_Verification.APIResponse).data.zip,
              };
              //console.log("this.APIResponse", this.APIResponse);
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "YOUR KYC NOT VALID !!!",
                ...this.configSuccess,
              });
              return;
            }
            $(`#KYCOTPModel`).modal("hide");
          });
      });
  }

  onCloseKYCOTP() {}
}
