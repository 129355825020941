<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form #f="ngForm" (ngSubmit)="SaveUpdateTemplate()" novalidate>
    <div class="row m-0 mb-3 formborder">
      <div class="col-md-3">
        <span class="required-lable">Type</span>
        <select
          name="Type"
          id="Type"
          class="form-control input-text-css"
          [(ngModel)]="Type"
          #refType="ngModel"
          required
          (change)="setProductIdZero($event.target.value)"
          [ngClass]="{
            'is-invalid': f.submitted && refType.invalid,
            'alert-warning':
              refType.invalid &&
              (refType.dirty || refType.touched || refType.untouched)
          }"
        >
          <option value="">Select Type</option>
          <option value="Legal">Legal</option>
          <option value="DOLetter">D.O Letter</option>
          <option value="AgreementLetter">Agreement Letter</option>
          <option value="ListOfDocument">List of Documents</option>
          <option value="sanctionletter">Sanction Letter</option>
          <option value="welcomeletter">Welcome Letter</option>
          <option value="nocletter">NOC Letter</option>
        </select>
      </div>
      <div class="col-md-3">
        <span class="required-lable">Create/Modify</span>
        <select
          name="AddType"
          id="AddType"
          class="form-control input-text-css"
          [(ngModel)]="AddType"
          (change)="OnChangeType()"
          #refAddType="ngModel"
          required
          [ngClass]="{
            'is-invalid': f.submitted && refAddType.invalid,
            'alert-warning':
              refAddType.invalid &&
              (refAddType.dirty || refAddType.touched || refAddType.untouched)
          }"
        >
          <option value="">Select Type</option>
          <option value="Create">Create</option>
          <option value="Modify">Modify</option>
        </select>
      </div>
      <div class="col-md-3" *ngIf="Type != 'Legal' && Type != ''">
        <span class="required-lable">Select Product</span>
        <select
          name="ProductId"
          id="ProductId"
          class="form-control input-text-css"
          #refProductId="ngModel"
          required
          [ngClass]="{
            'is-invalid': f.submitted && refProductId.invalid,
            'alert-warning':
              refProductId.invalid &&
              (refProductId.dirty ||
                refProductId.touched ||
                refProductId.untouched)
          }"
          (change)="GetTemplateType()"
          [(ngModel)]="ProductId"
        >
          <option value="">Select Products</option>
          <option
            *ngFor="let product of productDropdown"
            [value]="product.ProductId"
          >
            {{ product.Product_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-3" *ngIf="AddType == 'Modify'">
        <span class="required-lable">Template</span>
        <select
          name="TemplateId"
          id="TemplateId"
          class="form-control input-text-css"
          [(ngModel)]="TemplateId"
          required
          #refTemplateId="ngModel"
          (change)="geLegalLetter()"
          [ngClass]="{
            'is-invalid': f.submitted && refTemplateId.invalid,
            'alert-warning':
              refTemplateId.invalid &&
              (refTemplateId.dirty ||
                refTemplateId.touched ||
                refTemplateId.untouched)
          }"
        >
          <option value="0">Select Template</option>
          <option *ngFor="let row of TemplateDropdown" [value]="row.Id">
            {{
              row.Template_Name.split("$")[1] != undefined
                ? row.Template_Name.split("$")[1]
                : row.Template_Name
            }}
          </option>
        </select>
      </div>

      <div class="col-md-3" *ngIf="AddType == 'Create'">
        <span class="required-lable">Template New Name</span>
        <input
          type="text"
          name="TemplateName"
          id="TemplateName"
          #refTemplateName="ngModel"
          (change)="geLegalLetter()"
          [(ngModel)]="TemplateName"
          placeholder="Template New Name"
          class="form-control input-text-css"
          required
          [ngClass]="{
            'is-invalid': f.submitted && refTemplateName.invalid,
            'alert-warning':
              refTemplateName.invalid &&
              (refTemplateName.dirty ||
                refTemplateName.touched ||
                refTemplateName.untouched)
          }"
        />
      </div>
    </div>

    <ng-container *ngIf="AddType || TemplateName != ''">
      <div class="row m-0 formborder" style="padding: 30px 0 !important">
        <div class="col-md-9 p-0">
          <div class="container">
            <form [formGroup]="form">
              <angular-editor
                autofocus="true"
                id="editor2"
                formControlName="signature"
                [config]="config2"
                [(ngModel)]="templateData"
              ></angular-editor>
            </form>
          </div>
        </div>
        <div class="col-md-3 submenu" *ngIf="JsonData && JsonData.length > 0">
          <input
            type="text"
            class="form-control input-text-css mb-3"
            [(ngModel)]="searchText"
            id="searchText"
            name="searchText"
            style="position: sticky; top: 3px; width: 100%"
            placeholder="Enter Search Text"
          />
          <a
            class="lead fs-14 mb-2 p_data"
            href="javascript:void(0)"
            *ngFor="let x of JsonData | search : 'SideMenuName' : searchText"
            ><p>{{ x.SideMenuName }}</p></a
          >
        </div>
      </div>
      <div class="text-right mb-2">
        <button
          type="button"
          (click)="SaveUpdateTemplate()"
          class="btn font-size-12 button-btn"
          [class.spinner]="loading"
          [disabled]="!f.form.valid"
        >
          Save
        </button>
      </div>
    </ng-container>
  </form>
</div>
