import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
import { QuickLoanService } from "../services/quickLoan.service";
import { MasterService } from "../../Shared/app.Masters.Service";
declare var $: any;
import { ActivatedRoute, Params } from "@angular/router";
import * as moment from "moment";
import { LosService } from "src/app/_LOS/services/los.service";

@Component({
  selector: "app-quickloan-master-report",
  templateUrl: "./quickloan-master-report.component.html",
  styleUrls: ["./quickloan-master-report.component.scss"],
})
export class QuickLoanMasterReportComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  DataSource: any;
  DataSourceDetail: any;
  displayedColumnsDetails: string[];
  ProcessType: any;

  //Filter Parameter
  FromDate: any = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  ToDate: any = new Date();

  BranchesList: any[] = [];
  BranchId: any = 0;
  GetEmployeeDropdown: any = [];
  ExecutiveId: any = 0;
  selectedId: any;

  SelectSourceType: any = "";
  partnerDropdown: any;
  SalesExecutiveData: any = [];
  LeadSourceName: any;
  leadSourceDropdown: any;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("paginatorlist", { read: MatPaginator })
  paginatorlist: MatPaginator;

  displayedColumns: string[] = [
    "ProcessId",
    "Process_Name",
    "Process_Opening",
    "Process_Add",
    "Process_Reject",
    "Process_Completed",
    "Process_Pending",
  ];

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private lmsService: LmsService,
    private QuickLoanService: QuickLoanService,
    private _MasterService: MasterService,
    private losService: LosService
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Master Report Summary");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getBranchesList();
    this.GetEmployeeList();

    this.Get_PartnerForDropdown();
    this.getLeadSourceDropdown();
    this.GetSalesExecutive();
  }

  getLeadSourceDropdown() {
    this.showSpinner = true;
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
      console.log("Lead Source Dropdown", this.leadSourceDropdown);
      this.showSpinner = false;
    });
  }

  Get_PartnerForDropdown() {
    console.log("Partner Dropdown API Function");

    if (
      this.SelectSourceType == "Agent" ||
      this.SelectSourceType == "DSA" ||
      this.SelectSourceType == "Dealer"
    ) {
      this.losService
        .Get_PartnerForDropdown({
          PartnerType: this.SelectSourceType,
          branchId: 0,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = res;
        });
      this.LeadSourceName = 0;
    } else if (
      this.SelectSourceType == "Sales Executive" ||
      this.SelectSourceType == "Telecaller Executive" ||
      this.SelectSourceType == "Marketing Executive"
    ) {
      this.partnerDropdown = [];
      this.SalesExecutiveData.forEach((obj: any) => {
        this.partnerDropdown.push({
          PartnerId: obj.EmpId,
          Partner_Name: obj.EmpName,
        });
      });
      this.LeadSourceName = 0;
    } else {
      this.LeadSourceName = "";
    }
  }

  GetSalesExecutive() {
    this.losService
      .getEmployeeDropdown({
        BranchId: 0,
        ProcessId: 1,
        ProductId: 0,
      })
      .subscribe((res: any) => {
        // this.employeeDropdown = res;
        this.SalesExecutiveData = res;
        this.Get_PartnerForDropdown();
        //console.log('res', res);
      });
  }

  getBranchesList() {
    this.lmsService
      .GetBranches({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
      });
  }

  GetEmployeeList() {
    this._MasterService
      .GetEmployeeDropdown({ BranchId: this.BranchId, ProcessId: 0 })
      .subscribe((result) => {
        this.GetEmployeeDropdown = JSON.parse(JSON.stringify(result));
        //this.ExecutiveId = this.GetEmployeeDropdown.length > 1 ? this.GetEmployeeDropdown[0].EmpId : '';
      });
  }

  OnSearchMaterReportList() {
    this.showSpinner = true;
    this.QuickLoanService.QuickLoan_Master_Report_Summary({
      From_Date: this.FromDate,
      To_Date: this.ToDate,
      BranchId: this.BranchId,
      Executive: this.ExecutiveId,
      LoginUserId: this.currentUser.userId,
      SourceType: this.SelectSourceType || "",
      SourceId: this.LeadSourceName || 0,
    }).subscribe((res: any) => {
      console.log("Result123 : ", res);
      //this.customerRefModel = res;

      this.DataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res)));
      //this.DataSource.paginator = this.paginator;
      //this.DataSource.sort = this.sort;
      this.showSpinner = false;
    });
  }

  onClickMasterReportDetail(row, type) {
    this.ProcessType = type;
    this.showSpinner = true;
    this.selectedId = "";
    this.displayedColumnsDetails = [
      "ApplicationId",
      "Application_No",
      "Customer",
      "Branch",
      "Product",
      "LoanAmount",
      "CreateOn",
      "Sales_Executive",
      "SourceType",
      "SourceName",
      "TAT_Hr",
      "Sales_Status",
      "ComplotedOn",
      "Score",
      "Remark",
      "LatLong",
    ];

    this.DataSourceDetail = "";
    this.QuickLoanService.QuickLoan_Master_Report_Detail({
      From_Date: this.FromDate,
      To_Date: this.ToDate,
      BranchId: this.BranchId,
      Executive: this.ExecutiveId,
      LoginUserId: this.currentUser.userId,
      SearchValue: type,
      ProcessId: row.ProcessId,
      SourceType: this.SelectSourceType || "",
      SourceId: this.LeadSourceName || 0,
    }).subscribe((res: any) => {
      console.log(res);
      if (res.length > 0) {
        $("#ViewMasterReportDetail").modal("show");
        $("#ViewMasterReportDetail").css("z-index", "1050");
        this.DataSourceDetail = new MatTableDataSource(
          JSON.parse(JSON.stringify(res))
        );
        this.DataSourceDetail = this.DataSourceDetail.filteredData.filter(
          (item) => item.NPA_Type == row.NPA_Type
        );

        //this.DataSourceDetail.paginator = this.paginatorlist;
        this.DataSourceDetail.sort = this.sort;
        this.showSpinner = false;
      }
    });
  }

  onCloseMasterReportDetail() {
    $("#ViewMasterReportDetail").modal("hide");
  }
  public highlightRow(row) {
    this.selectedId = row.ApplicationId;
  }
  totaltypewise(type) {
    if (this.DataSource != null) {
      let sum = 0;
      for (let i = 0; i < this.DataSource.filteredData.length; i++) {
        sum += Number(this.DataSource.filteredData[i][type]);
      }
      return sum > 0 ? sum : "";
    }
    return 0;
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `Master-Report`,
      sheet: "Master-Report",
    });
  }

  exportTable1(exporter: any, ProcessType) {
    exporter.exportTable("xls", {
      fileName: `Process Report Detail ` + ProcessType,
      sheet: "Process Report Detail " + ProcessType,
    });
  }
}
