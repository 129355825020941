import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  Injectable,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { LmsService } from "../services/lms.service";
import { Router } from "@angular/router";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MasterService } from "../../Shared/app.Masters.Service";
import { DialogService } from "../../Shared/dialog.service";
import * as XLSX from "xlsx";
import { Workbook } from "exceljs";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import * as fs from "file-saver";
declare var $: any;

// Excel export code start here
import * as FileSaver from "file-saver";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Injectable()
// Excel export code end here

@Component({
  selector: "app-lms-nach-process",
  templateUrl: "./lms-nach-process.component.html",
  styleUrls: ["./lms-nach-process.component.scss"],
})
export class LmsNachProcessComponent implements OnInit {
  currentUser: any;
  selectedId: any;
  ProcessList: any[] = [];
  PostBulkProcess: any[] = [];
  ImportBulkProcess: any[] = [];
  IsAlreadySelected: boolean = false;
  showSpinner: boolean = false;
  IsSelectAll: boolean = false;
  IsImportShow: boolean = false;
  IsExportShow: boolean = true;
  dataSource: any;
  dataSourceLoan: any;
  dataSourceBulkPost: any;
  displayedColumnsLoan: string[] = [];
  displayedColumnsBulkPost: string[] = [];
  displayedColumnsBulkClearing: string[] = [];
  displayedColumnsBulkPostImport: string[] = [];
  displayedColumns: string[] = [];
  CurrentDate: any = new Date();
  PostDate: any;
  dialogreturn: any;
  loading: boolean = false;
  IsExportbtnShow: boolean = false;
  IsImportbtnShow: boolean = false;
  IsSavebtnShow: boolean = false;
  Id: any = 0;
  HoldReason: any;
  HoldDate: any;
  applicationDetail: any;
  BounceCharge: any;
  BounceChargeID: any;
  BounceDate: any;
  BounceReason: any;
  Type: any = "";
  AMOR_Type: any;
  ClearDate: any;
  BankList: any;
  filterValue: any;
  ProductList: any = [];
  BranchesList: any = [];
  private _MasterService;
  PresentBankInCompany: any;
  BankInCompany: any = "";
  SelectBranch: any = "";
  SelectProduct: any = "";
  CheckType: any = "NACH";
  SearchStatus: any = "";
  minRepresentPostDate: any;
  Bind_Voucher: any = 1;
  RepresentPostDate: any;
  DueDateFrom: any = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  DueDateTo: any = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  );

  PostDateFrom: any;
  PostDateTo: any;

  BounceDateFrom: any;
  BounceDateTo: any;

  ClearDateFrom: any;
  ClearDateTo: any;

  HoldDateFrom: any;
  HoldDateTo: any;

  CustomerBank: any;
  bulkCheckType: any = "NACH";
  bulkDueDateFrom: any = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  bulkDueDateTo: any = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  );
  countCheck: any = 0;
  BulkPostDate: any = new Date();
  bulkPresentBankInCompany: any;
  RepresentId: any;
  bulkPostDateFrom: any = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  bulkPostDateTo: any = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  );
  arrayBuffer: any;
  file: any;
  filelist: any;
  EmiAmount: any;
  voucherModel: any;
  Matchhdata: any;
  MisMatchdata: any;
  Row: any;
  //filterValues = {};
  filterValues: any = {
    CaseNo: "",
    EMI_Amount: "",
  };
  Search: any;
  LoanChargesRecoverableId = 35;
  BouncingChargesId = 31;
  ChequeinTransitId = 32;
  displayedColumnsBulkImport: any[] = [];
  displayedColumnsNotMatchImport: any[] = [];
  dataSourceBulkImport: any;
  dataSourceBulkImport_NotMatch: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  caseNo: any;

  @Input() loanSummary: any;
  LoanId: any;
  JsonData: any[] = [];
  Bounce_ReasonList: any;
  minDate: any;
  constructor(
    private datepipe: DatePipe,
    private dataSharingService: DataSharingService,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: DialogService,
    private losService: LosService,
    private lmsService: LmsService,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
  }

  ngOnInit() {
    //this.Search = "CaseNo";
    this.dataSharingService.HeaderTitle.next("Repayment Process");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.minDate = new Date(
      JSON.parse(sessionStorage.getItem("currentUser")).Data_Freeze.split(
        "T"
      )[0]
    );
    this.getBranchesList();
    this.getProductDropdown();
    this.getBankDropdown();
    this.lmsService
      .LMS_Commaon_Master({ Commands: "Select", Type: "Bounce_Reason" })
      .subscribe((res: any) => {
        console.log(res);
        this.Bounce_ReasonList = res;
      });

    this.displayedColumnsBulkClearing = [
      "Id",
      "IdC",
      "LoanAcNo",
      "Loan_Date",
      "CustomerName",
      "ChequeNo",
      "EMI_Amount",
      "DueDate",
      "CustomerBankname",
      "ComapnyAccount",
      "Type",
      "PostDate",
      "Clearing",
      "Date",
    ];
    this.displayedColumnsLoan = [
      "Id",
      "CaseNo",
      "CustomerName",
      "EMINo",
      "ChqueNo",
      "EMI_Amount",
      "DueDate",
      "CustomerBankname",
      "OD_Amount",
      "Last_Recv_Date",
      "Type",
      "Status",
      "PostDate",
      "ComapnyAccount",
      "Clearing",
      "ClearBounceDate",
      "BounceReason",
      "BounceCharge",
      "Undo",
      "HoldDate",
      "HoldReason",
      "NachCode",
      "Represent",
      //"Id", "CaseNo", "CustomerName", "EMINo", "CaseDate", "ChqueNo", "EMI_Amount", "DueDate", "CustomerBankname", "Type", "Status",
      //"PostDate", "ComapnyAccount", "Clearing", "ClearBounceDate", "BounceReason", "BounceCharge", "Undo", "HoldDate", "HoldReason"
    ];
    // this.NachProcess();

    if (this.loanSummary != undefined) {
      this.LoanId = this.loanSummary.LoanId;
      this.CheckType = "";
      this.displayedColumnsLoan = [
        "Id",
        "CaseNo",
        "CustomerName",
        "EMINo",
        "ChqueNo",
        "EMI_Amount",
        "DueDate",
        "CustomerBankname",
        "OD_Amount",
        "Last_Recv_Date",
        "Type",
        "Status",
        "PostDate",
        "ComapnyAccount",
        "Clearing",
        "ClearBounceDate",
        "BounceReason",
        "BounceCharge",
        "Undo",
        "HoldDate",
        "HoldReason",
        "NachCode",
      ];
      this.onSearchNachProcess();
    }
  }

  getProductDropdown() {
    this._MasterService
      .GetProductList({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.ProductList = JSON.parse(JSON.stringify(result));
      });
  }
  getBranchesList() {
    let user = JSON.parse(sessionStorage.getItem("currentUser"));
    this.lmsService.GetBranches({ Emp_Id: user.userId }).subscribe((result) => {
      this.BranchesList = JSON.parse(JSON.stringify(result));
    });
  }
  //NachProcess() {
  //  //this.showSpinner = true;

  //  this.lmsService
  //    .GET_LMS_NACH_PROCESS()
  //    .subscribe((res: any) => {
  //      console.log(res);

  //      this.ProcessList = JSON.parse(JSON.stringify(res));
  //      this.ProcessList.forEach(x => {
  //        console.log(x.PostDate);
  //        x.PostDate = x.PostDate == null ? '' : new Date(moment(x.PostDate).format('MM/DD/YYYY'));
  //        x.ClearingType = '';
  //        x.Clearinghold = false;
  //        x.Posthold = false;
  //        if (x.IsStop == 1) {
  //          x.Posthold = true;
  //          x.Clearinghold = true;
  //        }
  //        if (x.PostDate != null && x.PostDate !='' ) {
  //          x.Posthold = true;
  //        }
  //        if (x.BounceCharge != '' && x.BounceCharge != null) {
  //          x.BCharge = x.Charge_Values;
  //          x.ClearingType = 'Bounce';
  //        } else {
  //          x.BCharge = '';
  //        }
  //        if (x.ClearDate != '' && x.ClearDate != null) {
  //          x.ClearingType = 'Clear';
  //        }
  //      });
  //      console.log('dataSourceLoan', res)
  //      this.dataSourceLoan = new MatTableDataSource(this.ProcessList);

  //      this.dataSourceLoan.sort = this.sort;
  //      this.dataSourceLoan.paginator = this.paginator;
  //      this.showSpinner = false;
  //    });

  //}
  getBankDropdown() {
    var User = JSON.parse(sessionStorage.getItem("currentUser"));
    this.lmsService
      .GET_ACCOUNT_MASTER_BY_TAG({ TagName: "Bank", LoginUserId: User.userId })
      .subscribe((res: any) => {
        this.BankList = JSON.parse(JSON.stringify(res));
      });
  }
  onHold(row) {
    this.Id = row.Id;
    $("#HoldDetailModel").modal("show");
    $("#HoldDetailModel").css("z-index", "1050");
  }
  onCloseHoldDetail() {
    this.onSearchNachProcess();
    this.Id = 0;
    this.HoldDate = "";
    this.HoldReason = "";
    $("#HoldDetailModel").modal("hide");
  }
  onHoldOk() {
    this.showSpinner = true;
    let _data: any = {};
    _data.Hold = { HoldReason: this.HoldReason, HoldDate: this.HoldDate };
    this.lmsService
      .SAVE_NACHPROCESS({
        Int_Id: this.Id,
        StatusVal: "Hold",
        JSON: JSON.stringify(_data),
      })
      .subscribe((res) => {
        if (res[0].CODE == 0) {
          this.showSpinner = false;
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });

          this.onCloseHoldDetail();
        } else {
          this.showSpinner = false;
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  onUnHold(row) {
    this.lmsService
      .SAVE_NACHPROCESS({ Int_Id: row.Id, StatusVal: "UnHold" })
      .subscribe((res) => {
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.onCloseHoldDetail();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  ClearingChange(event: MatSelectChange, row) {
    this.Id = row.Id;
    this.Type = event.value;
    this.PostDate = row.PostDate;
    this.Row = this.ProcessList.filter((x) => x.Id == row.Id)[0];
    this.PresentBankInCompany = this.Row.ComapnyAccountId;
    if (event.value == "Bounce") {
      if (row.ChargeId != null && row.ChargeId != "") {
        this.BounceChargeID = row.ChargeId;
        this.BounceCharge = row.Charge_Values;
      } else {
        this.BounceChargeID = 0;
        this.BounceCharge = 0;
      }
      $("#BounceDetailModel").modal("show");
      $("#BounceDetailModel").css("z-index", "1050");
    }
    if (event.value == "Clear") {
      $("#BounceDetailModel").modal("show");
      $("#BounceDetailModel").css("z-index", "1050");
    }
  }
  onBounce() {
    this.lmsService
      .GetVoucherNo({
        VoucherType: "NachProcess",
        VoucherSubType: "Loan",
        VoucherDate: this.datepipe.transform(
          new Date(this.Row.FPostDate).toString(),
          "yyyy-MM-dd"
        ),
      })
      .subscribe((result: any) => {
        this.lmsService
          .LMS_Get_ACC_By_Loan_ChargesHead({
            HeadId: this.BouncingChargesId,
            LoanAcNo: this.Row.LoanAcNo,
          })
          .subscribe((rres: any) => {
            let _data: any = {};
            console.log("RRes : ", rres);
            _data.Bounce = {
              BounceDate: this.BounceDate,
              BounceCharge: this.BounceCharge,
              BounceReason: this.BounceReason,
            };

            var id = this.Id;

            this.lmsService
              .Get_AccountHead_For_VoucherEntry({
                HeadId: this.BouncingChargesId,
                VoucherSubType: "Loan",
                ProductId: this.Row.PrductId || 0,
                TaxType: "CR",
              })
              .subscribe((res: any) => {
                console.log("Res : ", res);
                let TaxSlab = [];
                TaxSlab = res.Item2;
                this.saveBounceVoucher(
                  result[0].VoucherNo,
                  rres,
                  TaxSlab,
                  _data,
                  id
                );
              });
          });
      });
  }
  onClear() {
    this.lmsService
      .GetVoucherNo({
        VoucherType: "NachProcess",
        VoucherSubType: "Loan",
        VoucherDate: this.datepipe.transform(
          new Date(this.PostDate).toString(),
          "yyyy-MM-dd"
        ),
      })
      .subscribe((result: any) => {
        this.lmsService
          .LMS_Get_ACC_By_Loan_ChargesHead({
            HeadId: 34,
            LoanAcNo: this.Row.LoanAcNo,
          })
          .subscribe((rres: any) => {
            this.saveClearVoucher(
              result[0].VoucherNo,
              rres[0].Account_Name,
              rres[0].AccountId
            );
          });
      });
  }
  onCloseBounceDetail() {
    this.onSearchNachProcess();
    var element1 = <HTMLInputElement>document.getElementById("type" + this.Id);
    element1.value = "";
    this.Id = "";
    this.BounceChargeID = "";
    this.BounceDate = "";
    this.BounceReason = "";
    this.Type = "";
    this.ClearDate = "";

    $("#BounceDetailModel").modal("hide");
  }
  PostDateChange(row: any) {
    this.minDate = new Date(
      this.currentUser.Data_Freeze.split("T")[0] + " 00:00:00"
    );
    if (new Date(row.PostDate) < this.minDate) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Data Freeze On Date ",
        ...this.configSuccess,
      });
      row.PostDate = null;
    } else {
      this.PostDate = row.PostDate;
      this.Id = row.Id;
      this.AMOR_Type = row.Type;
      this.Row = this.ProcessList.filter((x) => x.Id == row.Id)[0];
      this.getBankDropdown();
      //console.log("this.Row", this.Row);
      $("#PostDetailModel").modal("show");
      $("#PostDetailModel").css("z-index", "1050");
    }
  }
  onPost() {
    this.lmsService
      .GetVoucherNo({
        VoucherType: "NachProcess",
        VoucherSubType: "Loan",
        VoucherDate: this.datepipe.transform(
          new Date(this.PostDate).toString(),
          "yyyy-MM-dd"
        ),
      })
      .subscribe((result: any) => {
        this.savePostVoucher(result[0].VoucherNo, this.PostDate, 0, this.Id);
      });
  }
  savePostVoucher(VoucherNumber, PostDate, Represent, Id) {
    var ComapnyAccountId = this.Row.ComapnyAccountId;
    var ComapnyAccount = this.Row.ComapnyAccount;
    if (this.AMOR_Type == "RPDC") {
      ComapnyAccountId = this.PresentBankInCompany;
      ComapnyAccount = this.BankList.find((obj: any) => {
        return obj.AccountId == this.PresentBankInCompany;
      }).Account_Name;
    }
    let _vouvher: any = {};
    let VoucherNo = VoucherNumber;
    //console.log("voucherno", VoucherNo);
    _vouvher = {
      VoucherId: 0,
      Voucher_No: VoucherNo,
      Voucher_ReceiptNo_Book: null,
      Voucher_ReceiptDate: null,
      Voucher_Type: "Journal",
      Voucher_Sub_Type: "Loan",
      Voucher_Tag: "Bank",
      Voucher_Date: PostDate,
      Voucher_Effective_Date: null,
      Voucher_Mode_of_Payment: "",
      Voucher_Total_Credit_Amount: this.Row.EMI_Amount,
      Voucher_Total_Debit_Amount: this.Row.EMI_Amount,
      Voucher_Total_Tax: null,
      Voucher_Narration:
        "Loan No. " +
        this.Row.LoanAcNo +
        "  / Application No. " +
        this.Row.Application_No +
        " Instrument No. " +
        this.Row.ChequeNo,
      Voucher_Cheque_No: this.Row.ChequeNo,
      Voucher_Cheque_Date: this.datepipe.transform(
        new Date(this.Row.DueDate).toString(),
        "yyyy-MM-dd"
      ),
      Voucher_Bank_Name: this.Row.CustomerBankname,
      Voucher_Cheque_Clear_Date: null,
      Voucher_E_Transaction_Date: null,
      Voucher_E_Ref_No: this.Row.Id,
      LoginUserId: this.currentUser.userId,
    };

    let _voucheretail: any[] = [];
    _voucheretail.push({
      VoucherId: 0,
      CaseNo: this.Row.LoanAcNo,
      CustomerId: this.Row.CustomerId,
      Is_LOSAppliaction: 0,
      LOS_TransactionId: null,
      ChargeHeadId: this.ChequeinTransitId,
      ChargeHeadCategory: this.Row.AccountName,
      AccountId: this.Row.AccountId,
      BranchId: this.Row.BranchId,
      TranType: "CR",
      IsTax: null,
      Tax_Per: null,
      Amount: this.Row.EMI_Amount,
    });
    _voucheretail.push({
      VoucherId: 0,
      CaseNo: null,
      CustomerId: null,
      Is_LOSAppliaction: null,
      LOS_TransactionId: null,
      ChargeHeadId: null,
      ChargeHeadCategory: ComapnyAccount,
      AccountId: ComapnyAccountId,
      BranchId: null,
      TranType: "DR",
      IsTax: null,
      Tax_Per: null,
      Amount: this.Row.EMI_Amount,
    });
    // //let _find = this.accountTagDropdown.find((obj: any) => { return obj.AccountId == this.voucherModel.Voucher.DRAccountId; });
    //// let _total: any = 0;
    //// let _totalCR: any = 0;
    //// let _totalTX: any = 0;
    // this.voucherModel.Voucher_Detail.forEach((obj: any) => {
    //   _total = _total + obj.Amount;
    //   _totalCR = _totalCR + obj.Amount;
    //   _voucheretail.push(this.dataSharingService.cleanObject({
    //     VoucherId: obj.VoucherId,
    //     CaseNo: obj.CaseNo,
    //     CustomerId: obj.CustomerId,
    //     Is_LOSAppliaction: obj.Is_LOSAppliaction,
    //     ChargeHeadId: obj.ChargeHeadId,
    //     ChargeHeadCategory: obj.ChargeHeadCategory,
    //     AccountId: obj.AccountId,
    //     BranchId: obj.BranchId,
    //     TranType: obj.TranType,
    //     IsTax: obj.IsTax,
    //     Tax_Per: obj.Tax_Per,
    //     Amount: obj.Amount
    //   }));
    //   obj.TaxSlab.forEach((tobj: any) => {
    //     _total = _total + tobj.TaxAmount;
    //     _totalTX = _totalTX + tobj.TaxAmount;
    //     _voucheretail.push(this.dataSharingService.cleanObject({
    //       VoucherId: obj.VoucherId,
    //       CaseNo: obj.CaseNo,
    //       CustomerId: "",
    //       Is_LOSAppliaction: obj.Is_LOSAppliaction,
    //       ChargeHeadId: obj.ChargeHeadId,
    //       ChargeHeadCategory: tobj.TaxType,
    //       AccountId: tobj.TaxAccountId,
    //       BranchId: "",
    //       TranType: obj.TranType,
    //       IsTax: 1,
    //       Tax_Per: tobj.TaxRate,
    //       Amount: tobj.TaxAmount
    //     }))
    //   });
    // });

    // _voucheretail.push(this.dataSharingService.cleanObject({
    //   VoucherId: 0,
    //   CaseNo: "",
    //   CustomerId: "",
    //   Is_LOSAppliaction: "",
    //   ChargeHeadId: "",
    //   ChargeHeadCategory: _find.Account_Name,
    //   AccountId: _find.AccountId,
    //   BranchId: "",
    //   TranType: 'DR',
    //   IsTax: 0,
    //   Tax_Per: 0,
    //   Amount: _total
    // }));
    // _vouvher.Voucher_Total_Credit_Amount = _totalCR;
    // _vouvher.Voucher_Total_Debit_Amount = _total;
    // _vouvher.Voucher_Total_Tax = _totalTX;

    let _finalData = {
      Voucher: _vouvher,
      Voucher_Detail: _voucheretail,
    };

    let _data: any = {};
    if (this.Row.Type == "RPDC")
      _data.Post = {
        PostDate: PostDate,
        RPDC_ComapnyBank: this.PresentBankInCompany,
        Represent: Represent,
      };
    else
      _data.Post = {
        PostDate: PostDate,
        RPDC_ComapnyBank: "",
        Represent: Represent,
      };

    //this.lmsService.SAVE_NACHPROCESS({ Int_Id: this.Id, StatusVal: 'Post', Repayment_JSON: JSON.stringify(_data) }).subscribe((result) => {
    //  if (result[0].CODE == 0) {

    //    this.snackBar.openFromComponent(SnackbarComponent, { data: result[0].MSG, ...this.configSuccess });

    //  } else {
    //    this.snackBar.openFromComponent(SnackbarComponent, { data: result[0].MSG, ...this.configSuccess });
    //  }
    //});
    this.lmsService
      .Save_Acc_VoucherMaster_By_NachProcess({
        JSON: JSON.stringify(_finalData),
        Int_Id: Id,
        StatusVal: "Post",
        Repayment_JSON: JSON.stringify(_data),
      })
      .subscribe((res: any) => {
        if (res[0].CODE > 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
        this.onClosePostVoucher();
        this.onCloseRepresent();
      });
  }
  saveClearVoucher(VoucherNumber, AccountName, AccountId) {
    let _vouvher: any = {};
    let VoucherNo = VoucherNumber;
    //console.log("voucherno", VoucherNo);
    _vouvher = {
      VoucherId: 0,
      Voucher_No: VoucherNo,
      Voucher_ReceiptNo_Book: null,
      Voucher_ReceiptDate: null,
      Voucher_Type: "Journal",
      Voucher_Sub_Type: "Loan",
      Voucher_Tag: null,
      Voucher_Date: this.datepipe.transform(
        new Date(this.Row.FPostDate).toString(),
        "yyyy-MM-dd"
      ),

      Voucher_Effective_Date: null,
      Voucher_Mode_of_Payment: null,
      Voucher_Total_Credit_Amount: this.Row.EMI_Amount,
      Voucher_Total_Debit_Amount: this.Row.EMI_Amount,
      Voucher_Total_Tax: null,
      Voucher_Narration: "Instrument Successfuly pass",
      Voucher_Cheque_No: null,
      Voucher_Cheque_Date: null,
      Voucher_Bank_Name: null,
      Voucher_Cheque_Clear_Date: this.datepipe.transform(
        new Date(this.Row.FPostDate).toString(),
        "yyyy-MM-dd"
      ),
      Voucher_E_Transaction_Date: null,
      Voucher_E_Ref_No: null,
      LoginUserId: this.currentUser.userId,
    };
    //this.Row.Id
    let _voucheretail: any[] = [];
    _voucheretail.push({
      VoucherId: 0,
      CaseNo: this.Row.LoanAcNo,
      CustomerId: this.Row.CustomerId,
      Is_LOSAppliaction: 0,
      LOS_TransactionId: null,
      ChargeHeadId: this.ChequeinTransitId,
      ChargeHeadCategory: this.Row.AccountName,
      AccountId: this.Row.AccountId,
      BranchId: this.Row.BranchId,
      TranType: "DR",
      IsTax: null,
      Tax_Per: null,
      Amount: this.Row.EMI_Amount,
    });
    _voucheretail.push({
      VoucherId: 0,
      CaseNo: this.Row.LoanAcNo,
      CustomerId: this.Row.CustomerId,
      Is_LOSAppliaction: 0,
      LOS_TransactionId: null,
      ChargeHeadId: 34,
      ChargeHeadCategory: AccountName,
      AccountId: AccountId,
      BranchId: this.Row.BranchId,
      TranType: "CR",
      IsTax: null,
      Tax_Per: null,
      Amount: this.Row.EMI_Amount,
    });
    // //let _find = this.accountTagDropdown.find((obj: any) => { return obj.AccountId == this.voucherModel.Voucher.DRAccountId; });
    //// let _total: any = 0;
    //// let _totalCR: any = 0;
    //// let _totalTX: any = 0;
    // this.voucherModel.Voucher_Detail.forEach((obj: any) => {
    //   _total = _total + obj.Amount;
    //   _totalCR = _totalCR + obj.Amount;
    //   _voucheretail.push(this.dataSharingService.cleanObject({
    //     VoucherId: obj.VoucherId,
    //     CaseNo: obj.CaseNo,
    //     CustomerId: obj.CustomerId,
    //     Is_LOSAppliaction: obj.Is_LOSAppliaction,
    //     ChargeHeadId: obj.ChargeHeadId,
    //     ChargeHeadCategory: obj.ChargeHeadCategory,
    //     AccountId: obj.AccountId,
    //     BranchId: obj.BranchId,
    //     TranType: obj.TranType,
    //     IsTax: obj.IsTax,
    //     Tax_Per: obj.Tax_Per,
    //     Amount: obj.Amount
    //   }));
    //   obj.TaxSlab.forEach((tobj: any) => {
    //     _total = _total + tobj.TaxAmount;
    //     _totalTX = _totalTX + tobj.TaxAmount;
    //     _voucheretail.push(this.dataSharingService.cleanObject({
    //       VoucherId: obj.VoucherId,
    //       CaseNo: obj.CaseNo,
    //       CustomerId: "",
    //       Is_LOSAppliaction: obj.Is_LOSAppliaction,
    //       ChargeHeadId: obj.ChargeHeadId,
    //       ChargeHeadCategory: tobj.TaxType,
    //       AccountId: tobj.TaxAccountId,
    //       BranchId: "",
    //       TranType: obj.TranType,
    //       IsTax: 1,
    //       Tax_Per: tobj.TaxRate,
    //       Amount: tobj.TaxAmount
    //     }))
    //   });
    // });

    // _voucheretail.push(this.dataSharingService.cleanObject({
    //   VoucherId: 0,
    //   CaseNo: "",
    //   CustomerId: "",
    //   Is_LOSAppliaction: "",
    //   ChargeHeadId: "",
    //   ChargeHeadCategory: _find.Account_Name,
    //   AccountId: _find.AccountId,
    //   BranchId: "",
    //   TranType: 'DR',
    //   IsTax: 0,
    //   Tax_Per: 0,
    //   Amount: _total
    // }));
    // _vouvher.Voucher_Total_Credit_Amount = _totalCR;
    // _vouvher.Voucher_Total_Debit_Amount = _total;
    // _vouvher.Voucher_Total_Tax = _totalTX;

    let _finalData = {
      Voucher: _vouvher,
      Voucher_Detail: _voucheretail,
    };
    let _data: any = {};
    _data.Clear = { ClearDate: this.ClearDate };
    this.lmsService
      .Save_Acc_VoucherMaster_By_NachProcess({
        JSON: JSON.stringify(_finalData),
        Int_Id: this.Id,
        StatusVal: "Clear",
        Repayment_JSON: JSON.stringify(_data),
      })
      .subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.onCloseBounceDetail();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
        this.onCloseBounceDetail();
      });
    //this.lmsService.Save_Acc_VoucherMaster_By_NachProcess({ JSON: JSON.stringify(_finalData) }).subscribe((res: any) => {
    //  if (res[0].CODE >= 0) {
    //    this.snackBar.openFromComponent(SnackbarComponent, { data: 'Voucher No: ' + VoucherNumber + ' Successfully Saved !!!', ...this.configSuccess });
    //    let _data: any = {};
    //    _data.Clear = { ClearDate: this.ClearDate, VoucherId: res[0].CODE };
    //    this.lmsService.SAVE_NACHPROCESS({ Int_Id: this.Id, StatusVal: 'Clear', JSON: JSON.stringify(_data) }).subscribe((res) => {
    //      if (res[0].CODE == 0) {
    //        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });

    //      } else {
    //        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
    //      }
    //    });

    //    this.Update_Voucher_Clear_OR_Bounce();
    //  } else {
    //    this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
    //  }
    //})
  }
  saveBounceVoucher(VoucherNumber, bouncing, TaxSlab, data, Id) {
    this.lmsService
      .LMS_Get_ACC_By_Loan_ChargesHead({
        HeadId: this.LoanChargesRecoverableId,
        LoanAcNo: this.Row.LoanAcNo,
      })
      .subscribe((rres: any) => {
        var ComapnyAccountId = this.Row.ComapnyAccountId;
        var ComapnyAccount = this.Row.ComapnyAccount;
        if (this.Row.Type == "RPDC") {
          ComapnyAccountId = this.PresentBankInCompany;
          ComapnyAccount = this.BankList.find((obj: any) => {
            return obj.AccountId == this.PresentBankInCompany;
          }).Account_Name;
        }
        let _vouvher: any = {};
        let VoucherNo = VoucherNumber;
        //console.log("voucherno", VoucherNo);
        _vouvher = {
          VoucherId: 0,
          Voucher_No: VoucherNo,
          Voucher_ReceiptNo_Book: null,
          Voucher_ReceiptDate: null,
          Voucher_Type: "Journal",
          Voucher_Sub_Type: "Loan",
          Voucher_Tag: "Bank",
          Voucher_Date: this.datepipe.transform(
            new Date(this.Row.FPostDate).toString(),
            "yyyy-MM-dd"
          ),

          Voucher_Effective_Date: null,
          Voucher_Mode_of_Payment: "",
          Voucher_Total_Credit_Amount: this.Row.EMI_Amount,
          Voucher_Total_Debit_Amount: this.Row.EMI_Amount,
          Voucher_Total_Tax: null,
          Voucher_Narration: "Instrument Bounced due to " + this.BounceReason,
          Voucher_Cheque_No: this.Row.ChequeNo,
          Voucher_Cheque_Date: this.datepipe.transform(
            new Date(this.Row.DueDate).toString(),
            "yyyy-MM-dd"
          ),
          Voucher_Bank_Name: this.Row.CustomerBankname,
          Voucher_Cheque_Clear_Date: this.datepipe.transform(
            new Date(this.BounceDate).toString(),
            "yyyy-MM-dd"
          ),
          Voucher_E_Transaction_Date: null,
          Voucher_E_Ref_No: null,
          LoginUserId: this.currentUser.userId,
        };
        //this.Row.Id
        let _voucheretail: any[] = [];
        //Cheque in Transit
        _voucheretail.push({
          VoucherId: 0,
          CaseNo: this.Row.LoanAcNo,
          CustomerId: this.Row.CustomerId,
          Is_LOSAppliaction: 0,
          LOS_TransactionId: null,
          ChargeHeadId: this.ChequeinTransitId,
          ChargeHeadCategory: this.Row.AccountName,
          AccountId: this.Row.AccountId,
          BranchId: this.Row.BranchId,
          TranType: "DR",
          IsTax: null,
          Tax_Per: null,
          Amount: this.Row.EMI_Amount,
          Bind_Voucher: 1,
        });
        //Comapny Acc
        _voucheretail.push({
          VoucherId: 0,
          CaseNo: null,
          CustomerId: null,
          Is_LOSAppliaction: 0,
          LOS_TransactionId: null,
          ChargeHeadId: null,
          ChargeHeadCategory: ComapnyAccount,
          AccountId: ComapnyAccountId,
          BranchId: null,
          TranType: "CR",
          IsTax: null,
          Tax_Per: null,
          Amount: this.Row.EMI_Amount,
          Bind_Voucher: 2,
        });
        //Bouncing Charges
        _voucheretail.push({
          VoucherId: 0,
          CaseNo: this.Row.LoanAcNo,
          CustomerId: this.Row.CustomerId,
          Is_LOSAppliaction: 0,
          LOS_TransactionId: null,
          ChargeHeadId: this.BouncingChargesId,
          ChargeHeadCategory: bouncing[0].Account_Name,
          AccountId: bouncing[0].AccountId,
          BranchId: this.Row.BranchId,
          TranType: "CR",
          IsTax: null,
          Tax_Per: null,
          Amount: bouncing[0].Amount,
          Bind_Voucher: 3,
        });

        //tax

        let _total = Number(bouncing[0].Amount);
        TaxSlab.forEach((tobj: any) => {
          var TaxAmount = (parseFloat(bouncing[0].Amount) * tobj.TaxRate) / 100;
          _total = _total + Number(TaxAmount);
          // _totalTX = _totalTX + tobj.TaxAmount;
          _voucheretail.push(
            this.dataSharingService.cleanObject({
              VoucherId: 0,
              CaseNo: this.Row.LoanAcNo,
              CustomerId: "",
              Is_LOSAppliaction: 0,
              ChargeHeadId: this.BouncingChargesId,
              ChargeHeadCategory: tobj.TaxType,
              AccountId: tobj.TaxAccountId,
              BranchId: "",
              TranType: "CR",
              IsTax: 1,
              Tax_Per: tobj.TaxRate,
              Amount: TaxAmount,
              Bind_Voucher: 3,
            })
          );
        });

        _voucheretail.push({
          VoucherId: 0,
          CaseNo: this.Row.LoanAcNo,
          CustomerId: this.Row.CustomerId,
          Is_LOSAppliaction: 0,
          LOS_TransactionId: null,
          ChargeHeadId: this.LoanChargesRecoverableId,
          ChargeHeadCategory: rres[0].Account_Name,
          AccountId: rres[0].AccountId,
          BranchId: this.Row.BranchId,
          TranType: "DR",
          IsTax: null,
          Tax_Per: null,
          Amount: _total,
        });

        let _finalData = {
          Voucher: _vouvher,
          Voucher_Detail: _voucheretail,
        };
        //console.log("_finalData", _finalData);

        this.lmsService
          .Save_Acc_VoucherMaster_By_NachProcess({
            JSON: JSON.stringify(_finalData),
            Int_Id: Id,
            StatusVal: "Bounce",
            Repayment_JSON: JSON.stringify(data),
          })
          .subscribe((res: any) => {
            if (res[0].CODE >= 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: res[0].MSG,
                ...this.configSuccess,
              });
              this.onCloseBounceDetail();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: res[0].MSG,
                ...this.configSuccess,
              });
            }
            this.onCloseBounceDetail();
          });
      });
  }
  Update_Voucher_Clear_OR_Bounce() {
    this.lmsService
      .Update_Voucher_Clear_OR_Bounce({
        Id: this.Row.Id,
        ClearDate: this.ClearDate,
      })
      .subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.onCloseBounceDetail();
        }
      });
  }
  onClosePostVoucher() {
    this.onSearchNachProcess();
    var element1 = <HTMLInputElement>(
      document.getElementById("PostDate" + this.Id)
    );
    element1.value = "";
    this.PostDate = "";
    this.Row = {};
    this.Id = "";
    this.AMOR_Type = "";
    $("#PostDetailModel").modal("hide");
  }
  onSearchNachProcess() {
    this.dataSourceLoan = null;
    this.showSpinner = true;
    var bankCompany = this.BankInCompany;
    if (bankCompany != "") {
      bankCompany = this.BankInCompany.split("_")[0];
    }
    console.log("this.loanSummary:", this.loanSummary);
    if (this.loanSummary != undefined) {
      this.DueDateFrom = null;
      this.DueDateTo = null;
    }

    let searchData;
    searchData = {
      CheckType: this.CheckType,
      DueDateFrom: this.DueDateFrom,
      DueDateTo: this.DueDateTo,
      BankInCompany: bankCompany,
      Branch: this.SelectBranch,
      Product: this.SelectProduct,
      status: this.SearchStatus,
      CustomerBank: this.CustomerBank,
      LoanId: this.LoanId,
      PostDateFrom: this.PostDateFrom,
      PostDateTo: this.PostDateTo,
      ClearDateFrom: this.ClearDateFrom,
      ClearDateTo: this.ClearDateTo,
      HoldDateFrom: this.HoldDateFrom,
      HoldDateTo: this.HoldDateTo,
      BounceDateFrom: this.BounceDateFrom,
      BounceDateTo: this.BounceDateTo,
      LoanAcNo: this.caseNo,
    };
    console.log("searchData : ", searchData);
    this.lmsService
      .GET_LMS_NACH_PROCESSSearch({ JSON: JSON.stringify(searchData) })
      .subscribe((res: any) => {
        console.log("RESHH : ", res);

        for (var i = 0; i < res.length; i++) {
          if (res.length == 10) {
            break;
          }
        }
        if (res.length > 0) {
          this.ProcessList = JSON.parse(JSON.stringify(res));
          this.JsonData = res;
          this.ProcessList.forEach((x) => {
            //console.log(x.PostDate);
            x.FPostDate = x.PostDate;
            x.PostDate =
              x.PostDate == null || x.PostDate == ""
                ? ""
                : new Date(x.PostDate);
            x.Status = x.Status == null ? "" : x.Status;
            x.ClearingType = "";
            x.Clearinghold = false;
            x.Posthold = false;
            if (x.IsStop == 1) {
              x.Posthold = true;
              x.Clearinghold = true;
            }
            if (x.PostDate != null && x.PostDate != "") {
              x.Posthold = true;
            }
            if (
              x.PostDate == null ||
              x.PostDate == "" ||
              (x.ClearDate != null && x.ClearDate != "") ||
              (x.BounceDate != "" && x.BounceDate != null)
            ) {
              x.Clearinghold = true;
            }
            if (
              x.BounceCharge != "" &&
              x.BounceCharge != null &&
              x.BounceCharge != 0
            ) {
              x.BCharge = x.Charge_Values;
              x.ClearingType = "Bounce";
            } else {
              x.BCharge = "";
            }
            if (x.ClearDate != "" && x.ClearDate != null) {
              x.ClearingType = "Clear";
            }
          });
          //console.log("Res : ",this.ProcessList);
          //console.log('dataSourceLoan', res)

          this.dataSourceLoan = new MatTableDataSource(this.ProcessList);

          this.dataSourceLoan.sort = this.sort;
          //this.dataSourceLoan.paginator = this.paginator;
          this.showSpinner = false;
          // this.dataSourceLoan.filterPredicate = this.createFilter();
          // this.applyFilter();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }

  Undo(row: any) {
    this.dialogreturn = this.dialog.openConfirmDialog(
      "Do you want to last action will be reverted and related voucher will be deleted ? "
    );

    //this.dialogreturn = this.dialog.openConfirmDialog('Are you sure you want to delete this employee?');
    this.dialogreturn.afterClosed().subscribe((dialogResult) => {
      if (dialogResult == false) {
        return;
      } else {
        this.lmsService
          .SAVE_NACHPROCESS({
            Int_Id: row.Id,
            StatusVal: "Undo",
            JSON: JSON.stringify(""),
          })
          .subscribe((res) => {
            if (res[0].CODE == 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: res[0].MSG,
                ...this.configSuccess,
              });
              this.onClosePostVoucher();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: res[0].MSG,
                ...this.configSuccess,
              });
            }
          });
      }
    });
  }
  bulkPost() {
    $("#bulkPostDetailModel").modal("show");
    $("#bulkPostDetailModel").css("z-index", "1050");
  }
  SearchBulkPost() {
    this.displayedColumnsBulkPost = [
      "Id",
      "Selection",
      "CaseNo",
      "CaseDate",
      "CustomerName",
      "ChqueNo",
      "EMI_Amount",
      "DueDate",
      "CustomerBankname",
      "ComapnyAccount",
      "Type",
      "PostDate",
    ];
    let _data: any = {};
    _data = {
      CheckType: this.bulkCheckType,
      DueDateFrom: this.bulkDueDateFrom,
      DueDateTo: this.bulkDueDateTo,
      status: "bulkPost",
    };
    this.lmsService
      .GET_LMS_NACH_PROCESSSearch({ JSON: JSON.stringify(_data) })
      .subscribe((res: any) => {
        //console.log(res);

        this.PostBulkProcess = JSON.parse(JSON.stringify(res));
        this.PostBulkProcess.forEach((x) => {
          x.IsAlreadySelected = false;
        });
        if (this.PostBulkProcess.length > 0) this.IsSelectAll = true;
        else this.IsSelectAll = false;
        this.dataSourceBulkPost = new MatTableDataSource(this.PostBulkProcess);

        this.dataSourceBulkPost.sort = this.sort;
        this.dataSourceBulkPost.paginator = this.paginator;
        this.showSpinner = false;
      });
  }
  bulkClearing() {
    this.IsExportbtnShow = false;
    this.IsImportbtnShow = false;
    this.IsSavebtnShow = false;
    $("#bulkClearingDetailModel").modal("show");
    $("#bulkClearingDetailModel").css("z-index", "1050");
  }
  onCloseBulkPostVoucher() {
    //this.bulkDueDateFrom = '';
    //this.bulkDueDateTo = '';
    this.bulkDueDateFrom = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    this.bulkDueDateTo = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    );
    this.BulkPostDate = new Date();
    this.bulkPresentBankInCompany = "";
    this.PostBulkProcess = [];
    this.IsSelectAll = false;
    this.dataSourceBulkPost = new MatTableDataSource(this.PostBulkProcess);
    this.dataSourceBulkPost.sort = this.sort;
    this.dataSourceBulkPost.paginator = this.paginator;
    this.showSpinner = false;
    this.onSearchNachProcess();
    $("#bulkPostDetailModel").modal("hide");
  }

  SelectAll(event) {
    this.PostBulkProcess.forEach((x) => {
      if (event.checked == true) {
        x.IsAlreadySelected = true;
      }
      if (event.checked == false) {
        x.IsAlreadySelected = false;
      }
    });
    this.dataSourceBulkPost = new MatTableDataSource(this.PostBulkProcess);

    this.dataSourceBulkPost.sort = this.sort;
    this.dataSourceBulkPost.paginator = this.paginator;
    this.showSpinner = false;
  }
  onBulkPost() {
    var Ids = "",
      count = 0;
    this.PostBulkProcess.map((x) => {
      if (x.IsAlreadySelected == true) {
        Ids = Ids + x.Id + ",";
        count = count + 1;
      }
    });
    if (count > 0) {
      //Ids = Ids.replace(/,\s*$/, "");
      //console.log("ids", Ids);
      //let _data: any = {};
      //if (this.bulkCheckType == 'RPDC')
      //  _data.BulkPost = { Ids: Ids, PostDate: this.BulkPostDate, RPDC_ComapnyBank: this.bulkPresentBankInCompany };
      //else
      //  _data.BulkPost = { Ids: Ids, PostDate: this.BulkPostDate, RPDC_ComapnyBank: '' };

      //this.lmsService.SAVE_NACHPROCESS({ Int_Id: this.Id, StatusVal: 'BulkPost', JSON: JSON.stringify(_data) }).subscribe((res) => {
      //  if (res[0].CODE == 0) {
      //    this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      //    this.onCloseBulkPostVoucher();

      //  } else {
      //    this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      //  }
      //});
      var BulkPostDate = this.BulkPostDate;
      var bulkPresentBankInCompany = this.bulkPresentBankInCompany;
      var count = 0;
      this.PostBulkProcess.forEach((x) => {
        count++;
        if (x.IsAlreadySelected == true) {
          this.lmsService
            .GetVoucherNo({
              VoucherType: "NachProcess",
              VoucherSubType: "Loan",
              VoucherDate: this.datepipe.transform(
                new Date(this.BulkPostDate).toString(),
                "yyyy-MM-dd"
              ),
            })
            .subscribe((result: any) => {
              this.saveBulkPostVoucher(
                result[0].VoucherNo,
                x,
                BulkPostDate,
                bulkPresentBankInCompany
              );
            });
        }
      });
      if (count == this.PostBulkProcess.length) this.onCloseBulkPostVoucher();
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please atleast one process select",
        ...this.configSuccess,
      });
    }
  }
  saveBulkPostVoucher(
    VoucherNumber,
    Row,
    BulkPostDate,
    bulkPresentBankInCompany
  ) {
    var ComapnyAccountId = Row.ComapnyAccountId;
    var ComapnyAccount = Row.ComapnyAccount;
    if (this.bulkCheckType == "RPDC") {
      ComapnyAccountId = this.bulkPresentBankInCompany;
      ComapnyAccount = this.BankList.find((obj: any) => {
        return obj.AccountId == bulkPresentBankInCompany;
      }).Account_Name;
    }
    let _vouvher: any = {};
    let VoucherNo = VoucherNumber;
    //console.log("voucherno", VoucherNo);
    _vouvher = {
      VoucherId: 0,
      Voucher_No: VoucherNo,
      Voucher_ReceiptNo_Book: null,
      Voucher_ReceiptDate: null,
      Voucher_Type: "Journal",
      Voucher_Sub_Type: "Loan",
      Voucher_Tag: "Bank",
      Voucher_Date: BulkPostDate,
      Voucher_Effective_Date: null,
      Voucher_Mode_of_Payment: "",
      Voucher_Total_Credit_Amount: Row.EMI_Amount,
      Voucher_Total_Debit_Amount: Row.EMI_Amount,
      Voucher_Total_Tax: null,
      Voucher_Narration:
        "Loan No. " +
        Row.LoanAcNo +
        "  / Application No. " +
        Row.Application_No +
        " Instrument No. " +
        Row.ChequeNo,
      Voucher_Cheque_No: Row.ChequeNo,
      Voucher_Cheque_Date: this.datepipe.transform(
        new Date(Row.DueDate),
        "yyyy-MM-dd"
      ),
      Voucher_Bank_Name: Row.CustomerBankname,
      Voucher_Cheque_Clear_Date: null,
      Voucher_E_Transaction_Date: null,
      Voucher_E_Ref_No: Row.Id,
      LoginUserId: this.currentUser.userId,
    };

    let _voucheretail: any[] = [];
    _voucheretail.push({
      VoucherId: 0,
      CaseNo: Row.LoanAcNo,
      CustomerId: Row.CustomerId,
      Is_LOSAppliaction: 0,
      LOS_TransactionId: null,
      ChargeHeadId: this.ChequeinTransitId,
      ChargeHeadCategory: Row.AccountName,
      AccountId: Row.AccountId,
      BranchId: Row.BranchId,
      TranType: "CR",
      IsTax: null,
      Tax_Per: null,
      Amount: Row.EMI_Amount,
    });
    _voucheretail.push({
      VoucherId: 0,
      CaseNo: null,
      CustomerId: null,
      Is_LOSAppliaction: null,
      LOS_TransactionId: null,
      ChargeHeadId: null,
      ChargeHeadCategory: ComapnyAccount,
      AccountId: ComapnyAccountId,
      BranchId: null,
      TranType: "DR",
      IsTax: null,
      Tax_Per: null,
      Amount: Row.EMI_Amount,
    });

    let _finalData = {
      Voucher: _vouvher,
      Voucher_Detail: _voucheretail,
    };

    //console.log("_finalData", _finalData);
    let _data: any = {};
    if (Row.Type == "RPDC")
      _data.Post = {
        PostDate: BulkPostDate,
        RPDC_ComapnyBank: bulkPresentBankInCompany,
      };
    else _data.Post = { PostDate: BulkPostDate, RPDC_ComapnyBank: "" };

    this.lmsService
      .Save_Acc_VoucherMaster_By_NachProcess({
        JSON: JSON.stringify(_finalData),
        Int_Id: Row.Id,
        StatusVal: "Post",
        Repayment_JSON: JSON.stringify(_data),
      })
      .subscribe((res: any) => {
        if (res[0].CODE > 0) {
          //this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        } else {
          // this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        }
      });
  }
  onCloseBulkClearing() {
    //this.bulkPostDateFrom = '';
    //this.bulkPostDateTo = '';
    this.bulkPostDateFrom = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    this.bulkPostDateTo = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    );
    this.bulkCheckType = "NACH";
    this.PostBulkProcess = [];
    this.dataSourceBulkPost = new MatTableDataSource(this.PostBulkProcess);
    this.dataSourceBulkPost.sort = this.sort;
    this.dataSourceBulkPost.paginator = this.paginator;
    this.showSpinner = false;
    this.onSearchNachProcess();
    $("#bulkClearingDetailModel").modal("hide");
  }
  SearchBulkClearing() {
    this.displayedColumnsBulkPostImport = [
      "IdC",
      "Id",
      "CaseNo",
      "CaseDate",
      "CustomerName",
      "ChqueNo",
      "EMI_Amount",
      "DueDate",
      "CustomerBankname",
      "ComapnyAccount",
      "Type",
      "PostDate",
      "Clearing",
      "Date",
      "BounceReason",
    ];
    let _data: any = {};
    _data = {
      CheckType: this.bulkCheckType,
      PostDateFrom: this.bulkPostDateFrom,
      PostDateTo: this.bulkPostDateTo,
      status: "bulkClearing",
    };
    this.lmsService
      .GET_LMS_NACH_PROCESSSearch({ JSON: JSON.stringify(_data) })
      .subscribe((res: any) => {
        //console.log(res);

        this.PostBulkProcess = JSON.parse(JSON.stringify(res));
        this.PostBulkProcess.forEach((x) => {
          x.IsAlreadySelected = false;
        });
        if (this.PostBulkProcess.length > 0) this.IsSelectAll = true;
        else this.IsSelectAll = false;
        this.dataSourceBulkPost = new MatTableDataSource(this.PostBulkProcess);

        this.dataSourceBulkPost.sort = this.sort;
        this.dataSourceBulkPost.paginator = this.paginator;
        this.showSpinner = false;
        if (this.PostBulkProcess.length > 0) {
          this.IsExportbtnShow = true;
          this.IsImportbtnShow = false;
          this.IsSavebtnShow = false;
        } else {
          this.IsExportbtnShow = false;
          this.IsImportbtnShow = false;
          this.IsSavebtnShow = false;
        }
        this.IsExportShow = true;
        this.IsImportShow = false;
      });
  }
  onImport(event) {
    this.file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      //console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      this.PostBulkProcess = XLSX.utils.sheet_to_json(worksheet, {
        raw: false,
        dateNF: "yyyy-mm-dd",
      });
      console.log("filelist", this.PostBulkProcess);
      this.PostBulkProcess.forEach((x) => {
        x.Date = x.Date == null || x.Date == "" ? "" : new Date(x.Date);

        x.Id = Number(x.ID);
        if (x.Clearing != "00" && x.Clearing != "0") {
          x.BounceReason = this.Bounce_ReasonList.find((obj: any) => {
            return Number(obj.Code) == Number(x.Clearing);
          }).Value;
          x.ClearingType = "Bounce";
        } else {
          x.BounceReason = "";
          x.ClearingType = "Clear";
        }
      });
      this.dataSourceBulkPost = new MatTableDataSource(this.PostBulkProcess);
      this.dataSourceBulkPost.sort = this.sort;
      this.dataSourceBulkPost.paginator = this.paginator;
      this.showSpinner = false;
      this.IsExportShow = false;
      this.IsImportShow = true;
      this.IsExportbtnShow = false;
      this.IsImportbtnShow = false;
      this.IsSavebtnShow = true;
    };
  }
  onExport(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `lms-Process-BulkClearing-${this.bulkCheckType}-list`,
      sheet: "Bulk Clearing",
    });
    this.IsExportbtnShow = false;
    this.IsImportbtnShow = true;
  }
  onBulkClearing() {
    this.showSpinner = true;
    var Record = [];
    this.PostBulkProcess.forEach((x) => {
      x.PDate = x.PostDate == null ? "" : new Date(x.PostDate);

      if (x.PDate > x.Date) x.Date = "";
    });
    this.PostBulkProcess.forEach((val) => Record.push(Object.assign({}, val)));

    var count = Record.filter(
      (item) => item.Date == "" || item.ClearingType == ""
    ).length;
    if (count != undefined && count > 0)
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: count + " Records not update because Date not filled!",
        ...this.configSuccess,
      });
    this.PostBulkProcess = this.PostBulkProcess.filter(
      (item) => item.Date !== "" && item.ClearingType !== ""
    );

    if (this.PostBulkProcess.length > 0) {
      this.lmsService
        .LMS_Save_BulkClearing_Process({
          JSON: JSON.stringify(this.PostBulkProcess),
        })
        .subscribe((res) => {
          if (res[0].CODE == 0) {
            this.showSpinner = false;
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            this.onCloseBulkClearing();
          } else {
            this.showSpinner = false;
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    }
  }
  DateConvert(date, status) {
    let newDate = new Date(date);
    if (this.CurrentDate > newDate && (status == "Pending" || status == ""))
      return true;
    else return false;
  }
  StatusChange() {
    if (this.SearchStatus == "Post") {
      this.BounceDateFrom = "";
      this.BounceDateTo = "";
      this.HoldDateFrom = "";
      this.HoldDateTo = "";
      this.ClearDateFrom = "";
      this.ClearDateTo = "";
    }
    if (this.SearchStatus == "Hold") {
      this.BounceDateFrom = "";
      this.BounceDateTo = "";
      this.PostDateFrom = "";
      this.PostDateTo = "";
      this.ClearDateFrom = "";
      this.ClearDateTo = "";
    }
    if (this.SearchStatus == "Clear") {
      this.BounceDateFrom = "";
      this.BounceDateTo = "";
      this.HoldDateFrom = "";
      this.HoldDateTo = "";
      this.PostDateFrom = "";
      this.PostDateTo = "";
    }
    if (this.SearchStatus == "Bounce") {
      this.PostDateFrom = "";
      this.PostDateTo = "";
      this.HoldDateFrom = "";
      this.HoldDateTo = "";
      this.ClearDateFrom = "";
      this.ClearDateTo = "";
    }
  }
  /*exportExcel() {

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('ProductSheet');

    worksheet.columns = [
      { header: 'Case No', key: 'CaseNo', width: 10 },
      { header: 'Amount', key: 'Amount', width: 32 },
      { header: 'DueDate', key: 'DueDate', width: 10 },
      { header: 'PostDate', key: 'PostDate', width: 10 },
    ];
    console.log("this.ProcessList", this.ProcessList);
    this.ProcessList.forEach(e => {
      worksheet.addRow({ CaseNo: e.LoanAcNo, Amount: e.EMI_Amount, DueDate: e.DueDate, PostDate: e.FPostDate }, "n");
    });
    if (this.BankInCompany == '') {
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'NachProcessList.xlsx');
      })
    } else {
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'NachProcess' + this.BankInCompany.split('_')[1] + 'List.xlsx');
      })
    }

  }*/
  // Excel export in backend start here
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    //this.saveAsExcelFile(excelBuffer, excelFileName);

    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      excelFileName + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  exportExcel() {
    //Remove key and value from below JSON (this.ProcessList)
    this.JsonData.forEach((obj: any) => {
      delete obj.Id;
      delete obj.amortization_Loan;
      delete obj.CustomerId;
      delete obj.BranchId;
      delete obj.AccountId;
      delete obj.ComapnyAccountId;
      delete obj.IsGenerated;
      delete obj.IsStop;
      delete obj.PrductId;
      delete obj.ChargeId;
      delete obj.ClearingType;
      delete obj.Posthold;
      delete obj.BCharge;
    });
    this.exportAsExcelFile(this.JsonData, "Nach Process List");
  }
  // Excel export in backend code end here
  SearchChange() {
    //this.dataSourceLoan.filterPredicate = (data:
    // { CaseNo: string }, filterValue: string) =>
    // data.CaseNo.trim().toLowerCase().indexOf(filterValue) !== -1;
  }
  applyFilter(filterValue: string) {
    this.dataSourceLoan.filter = filterValue.trim().toLowerCase();
  }
  //applyFilter() {
  //  if (this.Search == 'CaseNo') {
  //    this.filterValues.CaseNo = this.filterValue;
  //    this.filterValues.EMI_Amount = '';
  //    this.filterValues.ChqueNo = '';
  //    this.dataSourceLoan.filter = JSON.stringify(this.filterValues);
  //  }
  //  if (this.Search == 'EMI_Amount') {
  //    this.filterValues.EMI_Amount = this.filterValue;
  //    this.filterValues.CaseNo = '';
  //    this.filterValues.ChqueNo = '';
  //    this.dataSourceLoan.filter = JSON.stringify(this.filterValues);
  //  }
  //  if (this.Search == 'ChqueNo') {
  //    this.filterValues.ChqueNo = this.filterValue;
  //    this.filterValues.CaseNo = '';
  //    this.filterValues.EMI_Amount = '';
  //    this.dataSourceLoan.filter = JSON.stringify(this.filterValues);
  //  }

  //  //filterValues = filterValues.trim().toLowerCase();
  //  //this.dataSourceLoan.filter = filterValues;
  //}
  createFilter() {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);

      return (
        data.LoanAcNo.indexOf(searchTerms.CaseNo) !== -1 &&
        data.EMI_Amount.indexOf(searchTerms.EMI_Amount) !== -1 &&
        data.ChequeNo.indexOf(searchTerms.ChqueNo) !== -1
      );
    };
    return filterFunction;
  }
  public calculateTotal() {
    if (this.dataSourceLoan != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceLoan.filteredData.length; i++) {
        sum += Number(this.dataSourceLoan.filteredData[i]["EMI_Amount"]);
      }
      return sum;
    }
    return 0;
    //return this.dataSourceLoan.reduce((accum, curr) => accum + curr.EMI_Amount, 0);
  }
  public highlightRow(row) {
    this.selectedId = row.Id;
  }

  public calculateBounceTotal() {
    if (this.dataSourceLoan != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceLoan.filteredData.length; i++) {
        sum += Number(this.dataSourceLoan.filteredData[i]["BounceCharge"]);
      }
      return sum;
    }
    return 0;
  }
  bulkImport(event) {
    this.showSpinner = true;
    this.Matchhdata = null;
    this.MisMatchdata = null;
    this.ImportBulkProcess = null;
    this.file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      //console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      this.ImportBulkProcess = XLSX.utils.sheet_to_json(worksheet, {
        raw: false,
        dateNF: "yyyy-mm-dd",
      });

      console.log("ImportBulkProcess", this.ImportBulkProcess);
      this.lmsService
        .LMS_CaseMatch_Nach_Import({
          JSON: JSON.stringify(this.ImportBulkProcess),
        })
        .subscribe((res) => {
          if (res[0].CODE == 0) {
            this.showSpinner = false;
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].Msg,
              ...this.configSuccess,
            });
          } else {
            this.showSpinner = false;
            this.displayedColumnsBulkImport = [
              "Id",
              "UMRN",
              "LoanAcNo",
              "DueDate",
              "Date",
              "Amount",
              "ReasonCode",
            ];
            this.displayedColumnsNotMatchImport = [
              "Id",
              "UMRN",
              "LoanAcNo",
              "DueDate",
              "Date",
              "Amount",
              "ReasonCode",
              "NotMach",
            ];
            var ImportData = JSON.parse(JSON.stringify(res));
            this.Matchhdata = ImportData.filter((x) => x.Status == 1);
            this.MisMatchdata = ImportData.filter((x) => x.Status == 0);
            this.dataSourceBulkImport = new MatTableDataSource(
              ImportData.filter((x) => x.Status == 1)
            );
            this.dataSourceBulkImport.sort = this.sort;
            //this.dataSourceBulkImport.paginator = this.paginator;

            this.dataSourceBulkImport_NotMatch = new MatTableDataSource(
              ImportData.filter((x) => x.Status == 0)
            );
            this.dataSourceBulkImport_NotMatch.sort = this.sort;
            // this.dataSourceBulkImport_NotMatch.paginator = this.paginator;
            $("#bulkImportModel").modal("show");
            $("#bulkImportModel").css("z-index", "1050");
          }
        });
    };
  }
  onBulkVoucherSave() {
    this.showSpinner = true;
    console.log("", this.Matchhdata);
    this.lmsService
      .LMS_Case_Save_Voucher_Nach_Import({
        JSON: JSON.stringify(this.Matchhdata),
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.showSpinner = false;
        } else {
          this.showSpinner = false;
        }
      });
    this.onCloseBulkImport();
  }

  onCloseBulkImport() {
    this.displayedColumnsBulkImport = [];
    this.displayedColumnsNotMatchImport = [];
    this.dataSourceBulkImport = null;

    $("#bulkImportModel").modal("hide");
  }
  onExportMisMatch(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `Nach_MisMatchData-list`,
      sheet: "Nach_MisMatchData",
    });
  }
  onRepresent(row) {
    this.Row = this.ProcessList.filter((x) => x.Id == row.Id)[0];
    this.RepresentId = row.Id;
    if (new Date() <= new Date(row.BounceDateFormate)) {
      this.RepresentPostDate = new Date(row.BounceDateFormate);
    } else this.RepresentPostDate = new Date();
    //this.minRepresentPostDate = new Date(row.BounceDateFormate);

    if (new Date(row.BounceDateFormate) > this.minDate) {
      this.minRepresentPostDate = new Date(row.BounceDateFormate);
    } else {
      this.minRepresentPostDate = this.minDate;
    }

    //  this.BounceDateFormate = row.BounceDateFormate;
    $("#RepresentModel").modal("show");
    $("#RepresentModel").css("z-index", "1050");
  }
  onCloseRepresent() {
    this.RepresentId = 0;
    this.minRepresentPostDate = "";
    this.Row = {};
    $("#RepresentModel").modal("hide");
  }
  onRepresentBounce() {
    this.lmsService
      .GetVoucherNo({
        VoucherType: "NachProcess",
        VoucherSubType: "Loan",
        VoucherDate: new Date(this.RepresentPostDate),
      })
      .subscribe((result: any) => {
        this.savePostVoucher(
          result[0].VoucherNo,
          this.RepresentPostDate,
          1,
          this.RepresentId
        );
        this.onCloseRepresent();
      });
  }
  bulkImportTemplate(excelFileName: string): void {
    // var json=[{Date:'',Rec_No:'',Rec_date:'',Loan_No:'',EMI_Amt:'',OD_Amt:'',Other_Amt:''}]
    var json = [
      [
        "TransactionReference",
        "UMRN",
        "DueDate",
        "Date",
        "Amount",
        "ReasonCode",
      ],
    ];
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json);
    // console.log('worksheet',worksheet);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }
}
