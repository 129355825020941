<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="d-flex align-items-center justify-content-between formborder">
    <form action="" class="search-text col-md-3">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
    <button
      type="button"
      class="mt-3 btn font-size-12 button-btn"
      (click)="onAddLoanSeries()"
    >
      <i class="fa fa-plus font-size-12" aria-label="true"></i> Add
    </button>
  </div>
  <div class="table-responsive mt-3">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="Id">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 100px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Series">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          Series
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px"
        >
          {{ row.Series }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Acc_Category">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Accounting Category
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Acc_Category }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Product">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Product
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Product }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="EditAction">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          Action
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px"
        >
          <i
            class="fas fa-lg fa-edit margin-right-4xs cursor-style"
            (click)="OnEditSeries(row)"
            aria-label="true"
          ></i>
          <i
            class="fa fa-trash style-delete"
            (click)="OnDeleteSeries(row)"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

<div
  class="modal fade in"
  id="AddLSModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveLoanSeries()" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Add Loan Series
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0 align-items-center formborder">
            <div class="col-md-6 row m-0 p-0 align-items-center">
              <div class="col-md-4 p-1">
                <span class="required-lable"> Loan Series : </span>
              </div>
              <div class="col-md-8 p-1">
                <input
                  required
                  type="text"
                  (keypress)="keyPressAlphaNumeric($event)"
                  #refLoan_Series="ngModel"
                  maxlength="5"
                  [ngClass]="{
                    'is-invalid': f.submitted && refLoan_Series.invalid,
                    'alert-warning':
                      refLoan_Series.invalid &&
                      (refLoan_Series.dirty ||
                        refLoan_Series.touched ||
                        refLoan_Series.untouched)
                  }"
                  [(ngModel)]="loanSeriesModal.Loan_Series"
                  placeholder="Loan Series"
                  name="Loan_Series"
                  id="Loan_Series"
                  class="form-control input-text-css"
                />
              </div>
            </div>
            <div class="col-md-6 row m-0 p-0 align-items-center">
              <div class="col-md-4 p-1">
                <span class="required-lable"> Accounting Category: </span>
              </div>
              <div class="col-md-8 p-1">
                <select
                  name="Loan_Accounting_CatId"
                  id="Loan_Accounting_CatId"
                  [(ngModel)]="loanSeriesModal.Loan_Accounting_CatId"
                  #refLoan_Accounting_CatId="ngModel"
                  [ngClass]="{
                    'is-invalid':
                      f.submitted && refLoan_Accounting_CatId.invalid,
                    'alert-warning':
                      refLoan_Accounting_CatId.invalid &&
                      (refLoan_Accounting_CatId.dirty ||
                        refLoan_Accounting_CatId.touched ||
                        refLoan_Accounting_CatId.untouched)
                  }"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Category</option>
                  <option
                    [value]="cat.Loan_CategoryId"
                    *ngFor="let cat of AccountingCatDropdown"
                  >
                    {{ cat.Loan_category }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-12 row m-0 p-0 align-items-center">
              <div class="col-md-2 p-1">
                <span class="required-lable"> Products: </span>
              </div>
              <div class="col-md-10 p-1">
                <ng-multiselect-dropdown
                  name="Loan_Products"
                  id="Loan_Products"
                  [placeholder]="'Select Products'"
                  #refLoan_Products="ngModel"
                  [ngClass]="{
                    'is-invalid': f.submitted && refLoan_Products.invalid,
                    'alert-warning':
                      refLoan_Products.invalid &&
                      (refLoan_Products.dirty ||
                        refLoan_Products.touched ||
                        refLoan_Products.untouched)
                  }"
                  [data]="ProductDropDown"
                  [settings]="dropdownSettings"
                  [(ngModel)]="loanSeriesModal.Loan_Products"
                >
                </ng-multiselect-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnClose()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveLoanSeries()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
