import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { QuickLoanService } from "../services/quickLoan.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { LmsService } from "../../_Lms/services/lms.service";
declare var $: any;

@Component({
  selector: "app-quickloan-tat-report",
  templateUrl: "./quickloan-tat-report.component.html",
  styleUrls: ["./quickloan-tat-report.component.scss"],
})
export class QuickloanTatReportComponent implements OnInit {
  currentUser: any;
  FileCheckingList: any[] = [];
  showSpinner: boolean = false;
  dataSource: any;
  displayedColumns: string[] = [];
  ProcessComplition: any;
  PendingSince: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  Customer: any = {};
  SelectType: any = "";

  BranchesList: any = [];
  SalesExecutiveData: any = [];
  CollectionExecutiveData: any = [];
  SourceAreaData: any = [];
  areaData: any = [];
  collectionArea: any = [];
  subAreaData: any = [];
  AccCatDropdown: any[] = [];
  SelectSourceType: any = "";
  partnerDropdown: any;
  // SalesExecutiveData: any = [];
  LeadSourceName: any = "";
  leadSourceDropdown: any;
  displayedDetailColumns: string[];
  dataSourceDetail: any = new MatTableDataSource([]);
  TatReportsList: any = {};
  Application_No: any = "";
  CustomerInfo: any = "";
  Product: any = "";
  Branch: any = "";
  ApplicationCreatedOn: any = "";
  LoanDuration_Month: any = "";
  LoanAmount: any = "";
  @ViewChild(MatSort) sort: MatSort;
  JsonData: Object;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (this.sort != undefined) {
      this.dataSourceDetail.sort = this.sort;
    }
  }

  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private _EncrDecrService: EncrDecrService,
    private dialog: DialogService,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private losService: LosService,
    private _QuickLoanService: QuickLoanService,
    private lmsService: LmsService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("TAT Report");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.Customer.Status = "Pending";

    this.displayedColumns = [
      "ApplicationId",
      "Application_No",
      "Application_Status",
      "Application_LoanAmount",
      "Branch_Name",
      "Application_Date",
      "Product_Name",
      "AC_CustomerType",
      "CustomerName",
      "RelationName",
      "Customer_Gender",
      "GenderAge",
      "PhoneNo",
      "SourceType",
      "SourceName",
      "Status",
      "Action",
    ];
    this.Get_PartnerForDropdown();
    this.getLeadSourceDropdown();
    this.GetSalesExecutive();
  }

  getLeadSourceDropdown() {
    this.showSpinner = true;
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
      console.log("Lead Source Dropdown", this.leadSourceDropdown);
      this.showSpinner = false;
    });
  }

  Get_PartnerForDropdown() {
    console.log("Partner Dropdown API Function");

    if (
      this.SelectSourceType == "Agent" ||
      this.SelectSourceType == "DSA" ||
      this.SelectSourceType == "Dealer"
    ) {
      this.losService
        .Get_PartnerForDropdown({
          PartnerType: this.SelectSourceType,
          branchId: 0,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = res;
        });
      this.LeadSourceName = 0;
    } else if (
      this.SelectSourceType == "Sales Executive" ||
      this.SelectSourceType == "Telecaller Executive" ||
      this.SelectSourceType == "Marketing Executive"
    ) {
      this.partnerDropdown = [];
      this.SalesExecutiveData.forEach((obj: any) => {
        this.partnerDropdown.push({
          PartnerId: obj.EmpId,
          Partner_Name: obj.EmpName,
        });
      });
      this.LeadSourceName = 0;
    } else {
      this.LeadSourceName = "";
    }
  }

  GetSalesExecutive() {
    this.losService
      .getEmployeeDropdown({
        BranchId: 0,
        ProcessId: 1,
        ProductId: 0,
      })
      .subscribe((res: any) => {
        // this.employeeDropdown = res;
        this.SalesExecutiveData = res;
        this.Get_PartnerForDropdown();
        //console.log('res', res);
      });
  }

  QuickLoanTATReportList() {
    this.Customer.LoginUserId = this.currentUser.userId;
    this.Customer.SourceType = this.SelectSourceType || "";
    (this.Customer.SourceId = this.LeadSourceName || 0),
      this._QuickLoanService
        .QuickLoan_TATReportList({
          JSON: JSON.stringify(this.Customer),
        })
        .subscribe((res: any) => {
          console.log("RESULT : ", res);
          if (res.length > 0) {
            this.FileCheckingList = JSON.parse(JSON.stringify(res));
            this.dataSource = new MatTableDataSource(this.FileCheckingList);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "No data found",
              ...this.configSuccess,
            });
            this.dataSource = null;
          }
          this.showSpinner = false;
        });
  }

  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("tat_report1") as HTMLTableElement;
    const worksheetName = "TAT REPORT"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  exportTableTat(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("tat_report2") as HTMLTableElement;
    const worksheetName = "TAT REPORT DETAILS"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  Remark;
  GoToTATReportDetail(row) {
    console.log(row);

    //this.showSpinner = true;
    this.displayedDetailColumns = [
      "ProcessId",
      "Process",
      "ProcessExecutive",
      "ProcessStatus",
      "AssignedOn",
      "CompletedOn",
      "TAT_Hr",
      "Score",
      "Remark",
      "LatLong",
    ];

    this.dataSourceDetail = new MatTableDataSource([]);
    this._QuickLoanService
      .Quick_rpt_TAT_Report({ LoanId: row.ApplicationId })
      .subscribe((res) => {
        console.log("result: ", res);

        $("#ViewTATReportModel").modal("show");
        $("#ViewTATReportModel").css("z-index", "1050");

        this.TatReportsList = JSON.parse(JSON.stringify(res));
        this.Application_No =
          Object.keys(this.TatReportsList).length > 0
            ? this.TatReportsList.Item1
              ? this.TatReportsList.Item1[0]
                ? this.TatReportsList.Item1[0].Application_No
                : ""
              : ""
            : "";
        this.CustomerInfo =
          Object.keys(this.TatReportsList).length > 0
            ? this.TatReportsList.Item1
              ? this.TatReportsList.Item1[0]
                ? this.TatReportsList.Item1[0].Customer
                : ""
              : ""
            : "";
        this.Product =
          Object.keys(this.TatReportsList).length > 0
            ? this.TatReportsList.Item1
              ? this.TatReportsList.Item1[0]
                ? this.TatReportsList.Item1[0].Product
                : ""
              : ""
            : "";
        this.Branch =
          Object.keys(this.TatReportsList).length > 0
            ? this.TatReportsList.Item1
              ? this.TatReportsList.Item1[0]
                ? this.TatReportsList.Item1[0].Branch
                : ""
              : ""
            : "";
        this.ApplicationCreatedOn =
          Object.keys(this.TatReportsList).length > 0
            ? this.TatReportsList.Item1
              ? this.TatReportsList.Item1[0]
                ? this.TatReportsList.Item1[0].ApplicationCreatedOn
                : ""
              : ""
            : "";
        this.LoanAmount =
          Object.keys(this.TatReportsList).length > 0
            ? this.TatReportsList.Item1
              ? this.TatReportsList.Item1[0]
                ? this.TatReportsList.Item1[0].LoanAmount
                : ""
              : ""
            : "";
        this.LoanDuration_Month =
          Object.keys(this.TatReportsList).length > 0
            ? this.TatReportsList.Item1
              ? this.TatReportsList.Item1[0]
                ? this.TatReportsList.Item1[0].LoanDuration_Month
                : ""
              : ""
            : "";
        this.JsonData = this.TatReportsList.Item2;
        this.dataSourceDetail = new MatTableDataSource(
          this.TatReportsList.Item2
        );
        this.dataSourceDetail.sort = this.sort;
        //this.dataSourceDetail.paginator = this.paginator;
        this.showSpinner = false;

        let sum = 0;
        let pending = this.TatReportsList.Item2.find((obj: any) => {
          return !obj.CompletedOn;
        }).TAT_Hr;
        for (let i = 0; i < this.TatReportsList.Item2.length; i++) {
          if (this.TatReportsList.Item2[i].CompletedOn)
            sum += Number(this.TatReportsList.Item2[i].TAT_Hr);
          if (
            !this.TatReportsList.Item2[i].CompletedOn &&
            Number(pending) < Number(this.TatReportsList.Item2[i].TAT_Hr)
          )
            pending = this.TatReportsList.Item2[i].TAT_Hr;
        }
        this.ProcessComplition = sum.toFixed(2);
        this.PendingSince = pending;
        console.log(" sum.toFixed(2)", sum.toFixed(2));
        var days: number = this.ProcessComplition / 24;
        console.log("days.toFixed(0)", Math.floor(days));
        var hour = (days - Math.floor(days)) * 24;
        if (hour) {
          this.ProcessComplition =
            Math.floor(days) + "d " + hour.toFixed(2) + "h";
        } else {
          this.ProcessComplition = Math.floor(days) + "d";
        }

        var days1: number = this.PendingSince / 24;
        var hour1 = (days1 - Math.floor(days1)) * 24;
        if (hour1) {
          this.PendingSince = Math.floor(days1) + "d " + hour1.toFixed(2) + "h";
        } else {
          this.PendingSince = Math.floor(days1) + "d";
        }
      });
  }
  onCloseTATReportModel() {
    $("#ViewTATReportModel").modal("hide");
  }
}
