<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

  <form #df="ngForm"
        novalidate
        (keydown.enter)="searchQuickloanCustomerLead()"
        class="formborder">
    <div class="row m-0 col-md-12">
      <div class="col-md-2">
        <span class="required-lable">From Date</span>
        <div class="datepicker_feild">
          <input [matDatepicker]="picker"
                 dateConvert
                 placeholder="DD/MM/YYYY"
                 name="DateFrom"
                 [(ngModel)]="DateFrom"
                 id="DateFrom"
                 class="form-control input-text-css"
                 required
                 #refDateFrom="ngModel"
                 [ngClass]="{
              'is-invalid': df.submitted && refDateFrom.invalid,
              'alert-warning':
                refDateFrom.invalid &&
                (refDateFrom.dirty ||
                  refDateFrom.touched ||
                  refDateFrom.untouched)
            }" />

          <mat-datepicker-toggle matSuffix
                                 [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>

      <div class="col-md-2">
        <span class="required-lable">To Date</span>
        <div class="datepicker_feild">
          <input [matDatepicker]="picker2"
                 dateConvert
                 placeholder="DD/MM/YYYY"
                 name="DateTo"
                 [(ngModel)]="DateTo"
                 id="DateTo"
                 class="form-control input-text-css"
                 required
                 #refDateTo="ngModel"
                 [ngClass]="{
              'is-invalid': df.submitted && refDateTo.invalid,
              'alert-warning':
                refDateTo.invalid &&
                (refDateTo.dirty || refDateTo.touched || refDateTo.untouched)
            }" />

          <mat-datepicker-toggle matSuffix
                                 [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
      </div>

      <div class="col-md-2 p-1">
        <span class="required-lable">Select Status</span>
        <select name="SelectStatus"
                id="SelectStatus"
                #refSelectStatus="ngModel"
                class="form-control input-text-css"
                [(ngModel)]="SelectStatus">
          <option value="">Select Status</option>
          <option value="All">All</option>
          <option value="Reject">Reject</option>
          <option value="Hold">Hold</option>
          <option value="pending">Pending</option>
          <option value="InProcess">In Process</option>
          <option value="Completed">Completed</option>
        </select>
      </div>

      <div class="col-md-2">
        <button type="button"
                (click)="searchQuickloanCustomerLead()"
                class="mt-3 btn font-size-12 button-btn"
                [disabled]="!df.form.valid">
          Search
        </button>
      </div>

      <div class="float-end col-md-4 mt-2" [hidden]="!dataSource">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input type="text"
                     id="FilterInputSearch"
                     (keyup)="applyFilter($event.target.value)"
                     name="FilterInputSearch"
                     placeholder="What're you searching for?"
                     class="form-control border-0 bg-light" />
              <div class="input-group-append">
                <button id="button-addon1"
                        type="submit"
                        class="btn btn-link text-primary">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>

  <div [hidden]="!dataSource" class="mt-3">
    <div class="table-responsive" style="overflow: auto; height: 65vh">
      <table
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8"
        matTableExporter
        #exporter="matTableExporter"
      >
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + i + 1 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="customerName">
          <th mat-header-cell *matHeaderCellDef>Customer Name</th>
          <td mat-cell *matCellDef="let element">
            {{
              element.firstName +
                " " +
                element.lastName +
                " " +
                element.relation +
                " " +
                element.relativeFirstName +
                " " +
                element.relativeLastName
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="text-center">Status</th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{ element.status }}
          </td>
        </ng-container>

        <ng-container matColumnDef="application_no">
          <th mat-header-cell *matHeaderCellDef class="text-center">
            Application No
          </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{ element.application_no }}
          </td>
        </ng-container>
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef style="width: 15% !important">
            Address
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.address + "," + element.state }}
          </td>
        </ng-container>
        <ng-container matColumnDef="loanRequired">
          <th mat-header-cell *matHeaderCellDef>Required Loan Amount</th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{ element.loanRequired }}
          </td>
        </ng-container>

        <ng-container matColumnDef="requestDate">
          <th mat-header-cell *matHeaderCellDef>Request Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.requestDate | date : "dd-MM-yyyy" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="mobileNo">
          <th mat-header-cell *matHeaderCellDef>Mobile No</th>
          <td mat-cell *matCellDef="let element">
            {{ element.mobileNo }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Reason">
          <th mat-header-cell *matHeaderCellDef class="text-center">Reason</th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{ element.reason }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Action">
          <th mat-header-cell class="grid-header j-c-center" *matHeaderCellDef>
            Action
          </th>
          <td mat-cell *matCellDef="let row" class="grid-cell text-center">

            <i *ngIf="row.status === 'pending' || row.status === 'Hold'"
               class="fa fa-arrow-circle-right arrow-circle action-btn"
               aria-label="true"
               (click)="viewLoanDetail(row)"></i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row  [ngClass]="{
   pending: row.status == 'pending',
   Hold: row.status == 'Hold',
   Reject: row.status == 'Reject',
   Completed: row.status == 'Completed'
 }" *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>

  <div class="row m-0 ml-3" [hidden]="!dataSource">
    <div class="col-md-4 mt-3">
      <button class="mt-2 btn font-size-12 button-btn"
              (click)="exportexcelNew()">
        <i class="fa fa-file-export mr-1"
           style="font-size: medium; cursor: pointer"></i>
        Export
      </button>
    </div>
    <div class="col-md-8 mt-2">
      <mat-paginator #paginatorRef
                     [pageSizeOptions]="[20, 50]"
                     showFirstLastButtons></mat-paginator>
    </div>
  </div>

  <div class="modal fade in"
       id="loanInNeedModal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myModalLabel"
       data-backdrop="false"
       style="background-color: rgba(0, 0, 0, 0.5); overflow: auto">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Customer Lead Details
          </h6>
          <button type="button"
                  (click)="onClose()"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>

        <form #rf="ngForm" novalidate>
          <div class="modal-body">
            <div class="formborder mt-2">
              <div class="row m-0 mt-2 col-md-12">
                <div class="col-md-12 bg-success1">
                  Demographic Information:
                </div>
              </div>

              <div class="row col-md-12 mt-2">
                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">Customer Name</h6>
                  <span class="fs-12">
                    {{
                    rowData?.firstName + " " + rowData?.lastName
                    }}
                  </span>
                </div>

                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">Relation</h6>
                  <span class="fs-12">
                    {{
                    (rowData?.relation || "") +
                      "-" +
                      (rowData?.relativeFirstName || "") +
                      " " +
                      (rowData?.relativeLastName || "")
                    }}
                  </span>
                </div>

                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">Date of Birth</h6>
                  <span class="fs-12">{{ rowData?.dob }}</span>
                </div>

                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">Gender</h6>
                  <span class="fs-12">{{ rowData?.gender }}</span>
                </div>

                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">Mobile No</h6>
                  <span class="fs-12">{{ rowData?.mobileNo }}</span>
                </div>

                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">Email Id</h6>
                  <span class="fs-12">{{ rowData?.email }}</span>
                </div>

                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">Marital Status</h6>
                  <span class="fs-12">{{ rowData?.mStatus }}</span>
                </div>

                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">Customer Category</h6>
                  <span class="fs-12">{{ rowData?.sourceOfIncome }}</span>
                </div>

                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">Customer Profile</h6>
                  <span class="fs-12">{{ rowData?.jobProfile }}</span>
                </div>

                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">Customer Income</h6>
                  <span class="fs-12">{{ rowData?.monthlyIncome }}</span>
                </div>

                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">Loan Amount</h6>
                  <span class="fs-12">{{ rowData?.loanRequired }}</span>
                </div>

                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">Loan Purpose</h6>
                  <span class="fs-12">{{ rowData?.purposeOfLoan }}</span>
                </div>

                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">Applied Date</h6>
                  <span class="fs-12">{{ rowData?.requestDate }}</span>
                </div>

                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">Aadhar Number</h6>
                  <span class="fs-12">{{ rowData?.aadharNo }}</span>
                </div>

                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">Pan Number</h6>
                  <span class="fs-12">{{ rowData?.panNo }}</span>
                </div>
              </div>
            </div>

            <div class="formborder mt-2">
              <div class="row m-0 mt-2 col-md-12">
                <div class="col-md-12 bg-success1">Customer Address:</div>
              </div>

              <div class="row m-0 mt-2 col-md-12">
                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">Address</h6>
                  <span class="fs-12">{{ rowData?.address }}</span>
                </div>

                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">Landmark</h6>
                  <span class="fs-12">{{ rowData?.area }}</span>
                </div>

                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">Pincode</h6>
                  <span class="fs-12">{{ rowData?.pinCode }}</span>
                </div>

                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">State</h6>
                  <span class="fs-12">{{ rowData?.state }}</span>
                </div>

                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">District</h6>
                  <span class="fs-12">{{ rowData?.district }}</span>
                </div>

                <div class="col-md-3 mb-3">
                  <h6 class="fs-14">City</h6>
                  <span class="fs-12">{{ rowData?.city }}</span>
                </div>
              </div>
            </div>

            <div class="mt-2" [hidden]="!dataSourceAadharPan">
              <div class="table-responsive" style="overflow: auto">
                <table mat-table
                       [dataSource]="dataSourceAadharPan"
                       class="modalTable"
                       matTableExporter
                       #exporter="matTableExporter">
                  <!-- <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>#</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      {{ i + 1 }}
                    </td>
                  </ng-container> -->

                  <ng-container matColumnDef="customerName">
                    <th mat-header-cell
                        *matHeaderCellDef
                        style="width: 25% !important; color: blue">
                      Customer Name
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.customerName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Adhaarno">
                    <th mat-header-cell
                        *matHeaderCellDef
                        style="width: 20% !important; color: blue">
                      Aadhar No
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.Adhaarno }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="PanNo">
                    <th mat-header-cell
                        *matHeaderCellDef
                        style="width: 20% !important">
                      Pan No
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.PanNo }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Address">
                    <th mat-header-cell
                        *matHeaderCellDef
                        style="width: 35% !important">
                      Address
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.Address }}
                    </td>
                  </ng-container>

                  <mat-radio-group [(ngModel)]="selectedRow"
                                   (change)="onRadioChange($event.value)">
                    <ng-container matColumnDef="radio">
                      <th mat-header-cell
                          class="grid-header j-c-center"
                          *matHeaderCellDef
                          style="width: 5% !important">
                        Select
                      </th>
                      <td mat-cell
                          *matCellDef="let row"
                          class="grid-cell text-center">
                        <mat-radio-button [value]="row"></mat-radio-button>
                      </td>
                    </ng-container>
                  </mat-radio-group>
                  <tr mat-header-row
                      *matHeaderRowDef="displayedColumnsPanAadhar"></tr>
                  <tr mat-row
                      *matRowDef="let row; columns: displayedColumnsPanAadhar"></tr>
                </table>
              </div>
            </div>

            <div class="formborder mt-2" *ngIf="showFinancialDetails">
              <div class="col-md-12 p-1 bg-success1">
                Loan Financial Details:
              </div>

              <div class="row m-0 col-md-12 p-1">
                <div class="col-md-3 p-1">
                  <span class="required-lable"> Branch </span>
                  <select (change)="getAreaByBranch($event.target.value)"
                          name="Branch_Id"
                          id="Branch_Id"
                          (change)="resetInterstValue(null)"
                          #refBranch_Id="ngModel"
                          required
                          [ngClass]="{
                      'is-invalid': rf.submitted && refBranch_Id.invalid,
                      'alert-warning':
                        refBranch_Id.invalid &&
                        (refBranch_Id.dirty ||
                          refBranch_Id.touched ||
                          refBranch_Id.untouched)
                    }"
                          [(ngModel)]="Branch_Id"
                          class="form-control input-text-css">
                    <option value="">Select Branch</option>
                    <option *ngFor="let branch of BranchesList"
                            [value]="branch.BranchId">
                      {{ branch.Branch_Name }}
                    </option>
                  </select>
                </div>

                <div class="col-md-3 p-1">
                  <span class="required-lable"> Loan Product </span>

                  <select name="LoanProduct_Id"
                          id="LoanProduct_Id"
                          #refLoanProduct_Id="ngModel"
                          disabled
                          required
                          [ngClass]="{ 'is-invalid': rf.submitted && refLoanProduct_Id.invalid,
                     'alert-warning':
                       refLoanProduct_Id.invalid &&
                       (refLoanProduct_Id.dirty ||
                         refLoanProduct_Id.touched ||
                         refLoanProduct_Id.untouched)
                   }"
                         [(ngModel)]="LoanProduct_Id"
                         class="form-control input-text-css">
                    <option value="">Select Products</option>
                    <ng-container *ngFor="let productg of ProductList">
                      <option [value]="productg.ProductId">
                        {{ productg.Product }}
                      </option>
                    </ng-container>
                  </select>
                </div>

                <div class="col-md-3 p-1">
                  <span class="required-lable"> Source Area</span>
                  <select name="SelectSourceArea"
                          id="SelectSourceArea"
                          (change)="resetInterstValue(null)"
                          #refSelectSourceArea="ngModel"
                          required
                          [ngClass]="{
                      'is-invalid': rf.submitted && refSelectSourceArea.invalid,
                      'alert-warning':
                        refSelectSourceArea.invalid &&
                        (refSelectSourceArea.dirty ||
                          refSelectSourceArea.touched ||
                          refSelectSourceArea.untouched)
                    }"
                          [(ngModel)]="SelectSourceArea"
                          class="form-control input-text-css">
                    <option value="">Select Source Area</option>
                    <ng-container *ngFor="let area of areaData">
                      <option *ngIf="area.Type.trim() == 'Source Area'"
                              [value]="area.Int_Id">
                        {{ area.Area_Name }}
                      </option>
                    </ng-container>
                  </select>
                </div>

                <div class="col-md-3 p-1">
                  <span class="required-lable"> Net Finance (Rs.) </span>
                  <input type="text"
                         [disabled]="IsDisabled"
                         numbersOnly
                         required
                         #refNetFinance_Amt="ngModel"
                         [(ngModel)]="NetFinance_Amt"
                         placeholder="Net Finance"
                         name="NetFinance_Amt"
                         id="NetFinance_Amt"
                         class="form-control input-text-css"
                         [ngClass]="{
                      'is-invalid': rf.submitted && refNetFinance_Amt.invalid,
                      'alert-warning':
                        refNetFinance_Amt.invalid &&
                        (refNetFinance_Amt.dirty ||
                          refNetFinance_Amt.touched ||
                          refNetFinance_Amt.untouched)
                    }" />
                </div>

                <div class="col-md-3 p-1">
                  <span class="required-lable">ROI (Per Day) </span>
                  <input type="text"
                         appTwoDigitDecimaNumber
                         required
                         #refReducing_ROI="ngModel"
                         [(ngModel)]="ROI"
                         (change)="resetInterstValue(ROI)"
                         placeholder="Reducing_ROI"
                         name="Reducing_ROI"
                         id="Reducing_ROI"
                         class="form-control input-text-css"
                         [ngClass]="{
                      'is-invalid': rf.submitted && refReducing_ROI.invalid,
                      'alert-warning':
                        refReducing_ROI.invalid &&
                        (refReducing_ROI.dirty ||
                          refReducing_ROI.touched ||
                          refReducing_ROI.untouched)
                    }" />
                </div>

                <div class="col-md-3 p-1">
                  <span class="required-lable">Loan Date</span>
                  <div class="datepicker_feild">
                    <input [matDatepicker]="picker"
                           dateConvert
                           placeholder="DD/MM/YYYY"
                           name="Loan_Date"
                           id="Loan_Date"
                           (dateChange)="changeLoan_Date()"
                           required
                           [min]="minDate"
                           #refLoan_Date="ngModel"
                           [disabled]="isDisabled"
                           class="form-control input-text-css"
                           [(ngModel)]="Loan_Date"
                           [ngClass]="{
                        'is-invalid': rf.submitted && refLoan_Date.invalid,
                        'alert-warning':
                          refLoan_Date.invalid &&
                          (refLoan_Date.dirty ||
                            refLoan_Date.touched ||
                            refLoan_Date.untouched)
                      }" />
                    <mat-datepicker-toggle matSuffix
                                           [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </div>
                </div>
                <div class="col-md-3 p-1">
                  <span class="required-lable">Loan Maturity Date</span>
                  <div class="datepicker_feild">
                    <input [matDatepicker]="picker1"
                           [min]="minLoanMaturityDate"
                           dateConvert
                           (dateChange)="DaysDifference()"
                           name="LoanMaturityDate"
                           id="LoanMaturityDate"
                           required
                           placeholder="DD/MM/YYYY"
                           #refLoanMaturityDate="ngModel"
                           [disabled]="isDisabled"
                           [ngClass]="{
                        'is-invalid':
                          rf.submitted && refLoanMaturityDate.invalid,
                        'alert-warning':
                          refLoanMaturityDate.invalid &&
                          (refLoanMaturityDate.dirty ||
                            refLoanMaturityDate.touched ||
                            refLoanMaturityDate.untouched)
                      }"
                           class="form-control input-text-css"
                           [(ngModel)]="LoanMaturityDate" />
                    <mat-datepicker-toggle matSuffix
                                           [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </div>
                </div>

                <div class="col-md-3 p-1">
                  <span class="required-lable"> Days </span>
                  <input type="text"
                         disabled
                         numbersOnly
                         required
                         #refDays="ngModel"
                         [(ngModel)]="Days"
                         placeholder="Days"
                         name="Days"
                         id="Days"
                         class="form-control input-text-css"
                         [ngClass]="{
                      'is-invalid': rf.submitted && refDays.invalid,
                      'alert-warning':
                        refDays.invalid &&
                        (refDays.dirty || refDays.touched || refDays.untouched)
                    }" />
                </div>
                <div class="col-md-12 mt-3 text-center">
                  <button *ngIf="showFinancialDetails"
                          type="button"
                          [disabled]="!rf.form.valid"
                          (click)="Calculate()"
                          class="btn font-size-12 button-btn">
                    Calculate
                  </button>
                </div>
              </div>

              <div class="row m-0 col-md-12 p-1">
                <div class="col-md-3 p-1">
                  <span class="required-lable"> Interest Amount </span>
                  <input type="text"
                         disabled
                         numbersOnly
                         required
                         #refInterestAmount="ngModel"
                         [(ngModel)]="InterestAmount"
                         placeholder="InterestAmount"
                         name="InterestAmount"
                         id="InterestAmount"
                         class="form-control input-text-css"
                         [ngClass]="{
                      'is-invalid': rf.submitted && refInterestAmount.invalid,
                      'alert-warning':
                        refInterestAmount.invalid &&
                        (refInterestAmount.dirty ||
                          refInterestAmount.touched ||
                          refInterestAmount.untouched)
                    }" />
                </div>
                <div class="col-md-3 p-1">
                  <span class="required-lable"> Agreement Amount </span>
                  <input type="text"
                         disabled
                         numbersOnly
                         required
                         #refAgreementAmount="ngModel"
                         [(ngModel)]="AgreementAmount"
                         placeholder="AgreementAmount"
                         name="AgreementAmount"
                         id="AgreementAmount"
                         class="form-control input-text-css"
                         [ngClass]="{
                      'is-invalid': rf.submitted && refAgreementAmount.invalid,
                      'alert-warning':
                        refAgreementAmount.invalid &&
                        (refAgreementAmount.dirty ||
                          refAgreementAmount.touched ||
                          refAgreementAmount.untouched)
                    }" />
                </div>
                <div class="col-md-3 mt-3 text-center">
                  <button type="button"
                          [disabled]="!rf.form.valid"
                          *ngIf="AgreementAmount && InterestAmount"
                          (click)="SaveApplication()"
                          class="btn font-size-12 button-btn">
                    Save
                  </button>
                </div>
              </div>
            </div>

            <div class="formborder mt-2" *ngIf="!showFinancialDetails">
              <div class="col-md-12 row"
                   style="display: flex; align-items: center; justify-content: end">
                <div class="col-md-6" *ngIf="!showFinancialDetails">
                  <button type="button"
                          (click)="rejectHoldReasonModel('Reject',rowData)"
                          class="btn font-size-12 button-btn float-right">
                    Reject
                  </button>
               
                  <button type="button" *ngIf="rowData?.status!='Hold'"
                          (click)="rejectHoldReasonModel('Hold',rowData)"
                          class="btn font-size-12 button-btn float-right">
                    Hold
                  </button>
               
                  <button type="button"
                          (click)="proceedLoan()"
                          class="btn font-size-12 button-btn  float-right"
                          [disabled]="!selectedRow && dataSourceAadharPan">
                    Proceed
                  </button>
                </div>
                <!-- -->
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>



  <div class="modal fade in"
       id="RejectHoldReason"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myModalLabel"
       data-backdrop="false"
       style="background-color: rgba(0, 0, 0, 0.5); overflow: auto">
    <div class="modal-dialog modal-s modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            {{ReasonStatus}}
          </h6>
          <button type="button"
                  (click)="onCloseRejectHold()"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>

        <form #rfr="ngForm" novalidate>
          <div class="modal-body">
            <div class="row m-0 col-md-12 p-1">
              
              <div class="col-md-8 p-1">
                <span class="required-lable"> {{ReasonStatus}} Reason </span>
                <input type="text"
                      
                       required
                       #refReason="ngModel"
                       [(ngModel)]="Reason"
                       placeholder="Reason"
                       name="Reason"
                       id="Reason"
                       class="form-control input-text-css"
                       [ngClass]="{
        'is-invalid': rfr.submitted && refReason.invalid,
        'alert-warning':
          refReason.invalid &&
          (refReason.dirty ||
            refReason.touched ||
            refReason.untouched)
      }" />
              </div>
              <div class="col-md-4 mt-3 text-center">
                <button type="button"
                        [disabled]="!rfr.form.valid"
                       
                        (click)="rejectHoldLoan()"
                        class="btn font-size-12 button-btn">
                  {{ReasonStatus}}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>


</div>
