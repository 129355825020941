export const constantUrl = {
  AllowExtension: ["jpg", "jpeg", "png", "xls", "xlsx", "doc", "docx", "pdf", "mp4", "avi", "mkv", "wmv", "flv", "webm", "mp3", "wav", "aac", "flac", "ogg", "m4a", "alac","wma"],

  //==================================================================================//

   apiEndpointWeb: window.location.href.split('#')[0] + '#/',
   apiEndpoint: window.location.href.split('#')[0] + 'api',
   hubUrl: window.location.href.split('#')[0] + "api/hubs",
   apiEndpointUploadDoc: window.location.href.split('#')[0] + "uploadDoc",
   apiProfilePicUrl: window.location.href.split('#')[0] + "uploadDoc/wwwroot/Document/Employee/ProfilePic/",
   customerProfilePicUrl: window.location.href.split('#')[0] + "uploadDoc/wwwroot/Document/Customer/",
   CrcApplicationDocUrl: window.location.href.split('#')[0] + "uploadDoc/wwwroot/Document/ApplicationDocument/",
   SenctionTemplateImageUrl: window.location.href.split('#')[0] + "uploadDoc/wwwroot/Document/TemplateDocument/",
   LoanDocUrl: window.location.href.split('#')[0] + "uploadDoc/wwwroot/Document/LoanDocument/",
   OthersDocUrl: window.location.href.split('#')[0] + "uploadDoc/wwwroot/Document/Others/",
   AccountDocUrl: window.location.href.split('#')[0] + "uploadDoc/wwwroot/Document/Account/",
  //==================================================================================//
  /*Demo*/
  //apiEndpointWeb: "https://demo.finnaux.com/#/",
  ////apiEndpoint: 'https://demo.finnaux.com/api',
  ////hubUrl: "https://demo.finnaux.com/api/hubs",
  //apiEndpointUploadDoc: "https://demo.finnaux.com/uploadDoc",
  //apiProfilePicUrl:    "https://demo.finnaux.com/uploadDoc/wwwroot/Document/Employee/ProfilePic/",
  //customerProfilePicUrl:    "https://demo.finnaux.com/uploadDoc/wwwroot/Document/Customer/",
  //CrcApplicationDocUrl:    "https://demo.finnaux.com/uploadDoc/wwwroot/Document/ApplicationDocument/",
  //SenctionTemplateImageUrl:    "https://demo.finnaux.com/uploadDoc/wwwroot/Document/TemplateDocument/",
  //LoanDocUrl:    "https://demo.finnaux.com/uploadDoc/wwwroot/Document/LoanDocument/",
  //OthersDocUrl: "https://demo.finnaux.com/uploadDoc/wwwroot/Document/Others/",
  //AccountDocUrl: "https://demo.finnaux.com/uploadDoc/wwwroot/Document/Account/",
  //MobileAPKUrl:    "https://demo.finnaux.com/uploadDoc/wwwroot/Document/MobileAPK/",
  //apiEndpoint: "http://localhost:5215",
  //hubUrl: "http://localhost:5215/hubs",
  //==================================================================================//
};
