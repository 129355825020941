<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div class="row m-0 mt-3 formborder">
  <div class="col-md-4 p-1" style="display: none">
    <span class="required-lable">Product Category :</span>
    <select
      name="SelectProductCategory"
      id="SelectProductCategory"
      #refSelectProductCategory="ngModel"
      disabled
      [(ngModel)]="SelectProductCategory"
      class="form-control input-text-css"
      required
    >
      <option value="">Select Product</option>
      <option
        *ngFor="let productCategory of ProductCategoryDropdown"
        [value]="productCategory.ProductCatId"
      >
        {{ productCategory.Product_Category }}
      </option>
    </select>
  </div>
  <div class="col-md-6 p-1">
    <span class="required-lable">Product Name :</span>
    <input
      required
      type="text"
      #refProductName="ngModel"
      name="ProductName"
      id="ProductName"
      placeholder="Product Name"
      [(ngModel)]="ProductName"
      class="form-control input-text-css"
      disabled
    />
  </div>
  <div class="col-md-6 p-1">
    <span class="required-lable">Product Short Name :</span>
    <input
      required
      type="text"
      #refProductShortName="ngModel"
      name="ProductShortName"
      id="ProductShortName"
      disabled
      [(ngModel)]="ProductShortName"
      placeholder="Product Short Name"
      class="form-control input-text-css"
    />
  </div>
</div>

<form #df="ngForm" novalidate class="formborder mt-2">
  <div class="row m-0 p-1 mb-2">
    <div class="col-md-12 p-1 product-charges">Process Details:</div>
  </div>

  <table class="table table-bordered table-striped mt-3">
    <thead>
      <tr style="background-color: #0e5c86 !important">
        <th style="color: white; text-align: center; width: 15px">Level</th>
        <th style="color: white; text-align: center">Process</th>
        <th style="color: white; text-align: center">Continued</th>
        <th style="color: white; text-align: center">Hold</th>
        <th style="color: white; text-align: center">Reject</th>
        <th style="color: white; text-align: center">Send Back</th>
        <th style="color: white; text-align: center;display: none;">Login Fee</th>
        <th style="color: white; text-align: center">Manual Assign</th>
        <th style="color: white; text-align: center">Deviation</th>
        <th style="color: white; text-align: center">Add/Remove</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let Process of ProcessModel; let i = index">
        <td>
          <input
            type="number"
            min="1"
            name="Level{{ i }}"
            id="Level{{ i }}"
            placeholder="Level"
            disabled
            class="form-control input-text-css"
            required
            #refLevel="ngModel"
            [(ngModel)]="Process.Level"
            [ngClass]="{
              'is-invalid': df.submitted && refLevel.invalid,
              'alert-warning':
                refLevel.invalid &&
                (refLevel.dirty || refLevel.touched || refLevel.untouched),
              'mt-1': i == 0
            }"
          />
        </td>

        <td>
          <select
            name="ProcessId{{ i }}"
            id="ProcessId{{ i }}"
            required
            #refProcessId="ngModel"
            [disabled]="i == 0 ? true : IsDisabled"
            [(ngModel)]="Process.ProcessId"
            class="form-control input-text-css"
            (change)="onChangeProcess(Process, i)"
          >
            <option value="">Select Process</option>
            <ng-container *ngFor="let item of ProcessDropdown">
              <option
                [value]="item.ProcessId"
                *ngIf="
                  i == 0 ||
                  (item.ProcessId != ProcessFirst.ProcessId &&
                    item.ProcessId != ProcessLast.ProcessId)
                "
              >
                {{ item.Process_Name }}
              </option>
            </ng-container>
          </select>
        </td>

        <td style="text-align: center;">
          <mat-checkbox
            name="Completed{{ i }}"
            id="Completed{{ i }}"
            [(ngModel)]="Process.Completed"
            disabled
            [ngClass]="{ 'chk-mt': i == 0 }"
          >
          </mat-checkbox>
        </td>

        <td style="text-align: center;">
          <mat-checkbox
            name="Hold{{ i }}"
            id="Hold{{ i }}"
            [(ngModel)]="Process.Hold"
            [disabled]="IsDisabled"
            [ngClass]="{ 'chk-mt-Hold': i == 0 }"
          >
          </mat-checkbox>
        </td>

        <td style="text-align: center;">
          <mat-checkbox
            name="Rejected{{ i }}"
            id="Rejected{{ i }}"
            [(ngModel)]="Process.Rejected"
            [disabled]="IsDisabled"
            [ngClass]="{ 'chk-mt-Reject': i == 0 }"
          >
          </mat-checkbox>
        </td>

        <td style="text-align: center;">
          <mat-checkbox
            name="SendBack{{ i }}"
            id="SendBack{{ i }}"
            [(ngModel)]="Process.SendBack"
            [disabled]="IsDisabled"
            [ngClass]="{ 'chk-mt-sendback': i == 0 }"
          >
          </mat-checkbox>
        </td>

        <td style="text-align: center;display: none;">
          <mat-checkbox
            name="Login_Fee_Check{{ i }}"
            id="Login_Fee_Check{{ i }}"
            (change)="ProcessCheckboxChange(i)"
            [(ngModel)]="Process.Login_Fee_Check"
            [disabled]="IsDisabled"
            [ngClass]="{ 'chk-mt-Login_Fee_Check': i == 0 }"
          >
          </mat-checkbox>
        </td>

        <td style="text-align: center;">
          <mat-checkbox
            name="Mannual_Assignment{{ i }}"
            id="Mannual_Assignment{{ i }}"
            [(ngModel)]="Process.Mannual_Assignment"
            [disabled]="IsDisabled"
            [ngClass]="{ 'chk-mt-Mannual_Assignment': i == 0 }"
          >
          </mat-checkbox>
        </td>

        <td style="text-align: center;">
          <mat-checkbox
            name="Deviation_Check{{ i }}"
            id="Deviation_Check{{ i }}"
            [(ngModel)]="Process.Deviation_Check"
            [disabled]="IsDisabled"
            [ngClass]="{ 'chk-mt-Deviation_Check': i == 0 }"
          >
          </mat-checkbox>
        </td>

        <!-- <td>
          <div> -->
        <button
          type="button"
          (click)="removeData(i)"
          [ngClass]="{ 'no-head': i != 0 }"
          *ngIf="ProcessModel.length > 1 && i > 0"
          [hidden]="IsDisabled"
          style="
            border-radius: 50%;
            background-color: #fff;
            color: #28a745;
            border: 1px solid #28a745;
            margin-left: 25px;
          "
        >
          -
        </button>
        <button
          type="button"
          (click)="addMoreData()"
          [ngClass]="{ both: ProcessModel.length > 1, 'no-head': i != 0 }"
          [disabled]="!df.form.valid"
          *ngIf="0 == ProcessModel.length"
          [hidden]="IsDisabled"
          style="
            border-radius: 50%;
            background-color: #fff;
            color: #28a745;
            border: 1px solid #28a745;
            margin-left: 25px;
          "
        >
          +
        </button>
        <button
          type="button"
          (click)="addMoreData()"
          [ngClass]="{ both: ProcessModel.length > 1, 'no-head': i != 0 }"
          [disabled]="!df.form.valid"
          *ngIf="i == ProcessModel.length - 1"
          [hidden]="IsDisabled"
          style="
            border-radius: 50%;
            background-color: #fff;
            color: #28a745;
            border: 1px solid #28a745;
            margin-left: 25px;
          "
        >
          +
        </button>
        <!-- </div>
        </td> -->
      </tr>
      
      
    <tr>
<td>
  <input
  type="number"
  min="1"
  name="Level1000"
  id="Level1000"
  placeholder="Level"
  style="width: 98%"
  class="form-control input-text-css"
  required
  #refLevel="ngModel"
  [(ngModel)]="ProcessLast.Level"
  disabled
  [ngClass]="{
    'is-invalid': df.submitted && refLevel.invalid,
    'alert-warning':
      refLevel.invalid &&
      (refLevel.dirty || refLevel.touched || refLevel.untouched)
  }"
/>
</td>

<td><select
  name="ProcessId1000"
  id="ProcessId1000"
  required
  #refProcessId="ngModel"
  readonly
  [ngClass]="{
    'is-invalid': df.submitted && refProcessId.invalid,
    'alert-warning':
      refProcessId.invalid &&
      (refProcessId.dirty ||
        refProcessId.touched ||
        refProcessId.untouched)
  }"
  [(ngModel)]="ProcessLast.ProcessId"
  class="form-control input-text-css"
  style="width: 100%; margin-left: -1%"
>
  <ng-container *ngFor="let item of ProcessDropdown">
    <option
      [value]="item.ProcessId"
      *ngIf="item.ProcessId == ProcessLast.ProcessId"
    >
      {{ item.Process_Name }}
    </option>
  </ng-container>
</select>
</td>

<td style="text-align:center"> <mat-checkbox
  name="Completed1000"
  id="Completed1000"
  [(ngModel)]="ProcessLast.Completed"
  disabled
>
</mat-checkbox></td>

<td style="text-align:center"><mat-checkbox
  name="Hold1000"
  id="Hold1000"
  [(ngModel)]="ProcessLast.Hold"
  [disabled]="IsDisabled"
>
</mat-checkbox></td>

<td style="text-align:center"> <mat-checkbox
  name="Rejected1000"
  id="Rejected1000"
  [(ngModel)]="ProcessLast.Rejected"
  [disabled]="IsDisabled"
>
</mat-checkbox></td>

<td style="text-align:center">  <mat-checkbox
  name="SendBack1000"
  id="SendBack1000"
  [(ngModel)]="ProcessLast.SendBack"
  [disabled]="IsDisabled"
>
</mat-checkbox></td>
<td style="display:none;text-align:center"><mat-checkbox
  name="Login_Fee_Check1000"
  id="Login_Fee_Check1000"
  [(ngModel)]="ProcessLast.Login_Fee_Check"
  (change)="ProcessLastCheckboxChange()"
  [disabled]="IsDisabled"
>
</mat-checkbox></td>


<td style="text-align:center">
  <mat-checkbox
    name="Mannual_Assignment1000"
    id="Mannual_Assignment1000"
    [(ngModel)]="ProcessLast.Mannual_Assignment"
    [disabled]="IsDisabled"
  >
  </mat-checkbox>
</td>

<td style="text-align:center"> <mat-checkbox
  name="Deviation_Check1000"
  id="Deviation_Check1000"
  [(ngModel)]="ProcessLast.Deviation_Check"
  [disabled]="IsDisabled"
>
</mat-checkbox></td>

      
    </tr>








    </tbody>
  </table>

<!-- 
</div> -->



  <div class="row m-0 text-right">
    <div class="col-md-12 p-1">
      <button
        type="button"
        class="btn font-size-12 button-btn"
        (click)="SaveProcessLevel()"
        mat-raised-button
        *ngIf="IsSave"
        [disabled]="!df.form.valid"
      >
        Save
      </button>
      <button
        type="button"
        class="btn font-size-12 button-btn"
        (click)="SaveProcessLevel()"
        mat-raised-button
        *ngIf="IsUpdate"
        [disabled]="!df.form.valid"
      >
        Update
      </button>

      <button
        type="button"
        (click)="onEditProcess()"
        *ngIf="IsEdit"
        class="btn font-size-12 button-btn"
        [class.spinner]="loading"
      >
        Edit
      </button>
    </div>
  </div>
</form>
