import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { ReportsService } from "src/app/_Reports/services/reports.service";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { QuickLoanService } from "../services/quickLoan.service";
import { MatTableDataSource } from "@angular/material/table";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { MatPaginator } from "@angular/material/paginator";
declare var $: any;
import { LmsService } from "../../_Lms/services/lms.service";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { LosService } from "src/app/_LOS/services/los.service";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
import { Router } from "@angular/router";
import { DialogService } from "src/app/Shared/dialog.service";

@Component({
  selector: "app-quickloan-customer-lead",
  templateUrl: "./quickloan-customer-lead.component.html",
  styleUrls: ["./quickloan-customer-lead.component.scss"],
})
export class QuickloanCustomerLeadComponent implements OnInit {
  showSpinner: boolean = false;

  DateFrom: any = new Date(new Date().getFullYear(), new Date().getMonth());
  DateTo: any = new Date();
  public get dialog(): DialogService {
    return this._dialog;
  }
  SelectStatus: any = "All";
  customerId: any;
  dataSource: any;
  JsonData: any;
  ROW: any;
  ReasonStatus: any;
  Reason: any;
  Branch_Id: string = "";
  BranchesList: any[] = [];
  LoanProduct_Id: string = "";
  ProductList: any[] = [];
  SelectSourceArea: string = "";
  areaData: any[] = [];
  NetFinance_Amt: any;
  ROI: any;
  EMI_Type: any = "";
  No_Of_Inst: any;
  minDate: any;
  Days: any;
  minLoanMaturityDate: any;
  IsDisabled: boolean = true;
  LoanMaturityDate: any;
  Loan_Date: any = new Date();
  showFinancialDetails = false;

  isAnyCheckboxSelected = false;
  selectedRow: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumnsPanAadhar: string[];

  displayedColumns: string[] = [
    "id",
    "customerName",
    "application_no",
    "mobileNo",
    "loanRequired",
    "requestDate",
    "address",
    "status",
    "Reason",
    "Action",
  ];
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  currentUser: any;
  loanInfoModel: any;
  rowData: any;
  branchHeadData: any;
  leadSourceDropdown: any;
  partnerDropdown: any;
  SalesExecutiveData: any;
  CollectionExecutiveData: Object;
  BranchName: Object;
  InterestAmount: any;
  AgreementAmount: any;
  dataSourceAadharPan: any;
  constructor(
    private _Route: Router,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private losService: LosService,
    private LmsService: LmsService,
    private _EncrDecrService: EncrDecrService,
    private _QuickLoanService: QuickLoanService,
    private cdr: ChangeDetectorRef,
    private _dialog: DialogService
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.dataSharingService.HeaderTitle.next("Customer Lead");
    this.minDate = new Date();
    this.getBranchesList();
    this.getProductDropdown();
    this.changeLoan_Date();
  }

  getAppNo_AND_LoanNo() {
    this.LmsService.getAppNo_AND_LoanNo({
      Branch_Id: this.loanInfoModel.Branch_Id,
    }).subscribe((res: any) => {
      // console.log("res", res);
      if (res.length > 0) {
        this.loanInfoModel.ApplicationNo = res[0].ApplicationNo;
      }
    });
    this.loanInfoModel.SelectBranchHead = "";
    this.getBranchHead(this.Branch_Id);
    this.getAreaByBranch(this.loanInfoModel.Branch_Id);
    this.GetCollectionExecutive(
      this.loanInfoModel.Branch_Id,
      this.loanInfoModel.LoanProduct_Id
    );
    this.GetSalesExecutive(
      this.loanInfoModel.Branch_Id,
      this.loanInfoModel.LoanProduct_Id
    );
  }

  getBranchHead(value) {
    this.LmsService.Get_Lms_BranchHead_ByBranch({ Branch_Id: value }).subscribe(
      (res: any) => {
        if (res.length > 0) {
          this.branchHeadData = res;
          this.loanInfoModel.SelectBranchHead = this.branchHeadData[0].EmpId;
        }
      }
    );
  }

  getBranchesList() {
    this.LmsService.GetBranches({ Emp_Id: this.currentUser.userId }).subscribe(
      (result: any) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
        console.log("Branch List", this.BranchesList);
        this.BranchName = result.Branch_Name;
        console.log("Branch Name", this.BranchName);
      }
    );
  }
  getProductDropdown() {
    this._MasterService
      .GetProductList({ Emp_Id: this.currentUser.userId })
      .subscribe((result: any) => {
        this.ProductList = JSON.parse(
          JSON.stringify(
            result.filter((x) => x.Category == "Single Installment")
          )
        );
      });
  }

  getLeadSourceDropdown() {
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
    });
  }

  // Get_PartnerForDropdown(isChange: boolean = true) {
  //   if (isChange) {
  //     this.loanInfoModel.LeadSourceName = "";
  //   }
  //   if (
  //     this.loanInfoModel.SelectSourceType == "Agent" ||
  //     this.loanInfoModel.SelectSourceType == "DSA" ||
  //     this.loanInfoModel.SelectSourceType == "Dealer"
  //   ) {
  //     this.losService
  //       .Get_PartnerForDropdown({
  //         PartnerType: this.loanInfoModel.SelectSourceType,
  //         branchId: this.loanInfoModel.Branch_Id,
  //       })
  //       .subscribe((res: any) => {
  //         this.partnerDropdown = res;
  //       });
  //   }

  //   if (this.loanInfoModel.SelectSourceType == "Sales Executive") {
  //     this.partnerDropdown = [];
  //     this.SalesExecutiveData.forEach((obj: any) => {
  //       this.partnerDropdown.push({
  //         PartnerId: obj.EmpId,
  //         Partner_Name: obj.EmpName,
  //       });
  //     });
  //   }
  // }
  // getAreaByBranch(value) {
  //   this.LmsService.Get_Lms_Area_ByBranch({ Branch_Id: value }).subscribe(
  //     (res: any) => {
  //       this.areaData = res;
  //     }
  //   );
  // }

  getAreaByBranch(Branch_Id: string) {
    // Ensure Branch_Id is valid
    if (Branch_Id) {
      this.LmsService.Get_Lms_Area_ByBranch({ Branch_Id }).subscribe(
        (res: any) => {
          this.areaData = res;
          console.log("Area Data", this.areaData);
        }
      );
    }
  }

  GetCollectionExecutive(value, productID) {
    this.LmsService.Get_Lms_CollectionExecutive({
      Branch_Id: value,
      ProductId: productID,
    }).subscribe((response) => {
      this.CollectionExecutiveData = response;
    });
  }
  GetSalesExecutive(value, productID) {
    this.losService
      .getEmployeeDropdown({
        BranchId: value,
        ProcessId: 1,
        ProductId: productID,
      })
      .subscribe((res: any) => {
        this.SalesExecutiveData = res;
        //this.Get_PartnerForDropdown();
      });
  }

  formatDate(date: Date): string {
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const year = d.getFullYear();
    return `${year}-${month}-${day}`; 
  }

  searchQuickloanCustomerLead() {
    this.showSpinner = true;
    this.cdr.detectChanges();

    console.log("showSpinner value", this.showSpinner);
    const formattedFromDate = this.formatDate(this.DateFrom);
    const formattedToDate = this.formatDate(this.DateTo);
    this._QuickLoanService
      .LoanInNeedAPI({
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        Status: this.SelectStatus,
      })
      .subscribe((response: any) => {
        console.log("Response", response);
        if (response.length > 0) {
          this.dataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(response))
          );
          this.dataSource.paginator = this.paginator;
          this.JsonData = response;

          console.log("Loan amount required", this.JsonData[0]?.loanRequired);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.dataSource = null;
        }
      });
    this.showSpinner = false;
    this.cdr.detectChanges();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onClose() {
    $("#loanInNeedModal").modal("hide");
    this.showFinancialDetails = false;
    this.Branch_Id = "";
    this.LoanProduct_Id = "";
    this.SelectSourceArea = "";
    this.NetFinance_Amt = "";
    this.ROI = "";
    this.EMI_Type = "";
    this.No_Of_Inst = "";
    this.customerId = "";
  }

  viewLoanDetail(row) {
    this.rowData = row;

    this._QuickLoanService
      .Get_Customer_Exists_For_panNo_AdhaarNo({
        panNo: this.rowData.aadharNo,
        AdhaarNo: this.rowData.panNo,
      })
      .subscribe((res: any) => {
        console.log("response of pan aadhar api call", res);

        if (res.length > 0) {
          console.log("RES", res);

          this.dataSourceAadharPan = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );

          this.displayedColumnsPanAadhar = [
            "radio",
            "customerName",
            "Adhaarno",
            "PanNo",
            "Address",
          ];

          this.dataSourceAadharPan = this.dataSourceAadharPan.filteredData.map(
            (item) => ({
              ...item,
              isSelected: false,
            })
          );
          this.LoanProduct_Id = "4";
          // console.log("DS after isSelected value", this.dataSourceAadharPan);
        } else {
          this.dataSourceAadharPan = null;
          this.LoanProduct_Id = "1";
        }
      });

    this.NetFinance_Amt = row.loanRequired;

    console.log("Row Data", this.rowData);
    $("#loanInNeedModal").modal("show");
    $("#loanInNeedModal").css("z-index", "1050");
  }

  onActionButtonClick() {
    if (this.selectedRow) {
      // Perform your action here based on the selected row
      console.log("Performing action on:", this.selectedRow);
    }
  }

  onRadioChange(row) {
    this.customerId = row.CustomerId;
    // console.log("customerID to be sent to req", this.customerId);
    this.selectedRow = row; // Set the selected row
    console.log("Selected row:", this.selectedRow);
  }
  // onCheckboxToggle(row) {
  //   row.isSelected = !row.isSelected;
  //   this.onCheckboxChange();
  // }

  // onCheckboxChange() {
  //   // console.log("isanycheckboxselected before", this.isAnyCheckboxSelected);
  //   // console.log("dataSourceAadharPan", this.dataSourceAadharPan);

  //   this.isAnyCheckboxSelected = this.dataSourceAadharPan.some(
  //     (row) => row.isSelected
  //   );

  //   // console.log(
  //   //   "isanycheckboxselected afterrrr loggg",
  //   //   this.isAnyCheckboxSelected
  //   // );
  // }

  proceedLoan() {
    console.log("Proceed Loan clicked");

    this.showFinancialDetails = true;

    this.onActionButtonClick();
  }

  rejectHoldLoan() {
    this.dialog
      .openConfirmDialog(`Are you sure you want to ` + this.ReasonStatus + `?`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult === false) {
          return;
        } else {
          this.showSpinner = true;

          this._QuickLoanService
            .LoanInNeedRejected({ id: this.ROW.id, status: this.ReasonStatus,reason:this.Reason })
            .subscribe((res: any) => {
             
              if (res.code == 1) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res.message,
                  ...this.configSuccess,
                });
                this.showSpinner = false;
                this.searchQuickloanCustomerLead();
                this.onClose();
                this.onCloseRejectHold();
              }
            });
        }
      });
  }

  rejectHoldReasonModel(status, rowData) {
    this.ReasonStatus = status;
    this.ROW = rowData;
    $("#RejectHoldReason").modal("show");
    $("#RejectHoldReason").css("z-index", "1050");
  }
  onCloseRejectHold() {
    $("#RejectHoldReason").modal("hide");
    this.ReasonStatus = "";
    this.Reason = "";
  }
  Calculate() {
    var ROI = parseFloat(this.ROI);
    var ROIInputMethod = this.ProductList.filter(
      (x) => x.ProductId == this.LoanProduct_Id
    )[0].ROI_Input_mathod;
    if (ROIInputMethod == "Daily") {
      ROI = ROI * this.currentUser.DaysInYear;
    }
    this.InterestAmount = Math.round(
      ((parseFloat(this.NetFinance_Amt) * ROI) /
        100 /
        this.currentUser.DaysInYear) *
        this.Days
    );
    this.AgreementAmount = Math.round(
      parseFloat(this.NetFinance_Amt) + parseFloat(this.InterestAmount)
    );
  }
  exportexcelNew() {}

  changeLoan_Date() {
    var year = new Date(this.Loan_Date).getFullYear();
    var month = new Date(this.Loan_Date).getMonth();
    var day = new Date(this.Loan_Date).getDate();
    var c = new Date(year, month, day + 1);
    console.log("new date", c);
    this.minLoanMaturityDate = c;
    this.DaysDifference();
  }
  DaysDifference() {
    if (
      this.Loan_Date != null &&
      this.LoanMaturityDate != null &&
      this.LoanMaturityDate != undefined
    ) {
      const startTime = new Date(this.Loan_Date).getTime();
      const endTime = new Date(this.LoanMaturityDate).getTime();

      // Calculate the difference in milliseconds
      const difference = endTime - startTime;

      // Convert milliseconds to days
      this.Days = Math.ceil(difference / (1000 * 3600 * 24));
      this.resetInterstValue();
    }
  }
  resetInterstValue() {
    this.InterestAmount = null;
    this.AgreementAmount = null;
  }
  SaveApplication() {
    var JSONdata = this.rowData;
    JSONdata.Branch_Id = this.Branch_Id;
    JSONdata.LoanProduct_Id = this.LoanProduct_Id;
    JSONdata.SelectSourceArea = this.SelectSourceArea;
    JSONdata.NetFinance_Amt = this.NetFinance_Amt;
    JSONdata.ROI = this.ROI;
    JSONdata.Loan_Date = new Date(this.Loan_Date);
    JSONdata.LoanMaturityDate = new Date(this.LoanMaturityDate);
    JSONdata.InterestAmount = this.InterestAmount;
    JSONdata.AgreementAmount = this.AgreementAmount;
    JSONdata.DaysDiff = this.Days;
    JSONdata.customerId = this.customerId;
    console.log("json", JSON);
    this._QuickLoanService
      .QuickLoan_Proceed_Customer_Lead({
        JSON: JSON.stringify(JSONdata),
        loginUserId: this.currentUser.userId,
      })
      .subscribe((result: any) => {
        if (result[0].CODE == 0) {
          this._QuickLoanService
            .LoanInNeedRejected({
              id: this.rowData.id,
              status: "InProcess",
              application_no: result[0].MSG,
            })
            .subscribe((res: any) => {
              console.log("Result of InProcess loan api call", res);
              if (res.code == 1) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res.message,
                  ...this.configSuccess,
                });
                this.showSpinner = false;
                this.searchQuickloanCustomerLead();
                this.onClose();
              }
            });
        }
      });
  }
}
