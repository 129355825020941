<div class="grid-container ">
  <div class="grid-item">
    <div class="grid-sub-item">Application No:</div>
    <div class="grid-sub-item-value">{{loandata["Application_No"]}}</div>
  </div>
  <div class="grid-item">
    <div class="grid-sub-item">Loan Account No:</div>
    <div class="grid-sub-item-value">{{loandata["LoanAcNo"]}}</div>
  </div>
  <div class="grid-item">
    <div class="grid-sub-item">Branch Name:</div>
    <div class="grid-sub-item-value">{{loandata["Branch_Name"]}}</div>
  </div>
  <div class="grid-item">
    <div class="grid-sub-item">Product Name:</div>
    <div class="grid-sub-item-value">{{loandata["Product_Name"]}}</div>
  </div>
  <div class="grid-item">
    <div class="grid-sub-item">Source Type:</div>
    <div class="grid-sub-item-value">{{loandata["SourceType"]}}</div>
  </div>
  <div class="grid-item">
    <div class="grid-sub-item">Branch Head:</div>
    <div class="grid-sub-item-value">{{loandata["BranchHead"]}}</div>
  </div>
  <div class="grid-item">
    <div class="grid-sub-item">Lead Source Name:</div>
    <div class="grid-sub-item-value">{{loandata["LeadSourceName"]}}</div>
  </div>
  <div class="grid-item">
    <div class="grid-sub-item">Sales Executive:</div>
    <div class="grid-sub-item-value">{{loandata["SalesExecutive"]}}</div>
  </div>
  <div class="grid-item">
    <div class="grid-sub-item">Source Area:</div>
    <div class="grid-sub-item-value">{{loandata["SourceArea"]}}</div>
  </div>
  <div class="grid-item">
    <div class="grid-sub-item">Asset Cost:</div>
    <div class="grid-sub-item-value">{{loandata["Application_AssetCost"]}}</div>
  </div>
  <div class="grid-item">
    <div class="grid-sub-item">Loan Amount:</div>
    <div class="grid-sub-item-value">{{loandata["Application_LoanAmount"]}}</div>
  </div>
  <div class="grid-item">
    <div class="grid-sub-item">Interest Amount:</div>
    <div class="grid-sub-item-value">{{loandata["Application_InterestAmt"]}}</div>
  </div>
  <div class="grid-item">
    <div class="grid-sub-item">Agreement Value:</div>
    <div class="grid-sub-item-value">{{loandata["Application_AgreementValue"]}}</div>
  </div>
  <div class="grid-item">
    <div class="grid-sub-item">Loan Tenure:</div>
    <div class="grid-sub-item-value">{{loandata["Application_LoanTenure"]}}</div>
  </div>
  <div class="grid-item">
    <div class="grid-sub-item">Loan EPI Amount:</div>
    <div class="grid-sub-item-value">{{loandata["Application_LoanEMIAmount"]}}</div>
  </div>
  <div class="grid-item">
    <div class="grid-sub-item">Case IRR:</div>
    <div class="grid-sub-item-value">{{loandata["Application_Case_IRR"]}}</div>
  </div>
  <div class="grid-item">
    <div class="grid-sub-item">LTV:</div>
    <div class="grid-sub-item-value">{{loandata["Application_LTV"]}}</div>
  </div>
  <div class="grid-item">
    <div class="grid-sub-item">First EMI Date:</div>
    <div class="grid-sub-item-value">{{loandata["FirstEMIDate"]}}</div>
  </div>
  <div class="grid-item">
    <div class="grid-sub-item">Loan Date:</div>
    <div class="grid-sub-item-value">{{loandata["Loan_Date"]}}</div>
  </div>
  <div class="grid-item">
    <div class="grid-sub-item">Expiry Date:</div>
    <div class="grid-sub-item-value">{{loandata["ExpiryDate"]}}</div>
  </div>
  		
  <div class="grid-item">
    <div class="grid-sub-item">Loan Purpose:</div>
    <div class="grid-sub-item-value">{{loandata["Application_LoanPurpose"]}}</div>
  </div>
</div>
