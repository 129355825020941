import { Component, OnInit, ViewChild, Input, Injectable } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReportsService } from "../services/reports.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
declare var $: any;
import { LmsService } from "../../_Lms/services/lms.service";
import {
  ActivatedRoute,
  NavigationEnd,
  Params,
  RoutesRecognized,
} from "@angular/router";
import * as moment from "moment";
import { MasterService } from "../../Shared/app.Masters.Service";
// Excel export code start here
import * as FileSaver from "file-saver";
import { filter, pairwise } from "rxjs/operators";
import * as XLSX from "xlsx";
import { Router } from "@angular/router";
import { LosService } from "src/app/_LOS/services/los.service";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Injectable()
// Excel export code end here

@Component({
  selector: "app-booking-list",
  templateUrl: "./booking-list.component.html",
  styleUrls: ["./booking-list.component.scss"],
})
export class BookingListComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  div_list: boolean = false;
  pageTitle: any = "Booking List";
  BranchesList: any = [];
  DataSource: any = [];
  DataSourceModel: any;
  ModelTitle: any;
  displayedColumnsModel: string[];
  Collateral: any;
  Collateral_Type: any;
  Info: any;
  dataviewAssetItemSource: any;
  dataAssetItemSource: any;
  ItemArr: any = [];
  displayedViewAssetItemColumns: any;

  selectedId: any;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  displayedColumns: string[];

  //Form value
  FromDate: any = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  ToDate: any = new Date();

  FyearId: any;
  maxDate: any;
  minDate: any;

  JsonData: any[] = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("paginatorlist", { read: MatPaginator, static: true })
  paginatorlist: MatPaginator;
  SelectSourceType: any = "";
  partnerDropdown: any;
  SalesExecutiveData: any = [];
  LeadSourceName: any;
  leadSourceDropdown: any;

  constructor(
    private router: Router,
    private dataSharingService: DataSharingService,
    private reportsService: ReportsService,
    private MasterService: MasterService,

    private snackBar: MatSnackBar,
    private lmsService: LmsService,
    private route: ActivatedRoute,
    private losService: LosService
  ) {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next(this.pageTitle);
    this.Get_PartnerForDropdown();
    this.getLeadSourceDropdown();
    this.GetSalesExecutive();
    this.displayedViewAssetItemColumns = [
      "Id",
      "ItemName",
      "ItemType",
      "Qty",
      "PurityCT",
      "GrossWt",
      "StoneWt",
      "OtherWt",
      "ValulationRate_PerCT",
      "ValulationAmount",
    ];

    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      //console.log("paran", this.FyearId);
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.minDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          this.maxDate = new Date(moment(res[0].fyent).format("MM/DD/YYYY"));
          this.FromDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          if (this.maxDate > new Date() && this.minDate < new Date())
            this.ToDate = new Date();
          else this.ToDate = this.maxDate;
          this.DataSource = null;
        });
    });
    this.displayedColumns = [
      "Id",
      "Application_No",
      "Application_Date",
      "LoanAcNo",
      "Loan_Date",
      "Loan_Acc_Category",
      "CustomerName",
      "Customer_PhoneNo",
      "Address",
      "LatLong",
      "Co",
      "Co_name",
      "Co_Borrower",
      "Co_Borrower_PhoneNo",
      "Co_BorrowerAddress",
      "Guarantor",
      "Guarantor_PhoneNo",
      "GuarantorAddress",
      "LoanSegment",
      "LoanAmount",
      "Application_InterestAmt",
      "Application_AgreementValue",
      "Application_LoanEMIAmount",
      "No_of_EMI",
      "Application_LoanTenure",
      "LTV",
      "ROI",
      "Application_Case_IRR",
      "Application_Disbursement_IRR",
      "Due_Date",
      "Application_ExpiryDate",
      "LoanClosed_Date",
      "Application_AdvInstl",
      "EMI_Frexvency",
      "No_of_Ref",
      "Primary_Assets_Type",
      "Additional_Assets_Type",
      "SourceType",
      "SourceName",
      "Product_Name",
      "Branch_Name",
      "SourceArea",
      "SalesExecutive",
      "CollectionExecutive",
      "CollectionArea",
      "CollectionSubarea",
      "SPDC_Bank",
      "SPDC_Cheque",
      "RPDC_Bank",
      "RPDC_Cheque",
      "Application_LoanPurpose",
      "EMI_Structure",
    ];
  }

  onSearchBusinessData() {
    this.showSpinner = true;

    this.reportsService
      .Report_Booking_List({
        From: this.FromDate,
        To: this.ToDate,
        LoanAcId: "",
        LoginUserId: this.currentUser.userId,
        SourceType: this.SelectSourceType || "",
        SourceId: this.LeadSourceName || 0,
      })
      .subscribe((res: any) => {
        console.log(res);
        if (res.length > 0) {
          this.JsonData = JSON.parse(JSON.stringify(res));
          this.DataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.DataSource.sort = this.sort;
          this.DataSource.paginator = this.paginator;
          this.showSpinner = false;
          this.div_list = true;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
          this.div_list = false;
          this.DataSource = null;
        }
      });
  }

  DateChange() {
    if (this.FromDate != "" && this.ToDate != "") {
      if (this.FromDate > this.ToDate) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "To Date will be greater than to From Date.",
          ...this.configSuccess,
        });
        this.FromDate = "";
        this.ToDate = "";
      }
    }
  }

  onClickCustomerRefData(row) {
    this.displayedColumnsModel = [
      "Id",
      "ReferenceType",
      "Name",
      "ContactNo",
      "Customer",
      "KnowYear",
      "Address",
      "CreatedOn",
    ];
    this.ModelTitle = "Customer Reference";
    this.paginatorlist.pageIndex = 0;
    this.lmsService
      .LMS_GetCustomerRef({ Loan_Id: row.Loan_Id })
      .subscribe((res: any) => {
        //console.log("res : ", res);
        if (res.length > 0) {
          this.DataSourceModel = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.DataSourceModel.sort = this.sort;
          this.DataSourceModel.paginator = this.paginatorlist;
          this.showSpinner = false;

          $("#ViewCustomerChqAssetDetails").modal("show");
          $("#ViewCustomerChqAssetDetails").css("z-index", "1050");
        }
      });
  }

  applyFilter(filterValue: string) {
    this.DataSource.filter = filterValue.trim().toLowerCase();
  }
  onClickChequeData(row, type) {
    let chqType;
    this.displayedColumnsModel = [
      "Id",
      "PdcType",
      "CustomerName",
      "Bankname",
      "Branch",
      "AccType",
      "Ac",
      "ChFrom",
      "ChTo",
      "NoOfChqs",
      "IFSCCode",
    ];

    if (type == 1) {
      this.ModelTitle = "SPDC Cheque Reference";
      chqType = "SPDC";
    } else {
      this.ModelTitle = "RPDC Cheque Reference";
      chqType = "RPDC";
    }
    this.paginatorlist.pageIndex = 0;
    this.lmsService
      .GetChequeDetails({ Loan_Id: row.Loan_Id, Type: chqType })
      .subscribe((res: any) => {
        console.log("SPDC : ", res);
        if (res.length > 0) {
          this.DataSourceModel = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.DataSourceModel.sort = this.sort;
          this.DataSourceModel.paginator = this.paginatorlist;
          this.showSpinner = false;

          $("#ViewCustomerChqAssetDetails").modal("show");
          $("#ViewCustomerChqAssetDetails").css("z-index", "1050");
        }
      });
  }
  onClickAssetsData(row, type) {
    let AssetType;
    this.displayedColumnsModel = [
      "Id",
      "Collateral_Type",
      "Collateral",
      "ValuationType",
      "ValuationAmount",
      "ValuationDate",
      "View_Detail",
    ];

    if (type == 1) {
      this.ModelTitle = " Primary Assets Type Details";
      AssetType = "Primary";
    } else {
      this.ModelTitle = " Additional Assets Type Details";
      AssetType = "Additional";
    }
    this.paginatorlist.pageIndex = 0;
    this.lmsService
      .LMS_GetAllUserAssetInfo({ Loan_Id: row.Loan_Id, Type: AssetType })
      .subscribe((res: any) => {
        console.log("res : ", res);
        if (res.length > 0) {
          this.DataSourceModel = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.DataSourceModel.sort = this.sort;
          this.DataSourceModel.paginator = this.paginatorlist;
          this.showSpinner = false;

          $("#ViewCustomerChqAssetDetails").modal("show");
          $("#ViewCustomerChqAssetDetails").css("z-index", "1050");
        }
      });
  }

  onCloseBookingList() {
    $("#ViewCustomerChqAssetDetails").modal("hide");
  }

  ViewData(row) {
    this.lmsService
      .ViewAssetInfo({ SearchBy: row.Collateral, Id: row.AssetId })
      .subscribe((response: any) => {
        this.Collateral = row.Collateral;
        this.Collateral_Type = row.Collateral_Type;
        this.Info = response.Item1[0];
        if (this.Collateral == "Gold") {
          this.dataviewAssetItemSource = new MatTableDataSource(response.Item2);
          this.dataAssetItemSource = new MatTableDataSource(response.Item2);
          this.ItemArr = response.Item2;
        }
        $("#View").modal("show");
        $("#View").css("z-index", "1050");
      });
  }
  onCloseView() {
    $("#View").modal("hide");
  }

  public highlightRow(row) {
    this.selectedId = row.Loan_Id;
  }

  totaltypewise(type) {
    if (
      this.DataSource != null &&
      this.DataSource != undefined &&
      this.DataSource != ""
    ) {
      let sum = 0;
      for (let i = 0; i < this.DataSource.filteredData.length; i++) {
        sum += Number(this.DataSource.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : 0;
    }
    return 0;
  }

  // Excel export in backend start here
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      excelFileName + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  exportexcelNew(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("booking_list") as HTMLTableElement;
    const worksheetName = "BOOKING LIST"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
  exportTable() {}

  getLeadSourceDropdown() {
    this.showSpinner = true;
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
      console.log("Lead Source Dropdown", this.leadSourceDropdown);
      this.showSpinner = false;
    });
  }

  Get_PartnerForDropdown() {
    console.log("Partner Dropdown API Function");

    if (
      this.SelectSourceType == "Agent" ||
      this.SelectSourceType == "DSA" ||
      this.SelectSourceType == "Dealer"
    ) {
      this.losService
        .Get_PartnerForDropdown({
          PartnerType: this.SelectSourceType,
          branchId: 0,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = res;
        });
      this.LeadSourceName = 0;
    } else if (
      this.SelectSourceType == "Sales Executive" ||
      this.SelectSourceType == "Telecaller Executive" ||
      this.SelectSourceType == "Marketing Executive"
    ) {
      this.partnerDropdown = [];
      this.SalesExecutiveData.forEach((obj: any) => {
        this.partnerDropdown.push({
          PartnerId: obj.EmpId,
          Partner_Name: obj.EmpName,
        });
      });
      this.LeadSourceName = 0;
    } else {
      this.LeadSourceName = "";
    }
  }

  GetSalesExecutive() {
    this.losService
      .getEmployeeDropdown({
        BranchId: 0,
        ProcessId: 1,
        ProductId: 0,
      })
      .subscribe((res: any) => {
        // this.employeeDropdown = res;
        this.SalesExecutiveData = res;
        this.Get_PartnerForDropdown();
        //console.log('res', res);
      });
  }
}
