import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../services/lms.service";
import { Router } from "@angular/router";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
import { LosService } from "src/app/_LOS/services/los.service";

declare var $: any;

@Component({
  selector: "app-lms-payment-initiation-list",
  templateUrl: "./lms-payment-initiation-list.component.html",
  styleUrls: ["./lms-payment-initiation-list.component.scss"],
})
export class LmsPaymentInitiationListComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  status: any = "Pending";
  dataSource: any;
  displayedColumns: string[] = [];
  FileCheckingList: any[] = [];

  SelectSourceType: any = "";
  partnerDropdown: any;
  SalesExecutiveData: any;
  LeadSourceName: any;
  leadSourceDropdown: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private lmsService: LmsService,
    private router: Router,
    private _EncrDecrService: EncrDecrService,
    private losService: LosService
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Payment Initiation List");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.Get_PartnerForDropdown();
    this.getLeadSourceDropdown();
    this.GetSalesExecutive();
    this.displayedColumns = [
      "Loan_Id",
      "Application_No",
      "LoanAcNo",
      "Loan_Date",
      "Branch",
      "Product_Name",
      "Application_LoanAmount",
      "Customer_Name",
      "Amount",
      "Pending_Amount",
      "Hold_Amount",
      "SourceType",
      "SourceName",
      "Action",
    ];
    this.LMS_Get_Initiation_List();
  }
  getLeadSourceDropdown() {
    this.showSpinner = true;
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
      console.log("Lead Source Dropdown", this.leadSourceDropdown);
      this.showSpinner = false;
    });
  }
  GetSalesExecutive() {
    this.losService
      .getEmployeeDropdown({
        BranchId: 0,
        ProcessId: 1,
        ProductId: 0,
      })
      .subscribe((res: any) => {
        // this.employeeDropdown = res;
        this.SalesExecutiveData = res;
        this.Get_PartnerForDropdown();
        //console.log('res', res);
      });
  }
  Get_PartnerForDropdown() {
    console.log("Partner Dropdown API Function");

    if (
      this.SelectSourceType == "Agent" ||
      this.SelectSourceType == "DSA" ||
      this.SelectSourceType == "Dealer"
    ) {
      this.losService
        .Get_PartnerForDropdown({
          PartnerType: this.SelectSourceType,
          branchId: 0,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = res;
        });
      this.LeadSourceName = 0;
      this.LMS_Get_Initiation_List();
    } else if (
      this.SelectSourceType == "Sales Executive" ||
      this.SelectSourceType == "Telecaller Executive" ||
      this.SelectSourceType == "Marketing Executive"
    ) {
      this.partnerDropdown = [];
      this.SalesExecutiveData.forEach((obj: any) => {
        this.partnerDropdown.push({
          PartnerId: obj.EmpId,
          Partner_Name: obj.EmpName,
        });
      });
      this.LeadSourceName = 0;
      this.LMS_Get_Initiation_List();
    } else {
      this.LeadSourceName = "";
      this.LMS_Get_Initiation_List();
    }
  }

  LMS_Get_Initiation_List() {
    this.showSpinner = true;
    this.lmsService
      .LMS_Get_PaymentInitiation_List({
        DV_Status: this.status,
        LoginUserId: this.currentUser.userId,
        SourceType: this.SelectSourceType || "",
        SourceId: this.LeadSourceName || 0,
      })
      .subscribe((res: any) => {
        //console.log("res", res);
        this.FileCheckingList = JSON.parse(JSON.stringify(res));
        this.dataSource = new MatTableDataSource(
          JSON.parse(JSON.stringify(res))
        );
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "payment_initiation_list"
    ) as HTMLTableElement;
    const worksheetName = "PENDING INITIATION LIST "; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  goToDetail(data: any) {
    this.router.navigate([
      `/lms/Payment-Initiation-detail/${this._EncrDecrService.encrypt(
        encodeURIComponent(data.Loan_Id)
      )}/${this.status}/${this._EncrDecrService.encrypt(
        encodeURIComponent(data.Pending_Amount)
      )}`,
    ]);
    //this.dataSharingService.PendingDisbursment.next(parseFloat(data.Pending_Amount));
  }
}
