<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div>
  <!--  style="height: 250px; max-width: 100%; overflow: auto;" -->
  <div
    *ngIf="
      PageAcess &&
      PageAcess !== undefined &&
      PageAcess !== '' &&
      PageAcess[0].View == 1
    "
  >
    <!-- <h1 class="fs-12 mt-1 h-b" *ngIf="isCalEMITableView">Loan Financial Details</h1> -->
    <div class="row m-0 mt-2 p-1" *ngIf="isCalEMITableView">
      <div class="col-md-12 p-1 bg-success1">Loan Financial Details:</div>
    </div>
    <div class="row m-0 mt-1">
      <ng-container *ngIf="isCalEMITableView">
        <form
          #eif="ngForm"
          class="row m-0"
          (ngSubmit)="onCalculateEmiAndIRR()"
          novalidate
        >
          <div class="col-md-12 mt-3" *ngIf="cEIModel.ProductId">
            <div class="table-responsive">
              <mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="SchemeId">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                    >#</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px"
                  >
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Scheme">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Scheme</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Scheme }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Amount">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Amount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Amount }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ROI">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >ROI (%)</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ROI }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Period">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Period</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Period }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="AdvanceEMI">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Advance EMI</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.AdvanceEMI }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Select">
                  <mat-header-cell
                    class="grid-header j-c-center"
                    style="max-width: 60px"
                    *matHeaderCellDef
                    >Select</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell j-c-center"
                    style="max-width: 60px"
                  >
                    <input
                      type="checkbox"
                      [value]="row.SchemeId"
                      [checked]="selectedScheme.SchemeId == row.SchemeId"
                      (change)="onCheckboxChange($event, row)"
                    />
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumns"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></mat-row>
              </mat-table>
              <!-- <p *ngIf="schemeArr.length==0">No Scheme Available</p> -->
            </div>
          </div>

          <div class="formborder mt-2">
            <div class="row m-0 col-md-12 p-1">
              <div
                class="col-md-3 p-1"
                *ngIf="loanSummary.Product_Category != 'Single Installment'"
              >
                <span>Asset Cost (Rs.) </span>
                <input
                  type="text"
                  numbersOnly
                  #refAsset_Cost="ngModel"
                  [(ngModel)]="cEIModel.Asset_Cost"
                  disabled
                  placeholder="Asset Cost"
                  name="Asset_Cost"
                  id="Asset_Cost"
                  class="form-control input-text-css"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refAsset_Cost.invalid,
                    'alert-warning':
                      refAsset_Cost.invalid &&
                      (refAsset_Cost.dirty ||
                        refAsset_Cost.touched ||
                        refAsset_Cost.untouched)
                  }"
                />
              </div>
              <div class="col-md-3 p-1">
                <span> Net Finance (Rs.) </span>
                <input
                  type="text"
                  numbersOnly
                  required
                  #refNetFinance_Amt="ngModel"
                  [(ngModel)]="cEIModel.NetFinance_Amt"
                  placeholder="Net Finance"
                  name="NetFinance_Amt"
                  id="NetFinance_Amt"
                  (change)="changeNetFinance_Amt()"
                  class="form-control input-text-css"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refNetFinance_Amt.invalid,
                    'alert-warning':
                      refNetFinance_Amt.invalid &&
                      (refNetFinance_Amt.dirty ||
                        refNetFinance_Amt.touched ||
                        refNetFinance_Amt.untouched)
                  }"
                />
              </div>
              <div class="col-md-3 p-1">
                <span class="required-lable"> Type</span>
                <select
                  name="Type"
                  id="Type"
                  #refType="ngModel"
                  [(ngModel)]="cEIModel.IRR_CalculateBy"
                  class="form-control input-text-css"
                  (change)="setType($event)"
                  [disabled]="
                    loanSummary.Product_Category == 'Single Installment'
                  "
                  required
                  [ngClass]="{
                    'is-invalid': eif.submitted && refType.invalid,
                    'alert-warning':
                      refType.invalid &&
                      (refType.dirty || refType.touched || refType.untouched)
                  }"
                >
                  <option value="ROI">Flat ROI</option>
                  <option value="FLAT_EMI">EMI Amt (₹)</option>
                  <option value="STEP_EMI">Step EMI</option>
                  <option value="Reducing_ROI">Reducing ROI</option>
                </select>
              </div>

              <div class="col-md-3 p-1">
                <span
                  class="required-lable"
                  *ngIf="cEIModel.IRR_CalculateBy != 'STEP_EMI'"
                >
                  {{ cEIModel.IRR_CalculateBy }}
                </span>
                <input
                  type="text"
                  appTwoDigitDecimaNumber
                  required
                  #refReducing_ROI="ngModel"
                  [(ngModel)]="cEIModel.Reducing_ROI"
                  placeholder="Reducing_ROI"
                  *ngIf="cEIModel.IRR_CalculateBy == 'Reducing_ROI'"
                  name="Reducing_ROI"
                  id="Reducing_ROI"
                  class="form-control input-text-css"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refReducing_ROI.invalid,
                    'alert-warning':
                      refReducing_ROI.invalid &&
                      (refReducing_ROI.dirty ||
                        refReducing_ROI.touched ||
                        refReducing_ROI.untouched)
                  }"
                />
                <input
                  type="text"
                  placeholder="Flat Rate"
                  name="Flat_Rate"
                  id="Flat_Rate"
                  class="form-control input-text-css"
                  [hidden]="true"
                  *ngIf="cEIModel.IRR_CalculateBy !== 'ROI'"
                />
                <input
                  type="text"
                  appTwoDigitDecimaNumber
                  required
                  #refFlat_Rate="ngModel"
                  [(ngModel)]="cEIModel.Flat_Rate"
                  placeholder="Flat Rate"
                  *ngIf="cEIModel.IRR_CalculateBy == 'ROI'"
                  name="Flat_Rate"
                  id="Flat_Rate"
                  class="form-control input-text-css"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refFlat_Rate.invalid,
                    'alert-warning':
                      refFlat_Rate.invalid &&
                      (refFlat_Rate.dirty ||
                        refFlat_Rate.touched ||
                        refFlat_Rate.untouched)
                  }"
                />

                <input
                  type="text"
                  placeholder="EMI Amount"
                  name="EMI_Amount"
                  id="EMI_Amount"
                  class="form-control input-text-css"
                  [hidden]="true"
                  *ngIf="cEIModel.IRR_CalculateBy !== 'FLAT_EMI'"
                />
                <input
                  type="text"
                  numbersOnly="true"
                  required
                  #refEMI_Amount="ngModel"
                  [(ngModel)]="cEIModel.EMI_Amount"
                  placeholder="EMI Amount"
                  *ngIf="cEIModel.IRR_CalculateBy == 'FLAT_EMI'"
                  name="EMI_Amount"
                  id="EMI_Amount"
                  class="form-control input-text-css"
                  [ngClass]="{
                    'is-invalid': eif.submitted && refEMI_Amount.invalid,
                    'alert-warning':
                      refEMI_Amount.invalid &&
                      (refEMI_Amount.dirty ||
                        refEMI_Amount.touched ||
                        refEMI_Amount.untouched)
                  }"
                />
              </div>
            </div>
            <div class="row m-0 p-0 col-md-12">
              <div class="col-md-3 p-1 mb-3">
                <span> EMI Frequency</span>
                <select name="EMI_Type"
                        id="EMI_Type"
                        #refEMI_Type="ngModel"
                        [disabled]="
                    loanSummary.Product_Category == 'Single Installment'
                  "
                        [(ngModel)]="cEIModel.EMI_Type"
                        [ngClass]="{
                    'is-invalid': eif.submitted && refEMI_Type.invalid,
                    'alert-warning':
                      refEMI_Type.invalid &&
                      (refEMI_Type.dirty ||
                        refEMI_Type.touched ||
                        refEMI_Type.untouched)
                  }"
                        class="form-control input-text-css"
                        (change)="setTenure()"
                        required>
                  <!-- <option value=""> EMI Frequancy </option> -->
                  <option value="Daily"
                          [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'">
                    Daily
                  </option>
                  <option value="Weekly"
                          [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'">
                    Weekly
                  </option>
                  <option value="ByWeekly"
                          [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'">
                    Two-Weekly
                  </option>
                  <option value="FourthWeekly"
                          [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'">
                    Four-Weekly
                  </option>
                  <option value="Fortnightly"
                          [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'">
                    Fortnightly
                  </option>
                  <option value="Monthly">Monthly</option>
                  <option value="ByMonthly"
                          [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'">
                    Bi-Monthly
                  </option>
                  <option value="Quarterly"
                          [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'">
                    Quarterly
                  </option>
                  <option value="HalfYearly"
                          [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'">
                    Half Yearly
                  </option>
                  <option value="Yearly"
                          [hidden]="cEIModel.IRR_CalculateBy == 'STEP_EMI'">
                    Yearly
                  </option>

                  <option value="SingleEMI"
                          [hidden]="
                      loanSummary.Product_Category != 'Single Installment'
                    ">
                    Single Installment
                  </option>
                </select>
              </div>
              <div class="col-md-3 p-1 mb-3">
                <span> No Of Installments </span>
                <input type="text"
                       numbersOnly
                       required
                       #refNo_Of_Inst="ngModel"
                       [(ngModel)]="cEIModel.No_Of_Inst"
                       [disabled]="
                    loanSummary.Product_Category == 'Single Installment'
                  "
                       placeholder="No Of Installments"
                       (blur)="setTenure()"
                       [ngClass]="{
                    'is-invalid': eif.submitted && refNo_Of_Inst.invalid,
                    'alert-warning':
                      refNo_Of_Inst.invalid &&
                      (refNo_Of_Inst.dirty ||
                        refNo_Of_Inst.touched ||
                        refNo_Of_Inst.untouched)
                  }"
                       name="No_Of_Inst"
                       id="No_Of_Inst"
                       class="form-control input-text-css" />
              </div>
              <div class="col-md-3 p-1 mb-3">
                <span> Tenure ({{ Name }}) </span>
                <input type="text"
                       numbersOnly
                       required
                       disabled
                       #refTenure="ngModel"
                       [disabled]="
                    loanSummary.Product_Category == 'Single Installment'
                  "
                       [(ngModel)]="cEIModel.Tenure"
                       placeholder="Tenure"
                       [ngClass]="{
                    'is-invalid': eif.submitted && refTenure.invalid,
                    'alert-warning':
                      refTenure.invalid &&
                      (refTenure.dirty ||
                        refTenure.touched ||
                        refTenure.untouched)
                  }"
                       name="Tenure"
                       id="Tenure"
                       class="form-control input-text-css" />
              </div>
              <div class="col-md-3 p-1 mb-3"
                   [hidden]="
                  cEIModel.EMI_Type == 'Daily' ||
                  cEIModel.EMI_Type == 'Weekly' ||
                  cEIModel.EMI_Type == 'Fortnightly' ||
                  cEIModel.EMI_Type == 'ByWeekly' ||
                  cEIModel.EMI_Type == 'FourthWeekly' ||
                  cEIModel.EMI_Type == 'SingleEMI'
                ">
                <span> Instl In Adv. </span>
                <input type="text"
                       numbersOnly
                       required
                       #refAdv_Inst="ngModel"
                       [(ngModel)]="cEIModel.Adv_Inst"
                       placeholder="Instl In Adv."
                       (blur)="setAdvanceInstl()"
                       [ngClass]="{
                    'is-invalid': eif.submitted && refAdv_Inst.invalid,
                    'alert-warning':
                      refAdv_Inst.invalid &&
                      (refAdv_Inst.dirty ||
                        refAdv_Inst.touched ||
                        refAdv_Inst.untouched)
                  }"
                       name="Adv_Inst"
                       id="Adv_Inst"
                       class="form-control input-text-css" />
              </div>
              <div class="col-md-3 p-1"
                   *ngIf="cEIModel.EMI_Type == 'SingleEMI'">
                <span class="required-lable">Loan Date</span>
                <div class="datepicker_feild">
                  <input [matDatepicker]="picker"
                         dateConvert
                         placeholder="DD/MM/YYYY"
                         name="Loan_Date"
                         id="Loan_Date"
                         *ngIf="cEIModel.EMI_Type == 'SingleEMI'"
                         (dateChange)="changeLoan_Date()"
                         required
                         [min]="minDate"
                         #refLoan_Date="ngModel"
                         class="form-control input-text-css"
                         [(ngModel)]="cEIModel.Loan_Date"
                         [ngClass]="{
                      'is-invalid': eif.submitted && refLoan_Date.invalid,
                      'alert-warning':
                        refLoan_Date.invalid &&
                        (refLoan_Date.dirty ||
                          refLoan_Date.touched ||
                          refLoan_Date.untouched)
                    }" />
                  <mat-datepicker-toggle matSuffix
                                         [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
              </div>
              <div class="col-md-3 p-1"
                   *ngIf="cEIModel.EMI_Type == 'SingleEMI'">
                <span class="required-lable">Loan Maturity Date</span>
                <div class="datepicker_feild">
                  <input [matDatepicker]="picker1"
                         [min]="minLoanMaturityDate"
                         *ngIf="cEIModel.EMI_Type == 'SingleEMI'"
                         dateConvert
                         (dateChange)="DaysDifference()"
                         name="LoanMaturityDate"
                         id="LoanMaturityDate"
                         required
                         placeholder="DD/MM/YYYY"
                         #refLoanMaturityDate="ngModel"
                         [ngClass]="{
                      'is-invalid':
                        eif.submitted && refLoanMaturityDate.invalid,
                      'alert-warning':
                        refLoanMaturityDate.invalid &&
                        (refLoanMaturityDate.dirty ||
                          refLoanMaturityDate.touched ||
                          refLoanMaturityDate.untouched)
                    }"
                         class="form-control input-text-css"
                         [(ngModel)]="cEIModel.LoanMaturityDate" />
                  <mat-datepicker-toggle matSuffix
                                         [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </div>
              </div>

              <div class="col-md-2 mb-3"
                   *ngIf="cEIModel.EMI_Type == 'SingleEMI'">
                <span>Processing Fee % </span>
                <input type="text"
                       #refProcessingFee="ngModel"
                       [(ngModel)]="cEIModel.ProcessingFee"
                       placeholder="Processing Fee"
                       [ngClass]="{
      'is-invalid': eif.submitted && refProcessingFee.invalid,
      'alert-warning':
        refProcessingFee.invalid &&
        (refProcessingFee.dirty ||
          refProcessingFee.touched ||
          refProcessingFee.untouched)
    }"
                       name="ProcessingFee"
                       id="ProcessingFee"
                       class="form-control input-text-css" />
              </div>
              <!-- <div class="col-md-12 mb-3" *ngIf="cEIModel.IRR_CalculateBy == 'STEP_EMI'"> -->
              <div class="col-md-12 mb-3" *ngIf="stepEmiForm">
                <div class="row m-0 mt-3 justify-content-center">
                  <div class="table-border">
                    <div class="item-row header">
                      <div class="lead w50 fs-14">#</div>
                      <div class="lead w120 fs-14">From EMI</div>
                      <div class="lead w120 fs-14">To EMI</div>
                      <div class="lead fs-14">EMI Amount</div>
                      <div class="lead w120 fs-14">No of EMI</div>
                      <div class="lead w150 fs-14">Total Amount</div>
                    </div>
                    <div class="item-row"
                         *ngFor="let semi of stepEMI; let i = index">
                      <div class="w50">{{ i + 1 }}</div>
                      <div class="w120">
                        <input type="text"
                               numbersOnly
                               readonly
                               [(ngModel)]="semi.FromEMI"
                               placeholder="From EMI"
                               name="FromEMI{{ i }}"
                               id="FromEMI{{ i }}"
                               class="form-control input-text-css" />
                      </div>
                      <div class="w120">
                        <input type="text"
                               numbersOnly
                               [disabled]="semi.disable"
                               [(ngModel)]="semi.ToEMI"
                               placeholder="To EMI"
                               name="ToEMI{{ i }}"
                               id="ToEMI{{ i }}"
                               class="form-control input-text-css" />
                      </div>
                      <div class="">
                        <input type="text"
                               numbersOnly
                               [disabled]="semi.disable"
                               [(ngModel)]="semi.EMI_Amount"
                               placeholder="EMI Amount"
                               name="EMI_Amount{{ i }}"
                               id="EMI_Amount{{ i }}"
                               class="form-control input-text-css mr-3 emi-amount" />
                        <button type="button"
                                *ngIf="!semi.disable"
                                class="primary-btn"
                                (click)="addNextStep(semi)">
                          <i class="fa fa-check-circle"
                             style="font-size: 18px"></i>
                        </button>
                        <button type="button"
                                class="primary-btn"
                                style="margin-left: 10px"
                                *ngIf="!semi.disable && i > 0"
                                (click)="removeLastStep(i)">
                          <i class="fa fa-minus-circle"
                             style="font-size: 18px"></i>
                        </button>
                      </div>
                      <div class="w120">{{ semi.NoOfInstl }}&nbsp;</div>
                      <div class="w150">{{ semi.TotalAmount }}&nbsp;</div>
                    </div>
                    <div class="item-row header" *ngIf="stepEmiData">
                      <div class="lead w50 fs-14">&nbsp;</div>
                      <div class="lead w120 fs-14"><b>Total</b></div>
                      <div class="lead w120 fs-14">&nbsp;</div>
                      <div class="lead fs-14">&nbsp;</div>
                      <div class="lead w120 fs-14">
                        <b>{{ stepEmiData.totalEmi }}</b>
                      </div>
                      <div class="lead w150 fs-14">
                        <b>{{ stepEmiData.totalAmount }}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-0 col-md-12 justify-content-center">
              <button
                type="button"
                (click)="onCalculateEmiAndIRR()"
                class="pl-5 pr-5 btn font-size-12 button-btn"
                [class.spinner]="loading"
                [disabled]="!eif.form.valid"
              >
                Calculate EMI and IRR
              </button>
            </div>
            <div
              class="row col-md-12 m-0 p-0 mt-3"
              id="scrollto"
              *ngIf="emiIrr"
            >
              <div class="col-md-3">
                <h6 class="fs-12">Disbursement Amount</h6>
                <p class="lead fs-12">
                  ₹ {{ emiIrr.Disbursement_Amt.toFixed(2) }}
                </p>
              </div>
              <div class="col-md-3">
                <h6 class="fs-12">Interest Amount</h6>
                <p class="lead fs-12">₹ {{ emiIrr.Interest_Amt.toFixed(2) }}</p>
              </div>
              <div class="col-md-3">
                <h6 class="fs-12">Agreement Value</h6>
                <p class="lead fs-12">
                  ₹ {{ emiIrr.Agreement_Value.toFixed(2) }}
                </p>
              </div>
              <div class="row m-0 pl-0 pr-0 col-md-3">
                <div
                  class="col-md-6 pr-1"
                  *ngIf="cEIModel.EMI_Type != 'SingleEMI'"
                >
                  <h6 class="fs-12">LTV</h6>
                  <p class="lead fs-12">{{ emiIrr.LTV.toFixed(2) }} %</p>
                </div>
                <div class="col-md-6 pl-1">
                  <h6 class="fs-12">ROI</h6>
                  <p class="lead fs-12">{{ emiIrr.ROI.toFixed(2) }} %</p>
                </div>
              </div>
              <div class="col-md-3">
                <h6 class="fs-12">EMI Amount</h6>
                <p class="lead fs-12">₹ {{ emiIrr.EMI_Amt.toFixed(2) }}</p>
              </div>
              <div class="col-md-3" *ngIf="cEIModel.EMI_Type != 'SingleEMI'">
                <h6 class="fs-12">Case IRR</h6>
                <p class="lead fs-12">{{ emiIrr.Case_IRR.toFixed(2) }} %</p>
              </div>
              <div
                class="col-md-3"
                [hidden]="
                  cEIModel.EMI_Type == 'Daily' ||
                  cEIModel.EMI_Type == 'Weekly' ||
                  cEIModel.EMI_Type == 'Fortnightly' ||
                  cEIModel.EMI_Type == 'ByWeekly' ||
                  cEIModel.EMI_Type == 'FourthWeekly' ||
                  cEIModel.EMI_Type == 'SingleEMI'
                "
              >
                <h6 class="fs-12">Disbursement IRR</h6>
                <p class="lead fs-12">
                  {{ emiIrr.Disbursement_IRR.toFixed(2) }} %
                </p>
              </div>
              <div
                class="col-md-3"
                [hidden]="
                  loanSummary.Product_Category == 'Un-Secured Loan' ||
                  cEIModel.EMI_Type == 'SingleEMI'
                "
              >
                <h6 class="fs-12">Margin</h6>
                <p class="lead fs-12">₹ {{ emiIrr.Margin.toFixed(2) }}</p>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
      <div class="formborder">
        <ng-container *ngIf="isCalEMIForm">
          <!--<p (click)="EmiCalculator()" style="color: #fff !important;background-color: #4dc3a3 !important;text-align: right;"><a>Add Loan Emi</a></p>-->
          <div
            class="row m-0 h-b w-b align-items-center justify-content-between"
            style="width: 100%"
          >
            <h1 class="fs-12 m-0">Loan Financial Details</h1>
            <!--<app-lms-edit-financial-detail-model [isEdit]="true" [applicationId]="applicationId" (close)="OnFinancialDetailClose($event)">
            </app-lms-edit-financial-detail-model>-->
            <button
              type="button"
              style="background-color: rgb(193 209 238 / 57%) !important"
              class="btn font-size-12 button-btn font-size-12"
              *ngIf="loanSummary.IsGenerateAmortization != true &&   PageAcess && PageAcess !== undefined &&  PageAcess !== '' && PageAcess[0].Edit == 1 "
              (click)="onEditFinanceDetail()"
            >
              <!--<i class="fa fa-eye" style="font-size: medium; cursor: pointer"></i>-->
              <div>
                <i
                  class="fa fa-edit ml-2"
                  style="color: black; font-size: 13px"
                ></i>
              </div>
            </button>
          </div>

          <div class="row m-0 mt-3">
            <div class="col-md-2"
                 *ngIf="loanSummary.Product_Category != 'Single Installment'">
              <h6 class="fs-12">Asset Cost</h6>
              <p class="lead fs-12 fw-7">₹ {{ applicationDetail.AssetCost }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Net Finance</h6>
              <p class="lead fs-12">₹ {{ applicationDetail.NetFinance }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">ROI</h6>
              <p class="lead fs-12">{{ applicationDetail.Flat_Rate }}%</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Tenure ({{ applicationDetail.Name }})</h6>
              <p class="lead fs-12">
                {{ applicationDetail.Tenure }}({{ applicationDetail.Name }})
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">No of Installment</h6>
              <p class="lead fs-12">{{ applicationDetail.No_Of_Instl }}</p>
            </div>
            <div class="col-md-2"
                 *ngIf="loanSummary.Product_Category != 'Single Installment'">
              <h6 class="fs-12">Adv. Installment</h6>
              <p class="lead fs-12">{{ applicationDetail.Adv_Instl }}</p>
            </div>
            <!-- <div class="col-md-2">
    <h6 class="fs-12">Management Fee</h6>
    <p class="lead fs-12">₹ {{ applicationDetail.ManagementFee }}</p>
  </div> -->
            <div class="col-md-2">
              <h6 class="fs-12">Disbursement Amount</h6>
              <p class="lead fs-12">
                ₹ {{ applicationDetail.DisbursementAmt }}
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Agreemnent Value</h6>
              <p class="lead fs-12">₹ {{ applicationDetail.AgreementValue }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Interest Amount</h6>
              <p class="lead fs-12">₹ {{ applicationDetail.InterestAmt }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">EMI Amount</h6>
              <p class="lead fs-12">₹ {{ applicationDetail.EMIAmount }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">EMI Type</h6>
              <p class="lead fs-12">{{ applicationDetail.EMI_Type }}</p>
            </div>
            <div class="col-md-2"
                 [hidden]="
                applicationDetail.EMI_Type == 'Daily' ||
                applicationDetail.EMI_Type == 'Weekly' ||
                applicationDetail.EMI_Type == 'Fortnightly' ||
                cEIModel.EMI_Type == 'ByWeekly' ||
                cEIModel.EMI_Type == 'FourthWeekly'
              ">
              <h6 class="fs-12">Case IRR</h6>
              <p class="lead fs-12">
                {{ applicationDetail.Case_IRR.toFixed(2) }}%
              </p>
            </div>
            <div class="col-md-2"
                 [hidden]="
                applicationDetail.EMI_Type == 'Daily' ||
                applicationDetail.EMI_Type == 'Weekly' ||
                applicationDetail.EMI_Type == 'Fortnightly' ||
                cEIModel.EMI_Type == 'ByWeekly' ||
                cEIModel.EMI_Type == 'FourthWeekly'
              ">
              <h6 class="fs-12">Disbursement IRR</h6>
              <p class="lead fs-12">
                {{ applicationDetail.Disbursement_IRR.toFixed(2) }}%
              </p>
            </div>
            <div class="col-md-2"
                 *ngIf="applicationDetail.EMI_Type != 'SingleEMI'">
              <h6 class="fs-12">LTV</h6>
              <p class="lead fs-12">{{ applicationDetail.LTV }}%</p>
            </div>
            <div class="col-md-2"
                 *ngIf="applicationDetail.EMI_Type != 'SingleEMI'"
                 [hidden]="loanSummary.Product_Category == 'Un-Secured Loan'">
              <h6 class="fs-12">Margin</h6>
              <p class="lead fs-12">
                ₹
                {{
                  applicationDetail.Margin < 0 ? 0 : applicationDetail.Margin
                }}
              </p>
            </div>
            <!-- <div class="col-md-2">
    <h6 class="fs-12">First EMI Date</h6>
    <p class="lead fs-12">{{ applicationDetail.FirstEMIDate }}</p>
  </div> -->
            <div class="col-md-2">
              <h6 class="fs-12">Expiry Date</h6>
              <p class="lead fs-12">{{ applicationDetail.ExpiryDate }}</p>
            </div>

            <div class="col-md-2">
              <h6 class="fs-12">Effective IRR</h6>
              <p class="lead fs-12">
                {{
                  applicationDetail.effective_Irr == null
                    ? ""
                    : (applicationDetail.effective_Irr | number : "1.2-2") + "%"
                }}
              </p>
            </div>
            <div class="col-md-2" *ngIf="applicationDetail.EMI_Type == 'SingleEMI'">
              <h6 class="fs-12">Processing Fee %</h6>
              <p class="lead fs-12">
                {{
       applicationDetail.ProcessingFee == null
         ? ""
         : (applicationDetail.ProcessingFee | number : "1.2-2") + "%"
                }}
              </p>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="row col-md-12 m-0 p-1 mt-3" id="scrollto">
        <h6 name="myModalLabel">Receipt & Deduction Details</h6>
      </div>

      <div class="tab12 row m-0 p-1" style="width: 100%">
        <div
          style="border: 1px solid #ddd; position: unset"
          class="m-0 col-md-6 pt-2 pb-2 align-items-center justify-content-center bdr"
          (click)="changeRDTab('list')"
          [ngClass]="{ active: tab == 'list' }"
        >
          Receipt & Deduction List
        </div>
        <div
          style="border: 1px solid #ddd; position: unset"
          [ngClass]="{ active: tab == 'new' }"
          class="m-0 col-md-6 pt-2 pb-2 align-items-center justify-content-center bdr"
          (click)="changeRDTab('new')"
          *ngIf="loanSummary.IsLoanNo != true"
        >
          Add New Deduction
        </div>
      </div>

      <div
        class="row m-0 col-md-12 p-1"
        *ngIf="tab == 'list'"
        style="min-height: 120px"
      >
        <div class="table-responsive mt-3">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col" style="display: none">Id</th>
                <th scope="col" style="display: none">LoanId</th>
                <th scope="col" style="width: 152px">Type</th>
                <th scope="col">ApplicationNo</th>
                <th scope="col">Charges Head</th>
                <th scope="col">Account</th>
                <th scope="col">Tax (%)</th>
                <th scope="col" style="width: 125px">Amount</th>
                <th scope="col">CollectionOn</th>
                <th scope="col">CollectionBy</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of ReceiptDeduction; let i = index">
                <td>{{ i + 1 }}</td>
                <td style="display: none">{{ data.Id }}</td>
                <td style="display: none">{{ data.LoanId }}</td>
                <td>{{ data.Type }}</td>
                <td>{{ data.ApplicationNo }}</td>
                <td>{{ data.ChagesHead }}</td>
                <td>{{ data.Account }}</td>
                <td>{{ data.TaxPercentage }}</td>
                <td>₹ {{ data.Amount }}</td>
                <td>{{ data.CollectionOn }}</td>
                <td>{{ data.CollectionBy }}</td>
                <td>
                  <i
                    class="fa fa-arrow-circle-right arrow-circle action-btn"
                    aria-label="true"
                    (click)="Get_ReceiptDeduction(data)"
                  ></i>
                  <i
                    *ngIf="
                      data.Type != 'Receipt' &&
                      !data.IsTax &&
                      loanSummary.IsLoanNo != true
                    "
                    class="fa fa-trash style-delete ml-3"
                    (click)="removeReceiptDeduction(data)"
                    style="cursor: pointer"
                    aria-label="true"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        class="row m-0 col-md-12 p-1"
        *ngIf="tab == 'new'"
        style="min-height: 120px"
      >
        <form #df="ngForm" class="row m-0 col-md-12 p-0 formborder" novalidate>
          <div class="row m-0 col-md-12 p-0 mt-3 add-remove">
            <div class="col-md-12 p-0 row m-0 type-column">
              <div class="row m-0 col-md-12 p-0 feild">
                <ng-container>
                  <div class="col-md-2 pr-0">
                    <span class="required-lable">Search</span>
                    <div class="input-btn">
                      <input
                        required
                        type="text"
                        readonly
                        name="CaseNo"
                        id="CaseNo"
                        [(ngModel)]="Voucher_Detail.CaseNo"
                        class="form-control input-text-css"
                        (keyup.enter)="searchCustomer()"
                      />
                      <button
                        type="button"
                        (click)="searchCustomer()"
                        class="primary-btn ml-2 search"
                      >
                        <i
                          class="fa fa-search"
                          aria-hidden="true"
                          style="font-size: 11px"
                        ></i>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-2 pr-0">
                    <span>Customer</span>
                    <input
                      type="text"
                      name="Customer"
                      id="Customer"
                      class="form-control input-text-css"
                      readonly
                      [(ngModel)]="Voucher_Detail.Customer"
                    />
                  </div>
                  <div class="col-md-2 pr-0">
                    <span>Branch</span>
                    <input
                      type="text"
                      name="Branch_Name"
                      id="Branch_Name"
                      class="form-control input-text-css"
                      readonly
                      [(ngModel)]="Voucher_Detail.Branch_Name"
                    />
                  </div>
                  <div class="col-md-2 pr-0">
                    <span class="required-lable">Category</span>
                    <select
                      name="ChargeHeadId"
                      id="ChargeHeadId"
                      required
                      #refChargeHeadId="ngModel"
                      [ngClass]="{
                        'is-invalid': df.submitted && refChargeHeadId.invalid,
                        'alert-warning':
                          refChargeHeadId.invalid &&
                          (refChargeHeadId.dirty ||
                            refChargeHeadId.touched ||
                            refChargeHeadId.untouched)
                      }"
                      [(ngModel)]="Voucher_Detail.ChargeHeadId"
                      class="form-control input-text-css"
                      (change)="onChangeChargesHead(Voucher_Detail)"
                      [disabled]="!Voucher_Detail.ProductId"
                    >
                      <option value="">Select Category</option>
                      <option
                        *ngFor="let item of chargesHeadDropdown"
                        [value]="item.Id"
                      >
                        {{ item.ChagesHead }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2 pr-0">
                    <span> Account Head</span>
                    <input
                      type="text"
                      name="ChargeHeadCategory"
                      id="ChargeHeadCategory"
                      class="form-control input-text-css"
                      readonly
                      [(ngModel)]="Voucher_Detail.ChargeHeadCategory"
                    />
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">Amount</span>
                    <input
                      type="number"
                      min="0"
                      name="Amount"
                      id="Amount"
                      [disabled]="!Voucher_Detail.ChargeHeadId"
                      class="form-control input-text-css text-right pr-3"
                      required
                      #refAmount="ngModel"
                      (change)="onChangeAmount($event, Voucher_Detail)"
                      (keyup.enter)="onChangeAmount($event, Voucher_Detail)"
                      [ngClass]="{
                        'is-invalid': df.submitted && refAmount.invalid,
                        'alert-warning':
                          refAmount.invalid &&
                          (refAmount.dirty ||
                            refAmount.touched ||
                            refAmount.untouched)
                      }"
                      [(ngModel)]="Voucher_Detail.Amount"
                    />
                  </div>
                </ng-container>
              </div>
            </div>
            <ng-container
              *ngIf="
                Voucher_Detail.TaxSlab && Voucher_Detail.TaxSlab.length > 0
              "
            >
              <div
                class="col-md-12 row m-0 p-0 mt-2"
                *ngFor="let taxData of Voucher_Detail.TaxSlab; let ti = index"
              >
                <div class="col-md-2 pr-0">
                  <span><b>Slab :</b>&nbsp;{{ taxData.SlabName }}</span>
                  <!-- <input type="text" readonly name="SlabName{{ i }}{{ti}}" id="SlabName{{ i }}{{ti}}"
                    [(ngModel)]="taxData.SlabName" class="form-control input-text-css" /> -->
                </div>
                <div class="col-md-3 pr-0">
                  <span><b>Type :</b>&nbsp;{{ taxData.TaxType }} </span>
                  <!-- <input type="text" readonly name="TaxType{{ i }}{{ti}}" id="TaxType{{ i }}{{ti}}"
                    [(ngModel)]="taxData.TaxType" class="form-control input-text-css" /> -->
                </div>
                <div class="col-md-2 pr-0">
                  <span><b>Rate :</b>&nbsp;{{ taxData.TaxRate }} %</span>
                  <!-- <input type="text" readonly name="TaxRate{{ i }}{{ti}}" id="TaxRate{{ i }}{{ti}}"
                    [(ngModel)]="taxData.TaxRate" class="form-control input-text-css" /> -->
                </div>
                <div class="col-md-3 pr-0">
                  <span>
                    <b>Account Head :</b>&nbsp;{{ taxData.TaxAccountName }}
                  </span>
                  <!-- <input type="text" readonly name="TaxAccountName{{ i }}{{ti}}" id="TaxAccountName{{ i }}{{ti}}"
                    [(ngModel)]="taxData.TaxAccountName" class="form-control input-text-css" /> -->
                </div>
                <div class="col-md-2 text-right pr-5">
                  <!--<span><b></b>&nbsp;<ng-container *ngIf="taxData.TaxAmount">{{taxData.TaxAmount}}</ng-container></span>-->
                  <!-- <input type="text" *ngIf="taxData.TaxAmount"  name="TaxAmount{{ i }}{{ti}}" id="TaxAmount{{ i }}{{ti}}"
                    [(ngModel)]="taxData.TaxAmount" class="form-control input-text-css" (change)="getTotalCalculation()" /> -->
                  <input
                    type="text"
                    *ngIf="taxData.TaxAmount"
                    name="TaxAmount{{ ti }}"
                    id="TaxAmount{{ ti }}"
                    [(ngModel)]="taxData.TaxAmount"
                    class="form-control input-text-css"
                    (change)="getTotalCalculation()"
                  />
                </div>
              </div>
            </ng-container>

            <!-- <button type="button" class="remove" (click)="removeData(i)" [ngClass]="{'no-head':i!=0}"*ngIf="voucherModel.Voucher_Detail.length>1">
              -
            </button>
            <button type="button" class="addmore" (click)="addMoreData()" *ngIf="i == voucherModel.Voucher_Detail.length-1"
              [ngClass]="{'both':voucherModel.Voucher_Detail.length>1,'no-head':i!=0}" [disabled]="!df.form.valid">
              +
            </button> -->
          </div>
          <div class="col-md-12 row m-0 p-0 pr-4 mt-2" *ngIf="totalCalculator">
            <div class="col-md-10 pr-0">
              <span><b>Grand Total :</b></span>
            </div>
            <!-- <div class="col-md-5  pr-0">
              <span><b>In Word :</b>&nbsp;{{totalCalculator.totalInWord}} </span>
            </div> -->
            <div class="col-md-2 text-right pr-5">
              <span>
                <b>In Digit :</b>&nbsp;{{ totalCalculator.total.toFixed(2) }}
              </span>
            </div>
          </div>
          <div
            class="row m-0 mt-3 col-md-12 p-0 justify-content-end"
            *ngIf="PageAcess && PageAcess[0].Add == 1"
          >
            <button
              type="button"
              (click)="changeRDTab('list')"
              class="btn btn-light font-size-12 mr-3"
            >
              Cancel
            </button>
            <button
              type="button"
              (click)="onSaveRdDetail()"
              [class.spinner]="loading"
              [disabled]="!df.form.valid"
              class="btn font-size-12 button-btn"
            >
              Save
            </button>
            <!-- <button type="button" (click)="onUpdateRdDetail()" *ngIf="!inViewMode" [hidden]="!IsReceiptUpdate"
              style="background-color: #28a745;color: #FFFFFF;font-size: 12px;" mat-raised-button color="primary"
              [class.spinner]="loading" [disabled]="!df.form.valid">
              Update
            </button> -->
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="row m-0 mt-0">
  <div class="row col-md-12 m-0 mt-3 justify-content-end">
    <button
      type="button"
      (click)="onClose()"
      [hidden]="!IsCloseBtn"
      class="btn font-size-12 button-btn"
      data-dismiss="modal"
    >
      Cancel
    </button>
    <button
      type="button"
      (click)="onSaveApplication()"
      class="ml-4 btn font-size-12 button-btn"
      [class.spinner]="loading"
    >
      <!-- *ngIf="emiIrr" -->
      Next <i class="fa fa-arrow-right"></i>
    </button>
  </div>
</div>

<div
  class="modal fade in"
  id="CustomerReceipt"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Search
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseSearch()"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 mt-3">
          <form
            #f="ngForm"
            (ngSubmit)="onSearchCustomerDetail()"
            novalidate
            class="row m-0 col-md-12 p-0"
          >
            <div class="row m-0 col-md-12 p-0 formborder">
              <div class="col-md-3">
                <span>Type</span>
                <select
                  name="Type"
                  id="Type"
                  class="form-control input-text-css"
                  [(ngModel)]="Type"
                >
                  <option [value]="0">All</option>
                  <option [value]="1">LOS Only</option>
                  <option [value]="2">LMS Only</option>
                </select>
              </div>
              <div class="col-md-3">
                <span>Application No.</span>
                <input
                  name="SApplicationNo"
                  [(ngModel)]="Customer.ApplicationNo"
                  id="SApplicationNo"
                  placeholder="Application No."
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3">
                <span>Loan No.</span>
                <input
                  name="SLoanNo"
                  [(ngModel)]="Customer.LoanNo"
                  id="SLoanNo"
                  placeholder="Loan No."
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3">
                <span>Customer Name</span>
                <input
                  name="CustomerName"
                  [(ngModel)]="Customer.CustomerName"
                  id="CustomerName"
                  placeholder="Customer Name"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3">
                <span>Customer Phone</span>
                <input
                  name="CustomerPhone"
                  [(ngModel)]="Customer.CustomerPhone"
                  id="CustomerPhone"
                  placeholder="Customer Phone"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3">
                <span>PAN Card</span>
                <input
                  name="PANCard"
                  [(ngModel)]="Customer.PANCard"
                  id="PANCard"
                  placeholder="PAN Card"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3">
                <span>Passport No.</span>
                <input
                  name="Passport"
                  [(ngModel)]="Customer.Passport"
                  id="Passport"
                  placeholder="Passport No."
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3">
                <span>Voter ID Card</span>
                <input
                  name="VoterID"
                  [(ngModel)]="Customer.VoterID"
                  id="VoterID"
                  placeholder="Voter ID Card"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3">
                <span>Driving License</span>
                <input
                  name="DL"
                  [(ngModel)]="Customer.DL"
                  id="DL"
                  placeholder="Driving License"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-3">
                <span>Aadhaar Card</span>
                <input
                  name="Aadhaar"
                  [(ngModel)]="Customer.Aadhaar"
                  id="Aadhaar"
                  placeholder="Aadhaar Card"
                  class="form-control input-text-css"
                />
              </div>
            </div>
            <div class="row m-0 col-md-12 p-0 justify-content-end">
              <button
                type="button"
                (click)="onSearchCustomerDetail()"
                class="btn font-size-12 button-btn"
              >
                Search
              </button>
            </div>
          </form>
          <div class="table-responsive mt-3">
            <mat-table
              [dataSource]="dataSourceCustomer"
              #sortList="matSort"
              matSort
              id="exportReport"
            >
              <!--<ng-container matColumnDef="CustomerId">
                  <mat-header-cell mat-sort-header class="grid-header" style="max-width: 50px" *matHeaderCellDef>#</mat-header-cell>
                  <mat-cell *matCellDef="let row; let i = index" class="grid-cell" style="max-width: 50px; max-height: 5px">
                    {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
                  </mat-cell>
                </ng-container>-->

              <ng-container matColumnDef="Type">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header text-center"
                  style="max-width: 150px"
                  *matHeaderCellDef
                >
                  Type
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell text-center"
                  style="max-width: 150px"
                >
                  {{ row.Type }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Application_No">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header text-center"
                  style="max-width: 150px"
                  *matHeaderCellDef
                >
                  Aplication No
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell text-center"
                  style="max-width: 150px"
                >
                  {{ row.Application_No }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="LoanAcNo">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header text-center"
                  style="max-width: 150px"
                  *matHeaderCellDef
                >
                  Loan No
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell text-center"
                  style="max-width: 150px"
                  >{{ row.LoanAcNo }}</mat-cell
                >
              </ng-container>

              <ng-container matColumnDef="CustomertName">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Customer</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell"
                  >{{ row.CustomertName }}({{ row.AC_CustomerType }})</mat-cell
                >
              </ng-container>

              <ng-container matColumnDef="FatherName">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Relation Name</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.FatherName }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Customer_Gender">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  style="max-width: 50px"
                  *matHeaderCellDef
                  >Gender</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell"
                  style="max-width: 50px"
                  >{{ row.Customer_Gender }}</mat-cell
                >
              </ng-container>

              <ng-container matColumnDef="GenderAge">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  style="max-width: 50px"
                  *matHeaderCellDef
                  >Age</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell"
                  style="max-width: 50px"
                  >{{ row.GenderAge }}</mat-cell
                >
              </ng-container>

              <ng-container matColumnDef="PhoneNo">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header text-center"
                  style="max-width: 150px"
                  *matHeaderCellDef
                >
                  Phone No
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell text-center"
                  style="max-width: 150px"
                >
                  {{ row.PhoneNo }}
                  <i
                    *ngIf="row.Customer_PhoneNo_IsVerified == true"
                    class="fa fa-check-circle ml-2"
                    style="color: green"
                  ></i>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="EditAction">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  style="max-width: 100px; justify-content: center"
                  *matHeaderCellDef
                >
                  View Detail
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell j-c-center"
                  style="max-width: 60px"
                >
                  <i
                    class="fa fa-arrow-circle-right"
                    (click)="selectSearchData(row)"
                    style="cursor: pointer; color: green; font-size: 16px"
                    aria-label="true"
                  ></i>
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedCustomerSearchColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedCustomerSearchColumns"
              ></mat-row>
            </mat-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
