<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="OnSearchMaterReportList()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-3">
        <span class="required-lable">From Date</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="FromDate"
            id="FromDate"
            class="form-control input-text-css"
            [(ngModel)]="FromDate"
            required
            #refFromDate="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refFromDate.invalid,
              'alert-warning':
                refFromDate.invalid &&
                (refFromDate.dirty ||
                  refFromDate.touched ||
                  refFromDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="FromDate"
          [(ngModel)]="FromDate"
          id="FromDate"
          placeholder="From Date"
          class="form-control input-text-css"
          bsDatepicker
          required
          #refFromDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refFromDate.invalid,
            'alert-warning':
              refFromDate.invalid &&
              (refFromDate.dirty ||
                refFromDate.touched ||
                refFromDate.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-3">
        <span class="required-lable">To Date</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ToDate"
            id="ToDate"
            class="form-control input-text-css"
            [(ngModel)]="ToDate"
            required
            #refToDate="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refToDate.invalid,
              'alert-warning':
                refToDate.invalid &&
                (refToDate.dirty || refToDate.touched || refToDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="ToDate"
          [(ngModel)]="ToDate"
          id="ToDate"
          placeholder="To Date"
          class="form-control input-text-css"
          bsDatepicker
          required
          #refToDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refToDate.invalid,
            'alert-warning':
              refToDate.invalid &&
              (refToDate.dirty || refToDate.touched || refToDate.untouched)
          }"
        /> -->
      </div>

      <div class="col-md-3">
        <span>Select Branch</span>
        <select
          name="BranchId"
          id="BranchId"
          (change)="GetEmployeeList()"
          [(ngModel)]="BranchId"
          class="form-control input-text-css"
        >
          <option value="0">Select Branch</option>
          <option *ngFor="let item of BranchesList" [value]="item.BranchId">
            {{ item.Branch_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-3">
        <span> Select Executive </span>
        <select
          name="ExecutiveId"
          id="ExecutiveId"
          [(ngModel)]="ExecutiveId"
          class="form-control input-text-css"
        >
          <option value="0">Select Executive</option>
          <option *ngFor="let emp of GetEmployeeDropdown" [value]="emp.EmpId">
            {{ emp.EmpName }}
          </option>
        </select>
      </div>

      <div class="col-md-3">
        <span> Source Type </span>
        <select
          name="SelectSourceType"
          id="SelectSourceType"
          #refSelectSourceType="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refSelectSourceType.invalid,
            'alert-warning':
              refSelectSourceType.invalid &&
              (refSelectSourceType.dirty ||
                refSelectSourceType.touched ||
                refSelectSourceType.untouched)
          }"
          (change)="Get_PartnerForDropdown()"
          [(ngModel)]="SelectSourceType"
          class="form-control input-text-css"
        >
          <option value="">Select Source Type</option>
          <option
            *ngFor="let lead of leadSourceDropdown"
            [value]="lead.Lead_Source"
          >
            {{ lead.Lead_Source }}
          </option>
        </select>
      </div>

      <div class="col-md-3">
        <div
          *ngIf="
            SelectSourceType == 'Agent' ||
            SelectSourceType == 'DSA' ||
            SelectSourceType == 'Dealer' ||
            SelectSourceType == 'Sales Executive' ||
            SelectSourceType == 'Telecaller Executive' ||
            SelectSourceType == 'Marketing Executive'
          "
        >
          <span> Source Name</span>
          <select
            name="LeadSourceName"
            id="LeadSourceName"
            #refLeadSourceName="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refLeadSourceName.invalid,
              'alert-warning':
                refLeadSourceName.invalid &&
                (refLeadSourceName.dirty ||
                  refLeadSourceName.touched ||
                  refLeadSourceName.untouched)
            }"
            [(ngModel)]="LeadSourceName"
            class="form-control input-text-css"
          >
            <option value="">Select Lead Reference</option>
            <option
              *ngFor="let partner of partnerDropdown"
              [value]="partner.PartnerId"
            >
              {{ partner.Partner_Name }}
            </option>
          </select>
        </div>
      </div>
      <div class="mt-3 col-md-6 text-right">
        <button
          type="button"
          (click)="OnSearchMaterReportList()"
          class="btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
          style="float: right"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="table-responsive mt-3" *ngIf="DataSource">
    <mat-table
      [dataSource]="DataSource"
      matSort
      matTableExporter
      #exporter="matTableExporter"
      style="max-width: 100%; overflow: auto"
    >
      <ng-container matColumnDef="ProcessId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          *matHeaderCellDef
          style="max-width: 50px"
          >#</mat-header-cell
        >
        <mat-cell *matCellDef="let row; let i = index" class="grid-cell mw50">
          {{ i + 1 }}
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          class="grid-header"
        ></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Process_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Process Name</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">{{
          row.Process_Name
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header text-left"
          >Total</mat-footer-cell
        >
      </ng-container>

      <ng-container matColumnDef="Process_Opening">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Opening</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          <div *ngIf="row.Process_Opening > 0; else elseBlock">
            <a
              href="javascript:void(0)"
              (click)="onClickMasterReportDetail(row, 'Opening')"
              ><B>{{ row.Process_Opening }}</B></a
            >
          </div>
          <ng-template #elseBlock>{{ row.Process_Opening }}</ng-template>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header text-left">{{
          totaltypewise("Process_Opening")
        }}</mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Process_Add">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Add</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          <div *ngIf="row.Process_Add > 0; else elseBlock">
            <a
              href="javascript:void(0)"
              (click)="onClickMasterReportDetail(row, 'Add')"
              ><B>{{ row.Process_Add }}</B></a
            >
          </div>
          <ng-template #elseBlock>{{ row.Process_Add }}</ng-template>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header text-left">{{
          totaltypewise("Process_Add")
        }}</mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Process_Reject">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Reject</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          <div *ngIf="row.Process_Reject > 0; else elseBlock">
            <a
              href="javascript:void(0)"
              (click)="onClickMasterReportDetail(row, 'Reject')"
              ><B>{{ row.Process_Reject }}</B></a
            >
          </div>
          <ng-template #elseBlock>{{ row.Process_Reject }}</ng-template>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header text-left">{{
          totaltypewise("Process_Reject")
        }}</mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Process_Completed">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Completed</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          <div *ngIf="row.Process_Completed > 0; else elseBlock">
            <a
              href="javascript:void(0)"
              (click)="onClickMasterReportDetail(row, 'Completed')"
              ><B>{{ row.Process_Completed }}</B></a
            >
          </div>
          <ng-template #elseBlock>{{ row.Process_Completed }}</ng-template>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header text-left">{{
          totaltypewise("Process_Completed")
        }}</mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Process_Pending">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Pending</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          <div *ngIf="row.Process_Pending > 0; else elseBlock">
            <a
              href="javascript:void(0)"
              (click)="onClickMasterReportDetail(row, 'Pending')"
              ><B>{{ row.Process_Pending }}</B></a
            >
          </div>
          <ng-template #elseBlock>{{ row.Process_Pending }}</ng-template>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="grid-header text-left">{{
          totaltypewise("Process_Pending")
        }}</mat-footer-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      <mat-footer-row
        class="sticky-footer fontcolor"
        *matFooterRowDef="displayedColumns; sticky: true"
        style="background: #4dc3a3"
      ></mat-footer-row>
    </mat-table>
    <div class="row m-0 align-items-center" style="background: #fff">
      <div class="col-md-4 p-1 pb-2 pt-2">
        <button
          class="btn font-size-12 button-btn"
          (click)="exportTable(exporter)"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <!-- <div class="col-md-8">
            <mat-paginator #paginatorRef [pageSizeOptions]="[20,50]" showFirstLastButtons> </mat-paginator>
          </div> -->
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="ViewMasterReportDetail"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Process Report Details of {{ ProcessType }}
        </h6>
        <button
          type="button"
          (click)="onCloseMasterReportDetail()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <mat-table
            [dataSource]="DataSourceDetail"
            matSort
            matTableExporter
            #exporter1="matTableExporter"
            style="height: 400px; max-width: 100%; overflow: auto"
          >
            <ng-container matColumnDef="ApplicationId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                style="max-width: 50px"
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell mw50"
              >
                <!-- {{ (paginatorlist.pageIndex * paginatorlist.pageSize) + (i + 1) }} -->
                {{ i + 1 }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Application_No">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Application No</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Application_No }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Customer">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Customer }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Branch">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Branch</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Branch }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Product">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Product</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Product }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="LoanAmount">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Loan Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.LoanAmount }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="CreateOn">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Create On</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.CreateOn }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Sales_Executive">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Sales Executive</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Sales_Executive }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="SourceType">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Source Type</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.SourceType }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="SourceName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Source Name</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.SourceName }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="TAT_Hr">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >TAT Hr</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.TAT_Hr }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Sales_Status">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Sales Status</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Sales_Status }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ComplotedOn">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Comploted On</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.ComplotedOn }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Score">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Score</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Score }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Remark">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Remark</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Remark }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="LatLong">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                LatLong
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                <a
                  target="_blank"
                  *ngIf="
                    row.LatLong != '' &&
                    row.LatLong != null &&
                    row.LatLong != ','
                  "
                  [href]="'http://maps.google.com/maps?q=' + row.LatLong"
                >
                  <i class="fa-solid fa-location-dot"></i>
                </a>
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayedColumnsDetails; sticky: true"
            ></mat-header-row>
            <mat-row
              (click)="highlightRow(row)"
              [class.highlight]="row.ApplicationId == selectedId"
              *matRowDef="let row; columns: displayedColumnsDetails"
            ></mat-row>
          </mat-table>
          <div class="row m-0 align-items-center" style="background: #fff">
            <div class="col-md-4 p-1 pb-2 pt-2">
              <button
                class="btn font-size-12 button-btn"
                (click)="exportTable1(exporter1, ProcessType)"
              >
                <i
                  class="fa fa-file-export mr-1"
                  style="font-size: medium; cursor: pointer"
                ></i>
                Export
              </button>
            </div>
            <!-- <div class="col-md-8">
              <mat-paginator #paginatorlist [pageSize]="4" [pageSizeOptions]="[4,10,50]" showFirstLastButtons> </mat-paginator>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
