<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form
  #dbf="ngForm"
  (ngSubmit)="onSearchBusinessCollectionSummary()"
  novalidate
  class="formborder"
>
  <div class="row m-0 col-md-12">
    <div class="col-md-2 p-1">
      <span>From Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="FromDate"
          id="FromDate"
          #refFromDate="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="FromDate"
          [ngClass]="{
            'is-invalid': dbf.submitted && refFromDate.invalid,
            'alert-warning':
              refFromDate.invalid &&
              (refFromDate.dirty ||
                refFromDate.touched ||
                refFromDate.untouched)
          }"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>
    <div class="col-md-2 p-1">
      <span>To Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="ToDate"
          id="ToDate"
          #refToDate="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="ToDate"
          [ngClass]="{
            'is-invalid': dbf.submitted && refToDate.invalid,
            'alert-warning':
              refToDate.invalid &&
              (refToDate.dirty || refToDate.touched || refToDate.untouched)
          }"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
    </div>

    <div class="col-md-2 p-1">
      <span class="required-lable">Type:</span>
      <select
        name="executiveType"
        id="executiveType"
        #refexecutiveType="ngModel"
        [ngClass]="{
          'is-invalid': dbf.submitted && refexecutiveType.invalid,
          'alert-warning':
            refexecutiveType.invalid &&
            (refexecutiveType.dirty ||
              refexecutiveType.touched ||
              refexecutiveType.untouched)
        }"
        [(ngModel)]="executiveType"
        class="form-control input-text-css"
        required
      >
        <option value="">Select Executive</option>
        <option value="CollectionExecutive">Collection Executive</option>
        <option value="SalesExecutive ">Sales Executive</option>
      </select>
    </div>

    <div class="col-md-1 p-1 text-right mr-1">
      <button
        type="button"
        (click)="onSearchBusinessCollectionSummary()"
        class="mt-3 btn font-size-12 button-btn"
        [disabled]="!dbf.form.valid"
      >
        Search
      </button>
    </div>
  </div>

  <!-- <div class="col-md-4 mt-2">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div> -->
</form>

<div class="content" *ngIf="showTable">
  <div class="loanInfo my-1 formborder" style="overflow: auto">
    <div class="grid-container" *ngIf="Level1.length > 0">
      <table
        mat-table
        *ngIf="C_Level1"
        [dataSource]="Level1"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef style="width: 130px !important">
            {{ columnNameMapping[columnName] || columnName }}
          </th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [style.display]="C_Level1[iCol][iRow].span === 0 ? 'none' : ''"
          >
            {{ row[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
      </table>
    </div>

    <div class="grid-container" *ngIf="Level2.length > 0">
      <table
        mat-table
        *ngIf="C_Level2"
        [dataSource]="Level2"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>
            {{ columnNameMapping[columnName] || columnName }}
          </th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [attr.rowspan]="C_Level2[iCol][iRow].span"
            [style.display]="C_Level2[iCol][iRow].span === 0 ? 'none' : ''"
          >
            <ng-container *ngIf="columnName === 'Emp_Name'">
              <a
                href="javascript:void(0)"
                (click)="goToEmployeeNameDetail(row)"
              >
                {{ row[columnName] }}
              </a>
            </ng-container>

            <ng-container *ngIf="columnName !== 'Emp_Name'">
              {{ row[columnName] }}
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ Totals_Level2[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
        <tr mat-footer-row *matFooterRowDef="columnNames && showFooterRow"></tr>
      </table>
    </div>

    <div class="grid-container" *ngIf="Level3.length > 0">
      <table
        mat-table
        *ngIf="C_Level3"
        [dataSource]="Level3"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>
            {{ columnNameMapping[columnName] || columnName }}
          </th>

          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [style.display]="C_Level3[iCol][iRow].span === 0 ? 'none' : ''"
          >
            <ng-container *ngIf="columnName === 'Emp_Name'">
              <a
                href="javascript:void(0)"
                (click)="goToEmployeeNameDetail(row)"
              >
                {{ row[columnName] }}
              </a>
            </ng-container>

            <ng-container *ngIf="columnName !== 'Emp_Name'">
              {{ row[columnName] }}
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ Totals_Level3[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
        <tr mat-footer-row *matFooterRowDef="columnNames"></tr>
      </table>
    </div>

    <div class="grid-container" *ngIf="Level4.length > 0">
      <table
        mat-table
        *ngIf="C_Level4"
        [dataSource]="Level4"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>
            {{ columnNameMapping[columnName] || columnName }}
          </th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [attr.rowspan]="C_Level4[iCol][iRow].span"
            [style.display]="C_Level4[iCol][iRow].span === 0 ? 'none' : ''"
          >
            <ng-container *ngIf="columnName === 'Emp_Name'">
              <a
                href="javascript:void(0)"
                (click)="goToEmployeeNameDetail(row)"
              >
                {{ row[columnName] }}
              </a>
            </ng-container>

            <ng-container *ngIf="columnName !== 'Emp_Name'">
              {{ row[columnName] }}
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ Totals_Level4[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
        <tr mat-footer-row *matFooterRowDef="columnNames"></tr>
      </table>
    </div>

    <div class="grid-container" *ngIf="Level5.length > 0">
      <table
        mat-table
        *ngIf="C_Level5"
        [dataSource]="Level5"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>
            {{ columnNameMapping[columnName] || columnName }}
          </th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [attr.rowspan]="C_Level5[iCol][iRow].span"
            [style.display]="C_Level5[iCol][iRow].span === 0 ? 'none' : ''"
          >
            <ng-container *ngIf="columnName === 'Emp_Name'">
              <a
                href="javascript:void(0)"
                (click)="goToEmployeeNameDetail(row)"
              >
                {{ row[columnName] }}
              </a>
            </ng-container>

            <ng-container *ngIf="columnName !== 'Emp_Name'">
              {{ row[columnName] }}
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ Totals_Level5[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
        <tr mat-footer-row *matFooterRowDef="columnNames"></tr>
      </table>
    </div>
    <div class="grid-container" *ngIf="Level6.length > 0">
      <table
        mat-table
        *ngIf="C_Level6"
        [dataSource]="Level6"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>
            {{ columnNameMapping[columnName] || columnName }}
          </th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [attr.rowspan]="C_Level6[iCol][iRow].span"
            [style.display]="C_Level6[iCol][iRow].span === 0 ? 'none' : ''"
          >
            <ng-container *ngIf="columnName === 'Emp_Name'">
              <a
                href="javascript:void(0)"
                (click)="goToEmployeeNameDetail(row)"
              >
                {{ row[columnName] }}
              </a>
            </ng-container>

            <ng-container *ngIf="columnName !== 'Emp_Name'">
              {{ row[columnName] }}
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ Totals_Level6[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
        <tr mat-footer-row *matFooterRowDef="columnNames"></tr>
      </table>
    </div>
    <div class="grid-container" *ngIf="Level7.length > 0">
      <table
        mat-table
        *ngIf="C_Level7"
        [dataSource]="Level7"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>
            {{ columnNameMapping[columnName] || columnName }}
          </th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [attr.rowspan]="C_Level7[iCol][iRow].span"
            [style.display]="C_Level7[iCol][iRow].span === 0 ? 'none' : ''"
          >
            <ng-container *ngIf="columnName === 'Emp_Name'">
              <a
                href="javascript:void(0)"
                (click)="goToEmployeeNameDetail(row)"
              >
                {{ row[columnName] }}
              </a>
            </ng-container>

            <ng-container *ngIf="columnName !== 'Emp_Name'">
              {{ row[columnName] }}
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ Totals_Level7[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
        <tr mat-footer-row *matFooterRowDef="columnNames"></tr>
      </table>
    </div>

    <div class="grid-container" *ngIf="Level8.length > 0">
      <table
        mat-table
        *ngIf="C_Level8"
        [dataSource]="Level8"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>
            {{ columnNameMapping[columnName] || columnName }}
          </th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [attr.rowspan]="C_Level8[iCol][iRow].span"
            [style.display]="C_Level8[iCol][iRow].span === 0 ? 'none' : ''"
          >
            <ng-container *ngIf="columnName === 'Emp_Name'">
              <a
                href="javascript:void(0)"
                (click)="goToEmployeeNameDetail(row)"
              >
                {{ row[columnName] }}
              </a>
            </ng-container>

            <ng-container *ngIf="columnName !== 'Emp_Name'">
              {{ row[columnName] }}
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ Totals_Level8[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
        <tr mat-footer-row *matFooterRowDef="columnNames"></tr>
      </table>
    </div>

    <div class="grid-container" *ngIf="Level9.length > 0">
      <table
        mat-table
        *ngIf="C_Level9"
        [dataSource]="Level9"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>
            {{ columnNameMapping[columnName] || columnName }}
          </th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [attr.rowspan]="C_Level9[iCol][iRow].span"
            [style.display]="C_Level9[iCol][iRow].span === 0 ? 'none' : ''"
          >
            <ng-container *ngIf="columnName === 'Emp_Name'">
              <a
                href="javascript:void(0)"
                (click)="goToEmployeeNameDetail(row)"
              >
                {{ row[columnName] }}
              </a>
            </ng-container>

            <ng-container *ngIf="columnName !== 'Emp_Name'">
              {{ row[columnName] }}
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ Totals_Level9[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
        <tr mat-footer-row *matFooterRowDef="columnNames"></tr>
      </table>
    </div>

    <div class="grid-container" *ngIf="Level10.length > 0">
      <table
        mat-table
        *ngIf="C_Level10"
        [dataSource]="Level10"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>
            {{ columnNameMapping[columnName] || columnName }}
          </th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [attr.rowspan]="C_Level10[iCol][iRow].span"
            [style.display]="C_Level10[iCol][iRow].span === 0 ? 'none' : ''"
          >
            <ng-container *ngIf="columnName === 'Emp_Name'">
              <a
                href="javascript:void(0)"
                (click)="goToEmployeeNameDetail(row)"
              >
                {{ row[columnName] }}
              </a>
            </ng-container>

            <ng-container *ngIf="columnName !== 'Emp_Name'">
              {{ row[columnName] }}
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ Totals_Level10[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
        <tr mat-footer-row *matFooterRowDef="columnNames"></tr>
      </table>
    </div>
  </div>
</div>

<div class="col-md-12" *ngIf="Level1.length > 0">
  <div class="col-md-4 mt-3 float-right">
    <button
      class="mt-1 btn font-size-12 button-btn float-right"
      (click)="exportexcelNew()"
    >
      <i
        class="fa fa-file-export mr-1"
        style="font-size: medium; cursor: pointer"
      ></i>
      Export
    </button>
  </div>
</div>

<div id="business_collection_report">
  <table
    *ngIf="C_Level1 && Level1?.length > 0"
    class="table table-bordered bg-white"
    style="font-family: 'Times New Roman', Times, serif; display: none"
  >
    <tr>
      <th
        *ngFor="let header of columnNames"
        style="background: #4dc3a3 !important; border: 1px solid white"
      >
        {{ columnNameMapping[header] || header }}
      </th>
    </tr>
    <tr *ngFor="let data of Level1; let indRow = index">
      <td *ngFor="let header of columnNames; let indCol = index">
        {{ data[header] }}
      </td>
    </tr>
  </table>

  <hr />

  <table
    *ngIf="C_Level2 && Level2?.length > 0"
    class="table table-bordered bg-white"
    style="font-family: 'Times New Roman', Times, serif; display: none"
  >
    <tr>
      <th
        *ngFor="let header of columnNames"
        style="background: #4dc3a3 !important; border: 1px solid white"
      >
        {{ columnNameMapping[header] || header }}
      </th>
    </tr>
    <tr *ngFor="let data of Level2; let indRow = index">
      <td *ngFor="let header of columnNames; let indCol = index">
        {{ data[header] }}
      </td>
    </tr>
  </table>

  <hr />

  <table
    *ngIf="C_Level3 && Level3?.length > 0"
    class="table table-bordered bg-white"
    style="font-family: 'Times New Roman', Times, serif; display: none"
  >
    <tr>
      <th
        *ngFor="let header of columnNames"
        style="background: #4dc3a3 !important; border: 1px solid white"
      >
        {{ columnNameMapping[header] || header }}
      </th>
    </tr>
    <tr *ngFor="let data of Level3; let indRow = index">
      <td *ngFor="let header of columnNames; let indCol = index">
        {{ data[header] }}
      </td>
    </tr>

    <tr>
      <td
        *ngFor="let header of columnNames"
        style="
          font-weight: bold;
          background: #f8f9fa;
          border-top: 2px solid #dee2e6;
        "
      >
        {{ Totals_Level3[header] || "" }}
      </td>
    </tr>
  </table>

  <hr />

  <table
    *ngIf="C_Level4 && Level4?.length > 0"
    class="table table-bordered bg-white"
    style="font-family: 'Times New Roman', Times, serif; display: none"
  >
    <tr>
      <th
        *ngFor="let header of columnNames"
        style="background: #4dc3a3 !important; border: 1px solid white"
      >
        {{ columnNameMapping[header] || header }}
      </th>
    </tr>
    <tr *ngFor="let data of Level4; let indRow = index">
      <td *ngFor="let header of columnNames; let indCol = index">
        {{ data[header] }}
      </td>
    </tr>

    <tr>
      <td
        *ngFor="let header of columnNames"
        style="
          font-weight: bold;
          background: #f8f9fa;
          border-top: 2px solid #dee2e6;
        "
      >
        {{ Totals_Level4[header] || "" }}
      </td>
    </tr>
  </table>

  <hr />

  <table
    *ngIf="C_Level5 && Level5?.length > 0"
    class="table table-bordered bg-white"
    style="font-family: 'Times New Roman', Times, serif; display: none"
  >
    <tr>
      <th
        *ngFor="let header of columnNames"
        style="background: #4dc3a3 !important; border: 1px solid white"
      >
        {{ columnNameMapping[header] || header }}
      </th>
    </tr>
    <tr *ngFor="let data of Level5; let indRow = index">
      <td *ngFor="let header of columnNames; let indCol = index">
        {{ data[header] }}
      </td>
    </tr>

    <tr>
      <td
        *ngFor="let header of columnNames"
        style="
          font-weight: bold;
          background: #f8f9fa;
          border-top: 2px solid #dee2e6;
        "
      >
        {{ Totals_Level5[header] || "" }}
      </td>
    </tr>


  </table>
  <hr />

  <table
    *ngIf="C_Level6 && Level6?.length > 0"
    class="table table-bordered bg-white"
    style="font-family: 'Times New Roman', Times, serif; display: none"
  >
    <tr>
      <th
        *ngFor="let header of columnNames"
        style="background: #4dc3a3 !important; border: 1px solid white"
      >
        {{ columnNameMapping[header] || header }}
      </th>
    </tr>
    <tr *ngFor="let data of Level6; let indRow = index">
      <td *ngFor="let header of columnNames; let indCol = index">
        {{ data[header] }}
      </td>
    </tr>

    <tr>
      <td
        *ngFor="let header of columnNames"
        style="
          font-weight: bold;
          background: #f8f9fa;
          border-top: 2px solid #dee2e6;
        "
      >
        {{ Totals_Level6[header] || "" }}
      </td>
    </tr>
  </table>

  <hr />

  <table
    *ngIf="C_Level7 && Level7?.length > 0"
    class="table table-bordered bg-white"
    style="font-family: 'Times New Roman', Times, serif; display: none"
  >
    <tr>
      <th
        *ngFor="let header of columnNames"
        style="background: #4dc3a3 !important; border: 1px solid white"
      >
        {{ columnNameMapping[header] || header }}
      </th>
    </tr>
    <tr *ngFor="let data of Level7; let indRow = index">
      <td *ngFor="let header of columnNames; let indCol = index">
        {{ data[header] }}
      </td>
    </tr>

    <tr>
      <td
        *ngFor="let header of columnNames"
        style="
          font-weight: bold;
          background: #f8f9fa;
          border-top: 2px solid #dee2e6;
        "
      >
        {{ Totals_Level7[header] || "" }}
      </td>
    </tr>
  </table>

  <hr />

  <table
    *ngIf="C_Level8 && Level8?.length > 0"
    class="table table-bordered bg-white"
    style="font-family: 'Times New Roman', Times, serif; display: none"
  >
    <tr>
      <th
        *ngFor="let header of columnNames"
        style="background: #4dc3a3 !important; border: 1px solid white"
      >
        {{ columnNameMapping[header] || header }}
      </th>
    </tr>
    <tr *ngFor="let data of Level8; let indRow = index">
      <td *ngFor="let header of columnNames; let indCol = index">
        {{ data[header] }}
      </td>
    </tr>

    <tr>
      <td
        *ngFor="let header of columnNames"
        style="
          font-weight: bold;
          background: #f8f9fa;
          border-top: 2px solid #dee2e6;
        "
      >
        {{ Totals_Level8[header] || "" }}
      </td>
    </tr>
  </table>
</div>

<!-- <div
  class="modal fade in"
  id="modalBusinessCollectionDetail"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          {{ modalTitle }}
        </h6>
        <button
          type="button"
          (click)="onCloseBusinessCollectionModal()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>

      <div class="modal-body"></div>
    </div>
  </div>
</div> -->

<div
  class="modal fade in"
  id="modalBusinessCollectionDetail"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            {{ modalTitle }}
          </h6>
          <button
            type="button"
            (click)="onCloseBusinessCollectionModal()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">


          <div class="col-md-12">
            <div class="col-md-3 float-right" [hidden]="!dataSourceModal">
              <form action="" class="search-text">
                <div class="bg-light rounded rounded-pill shadow-sm">
                  <div class="input-group">
                    <input
                      type="text"
                      id="FilterInputSearch"
                      (keyup)="applyFilterModal($event.target.value)"
                      name="FilterInputSearch"
                      placeholder="What're you searching for?"
                      class="form-control border-0 bg-light"
                    />
                    <div class="input-group-append">
                      <button
                        id="button-addon1"
                        type="submit"
                        class="btn btn-link text-primary"
                      >
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>



          <div class="table-responsive mt-3" *ngIf="dataSourceModal">
            <mat-table
              class="table-bordered"
              matSort
              [dataSource]="dataSourceModal"
              matTableExporter
              #exporter="matTableExporter"
              id="AttendanceList"
              style="height: 65vh; overflow: auto; max-width: 100%"
            >
              <ng-container matColumnDef="Loan_Id">
                <mat-header-cell mat-sort-header *matHeaderCellDef
                  >#</mat-header-cell
                >
                <mat-cell *matCellDef="let row; let i = index">
                  {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="LoanAcNo">
                <mat-header-cell mat-sort-header *matHeaderCellDef
                  >Loan No</mat-header-cell
                >
                <mat-cell *matCellDef="let row">
                  {{ row.LoanAcNo }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Branch_Name">
                <mat-header-cell mat-sort-header *matHeaderCellDef
                  >Branch</mat-header-cell
                >
                <mat-cell *matCellDef="let row">
                  {{ row.Branch_Name }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Product_Name">
                <mat-header-cell mat-sort-header *matHeaderCellDef
                  >Product</mat-header-cell
                >
                <mat-cell *matCellDef="let row">
                  {{ row.Product_Name }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Executive">
                <mat-header-cell mat-sort-header *matHeaderCellDef>
                  Executive
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{ row.Executive }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="CustomerName">
                <mat-header-cell mat-sort-header *matHeaderCellDef
                  >Customer Name</mat-header-cell
                >
                <mat-cell *matCellDef="let row">
                  {{ row.CustomerName }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Loan_Acc_Category">
                <mat-header-cell mat-sort-header *matHeaderCellDef
                  >Loan Acc Category</mat-header-cell
                >
                <mat-cell *matCellDef="let row">
                  <mat-cell *matCellDef="let row">
                    {{ row.Loan_Acc_Category }}
                  </mat-cell>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Business_Numbers">
                <mat-header-cell mat-sort-header *matHeaderCellDef
                  >Business Numbers</mat-header-cell
                >
                <mat-cell *matCellDef="let row">
                  {{ row.Business_Numbers }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>{{
                  totaltypewise("Business_Numbers")
                }}</mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Business_Amount">
                <mat-header-cell mat-sort-header *matHeaderCellDef
                  >Business Amount</mat-header-cell
                >
                <mat-cell *matCellDef="let row">
                  {{ row.Business_Amount }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>{{
                  totaltypewise("Business_Amount")
                }}</mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Opening_Overdue_Numbers">
                <mat-header-cell mat-sort-header *matHeaderCellDef
                  >OP OD Nos</mat-header-cell
                >
                <mat-cell *matCellDef="let row">
                  {{ row.Opening_Overdue_Numbers }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>{{
                  totaltypewise("Opening_Overdue_Numbers")
                }}</mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Opening_Overdue_Amount">
                <mat-header-cell mat-sort-header *matHeaderCellDef
                  >OP OD Amount</mat-header-cell
                >
                <mat-cell *matCellDef="let row">
                  {{ row.Opening_Overdue_Amount }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>{{
                  totaltypewise("Opening_Overdue_Amount")
                }}</mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Current_Due_Numbers">
                <mat-header-cell mat-sort-header *matHeaderCellDef
                  >Current Due Nos</mat-header-cell
                >
                <mat-cell *matCellDef="let row">
                  {{ row.Current_Due_Numbers }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>{{
                  totaltypewise("Current_Due_Numbers")
                }}</mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Current_Due_Amount">
                <mat-header-cell mat-sort-header *matHeaderCellDef
                  >Current Due Amount</mat-header-cell
                >
                <mat-cell *matCellDef="let row">
                  {{ row.Current_Due_Amount }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>{{
                  totaltypewise("Current_Due_Amount")
                }}</mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Current_Collection_Numbers">
                <mat-header-cell mat-sort-header *matHeaderCellDef
                  >Current Coll Nos</mat-header-cell
                >
                <mat-cell *matCellDef="let row">
                  {{ row.Current_Collection_Numbers }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>{{
                  totaltypewise("Current_Collection_Numbers")
                }}</mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Current_Collection_Amount">
                <mat-header-cell mat-sort-header *matHeaderCellDef
                  >Current Coll Amount</mat-header-cell
                >
                <mat-cell *matCellDef="let row">
                  {{ row.Current_Collection_Amount }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>{{
                  totaltypewise("Current_Collection_Amount")
                }}</mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Closing_Overdue_Numbers">
                <mat-header-cell mat-sort-header *matHeaderCellDef
                  >CL OD Nos</mat-header-cell
                >
                <mat-cell *matCellDef="let row">
                  {{ row.Closing_Overdue_Numbers }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>{{
                  totaltypewise("Closing_Overdue_Numbers")
                }}</mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Closing_Overdue_Amount">
                <mat-header-cell mat-sort-header *matHeaderCellDef
                  >CL OD Amount</mat-header-cell
                >
                <mat-cell *matCellDef="let row">
                  {{ row.Closing_Overdue_Amount }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>{{
                  totaltypewise("Closing_Overdue_Amount")
                }}</mat-footer-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedColumnsModal; sticky: true"
                class="sticky-footer fontcolor"
                style="background: #4dc3a3; max-height: 5px"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumnsModal"
              ></mat-row>

              <mat-footer-row
                class="sticky-footer fontcolor"
                *matFooterRowDef="displayedColumnsModal; sticky: true"
                style="background: #4dc3a3"
              ></mat-footer-row>
            </mat-table>
          </div>

          <div class="row m-0 ml-3" [hidden]="!dataSourceModal">
            <div class="col-md-4 mt-3">
              <button
                class="mt-3 btn font-size-12 button-btn"
                (click)="exportexcelModal()"
              >
                <i
                  class="fa fa-file-export mr-1"
                  style="font-size: medium; cursor: pointer"
                ></i>
                Export
              </button>
            </div>
            <div class="col-md-8 mt-3">
              <mat-paginator
                #paginatorRef
                [pageSizeOptions]="[20, 50]"
                showFirstLastButtons
              ></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<table
  id="reports_business_modal_table"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Ac No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product Name
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Executive
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Acc Category
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Business Numbers
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Business Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      OP OD Nos
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      OP OD Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Current Due Nos
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Current Due Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Current Coll Nos
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Current Coll Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      CL OD Nos
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      CL OD Amount
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.Executive }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.Loan_Acc_Category }}</td>
    <td>{{ x.Business_Numbers }}</td>
    <td>{{ x.Business_Amount }}</td>
    <td>{{ x.Opening_Overdue_Numbers }}</td>
    <td>{{ x.Opening_Overdue_Amount }}</td>
    <td>{{ x.Current_Due_Numbers }}</td>
    <td>{{ x.Current_Due_Amount }}</td>
    <td>{{ x.Current_Collection_Numbers }}</td>
    <td>{{ x.Current_Collection_Amount }}</td>
    <td>{{ x.Closing_Overdue_Numbers }}</td>
    <td>{{ x.Closing_Overdue_Amount }}</td>
  </tr>

  <tfoot>
    <tr>
      <td
        colspan="7"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Business_Numbers") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Business_Amount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Opening_Overdue_Numbers") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Opening_Overdue_Amount") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Current_Due_Numbers") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Current_Due_Amount") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Current_Collection_Numbers") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Current_Collection_Amount") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Closing_Overdue_Numbers") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Closing_Overdue_Amount") }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
