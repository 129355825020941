<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="onSearchDueList()"
    class="formborder"
  >
    <div class="col-md-12 row">
      <div
        class="datepicker_feild"
        *ngIf="this.DueListfrom != 'LMS'"
        style="padding-left: 15px !important"
      >
        <span>Due Up To</span>
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="DueDateFrom"
          id="DueDateFrom"
          #refDueDateFrom="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="DueDateFrom"
          [ngClass]="{
            'is-invalid': df.submitted && refDueDateFrom.invalid,
            'alert-warning':
              refDueDateFrom.invalid &&
              (refDueDateFrom.dirty ||
                refDueDateFrom.touched ||
                refDueDateFrom.untouched)
          }"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker"
          style="top: 24px"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>

      <div class="col-md-2">
        <span>Type</span>
        <select
          name="CheckType"
          id="CheckType"
          [(ngModel)]="CheckType"
          class="form-control input-text-css"
        >
          <option value="OD Cases">OD Cases</option>
          <option value="All">All</option>
        </select>
      </div>

      <div class="col-md-2">
        <span> Branch</span>
        <select
          name="SelectBranch"
          id="SelectBranch"
          #refSelectGender="ngModel"
          [(ngModel)]="SelectBranch"
          class="form-control input-text-css"
        >
          <option value="">Select Branch</option>
          <option *ngFor="let branch of BranchesList" [value]="branch.BranchId">
            {{ branch.Branch_Name }}
          </option>
        </select>
      </div>

      <div class="col-md-3">
        <span>Product</span>
        <select
          name="SelectProduct"
          id="SelectProduct"
          #refSelectGender="ngModel"
          [(ngModel)]="SelectProduct"
          class="form-control input-text-css"
        >
          <option value="" selected>Select Product</option>
          <option
            *ngFor="let product of ProductList"
            [value]="product.ProductId"
          >
            {{ product.Product }}
          </option>
        </select>
      </div>

      <div class="col-md-2">
        <span class="required-lable">Bucket</span>
        <select
          name="SlabId"
          id="SlabId"
          [(ngModel)]="SlabId"
          class="form-control input-text-css"
          required
          #refSlabId="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refSlabId.invalid,
            'alert-warning':
              refSlabId.invalid &&
              (refSlabId.dirty || refSlabId.touched || refSlabId.untouched)
          }"
        >
          <option value="" selected>Select Bucket</option>
          <option *ngFor="let row of SlabData" [value]="row.Id">
            {{ row.Slab_name }}
          </option>
        </select>
      </div>

      <!-- [ngClass]="{
          'col-md-2': this.DueListfrom != 'LMS',
          'col-md-3': this.DueListfrom == 'LMS'
        }" -->
      <div class="col-md-3">
        <span>Collection Executive</span>
        <select
          name="collection_exec"
          id="collection_exec"
          #refSelectGender="ngModel"
          [(ngModel)]="collection_exec"
          class="form-control input-text-css"
        >
          <option value="" selected>Select Collection Executive</option>
          <option
            *ngFor="let Coll_exce of CollectionExecutiveData"
            [value]="Coll_exce.EmpId"
          >
            {{ Coll_exce.Emp_FirstName }}
          </option>
        </select>
      </div>

      <div class="col-md-2">
        <span> Source Type </span>
        <select
          name="SelectSourceType"
          id="SelectSourceType"
          #refSelectSourceType="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refSelectSourceType.invalid,
            'alert-warning':
              refSelectSourceType.invalid &&
              (refSelectSourceType.dirty ||
                refSelectSourceType.touched ||
                refSelectSourceType.untouched)
          }"
          (change)="Get_PartnerForDropdown()"
          [(ngModel)]="SelectSourceType"
          class="form-control input-text-css"
        >
          <option value="">Select Source Type</option>
          <option
            *ngFor="let lead of leadSourceDropdown"
            [value]="lead.Lead_Source"
          >
            {{ lead.Lead_Source }}
          </option>
        </select>
      </div>

      <div
        *ngIf="
          SelectSourceType == 'Agent' ||
          SelectSourceType == 'DSA' ||
          SelectSourceType == 'Dealer' ||
          SelectSourceType == 'Sales Executive' ||
          SelectSourceType == 'Telecaller Executive' ||
          SelectSourceType == 'Marketing Executive'
        "
        class="col-md-3"
      >
        <div>
          <span> Source Name</span>
          <select
            name="LeadSourceName"
            id="LeadSourceName"
            #refLeadSourceName="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refLeadSourceName.invalid,
              'alert-warning':
                refLeadSourceName.invalid &&
                (refLeadSourceName.dirty ||
                  refLeadSourceName.touched ||
                  refLeadSourceName.untouched)
            }"
            [(ngModel)]="LeadSourceName"
            class="form-control input-text-css"
          >
            <option value="">Select Lead Reference</option>
            <option
              *ngFor="let partner of partnerDropdown"
              [value]="partner.PartnerId"
            >
              {{ partner.Partner_Name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-2 text-right mt-3" *ngIf="this.DueListfrom == 'LMS'">
        <button
          type="button"
          (click)="onSearchDueList()"
          class="btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Get Due List
        </button>
      </div>
      <div class="col-md-2" *ngIf="this.DueListfrom == 'LMS'">
        <button
          type="button"
          *ngIf="this.DueListfrom == 'LMS'"
          (click)="onGenerateDueList()"
          class="btn font-size-12 button-btn mt-3"
          style="width: max-content"
        >
          Generate Due List
        </button>
      </div>
      <div class="col-md-2 text-right mt-3" *ngIf="this.DueListfrom != 'LMS'">
        <button
          type="button"
          (click)="onSearchDueList()"
          class="btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>

      <div class="col-md-3 mt-3" [hidden]="!dataSource">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>

  <div class="mt-3" [hidden]="!dataSource">
    <div class="">
      <div
        class="table-responsive table-striped"
        style="overflow: auto; height: 65vh"
      >
        <table
          class="table-bordered"
          matSort
          mat-table
          [dataSource]="dataSource"
          matTableExporter
          #exporter="matTableExporter"
          id="DueList"
        >
          <ng-container matColumnDef="Id">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let row; let i = index">
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Application_No">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Application No
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Application_No }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="LoanAcNo">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Loan No</th>
            <td mat-cell *matCellDef="let row">
              <!--<a
                (click)="goToLoanStatement(row)"
                aria-label="true"
                style="color: blue; cursor: pointer"
              >-->
              {{ row.LoanAcNo }}
              <!-- </a>-->
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Loan_Date">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Loan Date</th>
            <td mat-cell *matCellDef="let row">{{ row.Loan_Date }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Loan_Acc_Category">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Loan Acc Category
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Loan_Acc_Category }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="CustomerName">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Customer Name
            </th>
            <td mat-cell *matCellDef="let row">{{ row.CustomerName }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="RelationName">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Relation Name
            </th>
            <td mat-cell *matCellDef="let row">{{ row.RelationName }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-center">Total</th>
          </ng-container>

          <ng-container matColumnDef="LoanAmount">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Loan Amount
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.LoanAmount }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("LoanAmount") }}
            </th>
          </ng-container>
          <ng-container matColumnDef="SalesExecutive">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Sales Executive
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.SalesExecutive }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right"></th>
          </ng-container>

          <ng-container matColumnDef="EMIAmount">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              EMI Amount
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.EMIAmount | number : "1.2-2" }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("EMIAmount") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Loan_Tenure">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Loan Tenure
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Loan_Tenure }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="FirstEMIDate">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              First EMI Date
            </th>
            <td mat-cell *matCellDef="let row">{{ row.FirstEMIDate }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="DPD_Days">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>DPD</th>
            <td mat-cell *matCellDef="let row">{{ row.DPD_Days }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="OverDUE_EMI">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>EMI No.</th>
            <td mat-cell *matCellDef="let row">{{ row.OverDUE_EMI }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Total_OverDUE_EMI__Amount">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              EMI Amount
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Total_OverDUE_EMI__Amount }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Total_OverDUE_EMI__Amount") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Total_OverDUE_Principal_Amount">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>POS</th>
            <td mat-cell *matCellDef="let row">
              {{ row.Total_OverDUE_Principal_Amount }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Total_OverDUE_Principal_Amount") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="overDue_Interest_AMount">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Interest</th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.overDue_Interest_AMount }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("overDue_Interest_AMount") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Last_Recv_Date">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Last Recv Date
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Last_Recv_Date }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Last_Recv_Amount">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Last Recv Amount
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Last_Recv_Amount }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Last_Recv_Amount") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="TotalEMI_NOS">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>EMI No.</th>
            <td mat-cell *matCellDef="let row">{{ row.TotalEMI_NOS }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Total_EMI_AMount">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              EMI Amount
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Total_EMI_AMount }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Total_EMI_AMount") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Total_Principal_Amount">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>POS</th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Total_Principal_Amount }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Total_Principal_Amount") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Total_Interest_AMount">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Interest</th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Total_Interest_AMount }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Total_Interest_AMount") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="EMI_DUE_NOS">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>EMI No.</th>
            <td mat-cell *matCellDef="let row">{{ row.EMI_DUE_NOS }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="EMI_DUE">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              EMI Amount
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.EMI_DUE }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("EMI_DUE") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Principal_DUE">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>POS</th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Principal_DUE }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Principal_DUE") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Interest_DUE">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Interest</th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Interest_DUE }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Interest_DUE") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="LastDue_Date">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Last Due Date
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Last_Mature_EMIDate }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right"></th>
          </ng-container>

          <ng-container matColumnDef="LastDue_Amt">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Last Due Amount
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Last_Mature_EMIAmount }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Last_Mature_EMIAmount") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="LastDue_EMINo">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Last Due EMI No.
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Last_Mature_EMINo }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Last_Mature_EMINo") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="receved_EMI">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>EMI No.</th>
            <td mat-cell *matCellDef="let row">{{ row.receved_EMI }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Total_Recv_EMI__Amount">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              EMI Amount
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Total_Recv_EMI__Amount }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Total_Recv_EMI__Amount") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Total_Recv_Principal_Amount">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>POS</th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Total_Recv_Principal_Amount }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Total_Recv_Principal_Amount") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Total_Recv_Interest_AMount">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Interest</th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Total_Recv_Interest_AMount }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Total_Recv_Interest_AMount") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Balance_Principle">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>POS</th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Balance_Principle }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Balance_Principle") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Balance_Interest">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Interest</th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Balance_Interest }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Balance_Interest") }}
            </th>
          </ng-container>
          <ng-container matColumnDef="FuturePrincipal">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Future Principal
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.FuturePrincipal }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("FuturePrincipal") }}
            </th>
          </ng-container>
          <ng-container matColumnDef="FutureInterest">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Future Interest
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.FutureInterest }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("FutureInterest") }}
            </th>
          </ng-container>
          <ng-container matColumnDef="Other_Charges">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Other Dues
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Other_Charges }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Other_Charges") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Penal_Interest">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>LPI</th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.Penal_Interest }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-right">
              {{ totaltypewise("Penal_Interest") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="EMI_Frequency">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              EMI Frequency
            </th>
            <td mat-cell *matCellDef="let row">{{ row.EMI_Frequency }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Product_Name">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Product Name
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Product_Name }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Branch_Name">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Branch Name
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Branch_Name }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="SourceType">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Source Type
            </th>
            <td mat-cell *matCellDef="let row">{{ row.SourceType }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="SourceName">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Source Name
            </th>
            <td mat-cell *matCellDef="let row">{{ row.SourceName }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Area_Name">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Area Name</th>
            <td mat-cell *matCellDef="let row">{{ row.Area_Name }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Sub_Area_Name">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Sub Area Name
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Sub_Area_Name }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="CollectionExecutive">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Collection Executive
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.CollectionExecutive }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Customer_PhoneNo">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Customer Phone No
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Customer_PhoneNo }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Alternate_PhoneNo">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Alternate Phone No
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Alternate_PhoneNo }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Address">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Address</th>
            <td mat-cell *matCellDef="let row">{{ row.Address }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Co_Borrower_Name">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Co-Borrower Name
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Co_Borrower_Name }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Co_Borrower_MobileNo">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Co-Borrower Mobile No
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Co_Borrower_MobileNo }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Co_Borrower_Address">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Co-Borrower Address
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Co_Borrower_Address }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Guarantor_Address">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Guarantor Address
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Guarantor_Address }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Guarantor_Name">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Guarantor Name
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Guarantor_Name }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Guarantor_MobileNo">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Guarantor Mobile No
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Guarantor_MobileNo }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="EMIType">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>EMI Type</th>
            <td mat-cell *matCellDef="let row">{{ row.EMIType }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="DueDate">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Due Date</th>
            <td mat-cell *matCellDef="let row">{{ row.DueDate }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="ExpiryDate">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Expiry Date
            </th>
            <td mat-cell *matCellDef="let row">{{ row.ExpiryDate }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Bucket">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Bucket</th>
            <td mat-cell *matCellDef="let row">{{ row.Bucket }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="UMRNNumber">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              UMRN Number
            </th>
            <td mat-cell *matCellDef="let row">{{ row.UMRNNumber }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="CompanyBankName">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Company Bank Name
            </th>
            <td mat-cell *matCellDef="let row">{{ row.CompanyBankName }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="AssignTo">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Assign To</th>
            <td mat-cell *matCellDef="let row">{{ row.AssignTo }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Legal_Status">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Legal Status
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Legal_Status }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="VehicleRegistationNo">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Vehicle Registation No
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.VehicleRegistationNo }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Action">
            <th
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
              class="text-center"
            >
              Action
            </th>
            <td mat-cell *matCellDef="let row" class="text-center">
              <i
                class="fa fa-arrow-circle-right arrow-circle action-btn"
                (click)="goToLoanStatement(row)"
                aria-label="true"
              ></i>
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <!-- Top header column start here -->
          <ng-container matColumnDef="row-first">
            <th
              mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="11"
              style="
                border-right: 1px #f7f9f9 solid;
                border-bottom: 0;
                text-align: center;
              "
            >
              PARTICULARS
            </th>
          </ng-container>

          <ng-container matColumnDef="row-second">
            <th
              mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="7"
              style="
                border-right: 1px #f7f9f9 solid;
                border-bottom: 0.5px;
                text-align: center;
              "
            >
              OVER DUE
            </th>
          </ng-container>
          <ng-container matColumnDef="row-third">
            <th
              mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="4"
              style="
                border-right: 1px #f7f9f9 solid;
                border-bottom: 0.5px;
                text-align: center;
              "
            >
              Agreement
            </th>
          </ng-container>
          <ng-container matColumnDef="row-fourth">
            <th
              mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="7"
              style="
                border-right: 1px #f7f9f9 solid;
                border-bottom: 0.5px;
                text-align: center;
              "
            >
              DUES
            </th>
          </ng-container>
          <ng-container matColumnDef="row-fifth">
            <th
              mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="4"
              style="
                border-right: 1px #f7f9f9 solid;
                border-bottom: 0.5px;
                text-align: center;
              "
            >
              Received
            </th>
          </ng-container>
          <ng-container matColumnDef="row-sixth">
            <th
              mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="5"
              style="
                border-right: 1px #f7f9f9 solid;
                border-bottom: 0.5px;
                text-align: center;
              "
            >
              Balances
            </th>
          </ng-container>
          <ng-container matColumnDef="row-last">
            <th
              mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="29"
              style="border-bottom: 0.5px"
            >
              &nbsp;
            </th>
          </ng-container>
          <!-- Top header column end here -->
          <!-- Top header row start here -->
          <tr
            mat-header-row
            *matHeaderRowDef="[
              'row-first',
              'row-second',
              'row-third',
              'row-fourth',
              'row-fifth',
              'row-sixth',
              'row-last'
            ]"
            style="background: #4dc3a3"
          ></tr>
          <!-- Top header row end here -->

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="sticky-footer fontcolor"
            style="background: #4dc3a3"
          ></tr>
          <tr
            mat-row
            (click)="highlightRow(row)"
            [class.highlight]="row.Loan_Id == selectedId"
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
          <tr
            mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"
          ></tr>
        </table>
      </div>
    </div>
  </div>
  <div class="row m-0 ml-3" [hidden]="!dataSource">
    <div class="col-md-4 mt-3">
      <button
        class="mt-3 btn font-size-12 button-btn"
        (click)="exportexcelNew()"
      >
        <i
          class="fa fa-file-export mr-1"
          style="font-size: medium; cursor: pointer"
        ></i>
        Export
      </button>
    </div>
    <div class="col-md-8 mt-3">
      <mat-paginator
        #paginatorRef
        [pageSizeOptions]="[20, 50]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>
<div
  class="modal fade in"
  id="ViewLoanStatementModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          View loan statement
        </h6>
        <button
          type="button"
          (click)="onCloseLoanStatementModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <app-lms-loanStatement
          [isShowHeader]="false"
          [loanSummary]="loanSummary"
        ></app-lms-loanStatement>
      </div>
    </div>
  </div>
</div>

<table
  id="export-due"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th
      colspan="11"
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: center;
      "
    >
      Particulars
    </th>
    <th
      colspan="7"
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: center;
      "
    >
      Overdue
    </th>
    <th
      colspan="4"
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: center;
      "
    >
      Agreement
    </th>
    <th
      colspan="7"
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: center;
      "
    >
      Due
    </th>
    <th
      colspan="4"
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: center;
      "
    >
      Received
    </th>
    <th
      colspan="5"
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: center;
      "
    >
      Balances
    </th>
    <th
      colspan="28"
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: center;
      "
    ></th>
  </tr>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Acc Category
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Relation Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Tenure
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      First EMI Date
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">DPD</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">POS</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Last Recv Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Last Recv Amount
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">POS</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">POS</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Last Due Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Last Due Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Last Due EMI No
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">POS</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">POS</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Future Principal
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Future Interest
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Other Dues
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">LPI</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Frequency
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Sales Executive
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Area Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Sub Area Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collection Executive
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Phone No.
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Alternate Phone No.
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Address
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Co-Borrower Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Co-Borrower Mobile Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Co-Borrower Address
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Gurantor Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Gurantor Mobile No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Gurantor Address
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Due Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Expiry Date
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Bucket
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      UMRN Number
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Company Bank Name
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Assign To
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Legal Status
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Vehicle Registation No
    </th>

    <!-- <th style="background: #4dc3a3 !important;">Action</th> -->
  </tr>
  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td style="text-align: left">{{ x.Application_No }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.Loan_Acc_Category }}</td>

    <td>{{ x.CustomerName }}</td>
    <td>{{ x.RelationName }}</td>
    <td>{{ x.LoanAmount }}</td>
    <td>{{ x.EMIAmount }}</td>
    <td>{{ x.Loan_Tenure }}</td>
    <td>{{ x.FirstEMIDate }}</td>

    <td>{{ x.DPD_Days }}</td>
    <td>{{ x.OverDUE_EMI }}</td>
    <td>{{ x.Total_OverDUE_EMI__Amount }}</td>
    <td>{{ x.Total_OverDUE_Principal_Amount }}</td>
    <td>{{ x.overDue_Interest_AMount }}</td>
    <td>{{ x.Last_Recv_Date }}</td>
    <td>{{ x.Last_Recv_Amount }}</td>

    <td>{{ x.TotalEMI_NOS }}</td>
    <td>{{ x.Total_EMI_AMount }}</td>
    <td>{{ x.Total_Principal_Amount }}</td>
    <td>{{ x.Total_Interest_AMount }}</td>

    <td>{{ x.EMI_DUE_NOS }}</td>
    <td>{{ x.EMI_DUE }}</td>
    <td>{{ x.Principal_DUE }}</td>
    <td>{{ x.Interest_DUE }}</td>
    <td>{{ x.Last_Mature_EMIDate }}</td>
    <td>{{ x.Last_Mature_EMIAmount }}</td>
    <td>{{ x.Last_Mature_EMINo }}</td>

    <td>{{ x.receved_EMI }}</td>
    <td>{{ x.Total_Recv_EMI__Amount }}</td>
    <td>{{ x.Total_Recv_Principal_Amount }}</td>
    <td>{{ x.Total_Recv_Interest_AMount }}</td>

    <td>{{ x.Balance_Principle }}</td>
    <td>{{ x.Balance_Interest }}</td>
    <td>{{ x.FuturePrincipal }}</td>
    <td>{{ x.FutureInterest }}</td>
    <td>{{ x.Other_Charges }}</td>

    <td>{{ x.Penal_Interest }}</td>
    <td>{{ x.EMI_Frequency }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.SalesExecutive }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.SourceType }}</td>
    <td>{{ x.SourceName }}</td>
    <td>{{ x.Area_Name }}</td>
    <td>{{ x.Sub_Area_Name }}</td>
    <td>{{ x.CollectionExecutive }}</td>
    <td>{{ x.Customer_PhoneNo }}</td>
    <td>{{ x.Alternate_PhoneNo }}</td>
    <td>{{ x.Address }}</td>
    <td>{{ x.Co_Borrower_Name }}</td>
    <td>{{ x.Co_Borrower_MobileNo }}</td>
    <td>{{ x.Co_Borrower_Address }}</td>
    <td>{{ x.Guarantor_Name }}</td>
    <td>{{ x.Guarantor_MobileNo }}</td>
    <td>{{ x.Guarantor_Address }}</td>
    <td>{{ x.EMIType }}</td>
    <td>{{ x.DueDate }}</td>
    <td>{{ x.ExpiryDate }}</td>
    <td>{{ x.Bucket }}</td>
    <td>{{ x.UMRNNumber }}</td>
    <td>{{ x.CompanyBankName }}</td>
    <td>{{ x.AssignTo }}</td>
    <td>{{ x.Legal_Status }}</td>
    <td>{{ x.VehicleRegistationNo }}</td>

    <!-- <td>{{x.Action}}</td> -->
  </tr>

  <tfoot>
    <tr>
      <td
        colspan="7"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("LoanAmount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("EMIAmount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Total_OverDUE_EMI__Amount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Total_OverDUE_Principal_Amount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("overDue_Interest_AMount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Last_Recv_Amount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Total_EMI_AMount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Total_Principal_Amount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Total_Interest_AMount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("EMI_DUE") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Principal_DUE") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Interest_DUE") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Last_Mature_EMIAmount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Last_Mature_EMINo") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Total_Recv_EMI__Amount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Total_Recv_Principal_Amount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Total_Recv_Interest_AMount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Balance_Principle") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Balance_Interest") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("FuturePrincipal") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("FutureInterest") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Other_Charges") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Penal_Interest") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b></b>
      </td>
    </tr>
  </tfoot>
</table>
