<app-mat-spinner-overlay *ngIf="loading"></app-mat-spinner-overlay>
<div class="dropdown">
  <button
    class="mt-2 btn font-size-12 button-btn dropdown-toggle mat-button-wrapper"
    style="padding: 10px"
    type="button"
    id="dropdownMenuButton"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    Generate {{ textName }}
  </button>
  <div
    class="dropdown-menu"
    aria-labelledby="dropdownMenuButton"
    style="max-height: 95px; overflow: auto"
  >
    <a
      class="dropdown-item"
      *ngFor="let x of dropdownLanguages"
      (click)="Get_Template(x.Id)"
      >{{ x.Template_Name }}</a
    >
  </div>
</div>

<div
  class="modal fade in"
  id="Template{{ TemplateName }}model"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <button
          type="button"
          class="font-size-12 button-btn"
          color="primary"
          [class.spinner]="loading"
          (click)="printLetter()"
        >
          Print {{ textName }}
        </button>
        <button
          type="button"
          (click)="OnClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div
        class="modal-body"
        id="pdf{{ TemplateName }}LetterDownload"
        style="overflow-wrap: anywhere"
      >
        <div class="row mb-0">
          <div class="col-md-12 ck-content">
            <div [innerHtml]="content"></div>
          </div>
        </div>
      </div>

      <div id="LODPrint" style="display: none">
        <table
          class="MsoTableGrid"
          width="1000px"
          border="1"
          cellspacing="0"
          cellpadding="0"
        >
          <thead>
            <tr>
              <!--<th class="WD-66">Document Type</th>-->
              <th class="WD-66">Document Title</th>
              <th class="WD-66">In Favour Of</th>
              <th class="WD-66">Date Of Executation</th>
              <th class="WD-66">Executation By</th>
              <th class="WD-66">No Of Pages</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of LODData; let i = index">
              <!--<td>{{ data.DocType }}</td>-->
              <td>{{ data.DocTitle }}</td>
              <td>{{ data.Remark }}</td>
              <td>{{ data.DateOfExecution }}</td>
              <td>{{ data.ExecutedBy }}</td>
              <td>{{ data.No_Of_Pages }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div id="sanctionDetailPrintAgreement" style="display: none">
        <table
          style="font-size: 15px"
          class="MsoTableGrid"
          width="1000px"
          border="1"
          cellspacing="0"
          cellpadding="0"
        >
          <thead>
            <tr class="height-sanctionDetail" style="background-color: #acc8ee">
              <!--<th class="WD-66">Document Type</th>-->
              <th class="WD-66" style="padding-left: 10px">Sr.No.</th>
              <th class="WD-66" style="padding-left: 10px">Parameter</th>
              <th class="WD-66" style="padding-left: 10px">Details</th>
            </tr>
          </thead>
          <tbody>
            <tr class="height-sanctionDetail">
              <td style="text-align: center">1</td>
              <td style="text-align: left; padding-left: 10px">
                Sanctioned Loan Amount
              </td>
              <td style="text-align: right; padding-right: 10px">
                {{ sanctionLoanAmountObj?.sanction_LoanAmount }}
              </td>
            </tr>
            <tr class="height-sanctionDetail">
              <td style="text-align: center">2</td>
              <td style="text-align: left; padding-left: 10px">
                Deduction - Processing Fees @
                {{ sanctionLoanAmountObj?.ProcessingFeePercentage }} %
              </td>
              <td style="text-align: right; padding-right: 10px">
                {{ sanctionLoanAmountObj?.ProcessingFeeCharges }}
              </td>
            </tr>

            <tr class="height-sanctionDetail">
              <td style="text-align: center">3</td>
              <td style="text-align: left; padding-left: 10px">
                Deduction - KYC Verification
              </td>
              <td style="text-align: right; padding-right: 10px">
                {{ sanctionLoanAmountObj?.Kyc_Charges }}
              </td>
            </tr>

            <tr class="height-sanctionDetail">
              <td style="text-align: center">4</td>
              <td style="text-align: left; padding-left: 10px">
                Deduction - On Boarding Fee
              </td>
              <td style="text-align: right; padding-right: 10px">
                {{ sanctionLoanAmountObj?.OnBoarding_Charges }}
              </td>
            </tr>
            <tr class="height-sanctionDetail">
              <td style="text-align: center">5</td>
              <td style="text-align: left; padding-left: 10px">
                GST @ 18% on Deduction
              </td>
              <td style="text-align: right; padding-right: 10px">
                {{ sanctionLoanAmountObj?.Gst_OnDeduction }}
              </td>
            </tr>
            <tr class="height-sanctionDetail">
              <td style="text-align: center">6</td>
              <td style="text-align: left; padding-left: 10px">
                Total Deductible Amount:
              </td>
              <td style="text-align: right; padding-right: 10px">
                {{ sanctionLoanAmountObj?.Total_Deduction }}
              </td>
            </tr>

            <tr class="height-sanctionDetail">
              <td style="text-align: center">7</td>
              <td style="text-align: left; padding-left: 10px">
                Net Disbursement Amount :
              </td>
              <td style="text-align: right; padding-right: 10px">
                {{ sanctionLoanAmountObj?.NetDisbursementAmount }}
              </td>
            </tr>

            <tr class="height-sanctionDetail">
              <td style="text-align: center">8</td>
              <td style="text-align: left; padding-left: 10px">
                Sanction Interest Amount:
              </td>
              <td style="text-align: right; padding-right: 10px">
                {{ sanctionLoanAmountObj?.Sanction_InterestAmount }}
              </td>
            </tr>

            <tr class="height-sanctionDetail">
              <td style="text-align: center">9</td>
              <td style="text-align: left; padding-left: 10px">
                Total Amount to be paid by By Borrower:
              </td>
              <td style="text-align: right; padding-right: 10px">
                {{ sanctionLoanAmountObj?.PaidByBorrower }}
              </td>
            </tr>
          </tbody>
        </table>

        <table
          style="font-size: 15px"
          class="MsoTableGrid"
          width="1000px"
          border="1"
          cellspacing="0"
          cellpadding="0"
        >
          <thead>
            <tr class="height-sanctionDetail">
              <!--<th class="WD-66">Document Type</th>-->
              <th class="WD-66" colspan="3" style="text-align: center">
                Interest Calculation
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="height-sanctionDetail">
              <td style="text-align: center; width: 11%">1</td>
              <td style="text-align: left; width: 72%; padding-left: 10px">
                Rate of Interest per Day
              </td>
              <td style="text-align: right; padding-right: 10px">
                {{ sanctionLoanAmountObj?.sanction_ROI }}
              </td>
            </tr>
            <tr class="height-sanctionDetail">
              <td style="text-align: center; width: 11%">2</td>
              <td style="text-align: left; width: 72%; padding-left: 10px">
                Tenure of the Loan (in days)
              </td>
              <td style="text-align: right; padding-right: 10px">
                {{ sanctionLoanAmountObj?.sanction_LoanTenure }}
              </td>
            </tr>

            <tr class="height-sanctionDetail">
              <td style="text-align: center; width: 11%">3</td>
              <td style="text-align: left; width: 72%; padding-left: 10px">
                Repayment Date
              </td>
              <td style="text-align: right; padding-right: 10px">
                {{ sanctionLoanAmountObj?.RepaymentDate }}
              </td>
            </tr>

            <tr class="height-sanctionDetail">
              <td style="text-align: center; width: 11%">4</td>
              <td style="text-align: left; width: 72%; padding-left: 10px">
                Repayment frequency by the borrower
              </td>
              <td style="text-align: right; padding-right: 10px">One time</td>
            </tr>
            <tr class="height-sanctionDetail">
              <td style="text-align: center; width: 11%">6</td>
              <td style="text-align: left; width: 72%; padding-left: 10px">
                Number of instalments of repayment
              </td>
              <td style="text-align: right; padding-right: 10px">1</td>
            </tr>
            <tr class="height-sanctionDetail">
              <td style="text-align: center; width: 11%">5</td>
              <td style="text-align: left; width: 72%; padding-left: 10px">
                Amount of each instalment of repayment (in Rupees)
              </td>
              <td style="text-align: right; padding-right: 10px">
                {{ sanctionLoanAmountObj?.PaidByBorrower }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
