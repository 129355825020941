<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form
  #dbf="ngForm"
  (ngSubmit)="onSearchInsuranceDetails()"
  novalidate
  class="formborder"
>
  <div class="row m-0">
    <div class="col-md-2 p-1">
      <span class="required-lable">Insurance Category : </span>
      <select
        name="Category"
        id="Category"
        #refCategory="ngModel"
        [ngClass]="{
          'is-invalid': dbf.submitted && refCategory.invalid,
          'alert-warning':
            refCategory.invalid &&
            (refCategory.dirty || refCategory.touched || refCategory.untouched)
        }"
        [(ngModel)]="Category"
        class="form-control input-text-css"
        required
      >
        <option value="0">Select Product</option>
        <option value="Vehicle Loan">Vehicle Loan</option>
        <option value="Property Loan">Property Loan</option>
        <option value="Customer">Customer</option>
      </select>
    </div>

    <div class="col-md-2 p-1">
      <span>From Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="FromDate"
          id="FromDate"
          #refFromDate="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="FromDate"
          [ngClass]="{
            'is-invalid': dbf.submitted && refFromDate.invalid,
            'alert-warning':
              refFromDate.invalid &&
              (refFromDate.dirty ||
                refFromDate.touched ||
                refFromDate.untouched)
          }"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>
    <div class="col-md-2 p-1">
      <span>To Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="ToDate"
          id="ToDate"
          #refToDate="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="ToDate"
          [ngClass]="{
            'is-invalid': dbf.submitted && refToDate.invalid,
            'alert-warning':
              refToDate.invalid &&
              (refToDate.dirty || refToDate.touched || refToDate.untouched)
          }"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
    </div>
    <div class="col-md-2 p-1">
      <span> Branch</span>
      <select
        name="Branch"
        id="Branch"
        #refBranch="ngModel"
        [(ngModel)]="Branch"
        class="form-control input-text-css"
        [ngClass]="{
          'is-invalid': dbf.submitted && refBranch.invalid,
          'alert-warning':
            refBranch.invalid &&
            (refBranch.dirty || refBranch.touched || refBranch.untouched)
        }"
      >
        <option value="0">All</option>
        <option *ngFor="let branch of BranchesList" [value]="branch.BranchId">
          {{ branch.Branch_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-1 p-1 text-right mr-1">
      <button
        type="button"
        (click)="onSearchInsuranceDetails()"
        class="mt-3 btn font-size-12 button-btn"
        [disabled]="!dbf.form.valid"
      >
        Search
      </button>
    </div>
  </div>

  <div
    class="col-md-4 mt-2"
    *ngIf="Category == 'Property Loan' && DataSourceProperty"
  >
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilterProperty($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div
    class="col-md-4 mt-2"
    *ngIf="Category == 'Vehicle Loan' && DataSourceVehicle"
  >
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilterVehicle($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div
    class="col-md-4 mt-2"
    style="margin-left: 2px"
    *ngIf="Category == 'Customer' && DataSourceCustomer"
  >
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilterCustomer($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</form>

<div class="mt-3" *ngIf="Category == 'Property Loan' && DataSourceProperty">
  <div class="">
    <div class="table-responsive">
      <mat-table
        [dataSource]="DataSourceProperty"
        matSort
        matTableExporter
        #exporter="matTableExporter"
        style="max-width: 100%; overflow: auto; height: 60vh"
      >
        <ng-container matColumnDef="Id">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 50px"
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 50px"
          >
            {{ i + 1 }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.LoanAcNo
          }}</mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="Application_No">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Application No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Application_No
          }}</mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="Loan_Date">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Loan_Date | date : "dd/MM/yyyy"
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ExpiryDate">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >ExpiryDate</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ExpiryDate
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Branch_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Branch</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Branch_Name
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Product_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Product</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Product_Name
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Customer">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Customer</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Customer
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="TypeofProperty">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Property Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.TypeofProperty
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Application_LoanAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
            row.Application_LoanAmount
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="PropertyOwnerName">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Property Owner</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.PropertyOwnerName
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="NatureofProperty">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Nature of Property</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.NatureofProperty
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="OwnershipType">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Ownership Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.OwnershipType
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="OwnershipDocument">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Ownership Document</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.OwnershipDocument
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="propertyAddress">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 150px"
            >Property Address</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 150px"
            >{{ row.propertyAddress }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="TotalArea">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Total Area</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.TotalArea
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ConstructedArea">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Constructed Area</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ConstructedArea
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="TypeofMortgage">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Type of Mortgage</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.TypeofMortgage
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="MortgageSingedBy">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Mortgage Singed By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.MortgageSingedBy
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValuationAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >ValuationAmount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
            row.ValuationAmount
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValuationDoneBy">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Valuation Done By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ValuationDoneBy
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValuationRemark">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Valuation Remark</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ValuationRemark
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="GeoLocation">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >GeoLocation</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.GeoLocation
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="InsuranceCompany">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >InsuranceCompany</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.InsuranceCompany
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Insurance_Branch">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Insurance_Branch</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Insurance_Branch
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="AssetPolicyType">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Asset Policy Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.AssetPolicyType
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="AssetAgentName">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Asset Agent Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.SearchReportDoneBy
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="AssetCoverNoteNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Asset Cover Note No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.AssetCoverNoteNo
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="AssetInsuranceDate">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Asset Insurance Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.AssetInsuranceDate
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="AssetRenewalDate">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Asset Renewal Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.AssetRenewalDate
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="AssetIdvAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Asset Idv Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.AssetIdvAmount
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="AssetPremiumAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Asset Premium Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.AssetPremiumAmount
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="AssetNomineeRelation">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Asset Nominee Relation</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.AssetNomineeRelation
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="AssetNomineeName">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Asset Nominee Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.AssetNomineeName
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Nominee_DOB">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Nominee DOB</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Nominee_DOB | date : "dd-MM-yyyy"
          }}</mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumnsProperty; sticky: true"
        ></mat-header-row>
        <mat-row
          (click)="highlightRow(row)"
          [ngClass]="{ highlight: row.Id == selectedId }"
          *matRowDef="let row; columns: displayedColumnsProperty"
        ></mat-row>
      </mat-table>
      <div class="row m-0 align-items-center">
        <div class="col-md-4">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportTable()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mt-3" *ngIf="Category == 'Vehicle Loan' && DataSourceVehicle">
  <div class="">
    <div class="table-responsive">
      <mat-table
        [dataSource]="DataSourceVehicle"
        matSort
        matTableExporter
        #exporter="matTableExporter"
        style="max-width: 100%; overflow: auto; height: 60vh"
      >
        <ng-container matColumnDef="Id">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 50px"
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 50px"
          >
            {{ i + 1 }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.LoanAcNo
          }}</mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="Application_No">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Application No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Application_No
          }}</mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="Loan_Date">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Loan_Date | date : "dd/MM/yyyy"
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ExpiryDate">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >ExpiryDate</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ExpiryDate
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Branch_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Branch</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Branch_Name
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Product_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Product</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Product_Name
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Customer">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Customer</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Customer
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="TypeofProperty">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Property Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.TypeofProperty
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Application_LoanAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
            row.Application_LoanAmount
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="PropertyOwnerName">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Property Owner</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.PropertyOwnerName
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="NatureofProperty">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Nature of Property</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.NatureofProperty
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="OwnershipType">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Ownership Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.OwnershipType
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="OwnershipDocument">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Ownership Document</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.OwnershipDocument
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="propertyAddress">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 150px"
            >Property Address</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 150px"
            >{{ row.propertyAddress }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="TotalArea">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Total Area</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.TotalArea
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ConstructedArea">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Constructed Area</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ConstructedArea
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="TypeofMortgage">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Type of Mortgage</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.TypeofMortgage
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="MortgageSingedBy">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Mortgage Singed By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.MortgageSingedBy
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValuationAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >ValuationAmount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
            row.ValuationAmount
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValuationDoneBy">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Valuation Done By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ValuationDoneBy
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ValuationRemark">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Valuation Remark</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ValuationRemark
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="GeoLocation">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >GeoLocation</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.GeoLocation
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="InsuranceCompany">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >InsuranceCompany</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.InsuranceCompany
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Insurance_Branch">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Insurance_Branch</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Insurance_Branch
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="AssetPolicyType">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Asset Policy Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.AssetPolicyType
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="AssetAgentName">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Asset Agent Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.SearchReportDoneBy
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="AssetCoverNoteNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Asset Cover Note No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.AssetCoverNoteNo
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="AssetInsuranceDate">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Asset Insurance Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.AssetInsuranceDate
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="AssetRenewalDate">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Asset Renewal Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.AssetRenewalDate
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="AssetIdvAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Asset Idv Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.AssetIdvAmount
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="AssetPremiumAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Asset Premium Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.AssetPremiumAmount
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="AssetNomineeRelation">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Asset Nominee Relation</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.AssetNomineeRelation
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="AssetNomineeName">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Asset Nominee Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.AssetNomineeName
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Nominee_DOB">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Nominee_DOB</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Nominee_DOB | date : "dd-MM-yyyy"
          }}</mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumnsVehicle; sticky: true"
        ></mat-header-row>
        <mat-row
          (click)="highlightRow(row)"
          [ngClass]="{ highlight: row.Id == selectedId }"
          *matRowDef="let row; columns: displayedColumnsVehicle"
        ></mat-row>
      </mat-table>

      <div class="row m-0 align-items-center">
        <div class="col-md-4">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportTable()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mt-3" *ngIf="Category == 'Customer' && DataSourceCustomer">
  <div class="">
    <div class="table-responsive">
      <mat-table
        [dataSource]="DataSourceCustomer"
        matSort
        matTableExporter
        #exporter="matTableExporter"
        style="max-width: 100%; overflow: auto; height: 60vh"
      >
        <ng-container matColumnDef="Id">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 50px"
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 50px"
          >
            {{ i + 1 }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="LoanAcNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.LoanAcNo
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Loan_Date">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Loan Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Loan_Date | date : "dd/MM/yyyy"
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ExpiryDate">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >ExpiryDate</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.ExpiryDate
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Branch_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Branch</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Branch_Name
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Product_Name">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Product</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Product_Name
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Customer">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Customer</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Customer
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="InsuranceCustomer">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Insurance Customer</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.InsuranceCustomer
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Customer_DOB">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Customer DOB</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Customer_DOB
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="CustomerInsAgentName">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Customer Ins Agent Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
            row.CustomerInsAgentName
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="InsuranceCompany">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Insurance Company</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.InsuranceCompany
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Insurance_Branch">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Insurance Branch</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Insurance_Branch
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="CustomerInsExpireDate">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Customer Ins Expire Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.CustomerInsExpireDate
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="CustomerInsInsuranceDate">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Customer Insurance Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.CustomerInsInsuranceDate
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="CustomerInsNomineeName">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
            style="max-width: 150px"
            >Nominee Name</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 150px"
            >{{ row.CustomerInsNomineeName }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="CustomerInsNomineeRelationHirer">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Relation</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.AssetNomineeRelation
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Nominee_DOB">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Nominee DOB</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.Nominee_DOB | date : "dd-MM-yyyy"
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="CustomerInsPolicyNo">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >PolicyNo</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.CustomerInsPolicyNo
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="CustomerInsPolicyType">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Policy Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.CustomerInsPolicyType
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="CustomerInsPremiumAmount">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Premium Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.CustomerInsPremiumAmount
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="CustomerInsPremiumNextDueDate">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Premium Next Due Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
            row.CustomerInsPremiumNextDueDate
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="CustomerInsSumAssured">
          <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
            >Sum Assured</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">{{
            row.CustomerInsSumAssured
          }}</mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumnsCustomer; sticky: true"
        ></mat-header-row>
        <mat-row
          (click)="highlightRow(row)"
          [ngClass]="{ highlight: row.Id == selectedId }"
          *matRowDef="let row; columns: displayedColumnsCustomer"
        ></mat-row>
      </mat-table>

      <div class="row m-0 align-items-center">
        <div class="col-md-4">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportTable()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="insurance_details"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
  *ngIf="Category == 'Property Loan' && DataSourceProperty"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Expiry Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Property Type
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Property Owner
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Nature of Property
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Ownership Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Ownership Document
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Property Address
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Area
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Constructed Area
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Type of Motgage
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Mortgage Signed By
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Valuation Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Valuation Done By
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Valuation Remark
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Geo Location
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Insurance Company
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Insurance Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Asset Policy Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Asset Agent Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Asset Cover Note No
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Asset Insurance Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Asset Renewal Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Asset Idv Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Asset Premium Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Nominee Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Nominee Relation
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Nominee DOB
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.ExpiryDate }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_Name }}</td>

    <td>{{ x.Customer }}</td>
    <td>{{ x.TypeofProperty }}</td>

    <td>{{ x.Application_LoanAmount }}</td>
    <td>{{ x.PropertyOwnerName }}</td>
    <td>{{ x.NatureofProperty }}</td>
    <td>{{ x.OwnershipType }}</td>
    <td>{{ x.OwnershipDocument }}</td>
    <td>{{ x.propertyAddress }}</td>
    <td>{{ x.TotalArea }}</td>
    <td>{{ x.ConstructedArea }}</td>
    <td>{{ x.TypeofMortgage }}</td>
    <td>{{ x.MortgageSingedBy }}</td>

    <td>{{ x.ValuationAmount }}</td>
    <td>{{ x.ValuationDoneBy }}</td>

    <td>{{ x.ValuationRemark }}</td>

    <td>{{ x.GeoLocation }}</td>
    <td>{{ x.InsuranceCompany }}</td>
    <td>{{ x.Insurance_Branch }}</td>
    <td>{{ x.AssetPolicyType }}</td>
    <td>{{ x.AssetAgentName }}</td>
    <td>{{ x.AssetCoverNoteNo }}</td>
    <td>{{ x.AssetInsuranceDate }}</td>

    <td>{{ x.AssetRenewalDate }}</td>
    <td>{{ x.AssetIdvAmount }}</td>

    <td>{{ x.AssetPremiumAmount }}</td>
    <td>{{ x.AssetNomineeName }}</td>
    <td>{{ x.AssetNomineeRelation }}</td>
    <td>{{ x.Nominee_DOB | date : "dd-MM-yyyy" }}</td>
  </tr>
</table>

<table
  id="insurance_details"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
  *ngIf="Category == 'Customer' && DataSourceCustomer"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Expiry Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Insurance Customer
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer DOB
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Agent Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Insurance Company
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Insurance Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Insurance Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Insurance Expire Date
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Nominee Name
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Nominee Relation
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Nominee DOB
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Policy No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Policy Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Premium Amount
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Premium Next Due Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Sum Assured
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.ExpiryDate }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_Name }}</td>

    <td>{{ x.Customer }}</td>
    <td>{{ x.InsuranceCustomer }}</td>
    <td>{{ x.Customer_DOB }}</td>

    <td>{{ x.CustomerInsAgentName }}</td>
    <td>{{ x.InsuranceCompany }}</td>
    <td>{{ x.Insurance_Branch }}</td>
    <td>{{ x.CustomerInsInsuranceDate }}</td>
    <td>{{ x.CustomerInsExpireDate }}</td>
    <td>{{ x.CustomerInsNomineeName }}</td>
    <td>{{ x.AssetNomineeRelation }}</td>
    <td>{{ x.Nominee_DOB | date : "dd-MM-yyyy" }}</td>
    <td>{{ x.CustomerInsPolicyNo }}</td>
    <td>{{ x.CustomerInsPolicyType }}</td>
    <td>{{ x.CustomerInsPremiumAmount }}</td>

    <td>{{ x.CustomerInsPremiumNextDueDate }}</td>
    <td>{{ x.CustomerInsSumAssured }}</td>
  </tr>
</table>

<table
  id="insurance_details"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
  *ngIf="Category == 'Vehicle Loan' && DataSourceVehicle"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Expiry Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Property Type
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Property Owner
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Nature of Property
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Ownership Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Ownership Document
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Property Address
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Area
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Constructed Area
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Type of Motgage
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Mortgage Signed By
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Valuation Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Valuation Done By
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Valuation Remark
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Geo Location
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Insurance Company
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Insurance Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Asset Policy Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Asset Agent Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Asset Cover Note No
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Asset Insurance Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Asset Renewal Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Asset Idv Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Asset Premium Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Nominee Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Nominee Relation
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Nominee DOB
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.ExpiryDate }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_Name }}</td>

    <td>{{ x.Customer }}</td>
    <td>{{ x.TypeofProperty }}</td>

    <td>{{ x.Application_LoanAmount }}</td>
    <td>{{ x.PropertyOwnerName }}</td>
    <td>{{ x.NatureofProperty }}</td>
    <td>{{ x.OwnershipType }}</td>
    <td>{{ x.OwnershipDocument }}</td>
    <td>{{ x.propertyAddress }}</td>
    <td>{{ x.TotalArea }}</td>
    <td>{{ x.ConstructedArea }}</td>
    <td>{{ x.TypeofMortgage }}</td>
    <td>{{ x.MortgageSingedBy }}</td>

    <td>{{ x.ValuationAmount }}</td>
    <td>{{ x.ValuationDoneBy }}</td>

    <td>{{ x.ValuationRemark }}</td>

    <td>{{ x.GeoLocation }}</td>
    <td>{{ x.InsuranceCompany }}</td>
    <td>{{ x.Insurance_Branch }}</td>
    <td>{{ x.AssetPolicyType }}</td>
    <td>{{ x.AssetAgentName }}</td>
    <td>{{ x.AssetCoverNoteNo }}</td>
    <td>{{ x.AssetInsuranceDate }}</td>

    <td>{{ x.AssetRenewalDate }}</td>
    <td>{{ x.AssetIdvAmount }}</td>

    <td>{{ x.AssetPremiumAmount }}</td>
    <td>{{ x.AssetNomineeName }}</td>
    <td>{{ x.AssetNomineeRelation }}</td>
    <td>{{ x.Nominee_DOB | date : "dd-MM-yyyy" }}</td>
  </tr>
</table>
