import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  Injectable,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../services/lms.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MasterService } from "../../Shared/app.Masters.Service";
import { DialogService } from "../../Shared/dialog.service";
declare var $: any;
import * as moment from "moment";

@Component({
  selector: "app-lms-Update-EffectiveIrr",
  templateUrl: "./lms-Update-EffectiveIrr.component.html",
  styleUrls: ["./lms-Update-EffectiveIrr.component.scss"],
})
export class LmsUpdateEffectiveIrrComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  loading: boolean = false;

  PostDateFrom: any;
  PostDateTo: any;
  LoanAcNo: any;

  dataSource: any;
  displayedColumns: string[] = [
    "Loan_Id",
    "LoanAcNo",
    "Loan_Date",
    "Application_FirstEMIDate",
    "Tenure",
    "No_Of_Inst",
    "EMI_Amount",
  ];
  JsonData: any[] = [];
  dialogreturn: any;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private dialog: DialogService,
    private lmsService: LmsService,
    private MasterService: MasterService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next(" Update Effective Irr");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.displayedColumns = [
      "Loan_Id",
      "LoanAcNo",
      "Loan_Date",
      "Application_FirstEMIDate",
      "Tenure",
      "No_Of_Inst",
      "EMI_Amount",
    ];
    this.onSearchEffectiveIrr();

}

  onSearchEffectiveIrr() {
    this.showSpinner = true;
    this.lmsService
      .LMS_Get_effectiveIrr_Update_List({})
      .subscribe((res: any) => {
        console.log("res", res);
        if (res.length > 0) {
          this.JsonData = JSON.parse(JSON.stringify(res));
          this.dataSource = new MatTableDataSource(this.JsonData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.dataSource = null;
        }
        this.showSpinner = false;
      });
  }
  OnUpdateEffectiveIrr() {
    this.JsonData.forEach((obj: any) => {
      this.lmsService.effectiveIrrCalculate({
        Loan_Id: Number(obj.Loan_Id),
        Loan_Date: new Date(obj.Loan_Date),
        Loan_First_EMIDate: new Date(obj.Application_FirstEMIDate),
        LoginUserId: this.currentUser.UserId,
        Tenure: Number(obj.Tenure),
        DisbIRR: Number(obj.DisbIRR),
        Case_IRR: Number(obj.Case_IRR),
        InterestAmount: Number(obj.InterestAmount),
        IRR_CalculateBy: obj.IRR_CalculateBy,
        EMI_Type: obj.EMI_Type,
        NetFinance_Amt: Number(obj.NetFinance_Amt),
        DaysInYear: Number(obj.DaysInYear),
        No_Of_Inst: Number(obj.No_Of_Inst),
        EMI_Amount: Number(obj.EMI_Amount),
        Adv_Inst: Number(obj.Adv_Inst)
      }).subscribe((res: any) => {


        if (res[0].CODE == 0) {
         
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
    })
  }
}
