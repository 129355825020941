<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<form #ta="ngForm" novalidate class="formborder">
  <div class="row m-0 col-md-12 p-0 d-flex justify-content-between">
    <div class="col-md-3">
      <span class="required-lable"> End Month Year </span>
      <input
        name="en_month_year"
        [(ngModel)]="en_month_year"
        id="en_month_year"
        placeholder="End Month Year"
        class="form-control input-text-css"
        bsDatepicker
        [bsConfig]="{ dateInputFormat: 'MM/YYYY' }"
        (ngModelChange)="Get_Collection_Target_VS_Achievement()"
        (onShown)="onOpenCalendar($event)"
        required
        #refen_month_year="ngModel"
        [ngClass]="{
          'is-invalid': ta.submitted && refen_month_year.invalid,
          'alert-warning':
            refen_month_year.invalid &&
            (refen_month_year.dirty ||
              refen_month_year.touched ||
              refen_month_year.untouched)
        }"
      />
    </div>

    <div class="col-md-4 mt-3">
      <button
        class="mt-1 btn font-size-12 button-btn"
        (click)="exportexcelNew()"
      >
        <i
          class="fa fa-file-export mr-1"
          style="font-size: medium; cursor: pointer"
        ></i>
        Export
      </button>
    </div>
  </div>
</form>
<div class="content">
  <div class="loanInfo my-1 formborder" style="overflow: auto">
    <div class="grid-container" *ngIf="Level1.length > 0">
      <table
        mat-table
        *ngIf="C_Level1"
        [dataSource]="Level1"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [attr.rowspan]="C_Level1[iCol][iRow].span"
            [style.display]="C_Level1[iCol][iRow].span === 0 ? 'none' : ''"
          >
            {{ row[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
      </table>
    </div>

    <div class="grid-container" *ngIf="Level2.length > 0">
      <table
        mat-table
        *ngIf="C_Level2"
        [dataSource]="Level2"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [attr.rowspan]="C_Level2[iCol][iRow].span"
            [style.display]="C_Level2[iCol][iRow].span === 0 ? 'none' : ''"
          >
            <div *ngIf="columnName != 'Emp_Name'">{{ row[columnName] }}</div>
            <a
              href="javascript:void(0)"
              (click)="goToEmp_NameDetail(row)"
              *ngIf="columnName == 'Emp_Name'"
              >{{ row[columnName] }}</a
            >
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
      </table>
    </div>

    <div class="grid-container" *ngIf="Level3.length > 0">
      <table
        mat-table
        *ngIf="C_Level3"
        [dataSource]="Level3"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [attr.rowspan]="C_Level3[iCol][iRow].span"
            [style.display]="C_Level3[iCol][iRow].span === 0 ? 'none' : ''"
          >
            {{ row[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
      </table>
    </div>

    <div class="grid-container" *ngIf="Level4.length > 0">
      <table
        mat-table
        *ngIf="C_Level4"
        [dataSource]="Level4"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [attr.rowspan]="C_Level4[iCol][iRow].span"
            [style.display]="C_Level4[iCol][iRow].span === 0 ? 'none' : ''"
          >
            {{ row[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
      </table>
    </div>

    <div class="grid-container" *ngIf="Level5.length > 0">
      <table
        mat-table
        *ngIf="C_Level5"
        [dataSource]="Level5"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [attr.rowspan]="C_Level5[iCol][iRow].span"
            [style.display]="C_Level5[iCol][iRow].span === 0 ? 'none' : ''"
          >
            {{ row[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
      </table>
    </div>
    <div class="grid-container" *ngIf="Level6.length > 0">
      <table
        mat-table
        *ngIf="C_Level6"
        [dataSource]="Level6"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [attr.rowspan]="C_Level6[iCol][iRow].span"
            [style.display]="C_Level6[iCol][iRow].span === 0 ? 'none' : ''"
          >
            {{ row[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
      </table>
    </div>
    <div class="grid-container" *ngIf="Level7.length > 0">
      <table
        mat-table
        *ngIf="C_Level7"
        [dataSource]="Level7"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [attr.rowspan]="C_Level7[iCol][iRow].span"
            [style.display]="C_Level7[iCol][iRow].span === 0 ? 'none' : ''"
          >
            {{ row[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
      </table>
    </div>

    <div class="grid-container" *ngIf="Level8.length > 0">
      <table
        mat-table
        *ngIf="C_Level8"
        [dataSource]="Level8"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [attr.rowspan]="C_Level8[iCol][iRow].span"
            [style.display]="C_Level8[iCol][iRow].span === 0 ? 'none' : ''"
          >
            {{ row[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
      </table>
    </div>

    <div class="grid-container" *ngIf="Level9.length > 0">
      <table
        mat-table
        *ngIf="C_Level9"
        [dataSource]="Level9"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [attr.rowspan]="C_Level9[iCol][iRow].span"
            [style.display]="C_Level9[iCol][iRow].span === 0 ? 'none' : ''"
          >
            {{ row[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
      </table>
    </div>

    <div class="grid-container" *ngIf="Level10.length > 0">
      <table
        mat-table
        *ngIf="C_Level10"
        [dataSource]="Level10"
        class="mat-elevation-z8"
      >
        <ng-container
          *ngFor="let columnName of columnNames; let iCol = index"
          [matColumnDef]="columnName"
        >
          <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
          <td
            mat-cell
            *matCellDef="let row; let iRow = index"
            [attr.rowspan]="C_Level10[iCol][iRow].span"
            [style.display]="C_Level10[iCol][iRow].span === 0 ? 'none' : ''"
          >
            {{ row[columnName] }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-row"
          *matHeaderRowDef="columnNames"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
      </table>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="CollectionTarget"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div
      cdkDrag
      cdkDragRootElement=".cdk-overlay-pane"
      id="Collection_model"
      class="modal-content"
    >
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Collection Target
        </h6>
        <button
          type="button"
          (click)="onCloseCollectionTarget()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row col-md-12 m-0 mt-2 justify-content-end">
          <button
            type="button"
            (click)="OnSave()"
            *ngIf="targetId == 0"
            class="font-size-12 button-btn"
            data-dismiss="modal"
          >
            Save
          </button>
          <button
            type="button"
            (click)="OnRevised()"
            class="ml-4 font-size-12 button-btn"
            *ngIf="targetId > 0"
            [class.spinner]="loading"
          >
            Revised
          </button>
        </div>

        <div class="content">
          <div class="loanInfo my-1">
            <div class="grid-container">
              <div class="row m-0 mt-0">
                <div class="col-md-12 mb-1 alert alert-success">
                  <div class="row">
                    <div class="col-md-6">
                      <span class="fs-14"
                        ><B
                          >Employee Name:
                          {{
                            ModelDetail.length > 0
                              ? ModelDetail[0].Emp_Name
                              : ""
                          }}</B
                        ></span
                      >
                    </div>
                    <div class="col-md-6">
                      <span class="fs-12"
                        ><B
                          >Manager :{{
                            ModelDetail.length > 0 ? ModelDetail[0].Manager : ""
                          }}</B
                        >
                      </span>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <mat-table
                      [dataSource]="dataCSource"
                      matSort
                      matTableExporter
                      style="max-width: 100%; overflow: auto"
                    >
                      <ng-container matColumnDef="TargetId">
                        <mat-header-cell
                          mat-sort-header
                          class="grid-header"
                          style="max-width: 50px"
                          *matHeaderCellDef
                        >
                          #
                        </mat-header-cell>
                        <mat-cell
                          *matCellDef="let row; let i = index"
                          class="grid-cell"
                          style="max-width: 50px; max-height: 5px"
                        >
                          {{
                            paginatorRef.pageIndex * paginatorRef.pageSize +
                              (i + 1)
                          }}
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="C_Month">
                        <mat-header-cell class="grid-header" *matHeaderCellDef
                          >Month</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">{{
                          row.C_Month
                        }}</mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="Type">
                        <mat-header-cell class="grid-header" *matHeaderCellDef
                          >Type</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">{{
                          row.Type
                        }}</mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="SubType">
                        <mat-header-cell class="grid-header" *matHeaderCellDef
                          >SubType</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">{{
                          row.SubType
                        }}</mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="Total">
                        <mat-header-cell class="grid-header" *matHeaderCellDef
                          >Total</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="grid-cell">{{
                          row.Total
                        }}</mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="DPD_001_030">
                        <mat-header-cell class="grid-header" *matHeaderCellDef
                          >DPD_001_030</mat-header-cell
                        >
                        <mat-cell
                          *matCellDef="let row; let i = index"
                          class="grid-cell"
                        >
                          <input
                            name="DPD_001_030{{ i }}"
                            type="number"
                            id="DPD_001_030{{ i }}"
                            *ngIf="row.Type == 'To Be Collected'"
                            (change)="
                              ChangeData(i, row.DPD_001_030, 'DPD_001_030')
                            "
                            [(ngModel)]="row.DPD_001_030"
                            class="form-control input-text-css"
                          />
                          <div *ngIf="row.Type != 'To Be Collected'">
                            {{ row.DPD_001_030 }}
                          </div>
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="DPD_031_060">
                        <mat-header-cell class="grid-header" *matHeaderCellDef
                          >DPD_031_060</mat-header-cell
                        >
                        <mat-cell
                          *matCellDef="let row; let i = index"
                          class="grid-cell"
                        >
                          <input
                            name="DPD_031_060{{ i }}"
                            type="number"
                            id="DPD_031_060{{ i }}"
                            *ngIf="row.Type == 'To Be Collected'"
                            (change)="
                              ChangeData(i, row.DPD_031_060, 'DPD_031_060')
                            "
                            [(ngModel)]="row.DPD_031_060"
                            class="form-control input-text-css"
                          />
                          <div *ngIf="row.Type != 'To Be Collected'">
                            {{ row.DPD_031_060 }}
                          </div>
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="DPD_061_090">
                        <mat-header-cell class="grid-header" *matHeaderCellDef
                          >DPD_061_090</mat-header-cell
                        >
                        <mat-cell
                          *matCellDef="let row; let i = index"
                          class="grid-cell"
                        >
                          <input
                            name="DPD_061_090{{ i }}"
                            type="number"
                            id="DPD_061_090{{ i }}"
                            *ngIf="row.Type == 'To Be Collected'"
                            (change)="
                              ChangeData(i, row.DPD_061_090, 'DPD_061_090')
                            "
                            [(ngModel)]="row.DPD_061_090"
                            class="form-control input-text-css"
                          />
                          <div *ngIf="row.Type != 'To Be Collected'">
                            {{ row.DPD_061_090 }}
                          </div>
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="DPD_091_120">
                        <mat-header-cell class="grid-header" *matHeaderCellDef
                          >DPD_091_120</mat-header-cell
                        >
                        <mat-cell
                          *matCellDef="let row; let i = index"
                          class="grid-cell"
                        >
                          <input
                            name="DPD_091_120{{ i }}"
                            type="number"
                            id="DPD_091_120{{ i }}"
                            *ngIf="row.Type == 'To Be Collected'"
                            (change)="
                              ChangeData(i, row.DPD_091_120, 'DPD_091_120')
                            "
                            [(ngModel)]="row.DPD_091_120"
                            class="form-control input-text-css"
                          />
                          <div *ngIf="row.Type != 'To Be Collected'">
                            {{ row.DPD_091_120 }}
                          </div>
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="DPD_121_150">
                        <mat-header-cell class="grid-header" *matHeaderCellDef
                          >DPD_121_150</mat-header-cell
                        >
                        <mat-cell
                          *matCellDef="let row; let i = index"
                          class="grid-cell"
                        >
                          <input
                            name="DPD_121_150{{ i }}"
                            type="number"
                            id="DPD_121_150{{ i }}"
                            *ngIf="row.Type == 'To Be Collected'"
                            (change)="
                              ChangeData(i, row.DPD_121_150, 'DPD_121_150')
                            "
                            [(ngModel)]="row.DPD_121_150"
                            class="form-control input-text-css"
                          />
                          <div *ngIf="row.Type != 'To Be Collected'">
                            {{ row.DPD_121_150 }}
                          </div>
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="DPD_151_180">
                        <mat-header-cell class="grid-header" *matHeaderCellDef
                          >DPD_151_180</mat-header-cell
                        >
                        <mat-cell
                          *matCellDef="let row; let i = index"
                          class="grid-cell"
                        >
                          <input
                            name="DPD_151_180{{ i }}"
                            type="number"
                            id="DPD_151_180{{ i }}"
                            *ngIf="row.Type == 'To Be Collected'"
                            (change)="
                              ChangeData(i, row.DPD_151_180, 'DPD_151_180')
                            "
                            [(ngModel)]="row.DPD_151_180"
                            class="form-control input-text-css"
                          />
                          <div *ngIf="row.Type != 'To Be Collected'">
                            {{ row.DPD_151_180 }}
                          </div>
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="DPD_181_360">
                        <mat-header-cell class="grid-header" *matHeaderCellDef
                          >DPD_181_360</mat-header-cell
                        >
                        <mat-cell
                          *matCellDef="let row; let i = index"
                          class="grid-cell"
                        >
                          <input
                            name="DPD_181_360{{ i }}"
                            type="number"
                            id="DPD_181_360{{ i }}"
                            *ngIf="row.Type == 'To Be Collected'"
                            (change)="
                              ChangeData(i, row.DPD_181_360, 'DPD_181_360')
                            "
                            [(ngModel)]="row.DPD_181_360"
                            class="form-control input-text-css"
                          />
                          <div *ngIf="row.Type != 'To Be Collected'">
                            {{ row.DPD_181_360 }}
                          </div>
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="DPD_361_720">
                        <mat-header-cell class="grid-header" *matHeaderCellDef
                          >DPD_361_720</mat-header-cell
                        >
                        <mat-cell
                          *matCellDef="let row; let i = index"
                          class="grid-cell"
                        >
                          <input
                            name="DPD_361_720{{ i }}"
                            type="number"
                            id="DPD_361_720{{ i }}"
                            *ngIf="row.Type == 'To Be Collected'"
                            (change)="
                              ChangeData(i, row.DPD_361_720, 'DPD_361_720')
                            "
                            [(ngModel)]="row.DPD_361_720"
                            class="form-control input-text-css"
                          />
                          <div *ngIf="row.Type != 'To Be Collected'">
                            {{ row.DPD_361_720 }}
                          </div>
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="DPD_721_1000">
                        <mat-header-cell class="grid-header" *matHeaderCellDef
                          >DPD_721_1000</mat-header-cell
                        >
                        <mat-cell
                          *matCellDef="let row; let i = index"
                          class="grid-cell"
                        >
                          <input
                            name="DPD_721_1000{{ i }}"
                            type="number"
                            id="DPD_721_1000{{ i }}"
                            *ngIf="row.Type == 'To Be Collected'"
                            (change)="
                              ChangeData(i, row.DPD_721_1000, 'DPD_721_1000')
                            "
                            [(ngModel)]="row.DPD_721_1000"
                            class="form-control input-text-css"
                          />
                          <div *ngIf="row.Type != 'To Be Collected'">
                            {{ row.DPD_721_1000 }}
                          </div>
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="DPD_Above_1000">
                        <mat-header-cell class="grid-header" *matHeaderCellDef
                          >DPD_Above_1000</mat-header-cell
                        >
                        <mat-cell
                          *matCellDef="let row; let i = index"
                          class="grid-cell"
                        >
                          <input
                            name="DPD_Above_1000{{ i }}"
                            type="number"
                            id="DPD_Above_1000{{ i }}"
                            *ngIf="row.Type == 'To Be Collected'"
                            (change)="
                              ChangeData(
                                i,
                                row.DPD_Above_1000,
                                'DPD_Above_1000'
                              )
                            "
                            [(ngModel)]="row.DPD_Above_1000"
                            class="form-control input-text-css"
                          />
                          <div *ngIf="row.Type != 'To Be Collected'">
                            {{ row.DPD_Above_1000 }}
                          </div>
                        </mat-cell>
                      </ng-container>

                      <mat-header-row
                        *matHeaderRowDef="modelcolumnNames; sticky: true"
                      ></mat-header-row>
                      <mat-row
                        *matRowDef="let row; columns: modelcolumnNames"
                      ></mat-row>
                    </mat-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="reviseTargetmodal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    style="margin: 1.75rem auto"
  >
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Revise Target
        </h6>
        <button
          type="button"
          (click)="OnCloseReviseModal()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form #ff="ngForm" novalidate>
          <div>
            <div class="col-md-12 p-1">
              <span class="required-lable">Remarks</span>
              <input
                type="text"
                name="reviseRemarks"
                id="reviseRemarks"
                [(ngModel)]="reviseRemarks"
                class="form-control input-text-css"
                required
                #refreviseRemarks="ngModel"
                placeholder="Remarks"
                [ngClass]="{
                  'is-invalid': ff.submitted && refreviseRemarks.invalid,
                  'alert-warning':
                    refreviseRemarks.invalid &&
                    (refreviseRemarks.dirty ||
                      refreviseRemarks.touched ||
                      refreviseRemarks.untouched)
                }"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          [disabled]="!ff.form.valid"
          class="btn font-size-12 button-btn"
          (click)="saveReviseTarget()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<div id="collectionTarget">
  <table
    *ngIf="C_Level1 && Level1?.length > 0"
    class="table table-bordered bg-white"
    style="font-family: 'Times New Roman', Times, serif; display: none"
  >
    <tr>
      <th
        *ngFor="let header of columnNames"
        style="background: #4dc3a3 !important; border: 1px solid white"
      >
        {{ header }}
      </th>
    </tr>
    <tr *ngFor="let data of Level1; let indRow = index">
      <td *ngFor="let header of columnNames; let indCol = index">
        {{ data[header] }}
      </td>
    </tr>
  </table>

  <hr />

  <table
    *ngIf="C_Level2 && Level2?.length > 0"
    class="table table-bordered bg-white"
    style="font-family: 'Times New Roman', Times, serif; display: none"
  >
    <tr>
      <th
        *ngFor="let header of columnNames"
        style="background: #4dc3a3 !important; border: 1px solid white"
      >
        {{ header }}
      </th>
    </tr>
    <tr *ngFor="let data of Level2; let indRow = index">
      <td *ngFor="let header of columnNames; let indCol = index">
        {{ data[header] }}
      </td>
    </tr>
  </table>

  <hr />

  <table
    *ngIf="C_Level3 && Level3?.length > 0"
    class="table table-bordered bg-white"
    style="font-family: 'Times New Roman', Times, serif; display: none"
  >
    <tr>
      <th
        *ngFor="let header of columnNames"
        style="background: #4dc3a3 !important; border: 1px solid white"
      >
        {{ header }}
      </th>
    </tr>
    <tr *ngFor="let data of Level3; let indRow = index">
      <td *ngFor="let header of columnNames; let indCol = index">
        {{ data[header] }}
      </td>
    </tr>
  </table>

  <hr />

  <table
    *ngIf="C_Level4 && Level4?.length > 0"
    class="table table-bordered bg-white"
    style="font-family: 'Times New Roman', Times, serif; display: none"
  >
    <tr>
      <th
        *ngFor="let header of columnNames"
        style="background: #4dc3a3 !important; border: 1px solid white"
      >
        {{ header }}
      </th>
    </tr>
    <tr *ngFor="let data of Level4; let indRow = index">
      <td *ngFor="let header of columnNames; let indCol = index">
        {{ data[header] }}
      </td>
    </tr>
  </table>

  <hr />

  <table
    *ngIf="C_Level5 && Level5?.length > 0"
    class="table table-bordered bg-white"
    style="font-family: 'Times New Roman', Times, serif; display: none"
  >
    <tr>
      <th
        *ngFor="let header of columnNames"
        style="background: #4dc3a3 !important; border: 1px solid white"
      >
        {{ header }}
      </th>
    </tr>
    <tr *ngFor="let data of Level5; let indRow = index">
      <td *ngFor="let header of columnNames; let indCol = index">
        {{ data[header] }}
      </td>
    </tr>
  </table>

  <hr />

  <table
    *ngIf="C_Level6 && Level6?.length > 0"
    class="table table-bordered bg-white"
    style="font-family: 'Times New Roman', Times, serif; display: none"
  >
    <tr>
      <th
        *ngFor="let header of columnNames"
        style="background: #4dc3a3 !important; border: 1px solid white"
      >
        {{ header }}
      </th>
    </tr>
    <tr *ngFor="let data of Level6; let indRow = index">
      <td *ngFor="let header of columnNames; let indCol = index">
        {{ data[header] }}
      </td>
    </tr>
  </table>

  <hr />

  <table
    *ngIf="C_Level7 && Level7?.length > 0"
    class="table table-bordered bg-white"
    style="font-family: 'Times New Roman', Times, serif; display: none"
  >
    <tr>
      <th
        *ngFor="let header of columnNames"
        style="background: #4dc3a3 !important; border: 1px solid white"
      >
        {{ header }}
      </th>
    </tr>
    <tr *ngFor="let data of Level7; let indRow = index">
      <td *ngFor="let header of columnNames; let indCol = index">
        {{ data[header] }}
      </td>
    </tr>
  </table>

  <hr />

  <table
    *ngIf="C_Level8 && Level8?.length > 0"
    class="table table-bordered bg-white"
    style="font-family: 'Times New Roman', Times, serif; display: none"
  >
    <tr>
      <th
        *ngFor="let header of columnNames"
        style="background: #4dc3a3 !important; border: 1px solid white"
      >
        {{ header }}
      </th>
    </tr>
    <tr *ngFor="let data of Level8; let indRow = index">
      <td *ngFor="let header of columnNames; let indCol = index">
        {{ data[header] }}
      </td>
    </tr>
  </table>
</div>
