import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { ReportsService } from "../services/reports.service";
import { ActivatedRoute } from "@angular/router";
import { LmsService } from "src/app/_Lms/services/lms.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Span, RowSpanComputer } from "../services/row-span-computer";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
declare var $: any;
@Component({
  selector: "app-reports-business-collection-summary",
  templateUrl: "./reports-business-collection-summary.component.html",
  styleUrls: ["./reports-business-collection-summary.component.scss"],
})
export class ReportsBusinessCollectionSummaryComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  ToDate: any = new Date();
  FromDate: any = new Date(new Date().getFullYear(), new Date().getMonth());
  executiveType: any = "";
  showTable: boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  private rowSpanComputer = new RowSpanComputer();

  ModelDetail1: Array<Span[]>;
  C_Level1: Array<Span[]>;
  C_Level2: Array<Span[]>;
  C_Level3: Array<Span[]>;
  C_Level4: Array<Span[]>;
  C_Level5: Array<Span[]>;
  C_Level6: Array<Span[]>;
  C_Level7: Array<Span[]>;
  C_Level8: Array<Span[]>;
  C_Level9: Array<Span[]>;
  C_Level10: Array<Span[]>;

  Level1: any = [];
  Level2: any = [];
  Level3: any = [];
  Level4: any = [];
  Level5: any = [];
  Level6: any = [];
  Level7: any = [];
  Level8: any = [];
  Level9: any = [];
  Level10: any = [];

  columnNames = [
    "Emp_Name",
    "ManagerName",
    "Designation",
    "Business_Numbers",
    "Business_Amount",
    "Opening_Overdue_Numbers",
    "Opening_Overdue_Amount",
    "Current_Due_Numbers",
    "Current_Due_Amount",
    "Current_Collection_Numbers",
    "Current_Collection_Amount",
    "Closing_Overdue_Numbers",
    "Closing_Overdue_Amount",
  ];

  columnNameMapping: { [key: string]: string } = {
    Emp_Name: "Employee Name",
    ManagerName: "Reporting To",
    Designation: "Designation",
    Business_Numbers: "Business Nos",
    Business_Amount: "Business Amount",
    Opening_Overdue_Numbers: "OP OD Nos",
    Opening_Overdue_Amount: "OP OD Amount",
    Current_Due_Numbers: "Current Due Nos",
    Current_Due_Amount: "Current Due Amount",
    Current_Collection_Numbers: "Current Coll Nos",
    Current_Collection_Amount: "Current Coll Amount",
    Closing_Overdue_Numbers: "CL OD Nos",
    Closing_Overdue_Amount: "CL OD Amount",
  };
  dataSourceModal: any;
  displayedColumnsModal: any;
  JsonData: any[] = [];
  totalRow: {};
  Totals_Level2: { [key: string]: number } = {};
  Totals_Level3: { [key: string]: number } = {};
  Totals_Level4: { [key: string]: number } = {};
  Totals_Level5: { [key: string]: number } = {};
  Totals_Level6: { [key: string]: number } = {};
  Totals_Level7: { [key: string]: number } = {};
  Totals_Level8: { [key: string]: number } = {};
  Totals_Level9: { [key: string]: number } = {};
  Totals_Level10: { [key: string]: number } = {};
  modalTitle: any;

  constructor(
    private dataSharingService: DataSharingService,
    private _ReportsService: ReportsService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private lmsService: LmsService
  ) { }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("Business Collection Summary");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.totalRow = {};
    this.columnNames.forEach((column) => {
      if (
        ["Numbers", "Amount", "Total", "Value"].some((key) =>
          column.includes(key)
        )
      ) {
        this.totalRow[column] = 0; // Initialize numeric columns with 0
      } else {
        this.totalRow[column] = ""; // Initialize non-numeric columns with empty string
      }
    });
    this.totalRow["Designation"] = "Total";
  }

  onSearchBusinessCollectionSummary() {
    this.showSpinner = true;

    this.Level1 = [];
    this.Level2 = [];
    this.Level3 = [];
    this.Level4 = [];
    this.Level5 = [];
    this.Level6 = [];
    this.Level7 = [];
    this.Level8 = [];
    this.Level9 = [];
    this.Level10 = [];

    this._ReportsService
      .Report_Business_And_Collection_Summary({
        LoginUserId: this.currentUser.userId,
        FromDate: this.FromDate,
        ToDate: this.ToDate,
        Type: this.executiveType,
      })
      .subscribe((res: any) => {
        console.log("ressssss", res);
        this.showSpinner = false;
        if (res.length > 0) {
          this.Level1 = res.filter((r) => r.EmpId === this.currentUser.userId);
          // console.log("Level 1", this.Level1);

          this.setLevel1();

          this.setLevel(res, this.Level2, this.Level1);
          this.setLevel(res, this.Level3, this.Level2);
          this.setLevel(res, this.Level4, this.Level3);
          this.setLevel(res, this.Level5, this.Level4);
          this.setLevel(res, this.Level6, this.Level5);
          this.setLevel(res, this.Level7, this.Level6);
          this.setLevel(res, this.Level8, this.Level7);
          this.setLevel(res, this.Level9, this.Level8);
          this.setLevel(res, this.Level10, this.Level9);

          this.showTable = true;

          this.Level1 = this.Level1.sort((a, b) => a.EmpId - b.EmpId).sort(
            (a, b) => b.Type - a.Type
          );
          this.Level2 = this.Level2.sort((a, b) => a.EmpId - b.EmpId).sort(
            (a, b) => b.Type - a.Type
          );
          this.Level3 = this.Level3.sort((a, b) => a.EmpId - b.EmpId).sort(
            (a, b) => b.Type - a.Type
          );

          this.Level4 = this.Level4.sort((a, b) => a.EmpId - b.EmpId).sort(
            (a, b) => b.Type - a.Type
          );
          this.Level5 = this.Level5.sort((a, b) => a.EmpId - b.EmpId).sort(
            (a, b) => b.Type - a.Type
          );
          this.Level6 = this.Level6.sort((a, b) => a.EmpId - b.EmpId).sort(
            (a, b) => b.Type - a.Type
          );
          this.Level7 = this.Level7.sort((a, b) => a.EmpId - b.EmpId).sort(
            (a, b) => b.Type - a.Type
          );
          this.Level8 = this.Level8.sort((a, b) => a.EmpId - b.EmpId).sort(
            (a, b) => b.Type - a.Type
          );
          this.Level9 = this.Level9.sort((a, b) => a.EmpId - b.EmpId).sort(
            (a, b) => b.Type - a.Type
          );
          this.Level10 = this.Level10.sort((a, b) => a.EmpId - b.EmpId).sort(
            (a, b) => b.Type - a.Type
          );

          this.C_Level1 = this.rowSpanComputer.compute(
            this.Level1,
            this.columnNames
          );
          this.C_Level2 = this.rowSpanComputer.compute(
            this.Level2,
            this.columnNames
          );

          this.C_Level3 = this.rowSpanComputer.compute(
            this.Level3,
            this.columnNames
          );

          this.C_Level4 = this.rowSpanComputer.compute(
            this.Level4,
            this.columnNames
          );
          this.C_Level5 = this.rowSpanComputer.compute(
            this.Level5,
            this.columnNames
          );
          this.C_Level6 = this.rowSpanComputer.compute(
            this.Level6,
            this.columnNames
          );
          this.C_Level7 = this.rowSpanComputer.compute(
            this.Level7,
            this.columnNames
          );
          this.C_Level8 = this.rowSpanComputer.compute(
            this.Level8,
            this.columnNames
          );
          this.C_Level9 = this.rowSpanComputer.compute(
            this.Level9,
            this.columnNames
          );
          this.C_Level10 = this.rowSpanComputer.compute(
            this.Level10,
            this.columnNames
          );

          this.Totals_Level2 = this.calculateTotals(
            this.Level2,
            this.columnNames
          );

          this.Totals_Level3 = this.calculateTotals(
            this.Level3,
            this.columnNames
          );
          this.Totals_Level4 = this.calculateTotals(
            this.Level4,
            this.columnNames
          );
          this.Totals_Level5 = this.calculateTotals(
            this.Level5,
            this.columnNames
          );
          this.Totals_Level6 = this.calculateTotals(
            this.Level6,
            this.columnNames
          );
          this.Totals_Level7 = this.calculateTotals(
            this.Level7,
            this.columnNames
          );
          this.Totals_Level8 = this.calculateTotals(
            this.Level8,
            this.columnNames
          );
          this.Totals_Level9 = this.calculateTotals(
            this.Level9,
            this.columnNames
          );
          this.Totals_Level10 = this.calculateTotals(
            this.Level10,
            this.columnNames
          );
        }
        else {
          this.showSpinner = false;
        }
      });

  }

  setLevel1() {
    this.Level1.push({
      Emp_Name: this.Level1[0].Emp_Name,
      Designation: this.Level1[0].Designation,
      ManagerName: this.Level1[0].ManagerName,
    });
  }

  setLevel(res, PushLevel, Level) {
    for (let i = 0; i < res.length; i++) {
      for (let j = 0; j < Level.length; j++) {
        if (res[i].Managerid === Level[j].EmpId) {
          PushLevel.push(res[i]);

          console.log("level 2", PushLevel);
        }
      }
    }
  }

  calculateTotals(level: any[], columns: string[]) {
    const totals = {};

    let showFooter = true;

    if (level.length <= 1) {
      // console.log("level.length", level.length);
      showFooter = false;
      return totals;
    }

    columns.forEach((col) => {
      if (col == "Designation") {
        totals[col] = "Total:";
      } else if (
        [
          "Business_Numbers",
          "Business_Amount",
          "Opening_Overdue_Numbers",
          "Opening_Overdue_Amount",
          "Current_Due_Numbers",
          "Current_Due_Amount",
          "Current_Collection_Numbers",
          "Current_Collection_Amount",
          "Closing_Overdue_Numbers",
          "Closing_Overdue_Amount",
        ].includes(col)
      ) {
        totals[col] = level.reduce(
          (sum, row) => sum + (Number(row[col]) || 0),
          0
        );
      }
    });
    // this.showFooterRow = showFooter;
    return totals;
  }
  goToEmployeeNameDetail(row) {
    // console.log("ROWWWWWWWWWW", row);

    this.modalTitle = this.executiveType;

    this._ReportsService
      .Report_Business_And_Collection({
        LoginUserId: row.EmpId,
        FromDate: this.FromDate,
        ToDate: this.ToDate,
        Type: this.executiveType,
      })
      .subscribe((res: any) => {
        // console.log("ressssssssssssssss modal", res);

        this.displayedColumnsModal = [
          "Loan_Id",
          "LoanAcNo",
          "Branch_Name",
          "Product_Name",
          "Executive",
          "CustomerName",
          "Loan_Acc_Category",
          "Business_Numbers",
          "Business_Amount",
          "Opening_Overdue_Numbers",
          "Opening_Overdue_Amount",
          "Current_Due_Numbers",
          "Current_Due_Amount",
          "Current_Collection_Numbers",
          "Current_Collection_Amount",
          "Closing_Overdue_Numbers",
          "Closing_Overdue_Amount",
        ];

        this.JsonData = JSON.parse(JSON.stringify(res));

        this.dataSourceModal = new MatTableDataSource(
          JSON.parse(JSON.stringify(res))
        );
        this.dataSourceModal.paginator = this.paginator;
      });

    $(`#modalBusinessCollectionDetail`).modal("show");
    $(`#modalBusinessCollectionDetail`).css("z-index", "1050");
  }

  totaltypewise(type) {
    if (this.dataSourceModal != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSourceModal.filteredData.length; i++) {
        sum += Number(this.dataSourceModal.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : 0;
    }
  }

  onCloseBusinessCollectionModal() {
    $(`#modalBusinessCollectionDetail`).modal("hide");
  }

  applyFilter(filterValue: any) {
    // this.DataSourceVehicle.filter = filterValue.trim().toLowerCase();
  }

  exportexcelNew(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "business_collection_report"
    ) as HTMLTableElement;
    const worksheetName = "BUSINESS COLLECTION REPORT"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
  exportexcelModal(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "reports_business_modal_table"
    ) as HTMLTableElement;
    const worksheetName = "BUSINESS COLLECTION DETAIL"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
  // Excel export in backend code end here

  applyFilterModal(filterValue: String) {
    this.dataSourceModal.filter = filterValue.trim().toLowerCase();
  }
}
