// #region Import Used Components and Services
import { Injectable } from "@angular/core";
import { throwError, Observable, BehaviorSubject } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "src/app/Shared/constantUrl";
import { GetrSaveresponseModel } from "../../Shared/Models/app.MasterResponseModel";
// #endregion

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  private lastAppId = new BehaviorSubject<any>(null);
  private lastFilterDropdownValue = new BehaviorSubject<any>({});

  constructor(
    private _Route: Router,
    private http: HttpClient,
    public snackBar: MatSnackBar
  ) {}

  // #region Get_Customer_Balance
  public Get_Customer_Balance(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Reports/Get_Customer_Balance";
    return this.http.post(apiUrl, requestModel);
  }
  // #endregion

  public Get_Due_List(requestModel: any) {
    var apiUrl = constantUrl.apiEndpoint + "/api/Reports/LMS_Get_DueList";
    return this.http.post(apiUrl, requestModel);
  }

  // #Report_ProductWiseBusiness
  public Report_ProductWiseBusiness(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_ProductWiseBusiness`,
      requestData
    );
  }
  // #end Report_ProductWiseBusiness

  // #Report_BranchWiseBusiness
  public Report_BranchWiseBusiness(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_BranchWiseBusiness`,
      requestData
    );
  }
  // #end Report_BranchWiseBusiness

  // #Report_Amount_WiseBusiness
  public Report_Amount_WiseBusiness(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Amount_WiseBusiness`,
      requestData
    );
  }
  // #end Report_Amount_WiseBusiness

  // #Report_Tenure_WiseBusiness
  public Report_Tenure_WiseBusiness(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Tenure_WiseBusiness`,
      requestData
    );
  }
  // #end Report_Tenure_WiseBusiness

  // #Report_IRR_WiseBusiness
  public Report_IRR_WiseBusiness(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_IRR_WiseBusiness`,
      requestData
    );
  }
  // #end Report_IRR_WiseBusiness

  // #Report_LTV_WiseBusiness
  public Report_LTV_WiseBusiness(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_LTV_WiseBusiness`,
      requestData
    );
  }
  // #end Report_LTV_WiseBusiness

  // #Report_BranchWisePortfolio
  public Report_BranchWisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_BranchWisePortfolio`,
      requestData
    );
  }
  // #end Report_BranchWisePortfolio

  // #Report_ProductWisePortfolio
  public Report_ProductWisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_ProductWisePortfolio`,
      requestData
    );
  }

  // #end Report_ProductWisePortfolio
  // #Report_LoanAccCategoryWisePortfolio
  public Report_LoanAccCategoryWisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_LoanAccCategoryWisePortfolio`,
      requestData
    );
  }
  // #end Report_LoanAccCategoryWisePortfolio

  // #Report_LoanAccCategoryWiseBusiness
  public Report_LoanAccCategoryWiseBusiness(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_LoanAccCategoryWiseBusiness`,
      requestData
    );
  }
  // #end Report_LoanAccCategoryWiseBusiness
  // #Report_Amount_WisePortfolio
  public Report_Amount_WisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Amount_WisePortfolio`,
      requestData
    );
  }
  // #end Report_Amount_WisePortfolio

  // #Report_Tenure_WisePortfolio
  public Report_Tenure_WisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Tenure_WisePortfolio`,
      requestData
    );
  }
  // #end Report_Tenure_WisePortfolio

  // #Report_IRR_WisePortfolio
  public Report_IRR_WisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_IRR_WisePortfolio`,
      requestData
    );
  }
  // #end Report_IRR_WisePortfolio

  // #Report_LTV_WisePortfolio
  public Report_LTV_WisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_LTV_WisePortfolio`,
      requestData
    );
  }
  // #end Report_LTV_WisePortfolio

  // #Report_Business_VS_Collection
  public Report_Business_VS_Collection(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Business_VS_Collection`,
      requestData
    );
  }
  // #end Report_Business_VS_Collection

  // #Report_Month_Wise_DUE_VS_Collection
  public Report_Month_Wise_DUE_VS_Collection(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Month_Wise_DUE_VS_Collection`,
      requestData
    );
  }
  // #end Report_Month_Wise_DUE_VS_Collection

  // #Report_Branch_Wise_DUE_VS_Collection
  public Report_Branch_Wise_DUE_VS_Collection(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Branch_Wise_DUE_VS_Collection`,
      requestData
    );
  }
  // #end Report_Branch_Wise_DUE_VS_Collection

  // #Report_Booking_List
  public Report_Booking_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Booking_List`,
      requestData
    );
  }
  // #end Report_Booking_List

  // #Report_PAR
  public Report_PAR(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_PAR`,
      requestData
    );
  }
  // #end Report_PAR

  // #Report_NPA_Detail
  public Report_NPA_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_NPA_Detail`,
      requestData
    );
  }
  // #end Report_NPA_Detail

  // #Report_NPA_Summary
  public Report_NPA_Summary(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_NPA_Summary`,
      requestData
    );
  }
  // #end Report_NPA_Summary

  // #Report_ACC_Leadger_By_Daily
  public Report_ACC_Leadger_By_Daily(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_ACC_Leadger_By_Daily`,
      requestData
    );
  }
  // #end Report_ACC_Leadger_By_Daily

  // #Report_ACC_Leadger_By_Monthly
  public Report_ACC_Leadger_By_Monthly(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_ACC_Leadger_By_Monthly`,
      requestData
    );
  }
  // #end Report_ACC_Leadger_By_Monthly

  // #Report_Cibil_Consumer
  public Report_Cibil_Consumer(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Cibil_Consumer`,
      requestData
    );
  }
  // #end Report_Cibil_Consumer

  // #Report_Crif_Consumer
  public Report_Crif_Consumer(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Crif_Consumer`,
      requestData
    );
  }
  // #end Report_Crif_Consumer

  // // #Report_Loan_Charges_Balance
  // public Report_Loan_Charges_Balance(requestData: any) {
  //   return this.http.post(`${constantUrl.apiEndpoint}/api/Reports/Report_Loan_Charges_Balance`, requestData);
  // }
  // #end Report_Loan_Charges_Balance

  // #Report_Business_VS_Collection_Detail
  public Report_Business_VS_Collection_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Business_VS_Collection_Detail`,
      requestData
    );
  }
  // #end Report_Business_VS_Collection_Detail

  // #Report_Month_Wise_DUE_VS_Collection_Detail
  public Report_Month_Wise_DUE_VS_Collection_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Month_Wise_DUE_VS_Collection_Detail`,
      requestData
    );
  }
  // #end Report_Month_Wise_DUE_VS_Collection_Detail

  // #Report_Branch_Wise_DUE_VS_Collection_Detail
  public Report_Branch_Wise_DUE_VS_Collection_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Branch_Wise_DUE_VS_Collection_Detail`,
      requestData
    );
  }
  // #end Report_Branch_Wise_DUE_VS_Collection_Detail

  // #Report_Collection_Efficiency
  public Report_Collection_Efficiency(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Collection_Efficiency`,
      requestData
    );
  }
  // #end Report_Collection_Efficiency

  // #LMS_Pending_Disbursment
  public LMS_Pending_Disbursment(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/LMS_Pending_Disbursment`,
      requestData
    );
  }
  // #end LMS_Pending_Disbursment

  // #Report_Get_Assets_List
  public Report_Get_Assets_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Get_Assets_List`,
      requestData
    );
  }
  // #end Report_Get_Assets_List
  // #Report_PAR_Detail
  public Report_PAR_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_PAR_Detail`,
      requestData
    );
  }
  // #end Report_PAR_Detail

  // #start Report_Exposure
  public Report_Exposure(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Exposure`,
      requestData
    );
  }
  // #end Report_Exposure

  // #start Report_Future_Flow
  public Report_Future_Flow(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Future_Flow`,
      requestData
    );
  }
  // #end Report_Future_Flow

  // #start Report_Cibil_Commercial
  public Report_Cibil_Commercial(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Cibil_Commercial`,
      requestData
    );
  }
  // #end Report_Cibil_Commercial

  // #start Report_Loan_Charges_Balance
  public Report_Loan_Charges_Balance(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Loan_Charges_Balance`,
      requestData
    );
  }
  // #end Report_Loan_Chrages_Balance

  // #start Reports_Attendance_ByTeam
  public Reports_Attendance_ByTeam(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Attendance_ByTeam`,
      requestData
    );
  }
  // #end  Reports_Attendance_ByTeam

  // #start Reports_Attendance_ByEmployee
  public Reports_Attendance_ByEmployee(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Attendance_ByEmployee`,
      requestData
    );
  }
  // #end  Reports_Attendance_ByEmployee

  // #start Report_Exposure1
  public Report_Exposure1(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_ExposureFinal`,
      requestData
    );
  }
  public Report_FutureDue(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_FutureDue`,
      requestData
    );
  }
  // #end Report_Exposure1

  // #start Report_Account_Interest
  public Report_ACC_Interest_Leadger_By_Daily(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_ACC_Interest_Leadger_By_Daily`,
      requestData
    );
  }
  // #end Report_Account_Interest

  // #start Target and Achievement
  public Report_Sales_Target_VS_Achievement(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Sales_Target_VS_Achievement`,
      requestData
    );
  }
  // #end #Target and Achievement

  // #start Target and Achievement
  public Get_Sales_Target(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Get_Sales_Target`,
      requestData
    );
  }
  // #end #Target and Achievement

  // #start Target and Achievement
  public Save_Sales_Target(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Save_Sales_Target`,
      requestData
    );
  }
  // #end #Target and Achievement
  // #start Report_Collection_Target_VS_Achievement
  public Report_Collection_Target_VS_Achievement(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Collection_Target_VS_Achievement`,
      requestData
    );
  }
  // #end #Report_Collection_Target_VS_Achievement

  // #start Report_Collection_By_Employee
  public Report_Collection_By_Employee(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Collection_By_Employee`,
      requestData
    );
  }
  // #end #Report_Collection_By_Employee
  // #start Save_Collection_Target
  public Save_Collection_Target(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Save_Collection_Target`,
      requestData
    );
  }
  // #end #Save_Collection_Target

  public ReportODInterestDetail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_OD_Interest_Detail`,
      requestData
    );
  }

  // #start Report_SalesExecutiveWiseBusiness
  public Report_SalesExecutiveWiseBusiness(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_SalesExecutiveWiseBusiness`,
      requestData
    );
  }
  // #end #Report_SalesExecutiveWiseBusiness
  // #start Report_SalesExecutiveWiseBusiness
  public Report_Dump_Data(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Dump_Data`,
      requestData
    );
  }
  // #end #Report_SalesExecutiveWiseBusiness

  //  start #StaffWiseBalance
  public Get_Staff_balance(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Get_Staff_balance`,
      requestData
    );
  }
  // #end  #StaffWiseBalance

  // #start Report_Collection_Efficiency_Detail
  public Report_Collection_Efficiency_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Collection_Efficiency_Detail`,
      requestData
    );
  }
  // #end #Report_SalesExecutiveWiseBusiness

  //  start #GetDealersBalance
  public Get_Dealers_Balance(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Get_Dealers_Balance`,
      requestData
    );
  }
  // #end   #GetDealersBalance

  //  start #GetDealersBalance
  public Trail_Balance_GroupBY_Branch(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Trail_Balance_GroupBY_Branch`,
      requestData
    );
  }
  // #end   #GetDealersBalance

  //  start #LoanCustomerDetails
  public Loan_Customer_Details(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Loan_Customer_Details`,
      requestData
    );
  }
  // #end   #LoanCustomerDetails

  //  start #Difference_LMS_Get_Loan_EMIAllocation
  public Difference_LMS_Get_Loan_EMIAllocation(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Difference_LMS_Get_Loan_EMIAllocation`,
      requestData
    );
  }
  // #end   #Difference_LMS_Get_Loan_EMIAllocation

  //  start #Difference_LMS_Update_Loan_EMIAllocation
  public Difference_LMS_Update_Loan_EMIAllocation(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Masters/Difference_LMS_Update_Loan_EMIAllocation`,
      requestData
    );
  }
  // #end   #Difference_LMS_Update_Loan_EMIAllocation

  //  start #Customer_KYC_Verification_Summary
  public Customer_KYC_Verification_Summary(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Customer_KYC_Verification_Summary`,
      requestData
    );
  }
  // #end   #Customer_KYC_Verification_Summary

  //  start #Report_Accured_Interest
  public Report_Accured_Interest(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Accured_Interest`,
      requestData
    );
  }
  // #end   #Report_Accured_Interest

  // #Report_Get_Insurance_List
  public Report_Get_Insurance_List(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Get_Insurance_List`,
      requestData
    );
  }
  // #end Report_Get_Insurance_List

  // #Report_OwnPartner_BranchWisePortfolio
  public Report_OwnPartner_BranchWisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_OwnPartner_BranchWisePortfolio`,
      requestData
    );
  }
  // #end Report_OwnPartner_BranchWisePortfolio

  // #Report_OwnPartner_AmountWisePortfolio
  public Report_OwnPartner_AmountWisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_OwnPartner_AmountWisePortfolio`,
      requestData
    );
  }
  // #end Report_OwnPartner_AmountWisePortfolio

  // #Report_OwnPartner_AmountWisePortfolio
  public Report_OwnPartner_ProductWisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_OwnPartner_ProductWisePortfolio`,
      requestData
    );
  }
  // #end Report_OwnPartner_ProductWisePortfolio

  // #Report_OwnPartner_TenureWisePortfolio
  public Report_OwnPartner_TenureWisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_OwnPartner_TenureWisePortfolio`,
      requestData
    );
  }
  // #end Report_OwnPartner_TenureWisePortfolio

  // #Report_OwnPartner_LTVWisePortfolio
  public Report_OwnPartner_LTVWisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_OwnPartner_LTVWisePortfolio`,
      requestData
    );
  }
  // #end Report_OwnPartner_LTVWisePortfolio

  // #Report_OwnPartner_LTVWisePortfolio
  public Report_OwnPartner_IRRWisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_OwnPartner_IRRWisePortfolio`,
      requestData
    );
  }
  // #end Report_OwnPartner_IRRWisePortfolio

  // #Report_GenderWisePortfolio
  public Report_GenderWisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_GenderWisePortfolio`,
      requestData
    );
  }
  // #end Report_GenderWisePortfolio

  // #Report_ReligionWisePortfolio
  public Report_ReligionWisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_ReligionWisePortfolio`,
      requestData
    );
  }
  // #end Report_ReligionWisePortfolio

  // #Report_Cast_WisePortfolio
  public Report_Cast_WisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Cast_WisePortfolio`,
      requestData
    );
  }
  // #end Report_Cast_WisePortfolio
  // #Report_MaritalStatus_WisePortfolio
  public Report_MaritalStatus_WisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_MaritalStatus_WisePortfolio`,
      requestData
    );
  }
  // #end Report_MaritalStatus_WisePortfolio

  // #Report_Profile_WisePortfolio
  public Report_Profile_WisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Profile_WisePortfolio`,
      requestData
    );
  }
  // #end Report_Profile_WisePortfolio

  // #Report_Natureofwork_WisePortfolio
  public Report_Natureofwork_WisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Natureofwork_WisePortfolio`,
      requestData
    );
  }
  // #end Report_Natureofwork_WisePortfolio

  // #Report_OwnPartner_GenderWisePortfolio
  public Report_OwnPartner_GenderWisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_OwnPartner_GenderWisePortfolio`,
      requestData
    );
  }
  // #end Report_OwnPartner_GenderWisePortfolio

  // #Report_OwnPartner_ReligionWisePortfolio
  public Report_OwnPartner_ReligionWisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_OwnPartner_ReligionWisePortfolio`,
      requestData
    );
  }
  // #end Report_OwnPartner_ReligionWisePortfolio

  // #Report_OwnPartner_CasteWisePortfolio
  public Report_OwnPartner_CasteWisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_OwnPartner_CasteWisePortfolio`,
      requestData
    );
  }
  // #end Report_OwnPartner_CasteWisePortfolio

  // #Report_OwnPartner_MaritalStatusWisePortfolio
  public Report_OwnPartner_MaritalStatusWisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_OwnPartner_MaritalStatusWisePortfolio`,
      requestData
    );
  }
  // #end Report_OwnPartner_MaritalStatusWisePortfolio

  // #Report_OwnPartner_ProfileWisePortfolio
  public Report_OwnPartner_ProfileWisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_OwnPartner_ProfileWisePortfolio`,
      requestData
    );
  }
  // #end Report_OwnPartner_ProfileWisePortfolio

  // #Report_OwnPartner_NatureofworkWisePortfolio
  public Report_OwnPartner_NatureofworkWisePortfolio(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_OwnPartner_NatureofworkWisePortfolio`,
      requestData
    );
  }
  // #end Report_OwnPartner_NatureofworkWisePortfolio

  // #Report_GenderWiseBusiness
  public Report_GenderWiseBusiness(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_GenderWiseBusiness`,
      requestData
    );
  }
  // #end Report_GenderWiseBusiness

  // #Report_ReligionWiseBusiness
  public Report_ReligionWiseBusiness(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_ReligionWiseBusiness`,
      requestData
    );
  }
  // #end Report_ReligionWiseBusiness

  // #Report_CastWiseBusiness
  public Report_CastWiseBusiness(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_CastWiseBusiness`,
      requestData
    );
  }
  // #end Report_CastWiseBusiness

  // #Report_MaritalStatusWiseBusiness
  public Report_MaritalStatusWiseBusiness(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_MaritalStatusWiseBusiness`,
      requestData
    );
  }
  // #end Report_MaritalStatusWiseBusiness

  // #Report_ProfileWiseBusiness
  public Report_ProfileWiseBusiness(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_ProfileWiseBusiness`,
      requestData
    );
  }
  // #end Report_ProfileWiseBusiness

  // #Report_NatureofworkWiseBusiness
  public Report_NatureofworkWiseBusiness(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_NatureofworkWiseBusiness`,
      requestData
    );
  }
  // #end Report_NatureofworkWiseBusiness

  // #Report_CibilAPI_Request_Response
  public Report_CibilAPI_Request_Response(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_CibilAPI_Request_Response`,
      requestData
    );
  }
  // #end Report_CibilAPI_Request_Response

  // #Customer_KYC_Verification_Detail
  public Customer_KYC_Verification_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Customer_KYC_Verification_Detail`,
      requestData
    );
  }
  // #end Customer_KYC_Verification_Detail

  // #DropdownFor_KycVerificationApi
  public DropdownFor_KycVerificationApi() {
    return this.http.get(
      `${constantUrl.apiEndpoint}/api/Reports/DropdownFor_KycVerificationApi`
    );
  }
  // #end DropdownFor_KycVerificationApi

  // #Report_Future_Flow_Detail
  public Report_Future_Flow_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Future_Flow_Detail`,
      requestData
    );
  }
  // #end Report_Future_Flow_Detail

  // #Report_Column_Future_Flow_Detail

  public Report_Column_Future_Flow_Detail(requestData: any) {
    return this.http.post(
      `${constantUrl.apiEndpoint}/api/Reports/Report_Column_Future_Flow_Detail`,
      requestData
    );
  }
  // #end Report_Column_Future_Flow_Detail



    // #Report_Business_And_Collection_Summary

    public Report_Business_And_Collection_Summary(requestData: any) {
      return this.http.post(
        `${constantUrl.apiEndpoint}/api/Reports/Report_Business_And_Collection_Summary`,
        requestData
      );
    }
    // #end Report_Column_Future_Flow_Detail




        // #Report_Business_And_Collection

        public Report_Business_And_Collection(requestData: any) {
          return this.http.post(
            `${constantUrl.apiEndpoint}/api/Reports/Report_Business_And_Collection`,
            requestData
          );
        }
        // #end Report_Business_And_Collection
    

}
