<div>
  <form #f="ngForm" (ngSubmit)="onSaveCrc()" novalidate>
    <div
      class="m-3 p-1 Top-Detail"
      style="display: flex"
      *ngIf="ApplicationDetail"
    >
      <img
        src="{{ customerProfileBaseUrl }}{{ ApplicationDetail.CustomerId }}/{{
          ApplicationDetail.ProfilePic
        }}"
        class="user-img mr-4 rounded-circle img-thumbnail"
        onerror="this.onerror=null;this.src='assets/images/default-user.png';"
        alt="user image"
      />
      <div class="row m-0" style="flex: 1">
        <div class="col-md-2">
          <h6 class="fs-12">Customer</h6>
          <p class="lead fs-12">
            <app-lms-customer-view-model
              [customerId]="ApplicationDetail.CustomerId"
              [index]="0"
            >
              {{ ApplicationDetail.Customer }}
              (
              {{
                ApplicationDetail.CustomerType == "Hirer"
                  ? "Borrower"
                  : ApplicationDetail.CustomerType
              }}
              )
            </app-lms-customer-view-model>
          </p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Phone No</h6>
          <p class="lead fs-12">{{ ApplicationDetail.PhoneNo }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">DOB</h6>
          <p class="lead fs-12" style="word-wrap: break-word">
            {{ ApplicationDetail.Customer_DOB | date : "dd/MM/yyyy" }}
          </p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Email</h6>
          <p class="lead fs-12" style="word-wrap: break-word">
            {{ ApplicationDetail.Email }}
          </p>
        </div>
        <div class="col-md-4">
          <h6 class="fs-12">Present Address</h6>
          <p class="lead fs-12">{{ ApplicationDetail.Address }}</p>
        </div>
        <div class="col-md-8 p-1">
          <button
            type="button"
            mat-raised-button
            class="btn font-size-12 button-btn ml-3"
            (click)="CheckCibil()"
            *ngIf="CibilApiShow"
          >
            CIBIL Check
          </button>

          <button
            type="button"
            mat-raised-button
            class="btn font-size-12 button-btn ml-3"
            (click)="CheckCRIF()"
            *ngIf="CRIFApiShow"
          >
            CRIF Check
          </button>
        </div>
        <div class="col-md-8 p-1">
          <button
            type="button"
            mat-raised-button
            class="btn font-size-12 button-btn ml-3"
            (click)="CheckCibildecisioncentre()"
            *ngIf="CibilApidecisioncentreShow"
          >
            CIBIL Check decisioncentre
          </button>
        </div>

        <div class="col-md-4 p-1 justify-content-end" style="text-align: end">
          <button
            type="button"
            class="btn font-size-12 button-btn ml-3"
            (click)="AddCRC()"
            *ngIf="AddButtonShow"
            [hidden]="CRCList.length == 4"
          >
            Add CBC
          </button>
        </div>
        <!--<div class="col-md-3 p-1" *ngFor="let kyc of kycList">
          <div class="row m-0 align-item-center" *ngIf="kyc.KYC_DocFile">
            <h6 class="p-0 pr-2 m-0 fs-12" style="line-height: inherit">
              {{ kyc.Doc_Name }}
            </h6>
            <a href="{{ customerProfileBaseUrl }}{{ ApplicationDetail.CustomerId }}/{{kyc.KYC_DocFile}}"
               target="_blank">
              <img src="assets/images/aadhar.jpg" alt="" style="max-width: 50px; margin-right: 5px"
                   *ngIf="kyc.Doc_Name == 'Aadhaar Card'" />
              <img src="assets/images/dl.jpg" alt="" style="max-width: 50px; margin-right: 5px"
                   *ngIf="kyc.Doc_Name == 'Driving License'" />
              <img src="assets/images/electricity.jpg" alt="" style="max-width: 50px; margin-right: 5px"
                   *ngIf="kyc.Doc_Name == 'Electricity Bill'" />
              <img src="assets/images/emp-id-card.jpg" alt="" style="max-width: 50px; margin-right: 5px"
                   *ngIf="kyc.Doc_Name == 'Other Photo ID'" />
              <img src="assets/images/pan-card.jpg" alt="" style="max-width: 50px; margin-right: 5px"
                   *ngIf="kyc.Doc_Name == 'PAN Card'" />
              <img src="assets/images/passport.jpg" alt="" style="max-width: 50px; margin-right: 5px"
                   *ngIf="kyc.Doc_Name == 'Passport Copy'" />
              <img src="assets/images/rasan-card.jpg" alt="" style="max-width: 50px; margin-right: 5px"
                   *ngIf="kyc.Doc_Name == 'Ration Card'" />
              <img src="assets/images/voter-id.jpg" alt="" style="max-width: 50px; margin-right: 5px"
                   *ngIf="kyc.Doc_Name == 'Voter ID Card'" />
              <img src="assets/images/doc.png" alt="" style="max-width: 50px; margin-right: 5px" *ngIf="
                                kyc.Doc_Name !== 'Aadhaar Card' &&
                                kyc.Doc_Name !== 'Driving License' &&
                                kyc.Doc_Name !== 'Electricity Bill' &&
                                kyc.Doc_Name !== 'Voter ID Card' &&
                                kyc.Doc_Name !== 'Ration Card' &&
                                kyc.Doc_Name !== 'Passport Copy' &&
                                kyc.Doc_Name !== 'PAN Card' &&
                                kyc.Doc_Name !== 'Other Photo ID' " />
              {{ kyc.KYC_DocNumber }}
            </a>
          </div>
        </div>-->
      </div>
    </div>
    <!--<div class="col-md-12 row m-0 mb-3 pr-0 justify-content-end">
    <app-los-edit-financial-detail-model [applicationId]="ApplicationId"></app-los-edit-financial-detail-model>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <app-los-assets-detail [applicationId]="ApplicationId"></app-los-assets-detail>
    </div>-->
    <hr />

    <div class="table-responsive">
      <mat-table [dataSource]="datasourceCRC">
        <ng-container matColumnDef="CRCId">
          <mat-header-cell
            class="grid-header"
            style="max-width: 50px"
            *matHeaderCellDef
          >
            #
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 50px; max-height: 5px"
          >
            {{ i + 1 }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="CRC_AgencyName">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Agency</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.CRC_AgencyName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Origin">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Origin</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ getOrigin(row) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="CRC_LoanTypeName">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Loan Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.CRC_LoanTypeName }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="CRC_CreditVisionScore">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Credit Vision Score</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.CRC_CreditVisionScore }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="CRC_PersonalLoanScore">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Personal Loan Score</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.CRC_PersonalLoanScore }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="CRC_Remarks">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >CRC Remarks</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.CRC_Remarks }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell
            class="grid-header j-c-center"
            style="max-width: 100px"
            *matHeaderCellDef
          >
            Action
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell j-c-center"
            style="max-width: 100px; align-items: center"
          >
            <i
              class="fa fa-eye"
              *ngIf="
                (row.isView == 0 && row.CRC_Agency == 1) ||
                (row.isViewCrifApi == 0 && row.CRC_Agency == 4) ||
                (row.CRC_Agency != 4 && row.CRC_Agency != 1)
              "
              (click)="getCRCDetail(row, false)"
              style="font-size: large; cursor: pointer"
            ></i>
            <i
              class="fa fa-eye"
              *ngIf="row.isView == 1"
              (click)="getCRCThirdDetail()"
              style="font-size: large; cursor: pointer"
            ></i>

            <a
              *ngIf="row.isViewCrifApi == 1"
              href="{{ docBaseUrl }}{{ loanSummary.ApplicationNo }}/{{
                row.CRC_ReportName
              }}"
              target="_blank"
              style="color: black"
            >
              <i
                class="fa fa-eye"
                style="font-size: large; cursor: pointer"
              ></i>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <!--<i class="fa fa-pencil-alt right btn_detail" (click)="getCRCDetail(row,true)" aria-label="true"></i>-->
            &nbsp;
            <i
              class="fa fa-trash style-delete"
              (click)="Delete_CRC(row)"
              *ngIf="
                (row.isView == 0 && row.CRC_Agency == 1) ||
                (row.isViewCrifApi == 0 && row.CRC_Agency == 4)
              "
              [hidden]="ApplicationDetail.DefaultCRCAgency == row.CRC_Agency"
              style="cursor: pointer"
              aria-label="true"
            ></i>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedCRCColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedCRCColumns"></mat-row>
      </mat-table>
    </div>
    <hr />
    <div class="text-right mb-2">
      <button
        type="button"
        (click)="OnClose()"
        class="btn font-size-12 button-btn mr-3"
      >
        Cancel
      </button>
    </div>
    <!--<div class="row m-3" [hidden]="!FormCRCShow">
              <div class="col-md-3 p-1">
                  <span> CRC Agency </span>
                  <select name="Agency" id="Agency" (change)="getCRCType($event)" #refAgency="ngModel"
                      [disabled]="inViewMode" [ngClass]="{
        'is-invalid': f.submitted && refAgency.invalid,
        'alert-warning':
          refAgency.invalid &&
          (refAgency.dirty || refAgency.touched || refAgency.untouched)
      }" [(ngModel)]="crcModel.CRC_Agency" class="form-control input-text-css" required>
                      <option value="">Select Agency</option>
                      <option *ngFor="let Type of CRC_Agency" [value]="Type.Id">
                          {{Type.Value}}
                      </option>
                  </select>
              </div>
              <div class="col-md-3 p-1">
                  <span> CRC Agency Loan Type </span>
                  <select name="SelectCRCType" id="SelectCRCType" #refSelectCRCType="ngModel"
                      [(ngModel)]="crcModel.CRC_LoanType" [ngClass]="{
        'is-invalid': f.submitted && refSelectCRCType.invalid,
        'alert-warning':
          refSelectCRCType.invalid &&
          (refSelectCRCType.dirty || refSelectCRCType.touched || refSelectCRCType.untouched)
      }" [disabled]="inViewMode" class="form-control input-text-css" required>
                      <option value="">Select Loan Type</option>
                      <option *ngFor="let Type of CRC_Type" [value]="Type.Id">
                          {{Type.Value}}
                      </option>
                  </select>
              </div>
              <div class="col-md-3 p-1">
                  <span> Credit Vision Score </span>
                  <input required type="number" #refCreditVisionScore="ngModel" [disabled]="inViewMode" [ngClass]="{
        'is-invalid': f.submitted && refCreditVisionScore.invalid,
        'alert-warning':
          refCreditVisionScore.invalid &&
          (refCreditVisionScore.dirty ||
            refCreditVisionScore.touched ||
            refCreditVisionScore.untouched)
      }" [(ngModel)]="crcModel.CRC_CreditVisionScore" placeholder="Credit Vision Score" name="CreditVisionScore"
                      id="CreditVisionScore" class="form-control input-text-css" />
              </div>
              <div class="col-md-3 p-1">
                  <span> Personal Loan Score </span>
                  <input required type="number" #refPersonalLoanScore="ngModel" [disabled]="inViewMode" [ngClass]="{
        'is-invalid': f.submitted && refPersonalLoanScore.invalid,
        'alert-warning':
          refPersonalLoanScore.invalid &&
          (refPersonalLoanScore.dirty ||
            refPersonalLoanScore.touched ||
            refPersonalLoanScore.untouched)
      }" [(ngModel)]="crcModel.CRC_PersonalLoanScore" placeholder="Personal Loan Score" name="PersonalLoanScore"
                      id="PersonalLoanScore" class="form-control input-text-css" />
              </div>
              <div class="col-md-3 p-1">
                  <span> Total Accounts </span>
                  <input required type="number" #refTotalAccounts="ngModel" [disabled]="inViewMode" [ngClass]="{
        'is-invalid': f.submitted && refTotalAccounts.invalid,
        'alert-warning':
          refTotalAccounts.invalid &&
          (refTotalAccounts.dirty ||
            refTotalAccounts.touched ||
            refTotalAccounts.untouched)
      }" [(ngModel)]="crcModel.CRC_TotalAccounts" placeholder="Total Accounts" name="TotalAccounts" id="TotalAccounts"
                      class="form-control input-text-css" />
              </div>
              <div class="col-md-3 p-1">
                  <span> Overdue Accounts </span>
                  <input type="number" min="0" numbersOnly #refOverdueAccounts="ngModel" [disabled]="inViewMode"
                      [ngClass]="{
        'is-invalid': f.submitted && refOverdueAccounts.invalid,
        'alert-warning':
          refOverdueAccounts.invalid &&
          (refOverdueAccounts.dirty ||
            refOverdueAccounts.touched ||
            refOverdueAccounts.untouched)
      }" [(ngModel)]="crcModel.CRC_OverdueAccounts" placeholder="Overdue Accounts" name="OverdueAccounts"
                      id="OverdueAccounts" class="form-control input-text-css" />
              </div>
              <div class="col-md-3 p-1">
                  <span> Zero Balance Accounts </span>
                  <input type="number" min="0" numbersOnly #refZeroBalanceAccounts="ngModel" [disabled]="inViewMode"
                      [ngClass]="{
        'is-invalid': f.submitted && refZeroBalanceAccounts.invalid,
        'alert-warning':
          refZeroBalanceAccounts.invalid &&
          (refZeroBalanceAccounts.dirty ||
            refZeroBalanceAccounts.touched ||
            refZeroBalanceAccounts.untouched)
      }" [(ngModel)]="crcModel.CRC_ZeroBalanceAccounts" placeholder="Zero Balance Accounts" name="ZeroBalanceAccounts"
                      id="ZeroBalanceAccounts" class="form-control input-text-css" />
              </div>
              <div class="col-md-3 p-1">
                  <span> High CR SANC Amount </span>
                  <input type="number" min="0" numbersOnly #refHighCR_SANCAmount="ngModel" [disabled]="inViewMode"
                      [ngClass]="{
        'is-invalid': f.submitted && refHighCR_SANCAmount.invalid,
        'alert-warning':
          refHighCR_SANCAmount.invalid &&
          (refHighCR_SANCAmount.dirty ||
            refHighCR_SANCAmount.touched ||
            refHighCR_SANCAmount.untouched)
      }" [(ngModel)]="crcModel.CRC_HighCR_SANCAmount" placeholder="High CR SANC Amount" name="HighCR_SANCAmount"
                      id="HighCR_SANCAmount" class="form-control input-text-css" />
              </div>
              <div class="col-md-3 p-1">
                  <span> Current Balance Amount </span>
                  <input type="number" min="0" numbersOnly #refCurrentBalanceAmount="ngModel" [disabled]="inViewMode"
                      [ngClass]="{
        'is-invalid': f.submitted && refCurrentBalanceAmount.invalid,
        'alert-warning':
          refCurrentBalanceAmount.invalid &&
          (refCurrentBalanceAmount.dirty ||
            refCurrentBalanceAmount.touched ||
            refCurrentBalanceAmount.untouched)
      }" [(ngModel)]="crcModel.CRC_CurrentBalanceAmount" placeholder="Current Balance Amount" name="CurrentBalanceAmount"
                      id="CurrentBalanceAmount" class="form-control input-text-css" />
              </div>
              <div class="col-md-3 p-1">
                  <span> Overdue Amount </span>
                  <input type="number" min="0" numbersOnly #refOverdueAmount="ngModel" [disabled]="inViewMode" [ngClass]="{
        'is-invalid': f.submitted && refOverdueAmount.invalid,
        'alert-warning':
          refOverdueAmount.invalid &&
          (refOverdueAmount.dirty ||
            refOverdueAmount.touched ||
            refOverdueAmount.untouched)
      }" [(ngModel)]="crcModel.CRC_OverdueAmount" placeholder="Overdue Amount" name="OverdueAmount" id="OverdueAmount"
                      class="form-control input-text-css" />
              </div>
              <div class="col-md-3 p-1">
                  <span> Oldest Account Open Date </span>
                  <input name="OldestAccountOpenDate" id="OldestAccountOpenDate" #refOldestAccountOpenDate="ngModel"
                      [disabled]="inViewMode" [ngClass]="{
        'is-invalid': f.submitted && refOldestAccountOpenDate.invalid,
        'alert-warning':
          refOldestAccountOpenDate.invalid &&
          (refOldestAccountOpenDate.dirty ||
            refOldestAccountOpenDate.touched ||
            refOldestAccountOpenDate.untouched)
      }" [(ngModel)]="crcModel.CRC_OldestAccountOpenDate" [maxDate]="CurrentDate"
                      value="{{ crcModel.CRC_OldestAccountOpenDate | date: 'dd/MM/yyyy' }}" placeholder="DD/MM/YYYY"
                      class="form-control input-text-css" [(bsValue)]="crcModel.CRC_OldestAccountOpenDate" bsDatepicker />
              </div>
              <div class="col-md-3 p-1">
                  <span> Recent Account Open Date </span>
                  <input name="RecentAccountOpenDate" id="RecentAccountOpenDate" #refRecentAccountOpenDate="ngModel"
                      [disabled]="inViewMode" [ngClass]="{
        'is-invalid': f.submitted && refRecentAccountOpenDate.invalid,
        'alert-warning':
          refRecentAccountOpenDate.invalid &&
          (refRecentAccountOpenDate.dirty ||
            refRecentAccountOpenDate.touched ||
            refRecentAccountOpenDate.untouched)
      }" [(ngModel)]="crcModel.CRC_RecentAccountOpenDate" [maxDate]="CurrentDate"
                      value="{{ crcModel.CRC_RecentAccountOpenDate | date: 'dd/MM/yyyy' }}" placeholder="DD/MM/YYYY"
                      class="form-control input-text-css" [(bsValue)]="crcModel.CRC_RecentAccountOpenDate" bsDatepicker />
              </div>
              <div class="col-md-3 p-1">
                  <span> Total Enquiry </span>
                  <input type="number" min="0" numbersOnly #refTotalEnquiry="ngModel" [disabled]="inViewMode" [ngClass]="{
        'is-invalid': f.submitted && refTotalEnquiry.invalid,
        'alert-warning':
          refTotalEnquiry.invalid &&
          (refTotalEnquiry.dirty ||
            refTotalEnquiry.touched ||
            refTotalEnquiry.untouched)
      }" [(ngModel)]="crcModel.CRC_TotalEnquiry" placeholder="Total Enquiry" name="TotalEnquiry" id="TotalEnquiry"
                      class="form-control input-text-css" />
              </div>
              <div class="col-md-3 p-1">
                  <span> Last 30 Days Enquiry </span>
                  <input type="number" min="0" numbersOnly #refLast30DaysEnquiry="ngModel" [disabled]="inViewMode"
                      [ngClass]="{
        'is-invalid': f.submitted && refLast30DaysEnquiry.invalid,
        'alert-warning':
          refLast30DaysEnquiry.invalid &&
          (refLast30DaysEnquiry.dirty ||
            refLast30DaysEnquiry.touched ||
            refLast30DaysEnquiry.untouched)
      }" [(ngModel)]="crcModel.CRC_Last30DaysEnquiry" placeholder="Last 30 Days Enquiry" name="Last30DaysEnquiry"
                      id="Last30DaysEnquiry" class="form-control input-text-css" />
              </div>
              <div class="col-md-3 p-1">
                  <span> Last 12 Month Enquiry </span>
                  <input type="number" min="0" numbersOnly #refLast12MonthEnquiry="ngModel" [disabled]="inViewMode"
                      [ngClass]="{
        'is-invalid': f.submitted && refLast12MonthEnquiry.invalid,
        'alert-warning':
          refLast12MonthEnquiry.invalid &&
          (refLast12MonthEnquiry.dirty ||
            refLast12MonthEnquiry.touched ||
            refLast12MonthEnquiry.untouched)
      }" [(ngModel)]="crcModel.CRC_Last12MonthEnquiry" placeholder="Last 12 Month Enquiry" name="Last12MonthEnquiry"
                      id="Last12MonthEnquiry" class="form-control input-text-css" />
              </div>
              <div class="col-md-3 p-1">
                  <span> Last 24 Month Enquiry </span>
                  <input type="number" min="0" numbersOnly #refLast1224MonthEnquiry="ngModel" [disabled]="inViewMode"
                      [ngClass]="{
        'is-invalid': f.submitted && refLast1224MonthEnquiry.invalid,
        'alert-warning':
          refLast1224MonthEnquiry.invalid &&
          (refLast1224MonthEnquiry.dirty ||
            refLast1224MonthEnquiry.touched ||
            refLast1224MonthEnquiry.untouched)
      }" [(ngModel)]="crcModel.CRC_Last24MonthEnquiry" placeholder="Last 24 Month Enquiry" name="Last24MonthEnquiry"
                      id="Last24MonthEnquiry" class="form-control input-text-css" />
              </div>
              <div class="col-md-3 p-1">
                  <span> Last Enquiry Date </span>
                  <input name="LastEnquiryDate" id="LastEnquiryDate" #refLastEnquiryDate="ngModel" [disabled]="inViewMode"
                      [ngClass]="{
        'is-invalid': f.submitted && refLastEnquiryDate.invalid,
        'alert-warning':
          refLastEnquiryDate.invalid &&
          (refLastEnquiryDate.dirty ||
            refLastEnquiryDate.touched ||
            refLastEnquiryDate.untouched)
      }" [(ngModel)]="crcModel.CRC_LastEnquiryDate" [maxDate]="CurrentDate"
                      value="{{ crcModel.CRC_LastEnquiryDate | date: 'dd/MM/yyyy' }}" placeholder="DD/MM/YYYY"
                      class="form-control input-text-css" [(bsValue)]="crcModel.CRC_LastEnquiryDate" bsDatepicker />
              </div>
              <div class="col-md-3 p-1">
                  <span> Recommendation </span>
                  <select name="Recommendation" id="Recommendation" #refRecommendation="ngModel" [disabled]="inViewMode"
                      [ngClass]="{
        'is-invalid': f.submitted && refRecommendation.invalid,
        'alert-warning':
          refRecommendation.invalid &&
          (refRecommendation.dirty ||
            refRecommendation.touched ||
            refRecommendation.untouched)
      }" [(ngModel)]="crcModel.CRC_Recommendation" class="form-control input-text-css" required>
                      <option value="">Select Recommendation</option>
                      <option value="Positive">Positive</option>
                      <option value="Negative">Negative</option>
                      <option value="Not Conducted">Not Conducted</option>
                  </select>
              </div>
              <div class="col-md-6 p-1">
                  <span> Remarks </span>
                  <textarea name="Remarks" id="Remarks" #refRemarks="ngModel" [disabled]="inViewMode" rows="1"
                      maxlength="500" [ngClass]="{
        'is-invalid': f.submitted && refRemarks.invalid,
        'alert-warning':
          refRemarks.invalid &&
          (refRemarks.dirty || refRemarks.touched || refRemarks.untouched)
      }" [(ngModel)]="crcModel.CRC_Remarks" class="form-control input-text-css" placeholder="Remarks" required>
    </textarea>
              </div>
              <div class="row m-0 col-md-12 mt-2 p-1 upload" *ngIf="!inViewMode">
                  <div class="col-md-6 p-1">
                      <p class="m-0 row">
                          Upload CRC Report:<i class="fa fa-upload ml-2" *ngIf="!crcModel.CRC_ReportName"
                              (click)="docFile.click()" aria-hidden="true"></i>
                          <ng-container *ngIf="crcModel.CRC_ReportName">
                              <div>
                                  <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>{{ crcModel.CRC_ReportName }}
                                  <i class="fa fa-times ml-2" aria-hidden="true" style="cursor: pointer"
                                      (click)="removeFile(docFile)"></i>
                              </div>
                          </ng-container>
                      </p>
                      <input #docFile type="file" [multiple]="false"
                          accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                          (change)="fileChangeListener(docFile.files)" style="display: none" />
                  </div>
                  <div class="col-md-6 p-1">
                      <p class="m-0 row">
                          Upload Other CRC Report:<i class="fa fa-upload ml-2" *ngIf="!crcModel.CRC_ReportName1"
                              (click)="docFile1.click()" aria-hidden="true"></i>
                          <ng-container *ngIf="crcModel.CRC_ReportName1">
                              <div>
                                  <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>{{ crcModel.CRC_ReportName1 }}
                                  <i class="fa fa-times ml-2" aria-hidden="true" style="cursor: pointer"
                                      (click)="removeFile1(docFile1)"></i>
                              </div>
                          </ng-container>
                      </p>
                      <input #docFile1 type="file" [multiple]="false"
                          accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                          (change)="fileChangeListener1(docFile1.files)" style="display: none" />
                  </div>
              </div>
              <div class="row m-0 col-md-12 mt-2 p-1" *ngIf="inViewMode">
                  <div class="col-md-6 p-1">
                      <p class="lead fs-14 m-0 row">
                          CRC Reports:
                          <a href="{{ docBaseUrl }}{{loanSummary.ApplicationNo }}/{{crcModel.CRC_ReportName}}"
                              class="ml-3" target="_blank">
                              {{ crcModel.CRC_ReportName }}
                              <i class="fa fa-link" aria-hidden="true"></i>
                          </a>
                      </p>
                  </div>
                  <div class="col-md-6 p-1" *ngIf="crcModel.CRC_ReportName1">
                      <p class="lead fs-14 m-0 row">
                          Other CRC Reports:
                          <a href="{{ docBaseUrl }}{{loanSummary.ApplicationNo }}/{{crcModel.CRC_ReportName1}}"
                              class="ml-3" target="_blank">
                              {{ crcModel.CRC_ReportName1 }}
                              <i class="fa fa-link" aria-hidden="true"></i>
                          </a>
                      </p>
                  </div>
              </div>
          </div>


          <div [hidden]="!DetailCRCShow">
              <h1 class="fs-12 h-b">CRC Details</h1>
              <div class="row m-0 mt-3" *ngIf="crcModel">
                  <div class="col-md-3">
                      <h6 class="fs-12">CRC Agency</h6>
                      <p class="lead fs-12">{{ crcModel.CRC_Agency_Name }}</p>
                  </div>
                  <div class="col-md-3">
                      <h6 class="fs-12">CRC Agency Loan Type </h6>
                      <p class="lead fs-12">{{ crcModel.CRC_LoanType_Name }}</p>
                  </div>
                  <div class="col-md-3">
                      <h6 class="fs-12">Credit Vision Score </h6>
                      <p class="lead fs-12">{{ crcModel.CRC_CreditVisionScore }}</p>
                  </div>
                  <div class="col-md-3">
                      <h6 class="fs-12">Personal Loan Score</h6>
                      <p class="lead fs-12">{{crcModel.CRC_PersonalLoanScore}}</p>
                  </div>
                  <div class="col-md-3">
                      <h6 class="fs-12"> Total Accounts </h6>
                      <p class="lead fs-12">{{ crcModel.CRC_TotalAccounts }}</p>
                  </div>

                  <div class="col-md-3">
                      <h6 class="fs-12">Overdue Accounts </h6>
                      <p class="lead fs-12">{{ crcModel.CRC_OverdueAccounts }}</p>
                  </div>
                  <div class="col-md-3">
                      <h6 class="fs-12">Zero Balance Accounts </h6>
                      <p class="lead fs-12">{{ crcModel.CRC_ZeroBalanceAccounts}}</p>
                  </div>

                  <div class="col-md-3">
                      <h6 class="fs-12"> High CR SANC Amount </h6>
                      <p class="lead fs-12">{{ crcModel.CRC_HighCR_SANCAmount }}</p>
                  </div>
                  <div class="col-md-3">
                      <h6 class="fs-12">Current Balance Amount</h6>
                      <p class="lead fs-12">{{crcModel.CRC_CurrentBalanceAmount }}</p>
                  </div>
                  <div class="col-md-3">
                      <h6 class="fs-12">Overdue Amount</h6>
                      <p class="lead fs-12">{{crcModel.CRC_OverdueAmount }}</p>
                  </div>
                  <div class="col-md-3">
                      <h6 class="fs-12"> Oldest Account Open Date </h6>
                      <p class="lead fs-12">{{crcModel.CRC_OldestAccountOpenDate }}</p>
                  </div>
                  <div class="col-md-3">
                      <h6 class="fs-12">Recent Account Open Date</h6>
                      <p class="lead fs-12">{{crcModel.CRC_RecentAccountOpenDate }}</p>
                  </div>
                  <div class="col-md-3">
                      <h6 class="fs-12">Total Enquiry </h6>
                      <p class="lead fs-12">{{crcModel.CRC_TotalEnquiry }}</p>
                  </div>
                  <div class="col-md-3">
                      <h6 class="fs-12">Last 30 Days Enquiry </h6>
                      <p class="lead fs-12">{{crcModel.CRC_Last30DaysEnquiry }}</p>
                  </div>
                  <div class="col-md-3">
                      <h6 class="fs-12">Last 12 Month Enquiry </h6>
                      <p class="lead fs-12">{{crcModel.CRC_Last12MonthEnquiry }}</p>
                  </div>
                  <div class="col-md-3">
                      <h6 class="fs-12">Last 24 Month Enquiry </h6>
                      <p class="lead fs-12">{{crcModel.CRC_Last24MonthEnquiry }}</p>

                  </div>
                  <div class="col-md-3">
                      <h6 class="fs-12">Last Enquiry Date</h6>
                      <p class="lead fs-12">{{crcModel.CRC_LastEnquiryDate }}</p>
                  </div>
                  <div class="row m-0 col-md-12 mt-2 p-1">
                      <div class="col-md-6 p-1">
                          <p class="lead fs-14 m-0 row">
                              CRC Reports:
                              <a href="{{ docBaseUrl }}{{loanSummary.ApplicationNo }}/{{crcModel.CRC_ReportName}}"
                                  class="ml-3" target="_blank">
                                  {{ crcModel.CRC_ReportName }}
                                  <i class="fa fa-link" aria-hidden="true"></i>
                              </a>
                          </p>
                      </div>
                      <div class="col-md-6 p-1" *ngIf="crcModel.CRC_ReportName1">
                          <p class="lead fs-14 m-0 row">
                              Other CRC Reports:
                              <a href="{{ docBaseUrl }}{{loanSummary.ApplicationNo }}/{{crcModel.CRC_ReportName1}}"
                                  class="ml-3" target="_blank">
                                  {{ crcModel.CRC_ReportName1 }}
                                  <i class="fa fa-link" aria-hidden="true"></i>
                              </a>
                          </p>
                      </div>
                  </div>
              </div>
          </div>
          <hr />
          <div class="text-right mb-2">

              <button type="button" (click)="OnClose()" class="btn btn-light font-size-12 mr-3">Cancel</button>
              <button type="button" (click)="onSaveCrc()" [hidden]="!isShowSave" [class.spinner]="loading"
                  style="background-color: #28a745; color: #ffffff; font-size: 12px" mat-raised-button color="primary"
                  [disabled]="!f.form.valid">
                  Save
              </button>
              <button type="button" (click)="UpdateCrc()" [class.spinner]="loading" [hidden]="!isShowUpdate"
                  style="background-color: #28a745;color: #FFFFFF;font-size: 12px;margin: 4px;" mat-raised-button
                  color="primary">
                  Update
              </button>
              <button type="button" (click)="EditCRC()" [class.spinner]="loading" [hidden]="!isShowEdit"
                  style="background-color: #28a745;color: #FFFFFF;font-size: 12px;margin: 4px;" mat-raised-button
                  color="primary">
                  Edit
              </button>
          </div>-->
  </form>
</div>

<div
  class="modal fade in"
  id="AdditionalCRC"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #rf="ngForm" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Add/Edit CRC
          </h6>
          <button
            type="button"
            (click)="OnAdditionalCRCClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-3">
            <div class="col-md-3 p-1">
              <span class="required-lable"> CRC Agency </span>
              <select
                name="Agency"
                id="Agency"
                #refAgency="ngModel"
                [disabled]="inViewMode || isShowUpdate || CRCList.length == 0"
                [ngClass]="{
                  'is-invalid': f.submitted && refAgency.invalid,
                  'alert-warning':
                    refAgency.invalid &&
                    (refAgency.dirty ||
                      refAgency.touched ||
                      refAgency.untouched)
                }"
                [(ngModel)]="crcModel.CRC_Agency"
                class="form-control input-text-css"
                (change)="GET_CRC_LoanType()"
                required
              >
                <option value="">Select Agency</option>
                <option *ngFor="let Type of CRC_Agency_Bind" [value]="Type.Id">
                  {{ Type.Value }}
                </option>
              </select>
            </div>
            <div class="col-md-3 p-1">
              <span class="required-lable"> CRC Agency Loan Type </span>
              <select
                name="CRC_LoanType"
                id="CRC_LoanType"
                #refCRC_LoanType="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refCRC_LoanType.invalid,
                  'alert-warning':
                    refCRC_LoanType.invalid &&
                    (refCRC_LoanType.dirty ||
                      refCRC_LoanType.touched ||
                      refCRC_LoanType.untouched)
                }"
                [(ngModel)]="crcModel.CRC_LoanType"
                class="form-control input-text-css"
                required
                [disabled]="inViewMode"
              >
                <option value="">Select Agency Loan Type</option>
                <option
                  *ngFor="let Type of CRC_LoanTypeDropDown"
                  [value]="Type.Id"
                >
                  {{ Type.Value }}
                </option>
              </select>
            </div>
            <div class="col-md-3 p-1">
              <span class="required-lable"> Credit Vision Score </span>
              <input
                required
                type="number"
                #refCreditVisionScore="ngModel"
                [disabled]="inViewMode"
                [ngClass]="{
                  'is-invalid': f.submitted && refCreditVisionScore.invalid,
                  'alert-warning':
                    refCreditVisionScore.invalid &&
                    (refCreditVisionScore.dirty ||
                      refCreditVisionScore.touched ||
                      refCreditVisionScore.untouched)
                }"
                [(ngModel)]="crcModel.CRC_CreditVisionScore"
                placeholder="Credit Vision Score"
                name="CreditVisionScore"
                id="CreditVisionScore"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3 p-1">
              <span class="required-lable"> Personal Loan Score </span>
              <input
                required
                type="number"
                #refPersonalLoanScore="ngModel"
                [disabled]="inViewMode"
                [ngClass]="{
                  'is-invalid': f.submitted && refPersonalLoanScore.invalid,
                  'alert-warning':
                    refPersonalLoanScore.invalid &&
                    (refPersonalLoanScore.dirty ||
                      refPersonalLoanScore.touched ||
                      refPersonalLoanScore.untouched)
                }"
                [(ngModel)]="crcModel.CRC_PersonalLoanScore"
                placeholder="Personal Loan Score"
                name="PersonalLoanScore"
                id="PersonalLoanScore"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3 p-1">
              <span class="required-lable"> Total Accounts </span>
              <input
                required
                type="number"
                #refTotalAccounts="ngModel"
                [disabled]="inViewMode"
                [ngClass]="{
                  'is-invalid': f.submitted && refTotalAccounts.invalid,
                  'alert-warning':
                    refTotalAccounts.invalid &&
                    (refTotalAccounts.dirty ||
                      refTotalAccounts.touched ||
                      refTotalAccounts.untouched)
                }"
                [(ngModel)]="crcModel.CRC_TotalAccounts"
                placeholder="Total Accounts"
                name="TotalAccounts"
                id="TotalAccounts"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3 p-1">
              <span> Overdue Accounts </span>
              <input
                type="number"
                #refOverdueAccounts="ngModel"
                [disabled]="inViewMode"
                [ngClass]="{
                  'is-invalid': f.submitted && refOverdueAccounts.invalid,
                  'alert-warning':
                    refOverdueAccounts.invalid &&
                    (refOverdueAccounts.dirty ||
                      refOverdueAccounts.touched ||
                      refOverdueAccounts.untouched)
                }"
                [(ngModel)]="crcModel.CRC_OverdueAccounts"
                placeholder="Overdue Accounts"
                name="OverdueAccounts"
                id="OverdueAccounts"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3 p-1">
              <span> Zero Balance Accounts </span>
              <input
                type="number"
                #refZeroBalanceAccounts="ngModel"
                [disabled]="inViewMode"
                [ngClass]="{
                  'is-invalid': f.submitted && refZeroBalanceAccounts.invalid,
                  'alert-warning':
                    refZeroBalanceAccounts.invalid &&
                    (refZeroBalanceAccounts.dirty ||
                      refZeroBalanceAccounts.touched ||
                      refZeroBalanceAccounts.untouched)
                }"
                [(ngModel)]="crcModel.CRC_ZeroBalanceAccounts"
                placeholder="Zero Balance Accounts"
                name="ZeroBalanceAccounts"
                id="ZeroBalanceAccounts"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3 p-1">
              <span> High CR SANC Amount </span>
              <input
                type="number"
                #refHighCR_SANCAmount="ngModel"
                [disabled]="inViewMode"
                [ngClass]="{
                  'is-invalid': f.submitted && refHighCR_SANCAmount.invalid,
                  'alert-warning':
                    refHighCR_SANCAmount.invalid &&
                    (refHighCR_SANCAmount.dirty ||
                      refHighCR_SANCAmount.touched ||
                      refHighCR_SANCAmount.untouched)
                }"
                [(ngModel)]="crcModel.CRC_HighCR_SANCAmount"
                placeholder="High CR SANC Amount"
                name="HighCR_SANCAmount"
                id="HighCR_SANCAmount"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3 p-1">
              <span> Current Balance Amount </span>
              <input
                type="number"
                #refCurrentBalanceAmount="ngModel"
                [disabled]="inViewMode"
                [ngClass]="{
                  'is-invalid': f.submitted && refCurrentBalanceAmount.invalid,
                  'alert-warning':
                    refCurrentBalanceAmount.invalid &&
                    (refCurrentBalanceAmount.dirty ||
                      refCurrentBalanceAmount.touched ||
                      refCurrentBalanceAmount.untouched)
                }"
                [(ngModel)]="crcModel.CRC_CurrentBalanceAmount"
                placeholder="Current Balance Amount"
                name="CurrentBalanceAmount"
                id="CurrentBalanceAmount"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3 p-1">
              <span> Overdue Amount </span>
              <input
                type="number"
                #refOverdueAmount="ngModel"
                [disabled]="inViewMode"
                [ngClass]="{
                  'is-invalid': f.submitted && refOverdueAmount.invalid,
                  'alert-warning':
                    refOverdueAmount.invalid &&
                    (refOverdueAmount.dirty ||
                      refOverdueAmount.touched ||
                      refOverdueAmount.untouched)
                }"
                [(ngModel)]="crcModel.CRC_OverdueAmount"
                placeholder="Overdue Amount"
                name="OverdueAmount"
                id="OverdueAmount"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3 p-1">
              <span> Oldest Account Open Date </span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="OldestAccountOpenDate"
                  id="OldestAccountOpenDate"
                  #refOldestAccountOpenDate="ngModel"
                  [disabled]="inViewMode"
                  [ngClass]="{
                    'is-invalid':
                      f.submitted && refOldestAccountOpenDate.invalid,
                    'alert-warning':
                      refOldestAccountOpenDate.invalid &&
                      (refOldestAccountOpenDate.dirty ||
                        refOldestAccountOpenDate.touched ||
                        refOldestAccountOpenDate.untouched)
                  }"
                  [(ngModel)]="crcModel.CRC_OldestAccountOpenDate"
                  [max]="CurrentDate"
                  class="form-control input-text-css"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
              <!-- <input
                name="OldestAccountOpenDate"
                id="OldestAccountOpenDate"
                #refOldestAccountOpenDate="ngModel"
                [disabled]="inViewMode"
                [ngClass]="{
                  'is-invalid': f.submitted && refOldestAccountOpenDate.invalid,
                  'alert-warning':
                    refOldestAccountOpenDate.invalid &&
                    (refOldestAccountOpenDate.dirty ||
                      refOldestAccountOpenDate.touched ||
                      refOldestAccountOpenDate.untouched)
                }"
                [(ngModel)]="crcModel.CRC_OldestAccountOpenDate"
                [maxDate]="CurrentDate"
                value="{{
                  crcModel.CRC_OldestAccountOpenDate | date : 'dd/MM/yyyy'
                }}"
                placeholder="DD/MM/YYYY"
                class="form-control input-text-css"
                [(bsValue)]="crcModel.CRC_OldestAccountOpenDate"
                bsDatepicker
              /> -->
            </div>
            <div class="col-md-3 p-1">
              <span> Recent Account Open Date </span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker2"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="RecentAccountOpenDate"
                  id="RecentAccountOpenDate"
                  #refRecentAccountOpenDate="ngModel"
                  [disabled]="inViewMode"
                  [ngClass]="{
                    'is-invalid':
                      f.submitted && refRecentAccountOpenDate.invalid,
                    'alert-warning':
                      refRecentAccountOpenDate.invalid &&
                      (refRecentAccountOpenDate.dirty ||
                        refRecentAccountOpenDate.touched ||
                        refRecentAccountOpenDate.untouched)
                  }"
                  [(ngModel)]="crcModel.CRC_RecentAccountOpenDate"
                  [max]="CurrentDate"
                  class="form-control input-text-css"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </div>
              <!-- <input
                name="RecentAccountOpenDate"
                id="RecentAccountOpenDate"
                #refRecentAccountOpenDate="ngModel"
                [disabled]="inViewMode"
                [ngClass]="{
                  'is-invalid': f.submitted && refRecentAccountOpenDate.invalid,
                  'alert-warning':
                    refRecentAccountOpenDate.invalid &&
                    (refRecentAccountOpenDate.dirty ||
                      refRecentAccountOpenDate.touched ||
                      refRecentAccountOpenDate.untouched)
                }"
                [(ngModel)]="crcModel.CRC_RecentAccountOpenDate"
                [maxDate]="CurrentDate"
                value="{{
                  crcModel.CRC_RecentAccountOpenDate | date : 'dd/MM/yyyy'
                }}"
                placeholder="DD/MM/YYYY"
                class="form-control input-text-css"
                [(bsValue)]="crcModel.CRC_RecentAccountOpenDate"
                bsDatepicker
              /> -->
            </div>
            <div class="col-md-3 p-1">
              <span> Total Enquiry </span>
              <input
                type="number"
                #refTotalEnquiry="ngModel"
                [disabled]="inViewMode"
                [ngClass]="{
                  'is-invalid': f.submitted && refTotalEnquiry.invalid,
                  'alert-warning':
                    refTotalEnquiry.invalid &&
                    (refTotalEnquiry.dirty ||
                      refTotalEnquiry.touched ||
                      refTotalEnquiry.untouched)
                }"
                [(ngModel)]="crcModel.CRC_TotalEnquiry"
                placeholder="Total Enquiry"
                name="TotalEnquiry"
                id="TotalEnquiry"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3 p-1">
              <span> Last 30 Days Enquiry </span>
              <input
                type="number"
                #refLast30DaysEnquiry="ngModel"
                [disabled]="inViewMode"
                [ngClass]="{
                  'is-invalid': f.submitted && refLast30DaysEnquiry.invalid,
                  'alert-warning':
                    refLast30DaysEnquiry.invalid &&
                    (refLast30DaysEnquiry.dirty ||
                      refLast30DaysEnquiry.touched ||
                      refLast30DaysEnquiry.untouched)
                }"
                [(ngModel)]="crcModel.CRC_Last30DaysEnquiry"
                placeholder="Last 30 Days Enquiry"
                name="Last30DaysEnquiry"
                id="Last30DaysEnquiry"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3 p-1">
              <span> Last 12 Month Enquiry </span>
              <input
                type="number"
                #refLast12MonthEnquiry="ngModel"
                [disabled]="inViewMode"
                [ngClass]="{
                  'is-invalid': f.submitted && refLast12MonthEnquiry.invalid,
                  'alert-warning':
                    refLast12MonthEnquiry.invalid &&
                    (refLast12MonthEnquiry.dirty ||
                      refLast12MonthEnquiry.touched ||
                      refLast12MonthEnquiry.untouched)
                }"
                [(ngModel)]="crcModel.CRC_Last12MonthEnquiry"
                placeholder="Last 12 Month Enquiry"
                name="Last12MonthEnquiry"
                id="Last12MonthEnquiry"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3 p-1">
              <span> Last 24 Month Enquiry </span>
              <input
                type="number"
                #refLast1224MonthEnquiry="ngModel"
                [disabled]="inViewMode"
                [ngClass]="{
                  'is-invalid': f.submitted && refLast1224MonthEnquiry.invalid,
                  'alert-warning':
                    refLast1224MonthEnquiry.invalid &&
                    (refLast1224MonthEnquiry.dirty ||
                      refLast1224MonthEnquiry.touched ||
                      refLast1224MonthEnquiry.untouched)
                }"
                [(ngModel)]="crcModel.CRC_Last24MonthEnquiry"
                placeholder="Last 24 Month Enquiry"
                name="Last24MonthEnquiry"
                id="Last24MonthEnquiry"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3 p-1">
              <span> Last Enquiry Date </span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker3"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="LastEnquiryDate"
                  id="LastEnquiryDate"
                  #refLastEnquiryDate="ngModel"
                  [disabled]="inViewMode"
                  [ngClass]="{
                    'is-invalid': f.submitted && refLastEnquiryDate.invalid,
                    'alert-warning':
                      refLastEnquiryDate.invalid &&
                      (refLastEnquiryDate.dirty ||
                        refLastEnquiryDate.touched ||
                        refLastEnquiryDate.untouched)
                  }"
                  [(ngModel)]="crcModel.CRC_LastEnquiryDate"
                  [max]="CurrentDate"
                  class="form-control input-text-css"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker3"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
              </div>
              <!-- <input
                name="LastEnquiryDate"
                id="LastEnquiryDate"
                #refLastEnquiryDate="ngModel"
                [disabled]="inViewMode"
                [ngClass]="{
                  'is-invalid': f.submitted && refLastEnquiryDate.invalid,
                  'alert-warning':
                    refLastEnquiryDate.invalid &&
                    (refLastEnquiryDate.dirty ||
                      refLastEnquiryDate.touched ||
                      refLastEnquiryDate.untouched)
                }"
                [(ngModel)]="crcModel.CRC_LastEnquiryDate"
                [maxDate]="CurrentDate"
                value="{{ crcModel.CRC_LastEnquiryDate | date : 'dd/MM/yyyy' }}"
                placeholder="DD/MM/YYYY"
                class="form-control input-text-css"
                [(bsValue)]="crcModel.CRC_LastEnquiryDate"
                bsDatepicker
              /> -->
            </div>
            <div class="col-md-3 p-1">
              <span class="required-lable"> Recommendation </span>
              <select
                name="Recommendation"
                id="Recommendation"
                #refRecommendation="ngModel"
                [disabled]="inViewMode"
                [ngClass]="{
                  'is-invalid': f.submitted && refRecommendation.invalid,
                  'alert-warning':
                    refRecommendation.invalid &&
                    (refRecommendation.dirty ||
                      refRecommendation.touched ||
                      refRecommendation.untouched)
                }"
                [(ngModel)]="crcModel.CRC_Recommendation"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Recommendation</option>
                <option value="Positive">Positive</option>
                <option value="Negative">Negative</option>
                <option value="Not Conducted">Not Conducted</option>
              </select>
            </div>
            <div class="col-md-9 p-1">
              <span> Remarks </span>
              <textarea
                name="Remarks"
                id="Remarks"
                #refRemarks="ngModel"
                [disabled]="inViewMode"
                rows="1"
                maxlength="500"
                [ngClass]="{
                  'is-invalid': f.submitted && refRemarks.invalid,
                  'alert-warning':
                    refRemarks.invalid &&
                    (refRemarks.dirty ||
                      refRemarks.touched ||
                      refRemarks.untouched)
                }"
                [(ngModel)]="crcModel.CRC_Remarks"
                class="form-control input-text-css"
                placeholder="Remarks"
                required
              >
              </textarea>
            </div>
            <div class="row m-0 col-md-12 mt-2 p-1 upload" *ngIf="!inViewMode">
              <div class="col-md-6 p-1">
                <p class="m-0 row">
                  Upload CRC Report:<i
                    class="fa fa-upload ml-2"
                    *ngIf="!crcModel.CRC_ReportName || !inViewMode"
                    (click)="docFile.click()"
                    aria-hidden="true"
                  ></i>
                  <ng-container *ngIf="crcModel.CRC_ReportName">
                    <div>
                      <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i
                      >{{ crcModel.CRC_ReportName }}
                      <i
                        class="fa fa-times ml-2"
                        aria-hidden="true"
                        style="cursor: pointer"
                        (click)="removeFile(docFile)"
                      ></i>
                    </div>
                  </ng-container>
                </p>
                <input
                  #docFile
                  type="file"
                  [multiple]="false"
                  accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                  (change)="fileChangeListener(docFile.files)"
                  style="display: none"
                />
              </div>
              <div class="col-md-6 p-1">
                <p class="m-0 row">
                  Upload Other CRC Report:<i
                    class="fa fa-upload ml-2"
                    *ngIf="!crcModel.CRC_ReportName1"
                    (click)="docFile1.click()"
                    aria-hidden="true"
                  ></i>
                  <ng-container *ngIf="crcModel.CRC_ReportName1">
                    <div>
                      <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i
                      >{{ crcModel.CRC_ReportName1 }}
                      <i
                        class="fa fa-times ml-2"
                        aria-hidden="true"
                        style="cursor: pointer"
                        (click)="removeFile1(docFile1)"
                      ></i>
                    </div>
                  </ng-container>
                </p>
                <input
                  #docFile1
                  type="file"
                  [multiple]="false"
                  accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                  (change)="fileChangeListener1(docFile1.files)"
                  style="display: none"
                />
              </div>
            </div>
            <div class="row m-0 col-md-12 mt-2 p-1" *ngIf="inViewMode">
              <div class="col-md-6 p-1">
                <p class="lead fs-14 m-0 row">
                  CRC Reports:
                  <a
                    href="{{ docBaseUrl }}{{ loanSummary.ApplicationNo }}/{{
                      crcModel.CRC_ReportName
                    }}"
                    class="ml-3"
                    target="_blank"
                  >
                    {{ crcModel.CRC_ReportName }}
                    <i class="fa fa-link" aria-hidden="true"></i>
                  </a>
                </p>
              </div>
              <div class="col-md-6 p-1" *ngIf="crcModel.CRC_ReportName1">
                <p class="lead fs-14 m-0 row">
                  Other CRC Reports:
                  <a
                    href="{{ docBaseUrl }}{{ loanSummary.ApplicationNo }}/{{
                      crcModel.CRC_ReportName1
                    }}"
                    class="ml-3"
                    target="_blank"
                  >
                    {{ crcModel.CRC_ReportName1 }}
                    <i class="fa fa-link" aria-hidden="true"></i>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnAdditionalCRCClose()"
            class="btn btn-light font-size-12"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveCrc()"
            *ngIf="isShowSave"
            class="btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
          <button
            type="button"
            (click)="UpdateCrc()"
            [class.spinner]="loading"
            [hidden]="!isShowUpdate"
            class="btn font-size-12 button-btn"
          >
            Update
          </button>
          <button
            type="button"
            (click)="EditCRC()"
            [class.spinner]="loading"
            [hidden]="!isShowEdit"
            class="btn font-size-12 button-btn"
          >
            Edit
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="modal fade in"
  id="cibilReport"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Cibil Report
        </h6>

        <button
          type="button"
          (click)="onCloseCibilReport()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>

      <div class="modal-body" style="font-size: 10px">
        <ng-container *ngIf="error == true && cibilResponse">
          <app-quickloan-cibil-report [cibilResponse]="cibilResponse">
          </app-quickloan-cibil-report>
        </ng-container>
        <ng-container *ngIf="error == false && cibilResponse">
          <div
            class="row m-0 mt-2 formborder"
            *ngFor="
              let errorc of cibilResponse?.controlData?.errorResponseArray
            "
          >
            <div class="col-md-6">
              <h6 class="fs-12">Error Code</h6>
              <p class="lead fs-12">{{ errorc.errorCode }}</p>
            </div>
            <div class="col-md-6">
              <h6 class="fs-12">Error Code</h6>
              <p class="lead fs-12">{{ errorc.errorMessage }}</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="crifReport"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Crif Report
        </h6>
        <button
          type="button"
          (click)="onCloseCrifReport()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body" style="font-size: 10px">
        <ng-container id="crif" *ngIf="!criferror && crifResponse">
          <!--<app-quickloan-cibil-report [cibilResponse]="crifResponse">
          </app-quickloan-cibil-report>-->
        </ng-container>
        <ng-container *ngIf="criferror && crifResponse">
          <div class="row m-0 mt-2 formborder" *ngFor="let errorc of criferror">
            <div class="col-md-6">
              <h6 class="fs-12">Error Code</h6>
              <p class="lead fs-12">{{ errorc.errorCode }}</p>
            </div>
            <div class="col-md-6">
              <h6 class="fs-12">Error Message</h6>
              <p class="lead fs-12">{{ errorc.errorMessage }}</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
