import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { LosService } from "../../_LOS/services/los.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "../../Shared/constantUrl";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { LmsService } from "../../_Lms/services/lms.service";
import { DialogService } from "../../Shared/dialog.service";
import { ToWords } from "to-words";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { QuickLoanService } from "../services/quickLoan.service";

@Component({
  selector: "app-quickloan-account-info",
  templateUrl: "./quickloan-account-info.component.html",
  styleUrls: ["./quickloan-account-info.component.scss"],
})
export class QuickloanAccountInfoComponent implements OnInit {
  // AccountComponent same as LosMyLeadtProcessComponentcurrentUser: any;
  currentUser: any;
  showSpinner: boolean = false;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  tab: any;
  rdDetailData: any;
  ReceiptData: any = {};
  ReceiptDeduction: any = [];
  inquiryId: any;
  inquiryDetail: any;
  loading: boolean = false;
  isFrequency: boolean = false;
  rdDataSource: any = "";
  CurrentDate: any = new Date();
  ApplicationId: any;
  applicationId: any;
  applicationDetail: any = "";

  isCalEMITableView: boolean = true;
  isCalEMIForm: boolean = false;
  disabled: boolean = true;
  stepEmiForm: boolean = true;
  ProductId: any;
  Name: any = "Months";
  minLoanMaturityDate: any;
  productDropdown: any[] = [];
  chargesDropdown: any[] = [];
  dataSource: any;
  displayedColumns: string[] = [
    "SchemeId",
    "Scheme",
    "Amount",
    "ROI",
    "Period",
    "AdvanceEMI",
    "Select",
  ];
  selectedScheme: any = {};
  cEIModel: any = {
    Asset_Cost: 0,
    IRR_Type: "Flat",
    EMI_Type: "Monthly",
    ProductId: "",
    IRR_CalculateBy: "ROI",
    Adv_Inst: 0,
  };
  stepEMI: any[] = [];
  emiIrr: any;
  IsEdit: any = 1;
  stepEmiData: any;
  //LoanId: any;
  //type: any;
  LoanDetail: any;
  IsReceiptSave: boolean = true;
  IsReceiptUpdate: boolean = false;
  IsCloseBtn: boolean = false;
  // @Input() Type: any = '';
  // @Input() LoanId: any;
  // @Input() applicationNo: any;
  @Input() loanSummary: any;
  @Input() Revert_IsEdit: any;

  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  Voucher_Detail: any = { TaxSlab: [] };
  chargesHeadDropdown: any[] = [];
  customerdataSource: any;
  customerdisplayedColumns: string[] = [
    "LoanId",
    "LoanNo",
    "Product_Name",
    "Customer",
    "PhoneNo",
    "Branch_Name",
    "Action",
  ];
  totalCalculator: any;
  dataSourceCustomer: any;
  Customer: any = {};
  displayedCustomerSearchColumns: string[] = [
    "Type",
    "Application_No",
    "LoanAcNo",
    "CustomertName",
    "FatherName",
    "Customer_Gender",
    "GenderAge",
    "PhoneNo",
    "EditAction",
  ];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  CustomerList: any[] = [];
  Type: any = "0";
  PageAcess: any;
  minDate: any;
  @Input() Processid: any;
  @Input() PageData: any;
  @Input() isNew: boolean;
  @Input() Status: any;
  @Input() ProcessName: any;

  constructor(
    private dataSharingService: DataSharingService,
    private dialog: DialogService,
    private _MasterService: MasterService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private encdec: EncrDecrService,
    private router: Router,
    private losService: LosService,
    private LmsService: LmsService,
    private _QuickLoanService: QuickLoanService
  ) {}

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next(this.ProcessName);
    this.minDate = new Date();
    if (this.loanSummary.Product_Category == "Single Installment") {
      this.cEIModel.EMI_Type = "SingleEMI";
      this.cEIModel.No_Of_Inst = 1;
      this.Name = "Days";
    }
    this.LOS_GetApplicationDetailsForSaleProcess();
    this.GetReceiptDeduction();

    this.ProductId = this.loanSummary.ProductId;
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getProductDropdown();
    this.tab = "list";
  }

  getLoanAccount() {
    this.stepEMI = [];
    this.showSpinner = true;
    this.LmsService.FinancialDetailsForUpdate({
      Loan_Id: this.loanSummary.LoanId,
    }).subscribe((res: any) => {
      // isCalEMITableView
      if (res.Item1.length > 0) {
        this.LoanDetail = res.Item1[0];
        this.IsEdit = res.Item1[0].IsEdit;
        //this.isCalEMITableView = false;
        //this.isCalEMIForm = true;
        if (res.Item1[0].IRR_CalculateBy) {
          this.cEIModel.Asset_Cost = res.Item1[0].AssetCost
            ? res.Item1[0].AssetCost == -1 || res.Item1[0].AssetCost == 0
              ? 0
              : res.Item1[0].AssetCost
            : 0;
          this.cEIModel.NetFinance_Amt = res.Item1[0].NetFinance;
          this.cEIModel.IRR_CalculateBy = res.Item1[0].IRR_CalculateBy;
          this.cEIModel.EMI_Type = res.Item1[0].EMI_Type;
          this.cEIModel.No_Of_Inst = res.Item1[0].No_Of_Instl;
          this.cEIModel.Tenure = res.Item1[0].Tenure;
          this.cEIModel.Adv_Inst = res.Item1[0].Adv_Instl;
          this.cEIModel.MgmtFee = res.Item1[0].ManagementFee;
          this.cEIModel.First_EMI_Date = new Date(res.Item1[0].FirstEMIDate);
          this.cEIModel.Flat_Rate = res.Item1[0].Flat_Rate;
          this.cEIModel.EMI_Amount = res.Item1[0].EMIAmount;
          this.cEIModel.IRR_CalculateBy = res.Item1[0].IRR_CalculateBy;
          this.cEIModel.Reducing_ROI = res.Item1[0].Case_IRR;
          this.cEIModel.Loan_Date = new Date(res.Item1[0].Loan_Date);
          this.cEIModel.LoanMaturityDate = new Date(
            res.Item1[0].LoanMaturityDate
          );
          this.cEIModel.ROI_Input_mathod = res.Item1[0].ROI_Input_mathod;
          if (this.loanSummary.Product_Category == "Single Installment") {
            this.cEIModel.EMI_Type = "SingleEMI";
          }
          if (
            this.cEIModel.EMI_Type == "Daily" ||
            this.cEIModel.EMI_Type == "Weekly" ||
            this.cEIModel.EMI_Type == "Fortnightly" ||
            this.cEIModel.EMI_Type == "ByWeekly" ||
            this.cEIModel.EMI_Type == "FourthWeekly" ||
            this.cEIModel.EMI_Type == "SingleEMI"
          )
            this.Name = "Days";
          else this.Name = "Months";
          if (this.cEIModel.IRR_CalculateBy == "Reducing_ROI")
            this.isFrequency = true;
          $(function () {
            $("#Flat_Rate").val(res.Item1[0].Flat_Rate);
          });

          $(function () {
            $("#EMI_Amounts").val(res.Item1[0].EMIAmount);
          });

          let data = {
            Disbursement_Amt: res.Item1[0].DisbursementAmt,
            Interest_Amt: res.Item1[0].InterestAmt,
            Agreement_Value: res.Item1[0].AgreementValue,
            LTV: res.Item1[0].LTV,
            ROI: res.Item1[0].Flat_Rate,
            EMI_Amt: res.Item1[0].EMIAmount,
            Case_IRR: res.Item1[0].Case_IRR,
            Disbursement_IRR: res.Item1[0].Disbursement_IRR,
            Margin: res.Item1[0].Margin,
          };
          this.emiIrr = data;
          if (res.Item1[0].IRR_CalculateBy == "ROI") {
            // this.cEIModel.IsStep = true;
            this.stepEMI.push({
              FromEMI: 1,
              ToEMI: this.cEIModel.No_Of_Inst,
              EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
              NoOfInstl: this.cEIModel.No_Of_Inst,
              TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
              disable: true,
            });
            // document.getElementById("CalculateByFlat")['checked'] = true;
            //var amt = this.emiIrr.ROI.toFixed(2);
            //$(function () {
            //  $("#Flat_Rate").val(amt);
            //});
          }
          if (res.Item1[0].IRR_CalculateBy == "STEP_EMI") {
            let stepArr: any[] = [];
            this.cEIModel.IsStep = true;
            this.stepEMI = res.Item2;

            for (let i = 0; i < res.Item2.length; i++) {
              this.stepEMI[i].EMI_Amount = res.Item2[i].EMIAmount;
              this.stepEMI[i].NoOfInstl = res.Item2[i].NoOfEMI;
              for (
                let j = +this.stepEMI[i].FromEMI;
                j <= +this.stepEMI[i].ToEMI;
                j++
              ) {
                if (+this.stepEMI[i].EMI_Amount >= 0) {
                  stepArr.push(this.stepEMI[i].EMI_Amount);
                }
              }
            }
            this.getTotlaEmiAndAmount();
          }
          if (res.Item1[0].IRR_CalculateBy == "FLAT_EMI") {
            this.stepEMI.push({
              FromEMI: 1,
              ToEMI: this.cEIModel.No_Of_Inst,
              EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
              NoOfInstl: this.cEIModel.No_Of_Inst,
              TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
              disable: true,
            });
            //  document.getElementById("CalculateByEMI")['checked'] = true;
            //var amt = this.emiIrr.EMI_Amt.toFixed(2);
            //$(function () {
            //  $("#EMI_Amounts").val(amt);
            //});
          }
        }
      }

      this.showSpinner = false;
    });
  }

  CalCGSTSGST() {
    let amt = this.ReceiptData.Amount;
    let pert = (this.ReceiptData.Amount * 9) / 100;
    this.ReceiptData.CGST = pert;
    this.ReceiptData.SGST = pert;
    this.ReceiptData.TotalAmount = amt + pert + pert;
  }

  EmiCalculator() {
    this.isCalEMITableView = true;
    this.isCalEMIForm = false;
  }

  LOS_GetInquiryDetails() {
    this.showSpinner = true;
    this.losService
      .LOS_GetInquiryDetails({ InquiryId: this.inquiryId })
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.inquiryDetail = res[0];
        this.cEIModel.Purpose = this.inquiryDetail.Purpose;
        this.cEIModel.NetFinance_Amt = this.inquiryDetail.LoanAmount;
      });
  }
  getProductDropdown() {
    this.showSpinner = true;
    this._MasterService.GetProductForDropdown().subscribe((result: any) => {
      this.productDropdown = JSON.parse(JSON.stringify(result));
      this.showSpinner = false;
    });
    this.showSpinner = false;
  }
  geSchemeList() {
    this.showSpinner = false;
    this._MasterService
      .LOS_GetSchemeToValidateLoan({ ProductId: this.cEIModel.ProductId })
      .subscribe((result: any) => {
        this.dataSource = new MatTableDataSource(
          JSON.parse(JSON.stringify(result))
        );
        this.showSpinner = false;
      });
  }
  onCheckboxChange(e: any, data: any) {
    if (this.selectedScheme.SchemeId == data.SchemeId) {
      this.selectedScheme = {};
    } else {
      this.selectedScheme = data;
      this.cEIModel.Adv_Inst = data.AdvanceEMI;
    }
  }
  onClose() {
    this.isCalEMITableView = false;
    this.isCalEMIForm = true;
    this.IsCloseBtn = false;
  }
  changeRDTab(tab: any) {
    if (tab != this.tab) {
      this.tab = tab;
      // this.onRDOpen(false);
    }
    // this.ReceiptData = {};
    // this.IsReceiptSave = true;
    // this.IsReceiptUpdate = false;
    if (tab == "new") {
      this.initializeVoucherDetail();
      //this.getChargeshead();
    }
  }

  onRDOpen(isOpen: boolean = true) {
    this.showSpinner = true;
    this.losService
      .LOS_GetApplicationCharges({ ApplicationId: this.ApplicationId })
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.rdDetailData = res;
        this.rdDataSource = new MatTableDataSource(res.Item2);
        this.rdDetailData.New = {
          ApplicationId: this.ApplicationId,
          LoginUserId: this.currentUser.userId,
          ChargeHeadId: "",
          ChargeReceiptDeduction: "",
        };

        //this.LOS_GetApplicationProductChargesHeadsForDropdown();

        // if (isOpen) {
        //    this.tab = 'list';
        //     this.LOS_GetApplicationProductChargesHeadsForDropdown();
        //     $('#rddetailModel').modal('show');
        //     $("#rddetailModel").css("z-index", "1050");
        // }
      });
    this.showSpinner = false;
  }

  onSaveRdDetail() {
    this.showSpinner = true;
    // this.ReceiptData.Loan_Id = this.loanSummary.LoanId;
    // this.ReceiptData.CollectionBy = 'Amit';
    // this.ReceiptData.CollectionOn = new Date();
    // this.ReceiptData.Int_Id = 0;
    // this.ReceiptData.IsActive = 0;
    this.LmsService.SaveReceiptDeduction({
      JSON: JSON.stringify({ Loaninfo: this.Voucher_Detail }),
    }).subscribe((res: any) => {
      this.showSpinner = false;
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
        this.tab = "list";
        this.GetReceiptDeduction();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
      }
      //this.rdDataSource = new MatTableDataSource(res.Item2);
    });
  }
  onUpdateRdDetail() {
    this.showSpinner = true;
    this.ReceiptData.Loan_Id = this.loanSummary.LoanId;
    this.ReceiptData.CollectionBy = "Amit";
    this.ReceiptData.CollectionOn = new Date();
    this.ReceiptData.IsActive = 0;
    this.LmsService.SaveReceiptDeduction(this.ReceiptData).subscribe(
      (res: any) => {
        this.showSpinner = false;
        this.tab = "list";
        this.GetReceiptDeduction();
        this.ReceiptData = {};
        //this.rdDataSource = new MatTableDataSource(res.Item2);
      }
    );
  }
  GetReceiptDeduction() {
    this.LmsService.GetReceiptDeduction({
      Loan_Id: this.loanSummary.LoanId,
    }).subscribe((res: any) => {
      this.ReceiptDeduction = res;
    });
  }

  LOS_GetApplicationProductChargesHeadsForDropdown() {
    this.losService
      .LOS_GetApplicationProductChargesHeadsForDropdown({
        ApplicationId: this.ApplicationId,
      })
      .subscribe((res: any) => {
        for (var i = 0; i < res.length; i++) {
          if (res[i].Id == 27 || res[i].Id == 28) {
            res.splice(i, 1);
          }
        }
        this.chargesDropdown = res;
      });
  }

  OnFinancialDetailClose(event: any) {
    // if (event) {
    //     this.LOS_GetApplicationDetailsForSaleProcess();
    // }
  }
  onEditFinanceDetail() {
    this.isCalEMITableView = true;
    this.isCalEMIForm = false;
    this.IsCloseBtn = true;
    this.getLoanAccount();
  }
  LOS_GetApplicationDetailsForSaleProcess() {
    this.showSpinner = true;

    this.LmsService.LMS_GetApplicationDetailsForSaleProcess({
      Loan_Id: this.loanSummary.LoanId,
    }).subscribe((res: any) => {
      this.showSpinner = false;
      if (res.Item1[0].NetFinance > 0) {
        this.applicationDetail = res.Item1[0];

        if (
          this.applicationDetail.EMI_Type == "Daily" ||
          this.applicationDetail.EMI_Type == "Weekly" ||
          this.applicationDetail.EMI_Type == "Fortnightly" ||
          this.applicationDetail.EMI_Type == "ByWeekly" ||
          this.applicationDetail.EMI_Type == "FourthWeekly" ||
          this.applicationDetail.EMI_Type == "SingleEMI"
        )
          this.applicationDetail.Name = "Days";
        else this.applicationDetail.Name = "Months";
        this.dataSharingService.LmsHeaderNetFinance.next(
          this.applicationDetail.NetFinance
        );
        this.isCalEMITableView = false;
        this.isCalEMIForm = true;
        this.IsCloseBtn = false;
        // this.customerlist = res.Item2;
        // this.dataCustomerSource = new MatTableDataSource(res.Item2);
        // this.dataCustomerAddressSource = new MatTableDataSource(res.Item3);
        // this.dataCustomerDocSource = new MatTableDataSource(res.Item4);
        //this.questionData = res.Item5;
        //this.applicationModel.Recommendation =
        this.applicationDetail.Recommendation || "";
        //this.applicationModel.Remarks = this.applicationDetail.Remark;
        //this.inViewMode = this.applicationDetail.Process_Status == "Completed";
      } else {
        this.isCalEMITableView = true;
        this.isCalEMIForm = false;
        this.IsCloseBtn = true;
      }
    });
  }

  onCalculateEmiAndIRR() {
    let netAmount: any[] = [];
    let roi: any[] = [];

    if (+this.cEIModel.NetFinance_Amt <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Net Finance Amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.Flat_Rate < 0 &&
      this.cEIModel.IRR_CalculateBy == "ROI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Flat rate should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.EMI_Amount <= 0 &&
      this.cEIModel.IRR_CalculateBy == "FLAT_EMI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMI amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.No_Of_Inst <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No of Installment should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.Tenure <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Tenure should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      this.cEIModel.IRR_CalculateBy == "FLAT_EMI" &&
      +this.cEIModel.EMI_Amount * +this.cEIModel.No_Of_Inst <
        +this.cEIModel.NetFinance_Amt
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No of Installment multiply by EMI amount should be greater than or equal to Net Finance Amount.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.cEIModel.EMI_Type == "SingleEMI") {
      var ROI = parseFloat(this.cEIModel.Flat_Rate);
      if (this.loanSummary.ROI_Input_mathod == "Daily") {
        ROI = ROI * this.currentUser.DaysInYear;
      }
      var Interest_Amt = Math.round(
        ((parseFloat(this.cEIModel.NetFinance_Amt) * ROI) /
          100 /
          this.currentUser.DaysInYear) *
          this.cEIModel.Tenure
      );
      var Agreement_Value = Math.round(
        parseFloat(this.cEIModel.NetFinance_Amt) +
          parseFloat(Interest_Amt.toString())
      );

      let data = {
        Disbursement_Amt: parseFloat(this.cEIModel.NetFinance_Amt),
        Interest_Amt: Interest_Amt,
        Agreement_Value: Agreement_Value,
        ROI: parseFloat(this.cEIModel.Flat_Rate),
        EMI_Amt: Agreement_Value,
        Case_IRR: parseFloat(this.cEIModel.Flat_Rate),
        Disbursement_IRR: parseFloat(this.cEIModel.Flat_Rate),
        Margin: 0,
      };
      this.emiIrr = data;

      if (this.cEIModel.IRR_CalculateBy == "ROI") {
        this.stepEMI = [];

        this.stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfInstl: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
        var amt = this.emiIrr.EMI_Amt.toFixed(2);
        $(function () {
          $("#EMI_Amounts").val(amt);
        });
      }

      if (this.cEIModel.IRR_CalculateBy == "FLAT_EMI") {
        this.stepEMI = [];

        this.stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfInstl: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
        var amt = this.emiIrr.ROI.toFixed(2);
        $(function () {
          $("#Flat_Rate").val(amt);
        });
      }
    } else {
      if (this.selectedScheme.Amount) {
        netAmount = this.selectedScheme.Amount.split("-");
        roi = this.selectedScheme.ROI.split("-");
        if (
          +this.cEIModel.NetFinance_Amt > +netAmount[1] ||
          +this.cEIModel.NetFinance_Amt < +netAmount[0]
        ) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Net Finance Amount should be in between Scheme Amount.",
            ...this.configSuccess,
          });
          return;
        }
        if (
          this.cEIModel.IRR_CalculateBy == "ROI" &&
          (+this.cEIModel.Flat_Rate > +roi[1] ||
            +this.cEIModel.Flat_Rate < +roi[0])
        ) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Flate Rate should be in between Scheme ROI.",
            ...this.configSuccess,
          });
          return;
        }
        if (+this.cEIModel.Adv_Inst < +this.selectedScheme.AdvanceEMI) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Advance Installment can not be less than Scheme Advance EMI.",
            ...this.configSuccess,
          });
          return;
        }
        this.calculateEmiIrr();
      } else {
        this.calculateEmiIrr();
      }
    }
  }

  calculateEmiIrr() {
    let stepArr: any[] = [];
    if (this.cEIModel.IRR_CalculateBy == "STEP_EMI") {
      this.cEIModel.IsStep = true;
      if (this.stepEMI.length < 1) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please add step EMI first",
          ...this.configSuccess,
        });
        return;
      }
      let lastEMI = this.stepEMI[this.stepEMI.length - 1].ToEMI;
      if (+lastEMI != +this.cEIModel.No_Of_Inst) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Total EMI Should Be Equal To No of Installment",
          ...this.configSuccess,
        });
        return;
      }
      if (
        this.stepEmiData != undefined &&
        +this.stepEmiData.totalAmount < +this.cEIModel.NetFinance_Amt
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Total EMI Amount should be  equal or Greater then  Net Finance Amount.",
          ...this.configSuccess,
        });
        return;
      }
      for (let i = 0; i < this.stepEMI.length; i++) {
        for (
          let j = +this.stepEMI[i].FromEMI;
          j <= +this.stepEMI[i].ToEMI;
          j++
        ) {
          if (+this.stepEMI[i].EMI_Amount >= 0) {
            stepArr.push(this.stepEMI[i].EMI_Amount);
          }
        }
      }
    } else {
      this.cEIModel.IsStep = false;
      this.cEIModel.STEP_IRR = 0;
    }
    let _data = { ...this.cEIModel };
    _data.cashflow = stepArr;
    if (this.cEIModel.IsStep) {
      _data.EMI_Amount = this.stepEmiData.totalAmount;
    }
    // if (this.cEIModel.Adv_Inst > 0) {
    _data.DaysInYear = this.currentUser.DaysInYear;
    //  this.LmsService.LMS_GetCalculateEMIIRR(_data).subscribe((res: any) => {
    //    if (res.length > 0) {
    //      _data.NetFinance_Amt =
    //        this.cEIModel.NetFinance_Amt -
    //        this.cEIModel.Adv_Inst * res[0].EMI_Amt;
    //      _data.No_Of_Inst = this.cEIModel.No_Of_Inst - this.cEIModel.Adv_Inst;
    //      console.log("_data.NetFinance_Amt", _data.NetFinance_Amt);
    //      this.getCalculateEMIIRR(_data);
    //    }
    //  });
    //} else {
    this.getCalculateEMIIRR(_data);
    // }
  }

  getCalculateEMIIRR(data: any) {
    this.showSpinner = true;
    // data.ProductId = 2;
    data.DaysInYear = this.currentUser.DaysInYear;
    this.LmsService.LMS_GetCalculateEMIIRR(data).subscribe((res: any) => {
      this.showSpinner = false;
      this.emiIrr = res[0];
      setTimeout(() => {
        var elmntToView = document.getElementById("scrollto");
        elmntToView.scrollIntoView();
      }, 500);

      if (this.cEIModel.IRR_CalculateBy == "ROI") {
        this.stepEMI = [];

        this.stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfInstl: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
        var amt = this.emiIrr.EMI_Amt.toFixed(2);
        $(function () {
          $("#EMI_Amounts").val(amt);
        });
      }

      if (this.cEIModel.IRR_CalculateBy == "FLAT_EMI") {
        this.stepEMI = [];

        this.stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfInstl: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
        var amt = this.emiIrr.ROI.toFixed(2);
        $(function () {
          $("#Flat_Rate").val(amt);
        });
      }
    });
  }

  handleByChange(event: any) {
    //   this.emiIrr = undefined;
    //   if (this.cEIModel.IRR_CalculateBy == 'ROI') {
    //       delete this.cEIModel.EMI_Amount;
    //   }
    //   else {
    //       delete this.cEIModel.Flat_Rate;
    //   }
    this.emiIrr = undefined;
    if (this.cEIModel.IRR_CalculateBy == "ROI") {
      delete this.cEIModel.EMI_Amount;
    } else {
      delete this.cEIModel.Flat_Rate;
    }
    if (this.cEIModel.IRR_CalculateBy !== "STEP_EMI") {
      this.stepEMI = [];
    } else {
      this.stepEMI.push({
        FromEMI: 1,
        ToEMI: "",
        EMI_Amount: "",
        disable: false,
      });
      this.stepEmiData = undefined;
    }
    //this.stepEmiForm = false;
  }
  setTenure() {
    if (this.cEIModel.No_Of_Inst && this.cEIModel.EMI_Type != "SingleEMI") {
      if (this.cEIModel.EMI_Type == "ByMonthly") {
        this.cEIModel.Tenure = 2 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Quarterly") {
        this.cEIModel.Tenure = 3 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "HalfYearly") {
        this.cEIModel.Tenure = 6 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Yearly") {
        this.cEIModel.Tenure = 12 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Weekly") {
        this.cEIModel.Tenure = 7 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Fortnightly") {
        this.cEIModel.Tenure = 15 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "ByWeekly") {
        this.cEIModel.Tenure = 14 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "FourthWeekly") {
        this.cEIModel.Tenure = 28 * +this.cEIModel.No_Of_Inst;
      } else {
        this.cEIModel.Tenure = this.cEIModel.No_Of_Inst;
      }

      if (
        this.cEIModel.EMI_Type == "Daily" ||
        this.cEIModel.EMI_Type == "Weekly" ||
        this.cEIModel.EMI_Type == "Fortnightly" ||
        this.cEIModel.EMI_Type == "ByWeekly" ||
        this.cEIModel.EMI_Type == "FourthWeekly" ||
        this.cEIModel.EMI_Type == "SingleEMI"
      )
        this.Name = "Days";
      else this.Name = "Months";
    } else {
      this.cEIModel.Tenure = "";
    }
  }
  handleChange(event: any) {
    this.emiIrr = undefined;
    if (this.cEIModel.IRR_Type == "Flat") {
      this.stepEMI = [];
    } else {
      this.stepEMI.push({
        FromEMI: 1,
        ToEMI: "",
        EMI_Amount: "",
        disable: false,
      });
      this.stepEmiData = undefined;
    }
  }
  addNextStep(data: any) {
    if (+data.ToEMI <= 0 || +data.EMI_Amount < 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Fill All Data.",
        ...this.configSuccess,
      });
      return;
    } else if (+data.ToEMI > +this.cEIModel.No_Of_Inst) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "To Emi can not be greater than number of installment.",
        ...this.configSuccess,
      });
      return;
    }
    // if (+data.ToEMI == +this.cEIModel.No_Of_Inst) {
    //     this.snackBar.openFromComponent(SnackbarComponent, { data: "Another Step can not be added. You can add till number of installment.", ...this.configSuccess });
    //     return;
    // }
    else {
      if (+data.FromEMI > +data.ToEMI) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "TO EMI should be greater than FROM EMI.",
          ...this.configSuccess,
        });
        return;
      }
      data.NoOfInstl = +data.ToEMI - +data.FromEMI + 1;
      data.TotalAmount = data.NoOfInstl * +data.EMI_Amount;
      if (+data.ToEMI < +this.cEIModel.No_Of_Inst) {
        data.disable = true;
        this.stepEMI.push({
          FromEMI: +data.ToEMI + 1,
          ToEMI: "",
          EMI_Amount: "",
          disable: false,
        });
      }
      this.getTotlaEmiAndAmount();
    }
  }
  getTotlaEmiAndAmount() {
    if (this.stepEMI.length > 1) {
      let TotalEmi: any = 0,
        TotalAmount: any = 0;
      for (let i = 0; i < this.stepEMI.length; i++) {
        if (this.stepEMI[i].EMI_Amount) {
          TotalEmi =
            TotalEmi + (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1);
          TotalAmount =
            TotalAmount +
            (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1) *
              +this.stepEMI[i].EMI_Amount;
        }
      }
      this.stepEmiData = { totalEmi: TotalEmi, totalAmount: TotalAmount };
    }
  }
  removeLastStep(index: any) {
    this.stepEMI.splice(index, 1);
    this.stepEMI[index - 1].disable = false;
    this.getTotlaEmiAndAmount();
  }

  onSaveApplication() {
    if (this.isCalEMIForm == true) {
      this.LmsService.SaveNext_Loan({
        Loan_Id: this.loanSummary.LoanId,
        CommandName: "Account",
        isStatus: 1,
      }).subscribe((response) => {
        this.QuickLoan_Save_PageProcess("Account Info");
        this.action.emit("next");
      });
    } else {
      if (+this.cEIModel.NetFinance_Amt <= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Net Finance Amount should be greater than 0.",
          ...this.configSuccess,
        });
        return;
      }
      if (
        +this.cEIModel.Flat_Rate < 0 &&
        this.cEIModel.IRR_CalculateBy == "ROI"
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Flate rate should be greater than 0.",
          ...this.configSuccess,
        });
        return;
      }
      if (
        +this.cEIModel.EMI_Amount <= 0 &&
        this.cEIModel.IRR_CalculateBy == "EMI"
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Positive EMI amount to be Entered",
          ...this.configSuccess,
        });
        return;
      }
      if (+this.cEIModel.No_Of_Inst <= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Positive No of Installment to be Entered",
          ...this.configSuccess,
        });
        return;
      }
      if (+this.cEIModel.Tenure <= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Positive Tenure to be Entered",
          ...this.configSuccess,
        });
        return;
      }
      if (
        this.cEIModel.IRR_CalculateBy !== "STEP_EMI" &&
        +this.cEIModel.No_Of_Inst * +this.emiIrr.EMI_Amt <
          +this.cEIModel.NetFinance_Amt
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "EMI multiply with installment should be equal or grater than net finance.",
          ...this.configSuccess,
        });
        return;
      }
      if (this.cEIModel.IRR_Type == "Step") {
        let length = this.stepEMI.length;
        let ToEmi = this.stepEMI[length - 1].ToEMI;
        if (+ToEmi != +this.cEIModel.No_Of_Inst) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Add EMI step.",
            ...this.configSuccess,
          });
          return;
        }
        if (
          this.emiIrr.Disbursement_Amt + this.emiIrr.Interest_Amt !=
          +this.stepEmiData.totalAmount
        ) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Total EMI Amount must be equal to  (Disbursement Amount  + Interest Amount)",
            ...this.configSuccess,
          });
          return;
        }
      }

      let _stepEmi: any[] = [];
      this.stepEMI.forEach((obj: any) => {
        _stepEmi.push(this.dataSharingService.cleanObject({ ...obj }));
      });
      if (
        this.emiIrr.Interest_Amt == null ||
        this.emiIrr.Interest_Amt == 0 ||
        this.emiIrr.Interest_Amt == ""
      ) {
        this.emiIrr.Interest_Amt = 0;
        this.emiIrr.Case_IRR = 0;
        this.emiIrr.Disbursement_IRR = 0;
      }
      var FirstEMIDate = new Date();
      if (this.cEIModel.EMI_Type == "SingleEMI") {
        FirstEMIDate = this.cEIModel.LoanMaturityDate;
      }
      let data = {
        Application: this.dataSharingService.cleanObject({
          LoanId: this.loanSummary.LoanId,
          //BranchId: 1,
          ProductId: this.cEIModel.ProductId,
          Asset_Cost: this.cEIModel.Asset_Cost,
          NetFinance_Amt: this.cEIModel.NetFinance_Amt,
          Flat_Rate: this.emiIrr.ROI,
          No_Of_Inst: this.cEIModel.No_Of_Inst,
          Tenure: this.cEIModel.Tenure,
          Adv_Inst: this.cEIModel.Adv_Inst,
          MgmtFee: 0, // this.cEIModel.MgmtFee,
          EMI_Type: this.cEIModel.EMI_Type,
          Disbursement_Amt: this.emiIrr.Disbursement_Amt,
          Interest_Amt: this.emiIrr.Interest_Amt,
          Agreement_Value: this.emiIrr.Agreement_Value,
          LTV: this.emiIrr.LTV,
          EMI_Amt: this.emiIrr.EMI_Amt,
          Case_IRR: this.emiIrr.Case_IRR,
          Disbursement_IRR: this.emiIrr.Disbursement_IRR,
          Loan_Purpose: this.cEIModel.Purpose,
          Loan_SchemeId: this.selectedScheme.SchemeId,
          FirstEMIDate: FirstEMIDate, // this.cEIModel.First_EMI_Date,
          IRR_Type: this.cEIModel.IRR_Type,
          LoginUserId: this.currentUser.userId,
          ROI: this.emiIrr.ROI,
          EMI_Amount: this.cEIModel.EMI_Amount,
          IRR_CalculateBy: this.cEIModel.IRR_CalculateBy,
          LoanMaturityDate: this.cEIModel.LoanMaturityDate,
          Loan_Date: this.cEIModel.Loan_Date,
        }),
        StepIRR: _stepEmi,
      };

      this.LmsService.LMS_SaveGenerateApplicationNo({
        JSON: JSON.stringify(data),
      }).subscribe((res: any) => {
        //this.LmsService
        //  .Save_Case_Logs({ Loan_Id: this.loanSummary.LoanId, JSON: JSON.stringify(dataLogs), CreatedBy: this.currentUser.userId, TagName:'Account Info' })
        //  .subscribe((res: any) => {
        //  });
        this.LmsService.Get_AccountHead_For_VoucherEntry({
          HeadId: 34,
          VoucherSubType: "Loan",
          ProductId: this.ProductId || 0,
          TaxType: "CR",
        }).subscribe((res: any) => {
          if (res.Item1[0]) {
            if (res.Item1[0].AccountId) {
              //data.AccountId = res.Item1[0].AccountId;
              //data.ChargeHeadCategory = res.Item1[0].AccountName;
              //data.TaxSlab = res.Item2;

              if (this.cEIModel.Adv_Inst > 0) {
                for (let i = 0; i < this.cEIModel.Adv_Inst; i++) {
                  this.ReceiptData.LoanId = this.loanSummary.LoanId;
                  this.ReceiptData.DeductionLoanId = this.loanSummary.LoanId;

                  this.ReceiptData.LoginUserId = this.currentUser.userId;
                  this.ReceiptData.CollectionOn = new Date();
                  this.ReceiptData.Int_Id = 0;
                  this.ReceiptData.ChargeHeadId = 34;
                  this.ReceiptData.ChargeHeadCategory =
                    res.Item1[0].AccountName;
                  this.ReceiptData.AccountId = res.Item1[0].AccountId;

                  this.ReceiptData.IsActive = 0;
                  this.ReceiptData.Charges = "Advance EMI";
                  this.ReceiptData.Type = "Deduction";
                  this.ReceiptData.Amount = this.emiIrr.EMI_Amt;
                  this.ReceiptData.CGST = 0;
                  this.ReceiptData.SGST = 0;
                  this.ReceiptData.TotalAmount = this.emiIrr.EMI_Amt;

                  this.LmsService.SaveReceiptDeduction({
                    JSON: JSON.stringify({ Loaninfo: this.ReceiptData }),
                  }).subscribe((res: any) => {
                    this.showSpinner = false;
                    //this.rdDataSource = new MatTableDataSource(res.Item2);
                  });
                }
                this.GetReceiptDeduction();
              }
            }
          }
        });

        this.loading = false;
        this.isCalEMITableView = false;
        this.isCalEMIForm = true;
        this.IsCloseBtn = false;

        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          setTimeout(() => {
            this.dataSharingService.LmsHeaderProduct.next(this.ProductId);
          }, 50);

          this.LmsService.SaveNext_Loan({
            Loan_Id: this.loanSummary.LoanId,
            CommandName: "Account",
            isStatus: 1,
          }).subscribe((response) => {
            this.QuickLoan_Save_PageProcess("Account Info");
            this.action.emit("next");
          });
          //this.router.navigateByUrl("/lms/asset-info");
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
    }
  }

  Get_ReceiptDeduction(data: any) {
    var Id = data.Int_Id;
    this.ReceiptData.Loan_Id = this.loanSummary.LoanId;
    this.ReceiptData.CollectionBy = "Amit";
    this.ReceiptData.CollectionOn = new Date();
    this.ReceiptData.Int_Id = data.Int_Id;
    this.ReceiptData.IsActive = 0;
    this.ReceiptData.Charges = data.Charges;
    this.ReceiptData.Type = data.Type;
    this.ReceiptData.Amount = data.Amount;
    this.ReceiptData.CGST = data.CGST;
    this.ReceiptData.SGST = data.SGST;
    this.ReceiptData.TotalAmount = data.TotalAmount;
    this.IsReceiptSave = false;
    this.IsReceiptUpdate = true;
    //this.LmsService.SaveReceiptDeduction(this.ReceiptData).subscribe((res: any) => {
    //  this.showSpinner = false;

    //  //this.rdDataSource = new MatTableDataSource(res.Item2);
    //});
    this.changeTab("new");
  }
  changeTab(tab: any) {
    if (tab != this.tab) {
      this.tab = tab;
      // this.onRDOpen(false);
    }
  }
  removeReceiptDeduction(data: any) {
    this.dialog
      .openConfirmDialog(`Are you sure you want to remove.`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.LmsService.Delete_ReceiptDeduction({
            Id: data.Id,
            LoginUserId: this.currentUser.userId,
          }).subscribe((res: any) => {
            if (res[0].CODE == 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: res[0].MSG,
                ...this.configSuccess,
              });
              this.GetReceiptDeduction();
            }
          });
        }
      });
  }

  initializeVoucherDetail() {
    let data;
    console.log("this.loanSummary", this.loanSummary);

    //if we are in same loan Search info Auto Fill below code
    if (Object.keys(this.Customer).length === 0) {
      this.Customer.ApplicationNo = this.loanSummary.ApplicationNo;
    }

    this._MasterService
      .Get_SearchCustomerByLMSLOS({ JSON: JSON.stringify(this.Customer) })
      .subscribe((res: any) => {
        //data = res.filter(x => x.Application_No == this.applicationDetail.ApplicationNo);
        data = res.filter(
          (x) => x.Application_No == this.loanSummary.ApplicationNo
        );
        console.log("RES : ", res);

        this.Voucher_Detail = {
          LoanId: this.loanSummary.LoanId,
          LoginUserId: this.currentUser.userId,
          DeductionLoanId: data[0].LoanId,
          CaseNo: data[0].Application_No,
          CustomerId: data[0].CustomerId,
          Is_LOSAppliaction: "0",
          ChargeHeadId: "",
          ChargeHeadCategory: "",
          AccountId: "",
          BranchId: data[0].BranchId,
          TranType: "CR",
          IsTax: 0,
          Tax_Per: 0,
          Amount: "",
          Customer: data[0].CustomertName,
          Branch_Name: data[0].Branch_Name,
          ProductId: data[0].ProductId,
          TaxSlab: [],
        };
        this.getChargeshead();
      });
  }
  getChargeshead() {
    this._MasterService
      .Get_ChargesHeadsByProduct_ForDropdow({
        ProductId: this.Voucher_Detail.ProductId,
      })
      .subscribe((res: any) => {
        this.chargesHeadDropdown = res;
      });
  }
  searchCustomer() {
    this.customerdataSource = new MatTableDataSource([]);
    $("#CustomerReceipt").modal("show");
    $("#CustomerReceipt").css("z-index", "1050");
    setTimeout(() => {
      $("#SearchValue").focus();
    }, 100);
  }
  Get_Acc_Search_LoanNo() {
    this.LmsService.Get_Acc_Search_LoanNo({
      SearchOn: "LoanNo",
      SearchValue: this.Voucher_Detail.SearchValue,
    }).subscribe((res: any) => {
      if (res.length > 0) {
        if (res.length == 1) {
          this.selectSearchData(res[0]);
        }
        this.customerdataSource = new MatTableDataSource(res);
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Search not found.",
          ...this.configSuccess,
        });
      }
    });
  }
  selectSearchData(data: any) {
    this.Voucher_Detail.DeductionLoanId = data.LoanId;
    this.Voucher_Detail.CaseNo = data.Application_No;
    this.Voucher_Detail.Customer = data.CustomertName;
    this.Voucher_Detail.CustomerId = data.CustomerId;
    this.Voucher_Detail.ProductId = data.ProductId;
    this.Voucher_Detail.BranchId = data.BranchId;
    this.Voucher_Detail.Branch_Name = data.Branch_Name;
    this.Voucher_Detail.Is_LOSAppliaction = data.Type == "LMS" ? 0 : 1;
    this.Voucher_Detail.ChargeHeadId = "";
    this.Voucher_Detail.LoanId = this.loanSummary.LoanId;
    this.Voucher_Detail.LoginUserId = this.currentUser.userId;
    this.Voucher_Detail.Amount = "";
    this.Voucher_Detail.TaxSlab = [];
    this.getChargeshead();
    this.OnCloseSearch();
  }
  OnCloseSearch() {
    $("#CustomerReceipt").modal("hide");
    $("#Customer").focus();
    this.Voucher_Detail.SearchValue = "";
  }
  onChangeChargesHead(data: any) {
    data.Amount = "";
    data.TaxSlab = [];
    this.LmsService.Get_AccountHead_For_VoucherEntry({
      HeadId: data.ChargeHeadId,
      VoucherSubType: "Loan",
      ProductId: data.ProductId || 0,
      TaxType: data.TranType,
    }).subscribe((res: any) => {
      if (res.Item1[0]) {
        if (res.Item1[0].AccountId) {
          data.AccountId = res.Item1[0].AccountId;
          data.ChargeHeadCategory = res.Item1[0].AccountName;
          data.TaxSlab = res.Item2;
        } else {
          data.ChargeHeadId = "";
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Account Head not linked with this category.",
            ...this.configSuccess,
          });
        }
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "No data Found.",
          ...this.configSuccess,
        });
      }
    });
  }
  onChangeAmount(event: any, data: any) {
    if (event) {
      event.preventDefault();
    }
    data.TaxSlab.forEach((obj: any) => {
      obj.TaxAmount = ((parseFloat(data.Amount) * obj.TaxRate) / 100).toFixed(
        2
      );
    });
    this.getTotalCalculation();
  }
  getTotalCalculation() {
    let _total: any = 0;
    let _totalCR: any = 0;
    let _totalTX: any = 0;
    _total = _total + this.Voucher_Detail.Amount || 0;
    _totalCR = _totalCR + this.Voucher_Detail.Amount || 0;
    this.Voucher_Detail.TaxSlab.forEach((tobj: any) => {
      _total = parseFloat(_total) + parseFloat(tobj.TaxAmount) || 0;
      _totalTX = parseFloat(_totalTX) + parseFloat(tobj.TaxAmount) || 0;
    });
    this.totalCalculator = {
      total: Number(_total),
      totalCR: Number(_totalCR),
      totaltax: Number(_totalTX),
      totalInWord: new ToWords().convert(Number(_total)),
    };
  }
  onSearchCustomerDetail() {
    this._MasterService
      .Get_SearchCustomerByLMSLOS({ JSON: JSON.stringify(this.Customer) })
      .subscribe((res: any) => {
        console.log(res);
        if (res.length > 0) {
          this.displayedCustomerSearchColumns = [
            "Type",
            "Application_No",
            "LoanAcNo",
            "CustomertName",
            "FatherName",
            "Customer_Gender",
            "GenderAge",
            "PhoneNo",
            "EditAction",
          ];

          this.CustomerList = JSON.parse(JSON.stringify(res));

          //this.CustomerList = this.CustomerList.filter(item => item.Type == 'LMS');

          this.dataSourceCustomer = new MatTableDataSource(this.CustomerList);
          this.dataSourceCustomer.sort = this.sort;
          this.showSpinner = false;
        }
      });
  }

  setType(event: any) {
    if (this.cEIModel.IRR_CalculateBy == "STEP_EMI") {
      this.stepEmiForm = true;
      this.cEIModel.EMI_Type = "Monthly";
      this.cEIModel.Tenure = this.cEIModel.No_Of_Inst;
    }
    this.stepEmiData = undefined;
    if (this.cEIModel.IRR_CalculateBy == "Reducing_ROI")
      this.isFrequency = true;
    else this.isFrequency = false;
    //if (this.cEIModel.Type == 'EMIAmt')
    //  this.cEIModel.IRR_CalculateBy = 'FLAT_EMI'
    //if (this.cEIModel.Type == 'StepEMI')
    //  this.cEIModel.IRR_CalculateBy = 'STEP_EMI'

    this.emiIrr = undefined;
    if (this.cEIModel.IRR_CalculateBy == "ROI") {
      delete this.cEIModel.EMI_Amount;
    } else {
      delete this.cEIModel.Flat_Rate;
    }

    if (this.cEIModel.IRR_CalculateBy !== "STEP_EMI") {
      this.stepEMI = [];
    } else {
      this.stepEMI = [];
      this.stepEMI.push({
        FromEMI: 1,
        ToEMI: "",
        EMI_Amount: "",
        disable: false,
      });
      this.stepEmiData = undefined;
    }
  }

  QuickLoan_Save_PageProcess(PageType) {
    this._QuickLoanService
      .QuickLoan_Save_PageProcess({
        PageType: PageType,
        ProductId: this.loanSummary.ProductId,
        Loan_Id: this.loanSummary.LoanId,
        Process_Id: this.Processid,
        Page_Id: this.PageData.MM_Id,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res) => {
        //return res[0].CODE;
      });
  }
  DaysDifference() {
    if (
      this.cEIModel.Loan_Date != null &&
      this.cEIModel.LoanMaturityDate != null &&
      this.cEIModel.LoanMaturityDate != undefined
    ) {
      const startTime = new Date(this.cEIModel.Loan_Date).getTime();
      const endTime = new Date(this.cEIModel.LoanMaturityDate).getTime();

      // Calculate the difference in milliseconds
      const difference = endTime - startTime;

      // Convert milliseconds to days
      this.cEIModel.Tenure = Math.ceil(difference / (1000 * 3600 * 24));
      this.emiIrr = undefined;
    }
  }
  ChangeText() {
    this.emiIrr = undefined;
  }
  changeLoan_Date() {
    var year = new Date(this.cEIModel.Loan_Date).getFullYear();
    var month = new Date(this.cEIModel.Loan_Date).getMonth();
    var day = new Date(this.cEIModel.Loan_Date).getDate();
    var c = new Date(year, month, day + 1);
    console.log("new date", c);
    this.minLoanMaturityDate = c;
    this.DaysDifference();
  }

  setAdvanceInstl() {
    if (Number(this.cEIModel.Adv_Inst) >= Number(this.cEIModel.No_Of_Inst)) {
      this.cEIModel.Adv_Inst = 0;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Advance Installment not greater than No. Of Installment !!!",
        ...this.configSuccess,
      });
    }
  }
}
