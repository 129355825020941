<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="onSearchCollectionEfficiency()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-2">
        <span class="required-lable">From Date</span>

        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="FromDate"
            id="FromDate"
            #refFromDate="ngModel"
            (dateChange)="DateChange()"
            class="form-control input-text-css"
            [(ngModel)]="FromDate"
            [ngClass]="{
              'is-invalid': df.submitted && refFromDate.invalid,
              'alert-warning':
                refFromDate.invalid &&
                (refFromDate.dirty ||
                  refFromDate.touched ||
                  refFromDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
      <div class="col-md-2">
        <span class="required-lable">Date To</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ToDate"
            id="ToDate"
            #refToDate="ngModel"
            (dateChange)="DateChange()"
            class="form-control input-text-css"
            [(ngModel)]="ToDate"
            [ngClass]="{
              'is-invalid': df.submitted && refToDate.invalid,
              'alert-warning':
                refToDate.invalid &&
                (refToDate.dirty || refToDate.touched || refToDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
      </div>
      <div class="col-md-2">
        <span>Group By</span>
        <select
          name="Type"
          id="Type"
          class="form-control input-text-css"
          [(ngModel)]="Type"
        >
          <option [value]="0">Branch</option>
          <option [value]="1">Collection Executive</option>
        </select>
      </div>
      <div class="col-md-2">
        <span class="required-lable"> Accounting Category </span>
        <select
          name="Loan_AccountingCategoryId"
          id="Loan_AccountingCategoryId"
          [(ngModel)]="LoanAccountCategory"
          class="form-control input-text-css"
        >
          <option value="0">All</option>
          <option
            *ngFor="let AccountingCat of AccountingCatDropdown"
            [value]="AccountingCat.Loan_CategoryId"
          >
            {{ AccountingCat.Loan_category }}
          </option>
        </select>
      </div>
      <div class="col-md-2">
        <span> Source Type </span>
        <select
          name="SelectSourceType"
          id="SelectSourceType"
          #refSelectSourceType="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refSelectSourceType.invalid,
            'alert-warning':
              refSelectSourceType.invalid &&
              (refSelectSourceType.dirty ||
                refSelectSourceType.touched ||
                refSelectSourceType.untouched)
          }"
          (change)="Get_PartnerForDropdown()"
          [(ngModel)]="SelectSourceType"
          class="form-control input-text-css"
        >
          <option value="">Select Source Type</option>
          <option
            *ngFor="let lead of leadSourceDropdown"
            [value]="lead.Lead_Source"
          >
            {{ lead.Lead_Source }}
          </option>
        </select>
      </div>

      <ng-container
        *ngIf="
          SelectSourceType == 'Agent' ||
          SelectSourceType == 'DSA' ||
          SelectSourceType == 'Dealer' ||
          SelectSourceType == 'Sales Executive' ||
          SelectSourceType == 'Telecaller Executive' ||
          SelectSourceType == 'Marketing Executive'
        "
      >
        <div class="col-md-3">
          <span> Source Name</span>
          <select
            name="LeadSourceName"
            id="LeadSourceName"
            #refLeadSourceName="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refLeadSourceName.invalid,
              'alert-warning':
                refLeadSourceName.invalid &&
                (refLeadSourceName.dirty ||
                  refLeadSourceName.touched ||
                  refLeadSourceName.untouched)
            }"
            [(ngModel)]="LeadSourceName"
            class="form-control input-text-css"
          >
            <option value="">Select Lead Reference</option>
            <option
              *ngFor="let partner of partnerDropdown"
              [value]="partner.PartnerId"
            >
              {{ partner.Partner_Name }}
            </option>
          </select>
        </div>
      </ng-container>
      <div class="col-md-2" style="padding-top: 22px">
        <mat-checkbox name="ODInterest" [(ngModel)]="LPI"></mat-checkbox>

        <span style="margin-left: 5px">Interest OverDue</span>
      </div>

      <!-- class="col-md-12 d-flex justify-content-between" -->

      <div class="col-md-2 ml-4" [hidden]="!dataSourceTree">
        <button
          type="button"
          *ngIf="ExpandBtn"
          class="mt-3 btn font-size-10 button-btn"
          (click)="OnClickExpandAll()"
        >
          Expand All
        </button>
        <button
          type="button"
          *ngIf="CollaspeBtn"
          class="mt-3 btn font-size-12 button-btn"
          (click)="OnClickCollaspeAll()"
        >
          Collapse All
        </button>
      </div>

      <div class="col-md-2" [hidden]="!dataSourceTree">
        <button
          class="mt-3 btn font-size-12 button-btn"
          (click)="exportExcelDetails()"
          style="width: max-content !important"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export Detail
        </button>
      </div>

      <div class="col-md-2" [hidden]="!dataSourceTree">
        <button
          class="mt-3 btn font-size-12 button-btn"
          (click)="exportExcelSummary(exporter)"
          style="width: max-content !important"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export Summary
        </button>
      </div>

      <!-- <div class="col-md-2"></div> -->
      <!-- <div class="col-md-2"></div>
      <div class="col-md-1"></div> -->

      <div class="col-md-2">
        <button
          type="button"
          (click)="onSearchCollectionEfficiency()"
          [disabled]="!df.form.valid"
          class="mt-3 btn font-size-12 button-btn ml-3"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="mt-3" [hidden]="!dataSourceTree">
    <div class="">
      <div
        class="table-responsive"
        style="
          min-height: none;
          overflow: scroll;
          position: absolute;
          max-height: -webkit-fill-available;
          max-width: -webkit-fill-available;
        "
      >
        <table
          mat-table
          [dataSource]="dataSourceTree"
          class="mat-elevation-z8"
          matSort
          matTableExporter
          #exporter="matTableExporter"
        >
          <ng-container matColumnDef="Name_type">
            <th mat-header-cell *matHeaderCellDef>
              <span [style.paddingLeft.px]="40"> &nbsp;<!--Name--> </span>
            </th>
            <td mat-cell *matCellDef="let data">
              <button
                mat-icon-button
                [style.visibility]="!data.expandable ? 'hidden' : ''"
                [style.marginLeft.px]="data.level * 20"
                (click)="treeControl.toggle(data)"
              >
                <mat-icon class="mat-icon-rtl-mirror">
                  {{ treeControl.isExpanded(data) ? "remove" : "add" }}
                </mat-icon>
              </button>

              {{ data.Name_type }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-align"></th>
          </ng-container>
          <ng-container matColumnDef="CustomerName">
            <th mat-header-cell *matHeaderCellDef>Customer Name</th>
            <td mat-cell *matCellDef="let data">{{ data.CustomerName }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-align"></th>
          </ng-container>
          <ng-container matColumnDef="Product_Name">
            <th mat-header-cell *matHeaderCellDef>Product Name</th>
            <td mat-cell *matCellDef="let data">{{ data.Product_Name }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-align"></th>
          </ng-container>
          <ng-container matColumnDef="Loan_Acc_Category">
            <th mat-header-cell *matHeaderCellDef>Loan Account Category</th>
            <td mat-cell *matCellDef="let data">
              {{ data.Loan_Acc_Category }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-align"></th>
          </ng-container>

          <ng-container matColumnDef="DPD_Days">
            <th mat-header-cell *matHeaderCellDef>DPD Days</th>
            <td mat-cell *matCellDef="let data">{{ data.DPD_Days }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-align"></th>
          </ng-container>

          <ng-container matColumnDef="SalesExecutive">
            <th mat-header-cell *matHeaderCellDef>Sales Executive</th>
            <td mat-cell *matCellDef="let data">{{ data.SalesExecutive }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-align"></th>
          </ng-container>

          <ng-container matColumnDef="LastReceivedDate">
            <th mat-header-cell *matHeaderCellDef>Last Received Date</th>
            <td mat-cell *matCellDef="let data">{{ data.LastReceivedDate }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-align"></th>
          </ng-container>

          <ng-container matColumnDef="VehicleNo">
            <th mat-header-cell *matHeaderCellDef>VehicleNo</th>
            <td mat-cell *matCellDef="let data">{{ data.VehicleNo }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-align"></th>
          </ng-container>

          <ng-container matColumnDef="Type_Which">
            <th mat-header-cell *matHeaderCellDef>
              {{ Type == 1 ? "Branch" : "Collection Exec" }}
            </th>
            <td mat-cell *matCellDef="let data">{{ data.Type_Which }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-align"></th>
          </ng-container>
          <ng-container matColumnDef="ODInterestBalance">
            <th mat-header-cell *matHeaderCellDef>LPI</th>
            <td mat-cell *matCellDef="let data">
              {{ data.ODInterestBalance.toFixed(2) }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-align">
              {{ totaltypewise1("ODInterestBalance") }}
            </th>
          </ng-container>
          <ng-container matColumnDef="OP_OD">
            <th mat-header-cell *matHeaderCellDef>OP OD</th>
            <td mat-cell *matCellDef="let data">{{ data.OP_OD }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-align">
              {{ totaltypewise1("OP_OD") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="OP_FU_Current">
            <th mat-header-cell *matHeaderCellDef>OP Fu Current</th>
            <td mat-cell *matCellDef="let data">{{ data.OP_FU_Current }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-align">
              {{ totaltypewise1("OP_FU_Current") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="OP_FU_Next">
            <th mat-header-cell *matHeaderCellDef>OP Fu Next</th>
            <td mat-cell *matCellDef="let data">{{ data.OP_FU_Next }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-align">
              {{ totaltypewise1("OP_FU_Next") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Current_Due">
            <th mat-header-cell *matHeaderCellDef>Current Due</th>
            <td mat-cell *matCellDef="let data">{{ data.Current_Due }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-align">
              {{ totaltypewise1("Current_Due") }}
            </th>
          </ng-container>
          <ng-container matColumnDef="DueDate">
            <th mat-header-cell *matHeaderCellDef>Due Date</th>
            <td mat-cell *matCellDef="let data">{{ data.DueDate }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-align">
              {{ totaltypewise1("DueDate") }}
            </th>
          </ng-container>
          <ng-container matColumnDef="DueEMI">
            <th mat-header-cell *matHeaderCellDef>Due EMI</th>
            <td mat-cell *matCellDef="let data">{{ data.DueEMI }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-align">
              {{ totaltypewise1("DueEMI") }}
            </th>
          </ng-container>
          <ng-container matColumnDef="Total_Due">
            <th mat-header-cell *matHeaderCellDef>Total Due</th>
            <td mat-cell *matCellDef="let data">{{ data.Total_Due }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-align">
              {{ totaltypewise1("Total_Due") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Collection_OP_OD">
            <th mat-header-cell *matHeaderCellDef>Collection OP OD</th>
            <td mat-cell *matCellDef="let data">{{ data.Collection_OP_OD }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-align">
              {{ totaltypewise1("Collection_OP_OD") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Collection_Current">
            <th mat-header-cell *matHeaderCellDef>Collection Current</th>
            <td mat-cell *matCellDef="let data">
              {{ data.Collection_Current }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-align">
              {{ totaltypewise1("Collection_Current") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Efficiency_Current">
            <th mat-header-cell *matHeaderCellDef>Efficiency Current</th>
            <td mat-cell *matCellDef="let data">
              {{
                data.Efficiency_Current == 0
                  ? data.Efficiency_Current.toFixed(2) + "%"
                  : data.Efficiency_Current
                  ? data.Efficiency_Current.toFixed(2) + "%"
                  : ""
              }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-align">
              <!-- {{ (tree[i].Total_Collection / tree[i].Total_Due) * 100 }} -->
              {{ calculateEfficiencyCurrent() }}%
            </th>
          </ng-container>
          <ng-container matColumnDef="Efficiency_OD">
            <th mat-header-cell *matHeaderCellDef>Efficiency OD</th>
            <td mat-cell *matCellDef="let data">
              {{
                data.Efficiency_OD == 0
                  ? data.Efficiency_OD.toFixed(2) + "%"
                  : data.Efficiency_OD
                  ? data.Efficiency_OD.toFixed(2) + "%"
                  : ""
              }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-align">
              {{ calculateEfficiencyOD() }}%
            </th>
          </ng-container>

          <ng-container matColumnDef="Collection_Future">
            <th mat-header-cell *matHeaderCellDef>Collection Future</th>
            <td mat-cell *matCellDef="let data">
              {{ data.Collection_Future }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-align">
              {{ totaltypewise1("Collection_Future") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="Total_Collection">
            <th mat-header-cell *matHeaderCellDef>Total Collection</th>
            <td mat-cell *matCellDef="let data">{{ data.Total_Collection }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-align">
              {{ totaltypewise1("Total_Collection") }}
            </th>
          </ng-container>

          <ng-container matColumnDef="CL_OD">
            <th mat-header-cell *matHeaderCellDef>CL OD</th>
            <td mat-cell *matCellDef="let data">{{ data.CL_OD }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-align">
              {{ totaltypewise1("CL_OD") }}
            </th>
          </ng-container>
          <ng-container matColumnDef="SourceType">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Source Type
            </th>
            <td mat-cell *matCellDef="let data">{{ data.SourceType }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="SourceName">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Source Name
            </th>
            <td mat-cell *matCellDef="let data">
              {{ data.SourceName }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Customer_PhoneNo">
            <th mat-header-cell *matHeaderCellDef>Customer PhoneNo</th>
            <td mat-cell *matCellDef="let data">
              <div *ngIf="currentUser.IsMaskingMobile">
                {{ data.Customer_PhoneNo | mask }}
              </div>

              <div *ngIf="!currentUser.IsMaskingMobile">
                {{ data.Customer_PhoneNo }}
              </div>
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-align"></th>
          </ng-container>
          <ng-container matColumnDef="Alternate_PhoneNo">
            <th mat-header-cell *matHeaderCellDef>Alternate Phone No</th>
            <td mat-cell *matCellDef="let data">
              <div *ngIf="currentUser.IsMaskingMobile">
                {{ data.Alternate_PhoneNo | mask }}
              </div>

              <div *ngIf="!currentUser.IsMaskingMobile">
                {{ data.Alternate_PhoneNo }}
              </div>
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-align"></th>
          </ng-container>
          <ng-container matColumnDef="Customer_Address">
            <th mat-header-cell *matHeaderCellDef>Customer Address</th>
            <td mat-cell *matCellDef="let data">{{ data.Customer_Address }}</td>
            <th mat-footer-cell *matFooterCellDef class="text-align"></th>
          </ng-container>
          <ng-container matColumnDef="Co_Borrower_Name">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Co-Borrower Name
            </th>
            <td mat-cell *matCellDef="let data">{{ data.Co_Borrower_Name }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Co_Borrower_MobileNo">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Co-Borrower Mobile No
            </th>
            <td mat-cell *matCellDef="let data">
              <div *ngIf="currentUser.IsMaskingMobile">
                {{ data.Co_Borrower_MobileNo | mask }}
              </div>

              <div *ngIf="!currentUser.IsMaskingMobile">
                {{ data.Co_Borrower_MobileNo }}
              </div>
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="guarantor_Name">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Guarantor Name
            </th>
            <td mat-cell *matCellDef="let data">{{ data.guarantor_Name }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="PDC_Type">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Repayment Type
            </th>
            <td mat-cell *matCellDef="let data">{{ data.PDC_Type }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Companybank">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Company Bank
            </th>
            <td mat-cell *matCellDef="let data">{{ data.Companybank }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="UmrnNumber">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              UMRN Number
            </th>
            <td mat-cell *matCellDef="let data">{{ data.UmrnNumber }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="EMIFrequency">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              EMI Frequency
            </th>
            <td mat-cell *matCellDef="let data">{{ data.EMIFrequency }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="LoanDate">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Loan Date</th>
            <td mat-cell *matCellDef="let data">{{ data.LoanDate }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="FirstEMIDate">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              First EMI Date
            </th>
            <td mat-cell *matCellDef="let data">{{ data.FirstEMIDate }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="guarantor_MobileNo">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>
              Guarantor Mobile No
            </th>
            <td mat-cell *matCellDef="let data">
              <div *ngIf="currentUser.IsMaskingMobile">
                {{ data.guarantor_MobileNo | mask }}
              </div>

              <div *ngIf="!currentUser.IsMaskingMobile">
                {{ data.guarantor_MobileNo }}
              </div>
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Efficiency_Total">
            <th mat-header-cell *matHeaderCellDef>Efficiency Total</th>
            <td mat-cell *matCellDef="let data">
              {{
                data.Efficiency_Total == 0
                  ? data.Efficiency_Total.toFixed(2) + "%"
                  : data.Efficiency_Total
                  ? data.Efficiency_Total.toFixed(2) + "%"
                  : ""
              }}
            </td>
            <th mat-footer-cell *matFooterCellDef class="text-align">
              <!-- {{ totaltypewise1("Efficiency_Total") + "%" }} -->
              {{ calculateTotalEfficiency() }}%
            </th>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedCTreeolumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; let i = index; columns: displayedCTreeolumns"
            [ngClass]="{ 'highlight-row': selectedRow === row }"
            (click)="selectRow(row)"
          ></tr>
          <tr
            mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedCTreeolumns; sticky: true"
            style="background: #4dc3a3"
          ></tr>
        </table>
      </div>
    </div>
  </div>
</div>

<table
  id="collection_efficency_detail"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Acc Category
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collection Executive
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Due Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Due EMI
    </th>
    <th
      *ngIf="LPI"
      style="background: #4dc3a3 !important; border: 1px solid white"
    >
      LPI
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      OP OD
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      OP Fu Current
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      OP Fu Next
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Current Due
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Due
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collection OP OD
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collection Current
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collection Future
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Collection
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      CL OD
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      DPD Days
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Efficiency Total
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Phone No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Alternate Phone No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Address
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Name
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Co Borrower Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Co Borrower Mobile No
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Repayment Type
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Company bank
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      UMRN Number
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Frequency
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      First EMI Date
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Loan_Acc_Category }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.CollectionExecutive_Name }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.DueDate }}</td>
    <td>{{ x.DueEMI }}</td>
    <td *ngIf="LPI">{{ x.ODInterestBalance }}</td>
    <td>{{ x.OP_OD.toFixed(2) }}</td>
    <td>{{ x.OP_FU_Current.toFixed(2) }}</td>
    <td>{{ x.OP_FU_Next.toFixed(2) }}</td>
    <td>{{ x.Current_Due }}</td>
    <td>{{ x.Total_Due }}</td>

    <td>{{ x.Collection_OP_OD }}</td>
    <td>{{ x.Collection_Current }}</td>
    <td>{{ x.Collection_Future }}</td>
    <td>{{ x.Total_Collection }}</td>
    <td>{{ x.CL_OD }}</td>
    <td>{{ x.DPD_Days }}</td>

    <td>
      {{
        x.Efficiency_Total == 0
          ? x.Efficiency_Total.toFixed(2) + ".00%"
          : x.Efficiency_Total
          ? x.Efficiency_Total.toFixed(2) + "%"
          : ""
      }}
    </td>

    <td>
      <div *ngIf="currentUser.IsMaskingMobile">
        {{ x.Customer_PhoneNo | mask }}
      </div>

      <div *ngIf="!currentUser.IsMaskingMobile">
        {{ x.Customer_PhoneNo }}
      </div>
    </td>
    <td>
      <div *ngIf="currentUser.IsMaskingMobile">
        {{ x.Alternate_PhoneNo | mask }}
      </div>

      <div *ngIf="!currentUser.IsMaskingMobile">
        {{ x.Alternate_PhoneNo }}
      </div>
    </td>

    <td>{{ x.Customer_Address }}</td>
    <td>{{ x.SourceType }}</td>
    <td>{{ x.SourceName }}</td>
    <td>{{ x.Co_Borrower_Name }}</td>

    <td>
      <div *ngIf="currentUser.IsMaskingMobile">
        {{ x.Co_Borrower_MobileNo | mask }}
      </div>

      <div *ngIf="!currentUser.IsMaskingMobile">
        {{ x.Co_Borrower_MobileNo }}
      </div>
    </td>

    <td>{{ x.PDC_Type }}</td>
    <td>{{ x.Companybank }}</td>
    <td>{{ x.UmrnNumber }}</td>
    <td>{{ x.EMIFrequency }}</td>
    <td>{{ x.LoanDate }}</td>
    <td>{{ x.FirstEMIDate }}</td>
  </tr>

  <tfoot>
    <tr>
      <td
        colspan="7"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("DueDate") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("DueEMI") }}</strong>
      </td>
      <td
        *ngIf="LPI"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("ODInterestBalance") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("OP_OD") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("OP_FU_Current") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("OP_FU_Next") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("Current_Due") }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("Total_Due") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("Collection_OP_OD") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("Collection_Current") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("Collection_Future") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("Total_Collection") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise1("CL_OD") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>

      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
    </tr>
  </tfoot>
</table>
