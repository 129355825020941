<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<div *ngIf="loanSummary == undefined" class="formborder">
  <div class="row m-0 mt-2" *ngIf="customerDetail">
    <div class="col-md-2">
      <h6 class="fs-12">Application No.</h6>
      <p class="lead m-0 fs-12">{{ customerDetail.ApplicationNo }}</p>
    </div>

    <div id="CAM" class="col-md-2" (click)="ShowCAMReport()">
      <h6 class="fs-12">Loan No</h6>
      <p class="lead m-0 fs-12">{{ customerDetail.LoanNo }}</p>
    </div>

    <div class="col-md-2">
      <h6 class="fs-12">Application Date</h6>
      <p class="lead m-0 fs-12">{{ customerDetail.ApplicationDate }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Loan Date</h6>
      <p class="lead m-0 fs-12">{{ customerDetail.LoanDate }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Branch</h6>
      <p class="lead m-0 fs-12">{{ customerDetail.Branch }}</p>
    </div>
    <div class="col-md-2">
      <h6 class="fs-12">Product</h6>
      <p class="lead m-0 fs-12">{{ customerDetail.Product }}</p>
    </div>

    <div class="col-md-12"><hr /></div>
  </div>

  <div class="mt-1">
    <div class="">
      <div class="table-responsive">
        <mat-table [dataSource]="CustomerDataSource">
          <ng-container matColumnDef="Customer">
            <mat-header-cell class="grid-header mw350" *matHeaderCellDef
              >Customer Name</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell mw350"
            >
              <app-lms-customer-view-model
                [customerId]="row.CustomerId"
                [index]="i"
              >
                {{ row.Customer }}
              </app-lms-customer-view-model>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="FatherName">
            <mat-header-cell class="grid-header mw250" *matHeaderCellDef
              >Relation</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw250">
              {{ row.FatherName }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerType">
            <mat-header-cell class="grid-header mw250" *matHeaderCellDef
              >Customer Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw250">
              {{ row.CustomerType == "Hirer" ? "Borrower" : row.CustomerType }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="GenderAge">
            <mat-header-cell class="grid-header mw250" *matHeaderCellDef
              >Gender/Age</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw250">
              {{ row.GenderAge }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="PhoneNo">
            <mat-header-cell class="grid-header mw250" *matHeaderCellDef
              >Mobile No.</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell mw250">
              <div *ngIf="currentUser.IsMaskingMobile">
                {{ row.PhoneNo | mask }}
              </div>

              <div *ngIf="!currentUser.IsMaskingMobile">
                {{ row.PhoneNo }}
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedCustomerColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedCustomerColumns"
          ></mat-row>
        </mat-table>
      </div>
      <hr />
    </div>
  </div>

  <ng-container *ngIf="customerDetail">
    <div class="">
      <div class="row m-0 mt-1">
        <div class="col-md-2">
          <h6 class="fs-12">Loan Amount</h6>
          <p class="lead m-0 fs-12">₹ {{ customerDetail.LoanAmount }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Interest Amount</h6>
          <p class="lead m-0 fs-12">₹ {{ customerDetail.InterestAmount }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Agree Amount</h6>
          <p class="lead m-0 fs-12">₹ {{ customerDetail.AgreedAmount }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">First EMI Date</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.FirstEMIDate }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Last EMI Date</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.LastEMIDate }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">IRR (%)</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.IRR }} %</p>
        </div>
      </div>
      <div class="row m-0 mt-3">
        <div class="col-md-2">
          <h6 class="fs-12">ROI (%)</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.ROI }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">LTV</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.LTV }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">No. of Installment</h6>
          <p class="lead m-0 fs-12">{{ customerDetail.NoOfInstallment }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">
            Tenure ({{ customerDetail != undefined ? LoanTenureFreq : "" }})
          </h6>

          <p class="lead m-0 fs-12">
            {{ customerDetail.Tenure }}
            {{ customerDetail != undefined ? LoanTenureFreq : "" }}
          </p>
        </div>
        <div class="col-md-12"><hr /></div>
      </div>
    </div>
  </ng-container>
</div>

<div class="row m-0 mt-2 formborder" *ngIf="customerDetail">
  <div class="col-md-6">
    <div class="table-responsive">
      <mat-table [dataSource]="ChargesDataSource">
        <ng-container matColumnDef="UpfrontCharges">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Upfront Charges</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.ChagesHead }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef style="font-size: 12px"
            >Total:</mat-footer-cell
          >
        </ng-container>

        <ng-container matColumnDef="Deduction">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Debit</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Deduction }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef style="font-size: 12px">
            {{ calculateDeductionTotal() }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Receipt">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Credit</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Receipt }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef style="font-size: 12px">
            {{ calculateReceiptTotal() }}
          </mat-footer-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedChargesColumns"
        ></mat-header-row>
        <mat-row
          [ngClass]="{ 'bold-text': row.ChargeHeadId == 28 }"
          *matRowDef="let row; columns: displayedChargesColumns"
        ></mat-row>
        <mat-footer-row
          class="sticky-footer"
          style="font-weight: bold; font-size: 12px"
          *matFooterRowDef="displayedChargesColumns"
        ></mat-footer-row>
      </mat-table>
    </div>
  </div>
  <div class="col-md-6">
    <div class="table-responsive">
      <mat-table [dataSource]="EmiDataSource">
        <ng-container matColumnDef="EMI_Pattern">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >EMI Pattern</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.EMI_Pattern }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef style="font-size: 12px"
            >Total:</mat-footer-cell
          >
        </ng-container>

        <ng-container matColumnDef="NoOfEMI">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >No. Of EMI</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.NoOfEMI }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef style="font-size: 12px">
            {{ calculateTotalEMI() }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="EMIAmount">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >EMI Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.EMIAmount }}
          </mat-cell>
          <mat-footer-cell
            *matFooterCellDef
            style="font-size: 12px"
          ></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="TotalAmount">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Total</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.TotalAmount }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef style="font-size: 12px">
            {{ calculateTotalAmount() }}
          </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedEMIColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedEMIColumns"></mat-row>
        <mat-footer-row
          class="sticky-footer bold-text"
          style="font-weight: bold; font-size: 12px"
          *matFooterRowDef="displayedEMIColumns"
        ></mat-footer-row>
      </mat-table>
    </div>
  </div>
</div>

<div
  class="formborder mt-2"
  *ngIf="PDCDataSource && PDCDataSource.filteredData.length > 0"
>
  <div class="col-md-12"><hr /></div>
  <div class="col-md-12">
    <h1 class="fs-12 mt-2">Disbursment Detail</h1>
    <div class="table-responsive mt-2">
      <mat-table [dataSource]="PDCDataSource">
        <ng-container matColumnDef="Customer">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Customer Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Customer }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Bankname">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Bank Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Bankname }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Branch">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Branch</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Branch }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="AccountNo">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Account No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.AccountNo }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="IFSCCode">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >IFSCCode</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.IFSCCode }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="AmountDisbursment">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.AmountDisbursment }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedPDCColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedPDCColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>

<div
  class="formborder mt-2"
  *ngIf="DataSourceDisbursment && DataSourceDisbursment.filteredData.length > 0"
>
  <div class="col-md-12"><hr /></div>
  <div class="col-md-12">
    <h1 class="fs-12 mt-2">Previous Payment Detail</h1>
    <div class="table-responsive mt-2">
      <mat-table [dataSource]="DataSourceDisbursment">
        <ng-container matColumnDef="VoucherId">
          <mat-header-cell class="grid-header mw50" *matHeaderCellDef
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell mw50"
            >{{ i + 1 }}</mat-cell
          >
          <mat-footer-cell *matFooterCellDef class="mw50"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Voucher_Date">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Voucher Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_Date }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Voucher_No">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Voucher No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_No }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Transaction_Date">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Transaction Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Transaction_Date }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Voucher_E_Ref_No">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Voucher Ref No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_E_Ref_No }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <!--<ng-container matColumnDef="Voucher_Cheque_No">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Cheque No</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">{{ row.Voucher_Cheque_No }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
        </ng-container>-->

        <ng-container matColumnDef="Amount">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Amount }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ calculateDisbursmentTotal() }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Voucher_CreateOn">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Create On</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_CreateOn }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Voucher_CreateBy">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Create By</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Voucher_CreateBy }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="Account_CR">
          <mat-header-cell class="grid-header minw166" *matHeaderCellDef
            >Account CR</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell minw166">
            {{ row.Account_CR }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef class="minw166">
            <!--<mat-radio-group [(ngModel)]="IsDeductLoan" (change)="onIsDeductLoan()" class="padding" aria-label="Select an option">
              <mat-radio-button value="0">Due</mat-radio-button>
              <mat-radio-button value="1">Deduct</mat-radio-button>
            </mat-radio-group>-->
          </mat-footer-cell>
        </ng-container>
        <!--<ng-container matColumnDef="InterestAmount">
          <mat-header-cell class="grid-header" *matHeaderCellDef>Interest Amount</mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            <input type="text" min="0" name="Amount{{ i }}" id="Amount{{ i }}" appTwoDigitDecimaNumber
                   class="form-control input-text-css text-right pr-3"
                   [(ngModel)]="row.InterestAmount">
          </mat-cell>
          <mat-footer-cell *matFooterCellDef class="text-right">
            <span class="form-control1 input-text-css text-right">{{calculateInterestTotal()}}</span></mat-footer-cell>
        </ng-container>-->
        <mat-header-row
          *matHeaderRowDef="displayedColumnsDisbursment"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsDisbursment"
        ></mat-row>
        <mat-footer-row
          class="sticky-footer fontcolor footerBgColor"
          *matFooterRowDef="displayedColumnsDisbursment"
        ></mat-footer-row>
      </mat-table>
    </div>
  </div>
</div>
<div class="Payment-Detail formborder mt-2">
  <div class="">
    <div class="col-md-12 bg-success1">Payment Detail :</div>
  </div>
  <!-- Payment Type Bank -->
  <ng-container>
    <form #f="ngForm" novalidate>
      <div class="row m-0 mt-2" *ngFor="let Bank of BankModel; let i = index">
        <div class="col-md-1 pr-1">
          <span class="required-lable" *ngIf="i == 0">Type</span>
          <select
            name="Type{{ i }}"
            id="Type{{ i }}"
            #refType="ngModel"
            disabled
            [(ngModel)]="Bank.Type"
            [disabled]="Bank.disable || Bank.disableUnderProcess"
            class="form-control input-text-css"
            (change)="onChangePaymentType(Bank, i)"
            [ngClass]="{
              'is-invalid': f.submitted && refType.invalid,
              'alert-warning':
                refType.invalid &&
                (refType.dirty || refType.touched || refType.untouched)
            }"
          >
            <option value="">Select Type</option>
            <option value="Customer">Customer</option>
            <option
              *ngIf="customerDetail.Product_Category == 'Vehicle Loan'"
              value="Dealer"
            >
              Dealer
            </option>
            <!-- <option value="Cash">Cash</option>
            <option value="Other">Other</option> -->
          </select>
        </div>
        <div class="col-md-7 pl-1 pr-1">
          <div class="row m-0">
            <div class="col-md-2 pr-1 pl-1" *ngIf="Bank.Type == 'Customer'">
              <span class="required-lable" *ngIf="i == 0">Sub Type</span>
              <select
                name="SubType{{ i }}"
                id="SubType{{ i }}"
                #refSubType="ngModel"
                required
                [(ngModel)]="Bank.SubType"
                [disabled]="Bank.disable || Bank.disableUnderProcess"
                class="form-control input-text-css"
                (change)="ChangeSubType(Bank, i)"
                [ngClass]="{
                  'is-invalid': f.submitted && refSubType.invalid,
                  'alert-warning':
                    refSubType.invalid &&
                    (refSubType.dirty ||
                      refSubType.touched ||
                      refSubType.untouched)
                }"
              >
                <option value="">Select Type</option>
                <option value="Bank">Bank</option>
                <option value="Cash">Cash</option>
                <option value="OtherParty">Direct Payment</option>
                <option value="Other">Adjust To Accounts</option>
                <option value="Dealer">Dealer</option>
                <option value="Partner">Partner</option>
              </select>
            </div>

            <div
              class="col-md-3 pl-1 pr-1"
              *ngIf="Bank.Type == 'Customer' && Bank.SubType == 'Bank'"
            >
              <span class="required-lable" *ngIf="i == 0">Customer</span>
              <select
                name="Customer{{ i }}"
                id="Customer{{ i }}"
                #refCustomer="ngModel"
                required
                [(ngModel)]="Bank.Customer"
                [disabled]="Bank.disable || Bank.disableUnderProcess"
                class="form-control input-text-css"
                (change)="ChangeBankACNo(Bank, i)"
                [ngClass]="{
                  'is-invalid': f.submitted && refCustomer.invalid,
                  'alert-warning':
                    refCustomer.invalid &&
                    (refCustomer.dirty ||
                      refCustomer.touched ||
                      refCustomer.untouched)
                }"
              >
                <option value="">Select A/C</option>
                <option *ngFor="let row of BankDetailDropdown" [value]="row.Id">
                  {{ row.CustomerBankName }}
                  {{
                    row.CustomerBankName.includes("(Dealer)")
                      ? ""
                      : "(" + row.Type + ")"
                  }}
                </option>
              </select>
            </div>

            <div
              class="col-md-3 pl-1 pr-1"
              *ngIf="Bank.Type == 'Customer' && Bank.SubType == 'Other'"
            >
              <span class="required-lable" *ngIf="i == 0">Customer</span>
              <input
                type="search"
                class="form-control input-text-css"
                (keyup.enter)="ChangeBankACNo(Bank, i)"
                (change)="ChangeBankACNo(Bank, i)"
                [(ngModel)]="Bank.AcHead"
                id="Customer{{ i }}"
                name="Customer{{ i }}"
                list="dynmicACHead"
                autocomplete="off"
                [disabled]="Bank.disable || Bank.disableUnderProcess"
                required
                #refCustomer="ngModel"
                placeholder="Select Dealer"
                [ngClass]="{
                  'is-invalid': f.submitted && refCustomer.invalid,
                  'alert-warning':
                    refCustomer.invalid &&
                    (refCustomer.dirty ||
                      refCustomer.touched ||
                      refCustomer.untouched)
                }"
              />
              <datalist id="dynmicACHead">
                <option
                  *ngFor="let x of OtherDropdown"
                  [value]="x.Account_Name"
                  title="x.Account_Name"
                ></option>
              </datalist>
            </div>

            <div
              class="col-md-3 pl-1 pr-1"
              *ngIf="Bank.Type == 'Customer' && Bank.SubType == 'OtherParty'"
            >
              <span class="required-lable" *ngIf="i == 0">Customer</span>
              <input
                type="text"
                class="form-control input-text-css"
                [(ngModel)]="Bank.Customer"
                id="Customer{{ i }}"
                name="Customer{{ i }}"
                [disabled]="Bank.disable || Bank.disableUnderProcess"
                required
                #refCustomer="ngModel"
                placeholder="Select Dealer"
                [ngClass]="{
                  'is-invalid': f.submitted && refCustomer.invalid,
                  'alert-warning':
                    refCustomer.invalid &&
                    (refCustomer.dirty ||
                      refCustomer.touched ||
                      refCustomer.untouched)
                }"
              />
            </div>

            <div class="col-md-3 pl-1 pr-1" *ngIf="Bank.SubType == 'Dealer'">
              <span class="required-lable" *ngIf="i == 0">Dealer</span>
              <input
                type="search"
                class="form-control input-text-css"
                (keyup.enter)="ChangeBankACNo(Bank, i)"
                (change)="ChangeBankACNo(Bank, i)"
                [(ngModel)]="Bank.AcHead"
                id="Customer{{ i }}"
                name="Customer{{ i }}"
                list="dynmicBank"
                autocomplete="off"
                required
                #refCustomer="ngModel"
                placeholder="Select Dealer"
                [disabled]="Bank.disable || Bank.disableUnderProcess"
                [ngClass]="{
                  'is-invalid': f.submitted && refCustomer.invalid,
                  'alert-warning':
                    refCustomer.invalid &&
                    (refCustomer.dirty ||
                      refCustomer.touched ||
                      refCustomer.untouched)
                }"
              />
              <datalist id="dynmicBank">
                <option
                  *ngFor="let x of DealerDropdown"
                  [value]="x.AccountId + '_' + x.Partner_AccountHolderName"
                ></option>
              </datalist>
            </div>

            <div class="col-md-3 pl-1 pr-1" *ngIf="Bank.SubType == 'Partner'">
              <span class="required-lable" *ngIf="i == 0">Partner</span>
              <input
                type="search"
                class="form-control input-text-css"
                (keyup.enter)="ChangeBankACNo(Bank, i)"
                (change)="ChangeBankACNo(Bank, i)"
                [(ngModel)]="Bank.AcHead"
                id="Customer1{{ i }}"
                name="Customer1{{ i }}"
                list="dynmicBank1"
                autocomplete="off"
                required
                #refCustomer="ngModel"
                placeholder="Select Partner"
                [disabled]="Bank.disable || Bank.disableUnderProcess"
                [ngClass]="{
                  'is-invalid': f.submitted && refCustomer.invalid,
                  'alert-warning':
                    refCustomer.invalid &&
                    (refCustomer.dirty ||
                      refCustomer.touched ||
                      refCustomer.untouched)
                }"
              />
              <datalist id="dynmicBank1">
                <option
                  *ngFor="let x of PartnerDropdown"
                  [value]="x.AccountId + '_' + x.Partner_AccountHolderName"
                ></option>
              </datalist>
            </div>
            <ng-container
              *ngIf="
                (Bank.SubType == 'Bank' && Bank.Customer) ||
                (Bank.SubType == 'Other' && Bank.AcHead) ||
                (Bank.SubType == 'Dealer' && Bank.AcHead) ||
                (Bank.SubType == 'Partner' && Bank.AcHead)
              "
            >
              <div class="col-md-2 pl-1 pr-1">
                <span *ngIf="i == 0">A/C No.</span>
                <input
                  type="text"
                  name="AccountNo{{ i }}"
                  id="AccountNo{{ i }}"
                  [(ngModel)]="Bank.AccountNo"
                  readonly
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-1 pl-1 pr-1">
                <span *ngIf="i == 0">Bank</span>
                <input
                  type="text"
                  name="BankName{{ i }}"
                  id="BankName{{ i }}"
                  [(ngModel)]="Bank.BankName"
                  readonly
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2 pl-1 pr-1">
                <span *ngIf="i == 0">IFSC</span>
                <input
                  type="text"
                  name="BankIFSC{{ i }}"
                  id="BankIFSC{{ i }}"
                  [(ngModel)]="Bank.BankIFSC"
                  readonly
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2 pl-1 pr-1">
                <span *ngIf="i == 0">Branch</span>
                <input
                  type="text"
                  name="BankBranch{{ i }}"
                  id="BankBranch{{ i }}"
                  [(ngModel)]="Bank.BankBranch"
                  readonly
                  class="form-control input-text-css"
                />
              </div>
            </ng-container>

            <ng-container *ngIf="Bank.SubType == 'OtherParty'">
              <div class="col-md-2 pl-1 pr-1">
                <span *ngIf="i == 0">A/C No.</span>
                <input
                  type="text"
                  name="AccountNo{{ i }}"
                  id="AccountNo{{ i }}"
                  [(ngModel)]="Bank.AccountNo"
                  [disabled]="Bank.disable || Bank.disableUnderProcess"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-1 pl-1 pr-1">
                <span *ngIf="i == 0">Bank</span>
                <input
                  type="text"
                  name="BankName{{ i }}"
                  id="BankName{{ i }}"
                  [(ngModel)]="Bank.BankName"
                  [disabled]="Bank.disable || Bank.disableUnderProcess"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-2 pl-1 pr-1">
                <span *ngIf="i == 0">IFSC</span>
                <input
                  type="text"
                  name="BankIFSC{{ i }}"
                  id="BankIFSC{{ i }}"
                  [(ngModel)]="Bank.BankIFSC"
                  [disabled]="Bank.disable || Bank.disableUnderProcess"
                  class="form-control input-text-css"
                />
              </div>
              <!--<div class="col-md-1 pl-1 pr-1">
                <span *ngIf="i==0">AC Type</span>
                <input type="text" name="AcType{{i}}" id="AcType{{i}}" [(ngModel)]="Bank.AcType" readonly class="form-control input-text-css">
              </div>-->
              <div class="col-md-2 pl-1 pr-1">
                <span *ngIf="i == 0">Branch</span>
                <input
                  type="text"
                  name="BankBranch{{ i }}"
                  id="BankBranch{{ i }}"
                  [(ngModel)]="Bank.BankBranch"
                  [disabled]="Bank.disable || Bank.disableUnderProcess"
                  class="form-control input-text-css"
                />
              </div>
            </ng-container>
            <ng-container *ngIf="Bank.SubType == 'Cash'">
              <div class="col-md-10 pl-1 pr-1">
                <span *ngIf="i == 0">Customer Name</span>
                <input
                  type="text"
                  name="CustomerName{{ i }}"
                  id="CustomerName{{ i }}"
                  [(ngModel)]="Bank.CustomerName"
                  readonly
                  class="form-control input-text-css"
                />
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-md-2 pl-1 pr-1 text-right">
          <span class="required-lable" *ngIf="i == 0">Pay Date</span>
          <!--<input type="number" min="0" name="Amount{{i}}" id="Amount{{i}}" class="form-control input-text-css text-right pr-1" required
          #refAmount="ngModel" [(ngModel)]="Bank.Amount" placeholder="Amount" (change)="getTotalCalculation()" (keyup.enter)="getTotalCalculation()"
          [ngClass]="{ 'is-invalid': f.submitted && refAmount.invalid, 'alert-warning': refAmount.invalid  && (refAmount.dirty || refAmount.touched || refAmount.untouched) }">-->
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker"
              dateConvert
              [min]="customerDetail.LoanDate"
              [max]="
                Bank.SubType == 'Other' || Bank.Type == 'Dealer'
                  ? CurrentDate
                  : ''
              "
              placeholder="DD/MM/YYYY"
              name="PayDate{{ i }}"
              id="PayDate{{ i }}"
              #refPayDate="ngModel"
              (dateChange)="onPayDateChange(Bank)"
              required
              [ngClass]="{
                'is-invalid': f.submitted && refPayDate.invalid,
                'alert-warning':
                  refPayDate.invalid &&
                  (refPayDate.dirty ||
                    refPayDate.touched ||
                    refPayDate.untouched)
              }"
              class="form-control input-text-css"
              [(ngModel)]="Bank.PayDate"
              [disabled]="Bank.disable || Bank.disableUnderProcess"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
        <div class="col-md-1 pl-1 pr-1 text-right">
          <span class="required-lable" *ngIf="i == 0">Amount</span>
          <input
            type="number"
            min="0"
            name="Amount{{ i }}"
            id="Amount{{ i }}"
            class="form-control input-text-css text-right pr-1"
            required
            #refAmount="ngModel"
            [(ngModel)]="Bank.Amount"
            placeholder="Amount"
            [disabled]="Bank.disable || Bank.disableUnderProcess"
            (change)="getTotalCalculation()"
            (keyup.enter)="getTotalCalculation()"
            [ngClass]="{
              'is-invalid': f.submitted && refAmount.invalid,
              'alert-warning':
                refAmount.invalid &&
                (refAmount.dirty || refAmount.touched || refAmount.untouched)
            }"
          />
        </div>

        <div
          class="col-md-1 pl-1 pr-1"
          [ngStyle]="{
            'padding-top': i == 0 ? '20px' : '0',
            'margin-left':
              Bank.disable || Bank.disableUnderProcess ? '-7px' : '0'
          }"
        >
          <button
            type="button"
            class="remove"
            (click)="removeBankDetail(i)"
            [hidden]="Bank.disable || Bank.disableUnderProcess"
            *ngIf="i < BankModel.length && BankModel.length > 1"
          >
            -
          </button>
          <button
            type="button"
            class="addmore"
            (click)="addBankDetail()"
            [hidden]="
              (Bank.disable || Bank.disableUnderProcess) && RemainingAmount < 0
            "
            *ngIf="i == BankModel.length - 1"
          >
            +
          </button>
          <button
            type="button"
            class="holdBalck"
            [hidden]="
              (Bank.disable && RemainingAmount < 0) || Bank.disableUnderProcess
            "
            *ngIf="
              !Bank.disable &&
              Bank.IsHold == false &&
              Bank.SubType != 'Other' &&
              Bank.Type != 'Dealer'
            "
            (click)="Hold(i, Bank)"
          >
            <i class="fa fa-unlock"></i>
          </button>
          <button
            type="button"
            class="holdRed"
            [hidden]="
              (Bank.disable && Bank.disableUnderProcess) ||
              Bank.disableUnderProcess
            "
            *ngIf="
              !Bank.disable &&
              Bank.IsHold == true &&
              Bank.SubType != 'Other' &&
              Bank.Type != 'Dealer'
            "
            (click)="Releasel(i, Bank)"
          >
            <i class="fa fa-lock"></i>
          </button>
          <i
            *ngIf="Bank.disable"
            class="fa fa-check-circle ml-2"
            style="color: green; font-size: 22px"
          ></i>
          <div *ngIf="Bank.disableUnderProcess">
            <input
              name="Under{{ i }}"
              id="Under{{ i }}"
              value=" Under Process"
              class="form-control input-text-css text-right pr-1"
              disabled
            />
          </div>
        </div>
      </div>
      <ng-container>
        <div
          class="row col-md-12"
          style="height: 43px; margin: 8px 0px 0px 0px"
        >
          <div class="col-md-10 pr-1 bg-success1">
            <div>
              <div style="float: left">
                <div style="float: left">
                  <div class="Text-align Float-left">
                    Current First EMI Date
                  </div>
                  <div
                    class="datepicker_feild Float-Right"
                    *ngIf="customerDetail"
                  >
                    <input
                      [matDatepicker]="picker"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      disabled
                      name="Application_FirstEMIDate"
                      id="Application_FirstEMIDate"
                      #refApplication_FirstEMIDate="ngModel"
                      required
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refApplication_FirstEMIDate.invalid,
                        'alert-warning':
                          refApplication_FirstEMIDate.invalid &&
                          (refApplication_FirstEMIDate.dirty ||
                            refApplication_FirstEMIDate.touched ||
                            refApplication_FirstEMIDate.untouched)
                      }"
                      class="form-control input-text-css"
                      [(ngModel)]="customerDetail.Application_FirstEMIDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    da
                    <mat-datepicker #picker></mat-datepicker>
                  </div>
                </div>

                <div
                  style="float: right; margin-left: 17px; display: none"
                  *ngIf="!IsFullyPayment"
                >
                  <div class="Float-left">
                    <mat-checkbox
                      name="ChangeFirstEMIDate"
                      [(ngModel)]="ChangeFirstEMIDate"
                      class="mt-1"
                    >
                      Change First EMI Date
                    </mat-checkbox>

                    <!--{{FinalFirstEMIDate | date}}-->
                    <div
                      class="datepicker_feild Float-Right"
                      *ngIf="ChangeFirstEMIDate"
                    >
                      <input
                        [matDatepicker]="picker"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        [min]="minFinalFirstEMIDate"
                        (dateChange)="onFinalFirstEMIDateChange()"
                        name="FinalFirstEMIDate"
                        id="FinalFirstEMIDate"
                        #refFinalFirstEMIDate="ngModel"
                        required
                        [ngClass]="{
                          'is-invalid':
                            f.submitted && refFinalFirstEMIDate.invalid,
                          'alert-warning':
                            refFinalFirstEMIDate.invalid &&
                            (refFinalFirstEMIDate.dirty ||
                              refFinalFirstEMIDate.touched ||
                              refFinalFirstEMIDate.untouched)
                        }"
                        class="form-control input-text-css"
                        [(ngModel)]="FinalFirstEMIDate"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Text-align" style="text-align: right; float: right">
                Total:
              </div>
            </div>
          </div>
          <div class="col-md-2 pr-2 bg-success1">
            <span
              class="form-control1 input-text-css text-right"
              style="margin-left: 10px; width: 85px"
              *ngIf="totalCalculator"
              >{{ totalCalculator.total }}</span
            >
          </div>
        </div>

        <div class="row m-0 mt-2" *ngIf="!IsFullyPayment">
          <div class="col-md-8"></div>
          <div class="col-md-3 text-right">
            <h6
              class="fs-14"
              *ngIf="RemainingAmount != 0"
              [ngStyle]="{ color: RemainingAmount < 0 ? 'red' : '' }"
            >
              Remaining Amount: {{ RemainingAmount }}
            </h6>
          </div>

          <div class="col-md-1 pr-1 text-right" *ngIf="totalCalculator">
            <button
              type="button"
              (click)="SaveDisbursment()"
              class="btn font-size-12 button-btn small-bt"
              [class.spinner]="loading"
              [disabled]="!f.form.valid && FinalFirstEMIDate"
            >
              Save
            </button>
          </div>
        </div>
      </ng-container>
    </form>
  </ng-container>
</div>

<br />

<div class="formborder mt-1">
  <div class="row m-0 col-md-12 mt-2">
    <div class="col-md-12 bg-success1">User Detail :</div>
  </div>
  <ng-container *ngIf="customerDetail">
    <div class="row m-0 mt-2">
      <div class="col-md-3">
        <h6 class="fs-12">Application Created By</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.Application_CreateBy }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">FI/PD Done By</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.FIDoneBy }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Credit Approved By</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.CreditApprovedBy }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Loan Converted By</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.LoanConvertBy }}</p>
      </div>
    </div>
    <div class="row m-0 mt-3">
      <div class="col-md-3">
        <h6 class="fs-12">DV Created By</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.DV_CreateBy }}</p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">DV Approved By</h6>
        <p class="lead m-0 fs-12">{{ customerDetail.DV_AuthorizBy }}</p>
      </div>

      <div class="col-md-3">
        <h6 class="fs-12">Payment Aproved by</h6>
        <p class="lead m-0 fs-12" *ngIf="Disbursment_Payment_Detail">
          {{ Disbursment_Payment_Detail.Authorized_By }}
        </p>
      </div>
      <div class="col-md-3">
        <h6 class="fs-12">Payment Aproved On</h6>
        <p class="lead m-0 fs-12" *ngIf="Disbursment_Payment_Detail">
          {{ Disbursment_Payment_Detail.Authorized_On }}
        </p>
      </div>
    </div>
  </ng-container>
</div>

<div
  class="modal fade in"
  id="CAMReport"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    style="max-width: 1140px"
  >
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          CAM Report
        </h6>
        <button
          type="button"
          class="close"
          (click)="HideCAMReport()"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <app-main-card-info [loanId]="LoanId"></app-main-card-info>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="PreEmiModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Pre Emi Interest Due
        </h6>
        <button
          type="button"
          (click)="onClosePreEmiModel()"
          class="close"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container>
          <div class="row m-0">
            <div class="col-md-3">
              <h6 class="fs-12">Loan Amount</h6>
              <p class="lead m-0 fs-12">₹ {{ customerDetail.LoanAmount }}</p>
            </div>

            <div class="col-md-3">
              <h6 class="fs-12">Loan Date</h6>
              <p class="lead m-0 fs-12">{{ customerDetail.LoanDate }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">First EMI Date</h6>
              <p class="lead m-0 fs-12">{{ customerDetail.FirstEMIDate }}</p>
            </div>

            <div class="col-md-3">
              <h6 class="fs-12">IRR (%)</h6>
              <p class="lead m-0 fs-12">{{ customerDetail.IRR }} %</p>
            </div>

            <!--<div class="col-md-2">
              <h6 class="fs-12">ROI (%)</h6>
              <p class="lead m-0 fs-12">{{ customerDetail.ROI }}</p>
            </div>-->
            <div class="col-md-3">
              <h6 class="fs-12 bold-text">Disbursment Amount</h6>
              <p class="lead m-0 fs-12 bold-text">{{ TotalOtherAmount }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12 bold-text">Days</h6>
              <p class="lead m-0 fs-12 bold-text">{{ InterestDays }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12 bold-text">Pre EMI Interest</h6>
              <p class="lead m-0 fs-12 bold-text">{{ InterestpayAmount }}</p>
            </div>
          </div>
        </ng-container>

        <div class="col-md-12 p-0 mt-1 text-right">
          <button
            type="button"
            (click)="onSaveWithPreEmi()"
            [class.spinner]="loading"
            class="mt-3 btn font-size-12 button-btn"
          >
            Post Pre EMI Interest
          </button>
          <button
            type="button"
            (click)="onSaveWithOutPreEmi()"
            [class.spinner]="loading"
            class="mt-3 btn font-size-12 button-btn"
          >
            Pre EMI Interest Not Required
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="Hold_UnHoldModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #fh="ngForm" (ngSubmit)="onSaveUnHoldModel()" novalidate>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            {{ Hold_Unhold }} Reason
          </h6>
          <button
            type="button"
            (click)="onCloseUnHoldModel()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row m-0">
            <div class="col-md-12 p-1" *ngIf="Hold_Unhold == 'Release'">
              <span *ngIf="Hold_Unhold == 'Release'">
                Hold Reason
                <!--{{BankDetaildata.createdOn}} by {{BankDetaildata.Authorized_By}}-->
              </span>

              <input
                required
                type="text"
                disabled
                #refBankDetaildata="ngModel"
                [(ngModel)]="BankDetaildata.HoldReason"
                placeholder="BankDetaildata"
                name="BankDetaildata"
                id="BankDetaildata"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-12 p-1">
              <span class="required-lable"> {{ Hold_Unhold }} Reason </span>

              <input
                required
                type="text"
                #refReasone="ngModel"
                [(ngModel)]="Reason"
                placeholder="Reason"
                name="Reason"
                id="Reason"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': fh.submitted && refReasone.invalid,
                  'alert-warning':
                    refReasone.invalid &&
                    (refReasone.dirty ||
                      refReasone.touched ||
                      refReasone.untouched)
                }"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button
            type="button"
            (click)="onCloseUnHoldModel()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveUnHoldModel()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!fh.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
