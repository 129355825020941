import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DataSharingService } from "../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../_Lms/services/lms.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
declare var $: any;
import { EncrDecrService } from "../AuthGuard/EncrDecrService";
import { MasterService } from "../Shared/app.Masters.Service";
import { DatePipe } from "@angular/common";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "app-print-demand-notice",
  templateUrl: "./print-demand-notice.component.html",
  styleUrls: ["./print-demand-notice.component.scss"],
})
export class PrintDemandNoticeComponent implements OnInit {
  showSpinner: boolean = false;
  applicationDetail: any;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  @Input() index: any;
  @Input("LoanId") LoanId: any;
  currentUser: any;
  templateData: any;
  content: SafeHtml;
  loading: boolean = false;
  constructor(
    public datepipe: DatePipe,
    private dataSharingService: DataSharingService,
    private _MasterService: MasterService,
    private route: ActivatedRoute,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private LmsService: LmsService,
    private sanitizer: DomSanitizer
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {});
  }

  ngOnInit(): void {
    this.templateData;
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  LMS_GetNocLatterDetailsViewModel() {
    this.LmsService.LMS_GetLatterDetailsViewModel({
      LoanId: this.LoanId,
    }).subscribe((res: any) => {
      this.applicationDetail = res[0];
      this.geNOCletter(this.applicationDetail.ProductId);
    });
  }

  geNOCletter(productid: any) {
    this._MasterService
      .Get_NOCLetter({ ProductId: productid })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.templateData = res[0].TemplateData;
          this.templateData = this.templateData.replace(
            /\[Application_No\]/g,
            this.applicationDetail.Application_No
          );
          this.templateData = this.templateData.replace(
            /\[Customer_Name\]/g,
            this.applicationDetail.Customer_Name.toUpperCase()
          );
          this.templateData = this.templateData.replace(
            /\[Relation_Name\]/g,
            this.applicationDetail.Relation_Name.toUpperCase()
          );
          this.templateData = this.templateData.replace(
            /\[Customer_Address\]/g,
            this.applicationDetail.Customer_Address.toUpperCase()
          );
          this.templateData = this.templateData.replace(
            /\[Customer_PhoneNo\]/g,
            this.applicationDetail.Customer_PhoneNo
          );
          this.templateData = this.templateData.replace(
            /\[Loan_Product\]/g,
            this.applicationDetail.Loan_Product
          );
          this.templateData = this.templateData.replace(
            /\[Loan_Amount\]/g,
            this.applicationDetail.Loan_Amount
          );
          this.templateData = this.templateData.replace(
            /\[Overdue_Installment_No\]/g,
            this.applicationDetail.OverDUE_EMI == null
              ? ""
              : this.applicationDetail.OverDUE_EMI
          );
          this.templateData = this.templateData.replace(
            /\[Margin_Amount\]/g,
            this.applicationDetail.Margin_Amount
          );
          this.templateData = this.templateData.replace(
            /\[Tenure_Month\]/g,
            this.applicationDetail.Tenure_Month
          );
          this.templateData = this.templateData.replace(
            /\[No_Of_instalment\]/g,
            this.applicationDetail.No_Of_instalment
          );
          this.templateData = this.templateData.replace(
            /\[EMI_Amount\]/g,
            this.applicationDetail.EMI_Amount
          );
          this.templateData = this.templateData.replace(
            /\[EMI_Advance\]/g,
            this.applicationDetail.EMI_Advance
          );
          this.templateData = this.templateData.replace(
            /\[ROI\]/g,
            this.applicationDetail.ROI
          );
          this.templateData = this.templateData.replace(
            /\[IRR\]/g,
            this.applicationDetail.IRR
          );
          this.templateData = this.templateData.replace(
            /\[Date\]/g,
            this.applicationDetail.Date
          );
          this.templateData = this.templateData.replace(
            /\[Date_Of_EMI\]/g,
            this.applicationDetail.Date_Of_EMI
          );
          this.templateData = this.templateData.replace(
            /\[Loan_No\]/g,
            this.applicationDetail.Loan_No
          );

          this.templateData = this.templateData.replace(
            /\[Close_Date\]/g,
            this.applicationDetail.Close_Date
          );
          this.templateData = this.templateData.replace(
            /\[Current_Date\]/g,
            this.datepipe.transform(new Date(), "MM-dd-yyyy")
          );
          this.templateData = this.templateData.replace(
            /\[Vehicle_No\]/g,
            this.applicationDetail.Vehicle_No
          );
          this.templateData = this.templateData.replace(
            /\[Chassis_No\]/g,
            this.applicationDetail.Chassis_No
          );
          this.templateData = this.templateData.replace(
            /\[Engine_No\]/g,
            this.applicationDetail.Engine_No
          );
          this.content = this.sanitizer.bypassSecurityTrustHtml(
            this.templateData
          );
          setTimeout(() => {
            $(`#NOCmodel${this.index}`).modal("show");
            $(`#NOCmodel${this.index}`).css("z-index", "1050");
          }, 1000);
        }
      });
  }

  OnClose() {
    $(`#NOCmodel${this.index}`).modal("hide");
  }

  printLetter() {
    this.LmsService.LMS_Save_NOC_Detail({
      LoanId: this.LoanId,
      LoginUserId: this.currentUser.userId,
    }).subscribe((res: any) => {
      if (res.length > 0) {
        setTimeout(() => {
          this.downloadPdf();
        }, 1000);
      }
    });
  }
  downloadPdf() {
    var prtContent = document.getElementById("pdfDemandNotice");
    console.log("prtContent.innerHTML", prtContent.innerHTML);
    var WinPrint = window.open("", "", "left=100,top=100");
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
  }
}
