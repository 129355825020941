import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
  VERSION,
} from "@angular/core";
import { LmsService } from "../../_Lms/services/lms.service";
import { LosService } from "../../_LOS/services/los.service";
import { CommonFunctions } from "src/app/CommonComponent/function/commonfunctions";
import { MasterService } from "src/app/Shared/app.Masters.Service";
declare var $: any;
@Component({
  selector: "app-quickloan-cibil-report",
  templateUrl: "./quickloan-cibil-report.component.html",
  styleUrls: ["./quickloan-cibil-report.component.scss"],
})
export class QuickloanCibilReportComponent implements OnInit {
  showSpinner: boolean = false;
  CibilData: any;
  ApplicationDetail: any;
  PurposeList: any[] = [];
  SuitFiled: any[] = [];
  CreditFacilityStatus: any[] = [];
  foundItem: any;
  CibilAcore_List: any[] = [];
  @Input() cibilResponse: any;
  OpenWindow: Window;

  //@ViewChild('TransUnionCIBIL') TransUnionCIBIL!: ElementRef;

  constructor(
    private losService: LosService,
    private lmsService: LmsService,
    private _commonfunctions: CommonFunctions,
    private _MasterService: MasterService
  ) {}

  ngOnInit(): void {
    let ele = document.getElementById("headerId");
    ele.style.display = "none";
    let ele1 = document.getElementById("footerId");
    ele1.style.display = "none";
    this.losService
      .GET_CRC_MASTER_BY_CRCAGENCY({
        Crc_Agency: "1",
        Is_Firm: 0,
      })
      .subscribe((res: any) => {
        this.PurposeList = res;
        console.log("Purpose list", this.PurposeList);
      });

    this.getCommonMasterForSuitFiled();
    this.getCommonMasterForCreditFacilityStatus();

    this.losService.Get_Master_CibilAcore_List().subscribe((res: any) => {
      this.CibilAcore_List = res;
    });
    this.getCibilReports();
  }

  getCommonMasterForSuitFiled() {
    this._MasterService
      .GetCommonMaster_FOR_DROPDOWN({ Type: "SUIT FILED STATUS" })
      .subscribe((res: any) => {
        this.SuitFiled = res;
        console.log("this.SuitFiled", this.SuitFiled);
      });
  }

  getCommonMasterForCreditFacilityStatus() {
    this._MasterService
      .GetCommonMaster_FOR_DROPDOWN({ Type: "WO AND SETTLED STATUS" })
      .subscribe((res: any) => {
        this.CreditFacilityStatus = res;
        console.log("this.CreditFacilityStatus", this.CreditFacilityStatus);
      });
  }

  getCibilReports() {
    this.showSpinner = true;
    // this.lmsService.Get_Lms_CIBILReports().subscribe((result) => {
    // console.log("Cibil Data", result);
    this.CibilData = this.cibilResponse;
    console.log("Cibil Data", this.CibilData);

    this.showSpinner = false;
    if (this.CibilData?.consumerCreditData[0]?.accounts != undefined) {
      this.CibilData?.consumerCreditData[0]?.accounts.forEach((obj) => {
        obj.payment = [];
        obj.paymentDate = [];
        obj.onedue = [];
        obj.twodue = [];
        obj.threedue = [];
        var payDate = Object.assign(this.convertToDate(obj.paymentStartDate));
        payDate.setMonth(payDate.getMonth());
        for (let i = 0; i < obj.paymentHistory.length; i += 3) {
          obj.payment.push(obj.paymentHistory.slice(i, i + 3));
        }
        for (let i = 0; i < obj.payment.length; i++) {
          const date = new Date();
          date.setMonth(payDate.getMonth() - i);
          obj.paymentDate.push(this.formatMonthYear(date));
        }
        for (let i = 0; i < 15; i++) {
          obj.onedue.push({ score: obj.payment[i], sDate: obj.paymentDate[i] });
        }
        if (obj.payment.length > 15) {
          for (let i = 15; i < 30; i++) {
            obj.twodue.push({
              score: obj.payment[i],
              sDate: obj.paymentDate[i],
            });
          }
        }
        if (obj.payment.length > 30) {
          for (let i = 30; i < obj.payment.length; i++) {
            obj.threedue.push({
              score: obj.payment[i],
              sDate: obj.paymentDate[i],
            });
          }
        }
      });
      console.log("accouts", this.CibilData?.consumerCreditData[0]?.accounts);
    }
  }
  formatMonthYear(date: Date): string {
    const month = date.getMonth();
    const year = date.getFullYear();
    return `${
      (month == 0
        ? "12"
        : month < 10
        ? "0" + month.toString()
        : month.toString()) +
      "-" +
      (month == 0
        ? (year - 1).toString().substring(4, 2)
        : year.toString().substring(4, 2))
    }`;
  }

  padZero(num: number): string {
    return num < 10 ? "0" + num : num.toString();
  }
  convertToDate(mmddyyyy: string): Date {
    const day = parseInt(mmddyyyy.substring(0, 2));
    const month = parseInt(mmddyyyy.substring(2, 4)); // Months are zero-based in JavaScript
    const year = parseInt(mmddyyyy.substring(4, 8));

    return new Date(year, month, day);
  }
  findItemById(code): void {
    if (this.PurposeList.find((item) => item.Code === code)) {
      return this.PurposeList.find((item) => item.Code === code).Value;
    } else null;
  }

  // findSuitFiledByID(code) {
  //   if (this.SuitFiled.find((item) => item.Code === code)) {
  //     let value = this.SuitFiled.find((item) => item.Code === code).Value;
  //     console.log("value of suit filed-----", value);
  //     return;
  //   } else null;
  // }

  findSuitFiledByID(code) {
    if (code == null || code === undefined) {
      return null;
    }
    const result = this.SuitFiled.find((item) => item.Code == code);
    return result ? result.Value : null;
  }

  findCreditFacilityStatusByiD(code) {
    if (code == null || code === undefined) {
      return null;
    }
    const result = this.CreditFacilityStatus.find((item) => item.Code === code);
    return result ? result.Value : null;
  }

  findCibilScore(code, scoreName): void {
    //console.log("score", this.CibilAcore_List.find(item => item.Code === code && item.ScoreName === scoreName))
    if (
      this.CibilAcore_List.find(
        (item) => item.Code === code && item.ScoreName === scoreName
      )
    ) {
      return this.CibilAcore_List.find(
        (item) => item.Code === code && item.ScoreName === scoreName
      ).Explanation;
    } else return null;
  }
  onDocsPrint(divName) {
    //var prtContent = document.getElementById(divName);
    //var WinPrint = window.open('', '_blank');
    //WinPrint.document.write(prtContent.innerHTML);
    //WinPrint.document.close();
    //WinPrint.focus();
    //WinPrint.print();
    this._commonfunctions.PrintCibil(divName, "Cibil", new Date().getTime());
    //let printContents = this.TransUnionCIBIL.nativeElement.innerHTML;
    //let originalContents = document.body.innerHTML;

    //document.body.innerHTML = printContents;

    //window.print();

    //document.body.innerHTML = originalContents;
  }

  onCloseCibilReport() {
    $("#cibilReport").modal("hide");
  }
}
