import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { Router } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import * as moment from "moment";
import { ToWords } from "to-words";
import { MasterService } from "../../Shared/app.Masters.Service";
import { LmsService } from "../../_Lms/services/lms.service";
import { ActivatedRoute, Params } from "@angular/router";
import { PartnerService } from "../services/partner.service";

@Component({
  selector: "app-partner-single-payment",
  templateUrl: "./partner-single-payment.component.html",
  styleUrls: ["./partner-single-payment.component.scss"],
})
export class PartnerSinglePaymentComponent implements OnInit {
  currentUser: any;
  data: any;
  selectedRowIndex: number = -1;
  SendBackToBranchList: any[] = [];
  showreceiptDetail: boolean = false;
  showSpinner: boolean = false;
  ApplicationHead: any[] = [];
  caseHead: any[] = [];
  applicationDetail: any;
  CurrentDate: any;
  CustomerId: any;
  Customer: any = {};
  loading: boolean = false;
  ReceiptDetail: any;
  customerDetail: any;
  ReceiptModel: any;
  addressModel: any;
  Voucher_Cheque_No: any;
  Type: any;
  Mode: any;
  systemconfig: any = {
    CompanyName: "",
    CompanyHeadOfficeAddress: "",
    CompanyPhoneNo: "",
    ComapnyEmail: "",
  };
  //POS: any;
  Installment: any = 0;
  dueEMIAmount: any = 0;
  Interest: any = 0;
  OtherDues: any = 0;
  CaseDetail: any;
  dataSource: any;
  AppCaseNo: any;
  CustomerType: any = "0";
  AppReceiptTotalAmount: any;
  caseDepositTotalAmount: any = 0;
  PayDepositeTotalAmount: any = 0;
  showCaseForm: boolean = false;
  showcash: boolean = false;
  showbank: boolean = false;
  showGateway: boolean = false;
  showApplicationForm: boolean = true;
  displayedColumns: string[] = [];
  displayedCustomerColumns: string[] = [];
  dataSourceCustomer: any;
  totalCalculator: any;
  chargesHeadDropdown: any[] = [];
  ReceiptEntryList: any[] = [];
  CustomerList: any[] = [];
  CustomerListPartnerModel: any;
  //PayPOS: any = 0;
  PayInstallment: any = 0;
  PayInterest: any = 0;
  PayOtherDues: any = 0;
  Application_No: any;
  LoanAcNo: any;
  CType: any;
  BranchId: any;
  Finalvoucheretail: any[] = [];
  AccountDetail: any[] = [];
  accountTagDropdown: any[] = [];
  //POSId: any = 27;
  InstallmentId: any = 34;
  InterestId: any = 30;
  OtherDuesId: any = 35;
  receiptNo: any;
  SelectBranchId: any;
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  dataSourcePartnerModel: any;
  displayedColumnsPartnerModel: any[] = [];

  SelectType: any = "";
  BranchesList: any = [];
  SalesExecutiveData: any = [];
  CollectionExecutiveData: any = [];
  SourceAreaData: any = [];
  areaData: any = [];
  collectionArea: any = [];
  subAreaData: any = [];
  AccCatDropdown: any[] = [];

  isDisabled: boolean = true;
  partnerInfo: any;

  showPartnerInfo: boolean = false;

  constructor(
    private dataSharingService: DataSharingService,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private losService: LosService,
    private _MasterService: MasterService,
    private lmsService: LmsService,
    private _Partnerservice: PartnerService
  ) {
    this.CurrentDate = moment(new Date()).format("DD/MM/YYYY");
    //console.log(this.CurrentDate);
    this.CaseDetail = {
      OverdueEMI: "",
      OverdueEMIAmount: "",
      OverdueInterest: "",
      Ledger: "",
    };
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Single Payment");

    this.route.queryParams.subscribe((params) => {
      this.SelectBranchId = params["BranchId"];
      this.initializeVoucher();
    });

    //this.initializeVoucherDetail("0",0);
    this.ApplicationHead = [];
    this.ReceiptDetail = [];
    this.customerDetail = [];
    this.caseHead = [];
    this.addressModel = [];
    this.addMore();
    this.Type = "Application";
    this.showPaymentMode();
    this.Mode = "";
    this.AppReceiptTotalAmount = "0";
    this.dueEMIAmount = "0";
    this.Interest = "0";
    this.OtherDues = "0";

    this.Get_Acc_Accounts_By_Tag();

    this.getBranchesList();
    this.GetCollectionExecutive();
    this.GetSalesExecutive();
    this.getAreaByUserId();
    this.getSubAreaByUserId();
    this.GetLoanAccCategoryDropdown();
  }

  getBranchesList() {
    this.lmsService
      .GetBranches({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
      });
  }

  GetCollectionExecutive() {
    this.lmsService
      .Get_Collection_Ex_By_UserId({
        LoginUserId: this.currentUser.userId,
        BranchId: "",
      })
      .subscribe((res) => {
        this.CollectionExecutiveData = JSON.parse(JSON.stringify(res));
      });
  }

  GetSalesExecutive() {
    this.lmsService
      .Get_Collection_Ex_By_UserId({
        LoginUserId: this.currentUser.userId,
        BranchId: "",
      })
      .subscribe((res) => {
        this.SalesExecutiveData = JSON.parse(JSON.stringify(res));
      });
  }

  getAreaByUserId() {
    this.lmsService
      .Get_Area_By_UserId({
        BranchId: "",
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        this.areaData = JSON.parse(JSON.stringify(res));
      });
  }

  getSubAreaByUserId() {
    this.lmsService
      .Get_SubArea_By_UserId({
        BranchId: "",
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        this.subAreaData = JSON.parse(JSON.stringify(res));
      });
  }

  GetLoanAccCategoryDropdown() {
    this.lmsService
      .Get_Loan_Accounting_Category_ForDropdown({ ProductId: 0 })
      .subscribe((res: any) => {
        this.AccCatDropdown = JSON.parse(JSON.stringify(res));
      });
  }

  SearchChange() {
    this.Customer = {
      CloseCase: "",
      BranchId: "",
      Area: "",
      SubArea: "",
      SalesEx: "",
      CollectionEx: "",
      LoanAccountingCatId: "",
    };
  }

  getDetail(Partner_LoanAcNo) {
    this._Partnerservice
      .Partner_Get_Search_Loan({ Partner_LoanAcNo: Partner_LoanAcNo })
      .subscribe((res: any) => {
        //console.log("RESDDDGT : ", res);
        if (res.length > 0) {
          this.showCaseForm = true;
          this.ReceiptDetail = JSON.parse(JSON.stringify(res[0]));
          this.LoanAcNo = res[0].PLoanAcNo;
          this.CustomerId = res[0].CustomerId;
          this.BranchId = res[0].BranchId;

          this.getChargeshead();

          this.getAccountDetail(this.InstallmentId);
          this.getAccountDetail(this.InterestId);
          this.getAccountDetail(this.OtherDuesId);
          this.showSpinner = false;
        } else {
          this.showCaseForm = false;
          this.totalCalculator = 0;
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
          this.ReceiptDetail = "";
        }
      });
  }

  showPaymentMode() {
    if (this.Mode == "1") {
      this.showcash = true;
      this.showGateway = false;
      this.showbank = false;
    } else if (this.Mode == "4" || this.Mode == "3") {
      this.showcash = false;
      this.showGateway = true;
      this.showbank = false;
    } else if (this.Mode == "2") {
      this.showcash = false;
      this.showGateway = false;
      this.showbank = true;
    } else {
      this.showcash = false;
      this.showGateway = false;
      this.showbank = false;
    }
  }

  showHead() {
    if (this.Type == "Application") {
      this.showApplicationForm = true;
      this.showCaseForm = false;
    } else {
      this.showApplicationForm = false;
      this.showCaseForm = true;
    }
  }

  getcaseTotalAmount() {
    this.caseDepositTotalAmount =
      Number(this.Installment) + Number(this.Interest) + Number(this.OtherDues);
    this.PayDepositeTotalAmount =
      Number(this.PayInstallment) +
      Number(this.PayInterest) +
      Number(this.PayOtherDues);
    const toWords = new ToWords();
    let words = toWords.convert(Number(this.caseDepositTotalAmount));
    //console.log(words);
    this.getTotalCalculation();
    //this.PayPOS = (Number(this.PayPOS)).toFixed(2);
    this.PayInterest = Number(this.PayInterest).toFixed(2);
    this.PayInstallment = Number(this.PayInstallment).toFixed(2);
    this.PayOtherDues = Number(this.PayOtherDues).toFixed(2);
  }

  getAppTotalAmount() {
    if (this.ApplicationHead.length > 0) {
      this.AppReceiptTotalAmount = "0";
      let _prev = this.ApplicationHead[this.ApplicationHead.length - 1];
      _prev.total =
        Number(_prev.amount) + Number(_prev.CGST) + Number(_prev.SGST);
      for (let i = 0; i < this.ApplicationHead.length; i++) {
        this.AppReceiptTotalAmount =
          Number(this.AppReceiptTotalAmount) +
          Number(this.ApplicationHead[i].total);
      }
      const toWords = new ToWords();
      let words = toWords.convert(Number(this.AppReceiptTotalAmount));
      //console.log(words);
    }
  }

  addMore() {
    if (this.ApplicationHead.length > 0) {
      let _prev = this.ApplicationHead[this.ApplicationHead.length - 1];
      if (
        _prev.head == "" ||
        _prev.amount == "" ||
        _prev.CGST == "" ||
        _prev.SGST == ""
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please First Fill Required Feild to Add More.",
          ...this.configSuccess,
        });
        return;
      }
    }
    this.ApplicationHead.push({ head: "", amount: "", CGST: "", SGST: "" });
  }

  remove(index: any) {
    this.ApplicationHead.splice(index, 1);
    this.getAppTotalAmount();
  }

  searchCustomer() {
    this.showSpinner = true;
    this.Finalvoucheretail = [];
    this.onCloseReceipt();
    this.voucherModel.Voucher_Detail = [];
    //this.PayPOS = 0.00;
    this.PayInstallment = 0.0;
    this.PayInterest = 0.0;
    this.PayOtherDues = 0.0;

    this._Partnerservice
      .Partner_Get_Receipt_Entry({
        Partner_LoanAcNo: this.AppCaseNo,
        ToDate: new Date(),
        HeadId: 0,
      })
      .subscribe((res: any) => {
        //console.log("TRRRTTTT : ", res);
        if (res.length > 0) {
          this.getDetail(this.AppCaseNo);
          this.initializeVoucherDetail();

          this.ReceiptEntryList = JSON.parse(JSON.stringify(res));

          if (
            this.ReceiptEntryList.filter(
              (item) => item.HeadId == this.InstallmentId
            ).length > 0
          ) {
            this.Installment = this.ReceiptEntryList.filter(
              (item) => item.HeadId == this.InstallmentId
            )[0].Balance;
          } else {
            this.Installment = 0.0;
          }

          if (
            this.ReceiptEntryList.filter(
              (item) => item.HeadId == this.InterestId
            ).length > 0
          ) {
            this.Interest = this.ReceiptEntryList.filter(
              (item) => item.HeadId == this.InterestId
            )[0].Balance;
          } else {
            this.Interest = 0.0;
          }

          if (
            this.ReceiptEntryList.filter(
              (item) => item.HeadId == this.OtherDuesId
            ).length > 0
          ) {
            this.OtherDues = this.ReceiptEntryList.filter(
              (item) => item.HeadId == this.OtherDuesId
            )[0].Balance;
          } else {
            this.OtherDues = 0.0;
          }

          this.caseHead = [];
          this.caseHead.push({
            //  POS: this.POS,
            Installment: this.Installment,
            Interest: this.Interest,
            OtherDues: this.OtherDues,
          });

          this.getcaseTotalAmount();
          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }
  onCloseReceipt() {
    $("#CustomerReceipt").modal("hide");
  }
  onOkReceipt() {
    $("#CustomerReceipt").modal("hide");
    this.showreceiptDetail = true;
    // this.ReceiptDetail = { fullName: "Ajay Gautam", address: "Jagatpura", Phone: "84747747444", email: "abc@gmail.com" };
    //if () {
    // this.CaseDetail = { POS: "122", OverdueEMI: "3", OverdueEMIAmount: "200000", OverdueInterest: "2.5", Ledger: "857389753" }
    //}
  }
  onSearchCustomerDetail() {
    this.showSpinner = true;

    this._MasterService
      .Get_SearchCustomerByLMSLOS({ JSON: JSON.stringify(this.Customer) })
      .subscribe((res: any) => {
        //console.log(res);
        if (res.length > 0) {
          this.displayedCustomerColumns = [
            "CustomerId",
            "Type",
            "Application_No",
            "LoanAcNo",
            "CustomertName",
            "FatherName",
            "Customer_Gender",
            "GenderAge",
            "PhoneNo",
            "EditAction",
          ];

          this.CustomerList = JSON.parse(JSON.stringify(res));
          if (this.CustomerType != "0") {
            var type;
            if (this.CustomerType == "1") type = "LOS";
            else type = "LMS";
            this.CustomerList = this.CustomerList.filter(
              (item) => item.Type == type
            );
          }
          this.dataSourceCustomer = new MatTableDataSource(this.CustomerList);
          this.dataSourceCustomer.sort = this.sort;
          this.dataSourceCustomer.paginator = this.paginator;
          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }
  goToDetail(row) {
    this.showSpinner = true;
    this.Finalvoucheretail = [];
    this.onCloseReceipt();
    this.voucherModel.Voucher_Detail = [];
    //this.PayPOS = 0.00;
    this.PayInstallment = 0.0;
    this.PayInterest = 0.0;
    this.PayOtherDues = 0.0;
    this.AppCaseNo = row.Application_No;
    this.CType = row.Type;

    if (row.Type == "LOS") this.Type = "Application";

    if (row.Type == "LMS") this.Type = "case";

    this.getDetail(row);

    this.showHead();
    this.Application_No = row.Application_No;
    this.LoanAcNo = row.LoanAcNo;
    this.CustomerId = row.CustomerId;
    this.BranchId = row.BranchId;
    this.initializeVoucherDetail();

    this._Partnerservice
      .Partner_Get_Receipt_Entry({
        Partner_LoanAcNo: row.Application_No,
        Loan_No: row.LoanAcNo,
        VoucherDate: new Date(),
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.ReceiptEntryList = JSON.parse(JSON.stringify(res));
          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
      });
  }
  onChangeAmount(event: any, data: any) {
    if (event) {
      event.preventDefault();
    }
    data.TaxSlab.forEach((obj: any) => {
      obj.TaxAmount = (parseInt(data.PayAmount) * obj.TaxRate) / 100;
    });
    data.PayAmount = Number(data.PayAmount).toFixed(2);
    this.getTotalCalculation();
  }
  removeData(index: any) {
    this.voucherModel.Voucher_Detail.splice(index, 1);
    this.getTotalCalculation();
  }
  addMoreData() {
    this.initializeVoucherDetail();
  }
  getTotalCalculation() {
    let _total: any = Number(this.caseDepositTotalAmount);
    let _totalCR: any = 0;
    let _totalTX: any = 0;
    let _payTotal: any = Number(this.PayDepositeTotalAmount);
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      _total = parseFloat(_total + Number(obj.Amount)).toFixed(2) || 0;
      _payTotal = _payTotal + Number(obj.PayAmount) || 0;
      _totalCR = _totalCR + Number(obj.Amount) || 0;
      obj.TaxSlab.forEach((tobj: any) => {
        _payTotal = Number(_payTotal) + Number(tobj.TaxAmount) || 0;
        _totalTX = Number(_totalTX) + Number(tobj.TaxAmount) || 0;
      });
    });
    this.totalCalculator = {
      total: Number(_total),
      totalCR: Number(_totalCR),
      payTotal: Number(_payTotal),
      totaltax: Number(_totalTX),
      totalInWord: new ToWords().convert(Number(_total)),
      payTotalInWord: new ToWords().convert(Number(_payTotal)),
    };
  }
  initializeVoucherDetail() {
    this.voucherModel.Voucher_Detail.push({
      VoucherId: 0,
      CaseNo: this.LoanAcNo,
      CustomerId: this.CustomerId,
      Is_LOSAppliaction: 0,
      ChargeHeadId: "",
      PayAmount: "",
      AccountId: "",
      BranchId: this.ReceiptDetail.BranchId,
      TranType: "DR",
      IsTax: 0,
      Tax_Per: 0,
      Amount: "",
      TaxSlab: [],
    });
  }
  onVoucherTagChange() {
    this.voucherModel.Voucher.Voucher_Mode_of_Payment = "";
    this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
    this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    this.voucherModel.Voucher.Voucher_Bank_Name = "";
    this.voucherModel.Voucher.Voucher_Cheque_Date = "";
    this.voucherModel.Voucher.Voucher_Cheque_No = "";
    this.Get_Acc_Accounts_By_Tag();
  }
  onChangeMode() {
    if (this.voucherModel.Voucher.Voucher_Mode_of_Payment == "Cheque") {
      this.voucherModel.Voucher.Voucher_Cheque_No = "";
      this.voucherModel.Voucher.Voucher_Cheque_Date = "";
      this.voucherModel.Voucher.Voucher_Bank_Name = "";
      this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    } else {
      this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
      this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    }
  }
  getChargeshead() {
    this._MasterService
      .Get_ChargesHeadsByAccountingId({
        LoanAccounting_Id: this.ReceiptDetail.LoanAccountingId,
      })
      .subscribe((res: any) => {
        this.chargesHeadDropdown = res;
      });
  }
  onChangeChargesHead(data: any, dindex: any) {
    this._Partnerservice
      .Partner_Get_Receipt_Entry({
        Partner_LoanAcNo: this.AppCaseNo,
        ToDate: new Date(),
        HeadId: data.ChargeHeadId,
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          data.Amount = res[0].Balance;
          this.getTotalCalculation();
        } else {
          data.Amount = 0.0;
          this.getTotalCalculation();
        }

        let _find = this.voucherModel.Voucher_Detail.find(
          (obj: any, index: any) => {
            return index != dindex && obj.ChargeHeadId == data.ChargeHeadId;
          }
        );
        if (
          _find ||
          data.ChargeHeadId == this.InstallmentId ||
          data.ChargeHeadId == this.InterestId ||
          data.ChargeHeadId == this.OtherDuesId
        ) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "This Account head already selected.",
            ...this.configSuccess,
          });
          data.ChargeHeadId = "";
          data.Amount = "";
          data.PayAmount = "";
          data.AccountId = "";
          data.ChargeHeadCategory = "";
          data.TaxSlab = [];
          this.getTotalCalculation();
          if (this.voucherModel.Voucher.Voucher_Sub_Type == "Accounting") {
            data.BranchId = "";
          }
          //this.getTotalCalculation();
          return;
        }
        data.PayAmount = "";
        data.TaxSlab = [];
        this.lmsService
          .Get_AccountHead_For_VoucherEntry({
            HeadId: data.ChargeHeadId,
            VoucherSubType: "Partner",
            ProductId: 0,
            TaxType: "CR",
          })
          .subscribe((res: any) => {
            if (res.Item1[0]) {
              data.AccountId = res.Item1[0].AccountId;
              data.ChargeHeadCategory = res.Item1[0].AccountName;
              data.TaxSlab = res.Item2;
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "No data Found.",
                ...this.configSuccess,
              });
            }
          });
      });
  }
  initializeVoucher() {
    this._MasterService
      .GetReceiptNo({
        BranchId: this.SelectBranchId,
      })
      .subscribe((res: any) => {
        this.receiptNo = res[0].ReceiptNo;
        this.voucherModel.Voucher = {
          VoucherId: 0,
          Voucher_Type: "Receipt",
          Voucher_Sub_Type: "Partner",
          Voucher_Tag: "Bank",
          Voucher_Date: new Date(),
          DRAccountId: "",
          Voucher_Mode_of_Payment: "",
          Voucher_Total_Credit_Amount: "",
          Voucher_Total_Debit_Amount: "",
          Voucher_Total_Tax: "",
          Voucher_Narration: "",
          Voucher_Cheque_No: "",
          Voucher_Cheque_Date: "",
          Voucher_Bank_Name: "",
          Voucher_Cheque_Clear_Date: "",
          Voucher_E_Transaction_Date: "",
          Voucher_E_Ref_No: "",
          Voucher_ReceiptNo_Book: this.receiptNo,
          Voucher_ReceiptDate: new Date(),
          LoginUserId: this.currentUser.userId,
        };
        setTimeout(() => {
          this.GetVoucherNo();
          this.Get_Acc_Accounts_By_Tag();
        }, 500);
      });
  }
  GetVoucherNo() {
    this.lmsService
      .GetVoucherNo({
        VoucherType: this.voucherModel.Voucher.Voucher_Type,
        VoucherSubType: "Loan",
        VoucherDate: this.voucherModel.Voucher.Voucher_Date,
      })
      .subscribe((res: any) => {
        this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
      });
  }
  Get_Acc_Accounts_By_Tag() {
    this.voucherModel.Voucher.DRAccountId = "";
    this.lmsService
      .Get_Acc_Accounts_By_Tag({
        LoginUserId: this.data.userId,
        Account_Tag: this.voucherModel.Voucher.Voucher_Tag,
        Voucher_Date: this.voucherModel.Voucher.Voucher_Date,
      })
      .subscribe((res: any) => {
        this.accountTagDropdown = res;
      });
  }
  getAccountDetail(ChargeHeadId) {
    var data = { AccountId: 0, ChargeHeadCategory: "" };
    this.lmsService
      .Get_AccountHead_For_VoucherEntry({
        HeadId: ChargeHeadId,
        VoucherSubType: "Partner",
        ProductId: 0,
        TaxType: "CR",
      })
      .subscribe((res: any) => {
        if (res.Item1[0]) {
          data.AccountId = res.Item1[0].AccountId;
          data.ChargeHeadCategory = res.Item1[0].AccountName;
          //data.ChargeHeadId = ChargeHeadId;
          this.AccountDetail.push({
            AccountId: data.AccountId,
            ChargeHeadCategory: data.ChargeHeadCategory,
            ChargeHeadId: ChargeHeadId,
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data Found.",
            ...this.configSuccess,
          });
        }
        //console.log(this.AccountDetail);
      });
  }
  getVoucherDetail(ChargeHeadId, Amount) {
    let _find = this.AccountDetail.find((obj: any) => {
      return obj.ChargeHeadId == ChargeHeadId;
    });
    var Is_LOSAppliaction = 0;
    if (this.CType == "LMS") Is_LOSAppliaction = 0;
    if (this.CType == "LOS") Is_LOSAppliaction = 1;
    var caseno;
    if (this.LoanAcNo != "") caseno = this.LoanAcNo;
    else caseno = this.Application_No;
    this.Finalvoucheretail.push(
      this.dataSharingService.cleanObject({
        VoucherId: 0,
        CaseNo: caseno,
        CustomerId: this.CustomerId,
        Is_LOSAppliaction: Is_LOSAppliaction,
        ChargeHeadId: ChargeHeadId,
        ChargeHeadCategory: _find.ChargeHeadCategory,
        AccountId: _find.AccountId,
        BranchId: this.ReceiptDetail.BranchId,
        TranType: "DR",
        IsTax: 0,
        Tax_Per: "",
        Amount: Amount,
      })
    );
  }
  saveVoucher() {
    if (this.totalCalculator.payTotal > 0) {
      let _vouvher: any = { ...this.voucherModel.Voucher };
      //let _voucheretail: any[] = [];
      let _find = this.accountTagDropdown.find((obj: any) => {
        return obj.AccountId == this.voucherModel.Voucher.DRAccountId;
      });
      let _total: any = 0;
      let _totalCR: any = 0;
      let _totalTX: any = 0;
      //if (Number(this.PayPOS) > 0)
      //  this.getVoucherDetail(this.POSId, this.PayPOS);
      if (Number(this.PayInstallment) > 0)
        this.getVoucherDetail(this.InstallmentId, this.PayInstallment);
      if (Number(this.PayInterest) > 0)
        this.getVoucherDetail(this.InterestId, this.PayInterest);
      if (Number(this.PayOtherDues) > 0)
        this.getVoucherDetail(this.OtherDuesId, this.PayOtherDues);

      this.voucherModel.Voucher_Detail.forEach((obj: any) => {
        //_total = _total + obj.PayAmount;
        //_totalCR = _totalCR + obj.PayAmount;
        if (obj.PayAmount > 0 && obj.PayAmount != null) {
          this.Finalvoucheretail.push(
            this.dataSharingService.cleanObject({
              VoucherId: obj.VoucherId,
              CaseNo: obj.CaseNo,
              CustomerId: obj.CustomerId,
              Is_LOSAppliaction: obj.Is_LOSAppliaction,
              ChargeHeadId: obj.ChargeHeadId,
              ChargeHeadCategory: obj.ChargeHeadCategory,
              AccountId: obj.AccountId,
              BranchId: obj.BranchId,
              TranType: "DR",
              IsTax: obj.IsTax,
              Tax_Per: obj.Tax_Per,
              Amount: obj.PayAmount,
            })
          );
          obj.TaxSlab.forEach((tobj: any) => {
            //_total = _total + tobj.TaxAmount;
            //_totalTX = _totalTX + tobj.TaxAmount;
            this.Finalvoucheretail.push(
              this.dataSharingService.cleanObject({
                VoucherId: obj.VoucherId,
                CaseNo: obj.CaseNo,
                CustomerId: obj.CustomerId,
                Is_LOSAppliaction: obj.Is_LOSAppliaction,
                ChargeHeadId: obj.ChargeHeadId,
                ChargeHeadCategory: tobj.TaxType,
                AccountId: tobj.TaxAccountId,
                BranchId: this.ReceiptDetail.BranchId,
                TranType: "DR",
                IsTax: 1,
                Tax_Per: tobj.TaxRate,
                Amount: tobj.TaxAmount,
              })
            );
          });
        }
      });

      this.Finalvoucheretail.push(
        this.dataSharingService.cleanObject({
          VoucherId: 0,
          CaseNo: "",
          CustomerId: "",
          Is_LOSAppliaction: "",
          ChargeHeadId: "",
          ChargeHeadCategory: _find.Account_Name,
          AccountId: _find.AccountId,
          BranchId: "",
          TranType: "CR",
          IsTax: 0,
          Tax_Per: 0,
          Amount: this.totalCalculator.payTotal,
        })
      );

      _vouvher.Voucher_Total_Credit_Amount = this.totalCalculator.payTotal;
      _vouvher.Voucher_Total_Debit_Amount = this.totalCalculator.payTotal;
      _vouvher.Voucher_Total_Tax = this.totalCalculator.totaltax;

      let _finalData = {
        Voucher: this.dataSharingService.cleanObject({ ..._vouvher }),
        Voucher_Detail: this.Finalvoucheretail,
      };
      var totalCalculator = this.totalCalculator;
      var addressModel = this.addressModel;
      var customerDetail = this.customerDetail;
      var LoanAcNo = this.LoanAcNo;
      var receiptNo = this.receiptNo;
      var Voucher_Cheque_No = this.voucherModel.Voucher.Voucher_Cheque_No;
      //console.log("FinalJson", _finalData);
      this._Partnerservice
        .PartnerSave_Acc_VoucherMaster({ JSON: JSON.stringify(_finalData) })
        .subscribe((res: any) => {
          if (res[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            //this.initializeVoucher();
            //this.voucherModel.Voucher_Detail = [];
            //this.initializeVoucherDetail();
            //this.totalCalculator = undefined;
            //this.Finalvoucheretail = [];
            this.searchCustomer();
            this._MasterService
              .GetConfigurationDetails()
              .subscribe((result) => {
                this.systemconfig = JSON.parse(JSON.stringify(result))[0];
                this.totalCalculator = totalCalculator;
                this.addressModel = addressModel;
                this.customerDetail = customerDetail;
                this.LoanAcNo = LoanAcNo;
                this.Voucher_Cheque_No = Voucher_Cheque_No;
                this.receiptNo = receiptNo;
                //$("#ReceiptPrint").modal("show");
                //$("#ReceiptPrint").css("z-index", "1050");
                this.showSpinner = false;
              });
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    } else {
      this.voucherModel.Voucher_Detail = [];
      this.Finalvoucheretail = [];
    }
  }
  onCloseReceiptPrint() {
    $("#ReceiptPrint").modal("hide");
    this.totalCalculator = undefined;
    this.initializeVoucher();
    this.voucherModel.Voucher_Detail = [];
    this.initializeVoucherDetail();
    this.systemconfig = {};
    this.totalCalculator = {};
    this.addressModel = [];
    this.customerDetail = [];
    this.LoanAcNo = "";
    this.receiptNo = "";
    this.Finalvoucheretail = [];
  }
  printLetter() {
    // this.loading = true;
    setTimeout(() => {
      this.downloadPdf();
    }, 1000);
  }
  downloadPdf() {
    // var data = document.getElementById('pdfSenctionDownload');
    var prtContent = document.getElementById("pdfReceiptDownload");
    var WinPrint = window.open("", "", "letf=100,top=100,width=600,height=600");
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
  }

  onClickOpenSeacrchPartnerModel() {
    this.dataSourcePartnerModel = null;
    $("#searchPartnerModel").modal("show");
    $("#searchPartnerModel").css("z-index", "1050");
  }

  OnCloseSearch() {
    $("#searchPartnerModel").modal("hide");
    this.dataSourcePartnerModel = null;
  }

  Get_Acc_Search_LoanNo() {
    this.displayedColumnsPartnerModel = [
      "CustomerId",
      "Type",
      "Partner_LoanAcNo",
      "Customer_LoanAcNo",
      "CustomertName",
      "FatherName",
      "Customer_Gender",
      "GenderAge",
      "PhoneNo",
      "EditAction",
    ];

    this._MasterService
      .Get_SearchCustomerByPartner({ JSON: JSON.stringify(this.Customer) })
      .subscribe((res: any) => {
        console.log("res partner", res);
        this.CustomerListPartnerModel = JSON.parse(JSON.stringify(res[0]));
        console.log("partner details", this.CustomerListPartnerModel);

        this.dataSourcePartnerModel = new MatTableDataSource(
          JSON.parse(JSON.stringify(res))
        );
        this.dataSourcePartnerModel.sort = this.sort;
        this.dataSourcePartnerModel.paginator = this.paginator;
        this.showSpinner = false;
      });
  }

  selectSearchData(row) {
    this.showPartnerInfo = true;
    console.log("ROWWWWWWW", row);

    this.AppCaseNo = row.PLoanAcNo;

    this.partnerInfo = row;
    console.log("partnerInfo", this.partnerInfo);

    $("#searchPartnerModel").modal("hide");
  }
}
