<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 align-items-end formborder">
    <div class="col-md-2 p-1">
      <span> Select Designation </span>
      <select
        name="FilterRolesId"
        id="FilterRolesId"
        [(ngModel)]="FilterRolesId"
        class="form-control input-text-css"
        (change)="getEmployeeList()"
      >
        <option value="">All Designation</option>
        <option
          *ngFor="let rolesDropdownData of RolesDropdownData"
          [value]="rolesDropdownData.RoleId"
        >
          {{ rolesDropdownData.Role_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-2 p-1">
      <span> Select Branch </span>
      <select
        name="FilterBranchId"
        id="FilterBranchId"
        [(ngModel)]="FilterBranchId"
        class="form-control input-text-css"
        (change)="getEmployeeList()"
      >
        <option value="">All Branch</option>
        <option
          *ngFor="let filterBranchList of FilterBranchList"
          [value]="filterBranchList.BranchId"
        >
          {{ filterBranchList.Branch_Name }}
        </option>
      </select>
    </div>
    <div class="col-md-2 p-1">
      <div class="toggle-button-cover">
        <div class="button-cover">
          <div class="button r" id="button-1">
            <input
              type="checkbox"
              class="checkbox"
              name="FilterStatusId"
              id="FilterStatusId"
              [(ngModel)]="status"
              (change)="changeFilter()"
            />
            <div class="knobs"></div>
            <div class="layer"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2 p-1 text-right">
      <button
        (click)="onRegisterEmployee('0')"
        class="mt-3 btn font-size-12 button-btn"
        style="width: max-content"
      >
        Register New Employee
      </button>
    </div>

    <div class="col-md-3 ml-5">
      <form action="" class="search-text mb-2">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="table-responsive mt-3">
    <mat-table
      [dataSource]="dataSource"
      matSort
      matTableExporter
      #exporter="matTableExporter"
      style="max-width: 100%; overflow: auto; height: 60vh"
    >
      <ng-container matColumnDef="EmpId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 50px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Emp_Name">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 350px"
          *matHeaderCellDef
        >
          Name
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 350px"
        >
          {{ row.Emp_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Emp_Role">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Designation
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Emp_Role }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Level">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
        >
          Level
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 50px"
        >
          {{ row.Level }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Emp_LoginId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          Emp LoginId
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px"
        >
          {{ row.Emp_LoginId }}
        </mat-cell>
      </ng-container>



      <ng-container matColumnDef="Base_BranchName">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
        Base Branch
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px"
        >
          {{ row.Base_BranchName }}
        </mat-cell>
      </ng-container>




      <ng-container matColumnDef="Emp_GenderAge">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          Gender/Age
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px"
        >
          {{ row.Emp_GenderAge }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Emp_PhoneNo">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          Mobile No
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px"
        >
          {{ row.Emp_PhoneNo }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Emp_RManger">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 250px"
          *matHeaderCellDef
        >
          Manger
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 250px"
        >
          {{ row.Emp_RManger }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Emp_RegOn">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 100px"
          *matHeaderCellDef
        >
          Reg. On
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 100px"
        >
          {{ row.Emp_RegOn }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ActiveStatus">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Status
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          <div *ngIf="row.Emp_IsActive == true" style="color: #02e47c">
            {{ row.ActiveStatus }}
          </div>
          <div *ngIf="row.Emp_IsActive == false" style="color: orangered">
            {{ row.ActiveStatus }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="EditAction">
        <mat-header-cell
          mat-sort-header
          class="grid-header justify-content-center"
          style="max-width: 115px"
          *matHeaderCellDef
        >
          Action
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 115px"
        >
          <div class="pr-1" style="width: 100%; text-align: right">
            <i
              *ngIf="
                row.Emp_IsActive == true &&
                (data['userId'] == 1 || row.Emp_Role.toLowerCase() != 'admin')
              "
              title="Edit Employee"
              class="fas fa-lg fa-edit margin-right-4xs cursor-style"
              aria-hidden="true"
              (click)="onRegisterEmployee(row.EmpId)"
              style="cursor: pointer"
              aria-label="true"
            ></i>

            <button
              type="button"
              *ngIf="
                row.Emp_Role.toLowerCase() != 'admin' &&
                row.Emp_IsActive == false
              "
              (click)="onDeleteRegisterEmployee(row)"
              class="btn btn-info btn-sm"
            >
              Active
            </button>
            <button
              type="button"
              *ngIf="
                row.Emp_Role.toLowerCase() != 'admin' &&
                row.Emp_IsActive == true
              "
              (click)="onDeleteRegisterEmployee(row)"
              class="btn btn-danger btn-sm"
            >
              De-Active
            </button>
          </div>
        </mat-cell>
      </ng-container>
      <!-- Header and Row Declarations -->
      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div class="row m-0 ml-3 mr-3 pt-2 pb-1" [hidden]="!dataSource">
      <div class="col-md-4 d-flex align-items-center justify-content-between">
        <button
          class="mt-2 btn font-size-12 button-btn"
          (click)="exportTable(exporter)"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-8 text-right">
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="EmployeeDetails"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          {{ MSG }}
        </h6>
        <button
          type="button"
          (click)="onCloseEmployeeDetails()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mt-2" [hidden]="!dataSourceError">
          <div class="">
            <div class="table-responsive mt-1">
              <mat-table
                [dataSource]="dataSourceError"
                matSort
                matTableExporter
                #exporter1="matTableExporter"
                id="exportReport"
                style="height: 60vh; max-width: 100%; overflow: auto"
              >
                <ng-container matColumnDef="Type">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Type }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="subType">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >subType</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.subType }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="BranchName">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Branch Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Name }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="EmpName">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Employee Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Name }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Application_No">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Application No</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Name }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="LoanAcNo">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Loan No</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.LoanAcNo }}
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumnsError; sticky: true"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumnsError"
                ></mat-row>
              </mat-table>
              <div
                class="col-md-12 d-flex justify-content-between align-items-center"
              >
                <div class="col-md-4">
                  <button
                    class="btn font-size-12 button-btn"
                    (click)="exportTable1(exporter1)"
                  >
                    <i
                      class="fa fa-file-export mr-1"
                      style="font-size: medium; cursor: pointer"
                    ></i>
                    Export
                  </button>
                </div>
                <div class="col-md-8">
                  <mat-paginator
                    #paginatorRef
                    [pageSizeOptions]="[20, 50]"
                    showFirstLastButtons
                  >
                  </mat-paginator>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
