import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { constantUrl } from "../../Shared/constantUrl";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { LmsService } from "../../_Lms/services/lms.service";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import * as moment from "moment";
import { DialogService } from "../../Shared/dialog.service";
import { QuickLoanService } from "../services/quickLoan.service";
import { ToWords } from "to-words";
declare var $: any;

@Component({
  selector: "app-sanction-conditions",
  templateUrl: "./sanction-conditions.component.html",
  styleUrls: ["./sanction-conditions.component.scss"],
})
export class SanctionConditionsComponent implements OnInit {
  showSpinner: boolean = false;
  isDisabled = true;
  Value: any = "Add";
  BtnValue: any = "Save";
  loading: boolean = false;
  SanctionConditionsDetails: any = [];
  SanctionConditionsData: any;
  stepEMI: any[] = [];
  stepEMIProposed: any[] = [];
  stepEMIApproved: any[] = [];
  stepEmiDataApproved: any;
  minLoanMaturityDate: any;
  Name: any = "Months";
  emiIrrdataApproved: any;
  emiIrr: any;
  stepEmiData: any;
  stepEmiDataProposed: any;
  selectedScheme: any = {};
  LoanDetail: any = {};
  LoanDetailApproved: any = {};
  isCalEMITableView: boolean = true;
  stepEmiForm: boolean = true;
  isFrequency: boolean = false;

  VoucherId: any;
  LoanId: any;
  dvDetail: any;
  stepArr: any;
  authRevertStatus: any = "Authorized";
  voucherRemark: any = "";
  receiptList: any[] = [];
  deductionList: any[] = [];
  dataSource: any;
  Tenure_Name: any;
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  totalCalculator: {
    totalCR: number;
    totalDR: number;
    totalCRTX: number;
    totalDRTX: number;
    totalCRInWord: string;
    totalDRInWord: string;
  };
  displayedCustomerDetailColumns: string[] = [];
  dataSourceCustomerDetail: any;
  selectedEsignSanctionFile: any;

  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  @Input() Revert_IsEdit: any;
  Headers: any = [];
  Id: any = 0;
  dialogreturn: any;
  @Input() loanSummary: any;
  @Input() Processid: any;
  @Input() PageData: any;
  @Input() isNew: boolean;
  @Input() Status: any;
  @Input() ProcessName: any;
  currentUser: any;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  emiIrrProposed: any;
  cEIModel: any = {
    Asset_Cost: 0,
    IRR_Type: "Flat",
    EMI_Type: "Monthly",
    ProductId: "",
    IRR_CalculateBy: "ROI",
  };
  Header: any;
  PageId: any = null;
  Vehicle_Type: any;
  selectedEsignFile: any;
  text: any;
  DocModel: any = {};
  DocModelSanction: any = {};
  dataUrl: string;
  constructor(
    private _Route: Router,
    private activedRoute: ActivatedRoute,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService,
    private LmsService: LmsService,
    private DecriptService: EncrDecrService,
    private dialog: DialogService,
    private _QuickLoanService: QuickLoanService,
    private _EncrDecrService: EncrDecrService,
    private route: ActivatedRoute,

    private lmsService: LmsService
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.LoanId = decodeURIComponent(param.get("id"));
      this.VoucherId = this._EncrDecrService.decrypt(
        decodeURIComponent(param.get("vid"))
      );
      this.PageId = decodeURIComponent(param.get("page"));

      // this.LMS_Get_V_Details();
      // console.log("Loan Id DV", this.LoanId);
    });
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    // console.log("this.Product_Category", this.loanSummary.Product_Category);
    this.LmsService.LMS_GetLoanDetails({
      Loan_Id: this.loanSummary.LoanId,
    }).subscribe((res: any) => {
      // console.log("LMS_GetLoanDetails", res);
      this.Vehicle_Type = res.Item6.length > 0 ? res.Item6[0].Vehicle_Type : "";
      // console.log("this.Vehicle_Type", this.Vehicle_Type);
    });
    this.GetSanctionConditions();
    // if (this.Processid == 8 || this.Processid == 2)
    this.getLoanAccount();
    // console.log("RevertedProcessId", this.loanSummary.RevertedProcessId);
  }
  addSanctionConditions() {
    this.Id = 0;
    this.Header = "";
    this.SanctionConditionsDetails = [];
    this.initializeDetail();
    $("#SanctionConditions").modal("show");
    $("#SanctionConditions").css("z-index", "1050");
  }
  onCloseSanctionConditions() {
    $("#SanctionConditions").modal("hide");
  }
  initializeDetail() {
    this.SanctionConditionsDetails.push({
      Detail: "",
    });
  }
  addMoreData() {
    this.initializeDetail();
  }
  removeData(index: any) {
    this.SanctionConditionsDetails.splice(index, 1);
  }
  OnSaveSanctionConditions() {
    this.onCloseSanctionConditions();
    this.showSpinner = true;
    var Details = { Data: this.SanctionConditionsDetails };
    // console.log("SanctionConditionsDetails", JSON.stringify(Details));
    this._QuickLoanService
      .QuickLoan_Save_Sanction_Conditions({
        JSON: JSON.stringify(Details),
        Header: this.Header,
        Loan_Id: this.loanSummary.LoanId,
        LoginUserId: this.currentUser.userId,
        Id: this.Id,
      })
      .subscribe((response: any) => {
        //console.log(response);
        if (response[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.GetSanctionConditions();
          this.QuickLoan_Save_PageProcess("Sanction Conditions");
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
        this.showSpinner = false;
      });
  }
  GetSanctionConditions() {
    this.Headers = [];
    this._QuickLoanService
      .QuickLoan_Get_Sanction_Conditions({ Loan_Id: this.loanSummary.LoanId })
      .subscribe((response: any) => {
        // console.log(response);
        if (response.length > 0) {
          this.SanctionConditionsData = response;
          this.SanctionConditionsData.forEach((obj: any) => {
            if (
              this.Headers &&
              this.Headers.find((result) => result.Id === obj.Id)
            ) {
              this.Headers.find(
                (result) => result.Id === obj.Id
              ).Condition.push({ Detail: obj.Detail });
            } else {
              this.Headers.push({
                Id: obj.Id,
                Header: obj.Particulars,
                IsCompleted: obj.Is_Completed,
                Condition: [{ Detail: obj.Detail }],
              });
            }
          });
        }
      });
    // console.log("Header", this.Headers);
  }
  QuickLoan_Save_PageProcess(PageType) {
    this._QuickLoanService
      .QuickLoan_Save_PageProcess({
        PageType: PageType,
        ProductId: this.loanSummary.ProductId,
        Loan_Id: this.loanSummary.LoanId,
        Process_Id: this.Processid,
        Page_Id: this.PageData.MM_Id,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res) => {
        //return res[0].CODE;
      });
  }
  OnEditSanctionConditions(vdData) {
    this.Header = "";
    this.SanctionConditionsDetails = [];
    this.BtnValue = "Edit";
    this.Id = vdData.Id;
    this.Header = vdData.Header;
    vdData.Condition.forEach((obj: any) => {
      this.SanctionConditionsDetails.push({
        Detail: obj.Detail,
      });
    });
    $("#SanctionConditions").modal("show");
    $("#SanctionConditions").css("z-index", "1050");
  }
  OnDeleteSanctionConditions(Id) {
    this.dialog
      .openConfirmDialog(`Are you sure you want to Delete Sanction Conditions.`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this._QuickLoanService
            .QuickLoan_Delete_Sanction_Conditions({
              Loan_Id: this.loanSummary.LoanId,
              Id: Id,
            })
            .subscribe((response: any) => {
              // console.log(response);
              if (response[0].CODE >= 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: response[0].MSG,
                  ...this.configSuccess,
                });
                this.GetSanctionConditions();
                this.QuickLoan_Save_PageProcess("Sanction Conditions");
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: response[0].MSG,
                  ...this.configSuccess,
                });
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: response[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      });
  }
  getLoanAccount() {
    this.stepEMI = [];
    // this.showSpinner = true;
    this._QuickLoanService
      .QuickLoan_Get_Financial_Proposed_And_Sanction({
        LoanId: this.loanSummary.LoanId,
      })
      .subscribe((res: any) => {
        // isCalEMITableView
        if (res.Item1.length > 0) {
          this.LoanDetail = res.Item1[0];
          this.cEIModel.ProcessingFee = this.LoanDetail.ProcessingFeePercentage
          let dataProposed = {
            Disbursement_Amt: res.Item1[0].DisbursementAmt,
            Interest_Amt: res.Item1[0].InterestAmt,
            Agreement_Value: res.Item1[0].AgreementValue,
            LTV: res.Item1[0].LTV,
            ROI: res.Item1[0].Flat_Rate,
            EMI_Amt: res.Item1[0].Application_LoanEMIAmount,
            Case_IRR: res.Item1[0].Case_IRR,
            Disbursement_IRR: res.Item1[0].Disbursement_IRR,
            Margin: res.Item1[0].Margin,
          };
          this.emiIrrProposed = dataProposed;
          if (
            this.LoanDetail.EMI_Type == "Daily" ||
            this.LoanDetail.EMI_Type == "Weekly" ||
            this.LoanDetail.EMI_Type == "Fortnightly" ||
            this.LoanDetail.EMI_Type == "ByWeekly" ||
            this.LoanDetail.EMI_Type == "FourthWeekly" ||
            this.LoanDetail.EMI_Type == "SingleEMI"
          ) {
            this.LoanDetail.Name = "Days";
            this.Name = "Days";
          } else {
            this.LoanDetail.Name = "Months";
            this.Name = "Months";
          }
          //this.isCalEMITableView = false;
          //this.isCalEMIForm = true;
          this.stepEMIProposed = [];
          if (
            res.Item1[0].Application_IRR_CalculateBy == "ROI" ||
            res.Item2[0].Application_IRR_CalculateBy == "Reducing_ROI"
          ) {
            // this.cEIModel.IsStep = true;
            this.stepEMIProposed.push({
              FromEMI: 1,
              ToEMI: res.Item1[0].No_Of_Instl,
              EMI_Amount: this.emiIrrProposed.EMI_Amt.toFixed(2),
              NoOfInstl: res.Item1[0].No_Of_Instl,
              TotalAmount: this.emiIrrProposed.Agreement_Value.toFixed(2),
              disable: true,
            });
          }
          if (res.Item1[0].Application_IRR_CalculateBy == "STEP_EMI") {
            this.stepEMIProposed = [];
            this._QuickLoanService
              .QuickLoan_Get_StepEMI({
                LoanId: this.loanSummary.LoanId,
                Id: res.Item1[0].RestructureId,
              })
              .subscribe((res: any) => {
                this.stepEMIProposed = res;

                for (let i = 0; i < res.length; i++) {
                  this.stepEMIProposed[i].EMI_Amount = res[i].EMIAmount;
                  this.stepEMIProposed[i].NoOfInstl = res[i].NoOfEMI;
                }
                this.getTotlaEmiAndAmountProposed();
              });
          }
          if (res.Item1[0].Application_IRR_CalculateBy == "FLAT_EMI") {
            this.stepEMIProposed.push({
              FromEMI: 1,
              ToEMI: res.Item1[0].No_Of_Instl,
              EMI_Amount: this.emiIrrProposed.EMI_Amt.toFixed(2),
              NoOfInstl: res.Item1[0].No_Of_Instl,
              TotalAmount: this.emiIrrProposed.Agreement_Value.toFixed(2),
              disable: true,
            });
            // document.getElementById("CalculateByEMI")['checked'] = true;
          }

          if (res.Item2.length > 0) {
            this.LoanDetailApproved = res.Item2[0];
            let dataApproved = {
              Disbursement_Amt: res.Item2[0].DisbursementAmt,
              Interest_Amt: res.Item2[0].InterestAmt,
              Agreement_Value: res.Item2[0].AgreementValue,
              LTV: res.Item2[0].LTV,
              ROI: res.Item2[0].Flat_Rate,
              EMI_Amt: res.Item2[0].Application_LoanEMIAmount,
              Case_IRR: res.Item2[0].Case_IRR,
              Disbursement_IRR: res.Item2[0].Disbursement_IRR,
              Margin: res.Item2[0].Margin,
            };
            if (res.Item2[0].Type == "Sanction") {
              this.LoanDetailApproved.Heading = "Recommended";
            } else {
              this.LoanDetailApproved.Heading = "Approved";
            }
            this.emiIrrdataApproved = dataApproved;
            if (
              this.LoanDetailApproved.EMI_Type == "Daily" ||
              this.LoanDetailApproved.EMI_Type == "Weekly" ||
              this.LoanDetailApproved.EMI_Type == "Fortnightly" ||
              this.LoanDetailApproved.EMI_Type == "ByWeekly" ||
              this.LoanDetailApproved.EMI_Type == "FourthWeekly" ||
              this.LoanDetailApproved.EMI_Type == "SingleEMI"
            ) {
              this.LoanDetailApproved.Name = "Days";
              this.Name = "Days";

              //
            } else {
              this.LoanDetailApproved.Name = "Months";
              this.Name = "Months";
            }
            //this.isCalEMITableView = false;
            //this.isCalEMIForm = true;
            this.stepEMIApproved = [];
            if (
              res.Item2[0].Application_IRR_CalculateBy == "ROI" ||
              res.Item2[0].Application_IRR_CalculateBy == "Reducing_ROI"
            ) {
              // this.cEIModel.IsStep = true;
              this.stepEMIApproved.push({
                FromEMI: 1,
                ToEMI: res.Item2[0].No_Of_Instl,
                EMI_Amount: this.emiIrrdataApproved.EMI_Amt.toFixed(2),
                NoOfInstl: res.Item2[0].No_Of_Instl,
                TotalAmount: this.emiIrrdataApproved.Agreement_Value.toFixed(2),
                disable: true,
              });
            }
            if (res.Item2[0].Application_IRR_CalculateBy == "STEP_EMI") {
              this.stepEMIApproved = [];
              this._QuickLoanService
                .QuickLoan_Get_StepEMI({
                  LoanId: this.loanSummary.LoanId,
                  Id: res.Item2[0].RestructureId,
                })
                .subscribe((res: any) => {
                  this.stepEMIApproved = res;

                  for (let i = 0; i < res.length; i++) {
                    this.stepEMIApproved[i].EMI_Amount = res[i].EMIAmount;
                    this.stepEMIApproved[i].NoOfInstl = res[i].NoOfEMI;
                  }
                  this.getTotlaEmiAndAmountApproved();
                });
            }
            if (res.Item2[0].Application_IRR_CalculateBy == "FLAT_EMI") {
              this.stepEMIApproved.push({
                FromEMI: 1,
                ToEMI: res.Item2[0].No_Of_Instl,
                EMI_Amount: this.emiIrrdataApproved.EMI_Amt.toFixed(2),
                NoOfInstl: res.Item2[0].No_Of_Instl,
                TotalAmount: this.emiIrrdataApproved.Agreement_Value.toFixed(2),
                disable: true,
              });
              // document.getElementById("CalculateByEMI")['checked'] = true;
            }

            if (res.Item2[0].Application_IRR_CalculateBy) {
              this.cEIModel.Asset_Cost = res.Item2[0].AssetCost
                ? res.Item2[0].AssetCost == -1 || res.Item2[0].AssetCost == 0
                  ? 0
                  : res.Item2[0].AssetCost
                : 0;
              this.cEIModel.NetFinance_Amt = res.Item2[0].NetFinance;
              this.cEIModel.IRR_CalculateBy =
                res.Item2[0].Application_IRR_CalculateBy;
              this.cEIModel.EMI_Type = res.Item2[0].EMI_Type;
              this.cEIModel.No_Of_Inst = res.Item2[0].No_Of_Instl;
              this.cEIModel.Tenure = res.Item2[0].Tenure;
              this.cEIModel.Adv_Inst = res.Item2[0].Adv_Instl;
              this.cEIModel.MgmtFee = res.Item2[0].ManagementFee;
              this.cEIModel.First_EMI_Date = new Date(
                res.Item2[0].FirstEMIDate
              );
              this.cEIModel.Flat_Rate = res.Item2[0].Flat_Rate;
              this.cEIModel.EMI_Amount = res.Item2[0].Application_LoanEMIAmount;
              this.cEIModel.IRR_CalculateBy =
                res.Item2[0].Application_IRR_CalculateBy;
              this.cEIModel.Reducing_ROI = res.Item2[0].Case_IRR;
              this.cEIModel.Loan_Date = new Date(res.Item2[0].Loan_Date);
              this.cEIModel.LoanMaturityDate = new Date(
                res.Item2[0].LoanMaturityDate
              );
              this.cEIModel.ROI_Input_mathod = res.Item2[0].ROI_Input_mathod;

              if (this.cEIModel.IRR_CalculateBy == "Reducing_ROI")
                this.isFrequency = true;
              $(function () {
                $("#Flat_Rate").val(res.Item2[0].Flat_Rate);
              });

              $(function () {
                $("#EMI_Amounts").val(res.Item2[0].Application_LoanEMIAmount);
              });

              let data = {
                Disbursement_Amt: res.Item2[0].DisbursementAmt,
                Interest_Amt: res.Item2[0].InterestAmt,
                Agreement_Value: res.Item2[0].AgreementValue,
                LTV: res.Item2[0].LTV,
                ROI: res.Item2[0].Flat_Rate,
                EMI_Amt: res.Item2[0].Application_LoanEMIAmount,
                Case_IRR: res.Item2[0].Case_IRR,
                Disbursement_IRR: res.Item2[0].Disbursement_IRR,
                Margin: res.Item2[0].Margin,
              };
              this.stepEMI = [];
              this.emiIrr = data;
              if (
                res.Item2[0].Application_IRR_CalculateBy == "ROI" ||
                res.Item2[0].Application_IRR_CalculateBy == "Reducing_ROI"
              ) {
                // this.cEIModel.IsStep = true;
                this.stepEMI.push({
                  FromEMI: 1,
                  ToEMI: this.cEIModel.No_Of_Inst,
                  EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
                  NoOfInstl: this.cEIModel.No_Of_Inst,
                  TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
                  disable: true,
                });
                // document.getElementById("CalculateByFlat")['checked'] = true;
                //var amt = this.emiIrr.ROI.toFixed(2);
                //$(function () {
                //  $("#Flat_Rate").val(amt);
                //});
              }
              if (res.Item2[0].Application_IRR_CalculateBy == "STEP_EMI") {
                let stepArr: any[] = [];
                this.cEIModel.IsStep = true;
                this._QuickLoanService
                  .QuickLoan_Get_StepEMI({
                    LoanId: this.loanSummary.LoanId,
                    Id: res.Item2[0].RestructureId,
                  })
                  .subscribe((res: any) => {
                    this.stepEMI = res;

                    for (let i = 0; i < res.length; i++) {
                      this.stepEMI[i].EMI_Amount = res[i].EMIAmount;
                      this.stepEMI[i].NoOfInstl = res[i].NoOfEMI;
                      for (
                        let j = +this.stepEMI[i].FromEMI;
                        j <= +this.stepEMI[i].ToEMI;
                        j++
                      ) {
                        if (+this.stepEMI[i].EMI_Amount >= 0) {
                          stepArr.push(this.stepEMI[i].EMI_Amount);
                        }
                      }
                    }
                    this.getTotlaEmiAndAmount();
                  });
              }
              if (res.Item2[0].Application_IRR_CalculateBy == "FLAT_EMI") {
                this.stepEMI.push({
                  FromEMI: 1,
                  ToEMI: this.cEIModel.No_Of_Inst,
                  EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
                  NoOfInstl: this.cEIModel.No_Of_Inst,
                  TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
                  disable: true,
                });
                // document.getElementById("CalculateByEMI")['checked'] = true;
              }
            }
          }
        }

        this.showSpinner = false;
      });
  }
  addSanctionFinancial() {
    $("#loanFinancialDetailsModel").modal("show");
    $("#loanFinancialDetailsModel").css("z-index", "1050");
    this.getLoanAccount();
  }
  onCloseDialog() {
    $("#loanFinancialDetailsModel").modal("hide");
  }
  setTenure() {
    if (this.cEIModel.No_Of_Inst && this.cEIModel.EMI_Type != "SingleEMI") {
      if (this.cEIModel.EMI_Type == "ByMonthly") {
        this.cEIModel.Tenure = 2 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Quarterly") {
        this.cEIModel.Tenure = 3 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "HalfYearly") {
        this.cEIModel.Tenure = 6 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Yearly") {
        this.cEIModel.Tenure = 12 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Weekly") {
        this.cEIModel.Tenure = 7 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "Fortnightly") {
        this.cEIModel.Tenure = 15 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "ByWeekly") {
        this.cEIModel.Tenure = 14 * +this.cEIModel.No_Of_Inst;
      } else if (this.cEIModel.EMI_Type == "FourthWeekly") {
        this.cEIModel.Tenure = 28 * +this.cEIModel.No_Of_Inst;
      } else {
        this.cEIModel.Tenure = this.cEIModel.No_Of_Inst;
      }
      if (
        this.cEIModel.EMI_Type == "Daily" ||
        this.cEIModel.EMI_Type == "Weekly" ||
        this.cEIModel.EMI_Type == "Fortnightly" ||
        this.cEIModel.EMI_Type == "ByWeekly" ||
        this.cEIModel.EMI_Type == "FourthWeekly" ||
        this.cEIModel.EMI_Type == "SingleEMI"
      )
        this.Name = "Days";
      else this.Name = "Months";
    }
    this.setAdvanceInstl();
  }
  setAdvanceInstl() {
    if (Number(this.cEIModel.Adv_Inst) >= Number(this.cEIModel.No_Of_Inst)) {
      this.cEIModel.Adv_Inst = 0;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Advance Installment not greater than No. Of Installment !!!",
        ...this.configSuccess,
      });
    }
  }
  addNextStep(data: any) {
    if (+data.ToEMI <= 0 || +data.EMI_Amount < 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Fill All Data.",
        ...this.configSuccess,
      });
      return;
    } else if (+data.ToEMI > +this.cEIModel.No_Of_Inst) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "To Emi can not be greater than number of installment.",
        ...this.configSuccess,
      });
      return;
    } else {
      if (+data.FromEMI > +data.ToEMI) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "To EMI should be greater than From EMI.",
          ...this.configSuccess,
        });
        return;
      }
      data.NoOfInstl = +data.ToEMI - +data.FromEMI + 1;
      data.TotalAmount = data.NoOfInstl * +data.EMI_Amount;
      if (+data.ToEMI < +this.cEIModel.No_Of_Inst) {
        data.disable = true;
        this.stepEMI.push({
          FromEMI: +data.ToEMI + 1,
          ToEMI: "",
          EMI_Amount: "",
          disable: false,
        });
      }
      this.getTotlaEmiAndAmount();
    }
  }
  getTotlaEmiAndAmount() {
    if (this.stepEMI.length > 1) {
      let TotalEmi: any = 0,
        TotalAmount: any = 0;
      for (let i = 0; i < this.stepEMI.length; i++) {
        if (this.stepEMI[i].EMI_Amount) {
          TotalEmi =
            TotalEmi + (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1);
          TotalAmount =
            TotalAmount +
            (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1) *
            +this.stepEMI[i].EMI_Amount;
        }
      }
      this.stepEmiData = { totalEmi: TotalEmi, totalAmount: TotalAmount };
    } else {
      this.stepEmiData = undefined;
    }
  }
  getTotlaEmiAndAmountProposed() {
    if (this.stepEMIProposed.length > 1) {
      let TotalEmi: any = 0,
        TotalAmount: any = 0;
      for (let i = 0; i < this.stepEMIProposed.length; i++) {
        if (this.stepEMIProposed[i].EMI_Amount) {
          TotalEmi =
            TotalEmi +
            (+this.stepEMIProposed[i].ToEMI -
              +this.stepEMIProposed[i].FromEMI +
              1);
          TotalAmount =
            TotalAmount +
            (+this.stepEMIProposed[i].ToEMI -
              +this.stepEMIProposed[i].FromEMI +
              1) *
            +this.stepEMIProposed[i].EMI_Amount;
        }
      }
      this.stepEmiDataProposed = {
        totalEmi: TotalEmi,
        totalAmount: TotalAmount,
      };
    } else {
      this.stepEmiDataProposed = undefined;
    }
  }
  getTotlaEmiAndAmountApproved() {
    if (this.stepEMIApproved.length > 1) {
      let TotalEmi: any = 0,
        TotalAmount: any = 0;
      for (let i = 0; i < this.stepEMIApproved.length; i++) {
        if (this.stepEMIApproved[i].EMI_Amount) {
          TotalEmi =
            TotalEmi +
            (+this.stepEMIApproved[i].ToEMI -
              +this.stepEMIApproved[i].FromEMI +
              1);
          TotalAmount =
            TotalAmount +
            (+this.stepEMIApproved[i].ToEMI -
              +this.stepEMIApproved[i].FromEMI +
              1) *
            +this.stepEMIApproved[i].EMI_Amount;
        }
      }
      this.stepEmiDataApproved = {
        totalEmi: TotalEmi,
        totalAmount: TotalAmount,
      };
    } else {
      this.stepEmiDataApproved = undefined;
    }
  }
  removeLastStep(index: any) {
    this.stepEMI.splice(index, 1);
    this.stepEMI[index - 1].disable = false;
    this.getTotlaEmiAndAmount();
  }
  onCalculateEmiAndIRR() {
    let netAmount: any[] = [];
    let roi: any[] = [];

    if (+this.cEIModel.NetFinance_Amt <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Net Finance Amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.Flat_Rate < 0 &&
      this.cEIModel.IRR_CalculateBy == "ROI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Flat rate should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.EMI_Amount <= 0 &&
      this.cEIModel.IRR_CalculateBy == "FLAT_EMI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMI amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.No_Of_Inst <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No of Installment should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.Tenure <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Tenure should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      this.cEIModel.IRR_CalculateBy == "FLAT_EMI" &&
      +this.cEIModel.EMI_Amount * +this.cEIModel.No_Of_Inst <
      +this.cEIModel.NetFinance_Amt
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No of Installment multiply by EMI amount should be greater than or equal to Net Finance Amount.",
        ...this.configSuccess,
      });
      return;
    }

    if (this.cEIModel.EMI_Type == "SingleEMI") {
      var ROI = parseFloat(this.cEIModel.Flat_Rate);
      if (this.cEIModel.ROI_Input_mathod == "Daily") {
        ROI = ROI * this.currentUser.DaysInYear;
      }
      var Interest_Amt = Math.round(
        ((parseFloat(this.cEIModel.NetFinance_Amt) * ROI) /
          100 /
          this.currentUser.DaysInYear) *
        this.cEIModel.Tenure
      );
      var Agreement_Value = Math.round(
        parseFloat(this.cEIModel.NetFinance_Amt) +
        parseFloat(Interest_Amt.toString())
      );

      let data = {
        Disbursement_Amt: this.cEIModel.NetFinance_Amt,
        Interest_Amt: Interest_Amt,
        Agreement_Value: Agreement_Value,
        ROI: parseFloat(this.cEIModel.Flat_Rate),
        EMI_Amt: Agreement_Value,
        Case_IRR: parseFloat(this.cEIModel.Flat_Rate),
        Disbursement_IRR: parseFloat(this.cEIModel.Flat_Rate),
        Margin: 0,
      };
      this.emiIrr = data;

      if (this.cEIModel.IRR_CalculateBy == "ROI") {
        this.stepEMI = [];

        this.stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfInstl: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
        var amt = this.emiIrr.EMI_Amt.toFixed(2);
        $(function () {
          $("#EMI_Amounts").val(amt);
        });
      }

      if (this.cEIModel.IRR_CalculateBy == "FLAT_EMI") {
        this.stepEMI = [];

        this.stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfInstl: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
        var amt = this.emiIrr.ROI.toFixed(2);
        $(function () {
          $("#Flat_Rate").val(amt);
        });
      }
    } else {
      if (this.selectedScheme.Amount) {
        netAmount = this.selectedScheme.Amount.split("-");
        roi = this.selectedScheme.ROI.split("-");
        if (
          +this.cEIModel.NetFinance_Amt > +netAmount[1] ||
          +this.cEIModel.NetFinance_Amt < +netAmount[0]
        ) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Net Finance Amount should be in between Scheme Amount.",
            ...this.configSuccess,
          });
          return;
        }
        if (
          this.cEIModel.IRR_CalculateBy == "ROI" &&
          (+this.cEIModel.Flat_Rate > +roi[1] ||
            +this.cEIModel.Flat_Rate < +roi[0])
        ) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Flate Rate should be in between Scheme ROI.",
            ...this.configSuccess,
          });
          return;
        }
        if (+this.cEIModel.Adv_Inst < +this.selectedScheme.AdvanceEMI) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Advance Installment can not be less than Scheme Advance EMI.",
            ...this.configSuccess,
          });
          return;
        }
        this.calculateEmiIrr();
      } else {
        this.calculateEmiIrr();
      }
    }
  }
  calculateEmiIrr() {
    let stepArr: any[] = [];
    if (this.cEIModel.IRR_CalculateBy == "STEP_EMI") {
      this.cEIModel.IsStep = true;
      if (this.stepEMI.length < 1) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please add step EMI first",
          ...this.configSuccess,
        });
        return;
      }
      let lastEMI = this.stepEMI[this.stepEMI.length - 1].ToEMI;
      if (+lastEMI != +this.cEIModel.No_Of_Inst) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Total EMI Should Be Equal To No of Installment",
          ...this.configSuccess,
        });
        return;
      }

      //if (this.stepEmiData == undefined || this.stepEmiData == null) {
      //  this.snackBar.openFromComponent(SnackbarComponent, {
      //    data: "Please recalculate amount.",
      //    ...this.configSuccess,
      //  });
      //  return;
      //}
      if (
        this.stepEmiData != undefined &&
        +this.stepEmiData.totalAmount < +this.cEIModel.NetFinance_Amt
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Total EMI Amount should be  equal or Greater then  Net Finance Amount.",
          ...this.configSuccess,
        });
        return;
      }
      for (let i = 0; i < this.stepEMI.length; i++) {
        for (
          let j = +this.stepEMI[i].FromEMI;
          j <= +this.stepEMI[i].ToEMI;
          j++
        ) {
          if (+this.stepEMI[i].EMI_Amount >= 0) {
            stepArr.push(this.stepEMI[i].EMI_Amount);
          }
        }
      }
    } else {
      this.cEIModel.IsStep = false;
      this.cEIModel.STEP_IRR = 0;
    }
    let _data = { ...this.cEIModel };
    _data.cashflow = stepArr;
    if (this.cEIModel.IsStep) {
      _data.EMI_Amount = this.stepEmiData.totalAmount;
    }
    // if (this.cEIModel.Adv_Inst > 0) {
    _data.DaysInYear = this.currentUser.DaysInYear;
    //  this.LmsService.LMS_GetCalculateEMIIRR(_data).subscribe((res: any) => {
    //    if (res.length > 0) {
    //      _data.NetFinance_Amt =
    //        this.cEIModel.NetFinance_Amt -
    //        this.cEIModel.Adv_Inst * res[0].EMI_Amt;
    //      _data.No_Of_Inst = this.cEIModel.No_Of_Inst - this.cEIModel.Adv_Inst;
    //      console.log("_data.NetFinance_Amt", _data.NetFinance_Amt);
    //      this.getCalculateEMIIRR(_data);
    //    }
    //  });
    //} else {
    this.getCalculateEMIIRR(_data);
    //}
  }
  getCalculateEMIIRR(data: any) {
    this.showSpinner = true;
    // data.ProductId = 2;
    data.DaysInYear = this.currentUser.DaysInYear;
    this.LmsService.LMS_GetCalculateEMIIRR(data).subscribe((res: any) => {
      this.showSpinner = false;
      this.emiIrr = res[0];
      setTimeout(() => {
        var elmntToView = document.getElementById("scrollto");
        elmntToView.scrollIntoView();
      }, 500);

      if (this.cEIModel.IRR_CalculateBy == "ROI") {
        this.stepEMI = [];

        this.stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfInstl: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
      }
      if (this.cEIModel.IRR_CalculateBy == "Reducing_ROI") {
        this.stepEMI = [];

        this.stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfInstl: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
      }
      $("#EMI_Amounts").val(this.emiIrr.EMI_Amt.toFixed(2));
      if (this.cEIModel.IRR_CalculateBy == "FLAT_EMI") {
        this.stepEMI = [];

        this.stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfInstl: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
      }
      $("#Flat_Rate").val(this.emiIrr.ROI.toFixed(2));
    });
  }
  onSaveApplication() {
    if (+this.cEIModel.NetFinance_Amt <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Net Finance Amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.Flat_Rate < 0 &&
      this.cEIModel.IRR_CalculateBy == "ROI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Flate rate should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.EMI_Amount <= 0 &&
      this.cEIModel.IRR_CalculateBy == "EMI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMI amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.No_Of_Inst <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No of Installment should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.Tenure <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Tenure should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      this.cEIModel.IRR_CalculateBy !== "STEP_EMI" &&
      +this.cEIModel.No_Of_Inst * +this.emiIrr.EMI_Amt <
      +this.cEIModel.NetFinance_Amt
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMI multiply with installment should be equal or grater than net finance.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.cEIModel.IRR_Type == "Step") {
      let length = this.stepEMI.length;
      let ToEmi = this.stepEMI[length - 1].ToEMI;
      if (+ToEmi != +this.cEIModel.No_Of_Inst) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Add EMI step.",
          ...this.configSuccess,
        });
        return;
      }
      if (
        this.emiIrr.Disbursement_Amt + this.emiIrr.Interest_Amt !=
        +this.stepEmiData.totalAmount
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Total EMI Amount must be equal to  (Disbursement Amount  + Interest Amount)",
          ...this.configSuccess,
        });
        return;
      }
    }
    let _stepEmi: any[] = [];
    this.stepEMI.forEach((obj: any) => {
      _stepEmi.push(this.dataSharingService.cleanObject({ ...obj }));
    });

    if (
      this.emiIrr.Interest_Amt == null ||
      this.emiIrr.Interest_Amt == 0 ||
      this.emiIrr.Interest_Amt == ""
    ) {
      this.emiIrr.Interest_Amt = 0;
      this.emiIrr.Case_IRR = 0;
      this.emiIrr.Disbursement_IRR = 0;
    }
    let data = {
      Application: this.dataSharingService.cleanObject({
        LoanId: this.loanSummary.LoanId,
        BranchId: 1,
        ProductId: this.cEIModel.ProductId,
        Asset_Cost: this.cEIModel.Asset_Cost,
        NetFinance_Amt: this.cEIModel.NetFinance_Amt,
        Flat_Rate: this.emiIrr.ROI,
        No_Of_Inst: this.cEIModel.No_Of_Inst,
        Tenure: this.cEIModel.Tenure,
        Adv_Inst: this.cEIModel.Adv_Inst,
        MgmtFee: 0, // this.cEIModel.MgmtFee,
        EMI_Type: this.cEIModel.EMI_Type,
        Disbursement_Amt: this.emiIrr.Disbursement_Amt,
        Interest_Amt: this.emiIrr.Interest_Amt,
        Agreement_Value: this.emiIrr.Agreement_Value,
        LTV: this.emiIrr.LTV,
        EMI_Amt: this.emiIrr.EMI_Amt,
        Case_IRR: this.emiIrr.Case_IRR,
        Disbursement_IRR: this.emiIrr.Disbursement_IRR,
        Loan_Purpose: this.cEIModel.Purpose,
        Loan_SchemeId: this.selectedScheme.SchemeId,
        FirstEMIDate: this.cEIModel.First_EMI_Date,
        IRR_Type: this.cEIModel.IRR_Type,
        LoginUserId: this.currentUser.userId,
        ROI: this.emiIrr.ROI,
        EMI_Amount: this.cEIModel.EMI_Amount,
        IRR_CalculateBy: this.cEIModel.IRR_CalculateBy,
        LoanMaturityDate: this.cEIModel.LoanMaturityDate,
        Loan_Date: this.cEIModel.Loan_Date,
        ProcessingFeePercentage: this.cEIModel.ProcessingFee
      }),
      StepIRR: _stepEmi,
    };

    this._QuickLoanService
      .QuickLoan_Update_Financial_Sanction({ JSON: JSON.stringify(data) })
      .subscribe((res: any) => {
        if (res) {
          if (res[0] ? res[0].CODE >= 0 : false) {
            if (this.Processid == 8 || this.Processid == 2)
              this.getLoanAccount();
            // console.log(" this.cEIModel.ProductId", this.cEIModel.ProductId);
            // console.log(" data", data);
            this.QuickLoan_Save_PageProcess("Sanction Conditions");
            //this.lmsService.Get_AccountHead_For_VoucherEntry({ HeadId: 34, VoucherSubType: 'Loan', ProductId: this.LoanAllDetail[0].ProductId || 0, TaxType: 'CR' }).subscribe((res: any) => {
            //  console.log("Get_AccountHead_For_VoucherEntry", res);
            //  if (res.Item1[0]) {
            //    if (res.Item1[0].AccountId) {
            //      if (this.cEIModel.Adv_Inst > 0) {
            //        for (let i = 0; i < this.cEIModel.Adv_Inst; i++) {

            //          this.ReceiptData.LoanId = this.Loan_id;
            //          this.ReceiptData.DeductionLoanId = this.Loan_id;

            //          this.ReceiptData.LoginUserId = this.currentUser.userId;
            //          this.ReceiptData.CollectionOn = new Date();
            //          this.ReceiptData.Int_Id = 0;
            //          this.ReceiptData.ChargeHeadId = 34;
            //          this.ReceiptData.ChargeHeadCategory = res.Item1[0].AccountName;
            //          this.ReceiptData.AccountId = res.Item1[0].AccountId;

            //          this.ReceiptData.IsActive = 0;
            //          this.ReceiptData.Charges = 'Advance EMI';
            //          this.ReceiptData.Type = 'Deduction';
            //          this.ReceiptData.Amount = this.emiIrr.EMI_Amt;
            //          this.ReceiptData.CGST = 0;
            //          this.ReceiptData.SGST = 0;
            //          this.ReceiptData.TotalAmount = this.emiIrr.EMI_Amt;
            //          this.lmsService.SaveReceiptDeduction({ JSON: JSON.stringify({ Loaninfo: this.ReceiptData }) }).subscribe((res: any) => {
            //            this.showSpinner = false;

            //            //this.rdDataSource = new MatTableDataSource(res.Item2);

            //          })
            //        }
            //      }
            //    }
            //  }
            //})

            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            this.onCloseDialog();
          } else if (res[0]) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
        }
      });
  }
  setType(event: any) {
    if (this.cEIModel.IRR_CalculateBy == "STEP_EMI") {
      this.stepEmiForm = true;
      this.cEIModel.EMI_Type = "Monthly";
      this.cEIModel.Tenure = this.cEIModel.No_Of_Inst;
    }
    if (this.cEIModel.IRR_CalculateBy == "Reducing_ROI")
      this.isFrequency = true;
    else this.isFrequency = false;
    //if (this.cEIModel.Type == 'EMIAmt')
    //  this.cEIModel.IRR_CalculateBy = 'FLAT_EMI'
    //if (this.cEIModel.Type == 'StepEMI')
    //  this.cEIModel.IRR_CalculateBy = 'STEP_EMI'

    this.emiIrr = undefined;
    if (this.cEIModel.IRR_CalculateBy == "ROI") {
      delete this.cEIModel.EMI_Amount;
    } else {
      delete this.cEIModel.Flat_Rate;
    }

    if (this.cEIModel.IRR_CalculateBy !== "STEP_EMI") {
      this.stepEMI = [];
    } else {
      this.stepEMI.push({
        FromEMI: 1,
        ToEMI: "",
        EMI_Amount: "",
        disable: false,
      });
      this.stepEmiData = undefined;
    }
  }
  OnCompletedSanctionConditions() {
    let FilterProductData = this.Headers.filter((a) => a.IsCompleted == true);
    var Ids = Array.prototype.map
      .call(FilterProductData, function (item) {
        return item.Id;
      })
      .join(",");
    this._QuickLoanService
      .QuickLoan_Completed_Sanction_Conditions({
        Ids: Ids,
        LoginUserId: this.currentUser.userId,
        LoanId: this.loanSummary.LoanId,
      })
      .subscribe((response: any) => {
        // console.log(response);
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.GetSanctionConditions();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  onSanctionFinancialApproved() {
    this.dialogreturn = this.dialog.openConfirmDialog(
      "Are you sure you want to Accept these Financial?"
    );
    this.dialogreturn.afterClosed().subscribe((dialogResult) => {
      if (dialogResult == true) {
        this._QuickLoanService
          .QuickLoan_Accept_Financial_Sanction({
            Id: this.LoanDetailApproved.RestructureId,
            LoanId: this.loanSummary.LoanId,
          })
          .subscribe((response) => {
            if (response[0].CODE == 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
              this.GetSanctionConditions();
              this.getLoanAccount();
              this.onCloseDialog();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
            }
          });
      }
    });
  }
  DaysDifference() {
    if (
      this.cEIModel.Loan_Date != null &&
      this.cEIModel.LoanMaturityDate != null &&
      this.cEIModel.LoanMaturityDate != undefined
    ) {
      const startTime = new Date(this.cEIModel.Loan_Date).getTime();
      const endTime = new Date(this.cEIModel.LoanMaturityDate).getTime();

      // Calculate the difference in milliseconds
      const difference = endTime - startTime;

      // Convert milliseconds to days
      this.cEIModel.Tenure = Math.ceil(difference / (1000 * 3600 * 24));
      this.emiIrr = undefined;
    }
  }
  ChangeText() {
    this.emiIrr = undefined;
  }
  changeLoan_Date() {
    var year = new Date(this.cEIModel.Loan_Date).getFullYear();
    var month = new Date(this.cEIModel.Loan_Date).getMonth();
    var day = new Date(this.cEIModel.Loan_Date).getDate();
    var c = new Date(year, month, day + 1);
    // console.log("new date", c);
    this.minLoanMaturityDate = c;
    this.DaysDifference();
  }
  LMS_Get_DV_Details() {
    this.showSpinner = true;
    this.lmsService
      .LMS_Get_DV_Details({ LoanAcId: this.LoanId, VoucherId: this.VoucherId })
      .subscribe((res: any) => {
        // console.log("resLogRess", res);
        this.dvDetail = res.Item1[0];
        // console.log("dvDetailLogggg", this.dvDetail);

        this.stepArr = res.Item4;
        this.authRevertStatus = this.dvDetail.VoucherStatus
          ? this.dvDetail.VoucherStatus == "Authorized" ||
            this.dvDetail.VoucherStatus == "Reverted"
            ? this.dvDetail.VoucherStatus
            : ""
          : "";
        this.voucherRemark = this.dvDetail.VoucherStatus
          ? this.dvDetail.VoucherStatus != "Pending"
            ? this.dvDetail.Remark
            : ""
          : "";
        this.deductionList = res.Item2;
        this.receiptList = res.Item3;
        this.dataSource = new MatTableDataSource(this.receiptList);
        this.showSpinner = false;
        if (
          this.dvDetail.EMI_Type == "Daily" ||
          this.dvDetail.EMI_Type == "Weekly" ||
          this.dvDetail.EMI_Type == "Fortnightly" ||
          this.dvDetail.EMI_Type == "ByWeekly" ||
          this.dvDetail.EMI_Type == "FourthWeekly"
        )
          this.Tenure_Name = "Days";
        else this.Tenure_Name = "Months";
        this.initializeVoucher();
        this.deductionList.map((item) => {
          if (!item.IsTax) {
            this.voucherModel.Voucher_Detail.push({
              ...item,
              TaxSlab: [],
            });
          } else if (this.voucherModel.Voucher_Detail.length > 1) {
            this.voucherModel.Voucher_Detail[
              this.voucherModel.Voucher_Detail.length - 1
            ].TaxSlab.push(item);
          }
        });
        this.GetCustomerDetails();
        this.getTotalCalculation();
      });
  }

  initializeVoucher() {
    this.voucherModel.Voucher = {
      Voucher_LoanId: this.dvDetail.LoanId,
      VoucherId: 0,
      Voucher_Type: "Disbursement",
      Voucher_Sub_Type: "Loan",
      Voucher_Tag: "",
      Voucher_Date: moment(this.dvDetail.VoucherDate, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      ),
      Voucher_No: this.dvDetail.VoucherNo,
      DRAccountId: "",
      Voucher_Mode_of_Payment: "",
      Voucher_Total_Credit_Amount: "",
      Voucher_Total_Debit_Amount: "",
      Voucher_Total_Tax: "",
      Voucher_Narration: this.dvDetail.Voucher_Narration,
      Voucher_Cheque_No: "",
      Voucher_Cheque_Date: "",
      Voucher_Bank_Name: "",
      Voucher_Cheque_Clear_Date: "",
      Voucher_E_Transaction_Date: "",
      Voucher_E_Ref_No: "",
      Voucher_ReceiptNo_Book: "",
      Voucher_ReceiptDate: "",
      LoginUserId: this.currentUser.userId,
    };
  }
  getTotalCalculation() {
    let _totalCR: any = 0;
    let _totalDR: any = 0;
    let _totalCRTX: any = 0;
    let _totalDRTX: any = 0;
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      if (obj.Type == "CR") {
        _totalCR = _totalCR + parseFloat(obj.Amount) || 0;
      } else {
        _totalDR = _totalDR + parseFloat(obj.Amount) || 0;
      }
      obj.TaxSlab.forEach((tobj: any) => {
        if (obj.Type == "CR") {
          _totalCR = _totalCR + parseFloat(tobj.Amount) || 0;
          _totalCRTX = _totalCRTX + parseFloat(tobj.Amount) || 0;
        } else {
          _totalDR = _totalDR + parseFloat(tobj.Amount) || 0;
          _totalDRTX = _totalDRTX + parseFloat(tobj.Amount) || 0;
        }
      });
    });
    this.totalCalculator = {
      totalCR: parseFloat(_totalCR),
      totalDR: parseFloat(_totalDR),
      totalCRTX: parseFloat(_totalCRTX),
      totalDRTX: parseFloat(_totalDRTX),
      totalCRInWord: new ToWords().convert(parseFloat(_totalCR)),
      totalDRInWord: new ToWords().convert(parseFloat(_totalDR)),
    };
  }
  GetCustomerDetails() {
    this.lmsService
      .LMS_GetLoanDetails({ Loan_Id: this.dvDetail.LoanId })
      .subscribe((res: any) => {
        // console.log("RES :", res);
        this.displayedCustomerDetailColumns = [
          "CustomerId",
          "Customer",
          "CustomerType",
          "GenderAge",
          "PhoneNo",
          "Relation_With_Hirer",
          "ExistingCustomer",
        ];
        this.dataSourceCustomerDetail = new MatTableDataSource(res.Item2);
      });
  }
  fileChangeListener(files: any) {
    // debugger;
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    let $this = this;
    let reader = new FileReader();
    this.selectedEsignFile = files[0].name;
    this.DocModel.DocName = files[0].name;
    this.DocModel.ApplicationNo = this.loanSummary.ApplicationNo;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.DocModel.DocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  removeFile(input: any) {
    input.value = "";
    this.selectedEsignFile = "";
    this.DocModel.DocName = "";
    this.DocModel.DocData = "";
  }
  fileChangeListener1(files: any) {
    // debugger;
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    let $this = this;
    let reader = new FileReader();
    this.selectedEsignSanctionFile = files[0].name;
    this.DocModelSanction.DocName = files[0].name;
    this.DocModelSanction.ApplicationNo = this.loanSummary.ApplicationNo;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.DocModelSanction.DocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  removeFile1(input: any) {
    input.value = "";
    this.selectedEsignSanctionFile = "";
    this.DocModelSanction.DocName = "";
    this.DocModelSanction.DocData = "";
  }
  OnESign() {
    var request =
      '{ "sign_coordinates": {' +
      '"email/mobile": {' +
      '"1": [' +
      '{' +
      ' "llx":3, ' +
      ' "lly":0,' +
      '"urx":142, ' +
      ' "ury":39' +
      '} ' +
      ' ], ' +
      '"5": [' +
      ' {' +
      '"llx":29, ' +
      ' "lly":416, ' +
      ' "urx":197, ' +
      ' "ury":456' +
      '}' +
      ']' +
      '} ' +
      '},' +
      '"signature_verification": {' +
      '"0": {' +
      '"abort_on_fail": "true",' +
      '"max_attempt": 3,' +
      ' "rules": [' +
      ' {' +
      '"operation": "AND",' +
      ' "conditions": [' +
      '{' +
      '  "field": "AADHAAR",' +
      ' "match_type": "EXACT",' +
      ' "value": "' + this.loanSummary.AADHAARNo + '"' +
      ' }' +
      ']' +
      '}' +
      ' ]' +
      '}' +
      '}, ' +
      '"signers": [{' +
      '"identifier": "' +
      this.loanSummary.Customer_Email +
      '", "name": "' +
      this.loanSummary.Customer +
      '", "sign_type": "AADHAAR","reason": "Loan Ref(' +
      this.loanSummary.ApplicationNo +
      ')", "signing_addons": [ { "type": "GEO_LOCATION", "perform_enrichment": true  }] } ], "expire_in_days": 3, "generate_access_token": true, "display_on_page": "all", "send_sign_link": "true",  "customer_notification_mode": "ALL", "notify_signers": "true" } ';
    //const formData: FormData = new FormData();
    // formData.append('file', this.selectedEsignFile, this.selectedEsignFile.name)

    this._QuickLoanService
      .digioEsignRequest({
        base64String: this.DocModel.DocData,
        fileName: this.DocModel.DocName,
        text: request,
        LoanId: this.loanSummary.LoanId,
        LoginUserId: this.currentUser.userId,
        Type: "Agreement"
      })
      .subscribe(
        (response: any) => {
          if (response.length > 0 && response[0].CODE >= 0) {
            console.log("File uploaded successfully!", response);
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: 'Upload E-sign Requested Successfully!!',
              ...this.configSuccess,
            });
            this.loanSummary.Digio_Esign_Status = "requested";
            this.loanSummary.Digio_Esign_UploadId = response[0].MSG;
          }
          else {
            console.log("File uploaded successfully!", response);
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
          }
        },
        (error) => {
          console.error("Error uploading file:", error);
        }
      );

    this.OnCloseAgreementModal();
  }
  OnESignSanction() {
    var request =
      '{ "sign_coordinates": {' +
      '"email/mobile": {' +
      '"1": [' +
      '{' +
      ' "llx":3, ' +
      ' "lly":0,' +
      '"urx":142, ' +
      ' "ury":39' +
      '} ' +
      ' ], ' +
      '"5": [' +
      ' {' +
      '"llx":29, ' +
      ' "lly":416, ' +
      ' "urx":197, ' +
      ' "ury":456' +
      '}' +
      ']' +
      '} ' +
      '},' +
      '"signature_verification": {' +
      '"0": {' +
      '"abort_on_fail": "true",' +
      '"max_attempt": 3,' +
      ' "rules": [' +
      ' {' +
      '"operation": "AND",' +
      ' "conditions": [' +
      '{' +
      '  "field": "AADHAAR",' +
      ' "match_type": "EXACT",' +
      ' "value": "' + this.loanSummary.AADHAARNo + '"' +
      ' }' +
      ']' +
      '}' +
      ' ]' +
      '}' +
      '}, ' +
      '"signers": [{' +
      '"identifier": "' +
      this.loanSummary.Customer_Email +
      '", "name": "' +
      this.loanSummary.Customer +
      '", "sign_type": "AADHAAR","reason": "Loan Ref(' +
      this.loanSummary.ApplicationNo +
      ')", "signing_addons": [ { "type": "GEO_LOCATION", "perform_enrichment": true  }] } ], "expire_in_days": 3, "generate_access_token": true, "display_on_page": "all", "send_sign_link": "true",  "customer_notification_mode": "ALL", "notify_signers": "true" } ';
    //const formData: FormData = new FormData();
    // formData.append('file', this.selectedEsignFile, this.selectedEsignFile.name)


    this._QuickLoanService
      .digioEsignRequest({
        base64String: this.DocModelSanction.DocData,
        fileName: this.DocModelSanction.DocName,
        text: request,
        LoanId: this.loanSummary.LoanId,
        LoginUserId: this.currentUser.userId,
        Type: "Sanction"
      })
      .subscribe(
        (response:any) => {
          if (response.length > 0 && response[0].CODE >= 0) {
            console.log("File uploaded successfully!", response);
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: 'Upload E-sign Requested Successfully!!',
              ...this.configSuccess,
            });
            this.loanSummary.Digio_Esign_Sanction_Status = "requested";
            this.loanSummary.Digio_Esign_Sanction_UploadId = response[0].MSG;
          }
          else {
            console.log("File uploaded successfully!", response);
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
          }


        }

      ,
        (error) => {
          console.error("Error uploading file:", error);
        }
      );
     

    this.OnCloseSanctionModal();
  }
  ShowCAMReport() {
    $("#CAMReport").modal("show");
    $("#CAMReport").css("z-index", "1050");
  }
  HideCAMReport() {
    $("#CAMReport").modal("hide");
  }

  openESignModel() {
    console.log("loan summaryyyyyyy", this.loanSummary);

    if (this.loanSummary.Customer_Email) {
      if (this.loanSummary.AADHAARNo) {
        $("#agreementUploadEsign").modal("show");
        $("#agreementUploadEsign").css("z-index", "1050");
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "This document can not be uploaded because AADHAAR NO is not Added.",
          ...this.configSuccess,
        });

      }
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "This document can not be uploaded because email id is not find.",
        ...this.configSuccess,
      });
    }
  }
  openESignSanctionModel() {
    if (this.loanSummary.Customer_Email) {
      if (this.loanSummary.AADHAARNo) {
        $("#SanctionUploadEsign").modal("show");
        $("#SanctionUploadEsign").css("z-index", "1050");
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "This document can not be uploaded because AADHAAR NO is not Added.",
          ...this.configSuccess,
        });

      }

    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "This document can not be uploaded because email id is not find.",
        ...this.configSuccess,
      });
    }
  }
  OnCloseSanctionModal() {
    $("#SanctionUploadEsign").modal("hide");
    this.selectedEsignSanctionFile = "";
  }
  OnCloseAgreementModal() {
    $("#agreementUploadEsign").modal("hide");
    this.selectedEsignFile = "";
  }

  checkStatusForEsign() {
    this._QuickLoanService
      .DigioEsignStatusCheck({
        UploadId: this.loanSummary.Digio_Esign_UploadId,
        LoanId: this.loanSummary.LoanId,
      })
      .subscribe(
        (response:any) => {
          console.log("File uploaded successfully!", response);

          if (response.length > 0 && response[0].CODE >= 0) {
          if (response[0].MSG == "completed") {
            this.loanSummary.Digio_Esign_Status = "completed";
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: 'Agreement E-Signed Succesfully.',
              ...this.configSuccess,
            });
          }
          else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: 'Agreement E-Sign Request Sent On Mail.',
              ...this.configSuccess,
            });
            }
          }
          else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
          }
        },
        (error) => {
          console.error("Error uploading file:", error);
        }
      );
  }
  checkStatusForEsignSanction() {
    this._QuickLoanService
      .DigioEsignStatusCheck({
        UploadId: this.loanSummary.Digio_Esign_Sanction_UploadId,
        LoanId: this.loanSummary.LoanId,
      })
      .subscribe(
        (response:any) => {
          console.log("File uploaded successfully!", response);

          if (response.length > 0 && response[0].CODE >= 0) {
            if (response[0].MSG == "completed") {
              this.loanSummary.Digio_Esign_Sanction_Status = "completed";
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: 'Sanction Letter E-Signed Succesfully.',
                ...this.configSuccess,
              });
            }
            else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: 'Sanction Letter E-Sign Request Sent On Mail.',
                ...this.configSuccess,
              });
            }
          }
          else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
          }
        },
        (error) => {
          console.error("Error uploading file:", error);
        }
      );
  }

  downloadESign() {
    this._QuickLoanService
      .DigioEsignDownload({
        UploadId: this.loanSummary.Digio_Esign_UploadId,
        LoanId: this.loanSummary.LoanId,
      })
      .subscribe((res: any) => {
        this.blobToBase64Async(res);
        this.saveFile(res, "LoanAgreement_" + this.loanSummary.ApplicationNo + "_" + this.loanSummary.Customer + ".pdf");
      });
  }
  downloadESignSanction() {
    this._QuickLoanService
      .DigioEsignDownload({
        UploadId: this.loanSummary.Digio_Esign_Sanction_UploadId,
        LoanId: this.loanSummary.LoanId,
      })
      .subscribe((res: any) => {
        this.blobToBase64Async(res);
        this.saveFile(res, "LoanSanction_" + this.loanSummary.ApplicationNo + "_" + this.loanSummary.Customer + ".pdf");
      });
  }
  blobToBase64Async(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      let $this = this;
      fileReader.onerror = (e) => reject(fileReader.error);

      fileReader.onload = function () {
        $this.dataUrl = fileReader.result.toString().split(";base64,").pop();

        return;
      };
      if (blob) {
        fileReader.readAsDataURL(blob);
      }
    });
  }

  saveFile(blob: Blob, fileName: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
  InitiateEVideoKYC() {
    this._QuickLoanService
      .DigioVideoKycRequest({
        EmailId: this.loanSummary.Customer_Email,
        CustomerName: this.loanSummary.Customer,
        LoanId: this.loanSummary.LoanId,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe(
        (response:any) => {
          if (response.length > 0 && response[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: ' E-Video Kyc Requested Successfully!!',
              ...this.configSuccess,
            });
            this.loanSummary.Digio_Video_Status = "requested";
            this.loanSummary.Digio_Video_UploadId = response[0].MSG;
          }
          else {
            console.log("File uploaded successfully!", response);
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
          }
        },
        (error) => {
          console.error("Error For Request:", error);
        }
      );
  }
  checkStatusForEVideoKYC() {
    this._QuickLoanService
      .DigioVideoKycStatusCheck({
        UploadId: this.loanSummary.Digio_Video_UploadId,
        LoanId: this.loanSummary.LoanId,
      })
      .subscribe(
        (response: any) => {
          console.log("File uploaded successfully!", response);

          if (response.length > 0 && response[0].CODE >= 0) {
          if (response[0].MSG == "approved") {
            this.loanSummary.Digio_Video_Status = "approved";
            this.loanSummary.Digio_Video_FileId = response[0].R_No;
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: 'Video KYC Approved Succesfully!!',
              ...this.configSuccess,
            });
          }
          else if (response[0].MSG == "approval_pending") {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: 'Approval Pending',
              ...this.configSuccess,
            });
          }
          else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: 'Video KYC Request Sent On Mail.',
              ...this.configSuccess,
            });
          }
        }
          else {
            console.log("File uploaded successfully!", response);
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
          }
        },
        (error) => {
          console.error("Error uploading file:", error);
        }
      );
  }
  downloadEVideoKYC() {
    this._QuickLoanService
      .DigioVideoKycDownload({
        UploadId: this.loanSummary.Digio_Video_FileId,
        LoanId: this.loanSummary.LoanId,
      })
      .subscribe((res: any) => {
        this.blobToBase64Async(res);
        this.saveFile(res, "Video KYC_" + this.loanSummary.ApplicationNo + "_" + this.loanSummary.Customer + ".webm");
      });
  }
  CancelForEsignSanction() {
    this._QuickLoanService
      .DigioEsignStatusCheck({
        UploadId: this.loanSummary.Digio_Esign_Sanction_UploadId,
        LoanId: this.loanSummary.LoanId,
      })
      .subscribe(
        (response:any) => {
          console.log("File uploaded successfully!", response);

          if (response.length > 0) {
            if (response[0].MSG == "completed") {
              this.loanSummary.Digio_Esign_Sanction_Status = "completed";
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: 'Sanction Letter E-Signed Succesfully.',
                ...this.configSuccess,
              });
            }
            else {
              this._QuickLoanService
                .DigioEsignCancel({
                  UploadId: this.loanSummary.Digio_Esign_Sanction_UploadId,
                  LoanId: this.loanSummary.LoanId,
                })
                .subscribe(
                  (res) => {
                    if (res[0].MSG == "expired")
                      this.loanSummary.Digio_Esign_Sanction_Status = "";
                    this.snackBar.openFromComponent(SnackbarComponent, {
                      data: 'Sanction Letter E-Signed Canceled.',
                      ...this.configSuccess,
                    });
                  })

            }
          }
        },
        (error) => {
          console.error("Error uploading file:", error);
        }
      );

  }

}
