<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="onSearchBusinessData()"
    class="formborder"
  >
    <div class="row m-0">
      <div class="col-md-2">
        <span class="required-lable"> From Date </span>

        <div class="datepicker_feild">
          <input
            required
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="FromDate"
            id="FromDate"
            #refFromDate="ngModel"
            class="form-control input-text-css"
            (dateChange)="DateChange()"
            [(ngModel)]="FromDate"
            [ngClass]="{
              'is-invalid': df.submitted && refFromDate.invalid,
              'alert-warning':
                refFromDate.invalid &&
                (refFromDate.dirty ||
                  refFromDate.touched ||
                  refFromDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
      <div class="col-md-2">
        <span class="required-lable"> To Date </span>

        <div class="datepicker_feild">
          <input
            required
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ToDate"
            id="ToDate"
            #refToDate="ngModel"
            class="form-control input-text-css"
            (dateChange)="DateChange()"
            [(ngModel)]="ToDate"
            [ngClass]="{
              'is-invalid': df.submitted && refToDate.invalid,
              'alert-warning':
                refToDate.invalid &&
                (refToDate.dirty || refToDate.touched || refToDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
      </div>

      <div class="col-md-2">
        <span> Source Type </span>
        <select
          name="SelectSourceType"
          id="SelectSourceType"
          #refSelectSourceType="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refSelectSourceType.invalid,
            'alert-warning':
              refSelectSourceType.invalid &&
              (refSelectSourceType.dirty ||
                refSelectSourceType.touched ||
                refSelectSourceType.untouched)
          }"
          (change)="Get_PartnerForDropdown()"
          [(ngModel)]="SelectSourceType"
          class="form-control input-text-css"
        >
          <option value="">Select Source Type</option>
          <option
            *ngFor="let lead of leadSourceDropdown"
            [value]="lead.Lead_Source"
          >
            {{ lead.Lead_Source }}
          </option>
        </select>
      </div>

      <div
        *ngIf="
          SelectSourceType == 'Agent' ||
          SelectSourceType == 'DSA' ||
          SelectSourceType == 'Dealer' ||
          SelectSourceType == 'Sales Executive' ||
          SelectSourceType == 'Telecaller Executive' ||
          SelectSourceType == 'Marketing Executive'
        "
      >
        <div>
          <span> Source Name</span>
          <select
            name="LeadSourceName"
            id="LeadSourceName"
            #refLeadSourceName="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refLeadSourceName.invalid,
              'alert-warning':
                refLeadSourceName.invalid &&
                (refLeadSourceName.dirty ||
                  refLeadSourceName.touched ||
                  refLeadSourceName.untouched)
            }"
            [(ngModel)]="LeadSourceName"
            class="form-control input-text-css"
          >
            <option value="">Select Lead Reference</option>
            <option
              *ngFor="let partner of partnerDropdown"
              [value]="partner.PartnerId"
            >
              {{ partner.Partner_Name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-2">
        <button
          type="button"
          (click)="onSearchBusinessData()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>
      <div class="col-md-4 mt-3">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>

  <div class="row mt-3" [hidden]="!DataSource">
    <div class="col-md-12">
      <div class="table-responsive table-striped">
        <table
          class="table-bordered"
          matSort
          mat-table
          [dataSource]="DataSource"
          style="max-width: 100%; overflow: auto"
        >
          <ng-container
            [matColumnDef]="col"
            *ngFor="let col of displayedColumns"
          >
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{
                col == "Id"
                  ? "#"
                  : col == "LoanAcNo"
                  ? "Loan No"
                  : col == "CustomerName"
                  ? "Customer Name"
                  : col == "Customer_PhoneNo"
                  ? "Phone No"
                  : col == "LatLong"
                  ? "Lat Long"
                  : col == "Co"
                  ? "C/O"
                  : col == "Co_name"
                  ? "C/O Name"
                  : col == "Co_Borrower"
                  ? "Co_Borrower Name"
                  : col == "Co_Borrower_PhoneNo"
                  ? "Co_Borrower PhoneNo"
                  : col == "Co_BorrowerAddress"
                  ? "Co_Borrower Address"
                  : col == "Guarantor"
                  ? "Guarantor Name"
                  : col == "Guarantor_PhoneNo"
                  ? "Guarantor PhoneNo"
                  : col == "GuarantorAddress"
                  ? "Guarantor Address"
                  : col == "LoanAmount"
                  ? "Loan Amount"
                  : col == "LoanSegment"
                  ? "Loan Segment"
                  : col == "Application_InterestAmt"
                  ? "Interest Amt"
                  : col == "Application_AgreementValue"
                  ? "Agreement Value"
                  : col == "Application_LoanEMIAmount"
                  ? "EMI Amount"
                  : col == "Application_LoanTenure"
                  ? "Tenure"
                  : col == "LTV"
                  ? "LTV"
                  : col == "Application_Case_IRR"
                  ? "Case IRR"
                  : col == "Application_Disbursement_IRR"
                  ? "Disb. IRR"
                  : col == "Application_ExpiryDate"
                  ? "Expiry Date"
                  : col == "LoanClosed_Date"
                  ? "Closed Date"
                  : col == "Application_AdvInstl"
                  ? "Adv EMI"
                  : col == "SourceType"
                  ? "Source Type"
                  : col == "SourceName"
                  ? "Source Name"
                  : col == "Product_Name"
                  ? "Loan Product"
                  : col == "SourceArea"
                  ? "Source Area"
                  : col == "SalesExecutive"
                  ? "Sales Executive"
                  : col == "CollectionExecutive"
                  ? "Collection Executive"
                  : col == "CollectionArea"
                  ? "Collection Area"
                  : col == "CollectionSubarea"
                  ? "Collection Sub Area"
                  : col == "Application_LoanPurpose"
                  ? "Loan Purpose"
                  : col == "Loan_Acc_Category"
                  ? "Category"
                  : col == "SourceArea"
                  ? "Source Area"
                  : col.split("_").join(" ")
              }}
            </th>
            <td mat-cell *matCellDef="let row; let i = index" class="grid-cell">
              <div *ngIf="col == 'CustomerName'; else elseBlock">
                <app-lms-customer-view-model
                  [customerId]="row.CustomerId"
                  [index]="i"
                >
                  {{ row.CustomerName }}
                </app-lms-customer-view-model>
              </div>
              <ng-template #elseBlock>
                <div *ngIf="col == 'Id'; else elsedivmain">
                  {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
                </div>
                <ng-template #elsedivmain>
                  <div *ngIf="col == 'No_of_Ref'; else elsediv">
                    <div *ngIf="row[col] > 0; else elsediv1">
                      <a
                        href="javascript:void(0)"
                        (click)="onClickCustomerRefData(row)"
                        ><B>{{ row[col] }}</B></a
                      >
                    </div>
                    <ng-template #elsediv1>{{ row[col] }}</ng-template>
                  </div>
                  <ng-template #elsediv>
                    <div *ngIf="col == 'SPDC_Cheque'; else elsediv_ch1">
                      <div *ngIf="row[col] > 0; else elsediv_ch2">
                        <a
                          href="javascript:void(0)"
                          (click)="onClickChequeData(row, 1)"
                          ><B>{{ row[col] }}</B></a
                        >
                      </div>
                      <ng-template #elsediv_ch2>{{ row[col] }}</ng-template>
                    </div>
                    <ng-template #elsediv_ch1>
                      <div *ngIf="col == 'RPDC_Cheque'; else elsediv_ch3">
                        <div *ngIf="row[col] > 0; else elsediv_ch4">
                          <a
                            href="javascript:void(0)"
                            (click)="onClickChequeData(row, 2)"
                            ><B>{{ row[col] }}</B></a
                          >
                        </div>
                        <ng-template #elsediv_ch4>{{ row[col] }}</ng-template>
                      </div>
                      <ng-template #elsediv_ch3>
                        <div
                          *ngIf="col == 'Primary_Assets_Type'; else elsediv_ch5"
                        >
                          <div *ngIf="row[col] != ''; else elsediv_ch6">
                            <a
                              href="javascript:void(0)"
                              (click)="onClickAssetsData(row, 1)"
                              >{{ row[col] }}</a
                            >
                          </div>
                          <ng-template #elsediv_ch6>{{ row[col] }}</ng-template>
                        </div>
                        <ng-template #elsediv_ch5>
                          <div
                            *ngIf="
                              col == 'Additional_Assets_Type';
                              else elsedivMask1
                            "
                          >
                            <div *ngIf="row[col] != ''; else elsediv_ch8">
                              <a
                                href="javascript:void(0)"
                                (click)="onClickAssetsData(row, 2)"
                                >{{ row[col] }}</a
                              >
                            </div>
                            <ng-template #elsediv_ch8>
                              {{ row[col] }}
                            </ng-template>
                          </div>
                          <ng-template #elsedivMask1>
                            <div
                              *ngIf="
                                col == 'Customer_PhoneNo';
                                else elsedivMask3
                              "
                            >
                              <div
                                *ngIf="
                                  row[col] != '' && currentUser.IsMaskingMobile;
                                  else elsedivMask2
                                "
                              >
                                {{ row[col] | mask }}
                              </div>
                              <ng-template #elsedivMask2>
                                {{ row[col] }}
                              </ng-template>
                            </div>
                            <ng-template #elsedivMask3>
                              <div
                                *ngIf="
                                  col == 'Co_Borrower_PhoneNo';
                                  else elsedivMask5
                                "
                              >
                                <div
                                  *ngIf="
                                    row[col] != '' &&
                                      currentUser.IsMaskingMobile;
                                    else elsedivMask4
                                  "
                                >
                                  {{ row[col] | mask }}
                                </div>
                                <ng-template #elsedivMask4>
                                  {{ row[col] }}
                                </ng-template>
                              </div>
                              <ng-template #elsedivMask5>
                                <div
                                  *ngIf="
                                    col == 'Guarantor_PhoneNo';
                                    else elsediv_ch7
                                  "
                                >
                                  <div
                                    *ngIf="
                                      row[col] != '' &&
                                        currentUser.IsMaskingMobile;
                                      else elsedivMask6
                                    "
                                  >
                                    {{ row[col] | mask }}
                                  </div>
                                  <ng-template #elsedivMask6>
                                    {{ row[col] }}
                                  </ng-template>
                                </div>

                                <ng-template #elsediv_ch7>
                                  <div
                                    [ngClass]="
                                      col == 'LoanAmount' ||
                                      col == 'Application_InterestAmt' ||
                                      col == 'Application_AgreementValue' ||
                                      col == 'Application_LoanEMIAmount' ||
                                      col == 'No_of_EMI' ||
                                      col == 'Application_LoanTenure' ||
                                      col == 'LTV' ||
                                      col == 'ROI' ||
                                      col == 'Application_Case_IRR' ||
                                      col == 'Application_Disbursement_IRR'
                                        ? 'text-right'
                                        : 'text-left'
                                    "
                                  >
                                    {{ row[col] }}
                                  </div>
                                </ng-template>
                              </ng-template>
                            </ng-template>
                          </ng-template>
                        </ng-template>
                      </ng-template>
                    </ng-template>
                  </ng-template>
                </ng-template>
              </ng-template>
            </td>
            <th
              mat-footer-cell
              *matFooterCellDef
              class="grid-header"
              [ngClass]="
                col == 'LoanAmount' ||
                col == 'Application_InterestAmt' ||
                col == 'Application_AgreementValue' ||
                col == 'Application_LoanEMIAmount' ||
                col == 'No_of_EMI' ||
                col == 'Application_LoanTenure' ||
                col == 'LTV' ||
                col == 'ROI' ||
                col == 'Application_Case_IRR' ||
                col == 'Application_Disbursement_IRR'
                  ? 'text-right'
                  : col == 'Co_name'
                  ? 'text-center'
                  : 'text-left'
              "
            >
              {{
                col == "LoanAmount"
                  ? totaltypewise("LoanAmount")
                  : col == "Co_name"
                  ? "Total"
                  : col == "Application_InterestAmt"
                  ? DataSource
                    ? totaltypewise("Application_InterestAmt")
                    : ""
                  : col == "Application_AgreementValue"
                  ? DataSource
                    ? totaltypewise("Application_AgreementValue")
                    : ""
                  : col == "Application_LoanEMIAmount"
                  ? DataSource
                    ? totaltypewise("Application_LoanEMIAmount")
                    : ""
                  : col == "LTV"
                  ? DataSource
                    ? ""
                    : ""
                  : ""
              }}
            </th>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            (click)="highlightRow(row)"
            [class.highlight]="row.Loan_Id == selectedId"
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
          <tr
            mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"
          ></tr>
        </table>
      </div>
      <div class="row m-0 pt-2 pb-1" [hidden]="!DataSource">
        <div class="col-md-4">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportexcelNew()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
        <div class="col-md-8 text-right">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="ViewCustomerChqAssetDetails"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div
      cdkDrag
      cdkDragRootElement=".cdk-overlay-pane"
      cdkDragHandle
      class="modal-content"
    >
      <div class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          {{ ModelTitle }}
        </h6>
        <button
          type="button"
          (click)="onCloseBookingList()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <mat-table
            [dataSource]="DataSourceModel"
            matSort
            matTableExporter
            #exporter="matTableExporter"
            style="height: 400px; max-width: 100%; overflow: auto"
          >
            <ng-container
              [matColumnDef]="col"
              *ngFor="let col of displayedColumnsModel"
            >
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                {{
                  col == "Id"
                    ? "#"
                    : col == "ReferenceType"
                    ? "Neighbour"
                    : col == "Customer"
                    ? "Relation With"
                    : col == "KnowYear"
                    ? "Association"
                    : col.split("_").join(" ")
                }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
                <div *ngIf="col == 'View_Detail'; else elsediv_btn">
                  <button
                    type="button"
                    (click)="ViewData(row)"
                    class="font-size-12 button-btn"
                  >
                    View
                  </button>
                </div>
                <ng-template #elsediv_btn>
                  {{
                    col == "Id"
                      ? paginatorlist.pageIndex * paginatorlist.pageSize +
                        (i + 1)
                      : row[col]
                  }}
                </ng-template>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumnsModel; sticky: true"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumnsModel"
            ></mat-row>
          </mat-table>
          <div class="row m-0 align-items-center">
            <div class="col-md-4">
              <button
                class="btn font-size-12 button-btn"
                (click)="exportTable()"
              >
                <i
                  class="fa fa-file-export mr-1"
                  style="font-size: medium; cursor: pointer"
                ></i>
                Export
              </button>
            </div>
            <div class="col-md-8">
              <mat-paginator
                #paginatorlist
                [pageSize]="20"
                [pageSizeOptions]="[20, 50]"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="View"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div
      cdkDrag
      cdkDragRootElement=".cdk-overlay-pane"
      cdkDragHandle
      class="modal-content"
    >
      <div class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          {{ Collateral_Type }} [{{ Collateral }}]
        </h6>
        <button
          type="button"
          (click)="onCloseView()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="Collateral == 'Vehicle'">
          <div class="row m-0 mt-3" *ngIf="Info">
            <div class="col-md-12">
              <h1 class="fs-12 h-b">{{ Info.Vehicle_SubType }}</h1>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Vehicle Manufacture123</h6>
              <p class="lead fs-12">{{ Info.VehicleManufacture }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Vehicle Category</h6>
              <p class="lead fs-12">{{ Info.VehicleCategory }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Vehicle Model Name</h6>
              <p class="lead fs-12">{{ Info.VehicleModelName }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Variant</h6>
              <p class="lead fs-12">{{ Info.Variant }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Manufacture Year</h6>
              <p class="lead fs-12">{{ Info.MefgYear }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Vehicle Registation No</h6>
              <p class="lead fs-12">{{ Info.VehicleRegistationNo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Registation Date</h6>
              <p class="lead fs-12">{{ Info.RegistationDate }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Registation Expiry Date</h6>
              <p class="lead fs-12">{{ Info.RegistationExpiryDate }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Fuel Type</h6>
              <p class="lead fs-12">{{ Info.FuelType }}</p>
            </div>
            <div class="col-md-2" *ngIf="Info.Vehicle_SubType == 'Commercial'">
              <h6 class="fs-12">Road Tax Upto</h6>
              <p class="lead fs-12">{{ Info.RoadTaxUpto }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Color</h6>
              <p class="lead fs-12">{{ Info.Color }}</p>
            </div>
            <div class="col-md-2" *ngIf="Info.Vehicle_SubType == 'Commercial'">
              <h6 class="fs-12">Fitness Upto</h6>
              <p class="lead fs-12">{{ Info.FitnessUpto }}</p>
            </div>
            <div class="col-md-2" *ngIf="Info.Vehicle_SubType == 'Commercial'">
              <h6 class="fs-12">Permit Upto</h6>
              <p class="lead fs-12">{{ Info.PermitUpto }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Vehicle Cost</h6>
              <p class="lead fs-12">{{ Info.VehicleCost }}</p>
            </div>
            <div class="col-md-2" *ngIf="Info.Vehicle_SubType == 'Commercial'">
              <h6 class="fs-12">Route</h6>
              <p class="lead fs-12">{{ Info.Rout }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Engine No</h6>
              <p class="lead fs-12">{{ Info.EngineNo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Chassis No</h6>
              <p class="lead fs-12">{{ Info.ChassisNo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Key No</h6>
              <p class="lead fs-12">{{ Info.KeyNo }}</p>
            </div>
          </div>
          <div class="row m-0 mt-3" *ngIf="Info.Vehicle_Type == 'Used'">
            <div class="col-md-12">
              <h1 class="fs-12 h-b">{{ Info.Vehicle_Type }} Vehicle</h1>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuation Done By</h6>
              <p class="lead fs-12">{{ Info.ValuationDoneBy }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuation Date</h6>
              <p class="lead fs-12">{{ Info.ValuationDate }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuation Amount</h6>
              <p class="lead fs-12">{{ Info.ValuationAmount }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuation Type</h6>
              <p class="lead fs-12">{{ Info.ValuationType }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Other Valuation Type</h6>
              <p class="lead fs-12">{{ Info.OtherValuationType }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Other Valuation Amount</h6>
              <p class="lead fs-12">{{ Info.OtherValuationAmt }}</p>
            </div>
          </div>
          <div class="row m-0 mt-3" *ngIf="Info.Vehicle_Type == 'New'">
            <div class="col-md-12">
              <h1 class="fs-12 h-b">{{ Info.Vehicle_Type }} Vehicle</h1>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">DO. No.</h6>
              <p class="lead fs-12">{{ Info.DONo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">DO. Do Date</h6>
              <p class="lead fs-12">{{ Info.DODoDate }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Estimation Amount</h6>
              <p class="lead fs-12">{{ Info.EstimationAmount }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Invoice No.</h6>
              <p class="lead fs-12">{{ Info.InvoiceNo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Invoice Date</h6>
              <p class="lead fs-12">{{ Info.InvoiceDate }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Invoice Value</h6>
              <p class="lead fs-12">{{ Info.InvoiceValue }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Dealer Contact Person</h6>
              <p class="lead fs-12">{{ Info.DealerContactPerson }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Dealer Contact No.</h6>
              <p class="lead fs-12">{{ Info.DealerContactNo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Quotation in favour of</h6>
              <p class="lead fs-12">{{ Info.DOIssuedinfavourof }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Remark</h6>
              <p class="lead fs-12">{{ Info.Remark }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Dealer</h6>
              <p class="lead fs-12">{{ Info.DealerId }}</p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="Collateral == 'Gold'">
          <div class="table-responsive mt-4 mb-4">
            <mat-table [dataSource]="dataviewAssetItemSource">
              <ng-container matColumnDef="Id">
                <mat-header-cell
                  class="grid-header"
                  style="max-width: 50px"
                  *matHeaderCellDef
                  >#</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell"
                  style="max-width: 50px; max-height: 5px"
                >
                  {{ i + 1 }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="ItemName">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Item Name</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.ItemName }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="ItemType">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Item Type</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.ItemType }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Qty">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Qty</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Qty }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="PurityCT">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Purity CT</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.PurityCT }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="GrossWt">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Gross Wt</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.GrossWt }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="StoneWt">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Stone Wt</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.StoneWt }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="OtherWt">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Other Wt</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.OtherWt }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="ValulationRate_PerCT">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Rate</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.ValulationRate_PerCT }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="ValulationAmount">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Amount</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.ValulationAmount }}
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedViewAssetItemColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedViewAssetItemColumns"
              ></mat-row>
            </mat-table>
          </div>
          <div class="row m-0 mt-3" *ngIf="Info">
            <div class="col-md-2">
              <h6 class="fs-12">Valution Done By</h6>
              <p class="lead fs-12">{{ Info.ValulationDoneBy }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valution Date</h6>
              <p class="lead fs-12">{{ Info.ValulationDate }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Total Valution Amount</h6>
              <p class="lead fs-12">{{ Info.TotalValulationAmount }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuer PhoneNo</h6>
              <p class="lead fs-12">{{ Info.ValuerPhoneNo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuer FirmName</h6>
              <p class="lead fs-12">{{ Info.ValuerFirmName }}</p>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="Collateral == 'Property'">
          <div class="row m-0 mt-3" *ngIf="Info">
            <div class="col-md-2">
              <h6 class="fs-12">Property Owner Name</h6>
              <p class="lead fs-12">{{ Info.CustomerId }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Address Of Property</h6>
              <p class="lead fs-12">{{ Info.Address }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">State</h6>
              <p class="lead fs-12">{{ Info.State }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">District</h6>
              <p class="lead fs-12">{{ Info.District }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Tehsil</h6>
              <p class="lead fs-12">{{ Info.tasil }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Type of Property</h6>
              <p class="lead fs-12">{{ Info.TypeofProperty }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Nature of Property</h6>
              <p class="lead fs-12">{{ Info.NatureofProperty }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Ownership Type</h6>
              <p class="lead fs-12">
                {{ Info.OwnershipType }}
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Ownership Document</h6>
              <p class="lead fs-12">{{ Info.OwnershipDocument }}</p>
            </div>

            <div class="col-md-2">
              <h6 class="fs-12">UnitofMeasurement</h6>
              <p class="lead fs-12">{{ Info.UnitofMeasurement }}</p>
            </div>

            <div class="col-md-2">
              <h6 class="fs-12">Total Area</h6>
              <p class="lead fs-12">{{ Info.TotalArea }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Constructed Area</h6>
              <p class="lead fs-12">{{ Info.ConstructedArea }}</p>
            </div>

            <div class="col-md-2">
              <h6 class="fs-12">Type of Mortgage</h6>
              <p class="lead fs-12">{{ Info.TypeofMortgage }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Mortgage Singed By</h6>
              <p class="lead fs-12">{{ Info.MortgageSingedBy }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuation Amount</h6>
              <p class="lead fs-12">{{ Info.ValuationAmount }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuation Done By</h6>
              <p class="lead fs-12">{{ Info.ValuationDoneBy }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuation Remark</h6>
              <p class="lead fs-12">{{ Info.ValuationRemark }}</p>
            </div>
            <div
              class="col-md-2"
              *ngIf="
                Info.ValuationDoneByID != '' && Info.ValuationDoneByID == 0
              "
            >
              <h6 class="fs-12">Valution Done By Name</h6>
              <p class="lead fs-12">{{ Info.ValuationDoneByOther }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Search Report Done By</h6>
              <p class="lead fs-12">{{ Info.SearchReportDoneBy }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Search Report Remark</h6>
              <p class="lead fs-12">{{ Info.SearchReportRemark }}</p>
            </div>
            <div
              class="col-md-2"
              *ngIf="
                Info.SearchReportDoneByID != '' &&
                Info.SearchReportDoneByID == 0
              "
            >
              <h6 class="fs-12">Search Report Done By Name</h6>
              <p class="lead fs-12">{{ Info.SearchReportDoneByOther }}</p>
            </div>

            <div class="col-md-2">
              <h6 class="fs-12">GeoLocation</h6>
              <p class="lead fs-12">
                <a
                  target="_blank"
                  *ngIf="
                    Info.GeoLocation != '' &&
                    Info.GeoLocation != null &&
                    Info.GeoLocation != ','
                  "
                  [href]="'http://maps.google.com/maps?q=' + Info.GeoLocation"
                >
                  <i class="fa-solid fa-location-dot"></i>
                </a>
              </p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<table
  id="booking_list"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Category
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Phone No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Address
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Lat Long
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">C/O</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      C/O Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Co-Borrower Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Co-Borrower Number
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      C0-Borrower Address
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Guarantor Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Guarantor Number
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Guarantor Address
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Segment
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Agreement Value
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      No of EMI
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Tenure
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">LTV</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">ROI</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Case IRR
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Disb IRR
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Due Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Expiry Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Closed Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Adv EMI
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Frequency
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      No of Ref
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Primary Asset Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Additional Assets Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Area
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Sales Executive
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collection Executive
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collection Area
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collection Sub Area
    </th>
    <!-- <th style="background: #4dc3a3 !important; border: 1px solid white">
      Disbursment Bank
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Disbursment Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Disbursment Amount
    </th> -->
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      SPDC Bank
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      SPDC Cheque
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      RPDC Bank
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      RPDC Cheque
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Purpose
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Structure
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.Application_Date }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.Loan_Acc_Category }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>
      <div *ngIf="currentUser.IsMaskingMobile">
        {{ x.Customer_PhoneNo | mask }}
      </div>

      <div *ngIf="!currentUser.IsMaskingMobile">
        {{ x.Customer_PhoneNo }}
      </div>
    </td>
    <td>{{ x.Address }}</td>
    <td>{{ x.LatLong }}</td>
    <td>{{ x.Co }}</td>
    <td>{{ x.Co_name }}</td>
    <td>{{ x.Co_Borrower }}</td>
    <td>{{ x.Co_Borrower_PhoneNo }}</td>
    <td>{{ x.Co_BorrowerAddress }}</td>
    <td>{{ x.Guarantor }}</td>
    <td>{{ x.Guarantor_PhoneNo }}</td>
    <td>{{ x.GuarantorAddress }}</td>
    <td>{{ x.LoanSegment }}</td>
    <td>{{ x.LoanAmount }}</td>
    <td>{{ x.Application_InterestAmt }}</td>
    <td>{{ x.Application_AgreementValue }}</td>
    <td>{{ x.Application_LoanEMIAmount }}</td>
    <td>{{ x.No_of_EMI }}</td>
    <td>{{ x.Application_LoanTenure }}</td>
    <td>{{ x.LTV }}</td>

    <td>{{ x.ROI }}</td>
    <td>{{ x.Application_Case_IRR }}</td>
    <td>{{ x.Application_Disbursement_IRR }}</td>
    <td>{{ x.Due_Date }}</td>
    <td>{{ x.Application_ExpiryDate }}</td>
    <td>{{ x.LoanClosed_Date }}</td>
    <td>{{ x.Application_AdvInstl }}</td>
    <td>{{ x.EMI_Frexvency }}</td>
    <td>{{ x.No_of_Ref }}</td>
    <td>{{ x.Primary_Assets_Type }}</td>
    <td>{{ x.Additional_Assets_Type }}</td>
    <td>{{ x.SourceType }}</td>
    <td>{{ x.SourceName }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.SourceArea }}</td>
    <td>{{ x.SalesExecutive }}</td>
    <td>{{ x.CollectionExecutive }}</td>
    <td>{{ x.CollectionArea }}</td>
    <td>{{ x.CollectionSubarea }}</td>
    <!-- <td>{{ x.Disbursment_Bank }}</td>
    <td>{{ x.Disbursment_Branch }}</td>
    <td>{{ x.Disbursment_Amount }}</td> -->
    <td>{{ x.SPDC_Bank }}</td>
    <td>{{ x.SPDC_Cheque }}</td>
    <td>{{ x.RPDC_Bank }}</td>
    <td>{{ x.RPDC_Cheque }}</td>
    <td>{{ x.Application_LoanPurpose }}</td>
    <td>{{ x.EMI_Structure }}</td>
  </tr>
  <tfoot>
    <tr>
      <td
        colspan="19"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("LoanAmount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Application_InterestAmt") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Application_AgreementValue") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Application_LoanEMIAmount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("No_of_EMI") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Application_LoanTenure") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("ROI") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("Application_Case_IRR") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <!-- <strong>{{ totaltypewise("Application_Disbursement_IRR") }}</strong> -->
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>

      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
      <td style="background: #4dc3a3 !important; border: 1px solid black"></td>
    </tr>
  </tfoot>
</table>
