import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../services/lms.service";
import { Router } from "@angular/router";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
import { LosService } from "src/app/_LOS/services/los.service";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
declare var $: any;

@Component({
  selector: "app-lms-dv-list",
  templateUrl: "./dv-list.component.html",
  styleUrls: ["./dv-list.component.scss"],
})
export class LMSDvListComponent implements OnInit {
  currentUser: any;
  dvList: any[] = [];
  dvrevertedList: any[] = [];
  showSpinner: boolean = false;
  status: any = "Pending";
  dataSource: any;
  dataSource1: any;
  displayedColumns: string[] = [];
  displayedColumns1: string[] = [];

  SelectSourceType: any = "";
  partnerDropdown: any;
  SalesExecutiveData: any;
  LeadSourceName: any;
  leadSourceDropdown: any;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("paginatorlist", { read: MatPaginator, static: true })
  paginatorlist: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private router: Router,
    private _EncrDecrService: EncrDecrService,
    private lmsService: LmsService,
    private losService: LosService
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Disbursement Voucher List");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.LMS_Get_DV_List();
    this.Get_PartnerForDropdown();
    this.getLeadSourceDropdown();
    this.GetSalesExecutive();
  }

  getLeadSourceDropdown() {
    this.showSpinner = true;
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
      console.log("Lead Source Dropdown", this.leadSourceDropdown);
      this.showSpinner = false;
    });
  }

  Get_PartnerForDropdown() {
    console.log("Partner Dropdown API Function");

    if (
      this.SelectSourceType == "Agent" ||
      this.SelectSourceType == "DSA" ||
      this.SelectSourceType == "Dealer"
    ) {
      this.losService
        .Get_PartnerForDropdown({
          PartnerType: this.SelectSourceType,
          branchId: 0,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = res;
        });
      this.LeadSourceName = 0;
      this.LMS_Get_DV_List();
    } else if (
      this.SelectSourceType == "Sales Executive" ||
      this.SelectSourceType == "Telecaller Executive" ||
      this.SelectSourceType == "Marketing Executive"
    ) {
      this.losService
        .getEmployeeDropdown({
          BranchId: 0,
          ProcessId: 1,
          ProductId: 0,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = [];
          this.SalesExecutiveData.forEach((obj: any) => {
            this.partnerDropdown.push({
              PartnerId: obj.EmpId,
              Partner_Name: obj.EmpName,
            });
          });
        });

      this.LeadSourceName = 0;
      this.LMS_Get_DV_List();
    } else {
      this.LeadSourceName = "";
      this.LMS_Get_DV_List();
    }
  }

  GetSalesExecutive() {
    this.losService
      .getEmployeeDropdown({
        BranchId: 0,
        ProcessId: 1,
        ProductId: 0,
      })
      .subscribe((res: any) => {
        // this.employeeDropdown = res;
        this.SalesExecutiveData = res;
        this.Get_PartnerForDropdown();
        //console.log('res', res);
      });
  }

  LMS_Get_DV_List() {
    this.showSpinner = true;
    if (this.status == "Pending") {
      this.displayedColumns = [
        "LoanId",
        "ApplicationNo",
        "LoanNo",
        "LoanDate",
        "Customer",
        "Branch",
        "Product",
        "LoanAmount",
        "CreatedBy",
        "SourceType",
        "SourceName",
        "Action",
      ];
      this.displayedColumns1 = [
        "LoanId",
        "ApplicationNo",
        "LoanNo",
        "LoanDate",
        "Customer",
        "Branch",
        "Product",
        "LoanAmount",
        "CreatedBy",

        "RevertedBy",
        "Action",
      ];
    } else {
      this.displayedColumns = [
        "LoanId",
        "ApplicationNo",
        "LoanNo",
        "LoanDate",
        "Customer",
        "Branch",
        "Product",
        "LoanAmount",
        "VoucherNo",
        "VoucherDate",
        "CreatedBy",
        "SourceType",
        "SourceName",
        "Action",
      ];
      this.displayedColumns1 = [
        "LoanId",
        "ApplicationNo",
        "LoanNo",
        "LoanDate",
        "Customer",
        "Branch",
        "Product",
        "LoanAmount",
        "VoucherNo",
        "VoucherDate",
        "CreatedBy",
        "RevertedBy",

        "Action",
      ];
    }
    this.lmsService
      .LMS_Get_DV_List({
        LoanStatus: this.status,
        LoginUserId: this.currentUser.userId,
        SourceType: this.SelectSourceType || "",
        SourceId: this.LeadSourceName || 0,
      })
      .subscribe((res: any) => {
        // if (res.length >= 0) {
        this.dvList = JSON.parse(JSON.stringify(res.Item1));
        this.dataSource = new MatTableDataSource(this.dvList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;

        this.dvrevertedList = JSON.parse(JSON.stringify(res.Item2));
        this.dataSource1 = new MatTableDataSource(this.dvrevertedList);
        this.dataSource1.sort = this.sort;
        this.dataSource1.paginator = this.paginatorlist;
        this.showSpinner = false;
        // } else {
        //   this.snackBar.openFromComponent(SnackbarComponent, {
        //     data: "No data found",
        //     ...this.configSuccess,
        //   });
        //   this.showSpinner = false;
        //   this.dataSource = null;
        //   this.dataSource1 = null;
        // }
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  goToDetail(data: any) {
    // var inquiryId = this.encdec.encrypt(data.InquiryId);

    this.router.navigate([
      `/lms/dvdetail/${encodeURIComponent(data.LoanId)}/${encodeURIComponent(
        this._EncrDecrService.encrypt(data.VoucherId)
      )}`,
    ]);
  }
  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("dv_list") as HTMLTableElement;
    const worksheetName = "DV LIST"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  exportTableReverted(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "reverted_dv_list"
    ) as HTMLTableElement;
    const worksheetName = "REVERTED DV LIST"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
