import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import {
  BsDatepickerModule,
  BsDatepickerConfig,
} from "ngx-bootstrap/datepicker";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { PopoverModule } from "ngx-bootstrap/popover";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DatePipe } from "@angular/common";
import { MatTableModule } from "@angular/material/table";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AppComponent } from "./app.component";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import {
  MatRadioModule,
  MatRadioChange,
  MatRadioButton,
} from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarModule,
} from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { LoginComponent } from "./Login/app.LoginComponent";
import { AppUserLayoutComponent } from "./_LOS/_layout/app-userlayout.component";
import { AdminLogoutComponent } from "./Login/app.AdminLogout.Component";
import { UserLogoutComponent } from "./Login/app.UserLogout.Component";
import { AdminAuthGuardService } from "./AuthGuard/AdminAuthGuardService";
import { UserAuthGuardService } from "./AuthGuard/UserAuthGuardService";
import { AuthGuardService } from "./AuthGuard/AuthGuardService";
import { DataSharingService } from "./AuthGuard/DataSharingService";
import { EncrDecrService } from "./AuthGuard/EncrDecrService";
import { ComingsoonComponent } from "./comingsoon/comingsoon.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSpinnerOverlayComponent } from "./mat-spinner-overlay/mat-spinner-overlay.component";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";
import { ButtonComponent } from "./CommonComponent/button/button.component";
import { SnackbarComponent } from "./snackbar/snackbar.component";
import { MatConfirmDialogComponent } from "./mat-confirm-dialog/mat-confirm-dialog.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { MatDialogComponent } from "./mat-dialog/mat-dialog.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { BsModalRef, BsModalService, ModalModule } from "ngx-bootstrap/modal";

import { PhoneMaskDirective } from "./phone-mask.directive";
import { HomePageComponent } from "./home-page/home-page.component";
import { IndexpageComponent } from "./indexpage/indexpage.component";
import { CommonheaderComponent } from "./commonheader/commonheader.component";
import { MstLoanProcessComponent } from "./_Configuration/mst-loan-process/mst-loan-process.component";
import { MstBranchesComponent } from "./_Configuration/mst-branches/mst-branches.component";
import { MstemployeelistComponent } from "./_Configuration/mstemployeelist/mstemployeelist.component";
import { AgmCoreModule } from "@agm/core";
//import { MstemployeenewregisterComponent } from './_Configuration/mstemployeenewregister/mstemployeenewregister.component';

import { MstZoneListComponent } from "./_Configuration/mst-zone-list/mst-zone-list.component";
import { MstDistrictListComponent } from "./_Configuration/mst-district-list/mst-district-list.component";
import { MstTahsilListComponent } from "./_Configuration/mst-tahsil-list/mst-tahsil-list.component";

import { LosAppUserLayoutComponent } from "./_LOS/los-app-user-layout/los-app-user-layout.component";

import { ProductworkflowComponent } from "./_Configuration/productworkflow/productworkflow.component";

import { MstProductListComponent } from "./_Configuration/mst-product-list/mst-product-list.component";
import { MstLoanDocumentComponent } from "./_Configuration/mst-loan-document/mst-loan-document.component";
import { MstBranchEmployeeComponent } from "./_Configuration/mst-branch-employee/mst-branch-employee.component";
import { MstDocumentsComponent } from "./_Configuration/mst-documents/mst-documents.component";
import { MstStateListComponent } from "./_Configuration/mst-state-list/mst-state-list.component";
import { MstLoanChargesComponent } from "./_Configuration/mst-loan-charges/mst-loan-charges.component";
import { QuestionsFiComponent } from "./_Configuration/questions-fi/questions-fi.component";
import { QuestionsTVRComponent } from "./_Configuration/questions-tvr/questions-tvr.component";
import { MstemployeeswapComponent } from "./_Configuration/mstemployeeswap/mstemployeeswap.component";
import { MstemployeehierarchyComponent } from "./_Configuration/mstemployeehierarchy/mstemployeehierarchy.component";

import { LosSearchCustomerComponent } from "./_Lms/los-search-customer/los-search-customer.component";
import { ChargesheadComponent } from "./_Configuration/chargeshead/chargeshead.component";
import { CreditpolicyComponent } from "./_Configuration/creditpolicy/creditpolicy.component";

import { RptssrsComponent } from "./_Configuration/rptssrs/rptssrs.component";
import { SystemconfigurationComponent } from "./_Configuration/systemconfiguration/systemconfiguration.component";
import { SearchapplicationComponent } from "./_Configuration/searchapplication/searchapplication.component";

import { MstcourierComponent } from "./_Configuration/mstcourier/mstcourier.component";
import { RptchargecollectionComponent } from "./_Configuration/rptchargecollection/rptchargecollection.component";
import { ProductassetdetailComponent } from "./_Configuration/productassetdetail/productassetdetail.component";

import { MatTableExporterModule } from "mat-table-exporter";
import {
  NumberDirective,
  MaxLengthDirective,
  DateConvertDirective,
  AlphaDirective,
  TwoDigitDecimaNumberDirective,
  AlphaNumericDirective,
} from "./directives/only-number.directive";
import { MstPartnerComponent } from "./_Configuration/mst-partner/mst-partner.component";
import { MstschemeComponent } from "./_Configuration/mstscheme/mstscheme.component";
import { PrintsectionletterComponent } from "./_Configuration/printsectionletter/printsectionletter.component";
import { PrintwelcomeletterComponent } from "./_Configuration/printwelcomeletter/printwelcomeletter.component";

import { LosCustomerViewModelComponent } from "./_LOS/los-customer-view-model/los-customer-view-model.component";
import { LoginfeereceiptComponent } from "./CommonComponent/loginfeereceipt/loginfeereceipt.component";
import { RptHoldInquiryComponent } from "./_Configuration/rpt-hold-inquiry/rpt-hold-inquiry.component";
import { SanctionletterTemplateComponent } from "./_Configuration/sanctionletter-template/sanctionletter-template.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { LosPendingDocumentApplicationListComponent } from "./_LOS/los-pending-document-application-list/los-pending-document-application-list.component";
import { LosPendingDocumentListComponent } from "./_LOS/los-pending-document-list/los-pending-document-list.component";

import { InterceptorService } from "./http-interceptor.service";
import { AppPageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { RptuserlogComponent } from "./_Configuration/rptuserlog/rptuserlog.component";
import { MstRoleComponent } from "./_Configuration/mst-role/mst-role.component";

//// LMS components starts
import { LMSDashboardComponent } from "./_Lms/dashboard/dashboard.component";
import { LmsCustomerEntryComponent } from "./_Lms/lms-customer-entry/lms-customer-entry.component";
import { LmsLoanInfoComponent } from "./_Lms/loan-info/loan-info.component";
import { AccountComponent } from "./_Lms/account/account.component";
import { PostAssetInfoComponent } from "./_Lms/asset-info/asset-info.component";
import { ChequeDetailsComponent } from "./_Lms/cheque-details/cheque-details.component";
import { LmsAppLayoutComponent } from "./_Lms/lms-app-layout/lms-app-layout.component";
import { LmsCRCListComponent } from "./_Lms/lms-crc-list/lms-crc-list.component";
import { LmsCrcFillComponent } from "./_Lms/lms-crc-fill/lms-crc-fill.component";
import { LmsFIComponent } from "./_Lms/lms-fi/lms-fi.component";
import { LmsTvrComponent } from "./_Lms/lms-tvr/lms-tvr.component";

import { LmsNoteRecommendationComponent } from "./_Lms/lms-Note-Recommendation/lms-Note-Recommendation.component";
import { LmsInsuranceComponent } from "./_Lms/lms-insurance/lms-insurance.component";
import { LmsAmortizationComponent } from "./_Lms/lms-amortization/lms-amortization.component";

import { LmsDashboardLayoutComponent } from "./_Lms/lms-dashboard-layout/lms-dashboard-layout.component";
import { CollectionSubAreaComponent } from "./_Accounting/collection-sub-area/collection-sub-area.component";
import { CollectionAreaComponent } from "./_Accounting/collection-area/collection-area.component";
import { AssetInfoMasterComponent } from "./_Accounting/asset-info-master/asset-info-master.component";
import { VehicleCategoryComponent } from "./_Accounting/vehicle-category/vehicle-category.component";
import { VehicleModelComponent } from "./_Accounting/vehicle-model/vehicle-model.component";
import { LoanAccountCategoryComponent } from "./_Accounting/loan-account-category/loan-account-category.component";
import { CrcMasterComponent } from "./_Accounting/crc-master/crc-master.component";
import { InsuranceCompanyComponent } from "./_Accounting/insurance-company/insurance-company.component";
import { LmsRepaymentScheduleReportComponent } from "./_Lms/lms-repayment-schedule-report/lms-repayment-schedule-report.component";
import { LMSLoanEntryComponent } from "./_Lms/lms-loan-entry/lms-loan-entry.component";
import { LmsBookingListComponent } from "./_Lms/lms-booking-list/lms-booking-list.component";
import { LmsModifyBookingListComponent } from "./_Lms/lms-modify-booking-list/lms-modify-booking-list.component";

//import { Date } from './_Lms/pipe/date/date';
import { LosReceiptEntryComponent } from "./_LOS/losreceiptentry/losreceiptentry.component";
import { VoucherEntryLayoutComponent } from "./_Voucher/voucher-entry-layout/voucher-entry-layout.component";

import { GroupMasterComponent } from "./_Accounting/group-master/group-master.component";
import { AccountingMasterComponent } from "./_Accounting/accounting-master/accounting-master.component";
import { AccountMasterDetailComponent } from "./_Accounting/accounting-master/account-master-detail.component";
import { InsuranceBranchComponent } from "./_Accounting/insurance-branch/insurance-branch.component";
import { LmsEditFinancialDetailModelComponent } from "./_Lms/lms-edit-financial-detail-model/lms-edit-financial-detail-model.component";
import { CommonMasterComponent } from "./_Accounting/Common_Master/Common_Master.component";
import { LMSNachEntryComponent } from "./_Lms/lms-nach-entry/lms-nach-entry.component";
import { LmsNachRegistrationComponent } from "./_Lms/lms-nach-registration/lms-nach-registration.component";
import { LMSDashboardAccountingComponent } from "./_Lms/dashboard-accouning/dashboard-accouning.component";
import { LmsAccountingLayoutComponent } from "./_Lms/lms-accounting-layout/lms-accounting-layout.component";
import { LoanAccountingCategoryComponent } from "./_Accounting/loan-accounting-category/loan-accounting-category.component";
import { LMSDashboardReportingComponent } from "./_Lms/dashboard-reporting/dashboard-reporting.component";
import { LmsAttachmentComponent } from "./_Lms/lms-attachment/lms-attachment.component";

import { LmsNachProcessComponent } from "./_Lms/lms-nach-process/lms-nach-process.component";
import { MstTaxSlabComponent } from "./_Configuration/mst-tax-slab/mst-tax-slab.component";
import { LmsReportingLayoutComponent } from "./_Lms/lms-reporting-layout/lms-reporting-layout.component";
import { MstDepartmentComponent } from "./_Configuration/mst-department/mst-department.component";
import { MstemployeeManagerSwapComponent } from "./_Configuration/mstemployee-managerswap/mstemployee-managerswap.component";

import { LmsLoanStatementComponent } from "./_Lms/lms-loanStatement/lms-loanStatement.component";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";

import { AccountingStatementComponent } from "./_Accounting/accounting-statement/accounting-statement.component";
import { MstLoanSeriesComponent } from "./_Configuration/mst-loan-series/mst-loan-series.component";
import { LmsCustomerViewModelComponent } from "./_Lms/lms-customer-view-model/lms-customer-view-model.component";
import { LmsLoanNoGenerateComponent } from "./_Lms/loanno-generate/loanno-generate.component";
import { LMSDvListComponent } from "./_Lms/dv-list/dv-list.component";

import { LmsDvAuthorizeListComponent } from "./_Lms/lms-dv-authorize-list/lms-dv-authorize-list.component";

import { LmsAppDvDetailComponent } from "./_Lms/dv-detail/dv-detail.component";

import { LmsDvAuthorizeDetailComponent } from "./_Lms/lms-dv-authorize-detail/lms-dv-authorize-detail.component";
import { LmsLoanDisbursmentDetailComponent } from "./_Lms/lms-loan-disbursment-detail/lms-loan-disbursment-detail.component";
import { CashBookComponent } from "./_Accounting/cash-book/cash-book.component";
import { BankBookComponent } from "./_Accounting/bank-book/bank-book.component";
import { TrialBalanceComponent } from "./_Accounting/trial-balance/trial-balance.component";
import { LoanBalanceComponent } from "./_Reports/loan-balance/loan-balance.component";
import { ReconcileBalanceComponent } from "./_Accounting/reconcile-balance/reconcile-balance.component";
import { LmsScheduleRegenerateComponent } from "./_Lms/lms-schedule-regenerate/lms-schedule-regenerate.component";
import { LmsScheduleRegenerateDetailsComponent } from "./_Lms/lms-schedule-regenerate-details/lms-schedule-regenerate-details.component";
import { LoanresheduleComponent } from "./_Lms/loanreshedule/loanreshedule.component";

import { LoanRescheduleDetailsComponent } from "./_Lms/loan-reschedule-details/loan-reschedule-details.component";

import { LmsPdcRegistrationComponent } from "./_Lms/lms-pdc-registration/lms-pdc-registration.component";

import { LMSCaseHistoryComponent } from "./_Lms/lms-case-history/lms-case-history.component";
import { VoucherEntryComponent } from "./_Voucher/voucher-entry/voucher-entry.component";
import { LmsLoanPrecloserComponent } from "./_Lms/lms-loan-precloser/lms-loan-precloser.component";
import { LmsLoanPrecloserDetailsComponent } from "./_Lms/lms-loan-precloser-details/lms-loan-precloser-details.component";
import { CrcreportComponent } from "./_Reports/crcreport/crcreport.component";
import { DueListComponent } from "./_Reports/due-list/due-list.component";
import { BusinessWiseComponent } from "./_Reports/business-wise/business-wise.component";
import { RptreportConfigurationComponent } from "./_Configuration/rptreport-configuration/rptreport-configuration.component";
import { PortfolioWiseComponent } from "./_Reports/portfolio-wise/portfolio-wise.component";
import { BusVsCollectionComponent } from "./_Reports/bus-vs-collection/bus-vs-collection.component";
import { MonthWiseDueVsCollectionComponent } from "./_Reports/month-wise-due-vs-collection/month-wise-due-vs-collection.component";
import { BranchWiseDueVsCollectionComponent } from "./_Reports/branch-wise-due-vs-collection/branch-wise-due-vs-collection.component";

import { BookingListComponent } from "./_Reports/booking-list/booking-list.component";

import { LmsLoanUndoComponent } from "./_Lms/lms-loan-undo/lms-loan-undo.component";
import { LmsLoanUndoDetailsComponent } from "./_Lms/lms-loan-undo-detail/lms-loan-undo-detail.component";
import { LmsLoanAutoCloserComponent } from "./_Lms/lms-loan-auto-closer/lms-loan-auto-closer.component";
import { BalanceSheetComponent } from "./_Accounting/balance-sheet/balance-sheet.component";
import { ParReportComponent } from "./_Reports/par-report/par-report.component";
import { DayBookComponent } from "./_Accounting/day-book/day-book.component";
import { LmsVoucherViewModelComponent } from "./_Lms/lms-voucher-view-model/lms-voucher-view-model.component";
import { PartnerLayoutComponent } from "./_Partner/partner-layout/partner-layout.component";
import { PartnerUpdateBookingListComponent } from "./_Partner/partner-update-booking-list/partner-update-booking-list.component";
import { PartnerModifyBookingListComponent } from "./_Partner/partner-modify-booking-list/partner-modify-booking-list.component";
import { PartnerDashboardComponent } from "./_Partner/partner-dashboard/partner-dashboard.component";
import { PartnerLayoutTabComponent } from "./_Partner/partner-layout-tab/partner-layout-tab.component";
import { PartnerLoanInfoComponent } from "./_Partner/partner-loan-info/partner-loan-info.component";
import { PartnerPortfolioWiseComponent } from "./_Partner/partner-portfolio-wise/partner-portfolio-wise.component";

import { PartnerAccountInfoComponent } from "./_Partner/partner-account-info/partner-account-info.component";
import { LmsDvStatusComponent } from "./_Lms/lms-dv-status/lms-dv-status.component";
import { LmsPddDetailComponent } from "./_Lms/lms-pdd-detail/lms-pdd-detail.component";
import { LmsLoanStatementSearchComponent } from "./_Lms/lms-loan-statement-search/lms-loan-statement-search.component";
import { MstRoleMasterComponent } from "./_Configuration/mst-role-master/mst-role-master.component";
import { NpaDetailReportComponent } from "./_Reports/npa-detail-report/npa-detail-report.component";
import { NpaSummaryReportComponent } from "./_Reports/npa-summary-report/npa-summary-report.component";
import { CrifReportComponent } from "./_Reports/crif-report/crif-report.component";
import { LoanBalanceMultiComponent } from "./_Reports/loan-balance-multi/loan-balance-multi.component";
import { LmsPdcChequeListComponent } from "./_Lms/lms-pdc-cheque-list/lms-pdc-cheque-list.component";
import { VoucherTrailComponent } from "./_Accounting/voucher-trail/voucher-trail.component";
import { EDComponent } from "./ed/ed.component";
import { SubComponent } from "./sub/sub.component";
import { LmsLoanClosedListComponent } from "./_Lms/lms-loan-closed-list/lms-loan-closed-list.component";
import { GstDetailComponent } from "./_Accounting/gst-detail/gst-detail.component";
import { PartnerAmortizationComponent } from "./_Partner/partner-amortization/partner-amortization.component";
import { PartnerDvDetailComponent } from "./_Partner/partner-dv-detail/partner-dv-detail.component";
import { PatnerLoanStatementComponent } from "./_Partner/patner-loan-statement/patner-loan-statement.component";
import { PartnerBookingListComponent } from "./_Partner/partner-booking-list/partner-booking-list.component";
import { PartnerDueListComponent } from "./_Partner/partner-due-list/partner-due-list.component";
import { PartnerBulkPaymentComponent } from "./_Partner/partner-bulk-payment/partner-bulk-payment.component";
import { PartnerSinglePaymentComponent } from "./_Partner/partner-single-payment/partner-single-payment.component";
import { DveditComponent } from "./_Lms/dvedit/dvedit.component";
import { PartnerLoanCompareComponent } from "./_Partner/partner-loan-compare/partner-loan-compare.component";
import { PartnerEmiCompareComponent } from "./_Partner/partner-emi-compare/partner-emi-compare.component";
import { LmsCollectionExAssignListComponent } from "./_Lms/lms-collection-ex-assign-list/lms-collection-ex-assign-list.component";
import { LmsDueListComponent } from "./_Lms/lms-due-list/lms-due-list.component";
import { MstLmsLoanSwapComponent } from "./_Configuration/mst-lms-loan-swap/mst-lms-loan-swap.component";
import { LmsCaseWiseAccBalanceComponent } from "./_Accounting/lms-case-wise-acc-balance/lms-case-wise-acc-balance.component";
import { PartnerLoanPreCloserComponent } from "./_Partner/partner-loan-pre-closer/partner-loan-pre-closer.component";
import { PartnerLoanPreCloserDetailsComponent } from "./_Partner/partner-loan-pre-closer-details/partner-loan-pre-closer-details.component";
import { ScheduleTransactionComponent } from "./_Accounting/schedule-transaction/schedule-transaction.component";

import { CollectionEfficiencyComponent } from "./_Reports/collection-efficiency/collection-efficiency.component";

//Product Page URLs
import { ProductAppTabLayoutComponent } from "./_Product/product-app-tab-layout/product-app-tab-layout.component";
import { ProductDetailsComponent } from "./_Product/product-details/product-details.component";
import { ProductAccountDetailsComponent } from "./_Product/product-account-details/product-account-details.component";
import { ProductWorkFlowComponent } from "./_Product/product-work-flow/product-work-flow.component";
import { ProductDocumentsComponent } from "./_Product/product-documents/product-documents.component";
import { ProductConfigurationComponent } from "./_Product/product-configuration/product-configuration.component";
import { GeoLocationComponent } from "./geo-location/geo-location.component";
import { LmsVoucherPrintModelComponent } from "./_Lms/lms-voucher-print-model/lms-voucher-print-model.component";
import { WelcomeletterTemplateComponent } from "./_Configuration/welcomeletter-template/welcomeletter-template.component";
import { NocletterTemplateComponent } from "./_Configuration/nocletter-template/nocletter-template.component";
import { VoucherLayoutComponent } from "./_VoucherAll/voucher-layout/voucher-layout.component";
import { ContraVoucherComponent } from "./_VoucherAll/contra-voucher/contra-voucher.component";
import { JournalVoucherComponent } from "./_VoucherAll/journal-voucher/journal-voucher.component";
import { EntryVoucherComponent } from "./_VoucherAll/entry-voucher/entry-voucher.component";
import { ReceiptVoucherComponent } from "./_VoucherAll/receipt-voucher/receipt-voucher.component";
import { PaymentVoucherComponent } from "./_VoucherAll/payment-voucher/payment-voucher.component";
import { MstDeDupeComponent } from "./_Configuration/mst-de-dupe/mst-de-dupe.component";
import { PrintnocletterComponent } from "./_Configuration/printnocletter/printnocletter.component";
import { RequestAndTaskComponent } from "./_Lms/request-and-task/request-and-task.component";
import { RequestAndTaskDetailComponent } from "./_Lms/request-and-task-detail/request-and-task-detail.component";
import { CustomerSearchComponent } from "./_Lms/customer-search/customer-search.component";
import { AddCustomerInfoComponent } from "./_Lms/add-customer-info/add-customer-info.component";
import { LmsReceiptBookComponent } from "./_Lms/lms-receipt-book/lms-receipt-book.component";
import { ScheduleMasterListComponent } from "./_Accounting/schedule-master-list/schedule-master-list.component";
//import { LoanStatementComponent } from './_Print/loan-statement/loan-statement.component';

import { DepositLayoutComponent } from "./_Deposit/deposit-layout/deposit-layout.component";
import { DepositDashboardComponent } from "./_Deposit/deposit-dashboard/deposit-dashboard.component";
import { DepositLayoutTabComponent } from "./_Deposit/deposit-layout-tab/deposit-layout-tab.component";
import { DepositProductListComponent } from "./_Deposit/deposit-product-list/deposit-product-list.component";
import { DepositProductDetailsComponent } from "./_Deposit/deposit-product-details/deposit-product-details.component";
import { DepositListComponent } from "./_Deposit/deposit-list/deposit-list.component";
import { DepositInfoLayoutTabComponent } from "./_Deposit/deposit-info-layout-tab/deposit-info-layout-tab.component";
import { DepositAddComponent } from "./_Deposit/deposit-add/deposit-add.component";
import { DepositDetailComponent } from "./_Deposit/deposit-detail/deposit-detail.component";
import { DepositProductAccountDetailsComponent } from "./_Deposit/deposit-product-account-details/deposit-product-account-details.component";
import { DepositAuthorizeListComponent } from "./_Deposit/deposit-authorize-list/deposit-authorize-list.component";
import { LmsPaymentAuthorizationComponent } from "./_Lms/lms-payment-authorization/lms-payment-authorization.component";
import { LmsPaymentAuthorizationListComponent } from "./_Lms/lms-payment-authorization-list/lms-payment-authorization-list.component";
import { PaymentApiComponent } from "./payment-api/payment-api.component";

import { PaymentListComponent } from "./_Reports/payment-list/payment-list.component";
import { DeviationModuleComponent } from "./deviation-module/deviation-module.component";

import { LmsEnachComponent } from "./_Lms/lms-enach/lms-enach.component";
import { AssetsDetailsComponent } from "./_Reports/assets-details/assets-details.component";
import { EnachGeneratedListComponent } from "./_Lms/enach-generated-list/enach-generated-list.component";
import { LmsEnachResponseComponent } from "./_Lms/lms-enach-response/lms-enach-response.component";

import { EmployeeAppTabLayoutComponent } from "./_Employee/employee-app-tab-layout/employee-app-tab-layout.component";
import { MstemployeenewregisterComponent } from "./_Employee/mstemployeenewregister/mstemployeenewregister.component";
import { EmployeeAssignBranchComponent } from "./_Employee/employee_assign_branch/employee_assign_branch.component";
import { EmployeeAssignLosProcessComponent } from "./_Employee/employee_assign_los_process/employee_assign_los_process.component";
import { EmployeeAssignQuickProcessComponent } from "./_Employee/employee_assign_Quick_process/employee_assign_Quick_process.component";

import { QuickloanTabLayoutComponent } from "./_QuickLoan/quickloan-tab-layout/quickloan-tab-layout.component";
import { QuickloanLoanInfoComponent } from "./_QuickLoan/quickloan-loan-info/quickloan-loan-info.component";
import { QuickloanCustomerDetailComponent } from "./_QuickLoan/quickloan-customer-detail/quickloan-customer-detail.component";
import { QuickloanAccountInfoComponent } from "./_QuickLoan/quickloan-account-info/quickloan-account-info.component";
import { QuickloanBankPdcComponent } from "./_QuickLoan/quickloan-bank-pdc/quickloan-bank-pdc.component";
import { QuickloanDocumentsComponent } from "./_QuickLoan/quickloan-documents/quickloan-documents.component";
import { QuickloanListComponent } from "./_QuickLoan/quickloan-list/quickloan-list.component";
import { QuickloanProcessLevelComponent } from "./_Product/quickloan-process-level/quickloan-process-level.component";
import { QuickloanProcessAssignmentComponent } from "./_Product/quickloan-process-assignment/quickloan-process-assignment.component";
import { QuickLoanMasterReportComponent } from "./_QuickLoan/quickloan-master-report/quickloan-master-report.component";

import { QuickLoanLayoutComponent } from "./_QuickLoan/quickloan-layout/quickloan-layout.component";
import { QuickLoanDashboardComponent } from "./_QuickLoan/quickloan-dashboard/quickloan-dashboard.component";
import { QuickloanCustomerReferenceComponent } from "./_QuickLoan/quickloan-customer-reference/quickloan-customer-reference.component";
import { QuickloanIncomeExpenditureComponent } from "./_QuickLoan/quickloan-income-expenditure/quickloan-income-expenditure.component";
import { QuickloanCRCComponent } from "./_QuickLoan/quickloan-crc/quickloan-crc.component";
import { QuickloanCrcFillComponent } from "./_QuickLoan/quickloan-crc-fill/quickloan-crc-fill.component";
import { LmsLendingAssignmentComponent } from "./_Lms/lms-lending-assignment/lms-lending-assignment.component";
import { LegalAssignCasesComponent } from "./_Legal/legal-assign-cases/legal-assign-cases.component";
import { CourtListComponent } from "./_Configuration/court-list/court-list.component";
import { CaseTypeListComponent } from "./_Configuration/case-type-list/case-type-list.component";
import { CaseTypeInfoComponent } from "./_Configuration/case-type-info/case-type-info.component";
import { LegalCasesListComponent } from "./_Legal/legal-cases-list/legal-cases-list.component";
import { LegalCasesModelComponent } from "./_Legal/legal-cases-model/legal-cases-model.component";
import { LegalLetterTemplateComponent } from "./_Configuration/legal-letter-template/legal-letter-template.component";
import { PrintDemandNoticeComponent } from "./print-demand-notice/print-demand-notice.component";
import { QuickloanDeviationComponent } from "./_QuickLoan/quickloan-deviation/quickloan-deviation.component";
import { QuickloanDeviationListComponent } from "./_QuickLoan/quickloan-deviation-list/quickloan-deviation-list.component";
import { QuickloanAssetsInfoComponent } from "./_QuickLoan/quickloan-assets-info/quickloan-assets-info.component";
import { QuickloanGenerateLoannoComponent } from "./_QuickLoan/quickloan-generate-loanno/quickloan-generate-loanno.component";
import { QuickloanFamilyMemberComponent } from "./_QuickLoan/quickloan-family-member/quickloan-family-member.component";
import { QuickloanFiQuestionsComponent } from "./_QuickLoan/quickloan-fi-questions/quickloan-fi-questions.component";
import { QuickloanTvrQuestionsComponent } from "./_QuickLoan/quickloan-tvr-questions/quickloan-tvr-questions.component";
import { LoanDeleteRequestComponent } from "./_Lms/loan-delete-request/loan-delete-request.component";
import { LoanDeleteAuthorizationComponent } from "./_Lms/loan-delete-authorization/loan-delete-authorization.component";
import { LmsLoanDisbursmentPaymentDetailComponent } from "./_Lms/lms-loan-disbursment-payment-detail/lms-loan-disbursment-payment-detail.component";

import { NgApexchartsModule } from "ng-apexcharts";
import { ChartsComponent } from "./_Reports/charts/charts.component";

import { LegalHearingCasesComponent } from "./_Legal/legal-hearing-cases/legal-hearing-cases.component";
import { LegalTabLayoutComponent } from "./_Legal/legal-tab-layout/legal-tab-layout.component";
import { LegalCaseInfoComponent } from "./_Legal/legal-case-info/legal-case-info.component";
import { LegalHearingInfoComponent } from "./_Legal/legal-hearing-info/legal-hearing-info.component";
import { LegalDocumentsInfoComponent } from "./_Legal/legal-documents-info/legal-documents-info.component";
import { LegalNoticeAttachmentsComponent } from "./_Legal/legal-notice-attachments/legal-notice-attachments.component";

import { QuickloanFileReceiveToHODetailComponent } from "./_QuickLoan/quickloan-file-receive-to-ho-detail/quickloan-file-receive-to-ho-detail.component";
import { LmsPaymentInitiationListComponent } from "./_Lms/lms-payment-initiation-list/lms-payment-initiation-list.component";
import { LmsPaymentInitiationDetailComponent } from "./_Lms/lms-Payment-Initiation-detail/lms-Payment-Initiation-detail.component";
import { QuickloanTatReportComponent } from "./_QuickLoan/quickloan-tat-report/quickloan-tat-report.component";
import { QRCodeModule } from "angular2-qrcode";
import { ExposureReportComponent } from "./_Reports/exposure-report/exposure-report.component";
import { LmsNpaMarkingComponent } from "./_Lms/lms-npa-marking/lms-npa-marking.component";
import { FutureFlowComponent } from "./_Reports/future-flow/future-flow.component";
import { CibilCommercialReportComponent } from "./_Reports/cibil-commercial/cibil-commercial.component";

import { QuickLoanInquiryListComponent } from "./_QuickLoan/quickloan-inquiry-list/quickloan-inquiry-list.component";
import { QuickLoanMyleadListComponent } from "./_QuickLoan/quickloan-my-lead-list/quickloan-my-lead-list.component";

import { QuickLoanMyLeadtDetailComponent } from "./_QuickLoan/quickloan-my-lead-detail/quickloan-my-lead-detail.component";
import { QuickLoanLegalProcessComponent } from "./_QuickLoan/quickloan-legal-process/quickloan-legal-process.component";
import { QuickLoanValuationProcessComponent } from "./_QuickLoan/quickloan-valuation-process/quickloan-valuation-process.component";
import { SanctionConditionsComponent } from "./_QuickLoan/sanction-conditions/sanction-conditions.component";
import { QuickloanInsuranceComponent } from "./_QuickLoan/quickloan-insurance/quickloan-insurance.component";
import { QuestionsNatureOfWorkComponent } from "./_Configuration/questions-natureofwork/questions-natureofwork.component";
import { NatureOfWorkComponent } from "./_Configuration/nature-of-work/nature-of-work.component";
import { QuickloanPDQuestionsComponent } from "./_QuickLoan/quickloan-pd-questions/quickloan-pd-questions.component";
import { PrintTemplateComponent } from "./_Configuration/print-template/print-template.component";
import { MainCardInfoComponent } from "./_Lms/card-info/main-card-info/main-card-info.component";
import { LoanSubcardInfoComponent } from "./_Lms/card-info/loan-subcard-info/loan-subcard-info.component";
import { CustomerSubcardInfoComponent } from "./_Lms/card-info/customer-subcard-info/customer-subcard-info.component";
import { CRCSubcardInfoComponent } from "./_Lms/card-info/crc-subcard-info/crc-subcard-info.component";
import { FamilySubcardInfoComponent } from "./_Lms/card-info/family-subcard-info/family-subcard-info.component";
import { IncomeSubcardInfoComponent } from "./_Lms/card-info/income-subcard-info/income-subcard-info.component";
import { ReferencesSubcardInfoComponent } from "./_Lms/card-info/references-subcard-info/references-subcard-info.component";
import { AssetSubcardInfoComponent } from "./_Lms/card-info/asset-subcard-info/asset-subcard-info.component";
import { ReceiptImportComponent } from "./_Lms/receipt-import/receipt-import.component";
import { ReceiptAuthenticationComponent } from "./_Accounting/receipt-authentication/receipt-authentication.component";
// import { ChatboxComponent } from './_Chatbox/ChatboxPopup/chatbox/chatbox.component';
// import { ViewNamesComponent } from './_Chatbox/ChatboxPopup/view-names/view-names.component';
// import { ViewMessageComponent } from './_Chatbox/ChatboxPopup/view-message/view-message.component';
// import { ViewAllEmployeeNameComponent } from './_Chatbox/ChatboxPopup/view-all-employee-name/view-all-employee-name.component';
import { SearchPipe } from "./_Chatbox/_pipe/search.pipe";
import { DateFormatPipe } from "./_Chatbox/_pipe/dateFormat.pipe";
import { NumberMaskPipe } from "./_Lms/services/mask.pipe";

import { ChatModalSheetComponent } from "./_Chatbox/ChatboxModal/chat-modal-sheet/chat-modal-sheet.component";
import { ScrollToBottomDirective } from "./_Chatbox/_Directive/scroll-to-bottom.directive";
import { PartnerLoanBalanceComponent } from "./_Partner/partner-loan-balance/partner-loan-balance.component";
import { ReportsAttendanceComponent } from "./_Reports/reports-attendance/reports-attendance.component";
import { RepossessListComponent } from "./_Lms/repossess-list/repossess-list.component";
import { ParkingYardComponent } from "./_Configuration/parking-yard/parking-yard.component";
import { ExposureReport1Component } from "./_Reports/exposure-report1/exposure-report1.component";
import { AccountInterestCalculationComponent } from "./_Accounting/account-interest-calculation/account-interest-calculation.component";
import { FutureDueComponent } from "./_Reports/future-due/future-due.component";
import { EmiCalculatorComponent } from "./emi-calculator/emi-calculator.component";
import { ReleaseRequestListComponent } from "./_Lms/release-request-list/release-request-list.component";
import { ReleaseAuthorizedListComponent } from "./_Lms/release-authorized-list/release-authorized-list.component";
import { PartnerFutureFlowComponent } from "./_Partner/partner-future-flow/partner-future-flow.component";
import { ReceiptTopupComponent } from "./_Topup/receipt-topup/receipt-topup.component";
import { PaymentTopupComponent } from "./_Topup/payment-topup/payment-topup.component";
import { JournalTopupComponent } from "./_Topup/journal-topup/journal-topup.component";
import { VoucherTopupComponent } from "./_Topup/voucher-topup/voucher-topup.component";

import { MachineMACAddressComponent } from "./_Configuration/machine-mac-address/machine-mac-address.component";
import { SalesTargetAchievementComponent } from "./_Reports/sales-target-achievement/sales-target-achievement.component";
import { CollectionTargetAchievementComponent } from "./_Reports/collection-target-achievement/collection-target-achievement.component";
import { OverDueInterestDetailComponent } from "./_Reports/over-due-interest-detail/over-due-interest-detail.component";
import { FIQuestionSubcardInfoComponent } from "./_Lms/card-info/FI-Question-subcard-info/FI-Question-subcard-info.component";
import { TVRQuestionSubcardInfoComponent } from "./_Lms/card-info/TVR-Question-subcard-info/TVR-Question-subcard-info.component";
import { CustomerKycSubcardInfoComponent } from "./_Lms/card-info/customer-kyc-subcard-info/customer-kyc-subcard-info.component";
import { PDQuestionSubcardInfoComponent } from "./_Lms/card-info/PD-Question-subcard-info/PD-Question-subcard-info.component";
import { AppPage403ErrorComponent } from "./page-403-error/page-403-error.component";
import { ResportDumpDataComponent } from "./_Reports/resport-dump-data/resport-dump-data.component";
import { TATReportComponent } from "./_Lms/card-info/tat-report/tat-report.component";
import { ReceiptDeductionComponent } from "./_Lms/card-info/receipt-deduction/receipt-deduction.component";
import { PdcDetailsComponent } from "./_Lms/card-info/pdc-details/pdc-details.component";
import { NgxPrintModule } from "ngx-print";
import { DeviationInfoComponent } from "./_Lms/card-info/deviation-info/deviation-info.component";
import { SanctionConditionComponent } from "./_Lms/card-info/sanction-condition/sanction-condition.component";

import { PrintCardComponent } from "./_Lms/card-info/print-card/print-card.component";
import { StaffBalanceComponent } from "./_Accounting/staff-balance/staff-balance.component";
import { CollectionEfficeincyDetailComponent } from "./_Reports/collection-efficeincy-detail/collection-efficeincy-detail.component";
import { PartnerBalanceComponent } from "./_Accounting/partner-balance/partner-balance.component";
import { PartnerAutoCloserComponent } from "./_Partner/partner-auto-closer/partner-auto-closer.component";
import { BranchWisePlComponent } from "./_Accounting/branch-wise-pl/branch-wise-pl.component";
import { LmsWaiverListComponent } from "./_Lms/lms-waiver-list/lms-waiver-list.component";
import { LmsWaiverCreationComponent } from "./_Lms/lms-waiver-creation/lms-waiver-creation.component";
import { SearchLoanComponent } from "./_Lms/search-loan/search-loan.component";

import { ReceiptDetailComponent } from "./_Lms/receipt-detail/receipt-detail.component";
import { LmsWaiverAuthorisedComponent } from "./_Lms/lms-waiver-authorised/lms-waiver-authorised.component";
import { LMSIncomeExpenditureComponent } from "./_Lms/lms-income-expenditure/lms-income-expenditure.component";
import { LMSFamilyMemberComponent } from "./_Lms/lms-family-member/lms-family-member.component";
import { LoanCustomerListComponent } from "./_Reports/loan-customer-list/loan-customer-list.component";
import { DifferenceEmiAllocationComponent } from "./_Reports/difference-emi-allocation/difference-emi-allocation.component";
import { CustomerKycVerificationComponent } from "./_Configuration/customer-kyc-verification/customer-kyc-verification.component";
import { PdcRepaymentProcessComponent } from "./_Lms/pdc-repayment-process/pdc-repayment-process.component";
import { AccuredInterestComponent } from "./_Reports/accured-interest/accured-interest.component";
import { LmsLoanWriteOffSettledComponent } from "./_Lms/lms-loan-WriteOff_Settled/lms-loan-WriteOff_Settled.component";
import { LmsLoanWriteOffSettledDetailsComponent } from "./_Lms/lms-loan-WriteOff_Settled-details/lms-loan-WriteOff_Settled-details.component";
import { ConsumerDurableCategoryComponent } from "./_Configuration/consumer-durable-category/consumer-durable-category.component";
import { ConsumerDurableManufactureComponent } from "./_Configuration/consumer-durable-manufacture/consumer-durable-manufacture.component";
import { ConsumerDurableProductMasterComponent } from "./_Configuration/consumer-durable-product-master/consumer-durable-product-master.component";
import { ConsumerDurableModelMasterComponent } from "./_Configuration/consumer-durable-model-master/consumer-durable-model-master.component";
import { QuickloanInquiryReportComponent } from "./_QuickLoan/quickloan-inquiry-report/quickloan-inquiry-report.component";
import { EmployeeAssignProductComponent } from "./_Employee/employee_assign_product/employee_assign_product.component";
import { QuickloanCibilReportComponent } from "./_QuickLoan/quickloan-cibil-report/quickloan-cibil-report.component";
import { LoanFinancialInfoComponent } from "./_Lms/card-info/loan-financial-info/loan-financial-info.component";

import { QuickloanFOIRComponent } from "./_QuickLoan/quickloan-foir/quickloan-foir.component";
import { RBIDNB2ReportComponent } from "./_Reports/RBIReturn/rbi_dnb2-report/rbi_dnb2-report.component";
import { LmsLoanFOIRComponent } from "./_Lms/lms-loan-foir/lms-loan-foir.component";
import { MstBranchProductProcessComponent } from "./_Configuration/mst-branch-product-process/mst-branch-product-process.component";
import { AttachedDocumentComponent } from "./_Lms/card-info/attached-document/attached-document.component";
import { FoirCardComponent } from "./_Lms/card-info/foir-card/foir-card.component";

import { LmsCaseWiseAccBalanceLoanComponent } from "./_Accounting/lms-case-wise-acc-balance-loan/lms-case-wise-acc-balance-loan.component";
import { InsuranceDetailsComponent } from "./_Reports/insurance-details/insurance-details.component";
import { ChangeLoanNoComponent } from "./_Configuration/change-loan-no/change-loan-no.component";
import { VehicleGridComponent } from "./_Configuration/vehicle-grid/vehicle-grid.component";
import { EnachAuBankComponent } from "./_Lms/enach-au-bank/enach-au-bank.component";
import { FileCheckingMasterComponent } from "./_Configuration/file-checking-master/file-checking-master.component";

import { QuickloanFileCheckingComponent } from "./_QuickLoan/quickloan-FileChecking/quickloan-FileChecking.component";
import { CibilReportAppComponent } from "./cibil-report-app/cibil-report-app.component";
import { SwapNachSourceComponent } from "./_Lms/swap-nach-source/swap-nach-source.component";
import { OwnManagedFundsComponent } from "./_Reports/own-managed-funds/own-managed-funds.component";
import { CustomerCibilVerificationComponent } from "./_Configuration/customer-cibil-verification/customer-cibil-verification.component";
import { QuickloanAppilcationStatusReportComponent } from "./_QuickLoan/quickloan-appilcation-status-report/quickloan-appilcation-status-report.component";
import { EnachNupayComponent } from "./_Lms/enach-nupay/enach-nupay.component";
import { KeyFactsStatementComponent } from "./_Reports/key-facts-statement/key-facts-statement.component";

import { CibilApiTestComponent } from "./_Lms/cibil-api-test/cibil-api-test.component";
import { QuickloanApplicationStatusComponent } from "./_QuickLoan/quickloan-application-status/quickloan-application-status.component";
import { CustomerKycVerificationDetailComponent } from "./_Configuration/customer-kyc-verification-detail/customer-kyc-verification-detail.component";
// import { QuickloanCustomerLeadComponent } from "./_QuickLoan/quickloan-customer-lead/quickloan-customer-lead.component";
import { GeneratePendingAmortizationComponent } from "./_Lms/generate-pending-amortization/generate-pending-amortization.component";
import { FutureFlowDetailComponent } from "./_Reports/future-flow-detail/future-flow-detail.component";
import { LmsDueListPreEmiComponent } from "./lms-due-list-pre-emi/lms-due-list-pre-emi.component";
import { QuickloanCustomerLeadComponent } from "./_QuickLoan/quickloan-customer-lead/quickloan-customer-lead.component";

import { LmsReceiptBookTeamComponent } from "./lms-receipt-book-team/lms-receipt-book-team.component";
import { QuickloanBankAnalysisComponent } from "./_QuickLoan/quickloan-bank-analysis/quickloan-bank-analysis.component";

import { PartnerReceiptImportComponent } from "./_Partner/partner-receipt-import/partner-receipt-import.component";
import { LmsUnaccountingVoucherComponent } from "./_Lms/lms-unaccounting-voucher/lms-unaccounting-voucher.component";
import { LmsBankAnalysisComponent } from "./_Lms/lms-bank-analysis/lms-bank-analysis.component";
import { LmsLoanPrecloserNewComponent } from "./_Lms/lms-loan-precloser-new/lms-loan-precloser-new.component";
import { LmsLoanPrecloserNewDetailsComponent } from "./_Lms/lms-loan-precloser-new-details/lms-loan-precloser-new-details.component";
import { LmsDeathTheftClaimComponent } from "./_Lms/lms-death-theft-claim/lms-death-theft-claim.component";
import { LmsPersonalDiscussionComponent } from "./_Lms/lms-personal-discussion/lms-personal-discussion.component";
import { LmsSanctionConditionComponent } from "./_Lms/lms-sanction-condition/lms-sanction-condition.component";
import { LmsFileCheckingComponent } from "./_Lms/lms-file-checking/lms-file-checking.component";
import { LmsUpdateEffectiveIrrComponent } from "./_Lms/lms-Update-EffectiveIrr/lms-Update-EffectiveIrr.component";
import { ReportsBusinessCollectionSummaryComponent } from "./_Reports/reports-business-collection-summary/reports-business-collection-summary.component";
import { CustomerThirdPartyapiComponent } from "./_Configuration/customer-third-partyapi/customer-third-partyapi.component";
import { EmployeeLendingAssignmentComponent } from './_Employee/employee-lending-assignment/employee-lending-assignment.component';

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    dateInputFormat: "DD/MM/YYYY",
  });
}
export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@NgModule({
  declarations: [
    AppComponent,
    AppUserLayoutComponent,
    LoginComponent,
    AdminLogoutComponent,
    UserLogoutComponent,
    ComingsoonComponent,
    MatSpinnerOverlayComponent,
    UnauthorizedComponent,
    ButtonComponent,
    SnackbarComponent,
    MatConfirmDialogComponent,
    DashboardComponent,
    MatDialogComponent,
    PhoneMaskDirective,
    HomePageComponent,
    IndexpageComponent,
    CommonheaderComponent,
    MstLoanProcessComponent,
    MstBranchesComponent,
    MstemployeelistComponent,
    MstemployeenewregisterComponent,
    MstZoneListComponent,
    MstDistrictListComponent,
    MstTahsilListComponent,
    LosAppUserLayoutComponent,

    ProductworkflowComponent,

    MstProductListComponent,
    MstLoanDocumentComponent,
    MstBranchEmployeeComponent,
    MstDocumentsComponent,
    MstStateListComponent,
    MstLoanChargesComponent,

    QuestionsFiComponent,
    QuestionsTVRComponent,
    MstemployeeswapComponent,
    MstemployeehierarchyComponent,

    LosSearchCustomerComponent,

    ChargesheadComponent,
    CreditpolicyComponent,

    RptssrsComponent,

    SystemconfigurationComponent,
    SearchapplicationComponent,

    MstcourierComponent,
    RptchargecollectionComponent,
    ProductassetdetailComponent,

    NumberDirective,
    MaxLengthDirective,
    DateConvertDirective,
    AlphaDirective,

    MstPartnerComponent,
    MstschemeComponent,

    PrintsectionletterComponent,
    LosCustomerViewModelComponent,

    LoginfeereceiptComponent,
    RptHoldInquiryComponent,
    SanctionletterTemplateComponent,

    LosPendingDocumentApplicationListComponent,
    LosPendingDocumentListComponent,
    AppPageNotFoundComponent,

    RptuserlogComponent,
    MstRoleComponent,
    LMSDashboardComponent,
    LmsDashboardLayoutComponent,
    LmsCustomerEntryComponent,
    LmsLoanInfoComponent,
    AccountComponent,
    PostAssetInfoComponent,
    ChequeDetailsComponent,
    LmsAppLayoutComponent,
    LmsCRCListComponent,
    LmsCrcFillComponent,
    LmsTvrComponent,
    LmsFIComponent,
    LmsInsuranceComponent,
    CollectionSubAreaComponent,
    CollectionAreaComponent,
    AssetInfoMasterComponent,
    VehicleCategoryComponent,
    VehicleModelComponent,
    LoanAccountCategoryComponent,
    CrcMasterComponent,
    InsuranceCompanyComponent,
    LmsRepaymentScheduleReportComponent,
    LMSLoanEntryComponent,
    LmsBookingListComponent,
    LosReceiptEntryComponent,
    VoucherEntryLayoutComponent,

    GroupMasterComponent,
    AccountingMasterComponent,
    AccountMasterDetailComponent,
    LoanAccountingCategoryComponent,
    InsuranceBranchComponent,
    LmsEditFinancialDetailModelComponent,
    LmsAmortizationComponent,
    CommonMasterComponent,
    LmsNoteRecommendationComponent,
    LMSNachEntryComponent,
    LmsNachRegistrationComponent,
    LmsAccountingLayoutComponent,
    LMSDashboardAccountingComponent,

    LmsNachProcessComponent,
    MstTaxSlabComponent,
    MstDepartmentComponent,
    LmsReportingLayoutComponent,
    LMSDashboardReportingComponent,
    MstemployeeManagerSwapComponent,
    LmsLoanStatementComponent,
    AccountingStatementComponent,
    LmsCustomerViewModelComponent,
    LmsLoanStatementComponent,
    MstLoanSeriesComponent,
    LmsAttachmentComponent,
    LmsLoanNoGenerateComponent,
    LMSDvListComponent,
    LmsModifyBookingListComponent,
    LmsDvAuthorizeListComponent,
    LmsAppDvDetailComponent,
    LmsDvAuthorizeDetailComponent,
    LmsLoanDisbursmentDetailComponent,
    CashBookComponent,
    BankBookComponent,
    TrialBalanceComponent,
    LoanBalanceComponent,
    ReconcileBalanceComponent,
    LmsScheduleRegenerateComponent,
    LmsScheduleRegenerateDetailsComponent,
    LoanresheduleComponent,
    LoanRescheduleDetailsComponent,
    LmsPdcRegistrationComponent,
    LMSCaseHistoryComponent,
    VoucherEntryComponent,
    LmsLoanPrecloserComponent,
    LmsLoanPrecloserDetailsComponent,
    CrcreportComponent,
    DueListComponent,
    BusinessWiseComponent,
    RptreportConfigurationComponent,
    PortfolioWiseComponent,
    BusVsCollectionComponent,
    MonthWiseDueVsCollectionComponent,
    BranchWiseDueVsCollectionComponent,
    BookingListComponent,
    LmsLoanUndoComponent,
    LmsLoanUndoDetailsComponent,
    LmsLoanAutoCloserComponent,
    BalanceSheetComponent,
    ParReportComponent,
    LmsVoucherViewModelComponent,
    DayBookComponent,
    PartnerLayoutComponent,
    PartnerUpdateBookingListComponent,
    PartnerModifyBookingListComponent,
    PartnerDashboardComponent,
    PartnerLayoutTabComponent,
    PartnerLoanInfoComponent,
    PartnerAccountInfoComponent,
    LmsDvStatusComponent,
    LmsPddDetailComponent,
    LmsLoanStatementSearchComponent,
    MstRoleMasterComponent,
    NpaDetailReportComponent,
    NpaSummaryReportComponent,
    CrifReportComponent,
    LoanBalanceMultiComponent,

    DveditComponent,
    PartnerSinglePaymentComponent,
    PartnerBulkPaymentComponent,
    LmsPdcChequeListComponent,
    VoucherTrailComponent,
    EDComponent,

    LmsLoanClosedListComponent,
    GstDetailComponent,
    PartnerAmortizationComponent,
    PartnerDvDetailComponent,
    PatnerLoanStatementComponent,
    PartnerBookingListComponent,
    PartnerDueListComponent,
    PartnerLoanCompareComponent,
    PartnerEmiCompareComponent,
    LmsCollectionExAssignListComponent,
    LmsDueListComponent,
    MstLmsLoanSwapComponent,
    LmsCaseWiseAccBalanceComponent,
    PartnerLoanPreCloserComponent,
    PartnerLoanPreCloserDetailsComponent,
    ScheduleTransactionComponent,

    CollectionEfficiencyComponent,
    ProductAppTabLayoutComponent,
    ProductDetailsComponent,
    ProductAccountDetailsComponent,
    ProductWorkFlowComponent,
    ProductDocumentsComponent,
    ProductConfigurationComponent,
    GeoLocationComponent,
    LmsVoucherPrintModelComponent,
    WelcomeletterTemplateComponent,
    NocletterTemplateComponent,
    VoucherLayoutComponent,
    ContraVoucherComponent,
    JournalVoucherComponent,
    EntryVoucherComponent,
    ReceiptVoucherComponent,
    PaymentVoucherComponent,
    MstDeDupeComponent,
    ProductConfigurationComponent,
    GeoLocationComponent,
    LmsVoucherPrintModelComponent,
    WelcomeletterTemplateComponent,
    MstDeDupeComponent,
    PrintnocletterComponent,
    RequestAndTaskComponent,
    RequestAndTaskDetailComponent,
    CustomerSearchComponent,
    AddCustomerInfoComponent,
    LmsReceiptBookComponent,
    ScheduleMasterListComponent,
    DepositLayoutComponent,
    DepositDashboardComponent,
    DepositLayoutTabComponent,
    DepositProductListComponent,
    DepositProductDetailsComponent,
    DepositListComponent,
    DepositInfoLayoutTabComponent,
    DepositAddComponent,
    DepositDetailComponent,
    DepositProductAccountDetailsComponent,
    DepositAuthorizeListComponent,
    LmsPaymentAuthorizationComponent,
    LmsPaymentAuthorizationListComponent,
    PaymentListComponent,
    PaymentApiComponent,
    DeviationModuleComponent,

    QuickloanTabLayoutComponent,
    QuickloanLoanInfoComponent,
    QuickloanProcessAssignmentComponent,
    QuickLoanLayoutComponent,
    QuickLoanDashboardComponent,
    QuickloanCustomerDetailComponent,
    QuickloanAccountInfoComponent,
    QuickloanBankPdcComponent,
    QuickloanDocumentsComponent,
    QuickloanListComponent,
    QuickloanProcessLevelComponent,
    LmsEnachComponent,
    AssetsDetailsComponent,
    EnachGeneratedListComponent,
    LmsEnachResponseComponent,
    EmployeeAppTabLayoutComponent,
    EmployeeAssignBranchComponent,
    EmployeeAssignLosProcessComponent,
    EmployeeAssignQuickProcessComponent,
    QuickloanCustomerReferenceComponent,
    QuickloanIncomeExpenditureComponent,
    QuickloanCRCComponent,
    QuickloanCrcFillComponent,
    LmsLendingAssignmentComponent,
    TwoDigitDecimaNumberDirective,
    AlphaNumericDirective,
    LegalAssignCasesComponent,
    CourtListComponent,
    CaseTypeListComponent,
    CaseTypeInfoComponent,
    LegalCasesListComponent,
    LegalCasesModelComponent,
    LegalLetterTemplateComponent,
    PrintDemandNoticeComponent,
    QuickloanDeviationComponent,
    QuickloanDeviationListComponent,
    QuickloanAssetsInfoComponent,
    LoanDeleteRequestComponent,
    LoanDeleteAuthorizationComponent,
    ChartsComponent,
    LegalHearingCasesComponent,
    LegalTabLayoutComponent,
    LegalCaseInfoComponent,
    LegalHearingInfoComponent,
    LegalDocumentsInfoComponent,
    LegalNoticeAttachmentsComponent,

    TwoDigitDecimaNumberDirective,
    AlphaNumericDirective,
    QuickloanGenerateLoannoComponent,
    QuickloanFamilyMemberComponent,
    QuickloanFiQuestionsComponent,
    QuickloanTvrQuestionsComponent,

    QuickloanDeviationComponent,
    QuickloanDeviationListComponent,
    QuickloanAssetsInfoComponent,
    LoanDeleteRequestComponent,
    LoanDeleteAuthorizationComponent,
    ChartsComponent,
    LmsLoanDisbursmentPaymentDetailComponent,
    QuickloanFileReceiveToHODetailComponent,
    LmsPaymentInitiationListComponent,
    LmsPaymentInitiationDetailComponent,
    QuickloanTatReportComponent,
    PartnerPortfolioWiseComponent,
    ExposureReportComponent,
    PrintwelcomeletterComponent,
    LmsNpaMarkingComponent,
    FutureFlowComponent,
    CibilCommercialReportComponent,
    QuickLoanInquiryListComponent,
    QuickLoanMyleadListComponent,
    QuickLoanMyLeadtDetailComponent,
    QuickLoanLegalProcessComponent,
    QuickLoanValuationProcessComponent,
    SanctionConditionsComponent,
    QuickloanInsuranceComponent,
    QuestionsNatureOfWorkComponent,
    NatureOfWorkComponent,
    QuickloanPDQuestionsComponent,
    PrintTemplateComponent,
    MainCardInfoComponent,
    LoanSubcardInfoComponent,
    CustomerSubcardInfoComponent,
    CRCSubcardInfoComponent,
    FamilySubcardInfoComponent,
    IncomeSubcardInfoComponent,
    ReferencesSubcardInfoComponent,
    AssetSubcardInfoComponent,
    ReceiptImportComponent,
    ReceiptAuthenticationComponent,
    QuickloanInsuranceComponent,
    QuestionsNatureOfWorkComponent,
    NatureOfWorkComponent,
    QuickloanPDQuestionsComponent,
    PrintTemplateComponent,
    MainCardInfoComponent,
    LoanSubcardInfoComponent,
    CustomerSubcardInfoComponent,
    CRCSubcardInfoComponent,
    FamilySubcardInfoComponent,
    IncomeSubcardInfoComponent,
    ReferencesSubcardInfoComponent,
    AssetSubcardInfoComponent,
    ReceiptImportComponent,
    SearchPipe,
    NumberMaskPipe,
    ChatModalSheetComponent,
    ScrollToBottomDirective,
    QuickloanInsuranceComponent,
    QuestionsNatureOfWorkComponent,
    NatureOfWorkComponent,
    QuickloanPDQuestionsComponent,
    PrintTemplateComponent,
    MainCardInfoComponent,
    LoanSubcardInfoComponent,
    CustomerSubcardInfoComponent,
    CRCSubcardInfoComponent,
    FamilySubcardInfoComponent,
    IncomeSubcardInfoComponent,
    ReferencesSubcardInfoComponent,
    AssetSubcardInfoComponent,
    ReceiptImportComponent,
    ReceiptAuthenticationComponent,
    PartnerLoanBalanceComponent,
    ReportsAttendanceComponent,
    RepossessListComponent,
    ParkingYardComponent,
    ExposureReport1Component,
    AccountInterestCalculationComponent,
    SubComponent,
    FutureDueComponent,
    EmiCalculatorComponent,
    ReleaseRequestListComponent,
    ReleaseAuthorizedListComponent,
    PartnerFutureFlowComponent,
    MachineMACAddressComponent,
    SalesTargetAchievementComponent,
    ReceiptTopupComponent,
    PaymentTopupComponent,
    JournalTopupComponent,
    VoucherTopupComponent,
    CollectionTargetAchievementComponent,
    OverDueInterestDetailComponent,
    FIQuestionSubcardInfoComponent,
    TVRQuestionSubcardInfoComponent,
    CustomerKycSubcardInfoComponent,
    PDQuestionSubcardInfoComponent,
    AppPage403ErrorComponent,
    ResportDumpDataComponent,
    TATReportComponent,
    ReceiptDeductionComponent,
    PdcDetailsComponent,
    PrintCardComponent,
    DeviationInfoComponent,
    SanctionConditionComponent,
    StaffBalanceComponent,
    CollectionEfficeincyDetailComponent,
    PartnerBalanceComponent,
    PartnerAutoCloserComponent,
    BranchWisePlComponent,
    ReceiptDetailComponent,
    LmsWaiverListComponent,
    LmsWaiverCreationComponent,
    SearchLoanComponent,
    LmsWaiverAuthorisedComponent,
    LMSIncomeExpenditureComponent,
    LMSFamilyMemberComponent,
    LoanCustomerListComponent,
    DifferenceEmiAllocationComponent,
    CustomerKycVerificationComponent,
    PdcRepaymentProcessComponent,
    AccuredInterestComponent,
    LmsLoanWriteOffSettledComponent,
    LmsLoanWriteOffSettledDetailsComponent,
    QuickLoanMasterReportComponent,
    ConsumerDurableCategoryComponent,
    ConsumerDurableManufactureComponent,
    ConsumerDurableProductMasterComponent,
    ConsumerDurableModelMasterComponent,
    QuickloanInquiryReportComponent,
    ConsumerDurableCategoryComponent,
    ConsumerDurableManufactureComponent,
    ConsumerDurableProductMasterComponent,
    ConsumerDurableModelMasterComponent,
    EmployeeAssignProductComponent,
    ConsumerDurableCategoryComponent,
    ConsumerDurableManufactureComponent,
    ConsumerDurableProductMasterComponent,
    ConsumerDurableModelMasterComponent,
    QuickloanInquiryReportComponent,
    QuickloanCibilReportComponent,
    ConsumerDurableCategoryComponent,
    ConsumerDurableManufactureComponent,
    ConsumerDurableProductMasterComponent,
    ConsumerDurableModelMasterComponent,
    QuickloanInquiryReportComponent,
    LoanFinancialInfoComponent,
    QuickloanFOIRComponent,
    RBIDNB2ReportComponent,
    LmsLoanFOIRComponent,
    MstBranchProductProcessComponent,
    DateFormatPipe,
    AttachedDocumentComponent,
    FoirCardComponent,
    LmsCaseWiseAccBalanceLoanComponent,
    InsuranceDetailsComponent,
    ChangeLoanNoComponent,
    VehicleGridComponent,
    EnachAuBankComponent,
    FileCheckingMasterComponent,
    QuickloanFileCheckingComponent,
    CibilReportAppComponent,
    SwapNachSourceComponent,
    OwnManagedFundsComponent,
    CustomerCibilVerificationComponent,
    QuickloanAppilcationStatusReportComponent,
    EnachNupayComponent,
    CibilApiTestComponent,
    QuickloanApplicationStatusComponent,
    QuickloanFileCheckingComponent,
    KeyFactsStatementComponent,
    CustomerKycVerificationDetailComponent,
    // QuickloanCustomerLeadComponent,
    GeneratePendingAmortizationComponent,
    FutureFlowDetailComponent,
    LmsDueListPreEmiComponent,
    QuickloanCustomerLeadComponent,
    LmsReceiptBookTeamComponent,
    QuickloanBankAnalysisComponent,
    LmsUnaccountingVoucherComponent,
    PartnerReceiptImportComponent,
    LmsBankAnalysisComponent,
    LmsLoanPrecloserNewComponent,
    LmsLoanPrecloserNewDetailsComponent,
    LmsDeathTheftClaimComponent,
    LmsPersonalDiscussionComponent,
    LmsSanctionConditionComponent,
    LmsFileCheckingComponent,
    LmsUpdateEffectiveIrrComponent,
    ReportsBusinessCollectionSummaryComponent,
    CustomerThirdPartyapiComponent,
    EmployeeLendingAssignmentComponent,
  ],
  imports: [
    BrowserModule,
    NgApexchartsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    AppRoutingModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    PopoverModule.forRoot(),
    MatTableModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatSnackBarModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatExpansionModule,
    MatGridListModule,
    MatTabsModule,
    MatDialogModule,
    DragDropModule,
    MatSlideToggleModule,
    NgMultiSelectDropDownModule,
    ReactiveFormsModule,
    CKEditorModule,
    AngularEditorModule,
    QRCodeModule,
    ModalModule,
    NgxPrintModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAyGt7WO27Ctv_GDgmHopjp3cosOu5eAmg",
    }),
    MatTableExporterModule,
    RouterModule.forRoot(
      [
        {
          path: "New",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "Dashboard",
              component: DashboardComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },

        {
          path: "ReceiptDetail",
          component: ReceiptDetailComponent,
        },
        {
          path: "employee",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "register",
              component: MstemployeenewregisterComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "search",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "customer",
              component: LosSearchCustomerComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },

        {
          path: "customer-profile",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "customer-profile",
              component: NatureOfWorkComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },

        {
          path: "de_dupe",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "de_dupe",
              component: MstDeDupeComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "state",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "list",
              component: MstStateListComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "employee",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "list",
              component: MstemployeelistComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "swap",
              component: MstemployeeswapComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "manager",
              component: MstemployeeManagerSwapComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "hierarchy",
              component: MstemployeehierarchyComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "list",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "product",
              component: MstProductListComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "doc",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "list",
              component: MstDocumentsComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "TVR",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "Questions",
              component: QuestionsTVRComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "FI",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "Questions",
              component: QuestionsFiComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "natureofwork",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "Questions",
              component: QuestionsNatureOfWorkComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },

        {
          path: "file-checking",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "filechecking",
              component: FileCheckingMasterComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },

        {
          path: "asset",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "detail",
              component: ProductassetdetailComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "loan",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "charges",
              component: MstLoanChargesComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "loan-product",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "loan-accounting-category",
              component: LoanAccountingCategoryComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "loan",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "document",
              component: MstLoanDocumentComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "loan-swap",
              component: MstLmsLoanSwapComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "branch",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "employee",
              component: MstBranchEmployeeComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "branch_product_process",
              component: MstBranchProductProcessComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "loan",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "process",
              component: MstLoanProcessComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "mst",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "branches",
              component: MstBranchesComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "courier",
              component: MstcourierComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "partner",
              component: MstPartnerComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "scheme",
              component: MstschemeComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "DesignationMaster",
              component: MstRoleComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "tax-slab",
              component: MstTaxSlabComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "department",
              component: MstDepartmentComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "loanseries",
              component: MstLoanSeriesComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "rolemaster",
              component: MstRoleMasterComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "customer-kyc-verification",
              component: CustomerKycVerificationComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "customer-kyc-verification-detail",
              component: CustomerKycVerificationDetailComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "customer-thirdPartyaApi",
              component: CustomerThirdPartyapiComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "coming",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "soon",
              component: ComingsoonComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        {
          path: "index",
          component: IndexpageComponent,
          children: [
            {
              path: "page",
              component: IndexpageComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        {
          path: "zone",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "list",
              component: MstZoneListComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "district",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "list",
              component: MstDistrictListComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "tahsil",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "list",
              component: MstTahsilListComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "parkingyard",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "list",
              component: ParkingYardComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "sub-area",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "list",
              component: CollectionSubAreaComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "accounting-interest-calculation",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "accounting-interest-calculation",
              component: AccountInterestCalculationComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        {
          path: "area",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "list",
              component: CollectionAreaComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "LOS",
          component: LosAppUserLayoutComponent,
          children: [
            {
              path: "Search/customer",
              component: LosSearchCustomerComponent,
              canActivate: [AuthGuardService],
            },

            {
              path: "search/application",
              component: SearchapplicationComponent,
              canActivate: [AuthGuardService],
            },

            {
              path: "pending-documents/:id",
              component: LosPendingDocumentListComponent,
              canActivate: [UserAuthGuardService],
            },

            {
              path: "losreceiptentry",
              component: LosReceiptEntryComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },

        {
          path: "charges",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "head",
              component: ChargesheadComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "master",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "crc-loan-type",
              component: CrcMasterComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "Common_Master",
              component: CommonMasterComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "insurance-company",
              component: InsuranceCompanyComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "insurance-branch",
              component: InsuranceBranchComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "Machine_Mac_Address",
              component: MachineMACAddressComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "credit",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "policy",
              component: CreditpolicyComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },

        {
          path: "changeloanno",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "changeloanno",
              component: ChangeLoanNoComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "product",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "workflow",
              component: ProductworkflowComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "product",
              component: ProductAppTabLayoutComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "product/:Id",
              component: ProductAppTabLayoutComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "product/:Id/:Add",
              component: ProductAppTabLayoutComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        {
          path: "employee",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "employee",
              component: EmployeeAppTabLayoutComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "employee/:Id",
              component: EmployeeAppTabLayoutComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "employee/:Id/:Add",
              component: EmployeeAppTabLayoutComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        {
          path: "asset",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "asset-info-master",
              component: AssetInfoMasterComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "vehicle-cat-master",
              component: VehicleCategoryComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "vehicle-model-master",
              component: VehicleModelComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "vehicle-grid-master",
              component: VehicleGridComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },

        {
          path: "Consumer",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "durable-cat-master",
              component: ConsumerDurableCategoryComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "durable-manufacture-master",
              component: ConsumerDurableManufactureComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "durable-product-master",
              component: ConsumerDurableProductMasterComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "durable-model-master",
              component: ConsumerDurableModelMasterComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },

        {
          path: "Sanction",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "letter",
              component: SanctionletterTemplateComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        {
          path: "welcome",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "letter",
              component: WelcomeletterTemplateComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        {
          path: "noc",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "letter",
              component: NocletterTemplateComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        {
          path: "template",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "letter",
              component: LegalLetterTemplateComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        {
          path: "rpt",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "ssrs",
              component: RptssrsComponent,
              canActivate: [UserAuthGuardService],
            },
            //{ path: 'chargecollection', component: RptchargecollectionComponent, canActivate: [UserAuthGuardService] },
            {
              path: "userlog",
              component: RptuserlogComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "report-configuration",
              component: RptreportConfigurationComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        {
          path: "rpt",
          component: LosAppUserLayoutComponent,
          children: [
            {
              path: "holdinquiry",
              component: RptHoldInquiryComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        {
          path: "rpt",
          component: LosAppUserLayoutComponent,
          children: [
            {
              path: "pendingdocumentapplicationlist",
              component: LosPendingDocumentApplicationListComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        {
          path: "system",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "configuration",
              component: SystemconfigurationComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "LOS/coming",
          component: LosAppUserLayoutComponent,
          children: [
            {
              path: "soon",
              component: ComingsoonComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },

        {
          path: "lms-dashboard",
          component: LmsDashboardLayoutComponent,
          children: [
            {
              path: "Home",
              component: DashboardComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "dashboard",
              component: LMSDashboardComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "insurance-master",
              component: InsuranceCompanyComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "lms-loan-entry",
              component: LMSLoanEntryComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "lms-nach-entry",
              component: LMSNachEntryComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "lms-updateBooking-list",
              component: LmsBookingListComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "group-master",
              component: GroupMasterComponent,
              canActivate: [AuthGuardService],
            },
            //{ path: 'accounting-master', component: AccountingMasterComponent, canActivate: [UserAuthGuardService] },
            {
              path: "lms-nach-registration",
              component: LmsNachRegistrationComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "lms-nach-process",
              component: LmsNachProcessComponent,
              canActivate: [AuthGuardService],
            },

            {
              path: "swapnachsource",
              component: SwapNachSourceComponent,
              canActivate: [AuthGuardService],
            },

            {
              path: "lms-nach-process",
              component: LmsNachProcessComponent,
              canActivate: [AuthGuardService],
            },

            // { path: 'lms-loanStatement', component: LmsLoanStatementComponent, canActivate: [UserAuthGuardService] },
            {
              path: "lms-modifyBooking-list",
              component: LmsModifyBookingListComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "losreceiptentry",
              component: LosReceiptEntryComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "lms-pdc-registration",
              component: LmsPdcRegistrationComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "due-list",
              component: LmsDueListComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "booking-list",
              component: BookingListComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "pdd-detail",
              component: LmsPddDetailComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "unAccountingVoucher",
              component: LmsUnaccountingVoucherComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "lms-loanStatement-search",
              component: LmsLoanStatementSearchComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "lms-loanStatement",
              component: LmsLoanStatementComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "cibil-api-test",
              component: CibilApiTestComponent,
              canActivate: [UserAuthGuardService],
            },

            {
              path: "pdc-chqdetail",
              component: LmsPdcChequeListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "collectionEx-Assign-list",
              component: LmsCollectionExAssignListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "request-and-task-detail",
              component: RequestAndTaskDetailComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "request-and-task-detail/:id",
              component: RequestAndTaskDetailComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "request-and-task",
              component: RequestAndTaskComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "request-and-task/:Type",
              component: RequestAndTaskComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "receiptbook",
              component: LmsReceiptBookComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "UpdateEffectiveIrr",
              component: LmsUpdateEffectiveIrrComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "receiptbookbyteam",
              component: LmsReceiptBookTeamComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "receipt_book_detail",
              component: ReceiptDetailComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "Receiptimport",
              component: ReceiptImportComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "enach-process",
              component: LmsEnachComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "enach-generated",
              component: EnachGeneratedListComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "enach-response",
              component: LmsEnachResponseComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "enachAU-process",
              component: EnachAuBankComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "enachNupay-process",
              component: EnachNupayComponent,
              canActivate: [AuthGuardService],
            },

            {
              path: "lending-assignment",
              component: LmsLendingAssignmentComponent,
              canActivate: [UserAuthGuardService],
            },

            {
              path: "assigncases",
              component: LegalAssignCasesComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "legalcases",
              component: LegalCasesListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "hearingcases",
              component: LegalHearingCasesComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "legalcases/details/:Id/:LoanId",
              component: LegalTabLayoutComponent,
              canActivate: [UserAuthGuardService],
            },

            {
              path: "loan-delete-authorization",
              component: LoanDeleteAuthorizationComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "npa-mark",
              component: LmsNpaMarkingComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "repossess-list",
              component: RepossessListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "release-request-list",
              component: ReleaseRequestListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "release-authorized-list",
              component: ReleaseAuthorizedListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "waiver-request",
              component: LmsWaiverListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "waiver-request",
              component: LmsWaiverCreationComponent,
              canActivate: [UserAuthGuardService],
            },
            // { path: 'search-  ', component: SearchLoanComponent,canActivate: [UserAuthGuardService] },
            // { path: '', redirectTo: '/waiver-list', pathMatch: 'full' }
            {
              path: "waiver-authorized",
              component: LmsWaiverAuthorisedComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "lms-pdc-process",
              component: PdcRepaymentProcessComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "due-Pre-EMI-Interest",
              component: LmsDueListPreEmiComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        {
          path: "quickloan-dashboard",
          component: QuickLoanLayoutComponent,
          children: [
            {
              path: "Home",
              component: DashboardComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "dashboard",
              component: QuickLoanDashboardComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "MainCardInfo/:id",
              component: MainCardInfoComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "quick-loan/list",
              component: QuickloanListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "quick-loan/list/:Pid",
              component: QuickloanListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "quick-loan/list/:Pid/:PName",
              component: QuickloanListComponent,
              canActivate: [UserAuthGuardService],
            },

            {
              path: "quick-loan/details/:Pid/:Prd_id",
              component: QuickloanTabLayoutComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "quick-loan/details/:Pid/:Prd_id/:id/:Status/:RevertedProcessId",
              component: QuickloanTabLayoutComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "quick-loan/details/:Pid/:Prd_id/:id/:Status/:RevertedProcessId/:RevertedProcessName/:RevertedEmployeeName/:IsEdit",
              component: QuickloanTabLayoutComponent,
              canActivate: [UserAuthGuardService],
              children: [
                // { path: 'message', component: ViewMessageComponent, canActivate: [UserAuthGuardService], },
                // { path: 'name', component: ViewNamesComponent, canActivate: [UserAuthGuardService],},
                // { path: 'allName', component: ViewAllEmployeeNameComponent, canActivate: [UserAuthGuardService], },
              ],
            },
            {
              path: "quick-loan/details/:Pid/:Prd_id/:inquiry",
              component: QuickloanTabLayoutComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "quick-loan/DeviationList/:Pid",
              component: QuickloanDeviationListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "file-receive-to-ho/:Pid/:id",
              component: QuickloanFileReceiveToHODetailComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "quickloan-tat-report",
              component: QuickloanTatReportComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "quick-loan/inquiry",
              component: QuickLoanInquiryListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "quick-loan/my-lead",
              component: QuickLoanMyleadListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "quick-loan/my-lead/:id",
              component: QuickLoanMyLeadtDetailComponent,
              canActivate: [UserAuthGuardService],
            },
            /* { path: 'quick-loan/legal-process', component: QuickLoanLegalProcessComponent, canActivate: [UserAuthGuardService] },*/
            {
              path: "quickloan-master-report",
              component: QuickLoanMasterReportComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "quickloan-lead-report",
              component: QuickloanInquiryReportComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "quickloan-applicationstatus-report",
              component: QuickloanAppilcationStatusReportComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "quickloan-application-process-status",
              component: QuickloanApplicationStatusComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "quickloan-customer-lead",
              component: QuickloanCustomerLeadComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        {
          path: "schedule",
          component: LmsDashboardLayoutComponent,
          children: [
            {
              path: "regenerate",
              component: LmsScheduleRegenerateComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "regenerate/:loanId",
              component: LmsScheduleRegenerateDetailsComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        {
          path: "loan",
          component: LmsDashboardLayoutComponent,
          children: [
            {
              path: "reschedule/:isMaker",
              component: LoanresheduleComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "reschedule/:isMaker/:loanId/:requestId",
              component: LoanRescheduleDetailsComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        {
          path: "loan",
          component: LmsDashboardLayoutComponent,
          children: [
            {
              path: "precloser/:isMaker",
              component: LmsLoanPrecloserComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "precloser/:isMaker/:loanId/:closerId",
              component: LmsLoanPrecloserDetailsComponent,
              canActivate: [UserAuthGuardService],
            },

            {
              path: "precloserNew/:isMaker",
              component: LmsLoanPrecloserNewComponent,
              canActivate: [UserAuthGuardService],
            },

            {
              path: "precloserNew/:isMaker/:loanId/:closerId",
              component: LmsLoanPrecloserNewDetailsComponent,
              canActivate: [UserAuthGuardService],
            },

            {
              path: "undo",
              component: LmsLoanUndoComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "undo/:loanId/:closerId",
              component: LmsLoanUndoDetailsComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "auto",
              component: LmsLoanAutoCloserComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "closecaselist",
              component: LmsLoanClosedListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "loandeleterequest",
              component: LoanDeleteRequestComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "WriteOff_Settled",
              component: LmsLoanWriteOffSettledComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "WriteOff_Settled/:loanId/:closerId",
              component: LmsLoanWriteOffSettledDetailsComponent,
              canActivate: [UserAuthGuardService],
            },

            {
              path: "deaththeftlossclaim",
              component: LmsDeathTheftClaimComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        {
          path: "lms-accounting",
          component: LmsAccountingLayoutComponent,
          children: [
            {
              path: "Home",
              component: DashboardComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "dashboard-accouning",
              component: LMSDashboardAccountingComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "accounting-interest-calculation",
              component: AccountInterestCalculationComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "group-master",
              component: GroupMasterComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "accounting-master",
              component: AccountingMasterComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "accounting-master/:id",
              component: AccountMasterDetailComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "losreceiptentry",
              component: LosReceiptEntryComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "accounting-statement",
              component: AccountingStatementComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "bank-book",
              component: BankBookComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "cash-book",
              component: CashBookComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "trial-balance",
              component: TrialBalanceComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "reconcile-balance",
              component: ReconcileBalanceComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "balance-sheet",
              component: BalanceSheetComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "day-book",
              component: DayBookComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "receipt-entry-authorization",
              component: ReceiptAuthenticationComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "staff_account_balance",
              component: StaffBalanceComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "partner_account_balance",
              component: PartnerBalanceComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "branchwise-balancesheet",
              component: BranchWisePlComponent,
              canActivate: [AuthGuardService],
            },

            {
              path: "voucher",
              component: VoucherEntryLayoutComponent,
              children: [
                {
                  path: "voucher-entry",
                  component: VoucherEntryComponent,
                  canActivate: [AuthGuardService],
                },
              ],
            },
            {
              path: "topup",
              component: VoucherTopupComponent,
              children: [
                {
                  path: "topup-receipt",
                  component: ReceiptTopupComponent,
                  canActivate: [AuthGuardService],
                },
                {
                  path: "topup-payment",
                  component: PaymentTopupComponent,
                  canActivate: [UserAuthGuardService],
                },
                {
                  path: "topup-journal",
                  component: JournalTopupComponent,
                  canActivate: [UserAuthGuardService],
                },
                // { path: 'topup-entry', component: EntryTopupComponent, canActivate: [UserAuthGuardService] },
              ],
            },
            {
              path: "voucherNew",
              component: VoucherLayoutComponent,
              children: [
                {
                  path: "voucher-receipt",
                  component: ReceiptVoucherComponent,
                  canActivate: [AuthGuardService],
                },
                {
                  path: "voucher-payment",
                  component: PaymentVoucherComponent,
                  canActivate: [UserAuthGuardService],
                },
                {
                  path: "voucher-journal",
                  component: JournalVoucherComponent,
                  canActivate: [UserAuthGuardService],
                },
                {
                  path: "voucher-contra",
                  component: ContraVoucherComponent,
                  canActivate: [UserAuthGuardService],
                },
                {
                  path: "voucher-entry",
                  component: EntryVoucherComponent,
                  canActivate: [AuthGuardService],
                },
              ],
            },
            {
              path: "Voucher_Trail",
              component: VoucherTrailComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "gst-detail",
              component: GstDetailComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "casewise_account_balance",
              component: LmsCaseWiseAccBalanceComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "loan_wise_acc_balance",
              component: LmsCaseWiseAccBalanceLoanComponent,
              canActivate: [AuthGuardService],
            },

            {
              path: "schedule-voucher-list",
              component: ScheduleTransactionComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "schedule-master-list",
              component: ScheduleMasterListComponent,
              canActivate: [AuthGuardService],
            },
            // { path: 'receipt-authentication', component: ReceiptAuthenticationComponent, canActivate: [UserAuthGuardService] },
          ],
        },
        {
          path: "reporting",
          component: LmsReportingLayoutComponent,
          children: [
            {
              path: "Home",
              component: DashboardComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "dashboard-reporting",
              component: LMSDashboardReportingComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "loan-balance",
              component: LoanBalanceComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "due-list",
              component: DueListComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "due-Pre-EMI-Interest",
              component: LmsDueListPreEmiComponent,
              canActivate: [AuthGuardService],
            },

            {
              path: "booking-list",
              component: BookingListComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "business-report",
              component: BusinessWiseComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "portfolio-report",
              component: PortfolioWiseComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "business-vs-collection",
              component: BusVsCollectionComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "month-wise-due-vs-collection",
              component: MonthWiseDueVsCollectionComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "branch-wise-due-vs-collection",
              component: BranchWiseDueVsCollectionComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "par-report",
              component: ParReportComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "branch-wise",
              component: ComingsoonComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "due-principle-wise",
              component: ComingsoonComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "due-Installment-wise",
              component: ComingsoonComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "business-principle-wise",
              component: ComingsoonComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "business-Installment-wise",
              component: ComingsoonComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "crc-report",
              component: CrcreportComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "cibil-commercial",
              component: CibilCommercialReportComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "masterreport",
              component: ResportDumpDataComponent,
              canActivate: [AuthGuardService],
            },

            {
              path: "npa-detail-report",
              component: NpaDetailReportComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "npa-summary-report",
              component: NpaSummaryReportComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "crif-report",
              component: CrifReportComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "loan-balance-multi",
              component: LoanBalanceMultiComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "collection-efficiency",
              component: CollectionEfficiencyComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "loan-customer-report",
              component: LoanCustomerListComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "difference-emi-allocation",
              component: DifferenceEmiAllocationComponent,
              canActivate: [AuthGuardService],
            },

            {
              path: "OverDueInterestDetail",
              component: OverDueInterestDetailComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "assets-detail-list",
              component: AssetsDetailsComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "insurance-detail-list",
              component: InsuranceDetailsComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "exposure-report-byplan",
              component: ExposureReportComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "future-flow-report",
              component: FutureFlowComponent,
              canActivate: [AuthGuardService],
            },

            {
              path: "future-flow-detail",
              component: FutureFlowDetailComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "attendance-report",
              component: ReportsAttendanceComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "exposure-report-byvoucher",
              component: ExposureReport1Component,
              canActivate: [AuthGuardService],
            },
            {
              path: "futuredue-report",
              component: FutureDueComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "sales-target-achievement",
              component: SalesTargetAchievementComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "Collection-target-achievement",
              component: CollectionTargetAchievementComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "collectionefficiencydetail",
              component: CollectionEfficeincyDetailComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "Accured_Interest-report",
              component: AccuredInterestComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "rbi_dnb2-report",
              component: RBIDNB2ReportComponent,
              canActivate: [AuthGuardService],
            },

            {
              path: "own-managed-report",
              component: OwnManagedFundsComponent,
              canActivate: [AuthGuardService],
            },

            {
              path: "customer-cibil-verification",
              component: CustomerCibilVerificationComponent,
              canActivate: [AuthGuardService],
            },

            {
              path: "businessandcollectionbyteam",
              component: ReportsBusinessCollectionSummaryComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "partner-dashboard",
          component: PartnerLayoutComponent,
          children: [
            {
              path: "Home",
              component: DashboardComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "dashboard",
              component: PartnerDashboardComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "partner-updateBooking-list",
              component: PartnerUpdateBookingListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "partner-modifyBooking-list",
              component: PartnerModifyBookingListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "Receiptimport",
              component: PartnerReceiptImportComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "loan-info/:id/:Type",
              component: PartnerLayoutTabComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "partner-booking-list",
              component: PartnerBookingListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "partner-due-list",
              component: PartnerDueListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "bulk-payment",
              component: PartnerBulkPaymentComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "single-payment",
              component: PartnerSinglePaymentComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "partner_loan_compare_report",
              component: PartnerLoanCompareComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "partner_emi_compare_report",
              component: PartnerEmiCompareComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "pre-closer",
              component: PartnerLoanPreCloserComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "pre-closer/:loanId/:closerId",
              component: PartnerLoanPreCloserDetailsComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "partner_portfolio_report",
              component: PartnerPortfolioWiseComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "Partner_Loan_Balance_Report",
              component: PartnerLoanBalanceComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "Partner-future-flow-report",
              component: PartnerFutureFlowComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "auto-closer",
              component: PartnerAutoCloserComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        {
          path: "lms",
          component: LmsDashboardLayoutComponent,
          children: [
            {
              path: "loan-info",
              component: LmsAppLayoutComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "loan-info/:id/:Type",
              component: LmsAppLayoutComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "dvlist",
              component: LMSDvListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "dvauthorize",
              component: LmsDvAuthorizeListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "dvdetail/:id/:vid",
              component: LmsAppDvDetailComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "dvdetail/:id/:vid/:Edit",
              component: LmsAppDvDetailComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "dvauthorize/:id/:vid",
              component: LmsDvAuthorizeDetailComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "dvauthorize/:id/:vid/:page",
              component: LmsDvAuthorizeDetailComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "printdetail/:id",
              component: LmsLoanDisbursmentDetailComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "dvstatus",
              component: LmsDvStatusComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "dvedit",
              component: DveditComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "paymentauthorization",
              component: LmsPaymentAuthorizationListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "paymentauthorization/:status",
              component: LmsPaymentAuthorizationListComponent,
              canActivate: [UserAuthGuardService],
            },

            {
              path: "paymentauthorization/:id",
              component: LmsPaymentAuthorizationComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "Pending-Disbursment",
              component: PaymentListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "pending-generate-amortization",
              component: GeneratePendingAmortizationComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "disbursment-payment-detail/:id/:status",
              component: LmsLoanDisbursmentPaymentDetailComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "paymentinitiation",
              component: LmsPaymentInitiationListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "Payment-Initiation-detail/:id/:status/:pendingAmount",
              component: LmsPaymentInitiationDetailComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "search/customer",
              component: LosSearchCustomerComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "customer-profile/customer-profile",
              component: NatureOfWorkComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "de_dupe/de_dupe",
              component: MstDeDupeComponent,
              canActivate: [AuthGuardService],
            },
            // { path: 'lms-customer', component: LmsCustomerEntryComponent, canActivate: [UserAuthGuardService] },
            // { path: 'lms-customer/:id/:LA-No/:Type', component: LmsCustomerEntryComponent, canActivate: [UserAuthGuardService] },
            // { path: 'lms-account', component: AccountComponent, canActivate: [UserAuthGuardService] },
            // { path: 'lms-account/:id/:LA-No/:Type', component: AccountComponent, canActivate: [UserAuthGuardService] },
            // { path: 'asset-info', component: PostAssetInfoComponent, canActivate: [UserAuthGuardService] },
            // { path: 'asset-info/:id/:LA-No/:Type', component: PostAssetInfoComponent, canActivate: [UserAuthGuardService] },
            // { path: 'Cheque-Details', component: ChequeDetailsComponent, canActivate: [UserAuthGuardService] },
            // { path: 'Cheque-Details/:id/:LA-No/:Type', component: ChequeDetailsComponent, canActivate: [UserAuthGuardService] },
            // { path: 'lms-crc-list', component: LmsCRCListComponent, canActivate: [UserAuthGuardService] },
            // { path: 'lms-crc-list/:id/:LA-No/:cid', component: LmsCRCListComponent, canActivate: [UserAuthGuardService] },
            //{ path: 'lms-crc-fill', component: LmsCrcFillComponent, canActivate: [UserAuthGuardService] },
            //{ path: 'lms-crc-fill/:id/:cid', component: LmsCrcFillComponent, canActivate: [UserAuthGuardService] },
            // { path: 'lms-fi-tvr', component: LmsFiTvrComponent, canActivate: [UserAuthGuardService] },
            // { path: 'lms-fi-tvr/:id/:LA-No/:Type', component: LmsFiTvrComponent, canActivate: [UserAuthGuardService] },
            // { path: 'lms-Note-Recommendation', component: LmsNoteRecommendationComponent, canActivate: [UserAuthGuardService] },
            // { path: 'lms-Note-Recommendation/:id/:LA-No/:Type', component: LmsNoteRecommendationComponent, canActivate: [UserAuthGuardService] },
            // { path: 'lms-insurance', component: LmsInsuranceComponent, canActivate: [UserAuthGuardService] },
            // { path: 'lms-insurance/:id/:LA-No/:Type', component: LmsInsuranceComponent, canActivate: [UserAuthGuardService] },
            // { path: 'lms-amortization', component: LmsAmortizationComponent, canActivate: [UserAuthGuardService] },
            // { path: 'lms-amortization/:id/:LA-No/:Type', component: LmsAmortizationComponent, canActivate: [UserAuthGuardService] },
            // { path: 'lms-dev-report', component: LmsDevReportComponent, canActivate: [UserAuthGuardService] },
            // { path: 'lms-dev-report/:id/:LA-No/:Type', component: LmsDevReportComponent, canActivate: [UserAuthGuardService] },
            // { path: 'lms-repayment-schedule', component: LmsRepaymentScheduleReportComponent, canActivate: [UserAuthGuardService] },
            // { path: 'lms-repayment-schedule/:id/:LA-No/:Type', component: LmsRepaymentScheduleReportComponent, canActivate: [UserAuthGuardService] },
          ],
        },
        {
          path: "deposit-dashboard",
          component: DepositLayoutComponent,
          children: [
            {
              path: "Home",
              component: DashboardComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "dashboard",
              component: DepositDashboardComponent,
              canActivate: [AuthGuardService],
            },
            {
              path: "Product-List",
              component: DepositProductListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "Product",
              component: DepositLayoutTabComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "Product/:Id",
              component: DepositLayoutTabComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "Deposit-List",
              component: DepositListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "Deposit-Info",
              component: DepositInfoLayoutTabComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "Deposit-Detail",
              component: DepositDetailComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "Deposit-Info/:id",
              component: DepositInfoLayoutTabComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "deposit-authorize",
              component: DepositAuthorizeListComponent,
              canActivate: [UserAuthGuardService],
            },
            {
              path: "Deposit-Info/:id/:page/:type",
              component: DepositInfoLayoutTabComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        {
          path: "court",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "list",
              component: CourtListComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "legalCaseType",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "list",
              component: CaseTypeListComponent,
              canActivate: [AuthGuardService],
            },
          ],
        },
        {
          path: "Unauthorized",
          component: AppUserLayoutComponent,
          children: [
            {
              path: "access",
              component: UnauthorizedComponent,
              canActivate: [UserAuthGuardService],
            },
          ],
        },
        // { path: 'Disbursment/Detail/: id', component: LmsLoanDisbursmentDetailComponent },
        //{ path: 'print/:LoanId', component: LoanStatementComponent },
        { path: "FeeReceipt", component: LoginfeereceiptComponent },
        //{ path: 'receivervideo/:id', component: ReceivervideoComponent },
        { path: "Home", component: HomePageComponent },
        { path: "GeoLocation/:lat/:lng", component: GeoLocationComponent },
        { path: "Login", component: LoginComponent },
        { path: "ED", component: EDComponent },
        { path: "SUB", component: SubComponent },
        { path: "Page403Error", component: AppPage403ErrorComponent },

        { path: "UserLogout", component: UserLogoutComponent },
        /* temp for testing the component*/

        // testing component end here
        { path: "SanctionCondition", component: SanctionConditionsComponent },
        { path: "cibilReport/:id/:cid", component: CibilReportAppComponent },
        { path: "", redirectTo: "Home", pathMatch: "full" },
        { path: "**", component: AppPageNotFoundComponent },
      ],
      { useHash: true, relativeLinkResolution: "legacy" }
    ),
  ],
  exports: [
    BsDatepickerModule,
    TimepickerModule,
    PopoverModule,
    PhoneMaskDirective,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: BsDatepickerConfig, useFactory: getDatepickerConfig },
    DatePipe,
    AdminAuthGuardService,
    AuthGuardService,
    UserAuthGuardService,
    DataSharingService,
    EncrDecrService,
    BsModalService,
  ],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
