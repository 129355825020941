<div>
  <div [class.FixHeight]="PageAcess[0].View == 0">
    <div class="row m-0 mt-2" *ngIf="PageAcess[0].View == 1">
      <div class="col-md-12">
        <div class="table-responsive">
          <mat-table
            [dataSource]="dataSource"
            matSort
            matTableExporter
            #exporter="matTableExporter"
            style="height: 190px; max-width: 100%; overflow: auto"
          >
            <ng-container matColumnDef="AssetId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 100px"
                *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 100px; max-height: 5px"
              >
                {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Collateral_Type">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Collateral Type</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Collateral_Type }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Collateral">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Collateral</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Collateral }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ValuationType">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Valuation Type</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.ValuationType }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ValuationAmount">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Valuation Amount</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.ValuationAmount }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ValuationDate">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Valuation Date</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.ValuationDate }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="View_Detail">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >View Detail</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                <i
                  class="fa fa-eye"
                  style="font-size: large; cursor: pointer"
                  (click)="ViewData(row)"
                  aria-label="true"
                  [class.spinner]="loading"
                ></i>
                <i
                  class="fa fa-trash style-delete ml-2"
                  (click)="Delete_AssetInfo(row)"
                  *ngIf="
                    PageAcess &&
                    PageAcess[0].Delete == 1 &&
                    Status != 'C' &&
                    !loanSummary.CloseDate
                  "
                  style="cursor: pointer"
                  aria-label="true"
                ></i>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>

          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>

  <div class="row m-0 mt-2">
    <div class="col-md-6">
      <div *ngIf="PageAcess[0].View == 1">
        <button
          type="button"
          (click)="OpenModel()"
          mat-raised-button
          class="mt-3 btn font-size-12 button-btn"
          [class.spinner]="loading"
          *ngIf="
            loanSummary.Product_Category != 'Un-Secured Loan' &&
            PageAcess[0].Add == 1 &&
            PrimaryCount < 1 &&
            !loanSummary.CloseDate
          "
        >
          Add Primary Collateral
        </button>
        <button
          type="button"
          (click)="OpenModel_Additional_Collateral()"
          mat-raised-button
          [hidden]="loanSummary.Product_Category == 'Consumer Durable Loan'"
          class="mt-3 btn font-size-12 button-btn"
          [class.spinner]="loading"
          *ngIf="PageAcess[0].Add == 1 && !loanSummary.CloseDate"
          [ngClass]="PrimaryCount < 1 ? 'ml-2' : ''"
        >
          Add Additional Collateral
        </button>
      </div>
    </div>
    <div class="col-md-6 text-right">
      <button
        type="button"
        (click)="next()"
        class="mt-3 btn font-size-12 button-btn"
        [class.spinner]="loading"
      >
        Next <i class="fa fa-arrow-right"></i>
      </button>
    </div>
  </div>
</div>

<!--start popup form add Primary collateral -->
<div
  class="modal fade in"
  id="kycModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Primary Collateral
        </h6>
        <button
          type="button"
          (click)="onCloseKYC()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="AssetsForm == 'Form'">
          <form #tf="ngForm" novalidate class="formborder">
            <div
              class="row mt-4 ml-3"
              *ngIf="AgricultureForm == 'AgricultureForm'"
            >
              <div class="col-md-6">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    checked
                    (change)="selectAgricultureForm('Property')"
                    type="radio"
                    name="inlineRadioOptionsA"
                    id="inlineRadioA1"
                    value="Property"
                  />
                  <label class="form-check-label" for="inlineRadioA1"
                    >Property</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    (change)="selectAgricultureForm('Vehicle')"
                    type="radio"
                    name="inlineRadioOptionsA"
                    id="inlineRadioA2"
                    value="Vehicle"
                  />
                  <label class="form-check-label" for="inlineRadioA2"
                    >Vehicle</label
                  >
                </div>
              </div>
            </div>

            <ng-container *ngIf="propertyForm == 'Vehicle'">
              <div class="row mt-2">
                <div class="col-md-5">
                  <div class="form-check-inline">
                    <input
                      class="form-check-input"
                      [(ngModel)]="vehicle_type"
                      (change)="selectVehicleType('New')"
                      type="radio"
                      name="inlineRadioOptions"
                      id="NewVehicle"
                      value="New"
                    />
                    <label class="form-check-label" for="NewVehicle"
                      >New Vehicle</label
                    >
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      [(ngModel)]="vehicle_type"
                      (change)="selectVehicleType('Used')"
                      type="radio"
                      name="inlineRadioOptions"
                      id="UsedVehicle"
                      value="Used"
                    />
                    <label class="form-check-label" for="UsedVehicle"
                      >Used Vehicle</label
                    >
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-check-inline">
                    <input
                      class="form-check-input"
                      [(ngModel)]="commercialVehicle"
                      (change)="selectVehicleType('Commercial')"
                      type="radio"
                      name="inlineRadioOptions1"
                      id="Commercial"
                      value="Commercial"
                    />
                    <label class="form-check-label" for="Commercial"
                      >Commercial</label
                    >
                  </div>
                  <div class="form-check-inline">
                    <input
                      class="form-check-input"
                      [(ngModel)]="commercialVehicle"
                      (change)="selectVehicleType('Non-Commercial')"
                      type="radio"
                      name="inlineRadioOptions1"
                      id="NonCommercial"
                      value="Non-Commercial"
                    />
                    <label class="form-check-label" for="NonCommercial"
                      >Non Commercial</label
                    >
                  </div>
                </div>

                <div class="col-md-2" *ngIf="usedVehicle">
                  <button
                    class="btn font-size-12 button-btn"
                    (click)="viewVehicleGridModal()"
                  >
                    Grid
                  </button>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-3">
                  <span class="required-lable">Vehicle Manufacture</span>

                  <select
                    name="VehicleManufacture1"
                    id="VehicleManufacture1"
                    #refVehicleManufacture1="ngModel"
                    (change)="LMS_GetVehicleModelMaster($event)"
                    [ngClass]="{
                      'is-invalid':
                        tf.submitted && refVehicleManufacture1.invalid,
                      'alert-warning':
                        refVehicleManufacture1.invalid &&
                        (refVehicleManufacture1.dirty ||
                          refVehicleManufacture1.touched ||
                          refVehicleManufacture1.untouched)
                    }"
                    [(ngModel)]="VehicleManufacture"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Vehicle Manufacture</option>
                    <option
                      *ngFor="let data of ManufactureData"
                      [value]="data.Id"
                    >
                      {{ data.Manufacture }}
                    </option>
                  </select>
                </div>
                <div class="col-md-3">
                  <span class="required-lable">Vehicle Category</span>
                  <select
                    name="SelectVehicleCategory"
                    id="SelectVehicleCategory"
                    (change)="LMS_GetVehicleModelMaster($event)"
                    #refSelectVehicleCategory="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        tf.submitted && refSelectVehicleCategory.invalid,
                      'alert-warning':
                        refSelectVehicleCategory.invalid &&
                        (refSelectVehicleCategory.dirty ||
                          refSelectVehicleCategory.touched ||
                          refSelectVehicleCategory.untouched)
                    }"
                    [(ngModel)]="SelectVehicleCategory"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Vehicle Category</option>
                    <option
                      *ngFor="let cat of vehicleCategoryData"
                      [value]="cat.Id"
                    >
                      {{ cat.Vehicle_Category }}
                    </option>
                  </select>
                </div>
                <div class="col-md-3">
                  <span class="required-lable">Vehicle Model Name</span>
                  <select
                    name="VehicleModel"
                    id="VehicleModel"
                    #refVehicleModel="ngModel"
                    [ngClass]="{
                      'is-invalid': tf.submitted && refVehicleModel.invalid,
                      'alert-warning':
                        refVehicleModel.invalid &&
                        (refVehicleModel.dirty ||
                          refVehicleModel.touched ||
                          refVehicleModel.untouched)
                    }"
                    [(ngModel)]="VehicleModel"
                    class="form-control input-text-css"
                    required
                    (ngModelchange)="OnChangeVehicleMOdel()"
                  >
                    <option value="">Vehicle Model Name</option>
                    <option
                      *ngFor="let data of vehicleModelData"
                      [value]="data.Id"
                    >
                      {{ data.Model_Name }}
                    </option>
                  </select>
                </div>

                <div class="col-md-3">
                  <span class="required-lable">Variant</span>
                  <input
                    type="text"
                    #refVariant="ngModel"
                    [(ngModel)]="Variant"
                    name="Variant"
                    id="Variant"
                    class="form-control input-text-css"
                    placeholder="Variant"
                  />
                </div>
                <div class="col-md-3">
                  <span class="required-lable">Manufacture Year</span>

                  <input
                    name="ManufactureYear"
                    [(ngModel)]="ManufactureYear"
                    id="ManufactureYear"
                    placeholder="Start Year"
                    class="form-control input-text-css"
                    bsDatepicker
                    [bsConfig]="bsConfig"
                    required
                    #refManufactureYear="ngModel"
                    (ngModelChange)="OnChangeyear()"
                    [ngClass]="{
                      'is-invalid': tf.submitted && refManufactureYear.invalid,
                      'alert-warning':
                        '' &&
                        (refManufactureYear.dirty ||
                          refManufactureYear.touched ||
                          refManufactureYear.untouched)
                    }"
                  />

                  <!-- <input
        name="ManufactureYear"
        id="ManufactureYear"
        #refManufactureYear="ngModel"
        maxlength="4"
        minlength="4"
        numbersOnly
        [ngClass]="{
          'is-invalid': tf.submitted && refManufactureYear.invalid,
          'alert-warning':
            '' &&
            (refManufactureYear.dirty ||
              refManufactureYear.touched ||
              refManufactureYear.untouched)
        }"
        [(ngModel)]="ManufactureYear"
        class="form-control input-text-css"
        placeholder="Manufacture Year"
      /> -->
                </div>
                <div class="col-md-3">
                  <span class="">Vehicle Registation No</span>
                  <input
                    type="text"
                    #refVehicleRegistationNo="ngModel"
                    (keypress)="onKeyPress($event)"
                    (change)="checkDuplication_Vehicle('RegistationNo')"
                    [(ngModel)]="VehicleRegistationNo"
                    name="VehicleRegistationNo"
                    id="VehicleRegistationNo"
                    class="form-control input-text-css"
                    placeholder="Vehicle Registation No"
                  />
                </div>

                <div class="col-md-3">
                  <span class="">Registation Date</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="RegistationDate"
                      id="RegistationDate"
                      #refRegistationDate="ngModel"
                      (dateChange)="ChangeRegistationDate($event)"
                      [(ngModel)]="RegistationDate"
                      class="form-control input-text-css"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </div>
                </div>
                <div class="col-md-3">
                  <span class="">Registation Expiry Date</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker2"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="RegistationExpiryDate"
                      id="RegistationExpiryDate"
                      #refRegistationExpiryDate="ngModel"
                      (dateChange)="ChangeExpiryDate($event)"
                      [min]="RegistationDate"
                      [(ngModel)]="RegistationExpiryDate"
                      class="form-control input-text-css"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker2"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </div>
                  <!-- <input
        name="RegistationExpiryDate"
        id="RegistationExpiryDate"
        #refRegistationExpiryDate="ngModel"
        (ngModelChange)="ChangeExpiryDate($event)"
        [minDate]="RegistationDate"
        [(ngModel)]="RegistationExpiryDate"
        value="{{ RegistationExpiryDate | date : 'dd/MM/yyyy' }}"
        class="form-control input-text-css"
        [(bsValue)]="RegistationExpiryDate"
        bsDatepicker
        placeholder="Registation Expiry Date"
      /> -->
                </div>
                <div class="col-md-3">
                  <span class="required-lable">Fuel Type</span>
                  <select
                    name="FuelType"
                    id="SelectGender"
                    #refFuelType="ngModel"
                    [(ngModel)]="SelectFuelType"
                    [ngClass]="{
                      'is-invalid': tf.submitted && refFuelType.invalid,
                      'alert-warning':
                        refFuelType.invalid &&
                        (refFuelType.dirty ||
                          refFuelType.touched ||
                          refFuelType.untouched)
                    }"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Fuel Type</option>
                    <option
                      *ngFor="let Type of FuelTypeOption"
                      [value]="Type.Id"
                    >
                      {{ Type.Value }}
                    </option>
                  </select>
                </div>

                <div class="col-md-3" *ngIf="commercialVehicle == 'Commercial'">
                  <span class="">Road Tax Upto</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker3"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="RoadTaxUpto"
                      id="RoadTaxUpto"
                      #refRoadTaxUpto="ngModel"
                      [(ngModel)]="RoadTaxUpto"
                      class="form-control input-text-css"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker3"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                  </div>
                  <!-- <input
        name="RoadTaxUpto"
        id="RoadTaxUpto"
        #refRoadTaxUpto="ngModel"
        [(ngModel)]="RoadTaxUpto"
        value="{{ RoadTaxUpto | date : 'dd/MM/yyyy' }}"
        placeholder="Road Tax Upto"
        class="form-control input-text-css"
        [(bsValue)]="RoadTaxUpto"
        bsDatepicker
      /> -->
                </div>
                <div class="col-md-3">
                  <span class="required-lable">Color</span>
                  <input
                    required
                    type="text"
                    #refClr="ngModel"
                    [ngClass]="{
                      'is-invalid': tf.submitted && refClr.invalid,
                      'alert-warning':
                        refClr.invalid &&
                        (refClr.dirty || refClr.touched || refClr.untouched)
                    }"
                    [(ngModel)]="Color"
                    name="Clr"
                    id="Clr"
                    class="form-control input-text-css"
                    placeholder="Color"
                  />
                </div>
                <div class="col-md-3" *ngIf="commercialVehicle == 'Commercial'">
                  <span class="">Fitness Upto</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker4"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="FitnessUpto"
                      id="FitnessUpto"
                      #refFitnessUpto="ngModel"
                      [(ngModel)]="FitnessUpto"
                      class="form-control input-text-css"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker4"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker4></mat-datepicker>
                  </div>
                  <!-- <input
        name="FitnessUpto"
        id="FitnessUpto"
        #refFitnessUpto="ngModel"
        [(ngModel)]="FitnessUpto"
        value="{{ FitnessUpto | date : 'dd/MM/yyyy' }}"
        placeholder="Fitness Upto"
        class="form-control input-text-css"
        [(bsValue)]="FitnessUpto"
        bsDatepicker
      /> -->
                </div>

                <div class="col-md-3" *ngIf="commercialVehicle == 'Commercial'">
                  <span class="">Permit Upto</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker5"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="PermitUpto"
                      id="PermitUpto"
                      #refPermitUpto="ngModel"
                      class="form-control input-text-css"
                      [(ngModel)]="PermitUpto"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker5"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker5></mat-datepicker>
                  </div>
                  <!-- <input
        name="PermitUpto"
        id="PermitUpto"
        #refPermitUpto="ngModel"
        placeholder="Permit Upto"
        [(ngModel)]="PermitUpto"
        value="{{ PermitUpto | date : 'dd/MM/yyyy' }}"
        class="form-control input-text-css"
        [(bsValue)]="PermitUpto"
        bsDatepicker
      /> -->
                </div>

                <div class="col-md-3">
                  <span class="required-lable">Vehicle Cost</span>
                  <input
                    required
                    type="number"
                    #reVC="ngModel"
                    min="0"
                    numbersOnly
                    placeholder="Vehicle Cost"
                    [ngClass]="{
                      'is-invalid': tf.submitted && reVC.invalid,
                      'alert-warning':
                        reVC.invalid &&
                        (reVC.dirty || reVC.touched || reVC.untouched)
                    }"
                    [(ngModel)]="VehicleCost"
                    name="VC"
                    id="VC"
                    class="form-control input-text-css"
                  />
                </div>

                <div class="col-md-3" *ngIf="commercialVehicle == 'Commercial'">
                  <span class="">Route</span>
                  <input
                    type="text"
                    #refRoute="ngModel"
                    placeholder="Route"
                    [ngClass]="{
                      'is-invalid': tf.submitted && refRoute.invalid,
                      'alert-warning':
                        '' &&
                        (refRoute.dirty ||
                          refRoute.touched ||
                          refRoute.untouched)
                    }"
                    [(ngModel)]="Route"
                    name="Route"
                    id="Route"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-3">
                  <span class="">Engine No</span>
                  <input
                    type="text"
                    #refEngineNo="ngModel"
                    [required]="
                      (InvoiceNo != '' && InvoiceNo != null) ||
                      (VehicleRegistationNo != '' &&
                        VehicleRegistationNo != null)
                    "
                    placeholder="Engine No"
                    (change)="checkDuplication_Vehicle('EngineNo')"
                    [ngClass]="{
                      'is-invalid': tf.submitted && refEngineNo.invalid,
                      'alert-warning':
                        refEngineNo.invalid &&
                        (refEngineNo.dirty ||
                          refEngineNo.touched ||
                          refEngineNo.untouched)
                    }"
                    [(ngModel)]="EngineNo"
                    name="ENO"
                    id="ENO"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-3">
                  <span class="">Chassis No</span>
                  <input
                    type="text"
                    #refChassisNo="ngModel"
                    placeholder="Chassis No"
                    [required]="
                      (InvoiceNo != '' && InvoiceNo != null) ||
                      (VehicleRegistationNo != '' &&
                        VehicleRegistationNo != null)
                    "
                    (change)="checkDuplication_Vehicle('ChassisNo')"
                    [ngClass]="{
                      'is-invalid': tf.submitted && refChassisNo.invalid,
                      'alert-warning':
                        refChassisNo.invalid &&
                        (refChassisNo.dirty ||
                          refChassisNo.touched ||
                          refChassisNo.untouched)
                    }"
                    [(ngModel)]="ChassisNo"
                    name="CNO"
                    id="CNO"
                    class="form-control input-text-css"
                  />
                </div>

                <div class="col-md-3">
                  <span class="">Key No</span>
                  <input
                    type="text"
                    #refKEYNO="ngModel"
                    placeholder="Key No"
                    [(ngModel)]="KeyNo"
                    name="KEYNO"
                    id="KEYNO"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-3 mt-4">
                  <mat-checkbox
                    [(ngModel)]="RC_HPNEndorsment"
                    id="RC_HPNEndorsment"
                    name="RC_HPNEndorsment"
                  >
                    RC HPNEndorsment
                  </mat-checkbox>
                </div>
                <div class="col-md-3 mt-4" *ngIf="newVehicle">
                  <mat-checkbox
                    [(ngModel)]="Invoice_HPNEndorsment"
                    id="Invoice_HPNEndorsment"
                    name="Invoice_HPNEndorsment"
                  >
                    Invoice HPNEndorsment
                  </mat-checkbox>
                </div>
              </div>

              <hr />
              <div class="row mt-4">
                <div class="col-md-12">
                  <button
                    type="button"
                    *ngIf="RCVerified_Button"
                    [hidden]="RCKYC_IsVerified == 1 || RCLastVerfiedDate"
                    (click)="onVerification(0)"
                    style="
                      margin-left: 5px;
                      font-size: 12px;
                      width: 105px;
                      height: 35px;
                      margin-top: 16px;
                      padding: 0px;
                    "
                    class="btn font-size-12 button-btn"
                  >
                    RC Verification
                  </button>
                  <!--<i class="fa fa-eye ml-2"
         (click)="getdata()"
         *ngIf="VehicleRegistationNo != '' && RCVerified_Button &&(RCKYC_IsVerified == 1 || RCLastVerfiedDate) "
         style="color: green"></i>-->
                  <button
                    type="button"
                    *ngIf="
                      VehicleRegistationNo != '' &&
                      RCVerified_Button &&
                      (RCKYC_IsVerified == 1 || RCLastVerfiedDate)
                    "
                    (click)="onVerification(1)"
                    class="btn font-size-6 button-btn"
                    style="width: max-content !important"
                  >
                    RC RE-Verification
                  </button>
                  <span
                    *ngIf="
                      VehicleRegistationNo != '' &&
                      RCVerified_Button &&
                      RCLastVerfiedDate
                    "
                    style="margin-top: 25px"
                  >
                    VERIFIED {{ RCLastVerfiedDate }}
                  </span>
                </div>
              </div>

              <ng-container *ngIf="newVehicle">
                <div class="row mt-2">
                  <div class="col-md-12">
                    <h5 style="color: black">New Vehicle</h5>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-3">
                    <span class="required-lable">Dealer</span>
                    <select
                      name="DealerId"
                      (change)="DealerChange(Dealer)"
                      id="DealerId"
                      #refDealerId="ngModel"
                      [(ngModel)]="Dealer"
                      [ngClass]="{
                        'is-invalid': tf.submitted && refDealerId.invalid,
                        'alert-warning':
                          refDealerId.invalid &&
                          (refDealerId.dirty ||
                            refDealerId.touched ||
                            refDealerId.untouched)
                      }"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Dealer</option>
                      <option
                        *ngFor="let collection of DealerList"
                        [value]="collection.PartnerId"
                      >
                        {{ collection.Partner }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Dealer Contact Person</span>
                    <input
                      required
                      type="text"
                      #refDealerContactPerson="ngModel"
                      [(ngModel)]="DealerContactPerson"
                      name="DealerContactPerson"
                      [ngClass]="{
                        'is-invalid':
                          tf.submitted && refDealerContactPerson.invalid,
                        'alert-warning':
                          refDealerContactPerson.invalid &&
                          (refDealerContactPerson.dirty ||
                            refDealerContactPerson.touched ||
                            refDealerContactPerson.untouched)
                      }"
                      id="DealerContactPerson"
                      class="form-control input-text-css"
                      placeholder="Dealer Contact Person"
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Dealer Contact No.</span>
                    <input
                      required
                      type="number"
                      #refDealerContactNo="ngModel"
                      [(ngModel)]="DealerContactNo"
                      numbersOnly
                      maxlength="10"
                      minlength="10"
                      name="DealerContactNo"
                      [ngClass]="{
                        'is-invalid':
                          tf.submitted && refDealerContactNo.invalid,
                        'alert-warning':
                          refDealerContactNo.invalid &&
                          (refDealerContactNo.dirty ||
                            refDealerContactNo.touched ||
                            refDealerContactNo.untouched)
                      }"
                      id="DealerContactNo"
                      class="form-control input-text-css"
                      placeholder="Dealer Contact No."
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">DO. No.</span>
                    <input
                      required
                      type="text"
                      #refDONo="ngModel"
                      [(ngModel)]="DeliveryorderNo"
                      [ngClass]="{
                        'is-invalid': tf.submitted && refDONo.invalid,
                        'alert-warning':
                          refDONo.invalid &&
                          (refDONo.dirty ||
                            refDONo.touched ||
                            refDONo.untouched)
                      }"
                      name="DoNO"
                      id="DoNO"
                      class="form-control input-text-css"
                      placeholder="DO. No."
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">DO. Do Date</span>
                    <div class="datepicker_feild">
                      <input
                        required
                        [matDatepicker]="picker6"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        #refDODoDate="ngModel"
                        [(ngModel)]="DeliveryorderDate"
                        name="DODoDate"
                        id="DODoDate"
                        [ngClass]="{
                          'is-invalid': tf.submitted && refDODoDate.invalid,
                          'alert-warning':
                            refDODoDate.invalid &&
                            (refDODoDate.dirty ||
                              refDODoDate.touched ||
                              refDODoDate.untouched)
                        }"
                        (dateChange)="ChangeDeliveryorderDate($event)"
                        [max]="RegistationDate"
                        class="form-control input-text-css"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker6"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker6></mat-datepicker>
                    </div>
                    <!-- <input
          required
          type="text"
          #refDODoDate="ngModel"
          [(ngModel)]="DeliveryorderDate"
          name="DODoDate"
          id="DODoDate"
          [ngClass]="{
            'is-invalid': tf.submitted && refDODoDate.invalid,
            'alert-warning':
              refDODoDate.invalid &&
              (refDODoDate.dirty ||
                refDODoDate.touched ||
                refDODoDate.untouched)
          }"
          (ngModelChange)="ChangeDeliveryorderDate($event)"
          [maxDate]="RegistationDate"
          value="{{ DeliveryorderDate | date : 'dd/MM/yyyy' }}"
          placeholder="DD/MM/YYYY"
          class="form-control input-text-css"
          [(bsValue)]="DeliveryorderDate"
          bsDatepicker
          placeholder="DO. Do Date"
        /> -->
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Estimation Amount</span>
                    <input
                      required
                      type="number"
                      min="0"
                      numbersOnly
                      #refEstimationAmount="ngModel"
                      [(ngModel)]="EstimationAmount"
                      [ngClass]="{
                        'is-invalid':
                          tf.submitted && refEstimationAmount.invalid,
                        'alert-warning':
                          refEstimationAmount.invalid &&
                          (refEstimationAmount.dirty ||
                            refEstimationAmount.touched ||
                            refEstimationAmount.untouched)
                      }"
                      name="EstimationAmount"
                      id="EstimationAmount"
                      class="form-control input-text-css"
                      placeholder="Estimation Amount"
                    />
                  </div>

                  <div class="col-md-3">
                    <span class="">Invoice No.</span>
                    <input
                      type="text"
                      #refInvoiceNo="ngModel"
                      [(ngModel)]="InvoiceNo"
                      placeholder="Invoice No."
                      name="InvoiceNo"
                      id="InvoiceNo"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="">Invoice Date</span>
                    <div class="datepicker_feild">
                      <input
                        required
                        [matDatepicker]="picker7"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        #refInvoiceDate="ngModel"
                        [(ngModel)]="InvoiceDate"
                        name="InvoiceDate"
                        id="InvoiceDate"
                        (dateChange)="ChangeInvoiveDateDate($event)"
                        [max]="RegistationDate"
                        class="form-control input-text-css"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker7"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker7></mat-datepicker>
                    </div>
                    <!-- <input
          type="text"
          #refInvoiceDate="ngModel"
          [(ngModel)]="InvoiceDate"
          name="InvoiceDate"
          id="InvoiceDate"
          (ngModelChange)="ChangeInvoiveDateDate($event)"
          [maxDate]="RegistationDate"
          value="{{ InvoiveDate | date : 'dd/MM/yyyy' }}"
          placeholder="DD/MM/YYYY"
          class="form-control input-text-css"
          [(bsValue)]="InvoiveDate"
          bsDatepicker
          placeholder="Invoice Date"
        /> -->
                  </div>
                  <div class="col-md-3">
                    <span class="">Invoice Value</span>
                    <input
                      numbersOnly
                      type="text"
                      #refInvoiceValue="ngModel"
                      [(ngModel)]="InvoiceValue"
                      [ngClass]="{
                        'is-invalid': tf.submitted && refInvoiceValue.invalid,
                        'alert-warning':
                          refInvoiceValue.invalid &&
                          (refInvoiceValue.dirty ||
                            refInvoiceValue.touched ||
                            refInvoiceValue.untouched)
                      }"
                      name="InvoiceValue"
                      id="InvoiceValue"
                      class="form-control input-text-css"
                      placeholder="Invoice Value"
                    />
                  </div>

                  <div class="col-md-3">
                    <span class="required-lable">Quotation in favour of</span>
                    <select
                      name="DOIssuedinfavourof"
                      #refDOIssuedinfavourof="ngModel"
                      [(ngModel)]="IssuedInFavourOf"
                      id="DOIssuedinfavourof"
                      [ngClass]="{
                        'is-invalid':
                          tf.submitted && refDOIssuedinfavourof.invalid,
                        'alert-warning':
                          refDOIssuedinfavourof.invalid &&
                          (refDOIssuedinfavourof.dirty ||
                            refDOIssuedinfavourof.touched ||
                            refDOIssuedinfavourof.untouched)
                      }"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Customer</option>
                      <option
                        [value]="cus.CustomerId"
                        *ngFor="let cus of CustomerList"
                      >
                        {{ cus.Customer }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-3">
                    <span class="">Remark</span>
                    <input
                      type="text"
                      #refRemark="ngModel"
                      [(ngModel)]="Remark"
                      name="Remark"
                      id="Remark"
                      class="form-control input-text-css"
                      placeholder="Remark"
                    />
                  </div>
                </div>
                <hr />
              </ng-container>

              <ng-container *ngIf="usedVehicle">
                <div class="row m-0">
                  <div class="col-md-12 p-1">
                    <div class="bg-success1">Used Vehicles:</div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-3">
                    <span class="">Valuation Done By</span>
                    <select
                      name="ValuationDoneBy"
                      id="ValuationDoneBy"
                      #refValuationDoneBy="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          tf.submitted && refValuationDoneBy.invalid,
                        'alert-warning':
                          refValuationDoneBy.invalid &&
                          (refValuationDoneBy.dirty ||
                            refValuationDoneBy.touched ||
                            refValuationDoneBy.untouched)
                      }"
                      [(ngModel)]="ValuationDoneBy"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Valuation Done By</option>
                      <option
                        *ngFor="let collection of ValuationDoneByData"
                        [value]="collection.PartnerId"
                      >
                        {{ collection.Partner_Name }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-3">
                    <span class="">Valuation Date</span>
                    <!--<input type="text" #refValuationDate="ngModel"  (ngModelChange)="ValuationDateChange()" [(ngModel)]="ValuationDate" name="ValuationDate" id="ValuationDate"
        [ngClass]="{ 'is-invalid': tf.submitted && refValuationDate.invalid, 'alert-warning': refValuationDate.invalid && (refValuationDate.dirty || refValuationDate.touched || refValuationDate.untouched) }"
        value="{{ValuationDate | date:'dd/MM/yyyy' }}" placeholder="DD/MM/YYYY"
        class="form-control input-text-css" [(bsValue)]="ValuationDate" bsDatepicker placeholder="Valuation Date">-->
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker8"
                        dateConvert
                        [min]="Application_Date"
                        placeholder="DD/MM/YYYY"
                        name="ValuationDate"
                        id="ValuationDate"
                        #refValuationDate="ngModel"
                        (dateChange)="ValuationDateChange()"
                        [ngClass]="{
                          'is-invalid':
                            tf.submitted && refValuationDate.invalid,
                          'alert-warning':
                            refValuationDate.invalid &&
                            (refValuationDate.dirty ||
                              refValuationDate.touched ||
                              refValuationDate.untouched)
                        }"
                        class="form-control input-text-css"
                        [(ngModel)]="ValuationDate"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker8"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker8></mat-datepicker>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <span class="">Valuation Amount</span>
                    <input
                      type="number"
                      min="0"
                      numbersOnly
                      #refValuationAmount="ngModel"
                      [(ngModel)]="ValutionAmount"
                      name="ValuationAmount"
                      id="ValuationAmount"
                      class="form-control input-text-css"
                      placeholder="Valuation Amount"
                      [ngClass]="{
                        'is-invalid':
                          tf.submitted && refValuationAmount.invalid,
                        'alert-warning':
                          refValuationAmount.invalid &&
                          (refValuationAmount.dirty ||
                            refValuationAmount.touched ||
                            refValuationAmount.untouched)
                      }"
                    />
                  </div>

                  <div class="col-md-3">
                    <span class="">Valuation Type</span>
                    <select
                      name="ValuationType"
                      id="ValuationType"
                      #refValuationType="ngModel"
                      [ngClass]="{
                        'is-invalid': tf.submitted && refValuationType.invalid,
                        'alert-warning':
                          refValuationType.invalid &&
                          (refValuationType.dirty ||
                            refValuationType.touched ||
                            refValuationType.untouched)
                      }"
                      [(ngModel)]="ValuationType"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Valuation Type</option>
                      <option value="Valuation">Valuation</option>
                      <option value="Grid">Grid</option>
                      <option value="IDV">IDV</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="">Other Valuation Type</span>
                    <input
                      type="text"
                      #refOtherValuationType="ngModel"
                      [(ngModel)]="OtherValuationType"
                      name="OtherValuationType"
                      [ngClass]="{
                        'is-invalid':
                          tf.submitted && refOtherValuationType.invalid,
                        'alert-warning':
                          refOtherValuationType.invalid &&
                          (refOtherValuationType.dirty ||
                            refOtherValuationType.touched ||
                            refOtherValuationType.untouched)
                      }"
                      id="OtherValuationType"
                      class="form-control input-text-css"
                      placeholder="Other Valuation Type"
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="">Other Valuation Amount</span>
                    <input
                      type="number"
                      min="0"
                      numbersOnly
                      #refOtherValuationAmt="ngModel"
                      [(ngModel)]="OtherValuationAmount"
                      name="OtherValuationAmt"
                      id="OtherValuationAmt"
                      class="form-control input-text-css"
                      placeholder="Other Valuation Amount"
                      [ngClass]="{
                        'is-invalid':
                          tf.submitted && refOtherValuationAmt.invalid,
                        'alert-warning':
                          refOtherValuationAmt.invalid &&
                          (refOtherValuationAmt.dirty ||
                            refOtherValuationAmt.touched ||
                            refOtherValuationAmt.untouched)
                      }"
                    />
                  </div>
                </div>
                <hr />
              </ng-container>

              <ng-container>
                <div class="row mt-2">
                  <div class="col-md-12 mt-2 upload">
                    <B> Upload Vehicle Images </B>
                    <input
                      #docFile5
                      type="file"
                      [multiple]="false"
                      accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                      (change)="
                        fileChangeListenerAttachment(docFile5.files, docFile5)
                      "
                      style="display: none"
                    />
                    <div
                      class="row m-0 mt-2 align-items-center"
                      *ngFor="let report of AssetsAttachment; let i = index"
                    >
                      <div class="col-md-1 pl-0">{{ i + 1 }}</div>
                      <div class="col-md-3">
                        <input
                          type="text"
                          placeholder="Title"
                          name="title{{ i }}"
                          id="title{{ i }}"
                          #refTitle="ngModel"
                          [(ngModel)]="report.Title"
                          class="form-control input-text-css"
                        />
                      </div>
                      <div class="col-md-3">
                        <p class="m-0 row">
                          <i
                            class="fa fa-upload ml-2"
                            *ngIf="!report.DocFileName"
                            (click)="docFile5.click()"
                            aria-hidden="true"
                          ></i>
                          <ng-container *ngIf="report.DocFileName">
                            <div>
                              <i
                                class="fa fa-file-pdf mr-2"
                                aria-hidden="true"
                              ></i
                              >{{ report.DocFileName }}
                              <i
                                class="fa fa-times ml-2"
                                aria-hidden="true"
                                style="cursor: pointer"
                                (click)="removeFileAttachment(i)"
                              ></i>
                            </div>
                          </ng-container>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div
                class="row mt-2 formborder ml-2 mr-2 mt-2"
                style="background-color: #d2ebec"
                *ngIf="
                  VehicleRegistationNo != '' &&
                  RCVerified_Button &&
                  (RCKYC_IsVerified == 1 || RCLastVerfiedDate)
                "
              >
                <div class="col-md-12 bg-success1">Verified RC Data</div>

                <div class="col-md-2 border-div">
                  <h6>Vehicle Registation No</h6>
                  <p>
                    {{ APIResponse.DoucumentNumber }}
                  </p>
                </div>

                <div class="col-md-2 border-div">
                  <h6>Fit Up To</h6>
                  <p>
                    {{ APIResponse.fit_up_to }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Registration Date</h6>
                  <p>
                    {{ APIResponse.registration_date }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Owner Name</h6>
                  <p>
                    {{ APIResponse.owner_name }}
                  </p>
                </div>

                <div class="col-md-2 border-div">
                  <h6>Father Name</h6>
                  <p>
                    {{ APIResponse.father_name }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Mobile Number</h6>
                  <p>
                    {{ APIResponse.mobile_number }}
                  </p>
                </div>

                <div class="col-md-2 border-div">
                  <h6>Vehicle Category</h6>
                  <p>
                    {{ APIResponse.vehicle_category }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Vehicle Chasi Number</h6>
                  <p>
                    {{ APIResponse.vehicle_chasi_number }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Vehicle Engine Number</h6>
                  <p>
                    {{ APIResponse.vehicle_engine_number }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Maker Description</h6>
                  <p>
                    {{ APIResponse.maker_description }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Maker Model</h6>
                  <p>
                    {{ APIResponse.maker_model }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Body Type</h6>
                  <p>
                    {{ APIResponse.body_type }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Fuel Type</h6>
                  <p>
                    {{ APIResponse.fuel_type }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Color</h6>
                  <p>
                    {{ APIResponse.color }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Norms Type</h6>
                  <p>
                    {{ APIResponse.norms_type }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Financer</h6>
                  <p>
                    {{ APIResponse.financer }}
                  </p>
                </div>

                <div class="col-md-2 border-div">
                  <h6>financed</h6>
                  <p>
                    {{ APIResponse.financed }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Insurance Company</h6>
                  <p>
                    {{ APIResponse.insurance_company }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Insurance Policy Number</h6>
                  <p>
                    {{ APIResponse.insurance_policy_number }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Insurance Upto</h6>
                  <p>
                    {{ APIResponse.insurance_upto }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Manufacturing Date</h6>
                  <p>
                    {{ APIResponse.manufacturing_date }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Registered At</h6>
                  <p>
                    {{ APIResponse.registered_at }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Tax Upto</h6>
                  <p>
                    {{ APIResponse.tax_upto }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Tax Paid Upto</h6>
                  <p>
                    {{ APIResponse.tax_paid_upto }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Seat Capacity</h6>
                  <p>
                    {{ APIResponse.seat_capacity }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Vehicle Category Description</h6>
                  <p>
                    {{ APIResponse.vehicle_category_description }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Pucc Number</h6>
                  <p>
                    {{ APIResponse.pucc_number }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Pucc Upto</h6>
                  <p>
                    {{ APIResponse.pucc_upto }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Owner Number</h6>
                  <p>
                    {{ APIResponse.owner_number }}
                  </p>
                </div>
                <div class="col-md-12 border-div">
                  <h6>Permanent Address</h6>
                  <p>
                    {{ APIResponse.permanent_address }}
                  </p>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="propertyForm == 'Property'">
              <div class="formborder">
                <div class="row">
                  <div class="col-md-4">
                    <span class="required-lable">Property Owner Name</span>
                    <select
                      name="CustomerId"
                      id="CustomerId"
                      #refCustomerId="ngModel"
                      [ngClass]="{
                        'is-invalid': tf.submitted && refCustomerId.invalid,
                        'alert-warning':
                          refCustomerId.invalid &&
                          (refCustomerId.dirty ||
                            refCustomerId.touched ||
                            refCustomerId.untouched)
                      }"
                      [(ngModel)]="PropertyOwnerName"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Owner</option>
                      <option
                        [value]="cus.CustomerId"
                        *ngFor="let cus of CustomerList"
                      >
                        {{ cus.Customer }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-8">
                    <span class="required-lable">Address Of Property</span>
                    <textarea
                      required
                      type="text"
                      #reAddress="ngModel"
                      placeholder="Address Of Property"
                      [ngClass]="{
                        'is-invalid': tf.submitted && reAddress.invalid,
                        'alert-warning':
                          reAddress.invalid &&
                          (reAddress.dirty ||
                            reAddress.touched ||
                            reAddress.untouched)
                      }"
                      [(ngModel)]="AddressOfProperty"
                      name="Address"
                      id="Address"
                      class="form-control input-text-css"
                    ></textarea>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-3">
                    <span class="required-lable">Reg State</span>
                    <select
                      name="State"
                      id="State"
                      #refState="ngModel"
                      [(ngModel)]="customerModel.Firm_RegState"
                      class="form-control input-text-css"
                      required
                      [ngClass]="{
                        'is-invalid': tf.submitted && refState.invalid,
                        'alert-warning':
                          refState.invalid &&
                          (refState.dirty ||
                            refState.touched ||
                            refState.untouched)
                      }"
                      (change)="
                        GetDistrickDropdownReg(customerModel.Firm_RegState)
                      "
                    >
                      <option value="">Select State</option>
                      <option
                        *ngFor="let state of stateDropdown"
                        [value]="state.StateId"
                      >
                        {{ state.State_Name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Reg District</span>
                    <select
                      name="District"
                      id="District"
                      #refDistrict="ngModel"
                      [(ngModel)]="customerModel.Firm_RegDistrict"
                      class="form-control input-text-css"
                      required
                      [ngClass]="{
                        'is-invalid': tf.submitted && refDistrict.invalid,
                        'alert-warning':
                          refDistrict.invalid &&
                          (refDistrict.dirty ||
                            refDistrict.touched ||
                            refDistrict.untouched)
                      }"
                      (change)="
                        GetTehasilDropdownReg(customerModel.Firm_RegDistrict)
                      "
                    >
                      <option value="">Select District</option>
                      <option
                        *ngFor="let district of districkDropdownReg"
                        [value]="district.DistrictId"
                      >
                        {{ district.District_Name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Reg Tehsil</span>
                    <select
                      name="Tasil"
                      id="Tasil"
                      #refTasil="ngModel"
                      [ngClass]="{
                        'is-invalid': tf.submitted && refTasil.invalid,
                        'alert-warning':
                          refTasil.invalid &&
                          (refTasil.dirty ||
                            refTasil.touched ||
                            refTasil.untouched)
                      }"
                      [(ngModel)]="customerModel.Firm_RegTehsil"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Tehsil</option>
                      <option
                        *ngFor="let tehsil of tehasilDropdownReg"
                        [value]="tehsil.TehsilId"
                      >
                        {{ tehsil.Tehsil_Name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Pincode</span>
                    <input
                      name="Pincode"
                      id="Pincode"
                      #refPincode="ngModel"
                      [ngClass]="{
                        'is-invalid': tf.submitted && refPincode.invalid,
                        'alert-warning':
                          refPincode.invalid &&
                          (refPincode.dirty ||
                            refPincode.touched ||
                            refPincode.untouched)
                      }"
                      [(ngModel)]="Pincode"
                      class="form-control input-text-css"
                      required
                    />
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-md-3">
                    <span class="required-lable">Type Of Property</span>
                    <select
                      name="TypeofProperty"
                      id="TypeofProperty"
                      #refTypeofProperty="ngModel"
                      [ngClass]="{
                        'is-invalid': tf.submitted && refTypeofProperty.invalid,
                        'alert-warning':
                          refTypeofProperty.invalid &&
                          (refTypeofProperty.dirty ||
                            refTypeofProperty.touched ||
                            refTypeofProperty.untouched)
                      }"
                      [(ngModel)]="TypeOfProperty"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Type Of Property</option>
                      <option
                        *ngFor="let Type of TypeOfPropertyList"
                        [value]="Type.Id"
                      >
                        {{ Type.Value }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Nature Of Property</span>
                    <select
                      name="NatureofProperty"
                      id="NatureofProperty"
                      #refNatureofProperty="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          tf.submitted && refNatureofProperty.invalid,
                        'alert-warning':
                          refNatureofProperty.invalid &&
                          (refNatureofProperty.dirty ||
                            refNatureofProperty.touched ||
                            refNatureofProperty.untouched)
                      }"
                      [(ngModel)]="NatureOfProperty"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Type Of Property</option>
                      <option
                        *ngFor="let Type of NatureOfPropertyList"
                        [value]="Type.Id"
                      >
                        {{ Type.Value }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Ownership Document</span>
                    <select
                      name="OwnershipDocument"
                      id="OwnershipDocument"
                      #refOwnershipDocument="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          tf.submitted && refOwnershipDocument.invalid,
                        'alert-warning':
                          refOwnershipDocument.invalid &&
                          (refOwnershipDocument.dirty ||
                            refOwnershipDocument.touched ||
                            refOwnershipDocument.untouched)
                      }"
                      [(ngModel)]="OwnershipDocument"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Ownership Document</option>
                      <option
                        *ngFor="let Type of OwnershipDocumentList"
                        [value]="Type.Id"
                      >
                        {{ Type.Value }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Ownership Type</span>
                    <select
                      name="OwnershipType"
                      id="OwnershipType"
                      #refOwnershipType="ngModel"
                      [ngClass]="{
                        'is-invalid': tf.submitted && refOwnershipType.invalid,
                        'alert-warning':
                          refOwnershipType.invalid &&
                          (refOwnershipType.dirty ||
                            refOwnershipType.touched ||
                            refOwnershipType.untouched)
                      }"
                      [(ngModel)]="OwnershipType"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Ownership Type</option>
                      <option
                        *ngFor="let Type of OwnershipTypeList"
                        [value]="Type.Id"
                      >
                        {{ Type.Value }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-md-3">
                    <span class="required-lable">Unit Of Measurement </span>
                    <select
                      name="UnitofMeasurement"
                      id="UnitofMeasurement"
                      #refUnitofMeasurement="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          tf.submitted && refUnitofMeasurement.invalid,
                        'alert-warning':
                          refUnitofMeasurement.invalid &&
                          (refUnitofMeasurement.dirty ||
                            refUnitofMeasurement.touched ||
                            refUnitofMeasurement.untouched)
                      }"
                      [(ngModel)]="UnitOfMeasurement"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Unit Of Measurement</option>
                      <option value="Sq. Ft">Sq. Ft</option>
                      <option value="Sq. Yards">Sq. Yards</option>
                      <option value="Sq. Metre">Sq. Metre</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Total Area </span>
                    <input
                      required
                      type="text"
                      appTwoDigitDecimaNumber
                      #reTotalArea="ngModel"
                      placeholder="Total Area"
                      [ngClass]="{
                        'is-invalid': tf.submitted && reTotalArea.invalid,
                        'alert-warning':
                          reTotalArea.invalid &&
                          (reTotalArea.dirty ||
                            reTotalArea.touched ||
                            reTotalArea.untouched)
                      }"
                      [(ngModel)]="TotalArea"
                      name="TotalArea"
                      id="TotalArea"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Constructed Area </span>
                    <input
                      required
                      type="text"
                      #reConstructedArea="ngModel"
                      appTwoDigitDecimaNumber
                      [(ngModel)]="ConstructedArea"
                      placeholder="Constructed Area"
                      name="ConstructedArea"
                      id="ConstructedArea"
                      [ngClass]="{
                        'is-invalid': tf.submitted && reConstructedArea.invalid,
                        'alert-warning':
                          reConstructedArea.invalid &&
                          (reConstructedArea.dirty ||
                            reConstructedArea.touched ||
                            reConstructedArea.untouched)
                      }"
                      class="form-control input-text-css"
                    />
                    <!--  (change)="GetConstructedArea($event)" -->
                  </div>
                  <div class="col-md-3">
                    <span class="">Type Of Mortgage </span>
                    <select
                      name="TypeofMortgage"
                      id="TypeofMortgage"
                      #refTypeofMortgage="ngModel"
                      [ngClass]="{
                        'is-invalid': tf.submitted && refTypeofMortgage.invalid,
                        'alert-warning':
                          refTypeofMortgage.invalid &&
                          (refTypeofMortgage.dirty ||
                            refTypeofMortgage.touched ||
                            refTypeofMortgage.untouched)
                      }"
                      [(ngModel)]="TypeOfMortgage"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Type Of Mortgage</option>
                      <option value="Registered">Registered</option>
                      <option value="Equitable">Equitable</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="">Mortgage signed by</span>
                    <select
                      name="MortgageSingedBy"
                      id="MortgageSingedBy"
                      #refMortgageSingedBy="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          tf.submitted && refMortgageSingedBy.invalid,
                        'alert-warning':
                          refMortgageSingedBy.invalid &&
                          (refMortgageSingedBy.dirty ||
                            refMortgageSingedBy.touched ||
                            refMortgageSingedBy.untouched)
                      }"
                      [(ngModel)]="MortgageSingedBy"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Mortgage signed by</option>
                      <option
                        *ngFor="let collection of CollectionExecutiveData"
                        [value]="collection.EmpId"
                      >
                        {{ collection.Emp_FirstName }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-md-3">
                    <span class="required-lable">Valution Amount</span>
                    <input
                      required
                      type="number"
                      min="0"
                      numbersOnly
                      #refValuationAmount="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          tf.submitted && refValuationAmount.invalid,
                        'alert-warning':
                          refValuationAmount.invalid &&
                          (refValuationAmount.dirty ||
                            refValuationAmount.touched ||
                            refValuationAmount.untouched)
                      }"
                      [(ngModel)]="ValutionAmount"
                      name="ValuationAmount"
                      id="ValuationAmount"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="">Valution Done By</span>
                    <select
                      name="ValuationDoneBy"
                      id="ValuationDoneBy"
                      #refValuationDoneBy="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          tf.submitted && refValuationDoneBy.invalid,
                        'alert-warning':
                          refValuationDoneBy.invalid &&
                          (refValuationDoneBy.dirty ||
                            refValuationDoneBy.touched ||
                            refValuationDoneBy.untouched)
                      }"
                      [(ngModel)]="ValuationDoneBy"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Valuation Done By</option>
                      <option
                        *ngFor="let collection of ValuationDoneByData"
                        [value]="collection.PartnerId"
                      >
                        {{ collection.Partner_Name }}
                      </option>
                      <option value="0">Other</option>
                    </select>
                  </div>
                  <div
                    class="col-md-3"
                    *ngIf="ValuationDoneBy != '' && ValuationDoneBy == 0"
                  >
                    <span class="">Valution Done By Name</span>
                    <input
                      type="text"
                      #refValuationDoneByOther="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          tf.submitted && refValuationDoneByOther.invalid,
                        'alert-warning':
                          refValuationDoneByOther.invalid &&
                          (refValuationDoneByOther.dirty ||
                            refValuationDoneByOther.touched ||
                            refValuationDoneByOther.untouched)
                      }"
                      [(ngModel)]="ValuationDoneByOther"
                      name="ValuationDoneByOther"
                      id="ValuationDoneByOther"
                      class="form-control input-text-css"
                    />
                  </div>

                  <div class="col-md-6">
                    <span class="">Valution Remark</span>
                    <textarea
                      type="text"
                      #refValuationRemark="ngModel"
                      [(ngModel)]="ValuationRemark"
                      name="ValuationRemark"
                      id="ValuationRemark"
                      [ngClass]="{
                        'is-invalid':
                          tf.submitted && refValuationRemark.invalid,
                        'alert-warning':
                          refValuationRemark.invalid &&
                          (refValuationRemark.dirty ||
                            refValuationRemark.touched ||
                            refValuationRemark.untouched)
                      }"
                      class="form-control input-text-css"
                    ></textarea>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-md-3">
                    <span class="">Search Report Done By</span>
                    <select
                      name="SearchReportDoneBy"
                      id="SearchReportDoneBy"
                      #refSearchReportDoneBy="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          tf.submitted && refSearchReportDoneBy.invalid,
                        'alert-warning':
                          refSearchReportDoneBy.invalid &&
                          (refSearchReportDoneBy.dirty ||
                            refSearchReportDoneBy.touched ||
                            refSearchReportDoneBy.untouched)
                      }"
                      [(ngModel)]="SearchReportDoneBy"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Report Done By</option>
                      <option
                        *ngFor="let collection of SearchReportDoneByData"
                        [value]="collection.PartnerId"
                      >
                        {{ collection.Partner_Name }}
                      </option>

                      <option value="0">Other</option>
                    </select>
                  </div>
                  <div
                    class="col-md-3"
                    *ngIf="SearchReportDoneBy != '' && SearchReportDoneBy == 0"
                  >
                    <span class="">Search Report Done By Name</span>
                    <input
                      type="text"
                      #refSearchReportDoneByOther="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          tf.submitted && refSearchReportDoneByOther.invalid,
                        'alert-warning':
                          refSearchReportDoneByOther.invalid &&
                          (refSearchReportDoneByOther.dirty ||
                            refSearchReportDoneByOther.touched ||
                            refSearchReportDoneByOther.untouched)
                      }"
                      [(ngModel)]="SearchReportDoneByOther"
                      name="SearchReportDoneByOther"
                      id="SearchReportDoneByOther"
                      class="form-control input-text-css"
                    />
                  </div>

                  <div class="col-md-9">
                    <span class="">Search Report Remark</span>
                    <textarea
                      type="text"
                      #refSearchReportRemark="ngModel"
                      [(ngModel)]="SearchReportRemark"
                      name="SearchReportRemark"
                      id="SearchReportRemark"
                      [ngClass]="{
                        'is-invalid':
                          tf.submitted && refSearchReportRemark.invalid,
                        'alert-warning':
                          refSearchReportRemark.invalid &&
                          (refSearchReportRemark.dirty ||
                            refSearchReportRemark.touched ||
                            refSearchReportRemark.untouched)
                      }"
                      class="form-control input-text-css"
                    ></textarea>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-2">
                    <b style="color: black"> Location </b>
                  </div>
                  <div class="col-md-4">
                    <span class="">Latitude</span>
                    <input
                      type="number"
                      step="00.000001"
                      #refLatitude="ngModel"
                      placeholder="Latitude"
                      [ngClass]="{
                        'is-invalid': tf.submitted && refLatitude.invalid,
                        'alert-warning':
                          refLatitude.invalid &&
                          (refLatitude.dirty ||
                            refLatitude.touched ||
                            refLatitude.untouched)
                      }"
                      [(ngModel)]="latitude"
                      name="Latitude"
                      id="Latitude"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-4">
                    <span class="">Longitude</span>
                    <input
                      type="number"
                      step="00.000001"
                      #refLongitude="ngModel"
                      placeholder="Longitude"
                      [ngClass]="{
                        'is-invalid': tf.submitted && refLongitude.invalid,
                        'alert-warning':
                          refLongitude.invalid &&
                          (refLongitude.dirty ||
                            refLongitude.touched ||
                            refLongitude.untouched)
                      }"
                      [(ngModel)]="longitude"
                      name="Longitude"
                      id="Longitude"
                      class="form-control input-text-css"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <p class="m-0 row">
                      Upload Valuation Report:<i
                        class="fa fa-upload ml-2"
                        *ngIf="!ValuationRemarkDoc"
                        (click)="docFile.click()"
                        aria-hidden="true"
                      ></i>
                      <ng-container *ngIf="ValuationRemarkDoc">
                        <div>
                          <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>
                          <ng-container *ngIf="ValuationDocData">
                            {{ ValuationRemarkDoc }}
                          </ng-container>
                          <a
                            href="{{ docBaseUrl }}{{
                              loanSummary.ApplicationNo
                            }}/{{ ValuationRemarkDoc }}"
                            class="ml-3"
                            target="_blank"
                            *ngIf="!ValuationDocData"
                          >
                            {{ ValuationRemarkDoc }}
                          </a>
                          <i
                            class="fa fa-times ml-2"
                            aria-hidden="true"
                            style="cursor: pointer"
                            (click)="removeFile(docFile)"
                          ></i>
                        </div>
                      </ng-container>
                    </p>
                    <input
                      #docFile
                      type="file"
                      [multiple]="false"
                      accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                      (change)="fileChangeListener(docFile.files)"
                      style="display: none"
                    />
                  </div>
                  <div class="col-md-6">
                    <p class="m-0 row">
                      Upload Search Report:<i
                        class="fa fa-upload ml-2"
                        *ngIf="!SearchReportRemarkDoc"
                        (click)="docFile1.click()"
                        aria-hidden="true"
                      ></i>
                      <ng-container *ngIf="SearchReportRemarkDoc">
                        <div>
                          <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>
                          <ng-container *ngIf="SearchDocData">
                            {{ SearchReportRemarkDoc }}
                          </ng-container>
                          <a
                            href="{{ docBaseUrl }}{{
                              loanSummary.ApplicationNo
                            }}/{{ SearchReportRemarkDoc }}"
                            class="ml-3"
                            target="_blank"
                            *ngIf="!SearchDocData"
                          >
                            {{ SearchReportRemarkDoc }}
                          </a>
                          <i
                            class="fa fa-times ml-2"
                            aria-hidden="true"
                            style="cursor: pointer"
                            (click)="removeFile1(docFile1)"
                          ></i>
                        </div>
                      </ng-container>
                    </p>
                    <input
                      #docFile1
                      type="file"
                      [multiple]="false"
                      accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                      (change)="fileChangeListener1(docFile1.files)"
                      style="display: none"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12 mt-2 upload">
                    <b> Upload Property Images </b>
                    <input
                      #docFile5
                      type="file"
                      [multiple]="false"
                      accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                      (change)="
                        fileChangeListenerAttachment(docFile5.files, docFile5)
                      "
                      style="display: none"
                    />
                    <div
                      class="row m-0 mt-2 align-items-center"
                      *ngFor="let report of AssetsAttachment; let i = index"
                    >
                      <div class="col-md-1 pl-0">{{ i + 1 }}</div>
                      <div class="col-md-3">
                        <input
                          type="text"
                          placeholder="Title"
                          name="title{{ i }}"
                          id="title{{ i }}"
                          #refTitle="ngModel"
                          [(ngModel)]="report.Title"
                          class="form-control input-text-css"
                        />
                      </div>
                      <div class="col-md-3">
                        <p class="m-0 row">
                          <i
                            class="fa fa-upload ml-2"
                            *ngIf="!report.DocFileName"
                            (click)="docFile5.click()"
                            aria-hidden="true"
                          ></i>
                          <ng-container *ngIf="report.DocFileName">
                            <div>
                              <i
                                class="fa fa-file-pdf mr-2"
                                aria-hidden="true"
                              ></i
                              >{{ report.DocFileName }}
                              <i
                                class="fa fa-times ml-2"
                                aria-hidden="true"
                                style="cursor: pointer"
                                (click)="removeFileAttachment(i)"
                              ></i>
                            </div>
                          </ng-container>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
            </ng-container>

            <ng-container *ngIf="ConsumerGoldForm == 'ConsumerGoldForm'">
              <form #ff="ngForm" novalidate>
                <div class="row mt-2">
                  <div class="col-md-2">
                    <span class="required-lable">Item Name</span>
                    <input
                      required
                      type="text"
                      #refItemName="ngModel"
                      [ngClass]="{
                        'is-invalid': ff.submitted && refItemName.invalid,
                        'alert-warning':
                          refItemName.invalid &&
                          (refItemName.dirty ||
                            refItemName.touched ||
                            refItemName.untouched)
                      }"
                      [(ngModel)]="Item.ItemName"
                      name="ItemName"
                      id="ItemName"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Gold Type</span>
                    <select
                      required
                      name="ItemType"
                      id="ItemType"
                      #refItemType="ngModel"
                      [(ngModel)]="Item.ItemType"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Type</option>
                      <option value="Sold">Sold</option>
                      <option value="Un-Sold">Un-Sold</option>
                    </select>
                    <!--<input required type="text" #refItemType="ngModel"
                            [ngClass]="{ 'is-invalid': ff.submitted && refItemType.invalid, 'alert-warning': refItemType.invalid  && (refItemType.dirty || refItemType.touched || refItemType.untouched) }"
                            [(ngModel)]="Item.ItemType" name="ItemType" id="ItemType"
                            class="form-control input-text-css">-->
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Qty</span>
                    <input
                      required
                      type="number"
                      min="0"
                      numbersOnly
                      #refQty="ngModel"
                      [ngClass]="{
                        'is-invalid': ff.submitted && refQty.invalid,
                        'alert-warning':
                          refQty.invalid &&
                          (refQty.dirty || refQty.touched || refQty.untouched)
                      }"
                      [(ngModel)]="Item.Qty"
                      name="Qty"
                      id="Qty"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Purity CT</span>
                    <input
                      required
                      type="number"
                      #refPurityCT="ngModel"
                      [ngClass]="{
                        'is-invalid': ff.submitted && refPurityCT.invalid,
                        'alert-warning':
                          refPurityCT.invalid &&
                          (refPurityCT.dirty ||
                            refPurityCT.touched ||
                            refPurityCT.untouched)
                      }"
                      [(ngModel)]="Item.PurityCT"
                      name="PurityCT"
                      id="PurityCT"
                      class="form-control input-text-css"
                    />
                    <!-- <select required name="PurityCT" id="PurityCT" #refPurityCT="ngModel"  [(ngModel)]="Item.PurityCT"
                            class="form-control input-text-css">
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                    </select> -->
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Gross Wt</span>
                    <input
                      required
                      type="text"
                      #refGrossWt="ngModel"
                      (change)="ChangeWT()"
                      [ngClass]="{
                        'is-invalid': ff.submitted && refGrossWt.invalid,
                        'alert-warning':
                          refGrossWt.invalid &&
                          (refGrossWt.dirty ||
                            refGrossWt.touched ||
                            refGrossWt.untouched)
                      }"
                      [(ngModel)]="Item.GrossWt"
                      name="GrossWt"
                      id="GrossWt"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Stone Wt</span>
                    <input
                      required
                      type="text"
                      #refStoneWt="ngModel"
                      (change)="ChangeWT()"
                      [ngClass]="{
                        'is-invalid': ff.submitted && refStoneWt.invalid,
                        'alert-warning':
                          refStoneWt.invalid &&
                          (refStoneWt.dirty ||
                            refStoneWt.touched ||
                            refStoneWt.untouched)
                      }"
                      [(ngModel)]="Item.StoneWt"
                      name="StoneWt"
                      id="StoneWt"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Other Wt</span>
                    <input
                      required
                      type="text"
                      #refOtherWt="ngModel"
                      (change)="ChangeWT()"
                      [ngClass]="{
                        'is-invalid': ff.submitted && refOtherWt.invalid,
                        'alert-warning':
                          refOtherWt.invalid &&
                          (refOtherWt.dirty ||
                            refOtherWt.touched ||
                            refOtherWt.untouched)
                      }"
                      [(ngModel)]="Item.OtherWt"
                      name="OtherWt"
                      id="OtherWt"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Net Wt</span>
                    <input
                      required
                      type="text"
                      #refNetWt="ngModel"
                      disabled
                      [ngClass]="{
                        'is-invalid': ff.submitted && refNetWt.invalid,
                        'alert-warning':
                          refNetWt.invalid &&
                          (refNetWt.dirty ||
                            refNetWt.touched ||
                            refNetWt.untouched)
                      }"
                      [(ngModel)]="Item.NetWt"
                      name="NetWt"
                      id="NetWt"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Pure Wt</span>
                    <input
                      required
                      type="text"
                      #refPureWt="ngModel"
                      disabled
                      [ngClass]="{
                        'is-invalid': ff.submitted && refPureWt.invalid,
                        'alert-warning':
                          refPureWt.invalid &&
                          (refPureWt.dirty ||
                            refPureWt.touched ||
                            refPureWt.untouched)
                      }"
                      [(ngModel)]="Item.PureWt"
                      name="PureWt"
                      id="PureWt"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Rate</span>
                    <input
                      required
                      type="text"
                      #refValulationRate_PerCT="ngModel"
                      (change)="ChangeWT()"
                      [ngClass]="{
                        'is-invalid':
                          ff.submitted && refValulationRate_PerCT.invalid,
                        'alert-warning':
                          refValulationRate_PerCT.invalid &&
                          (refValulationRate_PerCT.dirty ||
                            refValulationRate_PerCT.touched ||
                            refValulationRate_PerCT.untouched)
                      }"
                      [(ngModel)]="Item.ValulationRate_PerCT"
                      name="ValulationRate_PerCT"
                      id="ValulationRate_PerCT"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Amount</span>
                    <input
                      required
                      type="number"
                      step="0.01"
                      min="0"
                      numbersOnly
                      #refValulationAmount="ngModel"
                      disabled
                      [ngClass]="{
                        'is-invalid':
                          ff.submitted && refValulationAmount.invalid,
                        'alert-warning':
                          refValulationAmount.invalid &&
                          (refValulationAmount.dirty ||
                            refValulationAmount.touched ||
                            refValulationAmount.untouched)
                      }"
                      [(ngModel)]="Item.ValulationAmount"
                      name="ValulationAmount"
                      id="ValulationAmount"
                      class="form-control input-text-css"
                    />
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-4">
                    <p class="m-0 row">
                      Upload Image 1:<i
                        class="fa fa-upload ml-2"
                        *ngIf="!Item.Image1"
                        (click)="imgFile.click()"
                        aria-hidden="true"
                      ></i>
                      <ng-container *ngIf="Item.Image1">
                        <div>
                          <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>
                          <ng-container *ngIf="Item.Image1Data">
                            {{ Item.Image1 }}
                          </ng-container>
                          <a
                            href="{{ applicationDocUrl }}{{
                              loanSummary.ApplicationNo
                            }}/{{ Item.Image2 }}"
                            class="ml-3"
                            target="_blank"
                            *ngIf="!Item.Image1Data"
                          >
                            {{ Item.Image1 }}
                          </a>
                          <i
                            class="fa fa-times ml-2"
                            aria-hidden="true"
                            style="cursor: pointer"
                            (click)="removeImage(imgFile)"
                          ></i>
                        </div>
                      </ng-container>
                    </p>
                    <input
                      #imgFile
                      type="file"
                      [multiple]="false"
                      accept="image/*"
                      (change)="imageChangeListener(imgFile.files)"
                      style="display: none"
                    />
                  </div>
                  <div class="col-md-4">
                    <p class="m-0 row">
                      Upload Image 2:<i
                        class="fa fa-upload ml-2"
                        *ngIf="!Item.Image2"
                        (click)="imgFile1.click()"
                        aria-hidden="true"
                      ></i>
                      <ng-container *ngIf="Item.Image2">
                        <div>
                          <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>
                          <ng-container *ngIf="Item.Image2Data">
                            {{ Item.Image2 }}
                          </ng-container>
                          <a
                            href="{{ applicationDocUrl }}{{
                              loanSummary.ApplicationNo
                            }}/{{ Item.Image2 }}"
                            class="ml-3"
                            target="_blank"
                            *ngIf="!Item.Image2Data"
                          >
                            {{ Item.Image2 }}
                          </a>
                          <i
                            class="fa fa-times ml-2"
                            aria-hidden="true"
                            style="cursor: pointer"
                            (click)="removeImage1(imgFile1)"
                          ></i>
                        </div>
                      </ng-container>
                    </p>
                    <input
                      #imgFile1
                      type="file"
                      [multiple]="false"
                      accept="image/*"
                      (change)="imageChangeListener1(imgFile1.files)"
                      style="display: none"
                    />
                  </div>
                  <div class="col-md-4 text-right">
                    <button
                      type="button"
                      (click)="saveAssetItem()"
                      class="ml-4 btn font-size-12 button-btn"
                      [class.spinner]="loading"
                      [disabled]="!ff.form.valid"
                    >
                      Add Item
                    </button>
                  </div>
                </div>
              </form>
              <hr />
              <div class="table-responsive mt-4 mb-4">
                <mat-table [dataSource]="dataAssetItemSource">
                  <ng-container matColumnDef="Id">
                    <mat-header-cell
                      class="grid-header"
                      style="max-width: 50px"
                      *matHeaderCellDef
                      >#</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                      style="max-width: 50px; max-height: 5px"
                    >
                      {{ i + 1 }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="ItemName">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Item Name</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.ItemName }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="ItemType">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Item Type</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.ItemType }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Qty">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Qty</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.Qty }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="PurityCT">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Purity CT</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.PurityCT }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="GrossWt">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Gross Wt</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.GrossWt }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="StoneWt">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Stone Wt</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.StoneWt }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="OtherWt">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Other Wt</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.OtherWt }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="NetWt">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Net Wt</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.NetWt }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="PureWt">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Pure Wt</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{
                        (
                          (row.NetWt * (row.PurityCT * fixrate24ct)) /
                          100
                        ).toFixed(2)
                      }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="ValulationRate_PerCT">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Rate</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.ValulationRate_PerCT }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="ValulationAmount">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Amount</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.ValulationAmount.toFixed(2) }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Action">
                    <mat-header-cell
                      class="grid-header j-c-center"
                      style="max-width: 60px"
                      *matHeaderCellDef
                    >
                      Action
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell j-c-center"
                      style="max-width: 60px"
                    >
                      <!--<a href="javascript:void(0)" (click)="editAssetItem(row,i)"
                          style="margin-right: 10px;">Edit</a>
                      <a href="javascript:void(0)" (click)="deleteAssetItem(i)">Delete</a>-->
                      <i
                        class="fa fa-pencil-alt right"
                        (click)="editAssetItem(row, i)"
                        aria-label="true"
                        *ngIf="PageAcess[0].Edit == 1"
                      ></i>
                      <i
                        class="fa fa-trash style-delete ml-3"
                        (click)="deleteAssetItem(i)"
                        style="cursor: pointer"
                        aria-label="true"
                        *ngIf="PageAcess[0].Delete == 1"
                      ></i>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayedAssetItemColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedAssetItemColumns"
                  ></mat-row>
                </mat-table>
                <div class="row m-0 mt-4" *ngIf="assetTotal">
                  <div class="col-md-3">
                    <B>Total Qty:</B> {{ assetTotal.Qty }}
                  </div>
                  <div class="col-md-3">
                    <B>Total Gross Wt:</B> {{ assetTotal.GrossWt }}
                  </div>
                  <div class="col-md-3">
                    <B>Total Stone Wt:</B> {{ assetTotal.StoneWt }}
                  </div>
                  <div class="col-md-3">
                    <B>Total Net Wt:</B> {{ assetTotal.NetWt }}
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-3">
                  <span class="required-lable">Valution Done By</span>
                  <select
                    name="ValuationDoneBy"
                    id="ValuationDoneBy"
                    #refValuationDoneBy="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refValuationDoneBy.invalid,
                      'alert-warning':
                        refValuationDoneBy.invalid &&
                        (refValuationDoneBy.dirty ||
                          refValuationDoneBy.touched ||
                          refValuationDoneBy.untouched)
                    }"
                    [(ngModel)]="assetModel.ValulationDoneBy"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Valuation Done By</option>
                    <option
                      *ngFor="let collection of ValuationDoneByData"
                      [value]="collection.PartnerId"
                    >
                      {{ collection.Partner_Name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <span class="required-lable">Valution Date</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker9"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="ValulationDate "
                      id="ValulationDate "
                      #refValulationDate="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refValulationDate.invalid,
                        'alert-warning':
                          refValulationDate.invalid &&
                          (refValulationDate.dirty ||
                            refValulationDate.touched ||
                            refValulationDate.untouched)
                      }"
                      [(ngModel)]="assetModel.ValulationDate"
                      class="form-control input-text-css"
                      required
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker9"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker9></mat-datepicker>
                  </div>
                  <!-- <input
                    name="ValulationDate "
                    id="ValulationDate "
                    #refValulationDate="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refValulationDate.invalid,
                      'alert-warning':
                        refValulationDate.invalid &&
                        (refValulationDate.dirty ||
                          refValulationDate.touched ||
                          refValulationDate.untouched)
                    }"
                    [(ngModel)]="assetModel.ValulationDate"
                    value="{{
                      assetModel.ValulationDate | date : 'dd/MM/yyyy'
                    }}"
                    class="form-control input-text-css"
                    [(bsValue)]="assetModel.ValulationDate"
                    bsDatepicker
                    required
                  /> -->
                </div>
                <div class="col-md-2">
                  <span class="required-lable">Total Valution Amount</span>
                  <input
                    required
                    type="text"
                    #refTotalValulationAmount="ngModel"
                    disabled
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && refTotalValulationAmount.invalid,
                      'alert-warning':
                        refTotalValulationAmount.invalid &&
                        (refTotalValulationAmount.dirty ||
                          refTotalValulationAmount.touched ||
                          refTotalValulationAmount.untouched)
                    }"
                    [(ngModel)]="assetModel.TotalValulationAmount"
                    name="TotalValulationAmount"
                    id="TotalValulationAmount"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-2">
                  <span class="required-lable">Valuer PhoneNo</span>
                  <input
                    required
                    type="text"
                    #refValuerPhoneNo="ngModel"
                    [(ngModel)]="assetModel.ValuerPhoneNo"
                    numbersOnly
                    maxlength="10"
                    minlength="10"
                    name="ValuerPhoneNo"
                    [ngClass]="{
                      'is-invalid': f.submitted && refValuerPhoneNo.invalid,
                      'alert-warning':
                        refValuerPhoneNo.invalid &&
                        (refValuerPhoneNo.dirty ||
                          refValuerPhoneNo.touched ||
                          refValuerPhoneNo.untouched)
                    }"
                    id="ValuerPhoneNo"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-3">
                  <span class="required-lable">Valuer FirmName</span>
                  <input
                    required
                    type="text"
                    #refValuerFirmName="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refValuerFirmName.invalid,
                      'alert-warning':
                        refValuerFirmName.invalid &&
                        (refValuerFirmName.dirty ||
                          refValuerFirmName.touched ||
                          refValuerFirmName.untouched)
                    }"
                    [(ngModel)]="assetModel.ValuerFirmName"
                    name="ValuerFirmName"
                    id="ValuerFirmName"
                    class="form-control input-text-css"
                  />
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="PersonalForm == 'PersonalForm'">
              <div class="mt-2">
                <div class="row mt-2">
                  <div class="col-md-3">
                    <span class="required-lable"> Select Category </span>
                    <select
                      name="Category_Id"
                      id="Category_Id"
                      #refCategory_Id="ngModel"
                      [(ngModel)]="Category_Id"
                      class="form-control input-text-css"
                      required
                      [ngClass]="{
                        'is-invalid': f.submitted && refCategory_Id.invalid,
                        'alert-warning':
                          refCategory_Id.invalid &&
                          (refCategory_Id.dirty ||
                            refCategory_Id.touched ||
                            refCategory_Id.untouched)
                      }"
                      placeholder="Enter Category"
                      (change)="onChangeCategory(Category_Id)"
                    >
                      <option value="">Select Category</option>
                      <option
                        *ngFor="let category of categoryList"
                        [value]="category.Id"
                      >
                        {{ category.Category_Name }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-3">
                    <span class="required-lable"> Select Manufacture </span>
                    <select
                      name="Manufacture_Id"
                      id="Manufacture_Id"
                      #refManufacture_Id="ngModel"
                      required
                      [ngClass]="{
                        'is-invalid': f.submitted && refManufacture_Id.invalid,
                        'alert-warning':
                          refManufacture_Id.invalid &&
                          (refManufacture_Id.dirty ||
                            refManufacture_Id.touched ||
                            refManufacture_Id.untouched)
                      }"
                      [(ngModel)]="Manufacture_Id"
                      class="form-control input-text-css"
                      (change)="getProductName(Category_Id, Manufacture_Id)"
                    >
                      <option value="">Select Manufacture</option>
                      <option
                        *ngFor="let manufacture of manufactureList"
                        [value]="manufacture.Id"
                      >
                        {{ manufacture.Manufacture_Name }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-3">
                    <span class="required-lable"> Select Product </span>
                    <select
                      name="Product_Id"
                      id="Product_Id"
                      #refProduct_Id="ngModel"
                      [(ngModel)]="Product_Id"
                      class="form-control input-text-css"
                      required
                      [ngClass]="{
                        'is-invalid': f.submitted && refProduct_Id.invalid,
                        'alert-warning':
                          refProduct_Id.invalid &&
                          (refProduct_Id.dirty ||
                            refProduct_Id.touched ||
                            refProduct_Id.untouched)
                      }"
                      (change)="getModelName(Product_Id)"
                    >
                      <option value="">Select Product</option>

                      <option
                        *ngFor="let product of productList"
                        [value]="product.Id"
                      >
                        {{ product.Product_Name }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-3">
                    <span class="required-lable"> Select Model </span>
                    <select
                      name="Model_Id"
                      id="Model_Id"
                      #refrefModel_Id="ngModel"
                      [(ngModel)]="Model_Id"
                      class="form-control input-text-css"
                      required
                      [ngClass]="{
                        'is-invalid': f.submitted && refrefModel_Id.invalid,
                        'alert-warning':
                          refrefModel_Id.invalid &&
                          (refrefModel_Id.dirty ||
                            refrefModel_Id.touched ||
                            refrefModel_Id.untouched)
                      }"
                    >
                      <option value="">Select Model</option>

                      <option
                        *ngFor="let model of modelList"
                        [value]="model.Id"
                      >
                        {{ model.Model_Name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-3">
                    <span>Variant</span>
                    <input
                      type="text"
                      #refVarient="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refVarient.invalid,
                        'alert-warning':
                          refVarient.invalid &&
                          (refVarient.dirty ||
                            refVarient.touched ||
                            refVarient.untouched)
                      }"
                      [(ngModel)]="Varient"
                      name="Varient"
                      id="Varient"
                      class="form-control input-text-css"
                      placeholder="Enter Variant"
                    />
                  </div>

                  <div class="col-md-3">
                    <span>Identification 1</span>
                    <input
                      type="text"
                      #refIdentification_No1="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refIdentification_No1.invalid,
                        'alert-warning':
                          refIdentification_No1.invalid &&
                          (refIdentification_No1.dirty ||
                            refIdentification_No1.touched ||
                            refIdentification_No1.untouched)
                      }"
                      [(ngModel)]="Identification_No1"
                      name="Identification_No1"
                      id="Identification_No1"
                      class="form-control input-text-css"
                      placeholder="Enter Identification 1"
                    />
                  </div>
                  <div class="col-md-3">
                    <span>Identification 2</span>
                    <input
                      type="text"
                      #refIdentification_No2="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refIdentification_No2.invalid,
                        'alert-warning':
                          refIdentification_No2.invalid &&
                          (refIdentification_No2.dirty ||
                            refIdentification_No2.touched ||
                            refIdentification_No2.untouched)
                      }"
                      [(ngModel)]="Identification_No2"
                      name="Identification_No2"
                      id="Identification_No2"
                      class="form-control input-text-css"
                      placeholder="Enter Identification 2"
                    />
                  </div>

                  <div class="col-md-3">
                    <span class="required-lable">Cost</span>
                    <input
                      required
                      type="number"
                      #refCost="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refCost.invalid,
                        'alert-warning':
                          refCost.invalid &&
                          (refCost.dirty ||
                            refCost.touched ||
                            refCost.untouched)
                      }"
                      [(ngModel)]="Cost"
                      name="Cost"
                      id="Cost"
                      class="form-control input-text-css"
                      placeholder="Enter Cost"
                    />
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-3">
                    <span>Color</span>
                    <input
                      type="text"
                      #refColorName="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refColorName.invalid,
                        'alert-warning':
                          refColorName.invalid &&
                          (refColorName.dirty ||
                            refColorName.touched ||
                            refColorName.untouched)
                      }"
                      [(ngModel)]="ColorName"
                      name="ColorName"
                      id="ColorName"
                      class="form-control input-text-css"
                      placeholder="Enter Color"
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Dealer Name</span>
                    <select
                      name="Dealer_Id"
                      (change)="DealerChange(Dealer_Id)"
                      id="Dealer_Id"
                      #refDealer_Id="ngModel"
                      required
                      [(ngModel)]="Dealer_Id"
                      [ngClass]="{
                        'is-invalid': f.submitted && refDealer_Id.invalid,
                        'alert-warning':
                          refDealer_Id.invalid &&
                          (refDealer_Id.dirty ||
                            refDealer_Id.touched ||
                            refDealer_Id.untouched)
                      }"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Dealer</option>
                      <option
                        *ngFor="let collection of DealerList"
                        [value]="collection.PartnerId"
                      >
                        {{ collection.Partner }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-3">
                    <span>Dealer Contact Person</span>
                    <input
                      type="text"
                      #refDealerPerson="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refDealerPerson.invalid,
                        'alert-warning':
                          refDealerPerson.invalid &&
                          (refDealerPerson.dirty ||
                            refDealerPerson.touched ||
                            refDealerPerson.untouched)
                      }"
                      [(ngModel)]="DealerPerson"
                      name="DealerPerson"
                      id="DealerPerson"
                      class="form-control input-text-css"
                      placeholder="Enter Dealer Number"
                    />
                  </div>
                  <div class="col-md-3">
                    <span>Dealer Contact Number</span>
                    <input
                      type="text"
                      #refDealerPhoneNo="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refDealerPhoneNo.invalid,
                        'alert-warning':
                          refDealerPhoneNo.invalid &&
                          (refDealerPhoneNo.dirty ||
                            refDealerPhoneNo.touched ||
                            refDealerPhoneNo.untouched)
                      }"
                      [(ngModel)]="DealerPhoneNo"
                      name="DealerPhoneNo"
                      id="DealerPhoneNo"
                      class="form-control input-text-css"
                      placeholder="Enter Dealer Number"
                    />
                  </div>

                  <div class="col-md-3">
                    <span class="required-lable">Invoice No.</span>
                    <input
                      required
                      type="text"
                      #refInvoice_No="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refInvoice_No.invalid,
                        'alert-warning':
                          refInvoice_No.invalid &&
                          (refInvoice_No.dirty ||
                            refInvoice_No.touched ||
                            refInvoice_No.untouched)
                      }"
                      [(ngModel)]="Invoice_No"
                      name="Invoice_No"
                      id="Invoice_No"
                      class="form-control input-text-css"
                      placeholder="Enter Invoice No."
                    />
                  </div>

                  <div class="col-md-3">
                    <span class="required-lable">Invoice Date</span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker1"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        name="Invoice_Date"
                        id="Invoice_Date"
                        #refInvoice_Date="ngModel"
                        [max]="today"
                        required
                        [ngClass]="{
                          'is-invalid': f.submitted && refInvoice_Date.invalid,
                          'alert-warning':
                            refInvoice_Date.invalid &&
                            (refInvoice_Date.dirty ||
                              refInvoice_Date.touched ||
                              refInvoice_Date.untouched)
                        }"
                        class="form-control input-text-css"
                        [(ngModel)]="Invoice_Date"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker1"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                    </div>
                  </div>
                </div>
              </div>

              <ng-container>
                <div class="mt-3">
                  <div class="row m-0">
                    <div class="col-md-12 p-1">
                      <div class="bg-success1">Upload Images:</div>
                    </div>
                  </div>
                  <div class="row m-0">
                    <div class="col-md-12 upload">
                      <!-- <B> Upload Vehicle Images </B> -->
                      <input
                        #docFile5
                        type="file"
                        [multiple]="false"
                        accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                        (change)="
                          fileChangeListenerAttachment(docFile5.files, docFile5)
                        "
                        style="display: none"
                      />
                      <div
                        class="row m-0 mt-2 align-items-center"
                        *ngFor="let report of AssetsAttachment; let i = index"
                      >
                        <div class="col-md-1 pl-0">{{ i + 1 }}</div>
                        <div class="col-md-3">
                          <input
                            type="text"
                            placeholder="Title"
                            name="title{{ i }}"
                            id="title{{ i }}"
                            #refTitle="ngModel"
                            [(ngModel)]="report.Title"
                            class="form-control input-text-css"
                          />
                        </div>
                        <div class="col-md-3">
                          <p class="m-0 row">
                            <i
                              class="fa fa-upload ml-2"
                              *ngIf="!report.DocFileName"
                              (click)="docFile5.click()"
                              aria-hidden="true"
                            ></i>
                            <ng-container *ngIf="report.DocFileName">
                              <div>
                                <i
                                  class="fa fa-file-pdf mr-2"
                                  aria-hidden="true"
                                ></i
                                >{{ report.DocFileName }}
                                <i
                                  class="fa fa-times ml-2"
                                  aria-hidden="true"
                                  style="cursor: pointer"
                                  (click)="removeFileAttachment(i)"
                                ></i>
                              </div>
                            </ng-container>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <hr />
            </ng-container>

            <div
              *ngIf="PageAcess[0].Add == 1 && !updateBtn"
              class="text-right mb-2"
              style="margin-top: 6px"
            >
              <button
                type="button"
                (click)="saveAssetInfo('Primary Collateral')"
                [disabled]="!f.form.valid"
                mat-raised-button
                class="mt-3 btn font-size-12 button-btn"
                [class.spinner]="loading"
              >
                Save
              </button>
            </div>

            <div
              *ngIf="
                PageAcess &&
                PageAcess[0].Edit == 1 &&
                isEdit &&
                PageAcess[0].Edit == 1 &&
                !loanSummary.CloseDate
              "
              class="text-right mb-2"
            >
              <button
                type="button"
                (click)="editAssetInfo()"
                mat-raised-button
                class="mt-3 btn font-size-12 button-btn"
                [class.spinner]="loading"
              >
                Edit
              </button>
            </div>
            <div
              *ngIf="updateBtn && PageAcess[0].Edit == 1"
              class="text-right mb-2"
            >
              <button
                type="button"
                (click)="saveAssetInfo('Primary Collateral')"
                mat-raised-button
                class="mt-3 btn font-size-12 button-btn"
                [class.spinner]="loading"
                [disabled]="!f.form.valid"
              >
                Update
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<!--end popup form  add Primary collateral-->

<!--start popup form add additional collateral -->
<div
  class="modal fade in"
  id="additionalcollateral"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Additional Collateral
        </h6>
        <button
          type="button"
          (click)="onCloseKYC()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <!-- *ngIf="AssetsForms=='Forms'" -->
        <ng-container>
          <form #f="ngForm" novalidate>
            <div class="row m-0">
              <div class="col-md-3 p-1">
                <span class="required-lable">Add Additional Collateral</span>
                <select
                  name="refLoanProduct"
                  (change)="selectProducts($event)"
                  id="refLoanProduct"
                  [disabled]="Addi_ProductCat"
                  #refLoanProduct="ngModel"
                  [(ngModel)]="ProductCatId"
                  class="form-control input-text-css"
                >
                  <option value="">Select Collateral</option>
                  <option
                    *ngFor="let productCategory of ProductCategoryDropdown"
                    [value]="productCategory.ProductCatId"
                  >
                    {{ productCategory.Product_Category }}
                  </option>
                </select>
              </div>
            </div>

            <ng-container *ngIf="propertyForms == 'Vehicles'">
              <div class="formborder mt-3">
                <div class="row mt-2">
                  <div class="col-md-5">
                    <div class="form-check-inline">
                      <input
                        class="form-check-input"
                        [(ngModel)]="vehicle_type"
                        (change)="selectVehicleType('New')"
                        type="radio"
                        name="inlineRadioOptions"
                        id="NewVehicle1"
                        value="New"
                      />
                      <label class="form-check-label" for="NewVehicle1"
                        >New Vehicle</label
                      >
                    </div>
                    <div class="form-check-inline">
                      <input
                        class="form-check-input"
                        [(ngModel)]="vehicle_type"
                        (change)="selectVehicleType('Used')"
                        type="radio"
                        name="inlineRadioOptions"
                        id="UsedVehicle1"
                        value="Used"
                      />
                      <label class="form-check-label" for="UsedVehicle1"
                        >Used Vehicle</label
                      >
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="form-check-inline">
                      <input
                        class="form-check-input"
                        [(ngModel)]="commercialVehicle"
                        (change)="selectVehicleType('Commercial')"
                        type="radio"
                        name="inlineRadioOptions1"
                        id="Commercial1"
                        value="Commercial"
                      />
                      <label class="form-check-label" for="Commercial1"
                        >Commercial</label
                      >
                    </div>
                    <div class="form-check-inline">
                      <input
                        class="form-check-input"
                        [(ngModel)]="commercialVehicle"
                        (change)="selectVehicleType('Non-Commercial')"
                        type="radio"
                        name="inlineRadioOptions1"
                        id="NonCommercial1"
                        value="Non-Commercial"
                      />
                      <label class="form-check-label" for="NonCommercial1"
                        >Non Commercial</label
                      >
                    </div>
                  </div>
                  <div class="col-md-2" *ngIf="usedVehicle">
                    <button
                      class="btn font-size-12 button-btn"
                      (click)="viewVehicleGridModal()"
                    >
                      Grid
                    </button>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-3">
                    <span class="required-lable">Vehicle Manufacture</span>
                    <select
                      name="VehicleManufacture0"
                      id="VehicleManufacture0"
                      #refVehicleManufacture0="ngModel"
                      (change)="LMS_GetVehicleModelMaster($event)"
                      [(ngModel)]="VehicleManufacture"
                      class="form-control input-text-css"
                      required
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refVehicleManufacture0.invalid,
                        'alert-warning':
                          refVehicleManufacture0.invalid &&
                          (refVehicleManufacture0.dirty ||
                            refVehicleManufacture0.touched ||
                            refVehicleManufacture0.untouched)
                      }"
                    >
                      <option value="">Vehicle Manufacture</option>
                      <option
                        *ngFor="let data of ManufactureData"
                        [value]="data.Id"
                      >
                        {{ data.Manufacture }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Vehicle Category</span>
                    <select
                      name="VehicleCategory1"
                      id="VehicleCategory1"
                      (change)="LMS_GetVehicleModelMaster($event)"
                      #refVehicleCategory1="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refVehicleCategory1.invalid,
                        'alert-warning':
                          refVehicleCategory1.invalid &&
                          (refVehicleCategory1.dirty ||
                            refVehicleCategory1.touched ||
                            refVehicleCategory1.untouched)
                      }"
                      [(ngModel)]="SelectVehicleCategory"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Vehicle Category</option>
                      <option
                        *ngFor="let cat of vehicleCategoryData"
                        [value]="cat.Id"
                      >
                        {{ cat.Vehicle_Category }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Vehicle Model Name</span>
                    <select
                      (ngModelchange)="OnChangeVehicleMOdel()"
                      name="VehicleModelName1"
                      id="VehicleModelName1"
                      #refVehicleModelName1="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refVehicleModelName1.invalid,
                        'alert-warning':
                          refVehicleModelName1.invalid &&
                          (refVehicleModelName1.dirty ||
                            refVehicleModelName1.touched ||
                            refVehicleModelName1.untouched)
                      }"
                      [(ngModel)]="VehicleModel"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Vehicle Model Name</option>
                      <option
                        *ngFor="let data of vehicleModelData"
                        [value]="data.Id"
                      >
                        {{ data.Model_Name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Variant</span>
                    <input
                      required
                      type="text"
                      #refVariant1="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refVariant1.invalid,
                        'alert-warning':
                          refVariant1.invalid &&
                          (refVariant1.dirty ||
                            refVariant1.touched ||
                            refVariant1.untouched)
                      }"
                      [(ngModel)]="Variant"
                      name="Variant1"
                      id="Variant1"
                      class="form-control input-text-css"
                      placeholder="Variant"
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="">Manufacture Year</span>
                    <input
                      name="MefgYear1"
                      [(ngModel)]="ManufactureYear"
                      id="MefgYear1"
                      placeholder="Start Year"
                      class="form-control input-text-css"
                      bsDatepicker
                      [bsConfig]="bsConfig"
                      required
                      #refMefgYear1="ngModel"
                      (ngModelChange)="OnChangeyear()"
                      [ngClass]="{
                        'is-invalid': f.submitted && refMefgYear1.invalid,
                        'alert-warning':
                          refMefgYear1.invalid &&
                          (refMefgYear1.dirty ||
                            refMefgYear1.touched ||
                            refMefgYear1.untouched)
                      }"
                    />
                    <!-- <input
          name="MefgYear1"
          id="MefgYear1"
          #refMefgYear1="ngModel"
          maxlength="4"
          minlength="4"
          numbersOnly
          [ngClass]="{
            'is-invalid': f.submitted && refMefgYear1.invalid,
            'alert-warning':
              refMefgYear1.invalid &&
              (refMefgYear1.dirty ||
                refMefgYear1.touched ||
                refMefgYear1.untouched)
          }"
          [(ngModel)]="ManufactureYear"
          class="form-control input-text-css"
          placeholder="Manufacture Year"
        /> -->
                  </div>
                  <div class="col-md-3">
                    <span class="">Vehicle Registation No</span>
                    <input
                      type="text"
                      #refVehicleRegistationNo1="ngModel"
                      (blur)="checkDuplication_Vehicle('RegistationNo')"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refVehicleRegistationNo1.invalid,
                        'alert-warning':
                          refVehicleRegistationNo1.invalid &&
                          (refVehicleRegistationNo1.dirty ||
                            refVehicleRegistationNo1.touched ||
                            refVehicleRegistationNo1.untouched)
                      }"
                      [(ngModel)]="VehicleRegistationNo"
                      name="VehicleRegistationNo1"
                      id="VehicleRegistationNo1"
                      class="form-control input-text-css"
                      placeholder="Vehicle Registation No"
                    />
                  </div>

                  <div class="col-md-3">
                    <span class="">Registation Date</span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker10"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        name="RegistationDate1"
                        id="RegistationDate1"
                        #refRegistationDate1="ngModel"
                        (dateChange)="ChangeRegistationDate($event)"
                        [ngClass]="{
                          'is-invalid':
                            f.submitted && refRegistationDate1.invalid,
                          'alert-warning':
                            '' &&
                            (refRegistationDate1.dirty ||
                              refRegistationDate1.touched ||
                              refRegistationDate1.untouched)
                        }"
                        [(ngModel)]="RegistationDate"
                        class="form-control input-text-css"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker10"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker10></mat-datepicker>
                    </div>
                    <!-- <input
          name="RegistationDate1"
          id="RegistationDate1"
          #refRegistationDate1="ngModel"
          (ngModelChange)="ChangeRegistationDate($event)"
          [ngClass]="{
            'is-invalid':
              f.submitted && refRegistationDate1.invalid,
            'alert-warning':
              '' &&
              (refRegistationDate1.dirty ||
                refRegistationDate1.touched ||
                refRegistationDate1.untouched)
          }"
          [(ngModel)]="RegistationDate"
          value="{{ RegistationDate | date : 'dd/MM/yyyy' }}"
          class="form-control input-text-css"
          [(bsValue)]="RegistationDate"
          bsDatepicker
          placeholder="Registation Date"
        /> -->
                  </div>
                  <div class="col-md-3">
                    <span class="">Registation Expiry Date</span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker11"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        name="RegistationExpiryDate1"
                        id="RegistationExpiryDate1"
                        #refRegistationExpiryDate1="ngModel"
                        (dateChange)="ChangeExpiryDate($event)"
                        [min]="RegistationDate"
                        [ngClass]="{
                          'is-invalid':
                            f.submitted && refRegistationExpiryDate1.invalid,
                          'alert-warning':
                            '' &&
                            (refRegistationExpiryDate1.dirty ||
                              refRegistationExpiryDate1.touched ||
                              refRegistationExpiryDate1.untouched)
                        }"
                        [(ngModel)]="RegistationExpiryDate"
                        class="form-control input-text-css"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker11"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker11></mat-datepicker>
                    </div>
                    <!-- <input
          name="RegistationExpiryDate1"
          id="RegistationExpiryDate1"
          #refRegistationExpiryDate1="ngModel"
          (ngModelChange)="ChangeExpiryDate($event)"
          [minDate]="RegistationDate"
          [ngClass]="{
            'is-invalid':
              f.submitted && refRegistationExpiryDate1.invalid,
            'alert-warning':
              '' &&
              (refRegistationExpiryDate1.dirty ||
                refRegistationExpiryDate1.touched ||
                refRegistationExpiryDate1.untouched)
          }"
          [(ngModel)]="RegistationExpiryDate"
          value="{{ RegistationExpiryDate | date : 'dd/MM/yyyy' }}"
          class="form-control input-text-css"
          [(bsValue)]="RegistationExpiryDate"
          bsDatepicker
          placeholder="Registation Expiry Date"
        /> -->
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Fuel Type</span>
                    <select
                      name="FuelType1"
                      id="FuelType1"
                      #refFuelType1="ngModel"
                      [(ngModel)]="SelectFuelType"
                      [ngClass]="{
                        'is-invalid': f.submitted && refFuelType1.invalid,
                        'alert-warning':
                          refFuelType1.invalid &&
                          (refFuelType1.dirty ||
                            refFuelType1.touched ||
                            refFuelType1.untouched)
                      }"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Fuel Type</option>
                      <option
                        *ngFor="let Type of FuelTypeOption"
                        [value]="Type.Id"
                      >
                        {{ Type.Value }}
                      </option>
                    </select>
                  </div>
                  <div
                    class="col-md-3"
                    *ngIf="commercialVehicle == 'Commercial'"
                  >
                    <span class="">Road Tax Upto</span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker12"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        name="RoadTaxUpto1"
                        id="RoadTaxUpto1"
                        #refRoadTaxUpto1="ngModel"
                        class="form-control input-text-css"
                        [ngClass]="{
                          'is-invalid': f.submitted && refRoadTaxUpto1.invalid,
                          'alert-warning':
                            '' &&
                            (refRoadTaxUpto1.dirty ||
                              refRoadTaxUpto1.touched ||
                              refRoadTaxUpto1.untouched)
                        }"
                        [(ngModel)]="RoadTaxUpto"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker12"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker12></mat-datepicker>
                    </div>
                    <!-- <input
          name="RoadTaxUpto1"
          id="RoadTaxUpto1"
          #refRoadTaxUpto1="ngModel"
          placeholder="Road Tax Upto"
          [ngClass]="{
            'is-invalid': f.submitted && refRoadTaxUpto1.invalid,
            'alert-warning':
              '' &&
              (refRoadTaxUpto1.dirty ||
                refRoadTaxUpto1.touched ||
                refRoadTaxUpto1.untouched)
          }"
          [(ngModel)]="RoadTaxUpto"
          value="{{ RoadTaxUpto | date : 'dd/MM/yyyy' }}"
          class="form-control input-text-css"
          [(bsValue)]="RoadTaxUpto"
          bsDatepicker
        /> -->
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Color</span>
                    <input
                      required
                      type="text"
                      #refClr1="ngModel"
                      placeholder="Color"
                      [ngClass]="{
                        'is-invalid': f.submitted && refClr1.invalid,
                        'alert-warning':
                          refClr1.invalid &&
                          (refClr1.dirty ||
                            refClr1.touched ||
                            refClr1.untouched)
                      }"
                      [(ngModel)]="Color"
                      name="Clr1"
                      id="Clr1"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div
                    class="col-md-3"
                    *ngIf="commercialVehicle == 'Commercial'"
                  >
                    <span class="">Fitness Upto</span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker13"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        name="FitnessUpto1"
                        id="FitnessUpto1"
                        #refFitnessUpto1="ngModel"
                        class="form-control input-text-css"
                        [ngClass]="{
                          'is-invalid': f.submitted && refFitnessUpto1.invalid,
                          'alert-warning':
                            '' &&
                            (refFitnessUpto1.dirty ||
                              refFitnessUpto1.touched ||
                              refFitnessUpto1.untouched)
                        }"
                        [(ngModel)]="FitnessUpto"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker13"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker13></mat-datepicker>
                    </div>
                    <!-- <input
          name="FitnessUpto1"
          id="FitnessUpto1"
          #refFitnessUpto1="ngModel"
          placeholder="Fitness Upto"
          [ngClass]="{
            'is-invalid': f.submitted && refFitnessUpto1.invalid,
            'alert-warning':
              '' &&
              (refFitnessUpto1.dirty ||
                refFitnessUpto1.touched ||
                refFitnessUpto1.untouched)
          }"
          [(ngModel)]="FitnessUpto"
          value="{{ FitnessUpto | date : 'dd/MM/yyyy' }}"
          class="form-control input-text-css"
          [(bsValue)]="FitnessUpto"
          bsDatepicker
        /> -->
                  </div>

                  <div
                    class="col-md-3"
                    *ngIf="commercialVehicle == 'Commercial'"
                  >
                    <span class="">Permit Upto</span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker14"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        name="PermitUpto1"
                        id="PermitUpto1"
                        #refPermitUpto1="ngModel"
                        [ngClass]="{
                          'is-invalid': f.submitted && refPermitUpto1.invalid,
                          'alert-warning':
                            '' &&
                            (refPermitUpto1.dirty ||
                              refPermitUpto1.touched ||
                              refPermitUpto1.untouched)
                        }"
                        [(ngModel)]="PermitUpto"
                        class="form-control input-text-css"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker14"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker14></mat-datepicker>
                    </div>
                    <!-- <input
          name="PermitUpto1"
          id="PermitUpto1"
          #refPermitUpto1="ngModel"
          placeholder="Permit Upto"
          [ngClass]="{
            'is-invalid': f.submitted && refPermitUpto1.invalid,
            'alert-warning':
              '' &&
              (refPermitUpto1.dirty ||
                refPermitUpto1.touched ||
                refPermitUpto1.untouched)
          }"
          [(ngModel)]="PermitUpto"
          value="{{ PermitUpto | date : 'dd/MM/yyyy' }}"
          class="form-control input-text-css"
          [(bsValue)]="PermitUpto"
          bsDatepicker
        /> -->
                  </div>

                  <div class="col-md-3">
                    <span class="required-lable">Vehicle Cost</span>
                    <input
                      required
                      type="number"
                      numbersOnly
                      min="0"
                      #reVC1="ngModel"
                      placeholder="Vehicle Cost"
                      [ngClass]="{
                        'is-invalid': f.submitted && reVC1.invalid,
                        'alert-warning':
                          reVC1.invalid &&
                          (reVC1.dirty || reVC1.touched || reVC1.untouched)
                      }"
                      [(ngModel)]="VehicleCost"
                      name="VC1"
                      id="VC1"
                      class="form-control input-text-css"
                    />
                  </div>

                  <div
                    class="col-md-3"
                    *ngIf="commercialVehicle == 'Commercial'"
                  >
                    <span class="">Route</span>
                    <input
                      type="text"
                      [(ngModel)]="Route"
                      name="Route1"
                      id="Route1"
                      class="form-control input-text-css"
                      placeholder="Route"
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="">Engine No</span>
                    <input
                      type="text"
                      [(ngModel)]="EngineNo"
                      #refEngineNo1="ngModel"
                      name="ENO1"
                      [required]="
                        (InvoiceNo != '' && InvoiceNo != null) ||
                        (VehicleRegistationNo != '' &&
                          VehicleRegistationNo != null)
                      "
                      [ngClass]="{
                        'is-invalid': f.submitted && refEngineNo1.invalid,
                        'alert-warning':
                          refEngineNo1.invalid &&
                          (refEngineNo1.dirty ||
                            refEngineNo1.touched ||
                            refEngineNo1.untouched)
                      }"
                      id="ENO1"
                      (blur)="checkDuplication_Vehicle('EngineNo')"
                      class="form-control input-text-css"
                      placeholder="Engine No"
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="">Chassis No</span>
                    <input
                      type="text"
                      #refChassisNo1="ngModel"
                      [(ngModel)]="ChassisNo"
                      name="CNO1"
                      [required]="
                        (InvoiceNo != '' && InvoiceNo != null) ||
                        (VehicleRegistationNo != '' &&
                          VehicleRegistationNo != null)
                      "
                      [ngClass]="{
                        'is-invalid': f.submitted && refChassisNo1.invalid,
                        'alert-warning':
                          refChassisNo1.invalid &&
                          (refChassisNo1.dirty ||
                            refChassisNo1.touched ||
                            refChassisNo1.untouched)
                      }"
                      id="CNO1"
                      (blur)="checkDuplication_Vehicle('ChassisNo')"
                      class="form-control input-text-css"
                      placeholder="Chassis No"
                    />
                  </div>

                  <div class="col-md-3">
                    <span class="">Key No</span>
                    <input
                      type="text"
                      [(ngModel)]="KeyNo"
                      name="KEYNO1"
                      id="KEYNO1"
                      class="form-control input-text-css"
                      placeholder="Key No"
                    />
                  </div>
                  <div class="col-md-3 mt-4">
                    <mat-checkbox
                      [(ngModel)]="RC_HPNEndorsment"
                      id="RC_HPNEndorsment1"
                      name="RC_HPNEndorsment1"
                    >
                      RC HPNEndorsment
                    </mat-checkbox>
                  </div>
                  <div class="col-md-3 mt-4" *ngIf="newVehicle">
                    <mat-checkbox
                      [(ngModel)]="Invoice_HPNEndorsment"
                      id="Invoice_HPNEndorsment1"
                      name="Invoice_HPNEndorsment1"
                    >
                      Invoice HPNEndorsment
                    </mat-checkbox>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-12">
                  <button
                    type="button"
                    *ngIf="RCVerified_Button"
                    [hidden]="RCKYC_IsVerified == 1 || RCLastVerfiedDate"
                    (click)="onVerification(0)"
                    style="
                      margin-left: 5px;
                      font-size: 12px;
                      width: 105px;
                      height: 35px;
                      margin-top: 16px;
                      padding: 0px;
                    "
                    class="btn font-size-12 button-btn"
                  >
                    RC Verification
                  </button>
                  <!--<i class="fa fa-eye ml-2"
         (click)="getdata()"
         *ngIf="VehicleRegistationNo != '' && RCVerified_Button &&(RCKYC_IsVerified == 1 || RCLastVerfiedDate) "
         style="color: green"></i>-->
                  <button
                    type="button"
                    *ngIf="
                      VehicleRegistationNo != '' &&
                      RCVerified_Button &&
                      (RCKYC_IsVerified == 1 || RCLastVerfiedDate)
                    "
                    (click)="onVerification(1)"
                    class="btn font-size-6 button-btn"
                    style="width: max-content !important"
                  >
                    RC RE-Verification
                  </button>
                  <span
                    *ngIf="
                      VehicleRegistationNo != '' &&
                      RCVerified_Button &&
                      RCLastVerfiedDate
                    "
                    style="margin-top: 25px"
                  >
                    VERIFIED {{ RCLastVerfiedDate }}
                  </span>
                </div>
              </div>
              <div class="row m-0">
                <div class="col-md-12 p-1"><hr /></div>
              </div>

              <ng-container *ngIf="newVehicle">
                <div class="formborder mt-3">
                  <div class="row m-0">
                    <div class="col-md-12 p-1">
                      <div class="bg-success1">New Vehicle:</div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-md-3">
                      <span>Dealer</span>
                      <select
                        name="DealerId1"
                        id="DealerId1"
                        #refDealerId1="ngModel"
                        [(ngModel)]="Dealer"
                        placeholder="Dealer"
                        (change)="DealerChange(Dealer)"
                        class="form-control input-text-css"
                      >
                        <option value="">Select Dealer</option>
                        <option
                          *ngFor="let collection of DealerList"
                          [value]="collection.PartnerId"
                        >
                          {{ collection.Partner }}
                        </option>
                      </select>
                      <!-- <select name="DealerId1" id="DealerId1" #refDealerId1="ngModel" [(ngModel)]="Dealer"
            [ngClass]="{ 'is-invalid': f.submitted && refDealerId1.invalid, 'alert-warning': refDealerId1.invalid && (refDealerId1.dirty || refDealerId1.touched || refDealerId1.untouched) }"
            class="form-control input-text-css" required>
            <option value="">Select Dealer</option>
            <option *ngFor="let collection of DealerList" [value]="collection.PartnerId"> {{collection.Partner}} </option>
          </select> -->
                    </div>
                    <div class="col-md-3">
                      <span>Dealer Contact Person</span>
                      <input
                        type="text"
                        #refDealerContactPerson1="ngModel"
                        [(ngModel)]="DealerContactPerson"
                        placeholder="Dealer Contact Person"
                        name="DealerContactPerson1"
                        id="DealerContactPerson1"
                        class="form-control input-text-css"
                      />
                      <!-- <input required type="text" #refDealerContactPerson1="ngModel" [(ngModel)]="DealerContactPerson" name="DealerContactPerson1"
          [ngClass]="{ 'is-invalid': f.submitted && refDealerContactPerson1.invalid, 'alert-warning': refDealerContactPerson1.invalid && (refDealerContactPerson1.dirty || refDealerContactPerson1.touched || refDealerContactPerson1.untouched) }"
          id="DealerContactPerson1" class="form-control input-text-css"> -->
                    </div>
                    <div class="col-md-3">
                      <span>Dealer Contact No.</span>
                      <input
                        type="text"
                        #refDealerContactNo1="ngModel"
                        [(ngModel)]="DealerContactNo"
                        numbersOnly
                        maxlength="10"
                        minlength="10"
                        name="DealerContactNo1"
                        id="DealerContactNo1"
                        class="form-control input-text-css"
                        placeholder="Dealer Contact No."
                      />
                      <!-- <input required type="text" #refDealerContactNo1="ngModel" [(ngModel)]="DealerContactNo" numbersOnly maxlength="10"
          minlength="10" name="DealerContactNo1"
          [ngClass]="{ 'is-invalid': f.submitted && refDealerContactNo1.invalid, 'alert-warning': refDealerContactNo1.invalid && (refDealerContactNo1.dirty || refDealerContactNo1.touched || refDealerContactNo1.untouched) }"
          id="DealerContactNo1" class="form-control input-text-css"> -->
                    </div>
                    <div class="col-md-3">
                      <span>Quotation No.</span>
                      <input
                        type="text"
                        #refDONo1="ngModel"
                        [(ngModel)]="DeliveryorderNo"
                        name="DoNO1"
                        id="DoNO1"
                        class="form-control input-text-css"
                        placeholder="Quotation No."
                      />
                      <!-- <input required type="text" #refDONo1="ngModel" [(ngModel)]="DeliveryorderNo"
          [ngClass]="{ 'is-invalid': f.submitted && refDONo1.invalid, 'alert-warning': refDONo1.invalid && (refDONo1.dirty || refDONo1.touched || refDONo1.untouched) }"
          name="DoNO1" id="DoNO1" class="form-control input-text-css"> -->
                    </div>
                    <div class="col-md-3">
                      <span>Quotation Date.</span>
                      <div class="datepicker_feild">
                        <input
                          [matDatepicker]="picker15"
                          dateConvert
                          placeholder="DD/MM/YYYY"
                          #refDODoDate1="ngModel"
                          [(ngModel)]="DeliveryorderDate"
                          name="DODoDate1"
                          id="DODoDate1"
                          (dateChange)="ChangeDeliveryorderDate($event)"
                          [max]="RegistationDate"
                          class="form-control input-text-css"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker15"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker15></mat-datepicker>
                      </div>
                      <!-- <input
            type="text"
            #refDODoDate1="ngModel"
            [(ngModel)]="DeliveryorderDate"
            name="DODoDate1"
            id="DODoDate1"
            (ngModelChange)="ChangeDeliveryorderDate($event)"
            [maxDate]="RegistationDate"
            value="{{ DeliveryorderDate | date : 'dd/MM/yyyy' }}"
            placeholder="DD/MM/YYYY"
            class="form-control input-text-css"
            [(bsValue)]="DeliveryorderDate"
            bsDatepicker
            placeholder="Quotation Date"
          /> -->
                      <!-- <input required type="text" #refDODoDate1="ngModel" [(ngModel)]="DeliveryorderDate" name="DODoDate1" id="DODoDate1"
          [ngClass]="{ 'is-invalid': f.submitted && refDODoDate1.invalid, 'alert-warning': refDODoDate1.invalid && (refDODoDate1.dirty || refDODoDate1.touched || refDODoDate1.untouched) }"
          (ngModelChange)="ChangeDeliveryorderDate($event)" [maxDate]="RegistationDate" value="{{ DeliveryorderDate | date:'dd/MM/yyyy' }}"
          placeholder="DD/MM/YYYY" class="form-control input-text-css" [(bsValue)]="DeliveryorderDate" bsDatepicker> -->
                    </div>
                    <div class="col-md-3">
                      <span>Estimation Amount</span>
                      <input
                        type="number"
                        min="0"
                        numbersOnly
                        #refEstimationAmount1="ngModel"
                        [(ngModel)]="EstimationAmount"
                        name="EstimationAmount1"
                        id="EstimationAmount1"
                        class="form-control input-text-css"
                        placeholder="Estimation Amount"
                      />
                      <!-- <input required type="number" min="0" numbersOnly #refEstimationAmount1="ngModel" [(ngModel)]="EstimationAmount"
          [ngClass]="{ 'is-invalid': f.submitted && refEstimationAmount1.invalid, 'alert-warning': refEstimationAmount1.invalid && (refEstimationAmount1.dirty || refEstimationAmount1.touched || refEstimationAmount1.untouched) }"
          name="EstimationAmount1" id="EstimationAmount1" class="form-control input-text-css"> -->
                    </div>
                    <div class="col-md-3">
                      <span>Invoice No.</span>
                      <input
                        type="text"
                        #refInvoiceNo1="ngModel"
                        [(ngModel)]="InvoiceNo"
                        [ngClass]="{
                          'is-invalid': f.submitted && refInvoiceNo1.invalid,
                          'alert-warning':
                            refInvoiceNo1.invalid &&
                            (refInvoiceNo1.dirty ||
                              refInvoiceNo1.touched ||
                              refInvoiceNo1.untouched)
                        }"
                        name="InvoiceNo1"
                        id="InvoiceNo1"
                        class="form-control input-text-css"
                        placeholder="Invoice No."
                      />
                    </div>
                    <div class="col-md-3">
                      <span>Invoice Date</span>
                      <div class="datepicker_feild">
                        <input
                          [matDatepicker]="picker16"
                          dateConvert
                          placeholder="DD/MM/YYYY"
                          name="InvoiceDate1"
                          id="InvoiceDate1"
                          (dateChange)="ChangeInvoiveDateDate($event)"
                          [max]="RegistationDate"
                          #refInvoiceDate1="ngModel"
                          [(ngModel)]="InvoiceDate"
                          class="form-control input-text-css"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker16"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker16></mat-datepicker>
                      </div>
                      <!-- <input
            type="text"
            #refInvoiceDate1="ngModel"
            [(ngModel)]="InvoiceDate"
            placeholder="Invoice Date"
            name="InvoiceDate1"
            id="InvoiceDate1"
            (ngModelChange)="ChangeInvoiveDateDate($event)"
            [maxDate]="RegistationDate"
            value="{{ InvoiveDate | date : 'dd/MM/yyyy' }}"
            class="form-control input-text-css"
            [(bsValue)]="InvoiveDate"
            bsDatepicker
          /> -->
                    </div>
                    <div class="col-md-3">
                      <span>Invoice Value</span>
                      <input
                        type="text"
                        numbersOnly
                        #refInvoiceValue1="ngModel"
                        [(ngModel)]="InvoiceValue"
                        placeholder="Invoice Value"
                        [ngClass]="{
                          'is-invalid': f.submitted && refInvoiceValue1.invalid,
                          'alert-warning':
                            refInvoiceValue1.invalid &&
                            (refInvoiceValue1.dirty ||
                              refInvoiceValue1.touched ||
                              refInvoiceValue1.untouched)
                        }"
                        name="InvoiceValue1"
                        id="InvoiceValue1"
                        class="form-control input-text-css"
                      />
                    </div>

                    <div class="col-md-3">
                      <span>Quotation in favour of</span>
                      <select
                        name="DOIssuedinfavourof1"
                        #refDOIssuedinfavourof1="ngModel"
                        [(ngModel)]="IssuedInFavourOf"
                        id="DOIssuedinfavourof1"
                        class="form-control input-text-css"
                      >
                        t-
                        <option value="">Select Customer</option>
                        <option
                          [value]="cus.CustomerId"
                          *ngFor="let cus of CustomerList"
                        >
                          {{ cus.Customer }}
                        </option>
                      </select>
                      <!-- <select name="DOIssuedinfavourof1" #refDOIssuedinfavourof1="ngModel" [(ngModel)]="IssuedInFavourOf" id="DOIssuedinfavourof1"
            [ngClass]="{ 'is-invalid': f.submitted && refDOIssuedinfavourof1.invalid, 'alert-warning': refDOIssuedinfavourof1.invalid && (refDOIssuedinfavourof1.dirty || refDOIssuedinfavourof1.touched || refDOIssuedinfavourof1.untouched) }"
            class="form-control input-text-css" required>
            <option value=""> Select Customer</option>
            <option [value]="cus.CustomerId" *ngFor="let cus of CustomerList">
              {{cus.Customer}}
            </option>
          </select> -->
                    </div>
                    <div class="col-md-3">
                      <span>Remark</span>
                      <input
                        type="text"
                        #refRemark1="ngModel"
                        [(ngModel)]="Remark"
                        placeholder="Remark"
                        name="Remark1"
                        id="Remark1"
                        class="form-control input-text-css"
                      />
                      <!-- <input required type="text" #refRemark1="ngModel" [(ngModel)]="Remark"
          [ngClass]="{ 'is-invalid': f.submitted && refRemark1.invalid, 'alert-warning': refRemark1.invalid && (refRemark1.dirty || refRemark1.touched || refRemark1.untouched) }"
          name="Remark1" id="Remark1" class="form-control input-text-css"> -->
                    </div>
                  </div>
                </div>

                <div class="row m-0">
                  <div class="col-md-12 p-1"><hr /></div>
                </div>
              </ng-container>

              <ng-container *ngIf="usedVehicle">
                <div class="formborder mt-3">
                  <div class="row m-0">
                    <div class="col-md-12 p-1">
                      <div class="bg-success1">Used Vehicle:</div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-md-3">
                      <span class="">Valuation Done By</span>
                      <select
                        name="ValuationDoneBy1"
                        id="ValuationDoneBy1"
                        #refValuationDoneBy1="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            f.submitted && refValuationDoneBy1.invalid,
                          'alert-warning':
                            refValuationDoneBy1.invalid &&
                            (refValuationDoneBy1.dirty ||
                              refValuationDoneBy1.touched ||
                              refValuationDoneBy1.untouched)
                        }"
                        [(ngModel)]="ValuationDoneBy"
                        class="form-control input-text-css"
                      >
                        <option value="">Select Valuation Done By</option>
                        <option
                          *ngFor="let collection of ValuationDoneByData"
                          [value]="collection.PartnerId"
                        >
                          {{ collection.Partner_Name }}
                        </option>
                      </select>
                    </div>

                    <div class="col-md-3">
                      <span class="">Valuation Date</span>
                      <!--<input type="text" #refValuationDate1="ngModel" (ngModelChange)="ValuationDateChange()" [(ngModel)]="ValuationDate" name="ValuationDate1" id="ValuationDate1"
          [ngClass]="{ 'is-invalid': f.submitted && refValuationDate1.invalid, 'alert-warning': refValuationDate1.invalid && (refValuationDate1.dirty || refValuationDate1.touched || refValuationDate1.untouched) }"
          value="{{ValuationDate | date:'dd/MM/yyyy' }}" placeholder="DD/MM/YYYY"
          class="form-control input-text-css" [(bsValue)]="ValuationDate" bsDatepicker>-->
                      <div class="datepicker_feild">
                        <input
                          [matDatepicker]="picker17"
                          dateConvert
                          placeholder="DD/MM/YYYY"
                          [min]="Application_Date"
                          name="ValuationDate1"
                          id="ValuationDate1"
                          #refValuationDate1="ngModel"
                          (dateChange)="ValuationDateChange()"
                          [ngClass]="{
                            'is-invalid':
                              f.submitted && refValuationDate1.invalid,
                            'alert-warning':
                              refValuationDate1.invalid &&
                              (refValuationDate1.dirty ||
                                refValuationDate1.touched ||
                                refValuationDate1.untouched)
                          }"
                          class="form-control input-text-css"
                          [(ngModel)]="ValuationDate"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker17"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker17></mat-datepicker>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <span class=""> Valuation Amount</span>

                      <input
                        type="number"
                        min="0"
                        numbersOnly
                        #refValuationAmount1="ngModel"
                        [(ngModel)]="ValutionAmount"
                        name="ValuationAmount1"
                        id="ValuationAmount1"
                        class="form-control input-text-css"
                        placeholder="Valuation Amount"
                        [ngClass]="{
                          'is-invalid':
                            f.submitted && refValuationAmount1.invalid,
                          'alert-warning':
                            refValuationAmount1.invalid &&
                            (refValuationAmount1.dirty ||
                              refValuationAmount1.touched ||
                              refValuationAmount1.untouched)
                        }"
                      />
                    </div>

                    <div class="col-md-3">
                      <span class="">Valuation Type</span>
                      <select
                        name="ValuationType1"
                        id="ValuationType1"
                        #refValuationType1="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            f.submitted && refValuationType1.invalid,
                          'alert-warning':
                            refValuationType1.invalid &&
                            (refValuationType1.dirty ||
                              refValuationType1.touched ||
                              refValuationType1.untouched)
                        }"
                        [(ngModel)]="ValuationType"
                        class="form-control input-text-css"
                      >
                        <option value="">Select Valuation Type</option>
                        <option value="Valuation">Valuation</option>
                        <option value="Grid">Grid</option>
                        <option value="IDV">IDV</option>
                      </select>
                    </div>
                    <div class="col-md-3">
                      <span class="">Other Valuation Type</span>
                      <input
                        type="text"
                        #refOtherValuationType1="ngModel"
                        [(ngModel)]="OtherValuationType"
                        name="OtherValuationType1"
                        [ngClass]="{
                          'is-invalid':
                            f.submitted && refOtherValuationType1.invalid,
                          'alert-warning':
                            refOtherValuationType1.invalid &&
                            (refOtherValuationType1.dirty ||
                              refOtherValuationType1.touched ||
                              refOtherValuationType1.untouched)
                        }"
                        id="OtherValuationType1"
                        class="form-control input-text-css"
                        placeholder="Other Valuation Type"
                      />
                    </div>
                    <div class="col-md-3">
                      <span class="">Other Valuation Amount</span>
                      <input
                        type="number"
                        min="0"
                        numbersOnly
                        #refOtherValuationAmt1="ngModel"
                        [(ngModel)]="OtherValuationAmount"
                        name="OtherValuationAmt1"
                        id="OtherValuationAmt1"
                        class="form-control input-text-css"
                        placeholder="Other Valuation Amount"
                        [ngClass]="{
                          'is-invalid':
                            f.submitted && refOtherValuationAmt1.invalid,
                          'alert-warning':
                            refOtherValuationAmt1.invalid &&
                            (refOtherValuationAmt1.dirty ||
                              refOtherValuationAmt1.touched ||
                              refOtherValuationAmt1.untouched)
                        }"
                      />
                    </div>
                  </div>
                </div>

                <div class="row m-0">
                  <div class="col-md-12 p-1"><hr /></div>
                </div>
              </ng-container>

              <ng-container>
                <div class="formborder mt-3">
                  <div class="row m-0">
                    <div class="col-md-12 p-1">
                      <div class="bg-success1">Upload Vehicle Images:</div>
                    </div>
                  </div>
                  <div class="row m-0">
                    <div class="col-md-12 upload">
                      <!-- <B> Upload Vehicle Images </B> -->
                      <input
                        #docFile5
                        type="file"
                        [multiple]="false"
                        accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                        (change)="
                          fileChangeListenerAttachment(docFile5.files, docFile5)
                        "
                        style="display: none"
                      />
                      <div
                        class="row m-0 mt-2 align-items-center"
                        *ngFor="let report of AssetsAttachment; let i = index"
                      >
                        <div class="col-md-1 pl-0">{{ i + 1 }}</div>
                        <div class="col-md-3">
                          <input
                            type="text"
                            placeholder="Title"
                            name="title{{ i }}"
                            id="title{{ i }}"
                            #refTitle="ngModel"
                            [(ngModel)]="report.Title"
                            class="form-control input-text-css"
                          />
                        </div>
                        <div class="col-md-3">
                          <p class="m-0 row">
                            <i
                              class="fa fa-upload ml-2"
                              *ngIf="!report.DocFileName"
                              (click)="docFile5.click()"
                              aria-hidden="true"
                            ></i>
                            <ng-container *ngIf="report.DocFileName">
                              <div>
                                <i
                                  class="fa fa-file-pdf mr-2"
                                  aria-hidden="true"
                                ></i
                                >{{ report.DocFileName }}
                                <i
                                  class="fa fa-times ml-2"
                                  aria-hidden="true"
                                  style="cursor: pointer"
                                  (click)="removeFileAttachment(i)"
                                ></i>
                              </div>
                            </ng-container>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div
                class="row mt-2 formborder ml-2 mr-2 mt-2"
                style="background-color: #d2ebec"
                *ngIf="
                  VehicleRegistationNo != '' &&
                  RCVerified_Button &&
                  (RCKYC_IsVerified == 1 || RCLastVerfiedDate)
                "
              >
                <div class="col-md-12 bg-success1">Verified RC Data</div>

                <div class="col-md-2 border-div">
                  <h6>Doucument Number</h6>
                  <p>
                    {{ APIResponse.DoucumentNumber }}
                  </p>
                </div>

                <div class="col-md-2 border-div">
                  <h6>Fit Up To</h6>
                  <p>
                    {{ APIResponse.fit_up_to }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Registration Date</h6>
                  <p>
                    {{ APIResponse.registration_date }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Owner Name</h6>
                  <p>
                    {{ APIResponse.owner_name }}
                  </p>
                </div>

                <div class="col-md-2 border-div">
                  <h6>Father Name</h6>
                  <p>
                    {{ APIResponse.father_name }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Mobile Number</h6>
                  <p>
                    {{ APIResponse.mobile_number }}
                  </p>
                </div>

                <div class="col-md-2 border-div">
                  <h6>Vehicle Category</h6>
                  <p>
                    {{ APIResponse.vehicle_category }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Vehicle Chasi Number</h6>
                  <p>
                    {{ APIResponse.vehicle_chasi_number }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Vehicle Engine Number</h6>
                  <p>
                    {{ APIResponse.vehicle_engine_number }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Maker Description</h6>
                  <p>
                    {{ APIResponse.maker_description }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Maker Model</h6>
                  <p>
                    {{ APIResponse.maker_model }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Body Type</h6>
                  <p>
                    {{ APIResponse.body_type }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Fuel Type</h6>
                  <p>
                    {{ APIResponse.fuel_type }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Color</h6>
                  <p>
                    {{ APIResponse.color }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Norms Type</h6>
                  <p>
                    {{ APIResponse.norms_type }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Financer</h6>
                  <p>
                    {{ APIResponse.financer }}
                  </p>
                </div>

                <div class="col-md-2 border-div">
                  <h6>financed</h6>
                  <p>
                    {{ APIResponse.financed }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Insurance Company</h6>
                  <p>
                    {{ APIResponse.insurance_company }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Insurance Policy Number</h6>
                  <p>
                    {{ APIResponse.insurance_policy_number }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Insurance Upto</h6>
                  <p>
                    {{ APIResponse.insurance_upto }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Manufacturing Date</h6>
                  <p>
                    {{ APIResponse.manufacturing_date }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Registered At</h6>
                  <p>
                    {{ APIResponse.registered_at }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Tax Upto</h6>
                  <p>
                    {{ APIResponse.tax_upto }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Tax Paid Upto</h6>
                  <p>
                    {{ APIResponse.tax_paid_upto }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Seat Capacity</h6>
                  <p>
                    {{ APIResponse.seat_capacity }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Vehicle Category Description</h6>
                  <p>
                    {{ APIResponse.vehicle_category_description }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Pucc Number</h6>
                  <p>
                    {{ APIResponse.pucc_number }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Pucc Upto</h6>
                  <p>
                    {{ APIResponse.pucc_upto }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Owner Number</h6>
                  <p>
                    {{ APIResponse.owner_number }}
                  </p>
                </div>
                <div class="col-md-12 border-div">
                  <h6>Permanent Address</h6>
                  <p>
                    {{ APIResponse.permanent_address }}
                  </p>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="propertyForms == 'Propertys'">
              <div class="formborder">
                <div class="row mt-2">
                  <div class="col-md-3">
                    <span class="required-lable">Property Owner Name</span>
                    <select
                      name="CustomerId1"
                      id="CustomerId1"
                      #refCustomerId1="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refCustomerId1.invalid,
                        'alert-warning':
                          refCustomerId1.invalid &&
                          (refCustomerId1.dirty ||
                            refCustomerId1.touched ||
                            refCustomerId1.untouched)
                      }"
                      [(ngModel)]="PropertyOwnerName"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Owner</option>
                      <option
                        [value]="cus.CustomerId"
                        *ngFor="let cus of CustomerList"
                      >
                        {{ cus.Customer }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-9">
                    <span class="required-lable">Address Of Property</span>
                    <textarea
                      required
                      type="text"
                      #reAddress1="ngModel"
                      [(ngModel)]="AddressOfProperty"
                      name="Address1"
                      id="Address1"
                      [ngClass]="{
                        'is-invalid': f.submitted && reAddress1.invalid,
                        'alert-warning':
                          reAddress1.invalid &&
                          (reAddress1.dirty ||
                            reAddress1.touched ||
                            reAddress1.untouched)
                      }"
                      class="form-control input-text-css"
                      placeholder="Address Of Property"
                      rows="1"
                    ></textarea>
                  </div>

                  <div class="col-md-3">
                    <span class="required-lable">Reg State</span>
                    <select
                      name="State1"
                      id="State1"
                      #refState1="ngModel"
                      [(ngModel)]="customerModel.Firm_RegState"
                      class="form-control input-text-css"
                      required
                      [ngClass]="{
                        'is-invalid': f.submitted && refState1.invalid,
                        'alert-warning':
                          refState1.invalid &&
                          (refState1.dirty ||
                            refState1.touched ||
                            refState1.untouched)
                      }"
                      (change)="GetDistrickDropdownReg($event)"
                    >
                      <option value="">Select State</option>
                      <option
                        *ngFor="let state of stateDropdown"
                        [value]="state.StateId"
                      >
                        {{ state.State_Name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Reg District</span>
                    <select
                      name="District1"
                      id="District1"
                      #refDistrict1="ngModel"
                      [(ngModel)]="customerModel.Firm_RegDistrict"
                      class="form-control input-text-css"
                      required
                      (change)="GetTehasilDropdownReg($event)"
                      [ngClass]="{
                        'is-invalid': f.submitted && refDistrict1.invalid,
                        'alert-warning':
                          refDistrict1.invalid &&
                          (refDistrict1.dirty ||
                            refDistrict1.touched ||
                            refDistrict1.untouched)
                      }"
                    >
                      <option value="">Select District</option>
                      <option
                        *ngFor="let district of districkDropdownReg"
                        [value]="district.DistrictId"
                      >
                        {{ district.District_Name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Reg Tehsil</span>
                    <select
                      name="Tasil1"
                      id="Tasil1"
                      #refTasil1="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refTasil1.invalid,
                        'alert-warning':
                          refTasil1.invalid &&
                          (refTasil1.dirty ||
                            refTasil1.touched ||
                            refTasil1.untouched)
                      }"
                      [(ngModel)]="customerModel.Firm_RegTehsil"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Tehsil</option>
                      <option
                        *ngFor="let tehsil of tehasilDropdownReg"
                        [value]="tehsil.TehsilId"
                      >
                        {{ tehsil.Tehsil_Name }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-3">
                    <span class="required-lable">Pincode</span>
                    <input
                      name="Pincode1"
                      id="Pincode1"
                      #refPincode1="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refPincode1.invalid,
                        'alert-warning':
                          refPincode1.invalid &&
                          (refPincode1.dirty ||
                            refPincode1.touched ||
                            refPincode1.untouched)
                      }"
                      [(ngModel)]="Pincode"
                      class="form-control input-text-css"
                      required
                    />
                  </div>

                  <div class="col-md-3">
                    <span class="required-lable">Type Of Property</span>
                    <select
                      name="TypeofProperty1"
                      id="TypeofProperty1"
                      #refTypeofProperty1="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refTypeofProperty1.invalid,
                        'alert-warning':
                          refTypeofProperty1.invalid &&
                          (refTypeofProperty1.dirty ||
                            refTypeofProperty1.touched ||
                            refTypeofProperty1.untouched)
                      }"
                      [(ngModel)]="TypeOfProperty"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Type Of Property</option>
                      <option
                        *ngFor="let Type of TypeOfPropertyList"
                        [value]="Type.Id"
                      >
                        {{ Type.Value }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Nature Of Property</span>
                    <select
                      name="NatureofProperty1"
                      id="NatureofProperty1"
                      #refNatureofProperty1="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refNatureofProperty1.invalid,
                        'alert-warning':
                          refNatureofProperty1.invalid &&
                          (refNatureofProperty1.dirty ||
                            refNatureofProperty1.touched ||
                            refNatureofProperty1.untouched)
                      }"
                      [(ngModel)]="NatureOfProperty"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Type Of Property</option>
                      <option
                        *ngFor="let Type of NatureOfPropertyList"
                        [value]="Type.Id"
                      >
                        {{ Type.Value }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Ownership Document</span>
                    <select
                      name="OwnershipDocument1"
                      id="OwnershipDocument1"
                      #refOwnershipDocument1="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refOwnershipDocument1.invalid,
                        'alert-warning':
                          refOwnershipDocument1.invalid &&
                          (refOwnershipDocument1.dirty ||
                            refOwnershipDocument1.touched ||
                            refOwnershipDocument1.untouched)
                      }"
                      [(ngModel)]="OwnershipDocument"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Ownership Document</option>
                      <option
                        *ngFor="let Type of OwnershipDocumentList"
                        [value]="Type.Id"
                      >
                        {{ Type.Value }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-3">
                    <span class="required-lable">Ownership Type</span>
                    <select
                      name="OwnershipType1"
                      id="OwnershipType1"
                      #refOwnershipType1="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refOwnershipType1.invalid,
                        'alert-warning':
                          refOwnershipType1.invalid &&
                          (refOwnershipType1.dirty ||
                            refOwnershipType1.touched ||
                            refOwnershipType1.untouched)
                      }"
                      [(ngModel)]="OwnershipType"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Ownership Type</option>
                      <option
                        *ngFor="let Type of OwnershipTypeList"
                        [value]="Type.Id"
                      >
                        {{ Type.Value }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Unit Of Measurement </span>
                    <select
                      name="UnitofMeasurement1"
                      id="UnitofMeasurement1"
                      #refUnitofMeasurement1="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refUnitofMeasurement1.invalid,
                        'alert-warning':
                          refUnitofMeasurement1.invalid &&
                          (refUnitofMeasurement1.dirty ||
                            refUnitofMeasurement1.touched ||
                            refUnitofMeasurement1.untouched)
                      }"
                      [(ngModel)]="UnitOfMeasurement"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Unit Of Measurement</option>
                      <option value="Sq. Ft">Sq. Ft</option>
                      <option value="Sq. Yards">Sq. Yards</option>
                      <option value="Sq. Metre">Sq. Metre</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Total Area </span>
                    <input
                      required
                      type="text"
                      appTwoDigitDecimaNumber
                      #reTotalArea1="ngModel"
                      [(ngModel)]="TotalArea"
                      name="TotalArea1"
                      id="TotalArea1"
                      [ngClass]="{
                        'is-invalid': f.submitted && reTotalArea1.invalid,
                        'alert-warning':
                          reTotalArea1.invalid &&
                          (reTotalArea1.dirty ||
                            reTotalArea1.touched ||
                            reTotalArea1.untouched)
                      }"
                      class="form-control input-text-css"
                      placeholder="Total Area"
                    />
                  </div>

                  <div class="col-md-3">
                    <span class="required-lable">Constructed Area </span>
                    <input
                      required
                      type="text"
                      appTwoDigitDecimaNumber
                      #reConstructedArea1="ngModel"
                      [(ngModel)]="ConstructedArea"
                      name="ConstructedArea1"
                      id="ConstructedArea1"
                      (change)="GetConstructedArea($event)"
                      [ngClass]="{
                        'is-invalid': f.submitted && reConstructedArea1.invalid,
                        'alert-warning':
                          reConstructedArea1.invalid &&
                          (reConstructedArea1.dirty ||
                            reConstructedArea1.touched ||
                            reConstructedArea1.untouched)
                      }"
                      class="form-control input-text-css"
                      placeholder="Constructed Area"
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="">Type Of Mortgage </span>
                    <select
                      name="TypeofMortgage1"
                      id="TypeofMortgage1"
                      #refTypeofMortgage1="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refTypeofMortgage1.invalid,
                        'alert-warning':
                          refTypeofMortgage1.invalid &&
                          (refTypeofMortgage1.dirty ||
                            refTypeofMortgage1.touched ||
                            refTypeofMortgage1.untouched)
                      }"
                      [(ngModel)]="TypeOfMortgage"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Type Of Mortgage</option>
                      <option value="Registered">Registered</option>
                      <option value="Equitable">Equitable</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="">Mortgage signed by</span>
                    <select
                      name="MortgageSingedBy1"
                      id="MortgageSingedBy1"
                      #refMortgageSingedBy1="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refMortgageSingedBy1.invalid,
                        'alert-warning':
                          refMortgageSingedBy1.invalid &&
                          (refMortgageSingedBy1.dirty ||
                            refMortgageSingedBy1.touched ||
                            refMortgageSingedBy1.untouched)
                      }"
                      [(ngModel)]="MortgageSingedBy"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Mortgage signed by</option>
                      <option
                        *ngFor="let collection of CollectionExecutiveData"
                        [value]="collection.EmpId"
                      >
                        {{ collection.Emp_FirstName }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-3">
                    <span class="required-lable">Valution Amount</span>
                    <input
                      required
                      type="number"
                      min="0"
                      numbersOnly
                      #refValuationAmount1="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refValuationAmount1.invalid,
                        'alert-warning':
                          refValuationAmount1.invalid &&
                          (refValuationAmount1.dirty ||
                            refValuationAmount1.touched ||
                            refValuationAmount1.untouched)
                      }"
                      [(ngModel)]="ValutionAmount"
                      name="ValuationAmount1"
                      id="ValuationAmount1"
                      class="form-control input-text-css"
                      placeholder="Valution Amount"
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="">Valution Done By</span>
                    <select
                      name="ValuationDoneBy1"
                      id="ValuationDoneBy1"
                      #refValuationDoneBy1="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refValuationDoneBy1.invalid,
                        'alert-warning':
                          refValuationDoneBy1.invalid &&
                          (refValuationDoneBy1.dirty ||
                            refValuationDoneBy1.touched ||
                            refValuationDoneBy1.untouched)
                      }"
                      [(ngModel)]="ValuationDoneBy"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Valuation Done By</option>
                      <option
                        *ngFor="let collection of ValuationDoneByData"
                        [value]="collection.PartnerId"
                      >
                        {{ collection.Partner_Name }}
                      </option>
                      <option value="0">Other</option>
                    </select>
                  </div>
                  <div
                    class="col-md-3"
                    *ngIf="ValuationDoneBy != '' && ValuationDoneBy == 0"
                  >
                    <span class="">Valution Done By Name</span>
                    <input
                      type="text"
                      #refValuationDoneByOther1="ngModel"
                      placeholder="Valution Done By Name"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refValuationDoneByOther1.invalid,
                        'alert-warning':
                          refValuationDoneByOther1.invalid &&
                          (refValuationDoneByOther1.dirty ||
                            refValuationDoneByOther1.touched ||
                            refValuationDoneByOther1.untouched)
                      }"
                      [(ngModel)]="ValuationDoneByOther"
                      name="ValuationDoneByOther1"
                      id="ValuationDoneByOther1"
                      class="form-control input-text-css"
                    />
                  </div>

                  <div class="col-md-12">
                    <span class="">Valution Remark</span>
                    <textarea
                      type="text"
                      #refValuationRemark1="ngModel"
                      [(ngModel)]="ValuationRemark"
                      name="ValuationRemark1"
                      id="ValuationRemark1"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refValuationRemark1.invalid,
                        'alert-warning':
                          refValuationRemark1.invalid &&
                          (refValuationRemark1.dirty ||
                            refValuationRemark1.touched ||
                            refValuationRemark1.untouched)
                      }"
                      class="form-control input-text-css"
                      placeholder="Valution Remark"
                    ></textarea>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-3">
                    <span class="">Search Report Done By</span>
                    <select
                      name="SearchReportDoneBy1"
                      id="SearchReportDoneBy1"
                      #refSearchReportDoneBy1="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refSearchReportDoneBy1.invalid,
                        'alert-warning':
                          refSearchReportDoneBy1.invalid &&
                          (refSearchReportDoneBy1.dirty ||
                            refSearchReportDoneBy1.touched ||
                            refSearchReportDoneBy1.untouched)
                      }"
                      [(ngModel)]="SearchReportDoneBy"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Report Done By</option>
                      <option
                        *ngFor="let collection of SearchReportDoneByData"
                        [value]="collection.PartnerId"
                      >
                        {{ collection.Partner_Name }}
                      </option>
                      <option value="0">Other</option>
                    </select>
                  </div>
                  <div
                    class="col-md-3"
                    *ngIf="SearchReportDoneBy != '' && SearchReportDoneBy == 0"
                  >
                    <span class="">Search Report Done By Name</span>
                    <input
                      type="text"
                      #refSearchReportDoneByOther1="ngModel"
                      [(ngModel)]="SearchReportDoneByOther"
                      name="SearchReportDoneByOther1"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refSearchReportDoneByOther1.invalid,
                        'alert-warning':
                          refSearchReportDoneByOther1.invalid &&
                          (refSearchReportDoneByOther1.dirty ||
                            refSearchReportDoneByOther.touched ||
                            refSearchReportDoneByOther1.untouched)
                      }"
                      id="SearchReportDoneByOther1"
                      class="form-control input-text-css"
                      placeholder="Search Report Done By Name"
                    />
                  </div>

                  <div
                    [ngClass]="
                      SearchReportDoneBy != '' && SearchReportDoneBy == 0
                        ? 'col-md-6'
                        : 'col-md-9'
                    "
                  >
                    <span class="">Search Report Remark</span>
                    <textarea
                      type="text"
                      #refSearchReportRemark1="ngModel"
                      [(ngModel)]="SearchReportRemark"
                      name="SearchReportRemark1"
                      id="SearchReportRemark1"
                      rows="1"
                      class="form-control input-text-css"
                      placeholder="Search Report Remark"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refSearchReportRemark1.invalid,
                        'alert-warning':
                          refSearchReportRemark1.invalid &&
                          (refSearchReportRemark1.dirty ||
                            refSearchReportRemark.touched ||
                            refSearchReportRemark1.untouched)
                      }"
                    ></textarea>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-2">
                    <b style="color: black"> Location </b>
                  </div>
                  <div class="col-md-4">
                    <span class="">Latitude</span>
                    <input
                      type="number"
                      step="00.000001"
                      #refLatitude1="ngModel"
                      placeholder="Latitude"
                      [ngClass]="{
                        'is-invalid': f.submitted && refLatitude1.invalid,
                        'alert-warning':
                          refLatitude1.invalid &&
                          (refLatitude1.dirty ||
                            refLatitude1.touched ||
                            refLatitude1.untouched)
                      }"
                      [(ngModel)]="latitude"
                      name="Latitude1"
                      id="Latitude1"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-4">
                    <span class="">Longitude</span>
                    <input
                      type="number"
                      step="00.000001"
                      #refLongitude1="ngModel"
                      placeholder="Longitude"
                      [ngClass]="{
                        'is-invalid': f.submitted && refLongitude1.invalid,
                        'alert-warning':
                          refLongitude1.invalid &&
                          (refLongitude1.dirty ||
                            refLongitude1.touched ||
                            refLongitude1.untouched)
                      }"
                      [(ngModel)]="longitude"
                      name="Longitude1"
                      id="Longitude1"
                      class="form-control input-text-css"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <p class="m-0 row">
                      Upload Valuation Report:
                      <i
                        class="fa fa-upload ml-2"
                        *ngIf="!ValuationRemarkDoc"
                        (click)="docFile.click()"
                        aria-hidden="true"
                      ></i>
                      <ng-container *ngIf="ValuationRemarkDoc">
                        <div>
                          <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>
                          <ng-container *ngIf="ValuationDocData">
                            {{ ValuationRemarkDoc }}
                          </ng-container>
                          <a
                            href="{{ docBaseUrl }}{{ LoanAcNo }}/{{
                              ValuationRemarkDoc
                            }}"
                            class="ml-3"
                            target="_blank"
                            *ngIf="!ValuationDocData"
                          >
                            {{ ValuationRemarkDoc }}
                          </a>
                          <i
                            class="fa fa-times ml-2"
                            aria-hidden="true"
                            style="cursor: pointer"
                            (click)="removeFile(docFile)"
                          ></i>
                        </div>
                      </ng-container>
                    </p>
                    <input
                      #docFile
                      type="file"
                      [multiple]="false"
                      accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                      (change)="fileChangeListener(docFile.files)"
                      style="display: none"
                    />
                  </div>
                  <div class="col-md-6">
                    <p class="m-0 row">
                      Upload Search Report:<i
                        class="fa fa-upload ml-2"
                        *ngIf="!SearchReportRemarkDoc"
                        (click)="docFile1.click()"
                        aria-hidden="true"
                      ></i>
                      <ng-container *ngIf="SearchReportRemarkDoc">
                        <div>
                          <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>
                          <ng-container *ngIf="SearchDocData">
                            {{ SearchReportRemarkDoc }}
                          </ng-container>
                          <a
                            href="{{ docBaseUrl }}{{ LoanAcNo }}/{{
                              SearchReportRemarkDoc
                            }}"
                            class="ml-3"
                            target="_blank"
                            *ngIf="!SearchDocData"
                          >
                            {{ SearchReportRemarkDoc }}
                          </a>
                          <i
                            class="fa fa-times ml-2"
                            aria-hidden="true"
                            style="cursor: pointer"
                            (click)="removeFile1(docFile1)"
                          ></i>
                        </div>
                      </ng-container>
                    </p>
                    <input
                      #docFile1
                      type="file"
                      [multiple]="false"
                      accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                      (change)="fileChangeListener1(docFile1.files)"
                      style="display: none"
                    />
                  </div>
                </div>
                <hr />
                <div class="row mt-2">
                  <div class="col-md-12 mt-2 upload">
                    <B> Upload Property Images </B>
                    <input
                      #docFile5
                      type="file"
                      [multiple]="false"
                      accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                      (change)="
                        fileChangeListenerAttachment(docFile5.files, docFile5)
                      "
                      style="display: none"
                    />
                    <div
                      class="row m-0 mt-2 align-items-center"
                      *ngFor="let report of AssetsAttachment; let i = index"
                    >
                      <div class="col-md-1 pl-0">{{ i + 1 }}</div>
                      <div class="col-md-3">
                        <input
                          type="text"
                          placeholder="Title"
                          name="title{{ i }}"
                          id="title{{ i }}"
                          #refTitle="ngModel"
                          [(ngModel)]="report.Title"
                          class="form-control input-text-css"
                        />
                      </div>
                      <div class="col-md-3">
                        <p class="m-0 row">
                          <i
                            class="fa fa-upload ml-2"
                            *ngIf="!report.DocFileName"
                            (click)="docFile5.click()"
                            aria-hidden="true"
                          ></i>
                          <ng-container *ngIf="report.DocFileName">
                            <div>
                              <i
                                class="fa fa-file-pdf mr-2"
                                aria-hidden="true"
                              ></i
                              >{{ report.DocFileName }}
                              <i
                                class="fa fa-times ml-2"
                                aria-hidden="true"
                                style="cursor: pointer"
                                (click)="removeFileAttachment(i)"
                              ></i>
                            </div>
                          </ng-container>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="ConsumerGoldForms == 'ConsumerGoldForms'">
              <form #ff="ngForm" novalidate class="formborder">
                <div class="row mt-2">
                  <div class="col-md-2">
                    <span class="required-lable">Item Name</span>
                    <input
                      required
                      type="text"
                      #refItemName1="ngModel"
                      [ngClass]="{
                        'is-invalid': ff.submitted && refItemName1.invalid,
                        'alert-warning':
                          refItemName1.invalid &&
                          (refItemName1.dirty ||
                            refItemName1.touched ||
                            refItemName1.untouched)
                      }"
                      [(ngModel)]="Item.ItemName"
                      name="ItemName1"
                      id="ItemName1"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Gold Type</span>
                    <select
                      required
                      name="ItemType1"
                      id="ItemType1"
                      #refItemType1="ngModel"
                      [(ngModel)]="Item.ItemType"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Type</option>
                      <option value="Sold">Sold</option>
                      <option value="Un-Sold">Un-Sold</option>
                    </select>
                    <!--<input required type="text" #refItemType1="ngModel"
                            [ngClass]="{ 'is-invalid': ff.submitted && refItemType1.invalid, 'alert-warning': refItemType1.invalid  && (refItemType1.dirty || refItemType1.touched || refItemType1.untouched) }"
                            [(ngModel)]="Item.ItemType" name="ItemType1" id="ItemType1"
                            class="form-control input-text-css">-->
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Qty</span>
                    <input
                      required
                      type="number"
                      min="0"
                      numbersOnly
                      #refQty1="ngModel"
                      [ngClass]="{
                        'is-invalid': ff.submitted && refQty1.invalid,
                        'alert-warning':
                          refQty1.invalid &&
                          (refQty1.dirty ||
                            refQty1.touched ||
                            refQty1.untouched)
                      }"
                      [(ngModel)]="Item.Qty"
                      name="Qty1"
                      id="Qty1"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Purity CT</span>
                    <input
                      required
                      type="number"
                      #refPurityCT1="ngModel"
                      [ngClass]="{
                        'is-invalid': ff.submitted && refPurityCT1.invalid,
                        'alert-warning':
                          refPurityCT1.invalid &&
                          (refPurityCT1.dirty ||
                            refPurityCT1.touched ||
                            refPurityCT1.untouched)
                      }"
                      [(ngModel)]="Item.PurityCT"
                      name="PurityCT1"
                      id="PurityCT1"
                      class="form-control input-text-css"
                    />
                    <!-- <select required name="PurityCT1" id="PurityCT1" #refPurityCT1="ngModel" [(ngModel)]="Item.PurityCT" class="form-control input-text-css">
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                    </select> -->
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Gross Wt</span>
                    <input
                      type="text"
                      min="0.00"
                      #refGrossWt1="ngModel"
                      required
                      [ngClass]="{
                        'is-invalid': ff.submitted && refGrossWt1.invalid,
                        'alert-warning':
                          refGrossWt1.invalid &&
                          (refGrossWt1.dirty ||
                            refGrossWt1.touched ||
                            refGrossWt1.untouched)
                      }"
                      [(ngModel)]="Item.GrossWt"
                      name="GrossWt1"
                      id="GrossWt1"
                      class="form-control input-text-css"
                      onKeyUp="if (/[^\'0-9]/g.test(this.value)) this.value = this.value.replace(/[^\.'0-9]/g,'')"
                    />
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Stone Wt</span>
                    <input
                      required
                      type="text"
                      min="0.00"
                      #refStoneWt1="ngModel"
                      (change)="ChangeWT()"
                      [ngClass]="{
                        'is-invalid': ff.submitted && refStoneWt1.invalid,
                        'alert-warning':
                          refStoneWt1.invalid &&
                          (refStoneWt1.dirty ||
                            refStoneWt1.touched ||
                            refStoneWt1.untouched)
                      }"
                      [(ngModel)]="Item.StoneWt"
                      name="StoneWt1"
                      id="StoneWt1"
                      class="form-control input-text-css"
                      onKeyUp="if (/[^\'0-9]/g.test(this.value)) this.value = this.value.replace(/[^\.'0-9]/g,'')"
                    />
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Other Wt</span>
                    <input
                      required
                      type="text"
                      min="0.00"
                      #refOtherWt1="ngModel"
                      (change)="ChangeWT()"
                      [ngClass]="{
                        'is-invalid': ff.submitted && refOtherWt1.invalid,
                        'alert-warning':
                          refOtherWt1.invalid &&
                          (refOtherWt1.dirty ||
                            refOtherWt1.touched ||
                            refOtherWt1.untouched)
                      }"
                      [(ngModel)]="Item.OtherWt"
                      name="OtherWt1"
                      id="OtherWt1"
                      class="form-control input-text-css"
                      onKeyUp="if (/[^\'0-9]/g.test(this.value)) this.value = this.value.replace(/[^\.'0-9]/g,'')"
                    />
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Net Wt</span>
                    <input
                      required
                      type="number"
                      min="0.00"
                      #refNetWt1="ngModel"
                      disabled
                      [ngClass]="{
                        'is-invalid': ff.submitted && refNetWt1.invalid,
                        'alert-warning':
                          refNetWt1.invalid &&
                          (refNetWt1.dirty ||
                            refNetWt1.touched ||
                            refNetWt1.untouched)
                      }"
                      [(ngModel)]="Item.NetWt"
                      name="NetWt1"
                      id="NetWt1"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Pure Wt</span>
                    <input
                      required
                      type="text"
                      #refPureWt="ngModel"
                      disabled
                      [ngClass]="{
                        'is-invalid': f.submitted && refPureWt.invalid,
                        'alert-warning':
                          refPureWt.invalid &&
                          (refPureWt.dirty ||
                            refPureWt.touched ||
                            refPureWt.untouched)
                      }"
                      [(ngModel)]="Item.PureWt"
                      name="PureWt"
                      id="PureWt"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Rate</span>
                    <input
                      required
                      type="number"
                      min="0.00"
                      #refValulationRate_PerCT1="ngModel"
                      (change)="ChangeWT()"
                      [ngClass]="{
                        'is-invalid':
                          ff.submitted && refValulationRate_PerCT1.invalid,
                        'alert-warning':
                          refValulationRate_PerCT1.invalid &&
                          (refValulationRate_PerCT1.dirty ||
                            refValulationRate_PerCT1.touched ||
                            refValulationRate_PerCT1.untouched)
                      }"
                      [(ngModel)]="Item.ValulationRate_PerCT"
                      name="ValulationRate_PerCT1"
                      id="ValulationRate_PerCT1"
                      class="form-control input-text-css"
                      pattern="[0-9]+([\.,][0-9]+)?"
                    />
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Amount</span>
                    <input
                      required
                      type="number"
                      min="0.00"
                      #refValulationAmount1="ngModel"
                      disabled
                      [ngClass]="{
                        'is-invalid':
                          ff.submitted && refValulationAmount1.invalid,
                        'alert-warning':
                          refValulationAmount1.invalid &&
                          (refValulationAmount1.dirty ||
                            refValulationAmount1.touched ||
                            refValulationAmount1.untouched)
                      }"
                      [(ngModel)]="Item.ValulationAmount"
                      name="ValulationAmount1"
                      id="ValulationAmount1"
                      class="form-control input-text-css"
                      pattern="[0-9]+([\.,][0-9]+)?"
                    />
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-4">
                    <p class="m-0 row">
                      Upload Image 1:<i
                        class="fa fa-upload ml-2"
                        *ngIf="!Item.Image1"
                        (click)="imgFile.click()"
                        aria-hidden="true"
                      ></i>
                      <ng-container *ngIf="Item.Image1">
                        <div>
                          <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>
                          <ng-container *ngIf="Item.Image1Data">
                            {{ Item.Image1 }}
                          </ng-container>
                          <a
                            href="{{ applicationDocUrl }}{{
                              loanSummary.ApplicationNo
                            }}/{{ Item.Image2 }}"
                            class="ml-3"
                            target="_blank"
                            *ngIf="!Item.Image1Data"
                          >
                            {{ Item.Image1 }}
                          </a>
                          <i
                            class="fa fa-times ml-2"
                            aria-hidden="true"
                            style="cursor: pointer"
                            (click)="removeImage(imgFile)"
                          ></i>
                        </div>
                      </ng-container>
                    </p>
                    <input
                      #imgFile
                      type="file"
                      [multiple]="false"
                      accept="image/*"
                      (change)="imageChangeListener(imgFile.files)"
                      style="display: none"
                    />
                  </div>
                  <div class="col-md-4">
                    <p class="m-0 row">
                      Upload Image 2:
                      <i
                        class="fa fa-upload ml-2"
                        *ngIf="!Item.Image2"
                        (click)="imgFile1.click()"
                        aria-hidden="true"
                      ></i>
                      <ng-container *ngIf="Item.Image2">
                        <div>
                          <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>
                          <ng-container *ngIf="Item.Image2Data">
                            {{ Item.Image2 }}
                          </ng-container>
                          <a
                            href="{{ applicationDocUrl }}{{
                              loanSummary.ApplicationNo
                            }}/{{ Item.Image2 }}"
                            class="ml-3"
                            target="_blank"
                            *ngIf="!Item.Image2Data"
                          >
                            {{ Item.Image2 }}
                          </a>
                          <i
                            class="fa fa-times ml-2"
                            aria-hidden="true"
                            style="cursor: pointer"
                            (click)="removeImage1(imgFile1)"
                          ></i>
                        </div>
                      </ng-container>
                    </p>
                    <input
                      #imgFile1
                      type="file"
                      [multiple]="false"
                      accept="image/*"
                      (change)="imageChangeListener1(imgFile1.files)"
                      style="display: none"
                    />
                  </div>
                  <div class="col-md-4">
                    <button
                      type="button"
                      (click)="saveAssetItem()"
                      class="ml-4 btn font-size-12 button-btn"
                      mat-raised-button
                      [class.spinner]="loading"
                      [disabled]="!ff.form.valid"
                    >
                      Add Item
                    </button>
                  </div>
                </div>
              </form>
              <hr />
              <div class="table-responsive mt-4 mb-4">
                <mat-table [dataSource]="dataAssetItemSource">
                  <ng-container matColumnDef="Id">
                    <mat-header-cell
                      class="grid-header"
                      style="max-width: 50px"
                      *matHeaderCellDef
                      >#</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                      style="max-width: 50px; max-height: 5px"
                    >
                      {{ i + 1 }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="ItemName">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Item Name</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.ItemName }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="ItemType">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Item Type</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.ItemType }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Qty">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Qty</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.Qty }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="PurityCT">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Purity CT</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.PurityCT }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="GrossWt">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Gross Wt</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.GrossWt }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="StoneWt">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Stone Wt</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.StoneWt }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="OtherWt">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Other Wt</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.OtherWt }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="NetWt">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Net Wt</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.NetWt }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="PureWt">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Pure Wt</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{
                        (
                          (row.NetWt * (row.PurityCT * fixrate24ct)) /
                          100
                        ).toFixed(2)
                      }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="ValulationRate_PerCT">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Rate</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.ValulationRate_PerCT }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="ValulationAmount">
                    <mat-header-cell class="grid-header" *matHeaderCellDef
                      >Amount</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">
                      {{ row.ValulationAmount }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Action">
                    <mat-header-cell
                      class="grid-header j-c-center"
                      style="max-width: 60px"
                      *matHeaderCellDef
                      >Action</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell j-c-center"
                      style="max-width: 60px"
                    >
                      <!--<a href="javascript:void(0)" (click)="editAssetItem(row,i)"
                          style="margin-right: 10px;">Edit</a>
                      <a href="javascript:void(0)" (click)="deleteAssetItem(i)">Delete</a>-->
                      <i
                        class="fa fa-pencil-alt right"
                        (click)="editAssetItem(row, i)"
                        style="cursor: pointer; color: green; font-size: 16px"
                        aria-label="true"
                        *ngIf="PageAcess && PageAcess[0].Edit == 1"
                      ></i>
                      <i
                        class="fa fa-minus-circle font-size-15 ml-3"
                        (click)="deleteAssetItem(i)"
                        style="cursor: pointer"
                        aria-label="true"
                        *ngIf="PageAcess && PageAcess[0].Delete == 1"
                      ></i>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayedAssetItemColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedAssetItemColumns"
                  ></mat-row>
                </mat-table>
                <div class="row m-0 mt-4" *ngIf="assetTotal">
                  <div class="col-md-3">Total Qty:{{ assetTotal.Qty }}</div>
                  <div class="col-md-3">
                    Total Gross Wt:{{ assetTotal.GrossWt }}
                  </div>
                  <div class="col-md-3">
                    Total Stone Wt:{{ assetTotal.StoneWt }}
                  </div>
                  <div class="col-md-3">
                    Total Net Wt:{{ assetTotal.NetWt }}
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-3">
                  <span class="required-lable">Valution Done By</span>
                  <select
                    name="ValuationDoneBy"
                    id="ValuationDoneBy"
                    #refValuationDoneBy="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refValuationDoneBy.invalid,
                      'alert-warning':
                        refValuationDoneBy.invalid &&
                        (refValuationDoneBy.dirty ||
                          refValuationDoneBy.touched ||
                          refValuationDoneBy.untouched)
                    }"
                    [(ngModel)]="assetModel.ValulationDoneBy"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Valuation Done By</option>
                    <option
                      *ngFor="let collection of ValuationDoneByData"
                      [value]="collection.PartnerId"
                    >
                      {{ collection.Partner_Name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <span class="required-lable">Valution Date</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker18"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="ValulationDate "
                      id="ValulationDate "
                      #refValulationDate="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refValulationDate.invalid,
                        'alert-warning':
                          refValulationDate.invalid &&
                          (refValulationDate.dirty ||
                            refValulationDate.touched ||
                            refValulationDate.untouched)
                      }"
                      [(ngModel)]="assetModel.ValulationDate"
                      class="form-control input-text-css"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker18"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker18></mat-datepicker>
                  </div>
                  <!-- <input
                    name="ValulationDate "
                    id="ValulationDate "
                    #refValulationDate="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refValulationDate.invalid,
                      'alert-warning':
                        refValulationDate.invalid &&
                        (refValulationDate.dirty ||
                          refValulationDate.touched ||
                          refValulationDate.untouched)
                    }"
                    [(ngModel)]="assetModel.ValulationDate"
                    value="{{ ValulationDate | date : 'dd/MM/yyyy' }}"
                    class="form-control input-text-css"
                    [(bsValue)]="ValulationDate"
                    bsDatepicker
                    required
                  /> -->
                </div>
                <div class="col-md-2">
                  <span class="required-lable">Total Valution Amount</span>
                  <input
                    required
                    type="text"
                    #refTotalValulationAmount="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && refTotalValulationAmount.invalid,
                      'alert-warning':
                        refTotalValulationAmount.invalid &&
                        (refTotalValulationAmount.dirty ||
                          refTotalValulationAmount.touched ||
                          refTotalValulationAmount.untouched)
                    }"
                    [(ngModel)]="assetModel.TotalValulationAmount"
                    name="TotalValulationAmount"
                    id="TotalValulationAmount"
                    class="form-control input-text-css"
                  />
                  <!-- <input required type="text" #refTotalValulationAmount="ngModel" disabled
                          [ngClass]="{ 'is-invalid': f.submitted && refTotalValulationAmount.invalid, 'alert-warning': refTotalValulationAmount.invalid  && (refTotalValulationAmount.dirty || refTotalValulationAmount.touched || refTotalValulationAmount.untouched) }"
                          [(ngModel)]="assetModel.TotalValulationAmount" name="TotalValulationAmount" id="TotalValulationAmount"
                          class="form-control input-text-css"> -->
                </div>
                <div class="col-md-2">
                  <span class="required-lable">Valuer Phone No</span>
                  <input
                    required
                    type="text"
                    #refValuerPhoneNo="ngModel"
                    [(ngModel)]="assetModel.ValuerPhoneNo"
                    numbersOnly
                    maxlength="10"
                    minlength="10"
                    name="ValuerPhoneNo"
                    [ngClass]="{
                      'is-invalid': f.submitted && refValuerPhoneNo.invalid,
                      'alert-warning':
                        refValuerPhoneNo.invalid &&
                        (refValuerPhoneNo.dirty ||
                          refValuerPhoneNo.touched ||
                          refValuerPhoneNo.untouched)
                    }"
                    id="ValuerPhoneNo"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-3">
                  <span class="required-lable">Valuer Firm Name</span>
                  <input
                    required
                    type="text"
                    #refValuerFirmName="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refValuerFirmName.invalid,
                      'alert-warning':
                        refValuerFirmName.invalid &&
                        (refValuerFirmName.dirty ||
                          refValuerFirmName.touched ||
                          refValuerFirmName.untouched)
                    }"
                    [(ngModel)]="assetModel.ValuerFirmName"
                    name="ValuerFirmName"
                    id="ValuerFirmName"
                    class="form-control input-text-css"
                  />
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="PersonalForms == 'PersonalForms'">
              <hr />
            </ng-container>
          </form>
        </ng-container>
        <div class="row m-0">
          <div class="col-md-12 text-right">
            <button
              type="button"
              [disabled]="!f.form.valid"
              (click)="saveAssetInfo('Additional Collateral')"
              mat-raised-button
              class="mt-3 btn font-size-12 button-btn"
              *ngIf="saveBtn && PersonalForms != 'PersonalForms'"
            >
              Save
            </button>

            <button
              type="button"
              (click)="editAssetInfo()"
              mat-raised-button
              class="mt-3 btn font-size-12 button-btn"
              [class.spinner]="loading"
              *ngIf="
                PageAcess &&
                PageAcess[0].Edit == 1 &&
                isEdit &&
                !loanSummary.CloseDate
              "
            >
              Edit
            </button>

            <button
              type="button"
              [disabled]="!f.form.valid"
              (click)="saveAssetInfo('Additional Collateral')"
              mat-raised-button
              class="mt-3 btn font-size-12 button-btn"
              [class.spinner]="loading"
              *ngIf="updateBtn"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--end popup form  add additional collateral-->

<div
  class="modal fade in"
  id="duplicateData"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          {{ DuplicateType }} already exist .
        </h6>
        <button
          type="button"
          (click)="onCloseduplicateData()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 mt-3" *ngIf="duplicateData">
          <div class="table-responsive mt-3">
            <table class="table table-bordered">
              <thead>
                <tr
                  style="
                    color: #fff !important;
                    background-color: #4dc3a3 !important;
                  "
                >
                  <th scope="col">#</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Application Number</th>
                  <th scope="col">Loan Number</th>
                  <th scope="col">Loan Date</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Branch Name</th>
                  <th scope="col">Registation No</th>
                  <th scope="col">Chassis No</th>
                  <th scope="col">Engine No</th>
                  <th scope="col">Loan Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of duplicateData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ data.Customer_Name }}</td>
                  <td>{{ data.Application_No }}</td>
                  <td>{{ data.LoanAcNo }}</td>
                  <td>{{ data.Loan_Date }}</td>
                  <td>{{ data.Product_Name }}</td>
                  <td>{{ data.Branch_Name }}</td>
                  <td>{{ data.VehicleRegistationNo }}</td>
                  <td>{{ data.ChassisNo }}</td>
                  <td>{{ data.EngineNo }}</td>
                  <td>{{ data.LoanStatus }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--<div class="row col-md-12 m-0 mt-3 justify-content-center">
            <button type="button" (click)="onNoClick()" class="btn btn-light font-size-12">
              No
            </button>
            <button type="button" (click)="onYesClick()" class="ml-4"
                    style="background-color: #28a745;color: #FFFFFF;font-size: 12px;" mat-raised-button color="primary">
              yes
            </button>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="View"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          {{ Collateral_Type }} [{{ Collateral }}]
        </h6>
        <button
          type="button"
          (click)="onCloseView()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="Collateral == 'Vehicle'">
          <div class="row m-0 mt-3" *ngIf="Info">
            <div class="col-md-12">
              <h1 class="fs-12 h-b">{{ Info.Vehicle_SubType }}</h1>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Vehicle Manufacture</h6>
              <p class="lead fs-12">{{ Info.VehicleManufacture }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Vehicle Category</h6>
              <p class="lead fs-12">{{ Info.VehicleCategory }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Vehicle Model Name</h6>
              <p class="lead fs-12">{{ Info.VehicleModelName }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Variant</h6>
              <p class="lead fs-12">{{ Info.Variant }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Manufacture Year</h6>
              <p class="lead fs-12">{{ Info.MefgYear }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Vehicle Registation No</h6>
              <p class="lead fs-12">
                {{ Info.VehicleRegistationNo }}
                <i
                  *ngIf="
                    Info.VehicleRegistationNo != '' &&
                    (RCKYC_IsVerified == 1 || RCLastVerfiedDate)
                  "
                  class="fa fa-check ml-2"
                  style="color: green"
                ></i>
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Registation Date</h6>
              <p class="lead fs-12">{{ Info.RegistationDate }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Registation Expiry Date</h6>
              <p class="lead fs-12">{{ Info.RegistationExpiryDate }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Fuel Type</h6>
              <p class="lead fs-12">{{ Info.FuelType }}</p>
            </div>
            <div class="col-md-2" *ngIf="Info.Vehicle_SubType == 'Commercial'">
              <h6 class="fs-12">Road Tax Upto</h6>
              <p class="lead fs-12">{{ Info.RoadTaxUpto }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Color</h6>
              <p class="lead fs-12">{{ Info.Color }}</p>
            </div>
            <div class="col-md-2" *ngIf="Info.Vehicle_SubType == 'Commercial'">
              <h6 class="fs-12">Fitness Upto</h6>
              <p class="lead fs-12">{{ Info.FitnessUpto }}</p>
            </div>
            <div class="col-md-2" *ngIf="Info.Vehicle_SubType == 'Commercial'">
              <h6 class="fs-12">Permit Upto</h6>
              <p class="lead fs-12">{{ Info.PermitUpto }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Vehicle Cost</h6>
              <p class="lead fs-12">{{ Info.VehicleCost }}</p>
            </div>
            <div class="col-md-2" *ngIf="Info.Vehicle_SubType == 'Commercial'">
              <h6 class="fs-12">Route</h6>
              <p class="lead fs-12">{{ Info.Rout }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Engine No</h6>
              <p class="lead fs-12">{{ Info.EngineNo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Chassis No</h6>
              <p class="lead fs-12">{{ Info.ChassisNo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Key No</h6>
              <p class="lead fs-12">{{ Info.KeyNo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">RC HPNEndorsment</h6>
              <p class="lead fs-12">
                {{ Info.RC_HPNEndorsment == true ? "YES" : "NO" }}
              </p>
            </div>
            <div class="col-md-2" *ngIf="Info.Vehicle_Type == 'New'">
              <h6 class="fs-12">Invoice HPNEndorsment</h6>
              <p class="lead fs-12">
                {{ Info.Invoice_HPNEndorsment == true ? "YES" : "NO" }}
              </p>
            </div>
          </div>
          <div class="row m-0 mt-3" *ngIf="Info.Vehicle_Type == 'Used'">
            <div class="col-md-12">
              <h1 class="fs-12 h-b">{{ Info.Vehicle_Type }} Vehicle</h1>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuation Done By</h6>
              <p class="lead fs-12">{{ Info.ValuationDoneBy }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuation Date</h6>
              <p class="lead fs-12">{{ Info.ValuationDate }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuation Amount</h6>
              <p class="lead fs-12">{{ Info.ValuationAmount }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuation Type</h6>
              <p class="lead fs-12">{{ Info.ValuationType }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Other Valuation Type</h6>
              <p class="lead fs-12">{{ Info.OtherValuationType }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Other Valuation Amount</h6>
              <p class="lead fs-12">{{ Info.OtherValuationAmt }}</p>
            </div>
          </div>

          <div class="row m-0 mt-3" *ngIf="Info.Vehicle_Type == 'New'">
            <div class="col-md-12">
              <h1 class="fs-12 h-b">{{ Info.Vehicle_Type }} Vehicle</h1>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Dealer</h6>
              <p class="lead fs-12">{{ Info.DealerName }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Dealer Contact Person</h6>
              <p class="lead fs-12">{{ Info.DealerContactPerson }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Dealer Contact No.</h6>
              <p class="lead fs-12">{{ Info.DealerContactNo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">DO. No.</h6>
              <p class="lead fs-12">{{ Info.DONo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">DO. Do Date</h6>
              <p class="lead fs-12">{{ Info.DODoDate }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Estimation Amount</h6>
              <p class="lead fs-12">{{ Info.EstimationAmount }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Invoice No.</h6>
              <p class="lead fs-12">{{ Info.InvoiceNo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Invoice Date</h6>
              <p class="lead fs-12">{{ Info.InvoiceDate }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Invoice Value</h6>
              <p class="lead fs-12">{{ Info.InvoiceValue }}</p>
            </div>

            <div class="col-md-2">
              <h6 class="fs-12">Quotation in favour of</h6>
              <p class="lead fs-12">{{ Info.DOIssuedinfavourof }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Remark</h6>
              <p class="lead fs-12">{{ Info.Remark }}</p>
            </div>
          </div>
          <div class="row mt-2">
            <div
              class="col-md-12 mt-2 upload"
              *ngIf="AssetsAttachment.length > 0"
            >
              <b> Uploaded Vehicle Images </b>

              <div
                class="row m-0 mt-2 align-items-center"
                *ngFor="let report of AssetsAttachment; let i = index"
              >
                <div class="col-md-1 pl-0">
                  {{ i + 1 }}
                </div>
                <div class="col-md-3">
                  <p class="lead fs-12">{{ report.Title }}</p>
                </div>
                <div class="col-md-3">
                  <p class="m-0 row">
                    <a
                      href="{{ applicationDocUrl }}{{
                        loanSummary.ApplicationNo
                      }}/{{ report.DocFileName }}"
                      target="_blank"
                      *ngIf="report.DocFileName"
                      >{{ report.DocFileName }}</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right mb-2" style="margin-right: 50px">
            <button
              type="button"
              (click)="editAssetInfo(Info.AssetId)"
              *ngIf="
                PageAcess && PageAcess[0].Edit == 1 && !loanSummary.CloseDate
              "
              class="mt-3 btn font-size-12 button-btn"
              [class.spinner]="loading"
            >
              Edit
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="Collateral == 'Gold'">
          <div class="table-responsive mt-4 mb-4">
            <mat-table [dataSource]="dataviewAssetItemSource">
              <ng-container matColumnDef="Id">
                <mat-header-cell
                  class="grid-header"
                  style="max-width: 50px"
                  *matHeaderCellDef
                  >#</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell"
                  style="max-width: 50px; max-height: 5px"
                >
                  {{ i + 1 }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="ItemName">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Item Name</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.ItemName }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="ItemType">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Item Type</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.ItemType }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Qty">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Qty</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Qty }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="PurityCT">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Purity CT</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.PurityCT }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="GrossWt">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Gross Wt</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.GrossWt }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="StoneWt">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Stone Wt</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.StoneWt }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="OtherWt">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Other Wt</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.OtherWt }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="NetWt">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Net Wt</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.NetWt }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="PureWt">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Pure Wt</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{
                    ((row.NetWt * (row.PurityCT * fixrate24ct)) / 100).toFixed(
                      2
                    )
                  }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="ValulationRate_PerCT">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Rate</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.ValulationRate_PerCT }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="ValulationAmount">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Amount</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.ValulationAmount }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Image">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >View</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  <a
                    href="{{ applicationDocUrl }}{{
                      loanSummary.ApplicationNo
                    }}/{{ row.Image1 }}"
                    target="_blank"
                    *ngIf="row.Image1"
                  >
                    <i
                      *ngIf="row.Image1"
                      class="fa fa-eye"
                      style="font-size: large"
                    ></i>
                  </a>
                  <a
                    href="{{ applicationDocUrl }}{{
                      loanSummary.ApplicationNo
                    }}/{{ row.Image2 }}"
                    class="ml-2"
                    target="_blank"
                    *ngIf="row.Image2"
                  >
                    <i
                      *ngIf="row.Image2"
                      class="fa fa-eye"
                      style="font-size: large"
                    ></i>
                  </a>
                </mat-cell>
              </ng-container>
              <mat-header-row
                *matHeaderRowDef="displayedViewAssetItemColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedViewAssetItemColumns"
              ></mat-row>
            </mat-table>
          </div>

          <div class="row m-0 mt-3" *ngIf="Info">
            <div class="col-md-3">
              <h6 class="fs-12">Valution Done By</h6>
              <p class="lead fs-12">{{ Info.ValulationDoneBy }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valution Date</h6>
              <p class="lead fs-12">{{ Info.ValulationDate }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Total Valution Amount</h6>
              <p class="lead fs-12">{{ Info.TotalValulationAmount }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuer PhoneNo</h6>
              <p class="lead fs-12">{{ Info.ValuerPhoneNo }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Valuer FirmName</h6>
              <p class="lead fs-12">{{ Info.ValuerFirmName }}</p>
            </div>
          </div>
          <div class="text-right mb-2" style="margin-right: 50px">
            <button
              type="button"
              (click)="editAssetInfo(Info.Id)"
              *ngIf="
                PageAcess && PageAcess[0].Edit == 1 && !loanSummary.CloseDate
              "
              [class.spinner]="loading"
              classmt-3
              btn
              font-size-12
              button-btn
            >
              Edit
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="Collateral == 'Property'">
          <div class="row m-0 mt-3" *ngIf="Info">
            <div class="col-md-2">
              <h6 class="fs-12">Property Owner Name</h6>
              <p class="lead fs-12">{{ Info.CustomerId }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Address Of Property</h6>
              <p class="lead fs-12">{{ Info.Address }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">State</h6>
              <p class="lead fs-12">{{ Info.State }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">District</h6>
              <p class="lead fs-12">{{ Info.District }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Tehsil</h6>
              <p class="lead fs-12">{{ Info.tasil }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Pincode</h6>
              <p class="lead fs-12">{{ Info.PinCode }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Type of Property</h6>
              <p class="lead fs-12">{{ Info.TypeofProperty }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Nature of Property</h6>
              <p class="lead fs-12">{{ Info.NatureofProperty }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Ownership Type</h6>
              <p class="lead fs-12">{{ Info.OwnershipType }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Ownership Document</h6>
              <p class="lead fs-12">{{ Info.OwnershipDocument }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">UnitofMeasurement</h6>
              <p class="lead fs-12">{{ Info.UnitofMeasurement }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Total Area</h6>
              <p class="lead fs-12">{{ Info.TotalArea }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Constructed Area</h6>
              <p class="lead fs-12">{{ Info.ConstructedArea }}</p>
            </div>

            <div class="col-md-2">
              <h6 class="fs-12">Type of Mortgage</h6>
              <p class="lead fs-12">{{ Info.TypeofMortgage }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Mortgage Signed By</h6>
              <p class="lead fs-12">{{ Info.MortgageSingedBy }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuation Amount</h6>
              <p class="lead fs-12">{{ Info.ValuationAmount }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuation Done By</h6>
              <p class="lead fs-12">{{ Info.ValuationDoneBy }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuation Remark</h6>
              <p class="lead fs-12">{{ Info.ValuationRemark }}</p>
            </div>
            <div
              class="col-md-2"
              *ngIf="
                Info.ValuationDoneByID != '' && Info.ValuationDoneByID == 0
              "
            >
              <h6 class="fs-12">Valution Done By Name</h6>
              <p class="lead fs-12">{{ Info.ValuationDoneByOther }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Search Report Done By</h6>
              <p class="lead fs-12">{{ Info.SearchReportDoneBy }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Search Report Remark</h6>
              <p class="lead fs-12">{{ Info.SearchReportRemark }}</p>
            </div>
            <div
              class="col-md-2"
              *ngIf="
                Info.SearchReportDoneByID != '' &&
                Info.SearchReportDoneByID == 0
              "
            >
              <h6 class="fs-12">Search Report Done By Name</h6>
              <p class="lead fs-12">{{ Info.SearchReportDoneByOther }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">GeoLocation</h6>
              <p class="lead fs-12">
                <a
                  target="_blank"
                  *ngIf="
                    Info.GeoLocation != '' &&
                    Info.GeoLocation != null &&
                    Info.GeoLocation != ','
                  "
                  [href]="'http://maps.google.com/maps?q=' + Info.GeoLocation"
                >
                  <i class="fa-solid fa-location-dot"></i>
                </a>
              </p>
            </div>

            <div class="col-md-3">
              <h6 class="fs-12">Valuation Report Doc:</h6>
              <p class="lead fs-12">
                <a
                  href="{{ applicationDocUrl }}{{
                    loanSummary.ApplicationNo
                  }}/{{ Info.ValuationRemarkDoc }}"
                  target="_blank"
                  >{{ Info.ValuationRemarkDoc }}</a
                >
              </p>
            </div>

            <div class="col-md-2">
              <h6 class="fs-12">Search Report Doc:</h6>
              <p class="lead fs-12">
                <a
                  href="{{ applicationDocUrl }}{{
                    loanSummary.ApplicationNo
                  }}/{{ Info.SearchReportRemarkDoc }}"
                  target="_blank"
                  >{{ Info.SearchReportRemarkDoc }}</a
                >
              </p>
            </div>
          </div>
          <div class="row mt-2">
            <div
              class="col-md-12 mt-2 upload"
              *ngIf="AssetsAttachment.length > 0"
            >
              <b> Uploaded Property Images </b>

              <div
                class="row m-0 mt-2 align-items-center"
                *ngFor="let report of AssetsAttachment; let i = index"
              >
                <div class="col-md-1 pl-0">
                  {{ i + 1 }}
                </div>
                <div class="col-md-3">
                  <p class="lead fs-12">{{ report.Title }}</p>
                </div>
                <div class="col-md-3">
                  <p class="m-0 row">
                    <a
                      href="{{ applicationDocUrl }}{{
                        loanSummary.ApplicationNo
                      }}/{{ report.DocFileName }}"
                      target="_blank"
                      *ngIf="report.DocFileName"
                      >{{ report.DocFileName }}</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right mb-2" style="margin-right: 50px">
            <button
              type="button"
              (click)="editAssetInfo(Info.Id)"
              *ngIf="
                PageAcess && PageAcess[0].Edit == 1 && !loanSummary.CloseDate
              "
              [class.spinner]="loading"
              class="mt-3 btn font-size-12 button-btn"
            >
              Edit
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="Collateral == 'Consumer_Durable'">
          <div class="row m-0 mt-3" *ngIf="Info">
            <div class="col-md-2">
              <h6 class="fs-12">Category Name</h6>
              <p class="lead fs-12">{{ Info.Category_Name }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Manufacture Name</h6>
              <p class="lead fs-12">{{ Info.Manufacture_Name }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Product_Name</h6>
              <p class="lead fs-12">{{ Info.Product_Name }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Model Name</h6>
              <p class="lead fs-12">{{ Info.Model_Name }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Varient</h6>
              <p class="lead fs-12">{{ Info.Varient }}</p>
            </div>

            <div class="col-md-2">
              <h6 class="fs-12">Identification No1</h6>
              <p class="lead fs-12">{{ Info.Identification_No1 }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Identification No2</h6>
              <p class="lead fs-12">{{ Info.Identification_No2 }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Cost</h6>
              <p class="lead fs-12">{{ Info.Cost }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Color</h6>
              <p class="lead fs-12">{{ Info.Color }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Invoice_No</h6>
              <p class="lead fs-12">{{ Info.Invoice_No }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Invoice Date</h6>
              <p class="lead fs-12">{{ Info.Invoice_Date }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Dealer Name</h6>
              <p class="lead fs-12">{{ Info.Dealer_Name }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Dealer Contact Person</h6>
              <p class="lead fs-12">{{ Info.DealerContactPerson }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Dealer Contact No</h6>
              <p class="lead fs-12">{{ Info.DealerContactNo }}</p>
            </div>
          </div>
          <div class="row mt-2">
            <div
              class="col-md-12 mt-2 upload"
              *ngIf="AssetsAttachment.length > 0"
            >
              <b> Uploaded Consumer Durable Images </b>

              <div
                class="row m-0 mt-2 align-items-center"
                *ngFor="let report of AssetsAttachment; let i = index"
              >
                <div class="col-md-1 pl-0">
                  {{ i + 1 }}
                </div>
                <div class="col-md-3">
                  <p class="lead fs-12">{{ report.Title }}</p>
                </div>
                <div class="col-md-3">
                  <p class="m-0 row">
                    <a
                      href="{{ applicationDocUrl }}{{
                        loanSummary.ApplicationNo
                      }}/{{ report.DocFileName }}"
                      target="_blank"
                      *ngIf="report.DocFileName"
                      >{{ report.DocFileName }}</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right mb-2" style="margin-right: 50px">
            <button
              type="button"
              (click)="editAssetInfo(Info.Id)"
              *ngIf="
                PageAcess && PageAcess[0].Edit == 1 && !loanSummary.CloseDate
              "
              [class.spinner]="loading"
              class="mt-3 btn font-size-12 button-btn"
            >
              Edit
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="viewVehicleGridModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Vehicle Grid Model
        </h6>
        <button
          type="button"
          (click)="onCloseView()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div
          style="display: flex; align-items: center; gap: 40px"
          class="formborder"
        >
          <div class="col-md-2">
            <span class="required-lable">Year</span>
            <input
              name="getStartYear"
              [(ngModel)]="ManufactureYearModal"
              id="getStartYear"
              placeholder="Start Year"
              class="form-control input-text-css"
              bsDatepicker
              [bsConfig]="bsConfig"
              (ngModelChange)="onYearChangeVehicleGridCallAPi($event)"
              required
              #refgetStartYear="ngModel"
            />
          </div>

          <div class="col-md-4 mr_top pr-0">
            <div>
              <span class="required-lable">Model Name</span>
            </div>

            <input
              [(ngModel)]="Vehicle_Model_Name"
              type="search"
              class="form-control input-text-css"
              id="modelName"
              name="modelName"
              list="dynmicChargeHead"
              autocomplete="off"
              required
              placeholder="Select Model Name"
              (ngModelChange)="onModelIdChangeVehicleGrid($event)"
            />
            <datalist id="dynmicChargeHead">
              <option
                *ngFor="let item of modelNameDropdown"
                [value]="item.Vehicle_Model_Name"
                title="item.Vehicle_Model_Name"
              ></option>
            </datalist>
          </div>
        </div>

        <div>
          <div class="table-responsive mt-3" [hidden]="!dataSourceViewDetails">
            <table
              mat-table
              [dataSource]="dataSourceViewDetails"
              class="mat-elevation-z8"
              style="overflow: auto"
            >
              <ng-container matColumnDef="modelId">
                <th mat-header-cell *matHeaderCellDef>Model ID</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{ i + 1 }}
                </td>
              </ng-container>

              <ng-container matColumnDef="modelName">
                <th mat-header-cell *matHeaderCellDef>Model Name</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.modelName }}
                </td>
              </ng-container>

              <ng-container
                *ngFor="let column of displayedColumnsVehicleGridModel.slice(2)"
                [matColumnDef]="column"
              >
                <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
                <td mat-cell *matCellDef="let element" class="text-left">
                  {{ element[column] || "-" }}
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumnsVehicleGridModel"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsVehicleGridModel"
              ></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
