import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { Router } from "@angular/router";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
declare var $: any;

@Component({
  selector: "app-mstemployeelist",
  templateUrl: "./mstemployeelist.component.html",
  styleUrls: ["./mstemployeelist.component.scss"],
})
export class MstemployeelistComponent implements OnInit {
  private data: any;
  private _MasterService;
  showSpinner: boolean = false;
  RequestModel: RequestModel = new RequestModel();
  GetBranchesRequestModel: RequestModel = new RequestModel();
  DeleteEmployeeRequestModel: RequestModel = new RequestModel();

  dataSource: any;
  dataSourceError: any;
  MSG: any;
  displayedColumnsError: string[] = [];
  displayedColumns: string[] = [
    "EmpId",
    "Emp_Name",
    "Emp_Role",
    "Level",
    "Emp_LoginId",
    "Emp_GenderAge",
    "Emp_PhoneNo",
    "Emp_RManger",
    "Emp_RegOn",
    "Base_BranchName",
    "EditAction",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  FilterRolesId: any = "";
  FilterBranchId: any = "";
  FilterBranchList: any = [];
  EmployeeList: any = [];
  RolesDropdownData: any = [];
  dialogreturn: any;

  constructor(
    private _EncrDecrService: EncrDecrService,
    private _Route: Router,
    private dialog: DialogService,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
    sessionStorage.removeItem("getEmployeeID");
  }
  status: any = false;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Employee List");

    this._MasterService.GetRoleForDropdown().subscribe((result) => {
      this.RolesDropdownData = JSON.parse(JSON.stringify(result));
    });

    this.GetBranchesRequestModel.DistrictId = 0;
    this.GetBranchesRequestModel.TehsilId = 0;
    this._MasterService
      .GetBranchesDropdown(this.GetBranchesRequestModel)
      .subscribe((result) => {
        this.FilterBranchList = JSON.parse(JSON.stringify(result));
      });
    this.getEmployeeList();
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  changeFilter() {
    let _filterData = [];
    if (this.status == false) {
      _filterData = this.EmployeeList.filter((obj: any) => {
        return obj.Emp_IsActive;
      });
    } else {
      _filterData = this.EmployeeList.filter((obj: any) => {
        return !obj.Emp_IsActive;
      });
    }
    this.dataSource = new MatTableDataSource(_filterData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.showSpinner = false;
  }
  getEmployeeList() {
    this.showSpinner = true;
    this.RequestModel.BranchId =
      this.FilterBranchId == "" ? 0 : parseInt(this.FilterBranchId);
    this.RequestModel.RoleId =
      this.FilterRolesId == "" ? 0 : parseInt(this.FilterRolesId);
    this._MasterService
      .GetEmployeeList(this.RequestModel)
      .subscribe((result) => {
        this.EmployeeList = JSON.parse(JSON.stringify(result));
        this.changeFilter();
      });
  }

  onRegisterEmployee(selectedEmpID) {
    sessionStorage.setItem("getEmployeeID", selectedEmpID);

    if (selectedEmpID == "0") {
      this._Route.navigate([`employee/employee/`]);
    } else {
      this._Route.navigate([
        `employee/employee/${this._EncrDecrService.encrypt(
          encodeURIComponent(selectedEmpID)
        )}`,
      ]);
    }
  }

  onDeleteRegisterEmployee(row) {
    var msg = row.Emp_IsActive == false ? "activate" : "deactivate";
    this.dialogreturn = this.dialog.openConfirmDialog(
      "Do you want to " + msg + " selected employee ? " + row.Emp_Name
    );

    this.dialogreturn.afterClosed().subscribe((dialogResult) => {
      if (dialogResult == false) {
        return;
      } else {
        this.DeleteEmployeeRequestModel.EmpId = row.EmpId;
        this.DeleteEmployeeRequestModel.LoginEmpId = parseInt(this.data.userId);
        this.DeleteEmployeeRequestModel.Emp_IsActive =
          row.Emp_IsActive == false ? true : false;
        this._MasterService
          .DeleteEmployee(this.DeleteEmployeeRequestModel)
          .subscribe((response) => {
            if (response.length > 0) {
              if (response[0].CODE == 0 || response[0].CODE == "0") {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: response[0].MSG,
                  ...this.configSuccess,
                });
                this.getEmployeeList();
              } else {
                if (response[0].Type == "Employee") {
                  this.displayedColumnsError = [
                    "Type",
                    "subType",
                    "EmpName",
                    "LoanAcNo",
                  ];
                }
                if (response[0].Type == "Branch") {
                  this.displayedColumnsError = [
                    "Type",
                    "subType",
                    "BranchName",
                    "LoanAcNo",
                  ];
                }
                if (response[0].Type == "Loan-Application") {
                  this.displayedColumnsError = [
                    "Type",
                    "subType",
                    "Application_No",
                    "LoanAcNo",
                  ];
                }
                this.dataSourceError = new MatTableDataSource(
                  JSON.parse(JSON.stringify(response))
                );
                this.dataSourceError.sort = this.sort;
                this.MSG = response[0].MSG;
                $("#EmployeeDetails").modal("show");
                $("#EmployeeDetails").css("z-index", "1050");
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: response[0].MSG,
                  ...this.configSuccess,
                });

                this.getEmployeeList();
              }
            }
          });
      }
    });
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: "EmployeeList_" + new Date().toISOString(),
      sheet: "EmployeeList_" + this.paginator.pageIndex,
    });
  }
  onCloseEmployeeDetails() {
    $("#EmployeeDetails").modal("hide");
  }
  exportTable1(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `Employee-Delete-Error`,
      sheet: "Employee-Delete-Error",
    });
  }
}
