import { Component, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ParamMap, Router, ActivatedRoute } from "@angular/router";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
import { DialogService } from "src/app/Shared/dialog.service";
import { LmsService } from "../services/lms.service";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { constantUrl } from "src/app/Shared/constantUrl";
import { LosService } from "../../_LOS/services/los.service";
declare var $: any;

@Component({
  selector: "app-lms-loan-WriteOff_Settled-details",
  templateUrl: "./lms-loan-WriteOff_Settled-details.component.html",
  styleUrls: ["./lms-loan-WriteOff_Settled-details.component.scss"],
})
export class LmsLoanWriteOffSettledDetailsComponent implements OnInit {
  data: any;
  LoanId: any;
  showSpinner: boolean = false;

  LoanAllDetail: any = [];
  LoanCustomerList: any = [];
  dataSource: any;
  displayedColumns: string[] = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  minDate: any;
  loanSummary: any = {};
  accountName_Not_Bind: any = "";
  isMaker: string = "";
  PreCloserDetails: any = { LoanCloser_EffectiveDate: new Date() };
  PreCloserMethodList: any = [];
  PrintCloserMethodList: any = [];
  totalCalculator: any = {
    totalDueAmount: 0,
    totalReceiveAmount: 0,
    totalWaiverAmount: 0,
    totalBalance: 0,
  };
  printtotalCalculator: any = {
    totalDueAmount: 0,
    totalReceiveAmount: 0,
    totalWaiverAmount: 0,
    totalBalance: 0,
  };
  RequestDocName: any = "";
  RequestDocBase64: any = "";
  LoanChargesRecoverableList: any;
  LoanChargesRecoverable_Balance: any = 0;
  LoanChargesRecoverable: any = { Due_Amount: 0, Received_Amount: 0 };
  isShowForward: boolean = false;
  wavieruserList: any[] = [];
  today: Date = new Date();
  time: any = new Date().toString().split(" ")[4];
  MinEffectiveDate = new Date();
  _prevDate: any;

  loandocBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  AllowExtension: any = constantUrl.AllowExtension;
  Waiver_Account_Id: any = 0;
  closerId: any;
  savebutton: boolean = true;
  isExpired: boolean = false;
  isMyCloserRequest: boolean = false;
  isWavierGivenByMe: boolean = false;
  isUndoRemark: boolean = false;
  undoRemark: any = "";
  loanStatement: any;
  CloserTypeValue: any;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  CustomerName: any = "";
  CustomerAddress: any = "";
  BasePath: any = constantUrl.apiProfilePicUrl;
  CompanyLogo: any;

  StatusList: any;
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  employeeDropdown: any = [];
  accountTagDropdown: any[] = [];
  LoanTenureFreq: any = "Months";
  constructor(
    private losService: LosService,
    private dataSharingService: DataSharingService,
    private _EncrDecrService: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: DialogService,
    private route: ActivatedRoute,
    private lmsService: LmsService,
    private location: Location
  ) {
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.LoanId = this._EncrDecrService.decrypt(
        decodeURIComponent(param.get("loanId"))
      );
      this.closerId = this._EncrDecrService.decrypt(
        decodeURIComponent(param.get("closerId"))
      );
      this.isMaker = param.get("isMaker");

      this.getLoanRescheduleDetail();
    });
    sessionStorage.removeItem("LoanSummary");
  }

  ngOnInit() {
    this.showSpinner = false;
    this.dataSharingService.HeaderTitle.next("Loan Written_off Details");
    this.displayedColumns = [
      "Int_Id",
      "CustomerId",
      "Customer",
      "CustomerType",
      "GenderAge",
      "PhoneNo",
      "Relation_With_Hirer",
      "Present_Address",
      "ExistingCustomer",
    ];

    this.CompanyLogo = this.data.CompanyLogo;
    this.getStatusList();
  }
  getStatusList() {
    this.lmsService
      .LMS_Commaon_Master({ Commands: "Select", Type: "WO AND SETTLED STATUS" })
      .subscribe((res: any) => {
        this.StatusList = res;
      });
  }
  onEffectiveDateChange() {
    if (!this._prevDate || !this.PreCloserDetails.LoanCloser_EffectiveDate) {
      return;
    }
    if (
      this._prevDate.toLocaleDateString("fr-CA") !=
      new Date(
        this.PreCloserDetails.LoanCloser_EffectiveDate
      ).toLocaleDateString("fr-CA")
    ) {
      this.LMS_Get_Loan_Closer_Due_Detail(
        new Date(
          this.PreCloserDetails.LoanCloser_EffectiveDate
        ).toLocaleDateString("fr-CA")
      );
    }
  }

  LMS_Get_Loan_Closer_Due_Detail(date: any) {
    this.showSpinner = true;
    this.lmsService
      .LMS_Get_Loan_WriteOff_Settled_Detail({
        Loan_Id: JSON.parse(this.LoanId),
        LoginUserId: this.data.userId,
        EffictiveDate: date,
        LoanCloserId: JSON.parse(this.closerId),
      })
      .subscribe((res: any) => {
        if (res != null) {
          let _data = res.Item1[0] || {};
          this.Waiver_Account_Id =
            _data.Waiver_Account_Id == null ? 0 : _data.Waiver_Account_Id;
          _data.LoanCloser_EffectiveDate = new Date(
            _data.LoanCloser_EffectiveDate
          );
          _data.NextDueDate = new Date(_data.NextDueDate);
          this._prevDate = _data.LoanCloser_EffectiveDate;
          _data.CloserType = _data.CloserType || "";
          this.PreCloserDetails = _data;
          this.CloserTypeValue = _data.CloserType;
          this.PreCloserDetails.CloserType =
            _data.CloserType != ""
              ? this.StatusList.find((obj: any) => {
                  return obj.Value == this.CloserTypeValue;
                }).Id
              : "";
          this.PreCloserMethodList = res.Item2 ? res.Item2 : [];
          this.PrintCloserMethodList = res.Item2 ? res.Item2 : [];
          var headName = "";
          this.PreCloserMethodList.map((item) => {
            item.newBalance =
              item.Balance < 0 ? item.Balance * -1 : item.Balance;
            if (
              (item.AccountHeadId == null ||
                item.AccountHeadId == "" ||
                item.AccountHeadId == 0) &&
              item.ChagesHead != null
            ) {
              headName = item.ChagesHead;
              if (item.HeadId == 5) {
                headName = "Broken Period Interest";
              }
              this.accountName_Not_Bind =
                this.accountName_Not_Bind + headName + ",";
            }
          });
          this.PreCloserDetails.Waiver_GivenBy =
            this.PreCloserDetails.Waiver_GivenBy;
          if (
            this.PreCloserDetails.Closer_RequestStatus != "Closed" &&
            new Date().toLocaleDateString("fr-CA") !=
              this.PreCloserDetails.LoanCloser_EffectiveDate.toLocaleDateString(
                "fr-CA"
              )
          ) {
            this.isExpired =
              this.PreCloserDetails.LoanCloser_EffectiveDate.getTime() <
              this.MinEffectiveDate.getTime();
          }
          this.isMyCloserRequest =
            (this.PreCloserDetails.LoanCloser_RequestBy || "").toString() ==
            this.data.userId.toString();
          this.isWavierGivenByMe =
            (this.PreCloserDetails.Waiver_GivenBy || "").toString() ==
            this.data.userId.toString();
          this.onCalculateAmount();
          this.onPrintCalculateAmount();

          this.lmsService
            .ACC_Get_ChargesHead_Amount_Details({
              LoanId: JSON.parse(this.LoanId),
              ChargeHeadId: 35,
            })
            .subscribe((result: any) => {
              if (result.Item1.length > 0) {
                this.LoanChargesRecoverableList = result.Item1;
                this.LoanChargesRecoverable = result.Item2[0];
                this.LoanChargesRecoverable_Balance =
                  result.Item2[0].Due_Amount - result.Item2[0].Received_Amount;
              }
            });

          this.showSpinner = false;
        }
      });
  }
  LMS_Get_User_For_Waiver_Approval() {
    this.showSpinner = true;
    let _due =
      this.totalCalculator.totalDueAmount -
      this.totalCalculator.totalReceiveAmount;
    this.lmsService
      .LMS_Get_User_For_Waiver_Approval({
        LoginUserId: this.data.userId,
        WaiverAmount: this.PreCloserDetails.totalWaiverAmount,
        DueAmount: _due,
      })
      .subscribe((res: any) => {
        if (res != null) {
          this.wavieruserList = res;
          this.showSpinner = false;
        }
      });
  }

  getLoanRescheduleDetail() {
    this.showSpinner = true;
    this.lmsService
      .LMS_Get_Loan_Closer_Detail({
        Loan_Id: JSON.parse(this.LoanId),
        LoginUserId: this.data.userId,
      })
      .subscribe((res: any) => {
        if (res != null) {
          this.LoanAllDetail = res.Item1;


          if (
            this.LoanAllDetail[0].EMIType == "Daily" ||
            this.LoanAllDetail[0].EMIType == "Weekly" ||
            this.LoanAllDetail[0].EMIType == "Fortnightly" ||
            this.LoanAllDetail[0].EMIType == "ByWeekly" ||
            this.LoanAllDetail[0].EMIType == "FourthWeekly" ||
            this.LoanAllDetail[0].EMIType == "SingleEMI"
          )
            this.LoanTenureFreq = "Days";
          else this.LoanTenureFreq = "Months";



          this.MinEffectiveDate = new Date(this.LoanAllDetail[0].LoanDate);
          this.LoanCustomerList = JSON.parse(JSON.stringify(res.Item2));
          this.CustomerName = this.LoanCustomerList.find((obj: any) => {
            return obj.CustomerType == "Hirer";
          }).Customer;
          this.CustomerAddress = this.LoanCustomerList.find((obj: any) => {
            return obj.CustomerType == "Hirer";
          }).Present_Address;
          this.dataSource = new MatTableDataSource(this.LoanCustomerList);
          this.dataSource.sort = this.sort;
          this.showSpinner = false;
          this.LMS_Get_Loan_Closer_Due_Detail(null);
        }
      });
  }

  onCalculateAmount() {
    let _totalDue: any = 0;
    let _totalReceived: any = 0;
    let _totalWaiver: any = 0;
    let _totalBalance: any = 0;
    let _maxWaiver: any = 0;
    let _wc: any = 0;
    this.PreCloserMethodList.forEach((obj) => {
      _totalDue = _totalDue + obj.DueAmount;
      _totalReceived = _totalReceived + obj.ReceiveAmount;
      _totalWaiver = _totalWaiver + Number(obj.WaiverAmount);
      _totalBalance = _totalBalance + obj.Balance;
      obj.initialWaiverAmount = obj.WaiverAmount || 0;
      obj.lastWaiverAmount = obj.WaiverAmount || 0;
      obj.initialBalance = obj.initialBalance || obj.Balance + obj.WaiverAmount;
      if (obj.HeadId != 27 || obj.HeadId != 34) {
        _maxWaiver = _maxWaiver + obj.Balance;
      }
      const _cal = obj.DueAmount - obj.ReceiveAmount;
      if (obj.HeadId == 30 || obj.HeadId == 5) {
        _wc = _wc + (_cal < 0 ? 0 : _cal);
      } else {
        _wc = _wc + _cal;
      }
    });

    this.totalCalculator = {
      totalDueAmount: Number(_totalDue),
      totalReceiveAmount: Number(_totalReceived),
      totalWaiverAmount: Number(_totalWaiver),
      totalBalance: Number(_totalBalance),
      maxWaiver: Number(_maxWaiver),
    };
    this.PreCloserDetails.Waiver_Limit_Per_Amt =
      (_wc * this.PreCloserDetails.Waiver_Limit_Per) / 100;
    this.PreCloserDetails.Waiver_Limit_Per_Amt_R =
      this.PreCloserDetails.Waiver_Limit_Per_Amt <
      this.PreCloserDetails.Waiver_Limit_Amt
        ? this.PreCloserDetails.Waiver_Limit_Per_Amt
        : this.PreCloserDetails.Waiver_Limit_Amt;

    if (!this.PreCloserDetails.isFirstTimeCalculate) {
      let _tr =
        this.totalCalculator.totalBalance < 0
          ? 0
          : this.totalCalculator.totalBalance;

      if (
        !this.PreCloserDetails.CloserId &&
        (!this.PreCloserDetails.CustomerAgreed_Amount ||
          this.PreCloserDetails.CustomerAgreed_Amount <= 0)
      )
        this.PreCloserDetails.CustomerAgreed_Amount = _tr;
      this.PreCloserDetails.Prev_CustomerAgreed_Amount =
        this.PreCloserDetails.CustomerAgreed_Amount;
      this.PreCloserDetails.totalWaiverAmount =
        _tr - this.PreCloserDetails.CustomerAgreed_Amount;
      this.PreCloserDetails.isFirstTimeCalculate = true;
      if (this.PreCloserDetails.CloserId) {
        this.LMS_Get_User_For_Waiver_Approval();
        if (this.PreCloserDetails.Closer_RequestStatus == "Waiver Approved") {
          this.PreCloserDetails.totalWaiverAmount =
            this.PreCloserDetails.TotalWaiver_Approved;
        }
        if (this.PreCloserDetails.Closer_RequestStatus == "Waiver Pending") {
          this.isShowForward = true;
        } else if (
          this.PreCloserDetails.totalWaiverAmount >
          this.PreCloserDetails.Waiver_Limit_Per_Amt_R
        ) {
          this.isShowForward =
            this.PreCloserDetails.Closer_RequestStatus !== "Waiver Approved";
        }
      }
    }
  }
  resetCalculation() {
    this.PreCloserMethodList.forEach((obj) => {
      obj.WaiverAmount = 0; //obj.initialWaiverAmount;
      obj.Balance = obj.initialBalance - obj.WaiverAmount;
    });
    this.onCalculateAmount();
  }
  onChangeAgreeAmount(isReset: boolean = true) {
    if (
      this.PreCloserDetails.Prev_CustomerAgreed_Amount ==
      +this.PreCloserDetails.CustomerAgreed_Amount
    ) {
      return;
    }
    if (isReset) this.resetCalculation();
    let _tr =
      this.totalCalculator.totalBalance < 0
        ? this.totalCalculator.totalBalance * -1
        : this.totalCalculator.totalBalance;
    let _totalWaiverAmount =
      _tr - this.PreCloserDetails.CustomerAgreed_Amount < 0
        ? 0
        : _tr - this.PreCloserDetails.CustomerAgreed_Amount;
    if (_totalWaiverAmount > this.totalCalculator.maxWaiver) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Waiver can not be greater than ${this.totalCalculator.maxWaiver}.`,
        ...this.configSuccess,
      });
      setTimeout(() => {
        this.PreCloserDetails.CustomerAgreed_Amount =
          this.PreCloserDetails.Prev_CustomerAgreed_Amount;
      });
      return;
    }
    if (this.PreCloserDetails.CustomerAgreed_Amount < 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Deposit amount can not be less than zero.`,
        ...this.configSuccess,
      });
      setTimeout(() => {
        this.PreCloserDetails.CustomerAgreed_Amount =
          this.PreCloserDetails.Prev_CustomerAgreed_Amount;
      });
      return;
    }
    if (_tr - this.PreCloserDetails.CustomerAgreed_Amount < 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Deposit amount can not be greater than ${this.totalCalculator.totalBalance}.`,
        ...this.configSuccess,
      });
      setTimeout(() => {
        this.PreCloserDetails.CustomerAgreed_Amount =
          this.PreCloserDetails.Prev_CustomerAgreed_Amount;
      });
      return;
    }
    this.PreCloserDetails.totalWaiverAmount = _totalWaiverAmount;
    if (this.PreCloserDetails.Closer_RequestStatus == "Waiver Pending") {
      this.isShowForward = true;
      this.LMS_Get_User_For_Waiver_Approval();
    } else {
      if (
        this.PreCloserDetails.totalWaiverAmount >
        this.PreCloserDetails.Waiver_Limit_Per_Amt_R
      ) {
        this.isShowForward =
          this.PreCloserDetails.Closer_RequestStatus !== "Waiver Approved";
        this.LMS_Get_User_For_Waiver_Approval();
      } else {
        this.isShowForward = false;
      }
    }
    this.PreCloserDetails.Prev_CustomerAgreed_Amount =
      +this.PreCloserDetails.CustomerAgreed_Amount;
  }

  onChangeWaiverAmt(event, item) {
    //if (item.WaiverAmount == item.lastWaiverAmount) {
    //  return;
    //}
    //this.totalCalculator.totalWaiverAmount = this.totalCalculator.totalWaiverAmount + item.WaiverAmount

    let _amount =
      +this.PreCloserDetails.totalWaiverAmount +
      +item.lastWaiverAmount -
      +this.totalCalculator.totalWaiverAmount;
    //if (_amount >= +item.WaiverAmount) {
    if (+item.initialBalance >= +item.WaiverAmount) {
      item.Balance = +item.initialBalance - +item.WaiverAmount;
      if (item.Is_Tax) {
        let _find =
          this.PreCloserMethodList.filter((obj: any) => {
            return obj.HeadId == item.HeadId;
          }) || [];
        _find.forEach((obj: any) => {
          if (!obj.Is_Tax) {
            obj.WaiverAmount = Math.ceil(
              (item.WaiverAmount * obj.Tax_Per) / 100
            );
            obj.Balance = Math.ceil((item.Balance * obj.Tax_Per) / 100);
          }
        });
      }
      this.onCalculateAmount();
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Wavier amount can not be greater than ${+item.Balance}.`,
        ...this.configSuccess,
      });
      setTimeout(() => {
        item.WaiverAmount = item.lastWaiverAmount;
      });
    }
    // }
    //else {
    //  this.snackBar.openFromComponent(SnackbarComponent, { data: `Wavier amount remian only ${_amount}.`, ...this.configSuccess });
    //  setTimeout(() => {
    //    item.WaiverAmount = item.lastWaiverAmount;
    //  })
    //}

    if (
      this.totalCalculator.totalWaiverAmount >
      this.PreCloserDetails.CustomerAgreed_Amount
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Waiver can not be greater than ${this.PreCloserDetails.CustomerAgreed_Amount}.`,
        ...this.configSuccess,
      });

      item.WaiverAmount = 0;
      item.Balance = item.initialBalance;
      this.onCalculateAmount();

      return;
    }
  }

  fileChangeEvent(fileInput) {
    let Ext = fileInput.target.files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(
      fileInput.target.files[0].size / Math.pow(1024, 1)
    );

    if (FileSize > this.data.UploadMaxSize_In_KB) {
      this.PreCloserDetails.Request_Doc = "";
      this.RequestDocName = "";
      this.RequestDocBase64 = "";
      var Msg = `Upload file size should be less than or equal to ${this.data.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.PreCloserDetails.Request_Doc = "";
      this.RequestDocName = "";
      this.RequestDocBase64 = "";
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      return;
    }

    if (fileInput.target.files && fileInput.target.files.length) {
      let files = fileInput.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);
      fileReader.onload = (e) => {
        this.RequestDocName = "IMG_" + new Date().getTime() + ".png";
        this.RequestDocBase64 = fileReader.result
          .toString()
          .split(";base64,")
          .pop();
      };
    } else {
      this.PreCloserDetails.Request_Doc = "";
      this.RequestDocName = "";
      this.RequestDocBase64 = "";
    }
  }

  onRemoveImg() {
    this.PreCloserDetails.CloserRequestDoc = "";
    this.RequestDocName = "";
    this.RequestDocBase64 = "";
    this.PreCloserDetails.Request_Doc = "";
  }

  onCancel() {
    this.location.back();
  }

  onSaveAsDraft() {
    this.saveLoanCloser("Created");
  }
  onDeleteCloserRequest() {
    this.dialog
      .openConfirmDialog(`Are You Sure To Delete This Closer Request?`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.showSpinner = true;
          this.lmsService
            .LMS_Delete_Loan_Closer_Request({
              Loan_Id: JSON.parse(this.LoanId),
              CloserId: this.PreCloserDetails.CloserId,
              LoginUserId: this.data.userId,
            })
            .subscribe((res: any) => {
              if (res[0].CODE >= 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.onCancel();
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
              this.showSpinner = false;
            });
        }
      });
  }

  onPreClosureClose() {
    this.minDate = new Date(
      JSON.parse(sessionStorage.getItem("currentUser")).Data_Freeze.split(
        "T"
      )[0] + " 00:00:00"
    );
    if (
      new Date(this.PreCloserDetails.LoanCloser_EffectiveDate) < this.minDate
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Data Freeze On Date ",
        ...this.configSuccess,
      });
    } else {
      if (
        this.totalCalculator.totalBalance <=
        this.PreCloserDetails.CustomerAgreed_Amount +
          this.totalCalculator.totalWaiverAmount
      ) {
        this.dialog
          .openConfirmDialog(`Are you sure you want to close this loan?`)
          .afterClosed()
          .subscribe((dialogResult) => {
            if (dialogResult == false) {
              return;
            } else {
              this.saveLoanCloser("Closed");
            }
          });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: `Sum of customer agree amount  and waiver amount should be equal to received amount`,
          ...this.configSuccess,
        });
      }
    }
  }
  onWaieverApproved() {
    this.dialog
      .openConfirmDialog(`Are you sure you want to Approve?`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.saveLoanCloser("Waiver Approved");
        }
      });
  }

  onPrintPreClosureStatement() {
    var prtContent = document.getElementById("printData");
    var WinPrint = window.open("", "", "letf=100,top=100,width=600,height=600");
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
  }

  onWaieverReject() {
    this.dialog
      .openConfirmDialog(`Are you sure you want to Reject.`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.saveLoanCloser("Waiver Rejected");
        }
      });
  }

  onForwardToSeniorWaiverApproval() {
    if (this.PreCloserDetails.Waiver_GivenBy != "") {
      this.saveLoanCloser("Waiver Pending");
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Pleasse select user to waiver.`,
        ...this.configSuccess,
      });
    }
  }
  saveLoanCloser(status: any) {}

  onViewLoanStatement() {
    if (this.LoanAllDetail[0]) {
      this.loanSummary.LoanId = parseInt(this.LoanId);
      this.loanSummary.ApplicationNo = this.LoanAllDetail[0].ApplicationNo;
      this.loanSummary.LoanNo = this.LoanAllDetail[0].LoanNo;
      this.dataSharingService.LmsViewLoanStatement.next(this.loanSummary);
      $("#ViewLoanStatementPreCloserModel").modal("show");
      $("#ViewLoanStatementPreCloserModel").css("z-index", "1050");
    }
  }

  onCloseViewLoanStatement() {
    $("#ViewLoanStatementPreCloserModel").modal("hide");
  }
  onClickPrintStatement() {
    this.lmsService
      .LMS_Get_Loan_Closer_Statement({
        CloserId: this.PreCloserDetails.CloserId,
        LoginUserId: this.data.userId,
      })
      .subscribe((res: any) => {
        this.loanStatement = res;
        let _total = 0;
        this.loanStatement.Item2.forEach((obj: any) => {
          _total = _total + obj.Balance;
        });
        this.loanStatement.total = _total;
        $("#PrintLoanStatementPreCloserModel").modal("show");
        $("#PrintLoanStatementPreCloserModel").css("z-index", "1050");
      });
  }
  onClosePrintLoanStatement() {
    $("#PrintLoanStatementPreCloserModel").modal("hide");
  }
  downloadPdf() {
    var prtContent = document.getElementById("pdfPrintReport");
    //var WinPrint = window.open("", "", "letf=100,top=100,width=600,height=600");
    var WinPrint = window.open("letf=100,top=100");
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
  }
  onPrintCalculateAmount() {
    let _totalDue: any = 0;
    let _totalReceived: any = 0;
    let _totalWaiver: any = 0;
    let _totalBalance: any = 0;
    let _maxWaiver: any = 0;
    let _wc: any = 0;
    this.PrintCloserMethodList.forEach((obj) => {
      _totalDue = _totalDue + obj.DueAmount;
      _totalReceived = _totalReceived + obj.ReceiveAmount;
      _totalWaiver = _totalWaiver + Number(obj.WaiverAmount);
      _totalBalance = _totalBalance + obj.Balance;
      obj.initialWaiverAmount = obj.WaiverAmount || 0;
      obj.lastWaiverAmount = obj.WaiverAmount || 0;
      obj.initialBalance = obj.initialBalance || obj.Balance + obj.WaiverAmount;
      if (obj.HeadId != 27 || obj.HeadId != 34) {
        _maxWaiver = _maxWaiver + obj.Balance;
      }
      const _cal = obj.DueAmount - obj.ReceiveAmount;
      if (obj.HeadId == 30 || obj.HeadId == 5) {
        _wc = _wc + (_cal < 0 ? 0 : _cal);
      } else {
        _wc = _wc + _cal;
      }
    });

    this.printtotalCalculator = {
      totalDueAmount: Number(_totalDue),
      totalReceiveAmount: Number(_totalReceived),
      totalWaiverAmount: Number(_totalWaiver),
      totalBalance: Number(_totalBalance),
      maxWaiver: Number(_maxWaiver),
    };
  }
  WritteOff_Voucher() {
    if (this.totalCalculator.totalBalance > 0) {
      let _vouvher: any = {};
      //console.log("voucherno", VoucherNo);
      _vouvher = {
        VoucherId: 0,
        Voucher_ReceiptNo_Book: null,
        Voucher_ReceiptDate: null,
        Voucher_Type: "WriteOff",
        Voucher_Sub_Type: "Loan",
        Voucher_Tag: "",
        Voucher_Date: new Date(
          this.PreCloserDetails.LoanCloser_EffectiveDate
        ).toLocaleDateString("fr-CA"),
        Voucher_Effective_Date: null,
        Voucher_Mode_of_Payment: "",
        Voucher_Total_Credit_Amount: this.totalCalculator.totalBalance,
        Voucher_Total_Debit_Amount: this.totalCalculator.totalBalance,
        Voucher_Total_Tax: null,
        Voucher_Narration: "WriteOff Loan No. " + this.LoanAllDetail[0].LoanNo,
        Voucher_LoanId: JSON.parse(this.LoanId),
        Voucher_E_Transaction_Date: null,
        Voucher_E_Ref_No: null,
        LoginUserId: this.data.userId,
      };
      let _voucheretail: any[] = [];
      this.PreCloserMethodList.map((item) => {
        if (item.Balance != 0) {
          _voucheretail.push({
            VoucherId: 0,
            CaseNo: this.LoanAllDetail[0].LoanNo,
            CustomerId: this.LoanAllDetail[0].CustomerId,
            Is_LOSAppliaction: 0,
            LOS_TransactionId: null,
            ChargeHeadId: item.HeadId,
            ChargeHeadCategory: item.Account_Name,
            AccountId: item.AccountHeadId,
            BranchId: this.LoanAllDetail[0].BranchId,
            TranType: item.Balance > 0 ? "CR" : "DR",
            IsTax: null,
            Tax_Per: null,
            Amount: item.Balance > 0 ? item.Balance : -item.Balance,
          });
        }
      });
      _voucheretail.push({
        VoucherId: 0,
        CaseNo: this.LoanAllDetail[0].LoanNo,
        CustomerId: this.LoanAllDetail[0].CustomerId,
        Is_LOSAppliaction: 0,
        LOS_TransactionId: null,
        ChargeHeadId: 85,
        ChargeHeadCategory: this.PreCloserDetails.Written_off_AccountName,
        AccountId: this.PreCloserDetails.Written_off_AccountId,
        BranchId: this.LoanAllDetail[0].BranchId,
        TranType: this.totalCalculator.totalBalance > 0 ? "DR" : "CR",
        IsTax: null,
        Tax_Per: null,
        Amount:
          this.totalCalculator.totalBalance > 0
            ? this.totalCalculator.totalBalance
            : -this.totalCalculator.totalBalance,
      });
      let _finalData = {
        Voucher: _vouvher,
        Voucher_Detail: _voucheretail,
      };
      console.log("_finalData", _finalData);

      this.lmsService
        .Save_Acc_VoucherMasterWithoutRateLimit({
          JSON: JSON.stringify(_finalData),
        })
        .subscribe((res: any) => {
          if (res && res[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            this.SaveDetails();
          } else if (res) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    } else {
      this.SaveDetails();
    }
  }
  onSaveRequest() {
    this.showSpinner = true;
    this.savebutton = false;
    if (this.totalCalculator.totalBalance > 0) {
      if (this.CloserTypeValue == "Written-off") {
        if (
          this.PreCloserDetails.Written_off_AccountId != null &&
          this.PreCloserDetails.Written_off_AccountId > 0
        ) {
          this.WritteOff_Voucher();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: `Please Bind Written_off Account of this product.`,
            ...this.configSuccess,
          });
          return;
        }
      } else if (
        this.CloserTypeValue == "Settled" ||
        this.CloserTypeValue == "Post (WO) Settled" ||
        this.CloserTypeValue == "Written Off and Account Sold" ||
        this.CloserTypeValue == "Account Sold"
      ) {
        if (
          this.PreCloserDetails.Written_off_AccountId != null &&
          this.PreCloserDetails.Written_off_AccountId > 0
        ) {
          if (this.totalCalculator.totalWaiverAmount > 0) {
            let _vouvher: any = {};
            _vouvher = {
              VoucherId: 0,
              Voucher_ReceiptNo_Book: null,
              Voucher_ReceiptDate: null,
              Voucher_Type: "Settled",
              Voucher_Sub_Type: "Loan",
              Voucher_Tag: this.voucherModel.Voucher.Voucher_Tag,
              Voucher_Date: new Date(
                this.PreCloserDetails.LoanCloser_EffectiveDate
              ).toLocaleDateString("fr-CA"),
              Voucher_Effective_Date: null,
              Voucher_Mode_of_Payment:
                this.voucherModel.Voucher.Voucher_Mode_of_Payment,
              Voucher_Total_Credit_Amount:
                this.totalCalculator.totalWaiverAmount,
              Voucher_Total_Debit_Amount:
                this.totalCalculator.totalWaiverAmount,
              Voucher_Total_Tax: null,
              Voucher_Narration: "Settlement Voucher",
              Origin: "WritteOff",
              Voucher_E_Transaction_Date:
                this.voucherModel.Voucher.Voucher_E_Transaction_Date,
              Voucher_E_Ref_No: this.voucherModel.Voucher.Voucher_E_Ref_No,
              Voucher_LoanId: JSON.parse(this.LoanId),
              LoginUserId: this.data.userId,
            };
            let _voucheretail: any[] = [];
            let _find = this.accountTagDropdown.find((obj: any) => {
              return obj.AccountId == this.voucherModel.Voucher.DRAccountId;
            });

            this.PreCloserMethodList.map((item) => {
              if (item.WaiverAmount != null && item.WaiverAmount != 0) {
                _voucheretail.push(
                  this.dataSharingService.cleanObject({
                    VoucherId: 0,
                    CaseNo: this.LoanAllDetail[0].LoanNo,
                    CustomerId: this.LoanAllDetail[0].CustomerId,
                    Is_LOSAppliaction: 0,
                    ChargeHeadId: item.HeadId,
                    ChargeHeadCategory: item.Account_Name,
                    AccountId: item.AccountHeadId,
                    BranchId: this.LoanAllDetail[0].BranchId,
                    TranType: "CR",
                    IsTax: 0,
                    Tax_Per: "",
                    Amount: item.WaiverAmount,
                  })
                );
              }
            });
            _voucheretail.push(
              this.dataSharingService.cleanObject({
                VoucherId: 0,
                CaseNo: "",
                CustomerId: "",
                Is_LOSAppliaction: "",
                ChargeHeadId: "",
                ChargeHeadCategory: _find.Account_Name,
                AccountId: _find.AccountId,
                BranchId: "",
                TranType: "DR",
                IsTax: 0,
                Tax_Per: 0,
                Amount: this.totalCalculator.totalWaiverAmount,
              })
            );

            let _finalData = {
              Voucher: _vouvher,
              Voucher_Detail: _voucheretail,
            };
            console.log("_finalData", _finalData);

            this.lmsService
              .Save_Acc_VoucherMasterWithoutRateLimit({
                JSON: JSON.stringify(_finalData),
              })
              .subscribe((res: any) => {
                if (res && res[0].CODE >= 0) {
                  // this.showSpinner = false;
                  this.snackBar.openFromComponent(SnackbarComponent, {
                    data: res[0].MSG,
                    ...this.configSuccess,
                  });

                  this.WritteOff_Voucher();
                } else if (res) {
                  this.snackBar.openFromComponent(SnackbarComponent, {
                    data: res[0].MSG,
                    ...this.configSuccess,
                  });
                }
              });
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: `Please Update Settlement Amount.`,
              ...this.configSuccess,
            });
            return;
          }
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: `Please Bind Written_off Account of this product.`,
            ...this.configSuccess,
          });
          return;
        }
      } else {
        this.SaveDetails();
      }
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Already Balance Negative!!!.`,
        ...this.configSuccess,
      });
      return;
    }
  }
  StatusChange() {
    this.losService
      .getEmployeeDropdown({
        BranchId: this.LoanAllDetail[0].BranchId,
        ProcessId: 0,
      })
      .subscribe((res: any) => {
        this.employeeDropdown = res;
      });
    this.CloserTypeValue = this.StatusList.find((obj: any) => {
      return obj.Id == this.PreCloserDetails.CloserType;
    }).Value;
  }
  SaveDetails() {
    let _Closer: any = {
      CloserId: this.PreCloserDetails.CloserId,
      LoanId: JSON.parse(this.LoanId),
      Request_Status: this.StatusList.find((obj: any) => {
        return obj.Id == this.PreCloserDetails.CloserType;
      }).Value,
      EffectiveDate: new Date(
        this.PreCloserDetails.LoanCloser_EffectiveDate
      ).toLocaleDateString("fr-CA"),
      DueAmount: this.totalCalculator.totalDueAmount,
      ReceivedAmount: this.totalCalculator.totalReceiveAmount,
      BalanceAmount: this.totalCalculator.totalBalance,
      CustomerAgreedAmount: this.PreCloserDetails.CustomerAgreed_Amount,
      WaiverAmount: this.totalCalculator.totalWaiverAmount,
      WaiverLimit: this.PreCloserDetails.Waiver_Limit_Amt,
      Closer_Remark: this.PreCloserDetails.LoanCloser_Remark,
      Waiver_Remark: this.PreCloserDetails.Waiver_Remark,
      CloserDoc: this.RequestDocName || this.PreCloserDetails.CloserRequestDoc,
      LoginUserId: this.data.userId,
      WavierGivenBy: this.PreCloserDetails.Waiver_GivenBy,
      CloserType: this.StatusList.find((obj: any) => {
        return obj.Id == this.PreCloserDetails.CloserType;
      }).Value,
      //        this.StatusList.find((obj: any) => { return obj.Id == this.PreCloserDetails.CloserType }).Value,
    };
    let _CloserDetail: any[] = [];
    this.PreCloserMethodList.forEach((element: any) => {
      _CloserDetail.push({
        HeadId: element.HeadId,
        AccountHeadId: element.AccountHeadId,
        DueAmount: element.DueAmount,
        ReceivedAmount: element.ReceiveAmount,
        WaiverAmount: element.WaiverAmount,
        IsWaiver_Eligible: element.IsWaiver_Eligible,
      });
    });
    let _data: any = {
      Closer: _Closer,
      CloserDetail: _CloserDetail,
    };

    this.lmsService
      .LMS_Save_Loan_Closer_Request({ JSON: JSON.stringify(_data) })
      .subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          this.showSpinner = false;
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.onCancel();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.showSpinner = false;
        }
        this.showSpinner = false;
      });
  }
  onVoucherTagChange() {
    this.voucherModel.Voucher.Voucher_Mode_of_Payment = "";
    this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
    this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    this.voucherModel.Voucher.Voucher_Bank_Name = "";
    this.voucherModel.Voucher.Voucher_Cheque_Date = "";
    this.voucherModel.Voucher.Voucher_Cheque_No = "";
    this.Get_Acc_Accounts_By_Tag();
  }
  Get_Acc_Accounts_By_Tag() {
    this.voucherModel.Voucher.DRAccountId = "";
    this.lmsService
      .Get_Acc_Accounts_By_Tag({
        LoginUserId: this.data.userId,
        Account_Tag: this.voucherModel.Voucher.Voucher_Tag,
        Voucher_Date: this.voucherModel.Voucher.Voucher_Date,
      })
      .subscribe((res: any) => {
        this.accountTagDropdown = res;
      });
  }
  onChangeMode() {
    if (this.voucherModel.Voucher.Voucher_Mode_of_Payment == "Cheque") {
      this.voucherModel.Voucher.Voucher_Cheque_No = "";
      this.voucherModel.Voucher.Voucher_Cheque_Date = "";
      this.voucherModel.Voucher.Voucher_Bank_Name = "";
      this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    } else {
      this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
      this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    }
  }

  onUndoCloserRequest() {
    if (this.undoRemark == "") {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Enter undo remark.`,
        ...this.configSuccess,
      });
      return;
    }
    this.dialog
      .openConfirmDialog(`Are You Sure To Undo This Loan ?`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.showSpinner = true;
          this.lmsService
            .LMS_Loan_WriteOff_Settled_Undo({
              Loan_Id: JSON.parse(this.LoanId),
              Remark: this.undoRemark,
              LoginUserId: this.data.userId,
            })
            .subscribe((res: any) => {
              if (res[0].CODE >= 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.onCancel();
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
              this.showSpinner = false;
            });
        }
      });
  }
}
