<div>
  <form #f="ngForm" (ngSubmit)="onSaveEmployeeLendingAssignment()" novalidate>
    <div class="row m-0 mt-1 formborder">
      <div class="col-md-4 p-1">
        <span>Employee Name </span>
        <input
          required
          type="text"
          #refProductName="ngModel"
          name="EmployeeName"
          id="EmployeeName"
          placeholder="Employee Name"
          [(ngModel)]="EmployeeName"
          class="form-control input-text-css"
          disabled
        />
      </div>
      <div class="col-md-3 p-1">
        <span> Designation </span>
        <select
          name="SelectRoleId"
          id="SelectRoleId"
          disabled
          [(ngModel)]="SelectRoleId"
          class="form-control input-text-css"
        >
          <option
            *ngFor="let rolesDropdownData of RolesDropdownData"
            [value]="rolesDropdownData.RoleId"
          >
            {{ rolesDropdownData.Role_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-3 p-1">
        <span>Reporting Manager </span>
        <select
          name="EmpManagerId"
          id="EmpManagerId"
          disabled
          [(ngModel)]="EmpManagerId"
          class="form-control input-text-css"
        >
          <option
            *ngFor="let reportingManager of ReportingManagerDropdown"
            [value]="reportingManager.EmpId"
          >
            {{ reportingManager.EmpName }}
          </option>
        </select>
      </div>
      <!-- *ngIf="AssignProductList.length > 0" -->
      <div class="col-md-2 p-1 mt-3">
        <button
          type="button"
          (click)="onNewLenderAssign()"
          class="btn font-size-12 button-btn"
          [class.spinner]="loading"
        >
          Lender Assign
        </button>
      </div>
    </div>

    <div>
      <div class="table-responsive simple-table mt-2">
        <table class="table font-size-12">
          <thead>
            <tr>
              <th>#</th>
              <th>Lender Name</th>
              <th>
                <mat-checkbox
                  class="example-margin"
                  name="SelectAll"
                  id="SelectAll"
                  [(ngModel)]="SelectAll"
                  (change)="onChangeSelectAll()"
                >
                </mat-checkbox>
                Select All
              </th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let assignLending of LendingAssignmentList; let i = index"
              class="font-size-11"
            >
              <td>{{ i + 1 }}</td>
              <td>{{ assignLending.Loan_Acc_Category }}</td>
              <td>
                <mat-checkbox
                  [checked]="assignLending.isActiveSelect"
                  [disabled]="assignLending.isDisable"
                  id="checkbox-Product-{{ i }}"
                  name="checkbox-Product-{{ i }}"
                  (change)="
                    onChangeAssignLender(
                      $event,
                      assignLending.Loan_Acc_CategoryId
                    )
                  "
                >
                </mat-checkbox>
              </td>
              <td>
                <i
                  class="fa fa-trash style-delete"
                  aria-label="true"
                  (click)="
                    onDeleteAssignLender(assignLending.Loan_Acc_CategoryId)
                  "
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="text-right mt-2">
        <button
          type="button"
          (click)="onSaveEmployeeDetailData()"
          class="btn font-size-12 button-btn"
          [class.spinner]="loading"
        >
          Save
        </button>
      </div>
    


  </form>
</div>

<div
  class="modal fade in"
  id="LenderAssignModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          {{ popupValue }} Lender Assignment
        </h6>
        <button
          type="button"
          (click)="onCloseLenderAssignModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form #ff="ngForm" (ngSubmit)="LenderAssignment()" novalidate>
          <div class="row m-0">
            <div class="col-md-12 p-1">
              <span> Select Lender </span>
              <select
                required
                name="SelectLender"
                id="SelectLender"
                #refSelectLender="ngModel"
                [(ngModel)]="SelectLender"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': ff.submitted && refSelectLender.invalid,
                  'alert-warning':
                    refSelectLender.invalid &&
                    (refSelectLender.dirty ||
                      refSelectLender.touched ||
                      refSelectLender.untouched)
                }"
              >
                <option value="">Select Lender Assign</option>
                <option
                  *ngFor="let LenderAssign of AssignLenderList"
                  [value]="LenderAssign.Loan_Acc_CategoryId"
                >
                  {{ LenderAssign.Loan_Acc_Category }}
                </option>
              </select>
            </div>
          </div>
          <div class="table-responsive">
            <mat-table
              [dataSource]="dataSourceLenderAssign"
              matSort
              matTableExporter
              #exporter="matTableExporter"
              style="height: 190px; max-width: 100%; overflow: auto"
            >
              <ng-container matColumnDef="EmpId">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  style="max-width: 100px"
                  *matHeaderCellDef
                  >#</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell"
                  style="max-width: 100px; max-height: 5px"
                >
                  {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Emp_Name">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Emp_Name</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Emp_Name }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Designation">
                <mat-header-cell
                  mat-sort-header
                  class="grid-header"
                  *matHeaderCellDef
                  >Designation</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Designation }}
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></mat-row>
            </mat-table>

            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
          <div class="row m-0 mt-2">
            <div class="col-md-12 text-right">
              <button
                type="button"
                (click)="LenderAssignment()"
                [disabled]="!ff.form.valid"
                class="btn font-size-12 button-btn"
                [class.spinner]="loading"
              >
                Assign
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="DeleteAssignModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          {{ popupValue }} Product Assignment
        </h6>
        <button
          type="button"
          (click)="onCloseDeleteProductModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <mat-table
            [dataSource]="dataSource"
            matSort
            matTableExporter
            #exporter="matTableExporter"
            style="height: 190px; max-width: 100%; overflow: auto"
          >
            <ng-container matColumnDef="EmpId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 100px"
                *matHeaderCellDef
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 100px; max-height: 5px"
              >
                {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Emp_Name">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Emp_Name</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Emp_Name }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Designation">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Designation</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Designation }}
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>

          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>

        <div class="row m-0 mt-2">
          <div class="col-md-12 text-right">
            <button
              type="button"
              (click)="DeleteAllLendingAssignment()"
              class="btn font-size-12 button-btn"
              [class.spinner]="loading"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
