import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
import { Router } from "@angular/router";
import { PartnerService } from "../services/partner.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";

@Component({
  selector: "app-partner-booking-list",
  templateUrl: "./partner-booking-list.component.html",
  styleUrls: ["./partner-booking-list.component.scss"],
})
export class PartnerBookingListComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  DataSource: any;

  StartDate: any = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  EndDate: any = new Date();

  AcCatgList: any = [];
  ACCategType: any = "";
  Loan_Acc_Type: any = "Co-Lending";

  selectedId: any;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("paginatorlist", { read: MatPaginator, static: true })
  paginatorlist: MatPaginator;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  displayedColumns: string[] = [
    "PLoan_Id",
    "PLoanAcNo",
    "LMS_LoanAcNo",
    "Product_Name",
    "Branch_Name",
    "Loan_Acc_Category",
    "CustomerName",
    "LoanAmount",
    "InterestAmt",
    "AgreementValue",
    "LoanEMIAmount",
    "No_of_EMI",
    "LoanTenure",
    "ROI",
    "Case_IRR",
    "Disbursement_IRR",
    "Due_Date",
    "FirstEMIDate",
    "Application_ExpiryDate",
    "AdvInstl",
    "EMI_Frexvency",
    "EMI_Structure",
  ];

  constructor(
    private lmsService: LmsService,
    private partnerservice: PartnerService,
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Booking List");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.onChangeType();
  }

  onSearchPartnerBookingList() {
    this.showSpinner = true;
    this.partnerservice
      .Partner_Booking_List({
        StartDate: this.StartDate,
        EndDate: this.EndDate,
        LoanAccountingId: this.ACCategType,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res: any) => {
        console.log(res);

        if (res.length > 0) {
          this.DataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.DataSource.sort = this.sort;
          this.DataSource.paginator = this.paginatorlist;
          this.showSpinner = false;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No Data Found",
            ...this.configSuccess,
          });
          this.DataSource = null;
          this.showSpinner = false;
        }
      });
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `PARTNER BOOKING LIST`,
      sheet: "Booking-list",
    });
  }

  onChangeType() {
    this.ACCategType = "";
    this.lmsService
      .Get_ACC_Category_ForDropdown_By_Type({ Type: this.Loan_Acc_Type })
      .subscribe((result) => {
        this.AcCatgList = JSON.parse(JSON.stringify(result));
      });
  }

  DateChange() {
    if (this.StartDate != "" && this.EndDate != "") {
      if (this.StartDate > this.EndDate) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "End Date should be greater than to Start Date.",
          ...this.configSuccess,
        });
        this.StartDate = "";
        this.EndDate = "";
      }
    }
  }

  totaltypewise(type) {
    if (this.DataSource != undefined) {
      if (this.DataSource != null) {
        let sum = 0;
        for (let i = 0; i < this.DataSource.filteredData.length; i++) {
          sum += Number(this.DataSource.filteredData[i][type]);
        }
        return sum > 0 ? sum.toFixed(2) : "";
      }
      return "";
    }
  }

  public highlightRow(row) {
    this.selectedId = row.PLoan_Id;
  }
}
