<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<!-- Information Show -->
<div class="row m-0 mt-3 formborder" *ngIf="IsDisabled">
  <div class="col-md-2">
    <h6 class="fs-12">Branch</h6>
    <p class="lead fs-12 fw-7">{{ loanInfoModel.Branch }}</p>
  </div>
  <div class="col-md-2">
    <h6 class="fs-12">Branch Head</h6>
    <p class="lead fs-12">{{ loanInfoModel.BranchHeadName }}</p>
  </div>
  <div class="col-md-2">
    <h6 class="fs-12">Loan Product</h6>
    <p class="lead fs-12">{{ loanInfoModel.Product }}</p>
  </div>
  <div class="col-md-2">
    <h6 class="fs-12">Source Type</h6>
    <p class="lead fs-12">{{ loanInfoModel.SourceType }}</p>
  </div>
  <div
    class="col-md-2"
    *ngIf="
      loanInfoModel.SelectSourceType == 'Agent' ||
      loanInfoModel.SelectSourceType == 'DSA' ||
      loanInfoModel.SelectSourceType == 'Dealer' ||
      loanInfoModel.SelectSourceType == 'Sales Executive'||
      loanInfoModel.SelectSourceType == 'Telecaller Executive'||
      loanInfoModel.SelectSourceType == 'Marketing Executive'
    "
  >
    <h6 class="fs-12">Source Name</h6>
    <p class="lead fs-12">{{ loanInfoModel.Partner_Name }}</p>
  </div>
  <div
    class="col-md-2"
    *ngIf="
      loanInfoModel.SelectSourceType == 'Reference' ||
      loanInfoModel.SelectSourceType == 'Social Media'
    "
  >
    <h6 class="fs-12">Source Name</h6>
    <p class="lead fs-12">{{ loanInfoModel.LeadReference }}</p>
  </div>
  <div class="col-md-2">
    <h6 class="fs-12">Source Area</h6>
    <p class="lead fs-12">{{ loanInfoModel.SourceAreaName }}</p>
  </div>
  <div class="col-md-2">
    <h6 class="fs-12">Sales Executive</h6>
    <p class="lead fs-12">{{ loanInfoModel.SalesExecutiveName }}</p>
  </div>

  <div class="col-md-2">
    <h6 class="fs-12">Collection Executive</h6>
    <p class="lead fs-12">{{ loanInfoModel.CollectionExecutiveName }}</p>
  </div>
  <div class="col-md-2">
    <h6 class="fs-12">Collection Area</h6>
    <p class="lead fs-12">{{ loanInfoModel.AreaName }}</p>
  </div>
  <div class="col-md-2">
    <h6 class="fs-12">Collection Sub Area</h6>
    <p class="lead fs-12">{{ loanInfoModel.SubareaName }}</p>
  </div>
  <div class="col-md-2">
    <h6 class="fs-12">Loan Purpose</h6>
    <p class="lead fs-12">{{ loanInfoModel.LoanPurpose }}</p>
  </div>
</div>

<!--Form To Edit Information-->
<form #f="ngForm" novalidate>
  <div style="max-height: 330px; max-width: 100%; overflow: auto">
    <div class="row m-0 mt-2" *ngIf="!IsDisabled">
      <div class="col-md-3 p-1">
        <span class="required-lable"> Branch </span>
        <select
          name="Branch_Id"
          (change)="getAppNo_AND_LoanNo()"
          id="Branch_Id"
          #refBranch_Id="ngModel"
          required
          [disabled]="IsDisabled"
          [ngClass]="{
            'is-invalid': f.submitted && refBranch_Id.invalid,
            'alert-warning':
              refBranch_Id.invalid &&
              (refBranch_Id.dirty ||
                refBranch_Id.touched ||
                refBranch_Id.untouched)
          }"
          [(ngModel)]="loanInfoModel.Branch_Id"
          class="form-control input-text-css"
        >
          <option value="">Select Branch</option>
          <option
            *ngFor="let branch of BranchesList"
            [value]="branch.BranchId"
            (onSelect)="onSelectMethods($event)"
          >
            {{ branch.Branch_Name }}
          </option>
        </select>
      </div>
      <!-- <div class="col-md-3 p-1">
                <span class="required-lable">Application No </span>
                <input name="ApplicationNo" id="ApplicationNo" #refApplicationNo="ngModel" disabled [(ngModel)]="loanInfoModel.ApplicationNo" readonly
                    placeholder="ApplicationNo" class="form-control input-text-css">
            </div> -->
      <div class="col-md-3 p-1">
        <span class="required-lable"> Branch Head </span>
        <select
          name="SelectBranchHead"
          id="SelectBranchHead"
          #refSelectBranchHead="ngModel"
          required
          [ngClass]="{
            'is-invalid': f.submitted && refSelectBranchHead.invalid,
            'alert-warning':
              refSelectBranchHead.invalid &&
              (refSelectBranchHead.dirty ||
                refSelectBranchHead.touched ||
                refSelectBranchHead.untouched)
          }"
          [(ngModel)]="loanInfoModel.SelectBranchHead"
          class="form-control input-text-css"
          disabled
        >
          <option value="">Select Branch Head</option>
          <option *ngFor="let head of branchHeadData" [value]="head.EmpId">
            {{ head.Emp_FirstName + " " + head.Emp_LastName }}
          </option>
        </select>
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable"> Loan Product </span>
        <select
          name="LoanProduct_Id"
          id="LoanProduct_Id"
          #refLoanProduct_Id="ngModel"
          required
          [disabled]="IsDisabled"
          (change)="getAppNo_AND_LoanNo()"
          [ngClass]="{
            'is-invalid': f.submitted && refLoanProduct_Id.invalid,
            'alert-warning':
              refLoanProduct_Id.invalid &&
              (refLoanProduct_Id.dirty ||
                refLoanProduct_Id.touched ||
                refLoanProduct_Id.untouched)
          }"
          [(ngModel)]="loanInfoModel.LoanProduct_Id"
          class="form-control input-text-css"
        >
          <option value="">Select Products</option>
          <ng-container *ngFor="let productg of ProductList">
            <option
              *ngIf="productg.Product_IsActive == true"
              [value]="productg.ProductId"
            >
              {{ productg.Product }}
            </option>
          </ng-container>
        </select>
      </div>

      <div class="col-md-3 p-1">
        <span class="required-lable">Source Type </span>
        <select
          name="SelectSourceType"
          id="SelectSourceType"
          #refSelectSourceType="ngModel"
          required
          [disabled]="IsDisabled"
          [ngClass]="{
            'is-invalid': f.submitted && refSelectSourceType.invalid,
            'alert-warning':
              refSelectSourceType.invalid &&
              (refSelectSourceType.dirty ||
                refSelectSourceType.touched ||
                refSelectSourceType.untouched)
          }"
          (change)="Get_PartnerForDropdown()"
          [(ngModel)]="loanInfoModel.SelectSourceType"
          class="form-control input-text-css"
        >
          <option value="">Select Source Type</option>
          <option
            *ngFor="let lead of leadSourceDropdown"
            [value]="lead.Lead_Source"
          >
            {{ lead.Lead_Source_DisplayName }}
          </option>
        </select>
      </div>
      <ng-container
        *ngIf="
          loanInfoModel.SelectSourceType == 'Agent' ||
          loanInfoModel.SelectSourceType == 'DSA' ||
          loanInfoModel.SelectSourceType == 'Dealer' ||
          loanInfoModel.SelectSourceType == 'Sales Executive' ||
          loanInfoModel.SelectSourceType == 'Telecaller Executive' ||
          loanInfoModel.SelectSourceType == 'Marketing Executive'
        "
      >
        <div class="col-md-3 p-1">
          <span class="required-lable"> Source Name</span>
          <select
            name="LeadSourceName"
            id="LeadSourceName"
            #refLeadSourceName="ngModel"
            required
            [disabled]="IsDisabled"
            [ngClass]="{
              'is-invalid': f.submitted && refLeadSourceName.invalid,
              'alert-warning':
                refLeadSourceName.invalid &&
                (refLeadSourceName.dirty ||
                  refLeadSourceName.touched ||
                  refLeadSourceName.untouched)
            }"
            [(ngModel)]="loanInfoModel.LeadSourceName"
            class="form-control input-text-css"
          >
            <option value="">Select Lead Reference</option>
            <option
              *ngFor="let partner of partnerDropdown"
              [value]="partner.PartnerId"
            >
              {{ partner.Partner_Name }}
            </option>
          </select>
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          loanInfoModel.SelectSourceType == 'Reference' ||
          loanInfoModel.SelectSourceType == 'Social Media'
        "
      >
        <div class="col-md-3 p-1">
          <span> Source Name</span>
          <input
            type="text"
            #refLeadReference="ngModel"
            [(ngModel)]="loanInfoModel.LeadReference"
            [disabled]="IsDisabled"
            placeholder="Lead Reference"
            name="LeadReference"
            id="LeadReference"
            class="form-control input-text-css"
          />
        </div>
      </ng-container>
      <div class="col-md-3 p-1">
        <span class="required-lable"> Source Area</span>
        <select
          name="SelectSourceArea"
          id="SelectSourceArea"
          #refSelectSourceArea="ngModel"
          required
          [disabled]="IsDisabled"
          [ngClass]="{
            'is-invalid': f.submitted && refSelectSourceArea.invalid,
            'alert-warning':
              refSelectSourceArea.invalid &&
              (refSelectSourceArea.dirty ||
                refSelectSourceArea.touched ||
                refSelectSourceArea.untouched)
          }"
          [(ngModel)]="loanInfoModel.SelectSourceArea"
          class="form-control input-text-css"
        >
          <option value="">Select Source Area</option>
          <ng-container *ngFor="let area of areaData">
            <option
              *ngIf="area.Type.trim() == 'Source Area'"
              [value]="area.Int_Id"
            >
              {{ area.Area_Name }}
            </option>
          </ng-container>
        </select>
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable"> Sales Executive</span>
        <select
          name="SalesExecutive"
          id="SalesExecutive"
          #refSalesExecutive="ngModel"
          required
          (change)="onChangeSalesExecutive(loanInfoModel.SalesExecutive)"
          [disabled]="IsDisabled"
          [ngClass]="{
            'is-invalid': f.submitted && refSalesExecutive.invalid,
            'alert-warning':
              refSalesExecutive.invalid &&
              (refSalesExecutive.dirty ||
                refSalesExecutive.touched ||
                refSalesExecutive.untouched)
          }"
          [(ngModel)]="loanInfoModel.SalesExecutive"
          class="form-control input-text-css"
        >
          <option value="">Select Sales Executive</option>
          <option
            *ngFor="let collection of SalesExecutiveData"
            [value]="collection.EmpId"
          >
            {{ collection.EmpName }}
          </option>
        </select>
      </div>

      <div class="col-md-3 p-1">
        <span>Collection Executive</span>
        <select
          name="CollectionExecutive"
          id="CollectionExecutive"
          #refCollectionExecutive="ngModel"
          [disabled]="IsDisabled"
          [ngClass]="{
            'is-invalid': f.submitted && refCollectionExecutive.invalid,
            'alert-warning':
              refCollectionExecutive.invalid &&
              (refCollectionExecutive.dirty ||
                refCollectionExecutive.touched ||
                refCollectionExecutive.untouched)
          }"
          [(ngModel)]="loanInfoModel.CollectionExecutive"
          class="form-control input-text-css"
        >
          <option value="">Select Collection Executive</option>
          <option
            *ngFor="let collection of CollectionExecutiveData"
            [value]="collection.EmpId"
          >
            {{ collection.Emp_FirstName }}
          </option>
        </select>
      </div>
      <div class="col-md-3 p-1">
        <span> Collection Area</span>
        <select
          name="SelectArea"
          id="SelectArea"
          (change)="changeArea()"
          #refSelectArea="ngModel"
          [disabled]="IsDisabled"
          [ngClass]="{
            'is-invalid': f.submitted && refSelectArea.invalid,
            'alert-warning':
              refSelectArea.invalid &&
              (refSelectArea.dirty ||
                refSelectArea.touched ||
                refSelectArea.untouched)
          }"
          [(ngModel)]="loanInfoModel.SelectArea"
          class="form-control input-text-css"
        >
          <option value="">Select Collection Area</option>
          <ng-container *ngFor="let area of areaData">
            <option
              *ngIf="area.Type.trim() == 'Collection Area'"
              [value]="area.Int_Id"
            >
              {{ area.Area_Name }}
            </option>
          </ng-container>
        </select>
      </div>
      <div class="col-md-3 p-1">
        <span> Collection Sub Area</span>
        <select
          name="SelectSubarea"
          id="SelectSubarea"
          #refSelectSubarea="ngModel"
          [disabled]="IsDisabled"
          [ngClass]="{
            'is-invalid': f.submitted && refSelectSubarea.invalid,
            'alert-warning':
              refSelectSubarea.invalid &&
              (refSelectSubarea.dirty ||
                refSelectSubarea.touched ||
                refSelectSubarea.untouched)
          }"
          [(ngModel)]="loanInfoModel.SelectSubarea"
          class="form-control input-text-css"
        >
          <option value="">Select Collection SubArea</option>
          <option *ngFor="let subarea of subAreaData" [value]="subarea.Int_Id">
            {{ subarea.Sub_Area_Name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row m-0" *ngIf="!IsDisabled">
      <div class="col-md-12 p-1">
        <span class="required-lable"> Loan Purpose </span>
        <textarea
          [(ngModel)]="loanInfoModel.LoanPurpose"
          rows="3"
          maxlength="150"
          #refLoanPurpose="ngModel"
          required
          [disabled]="IsDisabled"
          [ngClass]="{
            'is-invalid': f.submitted && refLoanPurpose.invalid,
            'alert-warning':
              refLoanPurpose.invalid &&
              (refLoanPurpose.dirty ||
                refLoanPurpose.touched ||
                refLoanPurpose.untouched)
          }"
          placeholder="Loan Purpose"
          name="LoanPurpose"
          id="LoanPurpose"
          class="form-control input-text-css"
        ></textarea>
      </div>
    </div>
    <div class="row m-0 mt-2" *ngIf="PageData.currentTab == 1">
      <div class="col-md-12 text-right p-1">
        <button
          type="button"
          (click)="SaveLoanInfo()"
          class="btn font-size-12 button-btn"
          *ngIf="IsSave && Status != 'C'"
          [class.spinner]="loading"
          [disabled]="!f.form.valid"
        >
          Save
        </button>
        <button
          type="button"
          (click)="onEditLoanInfo()"
          *ngIf="
            IsEdit &&
            (Status == 'R' ? (Revert_IsEdit == 1 ? true : false) : true)
          "
          class="btn font-size-12 button-btn"
          [disabled]="Status == 'C'"
          [class.spinner]="loading"
        >
          Edit
        </button>
        <button
          type="button"
          class="btn font-size-12 button-btn"
          (click)="SaveLoanInfo()"
          *ngIf="IsUpdate"
          [disabled]="!f.form.valid"
        >
          Update
        </button>
        <button
          type="button"
          (click)="CheckCustomerLoanInfo()"
          class="btn font-size-12 button-btn ml-3"
          *ngIf="!IsSave"
        >
          Next <i class="fa fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</form>

<!--TAT Report-->

<div class="table-responsive mt-3" *ngIf="dataSourceDetail?.data.length">
  <mat-table
    #TATTable
    [dataSource]="dataSourceDetail"
    matSort
    style="height: 400px; max-width: 100%; overflow: auto"
  >
    <ng-container matColumnDef="ProcessId">
      <mat-header-cell
        mat-sort-header
        class="grid-header"
        style="max-width: 50px"
        *matHeaderCellDef
      >
        #
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row; let i = index"
        class="grid-cell"
        style="max-width: 50px"
      >
        {{ i + 1 }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Process">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
        Process</mat-header-cell
      >
      <mat-cell *matCellDef="let row" class="grid-cell">
        {{ row.Process }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="ProcessExecutive">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
        Executive
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">
        {{ row.ProcessExecutive }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="ProcessStatus">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
        Status</mat-header-cell
      >
      <mat-cell *matCellDef="let row" class="grid-cell">
        {{ row.ProcessStatus }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="AssignedOn">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
        Assign On</mat-header-cell
      >
      <mat-cell *matCellDef="let row" class="grid-cell">
        {{ row.AssignedOn }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="CompletedOn">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
        Completed On</mat-header-cell
      >
      <mat-cell *matCellDef="let row" class="grid-cell">
        {{ row.CompletedOn }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="TAT_Hr">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
        TAT (Hrs)
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">{{
        row.TAT_Hr
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Remark">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
        Remark
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">
        {{ row.Remark }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="LatLong">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
        LatLong
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">
        <a
          target="_blank"
          *ngIf="row.LatLong != '' && row.LatLong != null && row.LatLong != ','"
          [href]="'http://maps.google.com/maps?q=' + row.LatLong"
        >
          <i class="fa-solid fa-location-dot"></i>
        </a>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedDetailColumns"></mat-header-row>
    <mat-row
      [ngClass]="row.ProcessStatus == 'Pending' ? 'Pending' : ''"
      *matRowDef="let row; columns: displayedDetailColumns"
    ></mat-row>
  </mat-table>
</div>
<!-- <mat-paginator #paginatorRef [pageSizeOptions]="[50]" showFirstLastButtons> </mat-paginator> -->
