<div class="formborder">
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="row m-0 mt-3">
    <div class="col-md-4 p-1" style="display: none;">
      <span class="required-lable">Product Category :</span>
      <select name="SelectProductCategory" id="SelectProductCategory" #refSelectProductCategory="ngModel" disabled
              [(ngModel)]="SelectProductCategory" class="form-control input-text-css" required>
        <option value=""> Select Product </option>
        <option *ngFor="let productCategory of ProductCategoryDropdown" [value]="productCategory.ProductCatId">
          {{productCategory.Product_Category}}
        </option>
      </select>
    </div>
    <div class="col-md-6 p-1">
      <span class="required-lable">Product Name :</span>
      <input required type="text" #refProductName="ngModel" name="ProductName" id="ProductName" placeholder="Product Name"
             [(ngModel)]="ProductName" class="form-control input-text-css" disabled>
    </div>
    <div class="col-md-6 p-1">
      <span class="required-lable">Product Short Name :</span>
      <input required type="text" #refProductShortName="ngModel" name="ProductShortName" id="ProductShortName" disabled
             [(ngModel)]="ProductShortName" placeholder="Product Short Name" class="form-control input-text-css">
    </div>
  </div>


  <div class="row m-0">
    <div class="col-md-3 p-1">
      <select name="Type" id="Type" class="form-control input-text-css" (change)="GetConfigList()"
              [(ngModel)]="SearchPolicyType">
        <option value="">Select Policy Type</option>
        <option value="LOAN RELATED">LOAN RELATED</option>
        <option value="COLLECTION RELATED">COLLECTION RELATED</option>
        <!--<option value="FEES RELATED">--><!--FEES RELATED--><!--</option>-->
        <option value="CUSTOMER RELATED">CUSTOMER RELATED</option>
        <!--<option value="REQUEST">REQUEST</option>-->
      </select>
    </div>
    <div class="col-md-9 p-1 text-right">
      <button class="btn  font-size-12 button-btn" (click)="OnOpenProductConfigModal()"> New Product Configration </button>
    </div>
  </div>
</div>

<div class="table-responsive mt-1">
  <mat-table [dataSource]="dataSource" matSort matTableExporter id="exportReport" style="height: 230px; max-width: 100%; overflow: auto;">
    <ng-container matColumnDef="Id">
      <mat-header-cell class="grid-header" style="max-width:50px" *matHeaderCellDef>#</mat-header-cell>
      <mat-cell *matCellDef="let row,let i = index" class="grid-cell" style="max-width: 50px;max-height:5px">
        {{ (i + 1) }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Type">
      <mat-header-cell class="grid-header" *matHeaderCellDef>Policy Type</mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Type}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="PolicyName">
      <mat-header-cell class="grid-header" *matHeaderCellDef>Policy Name</mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell"> {{row.PolicyName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Minimum">
      <mat-header-cell class="grid-header" *matHeaderCellDef>Minimum</mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Minimum}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Maximum">
      <mat-header-cell class="grid-header" *matHeaderCellDef>Maximum</mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell"> {{row.Maximum}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="ChargesAmount">
      <mat-header-cell class="grid-header" *matHeaderCellDef>ChargesAmount</mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">{{row.ChargesAmount}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="IsApplicable">
      <mat-header-cell class="grid-header" *matHeaderCellDef>IsApplicable</mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">{{row.IsApplicable == true ? 'Yes' : 'No'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="IsDeviation">
      <mat-header-cell class="grid-header" *matHeaderCellDef>IsDeviation</mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">{{row.IsDeviation == true ? 'Yes' : 'No'}}</mat-cell>
    </ng-container>


    <ng-container matColumnDef="View_Detail">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Action</mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">
        <i class="fas fa-lg fa-edit margin-right-4xs cursor-style" aria-label="true" (click)="ViewData(row)"
           [class.spinner]="loading"></i>
        <i class="fa fa-trash style-delete" (click)="DeleteData(row)" aria-label="true"
           [class.spinner]="loading"></i>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <mat-paginator #paginatorRef [pageSizeOptions]="[20,50]" showFirstLastButtons> </mat-paginator>
</div>


<!--<div class="col-md-12 row m-0 p-0">
  <div class="col-md-12 text-right p-1">
    <button type="button" (click)="onSavePolicy()" mat-raised-button class="text-default" [class.spinner]="loading">
      Save
    </button>
  </div>
</div>-->



<div class="modal fade in" id="AddProductConfigModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     data-backdrop="false" style="background-color: rgba(0, 0, 0, 0.5); overflow: auto;">
  <form #dbf="ngForm" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px;">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">Add Product Configration</h6>
          <button type="button" (click)="OnCloseProductConfigModal()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body ">
          <div class=" formborder">
            <div class="row m-0">
              <div class="col-md-4 p-1">
                <span class="required-lable"> Policy Type: </span>
              </div>
              <div class="col-md-4 p-1">
                <select name="SelectPolicyType" id="SelectPolicyType" #refSelectPolicyType="ngModel" (change)="ChangePolicyType()" [disabled]="Edit"
                        [ngClass]="{ 'is-invalid': dbf.submitted && refSelectPolicyType.invalid, 'alert-warning': refSelectPolicyType.invalid  && (refSelectPolicyType.dirty || refSelectPolicyType.touched || refSelectPolicyType.untouched) }"
                        [(ngModel)]="SelectPolicyType" class="form-control input-text-css" required>
                  <option value="">Select Policy Type</option>
                  <option value="LOAN RELATED">LOAN RELATED</option>
                  <option value="COLLECTION RELATED">COLLECTION RELATED</option>
                  <!--<option value="FEES RELATED">FEES RELATED</option>-->
                  <option value="CUSTOMER RELATED">CUSTOMER RELATED</option>
                  <!--<option value="REQUEST">--><!--REQUEST--><!--</option>-->
                  <option value="CLOSER" *ngIf="IsCloserConfig==true">CLOSURE</option>
                  <option value="LoginFee">Login Fee</option>
                  <!-- <option value="LEGAL">LEGAL</option> -->
                </select>
              </div>
            </div>
            <div class="row m-0" *ngIf="SelectPolicyType=='CLOSER'">
              <div class="col-md-4 p-1">
                <span style="font-weight:bold"> Broken Period Interest Charging Method: </span>
              </div>
              <div class="col-md-4 p-1" *ngIf="SelectPolicyType=='CLOSER'">
                <!--<span style="font-weight:bold"> Broken Period Interest  </span>-->
                <!--<mat-checkbox [(ngModel)]="Broken_Period_Interest" id="Broken_Period_Interest" name="Broken_Period_Interest">
                </mat-checkbox>-->
                <select name="Broken_Period_Interest" id="Broken_Period_Interest" #refBroken_Period_Interest="ngModel" [disabled]="Edit"
                        [ngClass]="{ 'is-invalid': dbf.submitted && refBroken_Period_Interest.invalid, 'alert-warning': refBroken_Period_Interest.invalid  && (refBroken_Period_Interest.dirty || refBroken_Period_Interest.touched || refBroken_Period_Interest.untouched) }"
                        [(ngModel)]="Broken_Period_Interest" class="form-control input-text-css" required>

                  <option value="1">Days as Per Effective Date</option>
                  <option value="0">Full Month As Per Days</option>
                  <option value="2">Full Month As Per Plan</option>
                  <option value="3">No Broken Period Interest</option>
                  <option value="4">Full Term Period Interest</option>

                </select>
              </div>

            </div>
          </div>


          <table class="responstable formborder mt-1" id="MainTable" *ngIf="PolicyDetail.length>0 &&  SelectPolicyType!='CLOSER' && SelectPolicyType!='LoginFee' && SelectPolicyType!='LEGAL'">
            <tr>
              <th>Policy</th>
              <th *ngIf="SelectPolicyType=='FEES RELATED' || SelectPolicyType=='REQUEST'">Charges Amount</th>
              <th *ngIf="SelectPolicyType!='FEES RELATED' && SelectPolicyType!='REQUEST'">Minimum</th>
              <th *ngIf="SelectPolicyType!='FEES RELATED' && SelectPolicyType!='REQUEST'">Maximum</th>
              <th>Applicable</th>
              <th>Deviation Allowed</th>
              <th></th>
            </tr>
            <tr *ngFor="let Policy of PolicyDetail; let i=index">
              <td>
                <select name="Name{{i}}" id="Name{{i}}" #refName="ngModel" *ngIf="SelectPolicyType!='FEES RELATED'" (change)="onChangePolicyName(Policy,i)" [disabled]="Edit"
                        [ngClass]="{ 'is-invalid': dbf.submitted && refName.invalid, 'alert-warning': refName.invalid  && (refName.dirty || refName.touched || refName.untouched) }"
                        [(ngModel)]="Policy.name" class="form-control input-text-css" required>
                  <option value=""> Select Policy </option>
                  <option *ngFor="let PolicyName of PolicyNameList" [value]="PolicyName.Id">{{PolicyName.Value}}</option>
                </select>

                <select name="Name{{i}}" id="Name{{i}}" #refName="ngModel" *ngIf="SelectPolicyType=='FEES RELATED'" (change)="onChangePolicyName(Policy,i)" [disabled]="Edit"
                        [ngClass]="{ 'is-invalid': dbf.submitted && refName.invalid, 'alert-warning': refName.invalid  && (refName.dirty || refName.touched || refName.untouched) }"
                        [(ngModel)]="Policy.name" class="form-control input-text-css" required>
                  <option value=""> Select Policy </option>
                  <option *ngFor="let PolicyName of PolicyNameList" [value]="PolicyName.Id">{{PolicyName.ChagesHead}}</option>
                </select>
              </td>
              <td *ngIf="SelectPolicyType=='FEES RELATED' || SelectPolicyType=='REQUEST'">
                <input type="number" [(ngModel)]="Policy.ChargesAmount" [disabled]="SelectPolicyType=='FEES RELATED'" style=" height: 22px;" name="ChargesAmount{{i}}" id="ChargesAmount{{i}}" class="form-control input-text-css">
              </td>
              <td *ngIf="SelectPolicyType!='FEES RELATED' && SelectPolicyType!='REQUEST'">
                <input type="number" [(ngModel)]="Policy.Minimum" (change)="onChangeMinMax(Policy,i)" style=" height: 22px;" name="Minimum{{i}}" id="Minimum{{i}}" class="form-control input-text-css">
              </td>
              <td *ngIf="SelectPolicyType!='FEES RELATED' && SelectPolicyType!='REQUEST'">
                <input type="number" [(ngModel)]="Policy.Maximum" (change)="onChangeMinMax(Policy,i)" style=" height: 22px;" name="Maximum{{i}}" id="Maximum{{i}}" class="form-control input-text-css">
              </td>

              <td>
                <mat-checkbox class="example-margin " name="Applicable{{i}}" id="Applicable{{i}}" [(ngModel)]="Policy.Applicable" (change)="onChangeApplicable(Policy,i)">
                </mat-checkbox>
              </td>
              <td>
                <mat-checkbox class="example-margin" name="IsDeviation{{i}}" id="IsDeviation{{i}}" [disabled]="!Policy.Applicable" [(ngModel)]="Policy.IsDeviation">
                </mat-checkbox>
              </td>
              <td class="add-remove">
                <button type="button" class="remove" (click)="removeData(i)" [ngClass]="{'no-head':i!=0}"
                        *ngIf="PolicyDetail.length>1 && Edit==false">
                  -
                </button>
                <button type="button" class="addmore" (click)="addMoreData(i)" [disabled]="!dbf.form.valid"
                        [ngClass]="{'both':PolicyDetail.length>1,'no-head':i!=0}" *ngIf="i == PolicyDetail.length-1 && Edit==false">
                  +
                </button>
              </td>
            </tr>
          </table>

          <form #df="ngForm" novalidate *ngIf="SelectPolicyType=='CLOSER'">
            <div class="row m-0 add-remove formborder mt-1">
              <div class="col-md-12 row m-0 p-0">
                <div class="col-md-3 p-1">
                  <span style="font-weight:bold"></span>
                </div>
                <div class="col-md-2 p-1">
                  <span style="font-weight:bold">From Month</span>
                </div>
                <div class="col-md-2 p-1">
                  <span style="font-weight:bold">To Month</span>
                </div>
                <div class="col-md-2 p-1">
                  <span style="font-weight:bold">Charges Value</span>
                </div>
                <div class="col-md-1 p-1">
                  <span style="font-weight:bold">Charges ApplyOn</span>
                </div>
              </div>

              <div class="col-md-12 row m-0 p-0" *ngFor="let charges of pre_CloserCharges;let i=index">
                <div class="col-md-3 p-1">
                  <span>{{charges.Name}} </span>
                </div>
                <div class="col-md-2 p-1">
                  <span class="greaterThan">></span>
                  <input type="number" [(ngModel)]="charges.From_Values" placeholder="From"
                         name="From_Values{{i}}" id="From_Values{{i}}" class="pl-3 form-control input-text-css"
                         required #refFrom_Values="ngModel" readonly
                         [ngClass]="{ 'is-invalid': df.submitted && refFrom_Values.invalid,
                           'alert-warning': refFrom_Values.invalid  &&
                           (refFrom_Values.dirty || refFrom_Values.touched || refFrom_Values.untouched)
                             }">
                </div>
                <div class="col-md-2 p-1">
                  <input type="number" [(ngModel)]="charges.To_Values" placeholder="To"
                         name="To_Values{{i}}" id="To_Values{{i}}" (change)="ToValueChange(i)" class="form-control input-text-css"
                         required #refTo_Values="ngModel" [min]="charges.Name=='Lock-IN Period'?0:charges.From_Values"
                         [ngClass]="{ 'is-invalid': df.submitted && refTo_Values.invalid, 'alert-warning': refTo_Values.invalid  && (refTo_Values.dirty || refTo_Values.touched || refTo_Values.untouched) }">
                </div>
                <div class="col-md-2 p-1">
                  <input type="number" [(ngModel)]="charges.Charge_Values" [disabled]="charges.Name=='Lock-IN Period'" placeholder="Charges Value"
                         name="ChargeValues{{i}}" id="ChargeValues{{i}}" class="form-control input-text-css"
                         required #refChargeValues="ngModel"
                         [ngClass]="{ 'is-invalid': df.submitted && refChargeValues.invalid, 'alert-warning': refChargeValues.invalid  && (refChargeValues.dirty || refChargeValues.touched || refChargeValues.untouched) }">
                </div>
                <div class="col-md-1 p-1">
                  <input type="text" [(ngModel)]="charges.chargeApplyOn" name="chargeApplyOn{{i}}" id="chargeApplyOn{{i}}"
                         class="form-control input-text-css" value="POS" readonly>
                </div>

                <button type="button" class="remove" (click)="removeSlab(i)" [ngClass]="{'no-head':i!=0}"
                        *ngIf="pre_CloserCharges.length>1">
                  -
                </button>
                <button type="button" class="addmore" (click)="addMoreSlab(i)" [disabled]="!df.form.valid"
                        [ngClass]="{'both':pre_CloserCharges.length>1,'no-head':i!=0}" *ngIf="i == pre_CloserCharges.length-1">
                  +
                </button>
              </div>
            </div>
            <div class="modal-footer" *ngIf="PolicyDetail.length>0 || SelectPolicyType=='CLOSER'">
              <button type="button" (click)="OnCloseProductConfigModal()" class="btn  font-size-12 button-btn" data-dismiss="modal">
                Cancel
              </button>
              <button type="button" (click)="onSavePolicy()" *ngIf="Edit==false" class="btn  font-size-12 button-btn"
                      [class.spinner]="loading" [disabled]="!df.form.valid">
                Save
              </button>
            </div>
          </form>

          <form #df="ngForm" novalidate *ngIf="SelectPolicyType=='LoginFee'">
            <div class="row m-0 add-remove formborder mt-1">
              <div class="col-md-12 row m-0 p-0">
                <div class="col-md-3 p-1">
                  <span style="font-weight:bold"></span>
                </div>
                <div class="col-md-2 p-1">
                  <span style="font-weight:bold">From Loan Amount</span>
                </div>
                <div class="col-md-2 p-1">
                  <span style="font-weight:bold">To Loan Amount</span>
                </div>
                <div class="col-md-2 p-1">
                  <span style="font-weight:bold">Charges Type</span>
                </div>
                <div class="col-md-1 p-1">
                  <span style="font-weight:bold">Charges Value</span>
                </div>
              </div>

              <div class="col-md-12 row m-0 p-0" *ngFor="let charges of LoginFeeCharges;let i=index">
                <div class="col-md-3 p-1">
                  <span>{{charges.Name}} </span>
                </div>
                <div class="col-md-2 p-1">
                  <span class="greaterThan">></span>
                  <input type="number" [(ngModel)]="charges.From_Values" placeholder="From"
                         name="From_Values1{{i}}" id="From_Values1{{i}}" class="pl-3 form-control input-text-css"
                         required #refFrom_Values1="ngModel" readonly
                         [ngClass]="{ 'is-invalid': df.submitted && refFrom_Values1.invalid,
               'alert-warning': refFrom_Values1.invalid  &&
               (refFrom_Values1.dirty || refFrom_Values1.touched || refFrom_Values1.untouched)
                 }">
                </div>
                <div class="col-md-2 p-1">
                  <input type="number" [(ngModel)]="charges.To_Values" placeholder="To"
                         name="To_Values1{{i}}" id="To_Values1{{i}}" (change)="ToValueChangeLoginFee(i)" class="form-control input-text-css"
                         required #refTo_Values1="ngModel" [min]="charges.From_Values"
                         [ngClass]="{ 'is-invalid': df.submitted && refTo_Values1.invalid, 'alert-warning': refTo_Values1.invalid  && (refTo_Values1.dirty
                           || refTo_Values1.touched || refTo_Values1.untouched) }">
                </div>
                <div class="col-md-2 p-1">
                  <select name="chargeType1{{ i }}"
                          id="chargeType1{{ i }}"
                          [(ngModel)]="charges.ChargeType"
                          required #refchargeType1="ngModel"
                          [ngClass]="{ 'is-invalid': df.submitted && refchargeType1.invalid, 'alert-warning': refchargeType1.invalid
            && (refchargeType1.dirty || refchargeType1.touched || refchargeType1.untouched) }"
                          class="form-control input-text-css">
                    <option value="Fixed">Fixed</option>
                    <option value="Percentage">Percentage</option>
                  </select>
                </div>
                <div class="col-md-2 p-1">
                  <input type="number" [(ngModel)]="charges.Charge_Values" placeholder="Charges Value"
                         name="ChargeValues1{{i}}" id="ChargeValues1{{i}}" class="form-control input-text-css"
                         required #refChargeValues1="ngModel"
                         [ngClass]="{ 'is-invalid': df.submitted && refChargeValues1.invalid, 'alert-warning': refChargeValues1.invalid
                           && (refChargeValues1.dirty || refChargeValues1.touched || refChargeValues1.untouched) }">
                </div>



                <button type="button" class="remove" (click)="removeSlabLoginFee(i)" [ngClass]="{'no-head':i!=0}"
                        *ngIf="LoginFeeCharges.length>1">
                  -
                </button>
                <button type="button" class="addmore" (click)="addMoreSlabLoginFee(i)" [disabled]="!df.form.valid"
                        [ngClass]="{'both':LoginFeeCharges.length>1,'no-head':i!=0}" *ngIf="i == LoginFeeCharges.length-1">
                  +
                </button>
              </div>
            </div>
            <div class="modal-footer" *ngIf="PolicyDetail.length>0 || SelectPolicyType=='LoginFee'">
              <button type="button" (click)="OnCloseProductConfigModal()" class="btn  font-size-12 button-btn" data-dismiss="modal">
                Cancel
              </button>
              <button type="button" (click)="onSavePolicy()" *ngIf="Edit==false" class="btn  font-size-12 button-btn"
                      [class.spinner]="loading" [disabled]="!df.form.valid">
                Save
              </button>
            </div>
          </form>
          <table class="responstable formborder" id="MainTable" *ngIf="SelectPolicyType == 'LEGAL'">
            <tr>
              <th>Min DPD Days</th>
              <th>Min Overdues Amount</th>
            </tr>
            <tr>
              <td>
                <input type="number" [(ngModel)]="Legal.DPDDays" #refDPDDays="ngModel" name="DPDDays" id="DPDDays"
                       class="form-control input-text-css" required [ngClass]="{ 'is-invalid': dbf.submitted && refDPDDays.invalid, 'alert-warning': refDPDDays.invalid  && (refDPDDays.dirty || refDPDDays.touched || refDPDDays.untouched) }">
              </td>
              <td>
                <input type="number" [(ngModel)]="Legal.OverdueAmount" #refOverdueAmount="ngModel" name="OverdueAmount" id="OverdueAmount"
                       class="form-control input-text-css" required [ngClass]="{ 'is-invalid': dbf.submitted && refOverdueAmount.invalid, 'alert-warning': refOverdueAmount.invalid  && (refOverdueAmount.dirty || refOverdueAmount.touched || refOverdueAmount.untouched) }">
              </td>
            </tr>
          </table>

        </div>
        <div class="modal-footer" *ngIf="PolicyDetail.length>0 && SelectPolicyType!='CLOSER' && SelectPolicyType!='LoginFee'">
          <button type="button" (click)="OnCloseProductConfigModal()" class="btn  font-size-12 button-btn" data-dismiss="modal">
            Cancel
          </button>

          <button type="button" (click)="onSavePolicy()" *ngIf="Edit==false && SelectPolicyType != 'LEGAL'" class="btn  font-size-12 button-btn"
                  [class.spinner]="loading" [disabled]="!dbf.form.valid">
            Save
          </button>

          <button type="button" (click)="onSavePolicy()" *ngIf="Edit==true && SelectPolicyType != 'LEGAL'" class="btn  font-size-12 button-btn"
                  [class.spinner]="loading" [disabled]="!dbf.form.valid">
            Update
          </button>

          <button type="button" (click)="onSaveLegal()" *ngIf="SelectPolicyType == 'LEGAL'" class="btn  font-size-12 button-btn"
                  [class.spinner]="loading" [disabled]="!dbf.form.valid">
            {{ LegalDPDDays && LegalDPDDays > 0 ? 'Update' : 'Save' }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

