import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { constantUrl } from "../../Shared/constantUrl";
import { LmsService } from "../services/lms.service";
import { DialogService } from "../../Shared/dialog.service";
import { MasterService } from "../../Shared/app.Masters.Service";

import { ToWords } from "to-words";
import { float } from "html2canvas/dist/types/css/property-descriptors/float";

@Component({
  selector: "app-lms-Payment-Initiation-detail",
  templateUrl: "./lms-Payment-Initiation-detail.component.html",
  styleUrls: ["./lms-Payment-Initiation-detail.component.scss"],
})
export class LmsPaymentInitiationDetailComponent implements OnInit {
  currentUser: any;
  CurrentDate: any = new Date();
  showSpinner: boolean = false;
  loading: boolean = false;
  IsFullyPayment: boolean = false;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  accountTagDropdown: any[] = [];
  LoanId: any;
  customerId: any;
  SelectPayData: any = {};
  ChangeFirstEMIDate: boolean = false;
  customerDetail: any;
  ProcessHeadList: any[] = [];
  chargesHeadDropdown: any[] = [];
  PreEMIInterestPayment: any;
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  CrcApplicationDocUrl: any = constantUrl.CrcApplicationDocUrl;
  CustomerDataSource: any;
  @Input() loanSummary: any;
  ChargesHeadDataSource: any;
  displayedColumnsPreEMINew: any;
  totalvoucherCalculator: any = {
    totalCR: parseFloat("0"),
    totalDR: parseFloat("0"),
    totalCRTX: parseFloat("0"),
    totalDRTX: parseFloat("0"),
    totalCRInWord: new ToWords().convert(parseFloat("0")),
    totalDRInWord: new ToWords().convert(parseFloat("0")),
  };
  totalvoucherCalculator1: any = {
    totalCR: parseFloat("0"),
    totalDR: parseFloat("0"),
    totalCRTX: parseFloat("0"),
    totalDRTX: parseFloat("0"),
    totalCRInWord: new ToWords().convert(parseFloat("0")),
    totalDRInWord: new ToWords().convert(parseFloat("0")),
  };
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  voucherModel1: any = { Voucher: {}, Voucher_Detail: [] };
  ChargesDataSource: any;
  PreEMIInterestPaymentNew: any;

  PreEmiBalance: any;
  EmiDataSource: any;
  PDCDataSource: any;
  displayedCustomerColumns: string[] = [
    "Customer",
    "FatherName",
    "CustomerType",
    "GenderAge",
    "PhoneNo",
  ];
  displayedChargesHeadColumns: string[] = ["Charges", "Amount", "ReceiptOn"];
  displayedChargesColumns: string[] = [
    "UpfrontCharges",
    "Deduction",
    "Receipt",
  ];
  displayedEMIColumns: string[] = [
    "EMI_Pattern",
    "NoOfEMI",
    "EMIAmount",
    "TotalAmount",
  ];
  displayedPDCColumns: string[] = [
    "Customer",
    "Bankname",
    "Branch",
    "AccountNo",
    "IFSCCode",
    "AmountDisbursment",
  ];
  Status: any;
  Disbursment: any;
  TotalDisbursAmt: any;

  BankModel: any = [];
  EMIDate: any;
  FinalFirstEMIDate: any;
  BankDetailDropdown: any[] = [];
  DealerDropdown: any[] = [];
  PartnerDropdown: any[] = [];
  OtherDropdown: any[] = [];
  totalCalculator: any;
  minFinalFirstEMIDate: any;
  CustomerBorrower: any;
  DataSourceDisbursment: any;
  displayedColumnsPreEMI: string[] = [];
  AdvancePayment: any;
  IsLoanDeduct: any = "1";
  TotalInterest: any = 0;
  displayedColumnsDisbursment: string[] = [];
  DisbursedAmt: any = 0;
  Disbursment_Payment_Detail: any;
  _RestAmt: any;
  InterestpayAmount: any;
  FinalAmountPay: any;
  InterestDate: any;
  InterestDays: any;
  RemainingAmount: any = 0;
  AccountHead_For_ChargesHead: any;
  PendingDisbursmentAmount: any = 0;
  LoanTenureFreq: any = "Months";
  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: DialogService,
    private _MasterService: MasterService,
    private lmsService: LmsService
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.LoanId = this.encdec.decrypt(decodeURIComponent(param.get("id"))); // encdec.decrypt(param.get("appId"));
      this.PendingDisbursmentAmount = parseFloat(
        this.encdec.decrypt(decodeURIComponent(param.get("pendingAmount")))
      ); // encdec.decrypt(param.get("appId"));

      this.Status = param.get("status");
      console.log("sss", this.Status);
    });
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Payment Initiation Detail");

    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    if (this.loanSummary != "" && this.loanSummary != undefined) {
      //console.log("this.loanSummary.LoanId", this.loanSummary.LoanId);
      this.LoanId = this.loanSummary.LoanId;
    }
    this.getCustomerDetail();

    /*this.BankModel.push({
      Type: "",
      Customer: "",
      SubType: "",
      Amount:"",
    });*/
  }
  getCustomerDetail() {
    this.showSpinner = true;
    this.lmsService
      .LMS_Get_DV_Details_Print({ LoanAcId: this.LoanId.toString() })
      .subscribe((res: any) => {
        console.log("res", res);
        this.showSpinner = false;

        this.customerDetail = res.Item1[0];
        console.log("customer details", this.customerDetail);

        if (
          this.customerDetail?.EMI_Type == "Weekly" ||
          this.customerDetail?.EMI_Type == "Daily" ||
          this.customerDetail?.EMI_Type == "Fortnightly" ||
          this.customerDetail?.EMI_Type == "ByWeekly" ||
          this.customerDetail?.EMI_Type == "FourthWeekly" ||
          this.customerDetail?.EMI_Type == "SingleEMI"
        )
          this.LoanTenureFreq = "Days";
        else this.LoanTenureFreq = "Months";

        this.CustomerDataSource = new MatTableDataSource(res.Item2);
        this.ProcessHeadList = JSON.parse(JSON.stringify(res.Item3));
        this.ProcessHeadList.forEach((x) => {
          x.Deduction = 0;
          x.Receipt = 0;
          if (x.Type == "DR") x.Deduction = x.Amount;
          if (x.Type == "CR") x.Receipt = x.Amount;
          x.Total = x.Deduction + x.Receipt;
        });
        this.ChargesDataSource = new MatTableDataSource(this.ProcessHeadList);
        this.ChargesHeadDataSource = new MatTableDataSource(res.Item4);
        this.EmiDataSource = new MatTableDataSource(res.Item5);
        this.PDCDataSource = new MatTableDataSource(
          res.Item6.filter((x) => x.PdcType == "Disbursment")
        );
        this.customerDetail.Application_FirstEMIDate = new Date(
          this.customerDetail.Application_FirstEMIDate
        );
        console.log(
          "this.customerDetail.Application_FirstEMIDate",
          this.customerDetail.Application_FirstEMIDate
        );
        this.Disbursment = res.Item6.filter(
          (item) => item.PdcType == "Disbursment"
        )[0];
        this.CustomerBorrower = res.Item2.filter(
          (x) => x.CustomerType == "Hirer"
        )[0];

        this.TotalDisbursAmt = res.Item3.filter(
          (item) => item.ChargeHeadId == 28
        )[0].Amount;
        //console.log("this.TotalDisbursAmt", this.TotalDisbursAmt);
        // var PendingDisbursmentAmount = this.PendingDisbursmentAmount;
        if (this.customerDetail.IsAmortization == 1) {
          this.GetPaymentDetailByLoan();

          this.FinalFirstEMIDate = new Date();
          this.setFinalFirstEmiDate(this.FinalFirstEMIDate);
        } else {
          this.getPaymentDetailByLoanWithoutAmortization();
        }
        this.GetBankDetailCustomerPartnerAccount();
      });
  }

  calculateDeductionTotal() {
    if (this.ChargesDataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.ChargesDataSource.filteredData.length; i++) {
        sum += Number(this.ChargesDataSource.filteredData[i]["Deduction"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateAmountTotal() {
    if (this.ChargesHeadDataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.ChargesHeadDataSource.filteredData.length; i++) {
        sum += Number(this.ChargesHeadDataSource.filteredData[i]["Amount"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateReceiptTotal() {
    if (this.ChargesDataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.ChargesDataSource.filteredData.length; i++) {
        sum += Number(this.ChargesDataSource.filteredData[i]["Receipt"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTotal() {
    if (this.ChargesDataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.ChargesDataSource.filteredData.length; i++) {
        sum += Number(this.ChargesDataSource.filteredData[i]["Total"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTotalEMI() {
    if (this.EmiDataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.EmiDataSource.filteredData.length; i++) {
        sum += Number(this.EmiDataSource.filteredData[i]["NoOfEMI"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTotalAmount() {
    if (this.EmiDataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.EmiDataSource.filteredData.length; i++) {
        sum += Number(this.EmiDataSource.filteredData[i]["TotalAmount"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  Print() {
    window.print();
  }
  getPaymentDetailByLoanWithoutAmortization() {
    this.lmsService
      .LMS_PreEMI_Interest_Statement_Loan({
        loanId: this.customerDetail.LoanId,
      })
      .subscribe((res: any) => {
        this.PreEMIInterestPaymentNew = new MatTableDataSource(res);

        this.displayedColumnsPreEMINew = [
          "Voucher_Date",
          "ChargeHeadCategory",
          "Amount",
          "Balance",
          "Days",
          "InterestDue",
          "InterestReceived",
          "InterestBalance",
        ];
        console.log("jjgjg", res[res.length - 1].InterestBalance);
        if (res[res.length - 1].InterestBalance > 0) {
          this.InterestpayAmount = res[res.length - 1].InterestBalance;
        } else {
          this.InterestpayAmount = 0;
        }
      });
  }
  GetPaymentDetailByLoan() {
    this.displayedColumnsDisbursment = [
      "VoucherId",
      "Voucher_Date",
      "Voucher_No",
      "Transaction_Date",
      "Voucher_Cheque_No",
      "Amount",
      "Voucher_CreateOn",
      "Voucher_CreateBy",
      "Account_CR",
      "InterestAmount",
    ];

    this.lmsService
      .Get_Payment_Detail_By_Loan({ LoanId: this.customerDetail.LoanId })
      .subscribe((res: any) => {
        console.log("Get_Payment_Detail_By_Loan", res);
        this.AdvancePayment = JSON.parse(JSON.stringify(res.Item1));
        var PreEmiPayment = [];
        PreEmiPayment = JSON.parse(JSON.stringify(res.Item2));
        this.AdvancePayment.forEach((obj: any) => {
          obj.InterestAmount = 0;
        });

        //if (PreEmiPayment.length > 0) {
        //  this.PreEMIInterestPayment = new MatTableDataSource(PreEmiPayment);

        //  this.displayedColumnsPreEMI = [
        //    "VoucherId", "Voucher_Date", "Voucher_No", "Transaction_Date",
        //    "Voucher_CreateOn", "Voucher_CreateBy", "AmountDR","AmountCR"
        //  ];

        //}
        this.DataSourceDisbursment = new MatTableDataSource(
          this.AdvancePayment
        );
        var EMIDate = this.customerDetail.Application_FirstEMIDate;
        this.GetAdvancePaymentInterest(new Date(EMIDate));
        res.Item1.forEach((obj: any) => {
          this.DisbursedAmt = this.DisbursedAmt + parseFloat(obj.Amount) || 0;
        });
        console.log("this.DisbursedAmt", this.DisbursedAmt);
        this._RestAmt = this.DisbursedAmt
          ? this.TotalDisbursAmt - this.DisbursedAmt
          : this.TotalDisbursAmt;
        this.RemainingAmount = parseFloat(this._RestAmt);

        var InterestDataSource = [];
        this.PreEmiBalance = 0;
        if (this.AdvancePayment.length > 0) {
          this.AdvancePayment.forEach((obj: any) => {
            InterestDataSource.push({
              VoucherDate: obj.Voucher_Date,
              PaymentDone: obj.Amount,
              PreEMIDue: obj.InterestAmount,
              PreEMIRecvd: 0,
              InterestDays: obj.InterestDays,
              PreEmiBalance: this.PreEmiBalance + obj.InterestAmount,
              Voucher_CreateOn: obj.Voucher_CreateOn,
              Voucher_CreateBy: obj.Voucher_CreateBy,
            });
            this.PreEmiBalance = this.PreEmiBalance + obj.InterestAmount;
          });
        }
        if (PreEmiPayment.length > 0) {
          PreEmiPayment.forEach((obj: any) => {
            InterestDataSource.push({
              VoucherDate: obj.Voucher_Date,
              PaymentDone: 0,
              PreEMIDue: obj.AmountDR,
              PreEMIRecvd: obj.AmountCR,
              PreEmiBalance:
                this.PreEmiBalance +
                parseFloat(obj.AmountDR) -
                parseFloat(obj.AmountCR),
              Voucher_CreateOn: obj.Voucher_CreateOn,
              Voucher_CreateBy: obj.Voucher_CreateBy,
            });
            this.PreEmiBalance =
              this.PreEmiBalance +
              parseFloat(obj.AmountDR) -
              parseFloat(obj.AmountCR);
          });
        }
        this.PreEMIInterestPayment = new MatTableDataSource(InterestDataSource);

        this.displayedColumnsPreEMI = [
          "VoucherDate",
          "Voucher_CreateOn",
          "Voucher_CreateBy",
          "PaymentDone",
          "InterestDays",
          "PreEMIDue",
          "PreEMIRecvd",
          "PreEmiBalance",
        ];
      });
  }
  GetAdvancePaymentInterest(EMIDate) {
    var EDate = Object.assign(EMIDate);

    if (this.AdvancePayment) {
      if (this.customerDetail.EMI_Type == "Monthly") {
        EDate.setMonth(EDate.getMonth() - 1);
      } else if (this.customerDetail.EMI_Type == "ByMonthly") {
        EDate.setMonth(EDate.getMonth() - 2);
      } else if (this.customerDetail.EMI_Type == "Quarterly") {
        EDate.setMonth(EDate.getMonth() - 3);
      } else if (this.customerDetail.EMI_Type == "HalfYearly") {
        EDate.setMonth(EDate.getMonth() - 6);
      } else if (this.customerDetail.EMI_Type == "Yearly") {
        EDate.setMonth(EDate.getMonth() - 12);
      } else if (this.customerDetail.EMI_Type == "Weekly") {
        EDate.setDate(EDate.getDate() - 7);
      } else if (this.customerDetail.EMI_Type == "Fortnightly") {
        EDate.setDate(EDate.getDate() - 15);
      } else if (this.customerDetail.EMI_Type == "ByWeekly") {
        EDate.setDate(EDate.getDate() - 14);
      } else if (this.customerDetail.EMI_Type == "FourthWeekly") {
        EDate.setDate(EDate.getDate() - 28);
      } else {
        EDate.setDate(EDate.getDate() - 1);
      }
      this.InterestDate = EDate;
      this.AdvancePayment.forEach((obj: any) => {
        if (new Date(obj.VoucherDate) > EDate) {
          obj.InterestAmount = 0;
          obj.InterestDays = 0;
        } else {
          var Days = this.getDiffDays(new Date(obj.VoucherDate), EDate);
          obj.InterestAmount = Math.round(
            ((obj.Amount * this.customerDetail.IRR) /
              100 /
              this.currentUser.DaysInYear) *
              Days
          );
          obj.InterestDays = Days;
        }
      });
    }
  }
  getDiffDays(startDate, endDate) {
    if (startDate > endDate) {
      return 0;
    } else {
      return Math.ceil(Math.abs(startDate - endDate) / (1000 * 60 * 60 * 24));
    }
  }
  //addBankDetail() {
  //  if (this.BankModel.length > 0) {
  //    let _prev = this.BankModel[this.BankModel.length - 1];
  //    console.log("_prev", _prev);

  //    if (_prev.SubType == 'Cash') {
  //      if (_prev.CustomerName == "" || _prev.Type == "" || _prev.Amount == "" || _prev.SubType == "") {
  //        this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Fill Required Field to Add More.", ...this.configSuccess });
  //        return;
  //      }
  //    }
  //    else {
  //      if (_prev.Type == 'Dealer') {
  //        if (_prev.Type == "" || _prev.Amount == "" || _prev.Dealer_Name == "") {
  //          this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Fill Required Field to Add More.", ...this.configSuccess });
  //          return;
  //        }
  //      }
  //      else if (_prev.SubType == 'Other') {
  //        if (_prev.Type == "" || _prev.CustomerType == "" || _prev.Amount == "" || _prev.AcHead == "") {
  //          this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Fill Required Field to Add More.", ...this.configSuccess });
  //          return;
  //        }
  //      }
  //      else {
  //        if (_prev.Type == "" || _prev.CustomerType == "" || _prev.Amount == "" || _prev.Customer == "") {
  //          this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Fill Required Field to Add More.", ...this.configSuccess });
  //          return;
  //        }
  //      }
  //    }
  //  }
  //  this.BankModel.push({
  //    Id: 0,
  //    LoanId: this.customerDetail.LoanId,
  //    Type: "",
  //    Customer: "",
  //    SubType: "",
  //    Amount: "",
  //    PayDate: new Date(),
  //    CreatedBy: this.currentUser.userId,
  //    IsHold: false,
  //    Voucher_Id: 0,
  //    disable: false
  //  });
  //  this.FinalFirstEMIDate = new Date(Math.max(...this.BankModel.map(e => new Date(e.PayDate))));
  //  this.setFinalFirstEmiDate(this.FinalFirstEMIDate);
  //}

  //removeBankDetail(index: any) {
  //  this.BankModel.splice(index, 1);
  //  this.getTotalCalculation();
  //  if (Math.max(...this.BankModel.map(e => new Date(e.PayDate))) == 0) {
  //    this.FinalFirstEMIDate = this.customerDetail.Application_FirstEMIDate;
  //    this.setFinalFirstEmiDate(this.FinalFirstEMIDate);
  //    this.GetAdvancePaymentInterest(new Date(this.FinalFirstEMIDate));
  //  } else {
  //    this.FinalFirstEMIDate = new Date(Math.max(...this.BankModel.map(e => new Date(e.PayDate))));
  //    this.setFinalFirstEmiDate(this.FinalFirstEMIDate);
  //    this.GetAdvancePaymentInterest(new Date(this.FinalFirstEMIDate));
  //  }
  //}

  GetBankDetailCustomerPartnerAccount() {
    this.showSpinner = true;
    this.lmsService
      .Get_BankDetail_Customer_Partner_Account({
        LoanId: this.customerDetail.LoanId,
      })
      .subscribe((res: any) => {
        console.log("Get_BankDetail_Customer_Partner_Account", res);
        this.BankDetailDropdown = res.Item1;
        this.DealerDropdown = res.Item2;
        this.PartnerDropdown = res.Item4;
        this.OtherDropdown = res.Item3;

        if (this.Status == "Pending") {
          this.lmsService
            .Get_Payment_Initiation_Detail({ LoanId: this.LoanId })
            .subscribe((res: any) => {
              if (res.length > 0) {
                this.showSpinner = false;

                console.log("Get_Disbursment_Payment_Detail", res);
                this.Disbursment_Payment_Detail = res[0];
                this.BankModel = res;
                var PaymentDoneCount = 0;
                var PendingPayment = 0;
                this.BankModel.forEach((data: any) => {
                  if (data.Voucher_Id > 0) {
                    data.disable = true;
                    PaymentDoneCount = PaymentDoneCount + 1;
                  } else {
                    data.disable = false;
                    PendingPayment = PendingPayment + data.Amount;
                  }
                  if (data.SubType == "Dealer") {
                    let _DData = this.DealerDropdown.filter(
                      (x) => x.Id == data.Reference_Id
                    )[0];
                    console.log("_DData", _DData);
                    // data.disable = true;
                    // data.AcHead = _DData.Account_Name;
                    data.AcHead =
                      _DData.AccountId + "_" + _DData.Partner_AccountHolderName;
                    data.BankName = _DData.Partner_BankName;
                    data.AccountNo = _DData.Partner_AccountNo;
                    data.BankIFSC = _DData.Partner_BankIFSCCode;
                    data.AcType = _DData.BankAcType;
                    data.BankBranch = _DData.Partner_BankBranch;
                    data.Customer_Id = this.CustomerBorrower.CustomerId;
                    data.Type = data.Type;
                    data.Reference_Id = _DData.Id;
                    data.Account_Id_CR = _DData.AccountId;
                    data.PayDate =
                      data.PayDate == null || data.PayDate == ""
                        ? ""
                        : new Date(data.PayDate);
                  }
                  if (data.SubType == "Partner") {
                    let _DData = this.PartnerDropdown.filter(
                      (x) => x.Id == data.Reference_Id
                    )[0];
                    console.log("_DData", _DData);
                    // data.disable = true;
                    // data.AcHead = _DData.Account_Name;
                    data.AcHead =
                      _DData.AccountId + "_" + _DData.Partner_AccountHolderName;
                    data.BankName = _DData.Partner_BankName;
                    data.AccountNo = _DData.Partner_AccountNo;
                    data.BankIFSC = _DData.Partner_BankIFSCCode;
                    data.AcType = _DData.BankAcType;
                    data.BankBranch = _DData.Partner_BankBranch;
                    data.Customer_Id = this.CustomerBorrower.CustomerId;
                    data.Type = data.Type;
                    data.Reference_Id = _DData.Id;
                    data.Account_Id_CR = _DData.AccountId;
                    data.PayDate =
                      data.PayDate == null || data.PayDate == ""
                        ? ""
                        : new Date(data.PayDate);
                  }
                  if (data.Type == "Customer" && data.SubType == "Other") {
                    let _OData = this.OtherDropdown.filter(
                      (x) => x.Account_Name == data.Account_Id_CR
                    )[0];
                    console.log("_OData", _OData);
                    // data.disable = true;
                    //data.AcHead = _OData.Account_Name;
                    data.AcHead =
                      _OData.AccountId + "_" + _OData.Partner_AccountHolderName;

                    data.BankName = _OData.Bank_BankName;
                    data.AccountNo = _OData.Bank_AccountNo;
                    data.BankIFSC = _OData.Bank_IFSCCode;
                    data.AcType = _OData.BankAcType;
                    data.BankBranch = _OData.Bank_BranchName;
                    data.Customer_Id = this.CustomerBorrower.CustomerId;
                    data.Type = data.Type;
                    data.Reference_Id = 0;
                    data.Account_Id_CR = _OData.AccountId;
                    data.PayDate =
                      data.PayDate == null || data.PayDate == ""
                        ? ""
                        : new Date(data.PayDate);
                  }
                  if (data.Type == "Customer" && data.SubType == "Bank") {
                    // data.disable = false;
                    let _BData = this.BankDetailDropdown.filter(
                      (x) => x.Id == data.Reference_Id
                    )[0];
                    console.log("_BData", _BData);
                    // data.Customer = _BData.B_customerId + "_" + _BData.Type;
                    data.Customer = _BData.Id;
                    data.BankName = _BData.BankName;
                    data.AccountNo = _BData.AccountNo;
                    data.BankIFSC = _BData.BankIFSC;
                    data.AcType = _BData.BankAcType;
                    data.BankBranch = _BData.BankBranch;
                    data.Customer_Id = _BData.B_customerId;
                    data.Type = data.Type;
                    data.Reference_Id = _BData.Id;
                    data.Account_Id_CR = 0;
                    data.PayDate =
                      data.PayDate == null || data.PayDate == ""
                        ? ""
                        : new Date(data.PayDate);
                  }
                  if (data.SubType == "Cash") {
                    // data.disable = false;
                    console.log("this.CustomerBorrower", this.CustomerBorrower);
                    data.CustomerName = this.CustomerBorrower.Customer;

                    data.Customer_Id = this.CustomerBorrower.CustomerId;
                    data.PayDate =
                      data.PayDate == null || data.PayDate == ""
                        ? ""
                        : new Date(data.PayDate);
                    data.Type = data.Type;
                  }
                  if (data.Type == "Customer" && data.SubType == "OtherParty") {
                    data.Customer = data.Other_Customer_Name;
                    data.BankName = data.Other_Bank;
                    data.AccountNo = data.Other_AccountNo;
                    data.BankIFSC = data.Other_Ifsc;
                    data.BankBranch = data.Other_Branch;
                    data.Type = data.Type;
                    data.Account_Id_CR = 0;
                    data.PayDate =
                      data.PayDate == null || data.PayDate == ""
                        ? ""
                        : new Date(data.PayDate);
                  }
                });

                if (PendingPayment > this.PendingDisbursmentAmount) {
                  this.dialog
                    .openDialog(
                      `Actual Disbursment Payment and Authorized Disbursment Payment does not Match!! Please Check`
                    )
                    .afterClosed()
                    .subscribe((dialogResult) => {
                      this.router.navigate([`/lms/paymentinitiation`]);
                    });
                }
                if (PaymentDoneCount == this.BankModel.length) {
                  this.IsFullyPayment = true;
                } else {
                  this.IsFullyPayment = false;
                }
                this.getTotalCalculation();
              } else {
                this.showSpinner = false;
              }
            });
        } else if (this.Status == "Completed") {
          this.lmsService
            .Get_Disbursment_Payment_Detail({ LoanId: this.LoanId })
            .subscribe((res: any) => {
              if (res.length > 0) {
                this.showSpinner = false;
                console.log("Get_Disbursment_Payment_Detail", res);
                this.Disbursment_Payment_Detail = res[0];
                this.BankModel = res;
                //var PaymentDoneCount = 0;
                this.BankModel.forEach((data: any) => {
                  data.disable = false;
                  //if (data.Voucher_Id > 0) {
                  //  data.disable = true;
                  //  //PaymentDoneCount = PaymentDoneCount + 1;
                  //} else {
                  //  data.disable = false;
                  //}
                  if (data.SubType == "Dealer") {
                    let _DData = this.DealerDropdown.filter(
                      (x) => x.Id == data.Reference_Id
                    )[0];
                    console.log("_DData", _DData);
                    // data.disable = true;
                    data.AcHead = _DData.Account_Name;
                    data.BankName = _DData.Partner_BankName;
                    data.AccountNo = _DData.Partner_AccountNo;
                    data.BankIFSC = _DData.Partner_BankIFSCCode;
                    data.AcType = _DData.BankAcType;
                    data.BankBranch = _DData.Partner_BankBranch;
                    data.Customer_Id = this.CustomerBorrower.CustomerId;
                    data.Type = data.Type;
                    data.Reference_Id = _DData.Id;
                    data.Account_Id_CR = _DData.AccountId;
                    data.PayDate =
                      data.PayDate == null || data.PayDate == ""
                        ? ""
                        : new Date(data.PayDate);
                  }
                  if (data.SubType == "Partner") {
                    let _DData = this.PartnerDropdown.filter(
                      (x) => x.Id == data.Reference_Id
                    )[0];
                    console.log("_DData", _DData);
                    // data.disable = true;
                    data.AcHead = _DData.Account_Name;
                    data.BankName = _DData.Partner_BankName;
                    data.AccountNo = _DData.Partner_AccountNo;
                    data.BankIFSC = _DData.Partner_BankIFSCCode;
                    data.AcType = _DData.BankAcType;
                    data.BankBranch = _DData.Partner_BankBranch;
                    data.Customer_Id = this.CustomerBorrower.CustomerId;
                    data.Type = data.Type;
                    data.Reference_Id = _DData.Id;
                    data.Account_Id_CR = _DData.AccountId;
                    data.PayDate =
                      data.PayDate == null || data.PayDate == ""
                        ? ""
                        : new Date(data.PayDate);
                  }
                  if (data.Type == "Customer" && data.SubType == "Other") {
                    let _OData = this.OtherDropdown.filter(
                      (x) => x.Account_Name == data.Account_Id_CR
                    )[0];
                    console.log("_OData", _OData);
                    // data.disable = true;
                    data.AcHead = _OData.Account_Name;
                    data.BankName = _OData.Bank_BankName;
                    data.AccountNo = _OData.Bank_AccountNo;
                    data.BankIFSC = _OData.Bank_IFSCCode;
                    data.AcType = _OData.BankAcType;
                    data.BankBranch = _OData.Bank_BranchName;
                    data.Customer_Id = this.CustomerBorrower.CustomerId;
                    data.Type = data.Type;
                    data.Reference_Id = 0;
                    data.Account_Id_CR = _OData.AccountId;
                    data.PayDate =
                      data.PayDate == null || data.PayDate == ""
                        ? ""
                        : new Date(data.PayDate);
                  }
                  if (data.Type == "Customer" && data.SubType == "Bank") {
                    let _BData = this.BankDetailDropdown.filter(
                      (x) => x.Id == Number(data.Reference_Id)
                    )[0];
                    console.log("_BData", _BData);
                    //data.Customer = _BData.B_customerId + '_' + _BData.Type;
                    data.Customer = _BData.Id;

                    data.BankName = _BData.BankName;
                    data.AccountNo = _BData.AccountNo;
                    data.BankIFSC = _BData.BankIFSC;
                    data.AcType = _BData.BankAcType;
                    data.BankBranch = _BData.BankBranch;
                    data.Customer_Id = _BData.B_customerId;
                    data.Type = data.Type;
                    data.Reference_Id = _BData.Id;
                    data.Account_Id_CR = 0;
                    data.PayDate =
                      data.PayDate == null || data.PayDate == ""
                        ? ""
                        : new Date(data.PayDate);
                  }
                  if (data.SubType == "Cash") {
                    // data.disable = false;
                    console.log("this.CustomerBorrower", this.CustomerBorrower);
                    data.CustomerName = this.CustomerBorrower.Customer;

                    data.Customer_Id = this.CustomerBorrower.CustomerId;
                    data.PayDate =
                      data.PayDate == null || data.PayDate == ""
                        ? ""
                        : new Date(data.PayDate);
                    data.Type = data.Type;
                  }
                  if (data.Type == "Customer" && data.SubType == "OtherParty") {
                    data.Customer = data.Other_Customer_Name;
                    data.BankName = data.Other_Bank;
                    data.AccountNo = data.Other_AccountNo;
                    data.BankIFSC = data.Other_Ifsc;
                    data.BankBranch = data.Other_Branch;
                    data.Type = data.Type;
                    data.Account_Id_CR = 0;
                    data.PayDate =
                      data.PayDate == null || data.PayDate == ""
                        ? ""
                        : new Date(data.PayDate);
                  }
                });

                this.getTotalCalculation();
              } else {
                this.showSpinner = false;
              }
            });
        } else {
          this.showSpinner = false;
        }
      });
  }

  onChangePaymentType(data, index) {
    //data.Customer = "";
    //data.Amount = "";
    //this.getTotalCalculation();
    this.BankModel[index].BankName = "";
    this.BankModel[index].AccountNo = "";
    this.BankModel[index].BankIFSC = "";
    this.BankModel[index].AcType = "";
    this.BankModel[index].BankBranch = "";
    this.BankModel[index].SubType = "";
    this.BankModel[index].Dealer_Name = "";
    this.BankModel[index].Customer = "";
  }

  ChangeBankACNo(data: any, index: any) {
    var arr = data.Customer.split("_");
    if (data.Type == "Customer" && data.SubType == "Bank") {
      let _BData = this.BankDetailDropdown.filter(
        (x) => x.B_customerId == arr[0] && x.Type == arr[1]
      )[0];
      console.log("_BData", _BData);
      this.BankModel[index].BankName = _BData.BankName;
      this.BankModel[index].AccountNo = _BData.AccountNo;
      this.BankModel[index].BankIFSC = _BData.BankIFSC;
      this.BankModel[index].AcType = _BData.BankAcType;
      this.BankModel[index].BankBranch = _BData.BankBranch;
      this.BankModel[index].Customer_Id = _BData.B_customerId;
      this.BankModel[index].Type = data.Type;
      this.BankModel[index].Reference_Id = _BData.Id;
      this.BankModel[index].Account_Id_CR = 0;
    }

    if (data.SubType == "Dealer") {
      let _DData = this.DealerDropdown.filter(
        (x) => x.Account_Name == data.AcHead
      )[0];
      console.log("_DData", _DData);
      this.BankModel[index].BankName = _DData.Partner_BankName;
      this.BankModel[index].AccountNo = _DData.Partner_AccountNo;
      this.BankModel[index].BankIFSC = _DData.Partner_BankIFSCCode;
      this.BankModel[index].AcType = _DData.BankAcType;
      this.BankModel[index].BankBranch = _DData.Partner_BankBranch;
      this.BankModel[index].Customer_Id = this.CustomerBorrower.CustomerId;
      this.BankModel[index].Type = data.Type;
      this.BankModel[index].Reference_Id = _DData.Id;
      this.BankModel[index].Account_Id_CR = _DData.AccountId;
    }
    if (data.SubType == "Partner") {
      let _DData = this.PartnerDropdown.filter(
        (x) =>
          x.AccountId == data.AcHead.split("_")[0] &&
          x.Partner_AccountHolderName == data.AcHead.split("_")[1]
      )[0];
      console.log("_DData", _DData);
      if (_DData == undefined || _DData == null) {
        data.AcHead = "";
      } else {
        this.BankModel[index].BankName = _DData.Partner_BankName;
        this.BankModel[index].AccountNo = _DData.Partner_AccountNo;
        this.BankModel[index].BankIFSC = _DData.Partner_BankIFSCCode;
        this.BankModel[index].AcType = _DData.BankAcType;
        this.BankModel[index].BankBranch = _DData.Partner_BankBranch;
        this.BankModel[index].Customer_Id = this.CustomerBorrower.CustomerId;
        this.BankModel[index].Type = data.Type;
        this.BankModel[index].Reference_Id = _DData.Id;
        this.BankModel[index].Account_Id_CR = _DData.AccountId;
      }
    }
    if (data.Type == "Customer" && data.SubType == "Other") {
      let _OData = this.OtherDropdown.filter(
        (x) => x.Account_Name == data.AcHead
      )[0];
      console.log("_OData", _OData);
      this.BankModel[index].BankName = _OData.Bank_BankName;
      this.BankModel[index].AccountNo = _OData.Bank_AccountNo;
      this.BankModel[index].BankIFSC = _OData.Bank_IFSCCode;
      this.BankModel[index].AcType = _OData.BankAcType;
      this.BankModel[index].BankBranch = _OData.Bank_BranchName;
      this.BankModel[index].Customer_Id = this.CustomerBorrower.CustomerId;
      this.BankModel[index].Type = data.Type;
      this.BankModel[index].Reference_Id = 0;
      this.BankModel[index].Account_Id_CR = _OData.AccountId;
    }
    //this.getTotalCalculation();
  }

  ChangeSubType(data: any, index: any) {
    if (data.SubType == "Cash") {
      console.log("this.CustomerBorrower", this.CustomerBorrower);
      this.BankModel[index].CustomerName = this.CustomerBorrower.Customer;

      this.BankModel[index].Customer_Id = this.CustomerBorrower.CustomerId;

      this.BankModel[index].Type = data.Type;
      this.BankModel[index].Reference_Id = 0;
      this.BankModel[index].Account_Id_CR = 0;
    }
  }

  getTotalCalculation() {
    let _total: any = 0;
    this.BankModel.forEach((obj: any) => {
      if (obj.disable == false || obj.disable == undefined) {
        _total = _total + parseFloat(obj.Amount) || 0;
      }
    });

    this.totalCalculator = {
      total: parseFloat(_total),
    };
    this.RemainingAmount =
      parseFloat(this._RestAmt) - this.totalCalculator.total;
  }

  SaveDisbursment() {
    if (this.totalCalculator.total != this._RestAmt) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Total amount should not be equal  than remaining disbursment amount.",
        ...this.configSuccess,
      });
      return;
    }

    this.BankModel.forEach((obj: any) => {
      if (obj.Type == "Dealer" || obj.Type == "Partner") {
        obj.SubType = "Other";
      }
      obj.ChangeFirstEMIDate = this.ChangeFirstEMIDate;
      delete obj.Customer;
      delete obj.BankName;
      delete obj.AccountNo;
      delete obj.BankIFSC;
      delete obj.AcType;
      delete obj.BankBranch;
      delete obj.Dealer_Name;
      delete obj.CustomerName;
    });
    let _data = {
      Payment_Detail: this.BankModel,
    };
    console.log("_data", this.TotalInterest);
    this.lmsService
      .Save_Disbursment_Payment_Detail({
        JSON: JSON.stringify(_data),
        TotalInterest: this.TotalInterest,
        FirstEMIDate: this.FinalFirstEMIDate,
        InterestDate: this.InterestDate,
      })
      .subscribe((res: any) => {
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.router.navigate([`/lms/paymentauthorization`]);
          //this.getCustomerDetail();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
        this.showSpinner = false;
      });
  }
  calculateDisbursmentTotal() {
    if (this.DataSourceDisbursment != null) {
      let sum = 0.0;
      for (let i = 0; i < this.DataSourceDisbursment.filteredData.length; i++) {
        sum += Number(this.DataSourceDisbursment.filteredData[i]["Amount"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateInterestTotal() {
    if (this.DataSourceDisbursment != null) {
      let sum = 0.0;
      for (let i = 0; i < this.DataSourceDisbursment.filteredData.length; i++) {
        sum += Number(
          this.DataSourceDisbursment.filteredData[i]["InterestAmount"]
        );
      }
      this.TotalInterest = sum.toFixed(2);
      return sum.toFixed(2);
    }
    return 0;
  }
  calculatePreInterestTotalCR() {
    if (this.PreEMIInterestPayment != null) {
      let sum = 0.0;
      for (let i = 0; i < this.PreEMIInterestPayment.filteredData.length; i++) {
        sum += Number(this.PreEMIInterestPayment.filteredData[i]["AmountCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculatePreInterestTotalDR() {
    if (this.PreEMIInterestPayment != null) {
      let sum = 0.0;
      for (let i = 0; i < this.PreEMIInterestPayment.filteredData.length; i++) {
        sum += Number(this.PreEMIInterestPayment.filteredData[i]["AmountDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }

  calculatePreEMIRecvd() {
    if (this.PreEMIInterestPayment != null) {
      let sum = 0.0;
      for (let i = 0; i < this.PreEMIInterestPayment.filteredData.length; i++) {
        sum += Number(
          this.PreEMIInterestPayment.filteredData[i]["PreEMIRecvd"]
        );
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculatePreEMIDue() {
    if (this.PreEMIInterestPayment != null) {
      let sum = 0.0;
      for (let i = 0; i < this.PreEMIInterestPayment.filteredData.length; i++) {
        sum += Number(this.PreEMIInterestPayment.filteredData[i]["PreEMIDue"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  onPayDateChange() {
    console.log(
      "this.customerDetail.Application_FirstEMIDate",
      this.customerDetail.Application_FirstEMIDate
    );
    if (Math.max(...this.BankModel.map((e) => new Date(e.PayDate))) == 0) {
      this.FinalFirstEMIDate = this.customerDetail.Application_FirstEMIDate;
      this.setFinalFirstEmiDate(this.FinalFirstEMIDate);
      this.GetAdvancePaymentInterest(new Date(this.FinalFirstEMIDate));
    } else {
      this.FinalFirstEMIDate = new Date(
        Math.max(...this.BankModel.map((e) => new Date(e.PayDate)))
      );
      this.setFinalFirstEmiDate(this.FinalFirstEMIDate);
      this.GetAdvancePaymentInterest(new Date(this.FinalFirstEMIDate));
    }
  }
  //onCloseEMIDateModel() {
  //  this.EMIDate = '';
  //  $('#EMIDateModel').modal('hide');
  //}
  onFinalFirstEMIDateChange() {
    var EMIDate = new Date(this.FinalFirstEMIDate);
    this.GetAdvancePaymentInterest(EMIDate);
    //this.dialog.openConfirmDialog(`Are you sure you want to Change FirstEMI Date ${new Date(this.EMIDate).toDateString()}?`)
    //  .afterClosed().subscribe(dialogResult => {
    //    if (dialogResult == false) {
    //      this.FinalFirstEMIDate = '';
    //      return;
    //    } else {
    //this.FinalFirstEMIDate = this.EMIDate;

    // this.onCloseEMIDateModel();
    //}
    // });
  }

  setFinalFirstEmiDate(EDate) {
    console.log("EDate", EDate);
    this.minFinalFirstEMIDate = new Date(Object.assign(EDate));
    console.log("minFinalFirstEMIDate", this.minFinalFirstEMIDate);
    if (this.customerDetail.EMI_Type == "Monthly") {
      EDate.setMonth(EDate.getMonth() + 1);
    } else if (this.customerDetail.EMI_Type == "ByMonthly") {
      EDate.setMonth(EDate.getMonth() + 2);
    } else if (this.customerDetail.EMI_Type == "Quarterly") {
      EDate.setMonth(EDate.getMonth() + 3);
    } else if (this.customerDetail.EMI_Type == "HalfYearly") {
      EDate.setMonth(EDate.getMonth() + 6);
    } else if (this.customerDetail.EMI_Type == "Yearly") {
      EDate.setMonth(EDate.getMonth() + 12);
    } else if (this.customerDetail.EMI_Type == "Weekly") {
      EDate.setDate(EDate.getDate() + 7);
    } else if (this.customerDetail.EMI_Type == "Fortnightly") {
      EDate.setDate(EDate.getDate() + 15);
    } else if (this.customerDetail.EMI_Type == "ByWeekly") {
      EDate.setDate(EDate.getDate() + 14);
    } else if (this.customerDetail.EMI_Type == "FourthWeekly") {
      EDate.setDate(EDate.getDate() + 28);
    } else {
      EDate.setDate(EDate.getDate() + 1);
    }
  }
  Hold(Bank) {
    if (Bank.Id > 0) {
      this.lmsService
        .Update_Disbursment_Payment_Hold_UnHold({ Id: Bank.Id, IsHold: true })
        .subscribe((res: any) => {
          if (res[0].CODE == 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            Bank.IsHold = true;
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
          this.showSpinner = false;
        });
    } else {
      Bank.IsHold = true;
    }
  }
  Releasel(Bank) {
    if (Bank.Id > 0) {
      this.lmsService
        .Update_Disbursment_Payment_Hold_UnHold({ Id: Bank.Id, IsHold: false })
        .subscribe((res: any) => {
          if (res[0].CODE == 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            Bank.IsHold = false;
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
          this.showSpinner = false;
        });
    } else {
      Bank.IsHold = false;
    }
  }
  PaymentModel(Bank) {
    this.lmsService
      .lms_Check_InstallmentAmount({
        CaseNo: this.customerDetail.LoanNo,
        VoucherId: 0,
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          if (
            parseFloat(res[0].DVAmoutCR) - parseFloat(res[0].DVAmountDR) <
            parseFloat(Bank.Amount)
          ) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "The disbursed amount has been fully paid.",
              ...this.configSuccess,
            });
            return;
          } else {
            this.openPaymentModel(Bank);
          }
        }
      });
  }

  openPaymentModel(Bank) {
    this.voucherModel1 = { Voucher: {}, Voucher_Detail: [] };
    if (Bank.SubType == "Dealer" || Bank.SubType == "Partner") {
      this.voucherModel1.Voucher.Voucher_Sub_Type = "Loan";
      this.voucherModel1.Voucher.Voucher_Type = "Journal";
      this.voucherModel1.Voucher.Voucher_Date = new Date();
      this.voucherModel1.Voucher.LoginUserId = this.currentUser.userId;
      this.voucherModel1.Voucher.Origin = "Partner";

      this.voucherModel1.Voucher.Voucher_LoanId = this.customerDetail.LoanId;
      this.GetVoucherNo(this.voucherModel1);
      this.voucherModel1.Voucher.Voucher_Tag =
        subtype == "Cash"
          ? "CASH"
          : subtype == "OtherParty" ||
            subtype == "Dealer" ||
            subtype == "Partner"
          ? "Bank"
          : subtype;
      if (this.voucherModel1.Voucher.Voucher_Tag == "Bank") {
        this.voucherModel1.Voucher.Voucher_Mode_of_Payment = "RTGS";
      }
      this.totalvoucherCalculator1 = {
        totalCR: parseFloat("0"),
        totalDR: parseFloat("0"),
        totalCRTX: parseFloat("0"),
        totalDRTX: parseFloat("0"),
        totalCRInWord: new ToWords().convert(parseFloat("0")),
        totalDRInWord: new ToWords().convert(parseFloat("0")),
      };
    }
    this.SelectPayData = {};
    this.SelectPayData = Bank;
    console.log("SelectPayData", this.SelectPayData);
    var subtype = Object.assign(Bank.SubType);
    this.voucherModel = { Voucher: {}, Voucher_Detail: [] };
    this.voucherModel.Voucher.Voucher_Sub_Type = "Loan";
    this.voucherModel.Voucher.Voucher_Type = "Payment";
    this.voucherModel.Voucher.Voucher_Date = new Date();
    this.voucherModel.Voucher.LoginUserId = this.currentUser.userId;
    this.voucherModel.Voucher.Voucher_LoanId = this.customerDetail.LoanId;
    this.GetVoucherNo(this.voucherModel);
    this.voucherModel.Voucher.Voucher_Tag =
      subtype == "Cash"
        ? "CASH"
        : subtype == "OtherParty" || subtype == "Dealer" || subtype == "Partner"
        ? "Bank"
        : subtype;
    if (this.voucherModel.Voucher.Voucher_Tag == "Bank") {
      this.voucherModel.Voucher.Voucher_Mode_of_Payment = "RTGS";
    }
    this.IsLoanDeduct = "1";
    if (this.customerDetail.IsAmortization == 1) {
      this.GetInterestOnPay(
        new Date(this.customerDetail.Application_FirstEMIDate),
        Bank
      );
    }
    this._MasterService
      .Get_AccountHead_For_ChargesHead({
        HeadIds: "28,35,36",
        VoucherSubType: "Loan",
        ProductId: this.customerDetail.ProductId,
      })
      .subscribe((res: any) => {
        this.AccountHead_For_ChargesHead = res;
      });
    this.Get_Acc_Accounts_By_Tag(0);
    this.totalvoucherCalculator = {
      totalCR: parseFloat("0"),
      totalDR: parseFloat("0"),
      totalCRTX: parseFloat("0"),
      totalDRTX: parseFloat("0"),
      totalCRInWord: new ToWords().convert(parseFloat("0")),
      totalDRInWord: new ToWords().convert(parseFloat("0")),
    };
    this.getChargeshead();

    $("#PayModel").modal("show");
    $("#PayModel").css("z-index", "1050");
  }
  getChargeshead() {
    this._MasterService
      .Get_ChargesHeadsByProduct_ForDropdow({
        ProductId: this.customerDetail.ProductId,
      })
      .subscribe((res: any) => {
        this.chargesHeadDropdown = res;
      });
  }
  accountTagChange() {
    if (this.InterestpayAmount > 0) {
      let _ffPreEMI = this.AccountHead_For_ChargesHead.find((obj: any) => {
        return obj.HeadId == 36;
      });

      if (!_ffPreEMI) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please Bind PreEMI Interest!!",
          ...this.configSuccess,
        });
        this.onClosePayModel();
      } else {
        this.VoucherCreatetion();
      }
    } else {
      this.VoucherCreatetion();
    }
  }

  VoucherCreatetion() {
    this.voucherModel1.Voucher_Detail = [];
    if (
      this.SelectPayData.SubType == "Dealer" ||
      this.SelectPayData.SubType == "Partner"
    ) {
      this.voucherModel1.Voucher_Detail.push(
        this.dataSharingService.cleanObject({
          VoucherId: 0,
          CaseNo: this.customerDetail.LoanNo,
          CustomerId: this.CustomerBorrower.CustomerId,
          Is_LOSAppliaction: 1,
          ChargeHeadId: 28,
          ChargeHeadCategory: this.AccountHead_For_ChargesHead.find(
            (obj: any) => {
              return obj.HeadId == 28;
            }
          ).Account_Name,
          AccountId: this.AccountHead_For_ChargesHead.find((obj: any) => {
            return obj.HeadId == 28;
          }).AccountId,
          BranchId: this.customerDetail.BranchId,
          TranType: "DR",
          IsTax: 0,
          Tax_Per: 0,
          Amount: this.SelectPayData.Amount,
        })
      );
      this.voucherModel1.Voucher_Detail.push(
        this.dataSharingService.cleanObject({
          VoucherId: 0,
          CaseNo: this.customerDetail.LoanNo,
          CustomerId: this.CustomerBorrower.CustomerId,
          Is_LOSAppliaction: 1,
          ChargeHeadId: 28,
          ChargeHeadCategory: this.SelectPayData.AcHead,
          AccountId: this.SelectPayData.Account_Id_CR,
          BranchId: this.customerDetail.BranchId,
          TranType: "CR",
          IsTax: 0,
          Tax_Per: 0,
          Amount: this.SelectPayData.Amount,
        })
      );

      this.voucherModel.Voucher_Detail = [];
      this.voucherModel.Voucher_Detail.push(
        this.dataSharingService.cleanObject({
          VoucherId: 0,
          CaseNo: this.customerDetail.LoanNo,
          CustomerId: this.CustomerBorrower.CustomerId,
          Is_LOSAppliaction: "",
          ChargeHeadId: 28,
          ChargeHeadCategory: this.SelectPayData.AcHead,
          AccountId: this.SelectPayData.Account_Id_CR,
          BranchId: this.customerDetail.BranchId,
          TranType: "DR",
          IsTax: 0,
          Tax_Per: 0,
          Amount: this.SelectPayData.Amount,
        })
      );

      if (Number(this.voucherModel.Voucher.DRAccountId) > 0) {
        let _find = this.accountTagDropdown.find((obj: any) => {
          return obj.AccountId == this.voucherModel.Voucher.DRAccountId;
        });

        if (this.InterestpayAmount > 0) {
          if (this.IsLoanDeduct == "0") {
            this.voucherModel.Voucher_Detail.push(
              this.dataSharingService.cleanObject({
                VoucherId: 0,
                CaseNo: this.customerDetail.LoanNo,
                CustomerId: this.CustomerBorrower.CustomerId,
                Is_LOSAppliaction: "",
                ChargeHeadId: 35,
                ChargeHeadCategory: this.AccountHead_For_ChargesHead.find(
                  (obj: any) => {
                    return obj.HeadId == 35;
                  }
                ).Account_Name,
                AccountId: this.AccountHead_For_ChargesHead.find((obj: any) => {
                  return obj.HeadId == 35;
                }).AccountId,
                BranchId: this.customerDetail.BranchId,
                TranType: "DR",
                IsTax: 0,
                Tax_Per: 0,
                Amount: this.InterestpayAmount,
              })
            );
          }

          if (this.IsLoanDeduct != "2") {
            this.voucherModel.Voucher_Detail.push(
              this.dataSharingService.cleanObject({
                VoucherId: 0,
                CaseNo: this.customerDetail.LoanNo,
                CustomerId: this.CustomerBorrower.CustomerId,
                Is_LOSAppliaction: "",
                ChargeHeadId: 36,
                ChargeHeadCategory: this.AccountHead_For_ChargesHead.find(
                  (obj: any) => {
                    return obj.HeadId == 36;
                  }
                ).Account_Name,
                AccountId: this.AccountHead_For_ChargesHead.find((obj: any) => {
                  return obj.HeadId == 36;
                }).AccountId,
                BranchId: this.customerDetail.BranchId,
                TranType: "CR",
                IsTax: 0,
                Tax_Per: 0,
                Amount: this.InterestpayAmount,
              })
            );
          }
        }

        if (this.PreEmiBalance > 0) {
          if (this.IsLoanDeduct == "0") {
            this.voucherModel.Voucher_Detail.push(
              this.dataSharingService.cleanObject({
                VoucherId: 0,
                CaseNo: this.customerDetail.LoanNo,
                CustomerId: this.CustomerBorrower.CustomerId,
                Is_LOSAppliaction: "",
                ChargeHeadId: 35,
                ChargeHeadCategory: this.AccountHead_For_ChargesHead.find(
                  (obj: any) => {
                    return obj.HeadId == 35;
                  }
                ).Account_Name,
                AccountId: this.AccountHead_For_ChargesHead.find((obj: any) => {
                  return obj.HeadId == 35;
                }).AccountId,
                BranchId: this.customerDetail.BranchId,
                TranType: "DR",
                IsTax: 0,
                Tax_Per: 0,
                Amount: this.PreEmiBalance,
              })
            );
          }

          if (this.IsLoanDeduct != "2") {
            this.voucherModel.Voucher_Detail.push(
              this.dataSharingService.cleanObject({
                VoucherId: 0,
                CaseNo: this.customerDetail.LoanNo,
                CustomerId: this.CustomerBorrower.CustomerId,
                Is_LOSAppliaction: "",
                ChargeHeadId: 36,
                ChargeHeadCategory: this.AccountHead_For_ChargesHead.find(
                  (obj: any) => {
                    return obj.HeadId == 36;
                  }
                ).Account_Name,
                AccountId: this.AccountHead_For_ChargesHead.find((obj: any) => {
                  return obj.HeadId == 36;
                }).AccountId,
                BranchId: this.customerDetail.BranchId,
                TranType: "CR",
                IsTax: 0,
                Tax_Per: 0,
                Amount: this.PreEmiBalance,
              })
            );
          }
        }

        if (this.IsLoanDeduct == "1") {
          this.voucherModel.Voucher_Detail.push(
            this.dataSharingService.cleanObject({
              VoucherId: 0,
              CaseNo: "",
              CustomerId: "",
              Is_LOSAppliaction: "",
              ChargeHeadId: "",
              ChargeHeadCategory: _find.Account_Name,
              AccountId: _find.AccountId,
              BranchId: "",
              TranType: "CR",
              IsTax: 0,
              Tax_Per: 0,
              Amount:
                this.SelectPayData.Amount -
                this.InterestpayAmount -
                (this.PreEmiBalance > 0 ? this.PreEmiBalance : 0),
            })
          );
        } else {
          this.voucherModel.Voucher_Detail.push(
            this.dataSharingService.cleanObject({
              VoucherId: 0,
              CaseNo: "",
              CustomerId: "",
              Is_LOSAppliaction: "",
              ChargeHeadId: "",
              ChargeHeadCategory: _find.Account_Name,
              AccountId: _find.AccountId,
              BranchId: "",
              TranType: "CR",
              IsTax: 0,
              Tax_Per: 0,
              Amount: this.SelectPayData.Amount,
            })
          );
        }

        this.getTotalVoucherCalculation();
        this.getTotalVoucherCalculation1();
      }
    } else {
      this.voucherModel.Voucher_Detail = [];
      if (Number(this.voucherModel.Voucher.DRAccountId) > 0) {
        let _find = this.accountTagDropdown.find((obj: any) => {
          return obj.AccountId == this.voucherModel.Voucher.DRAccountId;
        });

        this.voucherModel.Voucher_Detail.push(
          this.dataSharingService.cleanObject({
            VoucherId: 0,
            CaseNo: this.customerDetail.LoanNo,
            CustomerId: this.CustomerBorrower.CustomerId,
            Is_LOSAppliaction: "",
            ChargeHeadId: 28,
            ChargeHeadCategory: this.AccountHead_For_ChargesHead.find(
              (obj: any) => {
                return obj.HeadId == 28;
              }
            ).Account_Name,
            AccountId: this.AccountHead_For_ChargesHead.find((obj: any) => {
              return obj.HeadId == 28;
            }).AccountId,
            BranchId: this.customerDetail.BranchId,
            TranType: "DR",
            IsTax: 0,
            Tax_Per: 0,
            Amount: this.SelectPayData.Amount,
          })
        );

        if (this.InterestpayAmount > 0) {
          if (this.IsLoanDeduct == "1") {
            //this.voucherModel.Voucher_Detail.push(this.dataSharingService.cleanObject({
            //  VoucherId: 0,
            //  CaseNo: "",
            //  CustomerId: "",
            //  Is_LOSAppliaction: "",
            //  ChargeHeadId: 28,
            //  ChargeHeadCategory: res.Item1[0].AccountName,
            //  AccountId: res.Item1[0].AccountId,
            //  BranchId: "",
            //  TranType: 'DR',
            //  IsTax: 0,
            //  Tax_Per: 0,
            //  Amount: this.InterestpayAmount
            //}));
            // this.voucherModel.Voucher_Detail[0].Amount = this.voucherModel.Voucher_Detail[0].Amount - this.InterestpayAmount;
          }
          if (this.IsLoanDeduct == "0") {
            this.voucherModel.Voucher_Detail.push(
              this.dataSharingService.cleanObject({
                VoucherId: 0,
                CaseNo: this.customerDetail.LoanNo,
                CustomerId: this.CustomerBorrower.CustomerId,
                Is_LOSAppliaction: "",
                ChargeHeadId: 35,
                ChargeHeadCategory: this.AccountHead_For_ChargesHead.find(
                  (obj: any) => {
                    return obj.HeadId == 35;
                  }
                ).Account_Name,
                AccountId: this.AccountHead_For_ChargesHead.find((obj: any) => {
                  return obj.HeadId == 35;
                }).AccountId,
                BranchId: this.customerDetail.BranchId,
                TranType: "DR",
                IsTax: 0,
                Tax_Per: 0,
                Amount: this.InterestpayAmount,
              })
            );
          }
          if (this.IsLoanDeduct != "2") {
            this.voucherModel.Voucher_Detail.push(
              this.dataSharingService.cleanObject({
                VoucherId: 0,
                CaseNo: this.customerDetail.LoanNo,
                CustomerId: this.CustomerBorrower.CustomerId,
                Is_LOSAppliaction: "",
                ChargeHeadId: 36,
                ChargeHeadCategory: this.AccountHead_For_ChargesHead.find(
                  (obj: any) => {
                    return obj.HeadId == 36;
                  }
                ).Account_Name,
                AccountId: this.AccountHead_For_ChargesHead.find((obj: any) => {
                  return obj.HeadId == 36;
                }).AccountId,
                BranchId: this.customerDetail.BranchId,
                TranType: "CR",
                IsTax: 0,
                Tax_Per: 0,
                Amount: this.InterestpayAmount,
              })
            );
          }
        }

        if (this.PreEmiBalance > 0) {
          if (this.IsLoanDeduct == "1") {
            //this.voucherModel.Voucher_Detail.push(this.dataSharingService.cleanObject({
            //  VoucherId: 0,
            //  CaseNo: "",
            //  CustomerId: "",
            //  Is_LOSAppliaction: "",
            //  ChargeHeadId: 28,
            //  ChargeHeadCategory: res.Item1[0].AccountName,
            //  AccountId: res.Item1[0].AccountId,
            //  BranchId: "",
            //  TranType: 'DR',
            //  IsTax: 0,
            //  Tax_Per: 0,
            //  Amount: this.InterestpayAmount
            //}));
            // this.voucherModel.Voucher_Detail[0].Amount = this.voucherModel.Voucher_Detail[0].Amount - this.InterestpayAmount;
          }
          if (this.IsLoanDeduct == "0") {
            this.voucherModel.Voucher_Detail.push(
              this.dataSharingService.cleanObject({
                VoucherId: 0,
                CaseNo: this.customerDetail.LoanNo,
                CustomerId: this.CustomerBorrower.CustomerId,
                Is_LOSAppliaction: "",
                ChargeHeadId: 35,
                ChargeHeadCategory: this.AccountHead_For_ChargesHead.find(
                  (obj: any) => {
                    return obj.HeadId == 35;
                  }
                ).Account_Name,
                AccountId: this.AccountHead_For_ChargesHead.find((obj: any) => {
                  return obj.HeadId == 35;
                }).AccountId,
                BranchId: this.customerDetail.BranchId,
                TranType: "DR",
                IsTax: 0,
                Tax_Per: 0,
                Amount: this.PreEmiBalance,
              })
            );
          }

          this.voucherModel.Voucher_Detail.push(
            this.dataSharingService.cleanObject({
              VoucherId: 0,
              CaseNo: this.customerDetail.LoanNo,
              CustomerId: this.CustomerBorrower.CustomerId,
              Is_LOSAppliaction: "",
              ChargeHeadId: 36,
              ChargeHeadCategory: this.AccountHead_For_ChargesHead.find(
                (obj: any) => {
                  return obj.HeadId == 36;
                }
              ).Account_Name,
              AccountId: this.AccountHead_For_ChargesHead.find((obj: any) => {
                return obj.HeadId == 36;
              }).AccountId,
              BranchId: this.customerDetail.BranchId,
              TranType: "CR",
              IsTax: 0,
              Tax_Per: 0,
              Amount: this.PreEmiBalance,
            })
          );
        }

        if (this.IsLoanDeduct == "1") {
          this.voucherModel.Voucher_Detail.push(
            this.dataSharingService.cleanObject({
              VoucherId: 0,
              CaseNo: "",
              CustomerId: "",
              Is_LOSAppliaction: "",
              ChargeHeadId: "",
              ChargeHeadCategory: _find.Account_Name,
              AccountId: _find.AccountId,
              BranchId: "",
              TranType: "CR",
              IsTax: 0,
              Tax_Per: 0,
              Amount:
                this.SelectPayData.Amount -
                this.InterestpayAmount -
                (this.PreEmiBalance > 0 ? this.PreEmiBalance : 0),
            })
          );
        } else {
          this.voucherModel.Voucher_Detail.push(
            this.dataSharingService.cleanObject({
              VoucherId: 0,
              CaseNo: "",
              CustomerId: "",
              Is_LOSAppliaction: "",
              ChargeHeadId: "",
              ChargeHeadCategory: _find.Account_Name,
              AccountId: _find.AccountId,
              BranchId: "",
              TranType: "CR",
              IsTax: 0,
              Tax_Per: 0,
              Amount: this.SelectPayData.Amount,
            })
          );
        }

        this.getTotalVoucherCalculation();
      }
    }
  }
  onIsDeductLoan(event) {
    this.totalvoucherCalculator = {
      totalCR: parseFloat("0"),
      totalDR: parseFloat("0"),
      totalCRTX: parseFloat("0"),
      totalDRTX: parseFloat("0"),
      totalCRInWord: new ToWords().convert(parseFloat("0")),
      totalDRInWord: new ToWords().convert(parseFloat("0")),
    };
    this.totalvoucherCalculator1 = {
      totalCR: parseFloat("0"),
      totalDR: parseFloat("0"),
      totalCRTX: parseFloat("0"),
      totalDRTX: parseFloat("0"),
      totalCRInWord: new ToWords().convert(parseFloat("0")),
      totalDRInWord: new ToWords().convert(parseFloat("0")),
    };
    console.log("IsLoanDeduct", this.IsLoanDeduct);
    // this.IsLoanDeduct = event.value;
    if (this.InterestpayAmount > 0 && this.IsLoanDeduct == "1") {
      this.FinalAmountPay = this.SelectPayData.Amount - this.InterestpayAmount;
    } else {
      this.FinalAmountPay = this.SelectPayData.Amount;
    }
    this.voucherModel.Voucher_Detail = [];
    this.voucherModel1.Voucher_Detail = [];
    this.Get_Acc_Accounts_By_Tag(0);
  }
  Get_Acc_Accounts_By_Tag(IsAuPaymentAccount) {
    this.voucherModel.Voucher.DRAccountId = "";

    this._MasterService
      .Get_Disbursment_Accounts_By_Tag({
        LoginUserId: this.currentUser.userId,
        Account_Tag: this.voucherModel.Voucher.Voucher_Tag,
        Voucher_Date: this.voucherModel.Voucher.Voucher_Date,
        Amount: this.FinalAmountPay,
      })
      .subscribe((res: any) => {
        if (IsAuPaymentAccount == 1) {
          this.accountTagDropdown = res.filter(
            (x) => x.IsAuPaymentAccount == 1
          );
        } else {
          this.accountTagDropdown = res;
        }
      });
  }
  onChangeMode() {
    if (this.voucherModel.Voucher.Voucher_Mode_of_Payment == "Cheque") {
      this.voucherModel.Voucher.Voucher_Cheque_No = "";
      this.voucherModel.Voucher.Voucher_Cheque_Date = "";
      this.voucherModel.Voucher.Voucher_Bank_Name = "";
      this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    } else {
      this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
      this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    }
  }
  GetVoucherNo(voucherModel) {
    this.lmsService
      .GetVoucherNo({
        VoucherType: this.voucherModel.Voucher.Voucher_Type,
        VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type,
        VoucherDate: this.voucherModel.Voucher.Voucher_Date,
      })
      .subscribe((res: any) => {
        voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
      });
  }
  onClosePayModel() {
    $("#PayModel").modal("hide");
  }
  SavePaymentVoucher() {
    this.onClosePayModel();
    this.showSpinner = true;

    if (
      this.SelectPayData.SubType == "Dealer" ||
      this.SelectPayData.SubType == "Partner"
    ) {
      this.voucherModel1.Voucher.Voucher_Total_Credit_Amount =
        this.totalvoucherCalculator1.totalCR;
      this.voucherModel1.Voucher.Voucher_Total_Debit_Amount =
        this.totalvoucherCalculator1.totalDR;

      let _finalData1 = {
        Voucher: this.dataSharingService.cleanObject({
          ...this.voucherModel1.Voucher,
        }),
        Voucher_Detail: this.voucherModel1.Voucher_Detail,
      };

      console.log("_finalData11 :", _finalData1);
      this.lmsService
        .Acc_Save_Voucher({ JSON: JSON.stringify(_finalData1) })
        .subscribe((res11: any) => {});
    }
    this.voucherModel.Voucher.Voucher_Total_Credit_Amount =
      this.totalvoucherCalculator.totalCR;
    this.voucherModel.Voucher.Voucher_Total_Debit_Amount =
      this.totalvoucherCalculator.totalDR;

    let _finalData = {
      Voucher: this.dataSharingService.cleanObject({
        ...this.voucherModel.Voucher,
      }),
      Voucher_Detail: this.voucherModel.Voucher_Detail,
    };

    console.log("_finalData :", _finalData);
    this.lmsService
      .Acc_Save_Voucher({ JSON: JSON.stringify(_finalData) })
      .subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          let _findCR = this.accountTagDropdown.find((obj: any) => {
            return obj.AccountId == this.voucherModel.Voucher.DRAccountId;
          });

          this.lmsService
            .Update_Payment_Disbursment_Voucher({
              Id: this.SelectPayData.Id,
              VoucherId: res[0].CODE,
              LoginUserId: this.currentUser.userId,
              Account_Id_DR: this.AccountHead_For_ChargesHead.find(
                (obj: any) => {
                  return obj.HeadId == 28;
                }
              ).AccountId,
              Account_Id_CR: _findCR.AccountId,
            })
            .subscribe((res: any) => {
              if (res[0].CODE >= 0) {
                this.showSpinner = false;
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                // this.getCustomerDetail();
                this.totalvoucherCalculator = undefined;
                this.router.navigate([`/lms/paymentinitiation`]);
              } else {
                this.showSpinner = false;
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
            });

          this.totalvoucherCalculator = undefined;
        } else {
          this.showSpinner = false;
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  GetInterestOnPay(EMIDate, Bank) {
    var EDate = Object.assign(EMIDate);
    var interestcal = false;
    // var diffDays = this.getDiffDays((new Date(Bank.PayDate)), EMIDate)
    var diffDays = this.getDiffDays(
      new Date(new Date().toISOString().split("T")[0] + " 00:00:00"),
      EMIDate
    );

    if (this.customerDetail.EMI_Type == "Monthly") {
      EDate.setMonth(EDate.getMonth() - 1);
      if (diffDays >= 30) {
        interestcal = true;
      }
    } else if (this.customerDetail.EMI_Type == "ByMonthly") {
      EDate.setMonth(EDate.getMonth() - 2);
      if (diffDays >= 60) {
        interestcal = true;
      }
    } else if (this.customerDetail.EMI_Type == "Quarterly") {
      EDate.setMonth(EDate.getMonth() - 3);
      if (diffDays >= 90) {
        interestcal = true;
      }
    } else if (this.customerDetail.EMI_Type == "HalfYearly") {
      EDate.setMonth(EDate.getMonth() - 6);
      if (diffDays >= 180) {
        interestcal = true;
      }
    } else if (this.customerDetail.EMI_Type == "Yearly") {
      EDate.setMonth(EDate.getMonth() - 12);
      if (diffDays >= 360) {
        interestcal = true;
      }
    } else if (this.customerDetail.EMI_Type == "Weekly") {
      EDate.setDate(EDate.getDate() - 7);
      if (diffDays >= 7) {
        interestcal = true;
      }
    } else if (this.customerDetail.EMI_Type == "Fortnightly") {
      EDate.setDate(EDate.getDate() - 15);
      if (diffDays >= 15) {
        interestcal = true;
      }
    } else if (this.customerDetail.EMI_Type == "ByWeekly") {
      EDate.setDate(EDate.getDate() - 14);
      if (diffDays >= 14) {
        interestcal = true;
      }
    } else if (this.customerDetail.EMI_Type == "FourthWeekly") {
      EDate.setDate(EDate.getDate() - 28);
      if (diffDays >= 28) {
        interestcal = true;
      }
    } else {
      EDate.setDate(EDate.getDate() - 1);
      if (diffDays >= 1) {
        interestcal = true;
      }
    }

    if (interestcal == false) {
      this.InterestpayAmount = 0;
      this.InterestDays = 0;
    } else {
      // var Days = this.getDiffDays((new Date(Bank.PayDate)), EDate)
      var Days = this.getDiffDays(
        new Date(new Date().toISOString().split("T")[0] + " 00:00:00"),
        EDate
      );

      this.InterestpayAmount = Math.round(
        ((this.SelectPayData.Amount * parseFloat(this.customerDetail.IRR)) /
          100 /
          this.currentUser.DaysInYear) *
          Days
      );
      this.InterestDays = Days;
    }
    if (this.InterestpayAmount > 0 && this.IsLoanDeduct == "1") {
      this.FinalAmountPay = this.SelectPayData.Amount - this.InterestpayAmount;
    } else {
      this.FinalAmountPay = this.SelectPayData.Amount;
    }
    console.log("his.FinalAmountPay", this.FinalAmountPay);
    console.log("his.InterestDays", this.InterestDays);
  }
  getTotalVoucherCalculation() {
    let _totalCR: any = 0;
    let _totalDR: any = 0;
    let _totalCRTX: any = 0;
    let _totalDRTX: any = 0;
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      if (obj.TranType == "CR") {
        _totalCR = _totalCR + parseFloat(obj.Amount) || 0;
      } else {
        _totalDR = _totalDR + parseFloat(obj.Amount) || 0;
      }
    });
    this.totalvoucherCalculator = {
      totalCR: parseFloat(_totalCR),
      totalDR: parseFloat(_totalDR),
      totalCRTX: parseFloat(_totalCRTX),
      totalDRTX: parseFloat(_totalDRTX),
      totalCRInWord: new ToWords().convert(parseFloat(_totalCR)),
      totalDRInWord: new ToWords().convert(parseFloat(_totalDR)),
    };
  }

  getTotalVoucherCalculation1() {
    let _totalCR: any = 0;
    let _totalDR: any = 0;
    let _totalCRTX: any = 0;
    let _totalDRTX: any = 0;
    this.voucherModel1.Voucher_Detail.forEach((obj: any) => {
      if (obj.TranType == "CR") {
        _totalCR = _totalCR + parseFloat(obj.Amount) || 0;
      } else {
        _totalDR = _totalDR + parseFloat(obj.Amount) || 0;
      }
    });
    this.totalvoucherCalculator1 = {
      totalCR: parseFloat(_totalCR),
      totalDR: parseFloat(_totalDR),
      totalCRTX: parseFloat(_totalCRTX),
      totalDRTX: parseFloat(_totalDRTX),
      totalCRInWord: new ToWords().convert(parseFloat(_totalCR)),
      totalDRInWord: new ToWords().convert(parseFloat(_totalDR)),
    };
  }

  ERequestPaymentModel(Bank) {
    this.lmsService
      .lms_Check_InstallmentAmount({
        CaseNo: this.customerDetail.LoanNo,
        VoucherId: 0,
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          if (
            parseFloat(res[0].DVAmoutCR) - parseFloat(res[0].DVAmountDR) <
            parseFloat(Bank.Amount)
          ) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "The disbursed amount has been fully paid.",
              ...this.configSuccess,
            });
            return;
          } else {
            this.openERequestPaymentModel(Bank);
          }
        }
      });
  }

  openERequestPaymentModel(Bank) {
    this.voucherModel1 = { Voucher: {}, Voucher_Detail: [] };
    if (Bank.SubType == "Dealer" || Bank.SubType == "Partner") {
      this.voucherModel1.Voucher.Voucher_Sub_Type = "Loan";
      this.voucherModel1.Voucher.Voucher_Type = "Journal";
      this.voucherModel1.Voucher.Voucher_Date = new Date();
      this.voucherModel1.Voucher.LoginUserId = this.currentUser.userId;
      this.voucherModel1.Voucher.Origin = "Partner";

      this.voucherModel1.Voucher.Voucher_LoanId = this.customerDetail.LoanId;
      this.GetVoucherNo(this.voucherModel1);
      this.voucherModel1.Voucher.Voucher_Tag =
        subtype == "Cash"
          ? "CASH"
          : subtype == "OtherParty" ||
            subtype == "Dealer" ||
            subtype == "Partner"
          ? "Bank"
          : subtype;
      if (this.voucherModel1.Voucher.Voucher_Tag == "Bank") {
        this.voucherModel1.Voucher.Voucher_Mode_of_Payment = "RTGS";
      }
      this.totalvoucherCalculator1 = {
        totalCR: parseFloat("0"),
        totalDR: parseFloat("0"),
        totalCRTX: parseFloat("0"),
        totalDRTX: parseFloat("0"),
        totalCRInWord: new ToWords().convert(parseFloat("0")),
        totalDRInWord: new ToWords().convert(parseFloat("0")),
      };
    }
    this.SelectPayData = {};
    this.SelectPayData = Bank;
    console.log("SelectPayData", this.SelectPayData);
    var subtype = Object.assign(Bank.SubType);
    this.voucherModel = { Voucher: {}, Voucher_Detail: [] };
    this.voucherModel.Voucher.Voucher_Sub_Type = "Loan";
    this.voucherModel.Voucher.Voucher_Type = "Payment";
    this.voucherModel.Voucher.Voucher_Date = new Date();
    this.voucherModel.Voucher.LoginUserId = this.currentUser.userId;
    this.voucherModel.Voucher.Voucher_LoanId = this.customerDetail.LoanId;
    this.GetVoucherNo(this.voucherModel);
    this.voucherModel.Voucher.Voucher_Tag =
      subtype == "Cash"
        ? "CASH"
        : subtype == "OtherParty" || subtype == "Dealer" || subtype == "Partner"
        ? "Bank"
        : subtype;
    if (this.voucherModel.Voucher.Voucher_Tag == "Bank") {
      this.voucherModel.Voucher.Voucher_Mode_of_Payment = "RTGS";
    }
    this.IsLoanDeduct = "1";
    if (this.customerDetail.IsAmortization == 1) {
      this.GetInterestOnPay(
        new Date(this.customerDetail.Application_FirstEMIDate),
        Bank
      );
    }
    this._MasterService
      .Get_AccountHead_For_ChargesHead({
        HeadIds: "28,35,36",
        VoucherSubType: "Loan",
        ProductId: this.customerDetail.ProductId,
      })
      .subscribe((res: any) => {
        this.AccountHead_For_ChargesHead = res;
      });
    this.Get_Acc_Accounts_By_Tag(1);
    this.totalvoucherCalculator = {
      totalCR: parseFloat("0"),
      totalDR: parseFloat("0"),
      totalCRTX: parseFloat("0"),
      totalDRTX: parseFloat("0"),
      totalCRInWord: new ToWords().convert(parseFloat("0")),
      totalDRInWord: new ToWords().convert(parseFloat("0")),
    };
    this.getChargeshead();

    $("#EPaymentModel").modal("show");
    $("#EPaymentModel").css("z-index", "1050");
  }
  onCloseEPostPayModel() {
    $("#EPaymentModel").modal("hide");
  }
  SavePostRequestPaymentVoucher() {
    this.onCloseEPostPayModel();
    console.log("selecteddata", this.SelectPayData);
    this.showSpinner = true;
    var FinalVoucher = {
      DealerVoucher: {},
      CustomerVoucher: {},
    };
    if (
      this.SelectPayData.SubType == "Dealer" ||
      this.SelectPayData.SubType == "Partner"
    ) {
      this.voucherModel1.Voucher.Voucher_Total_Credit_Amount =
        this.totalvoucherCalculator1.totalCR;
      this.voucherModel1.Voucher.Voucher_Total_Debit_Amount =
        this.totalvoucherCalculator1.totalDR;

      let _finalData1 = {
        Voucher: this.dataSharingService.cleanObject({
          ...this.voucherModel1.Voucher,
        }),
        Voucher_Detail: this.voucherModel1.Voucher_Detail,
      };

      console.log("_finalData11 :", _finalData1);
      FinalVoucher.DealerVoucher = _finalData1;
    }
    this.voucherModel.Voucher.Voucher_Total_Credit_Amount =
      this.totalvoucherCalculator.totalCR;
    this.voucherModel.Voucher.Voucher_Total_Debit_Amount =
      this.totalvoucherCalculator.totalDR;

    let _finalData = {
      Voucher: this.dataSharingService.cleanObject({
        ...this.voucherModel.Voucher,
      }),
      Voucher_Detail: this.voucherModel.Voucher_Detail,
    };

    console.log("_finalData :", _finalData);
    FinalVoucher.CustomerVoucher = _finalData;
    let _findCR = this.accountTagDropdown.find((obj: any) => {
      return obj.AccountId == this.voucherModel.Voucher.DRAccountId;
    });
    var json = {
      LoanId: this.customerDetail.LoanId,
      CreatedBy: this.currentUser.userId,
      ReferenceId: this.SelectPayData.Id,
      Voucher_Json: JSON.stringify(FinalVoucher),
      Account_Id_DR: this.AccountHead_For_ChargesHead.find((obj: any) => {
        return obj.HeadId == 28;
      }).AccountId,
      Account_Id_CR: _findCR.AccountId,
    };
    var benename = "";
    if (this.SelectPayData.SubType == "Bank") {
      benename = this.BankDetailDropdown.find(
        (x) => x.Id == Number(this.SelectPayData.Customer)
      ).CustomerBankName;
    }
    if (this.SelectPayData.SubType == "OtherParty") {
      benename = this.SelectPayData.Other_Customer_Name;
    }
    if (this.SelectPayData.SubType == "Dealer") {
      benename = this.DealerDropdown.find(
        (x) => x.AccountId == this.SelectPayData.AcHead.split("_")[0]
      ).Partner_AccountHolderName.split("(")[0];
    }
    if (this.SelectPayData.SubType == "Partner") {
      benename = this.PartnerDropdown.find(
        (x) => x.AccountId == this.SelectPayData.AcHead.split("_")[0]
      ).Partner_AccountHolderName.split("(")[0];
    }

    if (
      _findCR.AccountNo &&
      this.SelectPayData.BankIFSC &&
      this.SelectPayData.AccountNo &&
      benename
    ) {
      var AURequestJson = {
        TransactionCreationRequest: {
          uniqueRequestId: "",
          corporateCode: "",
          corporateProductCode: "",
          transactionDetails: [
            {
              paymentMethodName:
                this.voucherModel.Voucher.Voucher_Mode_of_Payment,
              remitterAccountNo: _findCR.AccountNo,
              amount: this.SelectPayData.Amount.toString(),
              ifscCode:
                this.voucherModel.Voucher.Voucher_Mode_of_Payment ==
                "Internal Fund Transfer"
                  ? ""
                  : this.SelectPayData.BankIFSC,
              payableCurrency: "INR",
              beneAccNo: this.SelectPayData.AccountNo,
              beneCode: "",
              valueDate: "",
              beneName: benename,
              remarks: this.voucherModel.Voucher.Voucher_Narration,
              transactionRefNo: this.customerDetail.LoanNo,
              paymentInstruction: "Disbursement Payment",
              //"email": this.customerDetail.CompanyMailId ? this.customerDetail.CompanyMailId : '',
              //"phoneNo": this.customerDetail.CompanyPhoneNo ? this.customerDetail.CompanyPhoneNo : ''
              email: "",
              phoneNo: "",
            },
          ],
        },
      };
      console.log("AURequestJson", JSON.stringify(AURequestJson));

      console.log("json", JSON.stringify(json));
      this._MasterService
        .AU_Payment_Initiate({
          AURequestJson: JSON.stringify(AURequestJson),
          Json: JSON.stringify(json),
        })
        .subscribe((res: any) => {
          if (res.status != "00") {
            console.log("res", res);
            this.showSpinner = false;
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res.MSG,
              ...this.configSuccess,
            });
          } else {
            this.showSpinner = false;

            this.GetBankDetailCustomerPartnerAccount();
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res.MSG,
              ...this.configSuccess,
            });
          }
        });
    } else {
      this.showSpinner = false;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please Fill Proper Bank Details !!",
        ...this.configSuccess,
      });
    }
    //let _findCR = this.accountTagDropdown.find((obj: any) => {
    //  return obj.AccountId == this.voucherModel.Voucher.DRAccountId;
    //});

    //this.lmsService
    //  .Update_Payment_Disbursment_Voucher({
    //    Id: this.SelectPayData.Id,
    //    VoucherId: res[0].CODE,
    //    LoginUserId: this.currentUser.userId,
    //    Account_Id_DR: this.AccountHead_For_ChargesHead.find(
    //      (obj: any) => {
    //        return obj.HeadId == 28;
    //      }
    //    ).AccountId,
    //    Account_Id_CR: _findCR.AccountId,
    //  })
    //  .subscribe((res: any) => {
    //    if (res[0].CODE >= 0) {
    //      this.showSpinner = false;
    //      this.snackBar.openFromComponent(SnackbarComponent, {
    //        data: res[0].MSG,
    //        ...this.configSuccess,
    //      });
    //      // this.getCustomerDetail();
    //      this.totalvoucherCalculator = undefined;
    //      this.router.navigate([`/lms/paymentinitiation`]);
    //    } else {
    //      this.showSpinner = false;
    //      this.snackBar.openFromComponent(SnackbarComponent, {
    //        data: res[0].MSG,
    //        ...this.configSuccess,
    //      });
    //    }
    //  });

    // this.totalvoucherCalculator = undefined;
  }

  EStatusCheckModel(Bank) {
    this.showSpinner = true;
    var AURequestJson = {
      TransactionEnquiryRequest: {
        uniqueRequestId: "",
        transactionRequestId: "",
        batchNo: Bank.batchNo,
        transactionRefNo: "",
        corporateCode: "",
      },
    };
    var json = {
      LoanId: this.customerDetail.LoanId,
      CreatedBy: this.currentUser.userId,
      Id: Bank.AU_Payment_Id,
      batchNo: Bank.batchNo,
    };

    console.log("AURequestJson", JSON.stringify(AURequestJson));

    console.log("json", JSON.stringify(json));
    this._MasterService
      .AU_Payment_Enquiry({
        AURequestJson: JSON.stringify(AURequestJson),
        Json: JSON.stringify(json),
      })
      .subscribe((res: any) => {
        if (res.status != "SUCCESS") {
          console.log("res", res);
          this.showSpinner = false;
          this.GetBankDetailCustomerPartnerAccount();
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res.status,
            ...this.configSuccess,
          });
        } else {
          this.showSpinner = false;

          this.GetBankDetailCustomerPartnerAccount();
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res.status,
            ...this.configSuccess,
          });
        }
      });
  }
  updateToken() {
    this.showSpinner = true;
    this.lmsService.AU_Payment_authTokenGenerate({}).subscribe((res: any) => {
      console.log("res", res);
      if (res.length > 0) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
      }
    });
    this.showSpinner = false;
  }
}
